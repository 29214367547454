import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Divider from 'components/divider';
import Scrollbar from 'components/scrollbar';
import useCalculateContainerDimension from 'hooks/useCalculateContainerDimension';
import conversationTypes from 'utils/constants/conversationTypes';
import allImage from 'assets/icons/systemicons/all.svg';
import { ReactComponent as Leave } from 'assets/icons/systemicons/ellipsis_menu/participate_leave.svg';
import Group from './group';
import List from './list';
import useStyles from './leftColumn-styles';

const listItemHeight = 40;
const notificationListPadding = 20;

const menuItems = [
  {
    title: 'Leave Conversation',
    action: 'leave-conversation',
    icon: <Leave />,
  },
];

const LeftColumn = ({
  all,
  teams,
  departments,
  groups,
  people,
  onAddButtonClicked,
  notificationsConvoList,
  onMenuSelect,
}) => {
  const containerRef = useRef(null);
  const { height } = useCalculateContainerDimension(containerRef);

  const [openPrivate, setOpenPrivate] = useState(true);
  const [openGroup, setOpenGroup] = useState(true);
  const [openOrganization, setOpenOrganization] = useState(true);

  const [privateListHeight, groupListHeight, organizationListHeight] = (() => {
    const openListCount = openPrivate + openGroup + openOrganization;
    const remainingHeight =
      height -
      3 * listItemHeight -
      (notificationsConvoList.length > 0
        ? notificationsConvoList.length * listItemHeight + notificationListPadding
        : 0);
    const privateHeight = openPrivate ? remainingHeight / openListCount : 0;
    const groupHeight = openGroup ? remainingHeight / openListCount : 0;
    const organizationHeight = openOrganization ? remainingHeight / openListCount : 0;
    return [privateHeight, groupHeight, organizationHeight];
  })();

  const toggleOpen = (callback) => callback((previousState) => !previousState);
  const togglePrivateOpen = () => toggleOpen(setOpenPrivate);
  const toggleGroupOpen = () => toggleOpen(setOpenGroup);
  const toggleOrganizationOpen = () => toggleOpen(setOpenOrganization);

  const classes = useStyles({ organizationListHeight });

  return (
    <div className={classes.root} ref={containerRef}>
      <Group
        height={privateListHeight}
        toggleOpen={togglePrivateOpen}
        groupTitle="Private messages"
        members={people}
        onAddButtonClicked={onAddButtonClicked}
        showAvatar
        avatarVariant="user"
      />
      <Group
        height={groupListHeight}
        toggleOpen={toggleGroupOpen}
        groupTitle="Groups"
        members={groups}
        onAddButtonClicked={onAddButtonClicked}
        showAvatar={false}
        showGroupMemberCount
      />
      <div className={classes.header} onClick={toggleOrganizationOpen} role="presentation">
        Organization
      </div>
      <div className={classes.organization}>
        <Scrollbar top={5} bottom={5}>
          {all.length > 0 && (
            <>
              <List members={all} showAvatar />
              <Divider className={classes.divider} />
            </>
          )}
          {departments.length > 0 && (
            <>
              <List
                members={departments}
                showAvatar
                avatarVariant="department"
                menuItems={menuItems}
                onMenuSelect={onMenuSelect}
              />
              <Divider className={classes.divider} />
            </>
          )}
          {teams.length > 0 && (
            <List
              members={teams}
              showAvatar
              avatarVariant="team"
              menuItems={menuItems}
              onMenuSelect={onMenuSelect}
            />
          )}
        </Scrollbar>
      </div>

      {notificationsConvoList.length > 0 && (
        <div className={classes.notificationList}>
          <List members={notificationsConvoList} showAvatar />
        </div>
      )}
    </div>
  );
};

LeftColumn.propTypes = {
  /** list of to all conversation */
  all: PropTypes.arrayOf(
    PropTypes.shape({
      mId: PropTypes.string,
      mTitle: PropTypes.string,
      mAvatarUrl: PropTypes.string,
      convoType: PropTypes.string,
    }),
  ),
  /** list of to notifications conversation */
  notificationsConvoList: PropTypes.arrayOf(
    PropTypes.shape({
      mId: PropTypes.string,
      mTitle: PropTypes.string,
      mAvatarUrl: PropTypes.string,
      convoType: PropTypes.string,
    }),
  ),
  /** list of team conversations */
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      mId: PropTypes.string,
      mTitle: PropTypes.string,
      mAvatarUrl: PropTypes.string,
      notificationCount: PropTypes.number,
    }),
  ),
  /** list of department conversations */
  departments: PropTypes.arrayOf(
    PropTypes.shape({
      mId: PropTypes.string,
      mTitle: PropTypes.string,
      mAvatarUrl: PropTypes.string,
      notificationCount: PropTypes.number,
    }),
  ),
  /** list of group conversations */
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      mId: PropTypes.string,
      mTitle: PropTypes.string,
      mAvatarUrl: PropTypes.string,
      info: PropTypes.number,
      notificationCount: PropTypes.number,
    }),
  ),
  /** list of personal direct conversations */
  people: PropTypes.arrayOf(
    PropTypes.shape({
      mId: PropTypes.string,
      mTitle: PropTypes.string,
      mAvatarUrl: PropTypes.string,
      notificationCount: PropTypes.number,
    }),
  ),
  /** callback to open new Message popover */
  onAddButtonClicked: PropTypes.func,
  /** callback to executue the actions from menu */
  onMenuSelect: PropTypes.func,
};

LeftColumn.defaultProps = {
  all: [
    {
      mId: 'messageAll',
      mTitle: 'Everyone',
      mAvatarUrl: allImage,
      mAssignedMembers: [{ mId: 'messageAll', mTitle: 'Everyone', mAvatarUrl: allImage }],
      convoType: conversationTypes.ALL,
    },
  ],
  notificationsConvoList: [],
  teams: [],
  departments: [],
  groups: [],
  people: [],
  onAddButtonClicked: () => {},
  onMenuSelect: () => {},
};

export default LeftColumn;
