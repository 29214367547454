import { useContext } from 'react';
import PropTypes from 'prop-types';
import { Fade } from '@material-ui/core';
import defaultStateValues from 'screens/planning/components/status/utils/statusBoards/defaultStateValues';
import OrderButton from 'features/orderForm/components/OrderButton';
import Tooltip from 'components/tooltip';
import variants from 'utils/instance/variants';
import providerStates from 'utils/constants/providerStates';
import configCtx from 'contexts/configContext';
import InstanceLink from '../instanceLink';
import InfoSummary from 'features/instance/components/infoSummary/InfoSummary';
import StatusSelector from './components/statusSelector';
import LockedIndicator from './components/lockedIndicator';
import useStyles from './footer-styles';
import { useCanSeeOrderManagement } from 'hooks/useCheckUserRight';

const getMessage = (variant, providerState, providerMessage) => {
  if (variant === variants.LINEAR || variant === variants.GENERAL) return '';
  if (
    providerState === providerStates.FAILED ||
    providerState === providerStates.PENDING ||
    providerState === providerStates.EXPIRED ||
    providerState === providerStates.ASSIGNED ||
    providerState === providerStates.DELETED ||
    providerState === providerStates.PUBLISHED ||
    providerState === providerStates.DRAFT ||
    providerState === providerStates.UNKNOWN
  )
    return providerMessage;
  if (providerState === providerStates.SCHEDULED)
    return `The instance will be published at scheduled time.`;
  return `Unable to handle state. ${providerMessage}`;
};

const FooterView = ({
  variant,
  clipDuration,
  instanceId,
  collapsed,
  onClipDurationChange,
  onScriptDurationChange,
  scriptDuration,
  totalDuration,
  clipAutoDurationField,
  scriptDurationField,
  statusId,
  onStatusChange,
  onBackgroundClick,
  isSavingContent,
  readLock,
  lockedByUser,
  writeLock,
  onDone,
  disableEdit,
  hasChanges,
  twitterThreadCount,
  canShowNewDesign,
  providerState,
  providerMessage,
  instance,
  canUpdateInstance,
  relatedMembers,
  publishingPoint,
  platformKind,
  autoClipDurationSettingsValue,
  canUpdateScriptDurationSettingsValue,
  isPreview,
  accountIdentifier,
  onCancel,
  isCancelled,
}) => {
  const { kanbanBoardStates = [] } = useContext(configCtx);
  const { canAccessOrderManagement } = useCanSeeOrderManagement();
  const statusState = kanbanBoardStates.find((s) => s.id === statusId);
  const backgroundColor = statusState?.backgroundColor || defaultStateValues.backgroundColor;
  const opacity = statusState?.opacity || defaultStateValues.opacity;
  const classes = useStyles({ backgroundColor, opacity });
  return (
    <div className={classes.root}>
      <div className={classes.footerContent}>
        <div role="presentation" className={classes.backgroundColor} onClick={onBackgroundClick} />
        <div className={classes.content}>
          <div className={classes.info}>
            {variant === variants.LINEAR && (
              <>
                <InfoSummary
                  disabled={disableEdit || isPreview}
                  duration={clipDuration}
                  manualDurationField={clipAutoDurationField}
                  title="Dur"
                  onUpdate={onClipDurationChange}
                  manualDurationSettingsValue={autoClipDurationSettingsValue}
                />

                <InfoSummary
                  disabled={!canUpdateScriptDurationSettingsValue || disableEdit || isPreview}
                  duration={scriptDuration}
                  manualDurationField={scriptDurationField}
                  title="Script"
                  onUpdate={onScriptDurationChange}
                  manualDurationSettingsValue={canUpdateScriptDurationSettingsValue}
                />

                <InfoSummary disableEdit duration={totalDuration} title="total" />
              </>
            )}
            {canShowNewDesign && variant === variants.TWITTER && (
              <InfoSummary disableEdit size="large" duration={twitterThreadCount} title="Threads" />
            )}
            <div className={classes.messageText}>
              {getMessage(variant, providerState, providerMessage)}
            </div>
          </div>
          <div className={classes.footerRightArea}>
            {canAccessOrderManagement && (
              <OrderButton
                resourceId={instanceId}
                resourceTitle={instance.mTitle}
                resourceType="instance"
                formTypes={['instance', platformKind ?? publishingPoint]}
              />
            )}
            <Tooltip title="Link instance" noArrow>
              <InstanceLink
                linkedInstances={relatedMembers || []}
                instance={instance}
                isLinkingDisabled={!canUpdateInstance}
              />
            </Tooltip>
            <StatusSelector
              {...{
                variant,
                statusId,
                publishingPoint,
                accountIdentifier,
              }}
              disableEdit={disableEdit}
              onChange={onStatusChange}
            />
          </div>
        </div>
      </div>
      {(readLock || writeLock) && (
        <Fade in timeout={{ enter: 250, exit: 250 }}>
          <div>
            {/** prevents ref error * */}
            <LockedIndicator
              {...{
                readLock,
                writeLock,
                onDone,
                onBackgroundClick,
                lockedByUser,
                isSavingContent,
                hasChanges,
                collapsed,
                onCancel,
                isCancelled,
              }}
            />
          </div>
        </Fade>
      )}
    </div>
  );
};

FooterView.propTypes = {
  /** Variant of the instance card */
  variant: PropTypes.oneOf(Object.values(variants)),
  /** Callback to be invoked on clip duration change */
  onClipDurationChange: PropTypes.func,
  /** Current status id of the instance */
  statusId: PropTypes.string,
  /** Callback to be invoked on status change */
  onStatusChange: PropTypes.func,
  /** Callback to be invoked on click for non-editable elements */
  onBackgroundClick: PropTypes.func,
  /** Boolean that indicates that editor content is saving */
  isSavingContent: PropTypes.bool,
  /** Name of the instance's locking user */
  lockedByUser: PropTypes.string,
  /** Boolean to indicate that the user has write access of the instance */
  writeLock: PropTypes.bool,
  /** Callback to be invoked when done button is pressed */
  onDone: PropTypes.func,
  /** List of users assigned to current instance */
  assignees: PropTypes.arrayOf(
    PropTypes.shape({
      mAvatarUrl: PropTypes.string,
    }),
  ),
  /** Callback to be invoked on assignee update */
  onAssigneeUpdate: PropTypes.func,
  /** Boolean that stops an user from editing an instance */
  disableEdit: PropTypes.bool,
  twitterThreadCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  providerState: PropTypes.string,
  providerMessage: PropTypes.string,
};

FooterView.defaultProps = {
  variant: variants.LINEAR,
  onClipDurationChange: (newDuration) => {},
  statusId: 'todo',
  onStatusChange: (newStatusId) => {},
  onBackgroundClick: () => {},
  isSavingContent: false,
  lockedByUser: 'Someone',
  writeLock: false,
  onDone: () => {},
  assignees: [],
  onAssigneeUpdate: (updatedAssignees) => {},
  disableEdit: false,
  twitterThreadCount: '-',
  providerState: '',
  providerMessage: '',
};

export default FooterView;
