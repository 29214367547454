/* eslint-disable no-console */
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { debounce, keyBy, omit, throttle } from 'lodash';

import { DefaultMdfIds, useGetMdfs } from 'api/mdf/useGetMdfs';
import { useGetOrderForms } from 'api/order_forms/useGetOrderForms';
import { SearchParameters, useSearch } from 'api/search/useSearch';
import useGetFeed from 'api/useGetFeed';
import { CommandToolbar, toolbarFilterDefaults } from 'components/command/toolbar/CommandToolbar';
import useOpenMember from 'components/contextMenu/useOpenMember';
import { MetadataWrapper } from 'components/createNewV3/styled';
import { MdfDropdown } from 'components/mdfEditor/MdfDropdown';
import { MdfEditor } from 'components/mdfEditor/MdfEditor';
import SplitBar from 'components/split';
import Text from 'components/text/Text';
import MimirItem from 'features/mimirDeck/components/items';
import useMimirSearch, {
  MimirItem as MimirInterface,
} from 'features/mimirDeck/hooks/useMimirSearch';
import OrderFormDropdown from 'features/orderForm/components/OrderFormDropdown';
import useDinaNavigate from 'hooks/useDinaNavigate';
import useOpenMimirItem from 'hooks/useOpenMimirItem';
import { VStack } from 'layouts/box/Box';
import {
  CommandDialog,
  CommandGroup,
  CommandInput,
  CommandList,
  CommandMounted,
} from 'lib/command';
import { useSettingsOpen } from 'screens/main/components/header/navbar/settings/atomsTs';
import { useAllMembersKeyed, useFeedSources } from 'store';
import { Metadata, NewFieldValue } from 'types/forms/forms';
import { Mdf, MemberType, SearchItemTypeEnum } from 'types/graphqlTypes';
import { OrderFormMemberType } from 'types/memberTypes/order_form';

import { FeedItem } from './views/FeedCommandItem';
import InitiallyHiddenInstructions from './views/InitiallyHiddenInstructions';
import InitiallyHiddenSearchGroup from './views/InitiallyHiddenSearchGroup';
import InstructionGroup from './views/InstructionGroup';
import MetadataGroup from './views/MetadataGroup';
import NavigationGroup from './views/NavigationGroup';
import SearchResults from './views/search-results/SearchResults';
import { mdfIdToMType, mTypeToLabel } from './command-constants';
import {
  CommandToolbarProps,
  DefaultMdfTypes,
  Destination,
  Section,
  treatAsSection,
} from './command-types';
import { isFiltering as calcIsFiltering, isFiltering } from './command-utils';
import CommandFooter from './CommandFooter';
import { CommandItem } from './CommandItem';
import { CommandPathProps, useCommandPath } from './CommandPath';
const defaultPlaceholderText = 'Type a command or search...';

/**
 * This must be included for each command item that may not have unique text content.
 *
 * This is necessary for cmdk to look at the contents as a unique value. For example, many feed
 * items may have practically the same content. Without including a unique id in the actual DOM
 * tree of the item, cmdk struggles seeing the difference and treats it as the same item.
 */
export const UniqueId = ({ id }: { id: string }) => <div style={{ display: 'none' }}>{id}</div>;

const showSearchResultForSections = [
  'rundowns',
  'stories',
  'instances',
  'contacts',
  'tasks',
  'notes',
];

interface Props {
  isMounted?: boolean;
  allowStaticSearchOptions?: boolean;
  setSearchParams?: (searchObject: SearchParameters) => void;
  searchParams?: SearchParameters;
}

const getToolbarStateFromInput = (input: SearchParameters): CommandToolbarProps => {
  const props: CommandToolbarProps = {
    ...input.toolbarState,
    isFiltering: false,
  };
  return {
    ...props,
    isFiltering: isFiltering(props),
  };
};

const CommandMenu = ({
  isMounted = false,
  allowStaticSearchOptions = true,
  setSearchParams,
  searchParams,
}: Props) => {
  const { navigateTo } = useDinaNavigate();
  const [toolbarHeight, setToolbarHeight] = useState(32);
  const [allMembersKeyed] = useAllMembersKeyed();
  const [toolbarState, setToolbarState] = useState<CommandToolbarProps>(
    searchParams
      ? getToolbarStateFromInput(searchParams)
      : {
          ...toolbarFilterDefaults,
        },
  );
  const [pathState, setPathState] = useCommandPath();
  const { orderForms } = useGetOrderForms();
  const [, setSettingsOpen] = useSettingsOpen();
  const { openItem } = useOpenMember();
  const openAssetInMimir = useOpenMimirItem();
  const { search: searchInMimir, loading } = useMimirSearch();
  const [searchPayload, setSearchPayload] = useState<Metadata>(
    searchParams?.metadataFilter ? { ...searchParams?.metadataFilter } : {},
  );
  const [livePayload, setLivePayload] = useState<Metadata>(
    searchParams?.metadataFilter ? { ...searchParams?.metadataFilter } : {},
  );
  const [selectedOrderForm, setSelectedOrderForm] = useState<OrderFormMemberType | null>(null);
  const [open, setOpen] = useState(false);
  const [focused, setFocused] = useState(false);
  const [inputValue, setInputValue] = useState(searchParams?.searchString ?? '');
  const [searchValue, setSearchValue] = useState(searchParams?.searchString ?? '');
  const [mimirItems, setMimirItems] = useState<MimirInterface[]>([]);
  const [showSidePanel, setShowSidePanel] = useState(!!searchParams?.mdfId);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { mdfs } = useGetMdfs({ all: true });
  const mdfMap = useMemo(() => {
    return keyBy(mdfs, (mdf) => mdf.id);
  }, [mdfs]);

  const orderMdfMap = useMemo(() => {
    const orderMdfs: Record<string, Mdf> = {};
    orderForms.forEach((o) => {
      const mdf = o.mSecId ? mdfMap[o.mSecId] : undefined;
      if (mdf) {
        orderMdfs[o.mDescription] = mdf;
      }
    });
    return orderMdfs;
  }, [mdfMap, orderForms]);

  const showTaskMetadataSearch = toolbarState.mTypes.includes(SearchItemTypeEnum.order);
  const activeSection = pathState.sections[pathState.sections.length - 1];
  const activeMdf =
    mdfMap[toolbarState.mdfId ?? toolbarState.defaultMdfId ?? ''] ||
    orderMdfMap[toolbarState.mdfId ?? ''];
  const {
    items: searchItems,
    loading: searchLoading,
    handleLoadMore,
  } = useSearch(
    searchParams
      ? { ...searchParams }
      : {
          skip: isMounted ? false : !open,
          searchString: searchValue,
          toolbarState,
          metadataFilter: searchPayload,
          mdfId: toolbarState.mdfId ?? undefined,
        },
  );

  const [sources] = useFeedSources();
  const { items, loading: feedsLoading } = useGetFeed({
    selectedProviders: sources ?? [],
    searchString: searchValue,
    noSubscribe: true,
    noSearch: !pathState.sections.includes('feeds'),
  });

  const throttled = useRef(
    throttle((newValue: string) => setSearchValue(newValue), 1000, { trailing: true }),
  );
  const debounced = useRef(debounce((newValue: string) => setSearchValue(newValue), 300));

  const debouncedMetadata = useRef(
    debounce((val: Metadata) => {
      setSearchPayload(val);
    }, 500),
  );

  useEffect(() => {
    throttled.current(inputValue);
    debounced.current(inputValue);
  }, [inputValue]);

  useEffect(() => {
    debouncedMetadata.current(livePayload);
  }, [livePayload]);

  const showLoading = loading || feedsLoading || searchLoading;
  const isHome = activeSection === 'home' || !activeSection;
  const isSearch =
    toolbarState.isFiltering ||
    showSearchResultForSections.includes(activeSection) ||
    activeMdf !== undefined;

  const shouldFilter = activeSection !== 'feeds' && activeSection !== 'assets';
  const debouncedMimirSearch = useRef(
    debounce(async (searchString: string) => {
      const result = await searchInMimir(searchString);
      setMimirItems(result);
    }, 1000),
  ).current;

  useEffect(() => {
    if (activeSection === 'assets') {
      debouncedMimirSearch(searchValue)?.catch((err) => console.error(err));
    }
  }, [searchValue, debouncedMimirSearch, activeSection]);

  useEffect(() => {
    return () => {
      debouncedMimirSearch.cancel();
    };
  }, [open]);

  useEffect(() => {
    if (setSearchParams) {
      // We do not want to set mdfId for default mTypes - many older stories will not
      // have this set, and will not appear if we send in that mdfId to search.
      const shouldSetMdfId = !DefaultMdfIds.includes(activeMdf?.id);
      setSearchParams({
        skip: false,
        searchString: searchValue,
        toolbarState: { ...toolbarState },
        metadataFilter: searchPayload,
        mdfId: shouldSetMdfId ? activeMdf?.id : undefined,
      });
    }
  }, [setSearchParams, searchValue, toolbarState, searchPayload, activeMdf]);

  useEffect(() => {
    const downKey = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen((prev) => !prev);
      }
    };
    document.addEventListener('keydown', downKey);
    return () => document.removeEventListener('keydown', downKey);
  }, []);

  const placeholder = useMemo(() => {
    if (toolbarState.isFiltering) {
      return 'Type to search further or press backspace to reset';
    }
    if (toolbarState.mTypes.length) {
      return `Search in ${toolbarState.mTypes.map((m) => mTypeToLabel[m]).join(', ')}`;
    } else {
      return defaultPlaceholderText;
    }
  }, [toolbarState.mTypes, toolbarState.isFiltering, inputValue]);

  const popSection = useCallback(() => {
    setPathState((p: CommandPathProps) => {
      const x = [...p.sections];
      x.splice(-1, 1);
      return { sections: x };
    });
  }, []);

  const navigateToPage = useCallback(
    (val: Destination) => {
      if (val === 'settings') {
        setSettingsOpen(true);
      } else {
        navigateTo(val);
      }
      setOpen(false);
    },
    [setOpen, setSettingsOpen, navigateTo],
  );

  const updateFilterState = useCallback(
    (val: Partial<CommandToolbarProps>) => {
      setToolbarState((prevState) => {
        const newValue = {
          ...prevState,
          ...val,
        };
        return {
          ...newValue,
          isFiltering: calcIsFiltering(newValue),
        };
      });
    },
    [setToolbarState],
  );

  const goToMdf = useCallback(
    (mdf: Mdf | null) => {
      setInputValue('');
      setShowSidePanel(true);

      if (mdf && mdfIdToMType[mdf.id as DefaultMdfTypes]) {
        updateFilterState({
          mTypes: [mdfIdToMType[mdf.id as DefaultMdfTypes]],
          defaultMdfId: mdf.id as DefaultMdfTypes,
          mdfId: null,
        });
      } else if (mdf === null) {
        updateFilterState({
          mTypes: [],
          defaultMdfId: null,
        });
      } else {
        updateFilterState({
          defaultMdfId: null,
          mdfId: mdf.id,
          mTypes: [],
        });
      }
    },
    [setInputValue],
  );

  const doSearch = useCallback((val: string) => {
    console.log(val);
  }, []);

  const goToSection = useCallback(
    (val: Section | SearchItemTypeEnum) => {
      if (!treatAsSection(val)) {
        updateFilterState({
          mTypes: [val],
        });
      } else {
        setPathState({ sections: [...pathState.sections, val] });
      }
      setInputValue('');
    },
    [setPathState, setInputValue, pathState, updateFilterState],
  );

  const updateFieldValue = useCallback(
    (p: NewFieldValue) => {
      setLivePayload((prevState) => ({
        ...prevState,
        [p.fieldId]: p.value,
      }));
    },
    [setLivePayload],
  );

  const clearFieldValue = useCallback(
    (fieldId: string) => {
      setLivePayload((prevState) => ({
        ...omit(prevState, fieldId),
      }));
    },
    [setLivePayload],
  );

  const resetAll = useCallback(() => {
    setPathState({ sections: [] });
    setLivePayload({});
    setSelectedOrderForm(null);
    updateFilterState({ ...toolbarFilterDefaults });
  }, [setLivePayload, setToolbarState]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (inputValue.length || !focused) {
        return;
      }

      if (e.key === 'Backspace') {
        e.preventDefault();
        if (!isHome) popSection();
        resetAll();
      }
    },
    [inputValue.length, isHome, focused, popSection, resetAll],
  );

  const onOrderFormSelect = useCallback(
    (val: OrderFormMemberType | null) => {
      setSelectedOrderForm(val);
      updateFilterState({
        mdfId: val?.mSecId ?? null,
        mTypes: [SearchItemTypeEnum.order], // Force search to Task only.
      });
    },
    [setSelectedOrderForm, updateFilterState],
  );

  const doOpen = useCallback(
    (val: MemberType) => {
      openItem(val);
      setOpen(false);
    },
    [openItem, setOpen],
  );

  const startFilteringLessImportantOptions = inputValue.length > 2;
  const CommandPart = useMemo(() => {
    return (
      <CommandList>
        {allowStaticSearchOptions && isHome && !isSearch && (
          <>
            {startFilteringLessImportantOptions && (
              <>
                <InitiallyHiddenInstructions inputValue={inputValue} />
                <InitiallyHiddenSearchGroup goToSection={goToSection} inputValue={inputValue} />
              </>
            )}
            {!isMounted && <NavigationGroup navigateTo={navigateToPage} />}
            {!isMounted && <InstructionGroup setOpen={setOpen} />}
            {startFilteringLessImportantOptions && (
              <MetadataGroup goToMdf={goToMdf} mdfs={mdfs} inputValue={inputValue} />
            )}
            <SearchResults
              searchValue={searchValue}
              sortedBy={toolbarState.sortBy}
              order={toolbarState.order}
              toolbarState={toolbarState}
              setToolbarState={setToolbarState}
              loading={searchLoading}
              openItem={doOpen}
              setOpen={setOpen}
              items={searchItems}
              members={allMembersKeyed}
              loadMore={handleLoadMore}
            />
          </>
        )}

        {(!allowStaticSearchOptions || isSearch) && (
          <SearchResults
            searchValue={searchValue}
            sortedBy={toolbarState.sortBy}
            order={toolbarState.order}
            toolbarState={toolbarState}
            setToolbarState={setToolbarState}
            loading={searchLoading}
            openItem={doOpen}
            setOpen={setOpen}
            items={searchItems}
            members={allMembersKeyed}
            loadMore={handleLoadMore}
          />
        )}

        {activeSection === 'feeds' && items.length > 0 && (
          <CommandGroup heading="Search results">
            {items.map((feedItem, i) => (
              <CommandItem key={feedItem.mId} type="feed" onSelect={doSearch}>
                <>
                  <UniqueId id={feedItem.mId ?? feedItem.mTitle ?? `${i}`} />
                  <FeedItem item={feedItem} />
                </>
              </CommandItem>
            ))}
          </CommandGroup>
        )}

        {activeSection === 'assets' && mimirItems.length > 0 && (
          <CommandGroup heading="Search results">
            {mimirItems.map((mimirItem) => (
              <CommandItem key={mimirItem.id} type="asset" onSelect={doSearch}>
                <>
                  <UniqueId id={mimirItem.id} />
                  <MimirItem item={mimirItem} onClick={() => openAssetInMimir(mimirItem.id)} />
                </>
              </CommandItem>
            ))}
          </CommandGroup>
        )}
      </CommandList>
    );
  }, [
    isSearch,
    inputValue,
    mdfs,
    allMembersKeyed,
    startFilteringLessImportantOptions,
    activeSection,
    mimirItems,
    searchItems,
    doSearch,
    setOpen,
    handleLoadMore,
    openItem,
    isMounted,
  ]);

  const CommandComponent = isMounted ? CommandMounted : CommandDialog;

  return (
    <CommandComponent
      open={isMounted || open}
      onDialogFocus={() => inputRef.current?.focus()}
      onOpenChange={setOpen}
      onKeyDown={onKeyDown}
      shouldFilter={shouldFilter}
      innerHeight={toolbarHeight}
      dialogWidth={showSidePanel ? 52 : 38}
    >
      <CommandInput
        ref={inputRef}
        loading={showLoading}
        placeholder={placeholder}
        value={inputValue}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onValueChange={(value) => {
          setInputValue(value);
        }}
      />
      <CommandToolbar
        toolbarState={toolbarState}
        setToolbarState={setToolbarState}
        setToolbarHeight={setToolbarHeight}
        toolbarHeight={toolbarHeight}
        resetAll={resetAll}
        clearFieldValue={clearFieldValue}
        metadataFilter={livePayload}
        mdfs={mdfs}
        mdfId={activeMdf?.id}
        items={searchItems}
        allMembersKeyed={allMembersKeyed}
        showSidePanel={showSidePanel}
        toggleSidePanel={() => setShowSidePanel(!showSidePanel)}
      />
      {showSidePanel ? (
        <SplitBar
          style={{ height: `calc(70vh - ${toolbarHeight + 32}px`, flex: 'none' }}
          split={undefined}
          primary="first"
          pane1Style={{
            minWidth: '300px',
            maxWidth: '80%',
          }}
          pane2Style={{
            minWidth: '10%',
            maxWidth: '80%',
          }}
        >
          <MetadataWrapper>
            <VStack alignItems="start">
              <Text variant="overline">
                Filter by {showTaskMetadataSearch ? 'task type' : 'metadata'}
              </Text>
              {showTaskMetadataSearch ? (
                <OrderFormDropdown
                  onSelect={onOrderFormSelect}
                  selectedOrderForm={selectedOrderForm}
                />
              ) : (
                <MdfDropdown
                  hideLabel
                  systemOnly
                  placeholder="Select type"
                  value={activeMdf?.id ?? ''}
                  onSelectMdf={goToMdf}
                />
              )}

              {activeMdf && (
                <MdfEditor
                  fireOnChange
                  forceDateRange
                  ignoreDefaultValue
                  fields={activeMdf.fields}
                  defaultLayoutSettings={activeMdf.views.default}
                  layoutSettings={activeMdf.views.story_create}
                  metadata={livePayload}
                  permissions={activeMdf.permissions}
                  updateFieldValue={updateFieldValue}
                />
              )}
            </VStack>
          </MetadataWrapper>
          {CommandPart}
        </SplitBar>
      ) : (
        CommandPart
      )}
      {!isMounted && <CommandFooter />}
    </CommandComponent>
  );
};

export default memo(CommandMenu);
