import { useCallback, useState, memo, forwardRef, useImperativeHandle, useContext } from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

import { ScopeProvider } from 'jotai-molecules';
import { kanbanScope } from 'features/kanban/store/kanban';
import useCreateRundown from 'hooks/useCreateRundown';
import useDinaNavigate from 'hooks/useDinaNavigate';
import { formatDuration } from 'utils/formatDuration';
import memberTypes from 'graphql/memberTypes';
import { sortRundownsByStartTime } from 'utils/rundown/sortRundowns';
import capitalize from 'utils/capitalize';
import getRelativeDate from 'utils/getRelativeDate';
import UserContext from 'contexts/UserContext';

import useGetDailyRundowns from './../../api/useGetRundowns';
import useGetMasterRundowns from './../../api/useGetMasterRundowns';

import KanbanLoader from './KanbanLoader';

import NewRundownSelector from '../newRundownSelector';
import Popover from 'components/popover/Popover';
import CreateRundown from 'components/createNew';
import RundownListItem from '../listItem';
import isRundownEditable from 'utils/rundown/isRundownEditable';

const GroupContainer = forwardRef(
  ({ selectedDates, setPreviewRundown, canCreateNewRundown }, ref) => {
    const { navigateTo } = useDinaNavigate();

    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorE2, setAnchorE2] = useState(null);
    const [input, setInput] = useState(null);
    const [createRundown] = useCreateRundown();
    const userContext = useContext(UserContext);

    const { startDate, endDate } = selectedDates;

    const { data, error } = useGetDailyRundowns({
      startDate,
      endDate,
    });

    const { data: masterRundowns, error: masterError } = useGetMasterRundowns({
      startDate,
      endDate,
    });

    const onInitializeRundown = (selectedRundown, anchor) => {
      setInput(selectedRundown);
      setAnchorEl(null);
      setAnchorE2(anchor);
    };

    const onCreate = async (newName) => {
      if (!input || !canCreateNewRundown) return;
      const newInput = input;
      newInput.mTitle = newName;
      const newRundown = await createRundown(newInput);
      setAnchorE2(null);
      setInput(null);

      if (!newRundown) return;
      const { mId: id, mType } = newRundown;

      navigateTo(mType, id);
    };

    const getPublishingAtTime = (rundown) => {
      if (!rundown.mPublishingAt) return '00:00:00';
      return format(rundown.mPublishingAt, 'HH:mm:ss');
    };

    const getPublishingAtDate = (rundown) => {
      if (!rundown.mPublishingAt) return '1970-01-01';
      return format(rundown.mPublishingAt, 'ddd. D MMM. YYYY');
    };

    const getPlannedDuration = (rundown) => {
      const ret = rundown.mPlannedDuration ? formatDuration(rundown.mPlannedDuration) : 0;
      return ret;
    };

    const renderItem = useCallback(
      ({ member }) => {
        return (
          <RundownListItem
            key={`${member.mId}${member.mRefId}`}
            title={member.mTitle}
            mThumbnailKey={member.mThumbnailKey}
            startTime={getPublishingAtTime(member)}
            timeZone={member?.recurrence?.timeZone}
            plan={getPlannedDuration(member)}
            id={member.mId}
            rundowntype={member.mType}
            platformKind={member.platformKind}
            state={member.mState}
            selectedDates={selectedDates}
            startDate={member.mPublishingAt}
            setPreviewRundown={setPreviewRundown}
            tooltipTitle={getPublishingAtDate(member)}
            showSelected
          />
        );
      },
      [selectedDates, getPublishingAtDate],
    );

    const renderMasterItem = useCallback(
      ({ member }) => {
        const canAccess = isRundownEditable({
          permissions: member?.permissions,
          groups: userContext?.groups,
        });

        return canAccess ? (
          <RundownListItem
            key={member.mId}
            title={member.mTitle}
            mThumbnailKey={member.mThumbnailKey}
            startTime={member?.recurrence?.startTime || '00:00:00'}
            timeZone={member?.recurrence?.timeZone}
            plan={member.recurrence.duration}
            difference={member.difference}
            platformKind={member.platformKind}
            id={member.mId}
            selectedDates={selectedDates}
            initializeRundown={(e) => onInitializeRundown(e, anchorEl)}
          />
        ) : null;
      },
      [selectedDates, onInitializeRundown],
    );

    const handleSetAnchorEl = useCallback(
      (e) => {
        setAnchorEl(e.currentTarget.parentNode);
      },
      [setAnchorEl],
    );

    useImperativeHandle(
      ref,
      () => ({
        create(e) {
          handleSetAnchorEl(e);
        },
      }),
      [],
    );

    if (error || masterError)
      return (
        <div>
          {error?.message}
          {masterError && (
            <>
              <br /> {masterError.message}
            </>
          )}
        </div>
      );

    if (data && masterRundowns) {
      const getUnInitializedRundowns = (masters, dailies) => {
        let ret = [];
        if (!masters) return ret;
        const sortedMasterRundowns = sortRundownsByStartTime([...masters]);
        ret = [].concat(
          sortedMasterRundowns.filter(
            (obj1) =>
              dailies.every((obj2) => obj1.mId !== obj2.mRundownTemplateId) ||
              !obj1.recurrence.dailyExclusive,
          ),
          dailies.filter((obj2) =>
            sortedMasterRundowns.every((obj1) => obj2.mRundownTemplateId !== obj1.mId),
          ),
        );

        const unusedRundowns = ret.filter(
          (rundown) => rundown.mType === memberTypes.RUNDOWN_TEMPLATE,
        );

        return unusedRundowns;
      };

      const unInitializedRundowns = getUnInitializedRundowns(
        masterRundowns ?? undefined,
        data,
      ).filter(Boolean);

      const start = selectedDates?.startDate;
      const notFoundDate = capitalize(getRelativeDate(start, 'dd. D MMM YY')) ?? 'this date';

      return (
        <>
          {data?.length > 0 ? (
            <KanbanLoader
              mId="rundowngroups"
              members={data}
              customRenderItem={renderItem}
              selectedDates={selectedDates}
            />
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '16px' }}>
              No rundowns found for {notFoundDate}
            </div>
          )}
          <Popover
            onClose={() => {
              setAnchorEl(null);
            }}
            anchorEl={anchorEl}
            position="right"
          >
            <ScopeProvider scope={kanbanScope} value="newRundown">
              <NewRundownSelector
                mId="rundowngroups"
                members={unInitializedRundowns}
                customRenderItem={renderMasterItem}
              />
            </ScopeProvider>
          </Popover>
          <Popover onClose={() => setAnchorE2(null)} anchorEl={anchorE2} position="right">
            <CreateRundown
              variant={memberTypes.RUNDOWN}
              onCancel={() => setAnchorE2(null)}
              onCreate={onCreate}
              defaultTitle={input ? input.mTitle : 'rundown title'}
            />
          </Popover>
        </>
      );
    }

    return null;
  },
);

GroupContainer.propTypes = {
  /** selected dates */
  selectedDates: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }),
  /** set the preview rundown */
  setPreviewRundown: PropTypes.func,
  /** Boolean that allows creation of new rundown */
  canCreateNewRundown: PropTypes.bool,
};

GroupContainer.defaultProps = {
  selectedDates: { startDate: null, endDate: null },
  setPreviewRundown: () => {},
  canCreateNewRundown: true,
};

export default memo(GroupContainer);
