import { useCallback, useContext, useRef, useState } from 'react';

import { ReactComponent as StoryIcon } from 'assets/icons/systemicons/story_off.svg';
import { Button } from 'components/buttons';
import LoadingIndicator from 'components/loadingIndicator';
import NotificationPopup, { notificationIdentifiers } from 'components/notificationPopup';
import USER_CONTEXT from 'contexts/UserContext';
import AccessControl from 'features/accessControl';
import OrderButton from 'features/orderForm/components/OrderButton';
import PublishButton from 'features/publishButton';
import useCreateStoryFromPitch from 'hooks/useCreateStoryFromPitch';
import useDinaNavigate from 'hooks/useDinaNavigate';
import { Story } from 'types';

import Dropdown from './Dropdown';
import Share from './Share';
import Title from './Title';

import {
  LoadingIconWrapper,
  Options,
  TitleDropdownWrapper,
  ToolbarContainer,
  VDivider,
} from './styled';

interface ToolbarProps {
  story: Story;
  isPitch: boolean;
  canUpdateStory: boolean;
  isDescriptionSettingsHidden: boolean;
}

function Toolbar({
  story,
  isPitch,
  canUpdateStory,
  isDescriptionSettingsHidden,
}: Readonly<ToolbarProps>) {
  const { mId: userId } = useContext(USER_CONTEXT);
  const { navigateTo } = useDinaNavigate();
  const createStoryFromPitch = useCreateStoryFromPitch();
  const [creatingStory, setCreatingStory] = useState(false);

  const notificationRef = useRef(null);
  const canViewStory = story?.__typename !== 'RestrictedErrorType';

  const createStory = useCallback(async () => {
    setCreatingStory(true);
    const storyId = (await createStoryFromPitch(
      story?.mId,
      userId,
      story?.mPublishingAt,
    )) as string;
    navigateTo('story', storyId);
    setCreatingStory(false);
  }, [navigateTo, createStoryFromPitch, story?.mId, story?.mPublishingAt, userId]);

  return (
    <ToolbarContainer>
      {canViewStory && <PublishButton member={story} canUpdatePublishing={canUpdateStory} />}
      <NotificationPopup
        position="bottom"
        anchor={notificationRef}
        id={notificationIdentifiers.TitleAndOption}
        title="The Story title can now be found on the toolbar!"
        text="To update the Story title, simply click on the text to edit it,
        just as easy as before."
      />
      <TitleDropdownWrapper ref={notificationRef}>
        <Title storyId={story.mId} storyTitle={story.mTitle} />
      </TitleDropdownWrapper>
      <Options>
        {canViewStory && canUpdateStory && (
          <>
            {!isPitch && (
              <>
                <OrderButton
                  dark
                  resourceId={story?.mId ?? ''}
                  resourceTitle={story?.mTitle ?? ''}
                  resourceType="story"
                  formTypes={['story']}
                />
                <VDivider />
              </>
            )}
            <Share storyId={story.mId} assignedMembers={story.mAssignedMembers} />
            <VDivider />
            <AccessControl
              mType={story?.mType ?? ''}
              storyId={story?.mId ?? ''}
              persistIconColor
              height={32}
            />
            {isPitch && (
              <Button
                variant="contained"
                usage="story"
                height={32}
                width={140}
                onClick={() => {
                  void (async () => {
                    await createStory();
                  })();
                }}
              >
                <LoadingIconWrapper>
                  {creatingStory ? <LoadingIndicator height={20} width={20} /> : <StoryIcon />}
                </LoadingIconWrapper>
                Create Story
              </Button>
            )}
            <Dropdown
              story={story}
              isPitch={isPitch}
              canUpdateStory={canUpdateStory}
              isDescriptionSettingsHidden={isDescriptionSettingsHidden}
            />
          </>
        )}
      </Options>
    </ToolbarContainer>
  );
}

export default Toolbar;
