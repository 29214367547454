import React, { useState } from 'react';
import { ListSubheader, List } from '@material-ui/core';

import Popover from 'components/popover';

import FolderMore from 'assets/icons/systemicons/folder_more.svg';
import FolderAdd from 'assets/icons/systemicons/folder_add.svg';

import FoldersSubMenu from './components/foldersSubMenu';
import TemplateSubMenu from './components/templatesSubMenu';
import CreateNew from '../linearEllipsisMenu/components/createNew';

import MenuItem from '../menuItem';
import useStyles from './foldersMenuList-styles';
import useToggleFolderPin from 'api/useToggleFolderPin';
import useUpdateTemplateOrder from 'api/useUpdateTemplateOrder';
import sortByTitle from 'utils/instance/templates/sortByTitle';

const MAX_UNPINNED_FOLDERS = 3;

const getTopLevelFolders = (sortedFolders = []) => {
  const topLevelFolders = sortedFolders.filter(({ mProperties }) => mProperties?.pinned);

  const fillerLength = MAX_UNPINNED_FOLDERS - topLevelFolders.length;
  if (fillerLength > 0) {
    const fillerArray = [];
    sortedFolders.slice(0, MAX_UNPINNED_FOLDERS).forEach((template) => {
      if (fillerArray.length === fillerLength) return;
      if (template?.mProperties?.pinned) return;
      fillerArray.push(template);
    });
    topLevelFolders.push(...fillerArray);
  }
  return topLevelFolders;
};

const FoldersMenuList = ({
  anchorEl,
  folders,
  canCreateNewTemplate,
  canDeleteTemplateFolder,
  canPinTemplateFolder,
  canDeleteTemplate,
  canReorderTemplates,
  canSetDefaultTemplate,
  writeLock,
  onDeleteFolder,
  closeMenu,
  onSaveTemplate,
  onSelectTemplate,
  onDeleteTemplate,
  onCreateFolder,
  defaultTemplateRefId,
  onSetDefaultTemplate,
  isContentLoaded,
}) => {
  const classes = useStyles();

  const [anchor, setAnchor] = useState(null);
  const [popoverComponent, setPopoverComponent] = useState(null);

  const handleClosePopover = () => {
    setAnchor(null);
    setPopoverComponent(null);
  };

  const handleSaveTemplate = (folderId, saveTitle, overwriteData) => {
    onSaveTemplate(folderId, saveTitle, overwriteData);
    handleClosePopover();
    closeMenu();
  };

  const handleTemplateSelect = (template) => {
    writeLock && onSelectTemplate(template);
    handleClosePopover();
    closeMenu();
  };

  const handleDeleteTemplate = (mId, mRefId, mContentKey) => {
    onDeleteTemplate(mId, mRefId, mContentKey);
    handleClosePopover();
  };

  const handleCreateFolder = (folderTitle) => {
    onCreateFolder(folderTitle);
    handleClosePopover();
  };

  const handleDeleteFolder = (mId, mRefId) => {
    onDeleteFolder(mId, mRefId);
    handleClosePopover();
  };

  const sortedFolders = sortByTitle(folders);

  const topLevelFolders = getTopLevelFolders(sortedFolders);

  const togglePin = useToggleFolderPin();
  const updateMOrder = useUpdateTemplateOrder();

  return (
    <>
      <List disablePadding classes={{ root: classes.menuItem }}>
        <ListSubheader classes={{ root: classes.listSubheader }}>INSTANCE TEMPLATES</ListSubheader>
        {topLevelFolders.map(({ mId, mRefId, mTitle, items, mProperties, mOrder }) => (
          <MenuItem
            key={mRefId}
            label={mTitle}
            data={items}
            showDeleteButton={canDeleteTemplateFolder}
            anchorEl={anchorEl}
            isMuted={!writeLock}
            onDeleteButtonClick={() => onDeleteFolder(mId, mRefId)}
            pinned={mProperties?.pinned}
            showPin={canPinTemplateFolder}
            canReorderTemplates={canReorderTemplates}
            togglePin={(newPinnedValue) => togglePin(mId, mRefId, mProperties, newPinnedValue)}
            onClick={() => {
              setAnchor(anchorEl);
              setPopoverComponent(
                <TemplateSubMenu
                  folderId={mRefId}
                  templates={items}
                  anchorEl={anchorEl}
                  onTemplateSelect={handleTemplateSelect}
                  onTemplateSave={handleSaveTemplate}
                  onDeleteTemplate={handleDeleteTemplate}
                  disabled={!writeLock}
                  canCreateNewTemplate={canCreateNewTemplate}
                  canDeleteTemplate={canDeleteTemplate}
                  canReorderTemplates={canReorderTemplates}
                  canSetDefaultTemplate={canSetDefaultTemplate}
                  mOrder={mOrder}
                  updateMOrder={(newMOrder) => updateMOrder(mId, mRefId, newMOrder)}
                  defaultTemplateRefId={defaultTemplateRefId}
                  onSetDefaultTemplate={onSetDefaultTemplate}
                  isContentLoaded={isContentLoaded}
                />,
              );
            }}
          />
        ))}
        {folders?.length > 0 && (
          <MenuItem
            data={folders}
            label="All Template Folders"
            image={FolderMore}
            onClick={() => {
              setAnchor(anchorEl);
              setPopoverComponent(
                <FoldersSubMenu
                  folders={sortedFolders}
                  anchorEl={anchorEl}
                  onTemplateSelect={handleTemplateSelect}
                  onTemplateSave={handleSaveTemplate}
                  onDeleteTemplate={handleDeleteTemplate}
                  onDeleteFolder={handleDeleteFolder}
                  disableChildren={!writeLock}
                  canCreateNewTemplate={canCreateNewTemplate}
                  canDeleteTemplate={canDeleteTemplate}
                  canDeleteTemplateFolder={canDeleteTemplateFolder}
                  canPinTemplateFolder={canPinTemplateFolder}
                  canReorderTemplates={canReorderTemplates}
                  canSetDefaultTemplate={canSetDefaultTemplate}
                  togglePin={togglePin}
                  updateMOrder={updateMOrder}
                  defaultTemplateRefId={defaultTemplateRefId}
                  onSetDefaultTemplate={onSetDefaultTemplate}
                  isContentLoaded={isContentLoaded}
                />,
              );
            }}
          />
        )}
        {canCreateNewTemplate && (
          <MenuItem
            label="Create New Template Folder"
            image={FolderAdd}
            onClick={() => {
              setAnchor(anchorEl);
              setPopoverComponent(
                <CreateNew
                  onCancel={handleClosePopover}
                  onOk={handleCreateFolder}
                  data={folders}
                />,
              );
            }}
            showSecondaryItem={false}
          />
        )}
        <Popover
          noMargin
          anchorEl={anchor}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={handleClosePopover}
        >
          {popoverComponent}
        </Popover>
      </List>
    </>
  );
};

FoldersMenuList.propTypes = {};

export default FoldersMenuList;
