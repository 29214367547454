import styled from '@emotion/styled';
import { FormControlLabel } from '@material-ui/core';

import Text from 'components/text/Text';
import { HStack, VStack } from 'layouts/box/Box';

import { ToolbarIcons } from '../command-constants';

export const StyledFormControl = styled(FormControlLabel)`
  margin-left: 0;
  .MuiCheckbox-root {
    width: 22px;
    height: 22px;
    margin-right: 6px;
  }
  .MuiFormControlLabel-label {
    font-size: 13px;
  }
`;

export const Wrapper = styled(VStack)`
  padding-left: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  overflow: unset;
`;

export const FirstRow = styled(HStack)`
  width: 100%;
  min-height: 30px;
  justify-content: space-between;
  overflow: auto;
`;

export const SecondRow = styled(HStack)`
  width: 100%;
  gap: 8px;
  justify-content: start;
  align-items: start;

  .member-wrapper {
    height: 24px;
  }
  .avatar {
    width: 20px;
    height: 20px;
    min-width: 20px;
  }
  .member-details {
    font-size: 12px;
  }
`;

export const StyledText = styled(Text)`
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-shrink: 0;
  &:hover {
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
  &:hover svg path {
    fill-opacity: 1;
  }
`;

export const ClearAll = styled(ToolbarIcons.ClearAll)`
  height: 18px;
  width: 18px;
  cursor: pointer;
  &:hover path {
    fill-opacity: 1;
  }
`;

export const StyledCaret = styled(ToolbarIcons.Caret)`
  margin-left: 4px;
  margin-top: 2px;
`;

export const StyledClear = styled(ToolbarIcons.Clear)`
  margin-left: 4px;
  margin-top: 2px;
  width: 18px;
  height: 18px;
`;

export const ColumnHeader = styled(Text)`
  margin-bottom: 8px !important;
`;

export const ToolbarItemColumn = styled(VStack)`
  padding: 4px;
  align-items: start;
  &.disabled * {
    opacity: 0.6;
    cursor: default !important;
  }
`;

export const ToolbarItemLabel = styled(Text)`
  margin-left: 8px !important;
  cursor: pointer;
`;

export const StyledRightSide = styled(HStack)`
  .MuiFormControlLabel-root {
    margin-right: 0;
  }
`;

export const ToolbarItem = styled(HStack)`
  padding: 8px;
  gap: 12px;
  justify-content: start !important;
  align-items: start;
`;

export const MenuOption = styled(HStack)`
  a {
    display: flex;
    gap: 8px;
    align-items: center;
    text-decoration: none;
  }
  padding: 3px;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
    background: rgba(255, 255, 255, 0.05);
    svg path {
      fill-opacity: 1;
    }
  }
`;
