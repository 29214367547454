import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMembersDialog } from 'store';
import ContentCard from 'screens/main/components/rightArea/sidebar/popup-cards';
import Scrollbar from 'screens/main/components/scrollbar';
import DetailList from '../../../detailList';
import AddDescription from '../../../addDescription';
import AddItem from '../../../addItem';
import useStyles from './info-styles';

const InfoView = ({
  description,
  updateDescription,
  teams,
  addTeamsToDepartment,
  people,
  addPeopleToDepartment,
  containerRef,
}) => {
  const classes = useStyles();
  const [cardContent, setCardContent] = useState(null);

  const [, setGetMembers] = useMembersDialog();

  const doGetMembers = (variant) => {
    const isUserVariant = variant === 'user';
    setGetMembers({
      variant,
      open: true,
      startWith: isUserVariant ? [...people] : [...teams],
      dialogTitle: `Add ${isUserVariant ? 'members' : 'teams'}`,
      removeTooltipText: `Remove ${variant} from department`,
      subHeader: `${isUserVariant ? 'members' : 'teams'} part of department:`,
      onOk: (newMembers) => {
        if (isUserVariant) {
          addPeopleToDepartment(newMembers);
        } else {
          addTeamsToDepartment(newMembers);
        }
      },
    });
  };

  const onListItemClick = (item) => {
    setCardContent(item);
  };

  return (
    <div className={classes.root}>
      <Scrollbar>
        <AddDescription
          addItemTitle="Add Description..."
          onUpdate={updateDescription}
          helperText="Short description of what the Department does"
          {...{ description }}
        />

        <DetailList
          listTitle="Teams"
          members={teams}
          avatarVariant="team"
          onItemClick={onListItemClick}
        />

        <AddItem title="Teams..." onClick={() => doGetMembers('team')} />
        <DetailList
          listTitle="People"
          members={people}
          avatarVariant="user"
          onItemClick={onListItemClick}
        />

        <AddItem title="People..." onClick={() => doGetMembers('user')} />
        <ContentCard item={cardContent} containerRef={containerRef} />
      </Scrollbar>
    </div>
  );
};

InfoView.propTypes = {
  /** Defines the for the department  */
  description: PropTypes.string,
  /** Callback to be invoked on description change  */
  updateDescription: PropTypes.func,
  /** Defines the list of teams that belong to this department  */
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      mId: PropTypes.string,
      mTitle: PropTypes.string,
      mAvatarUrl: PropTypes.string,
      mCreatedAt: PropTypes.string,
      description: PropTypes.string,
      handleDrop: PropTypes.func,
    }),
  ),
  /** Callback to be invoked on addTeamsToDepartment dialog confirmation  */
  addTeamsToDepartment: PropTypes.func,
  /** Defines the list of people that belong to this department  */
  people: PropTypes.arrayOf(
    PropTypes.shape({
      mId: PropTypes.string,
      mTitle: PropTypes.string,
      mAvatarUrl: PropTypes.string,
      mCreatedAt: PropTypes.string,
      description: PropTypes.string,
      handleDrop: PropTypes.func,
    }),
  ),
  /** Callback to be invoked on addPeopleToDepartment dialog confirmation  */
  addPeopleToDepartment: PropTypes.func,
};

InfoView.defaultProps = {
  description: '',
  updateDescription: () => {},
  teams: [],
  addTeamsToDepartment: () => {},
  people: [],
  addPeopleToDepartment: () => {},
};

export default InfoView;
