import { atom, useAtom, useSetAtom } from 'jotai';
import { focusAtom } from 'jotai-optics';

/**
 * Based on logging a saved search in runtime
 */
interface SavedSearch {
  id: string;
  isPinned: boolean;
  label: string;
  model: Record<string, string>;
}

interface SideBar {
  leftHidden: boolean;
  rightHidden: boolean;
  rightExpanded: boolean;
  leftSelection: string;
  rightSelection: string;
  contactTab: number;
  openGroups: Record<string, { open: boolean }>;
  leftSidebarSavedSearches: SavedSearch[];
  rightSidebarSavedSearches: SavedSearch[];
}

const defaultSidebarValues: SideBar = {
  leftHidden: true,
  rightHidden: true,
  rightExpanded: false,
  leftSelection: '',
  rightSelection: '',
  contactTab: 0,
  openGroups: {},
  leftSidebarSavedSearches: [],
  rightSidebarSavedSearches: [],
};

const sidebarsData = localStorage.getItem('sidebars');
const sidebarStorageAtom = atom<SideBar>(
  sidebarsData ? (JSON.parse(sidebarsData) as SideBar) : defaultSidebarValues,
);

export const sidebarAtom = atom<SideBar, [SideBar], void>(
  (get) => get(sidebarStorageAtom),
  (_get, set, nextValue) => {
    set(sidebarStorageAtom, nextValue);
    localStorage.setItem('sidebars', JSON.stringify(nextValue));
  },
);
export const useSidebar = () => useAtom(sidebarAtom);

export const leftHiddenAtom = focusAtom(sidebarAtom, (optic) => optic.prop('leftHidden'));
export const useLeftHidden = () => useAtom(leftHiddenAtom);

export const rightHiddenAtom = focusAtom(sidebarAtom, (optic) => optic.prop('rightHidden'));
export const useRightHidden = () => useAtom(rightHiddenAtom);

export const leftSelectionAtom = focusAtom(sidebarAtom, (optic) => optic.prop('leftSelection'));
export const useLeftSelection = () => useAtom(leftSelectionAtom);

export const rightSelectionAtom = focusAtom(sidebarAtom, (optic) => optic.prop('rightSelection'));
export const useRightSelection = () => useAtom(rightSelectionAtom);

export const openGroupsAtom = focusAtom(sidebarAtom, (optic) => optic.prop('openGroups'));
export const useOpenGroups = () => useAtom(openGroupsAtom);

export const contactsTabAtom = focusAtom(sidebarAtom, (optic) => optic.prop('contactTab'));
export const useContactsTab = () => useAtom(contactsTabAtom);

export const leftSidebarSavedSearchesAtom = focusAtom(sidebarAtom, (optic) =>
  optic.prop('leftSidebarSavedSearches'),
);
export const useLeftSidebarSavedSearches = () => useAtom(leftSidebarSavedSearchesAtom);

export const rightSidebarSavedSearchesAtom = focusAtom(sidebarAtom, (optic) =>
  optic.prop('rightSidebarSavedSearches'),
);
export const useRightSidebarSavedSearches = () => useAtom(rightSidebarSavedSearchesAtom);

const setToggleBothSidebarsAtom = atom(null, (get, set) => {
  const leftHidden = get(leftHiddenAtom);
  const rightHidden = get(rightHiddenAtom);
  const leftSelection = get(leftSelectionAtom);
  const rightSelection = get(rightSelectionAtom);

  const bothHidden = leftHidden && rightHidden;

  if (!bothHidden) {
    if (!leftHidden) set(leftHiddenAtom, (prev) => !prev);
    if (!rightHidden) set(rightHiddenAtom, (prev) => !prev);
  }

  if (bothHidden) {
    set(leftSelectionAtom, leftSelection ?? 'user');
    set(rightSelectionAtom, rightSelection ?? 'contacts');
    set(leftHiddenAtom, (prev) => !prev);
    set(rightHiddenAtom, (prev) => !prev);
  }
});
export const useSetToggleBothSidebars = () => useSetAtom(setToggleBothSidebarsAtom);

/* Sidebar Date Picker Atom */

export interface AtomDateRange {
  startDate: Date | null;
  endDate: Date | null;
}
const datePickerAtom = atom<AtomDateRange>({ startDate: null, endDate: new Date() });

const sidebarDatePickerAtom = atom<AtomDateRange, [AtomDateRange], void>(
  (get) => {
    const { startDate, endDate } = get(datePickerAtom);

    const start = startDate ? new Date(startDate) : null;
    start?.setHours(0, 0, 0, 0);

    const end = endDate ? new Date(endDate) : null;
    end?.setHours(23, 59, 59, 500);

    return { startDate: start, endDate: end };
  },
  (_get, set, updatedValue) => {
    set(datePickerAtom, updatedValue);
  },
);

export const useSidebarDatePickerAtom = () => useAtom(sidebarDatePickerAtom);

/* Sidebar sub tabs */

export const scheduleTypes = {
  ALL: 'all',
  SCHEDULED: 'scheduled',
  UNSCHEDULED: 'unscheduled',
  ARCHIVED: 'archived',
};

export const subtabs = Object.values(Object.freeze(scheduleTypes));

const sidebarSelectedSubtabAtom = atom(0);
export const useSidebarSelectedSubtab = () => useAtom(sidebarSelectedSubtabAtom);
