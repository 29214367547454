import { AvatarVariant } from 'components/avatar/Avatar';
import conversationTypes from 'utils/constants/conversationTypes';

const getAvatarVariant = (convoType: string): AvatarVariant => {
  switch (convoType) {
    case conversationTypes.TEAM:
      return 'team';
    case conversationTypes.DEPARTMENT:
      return 'department';
    default:
      return 'user';
  }
};

export default getAvatarVariant;
