import { useQuery } from '@apollo/client';

import GET_RUNDOWNS from 'graphql/queries/getRundowns';
import { Rundown } from 'types/graphqlTypes';

type ReturnType = {
  getRundowns: Rundown[];
};

type InputType = {
  mId: string;
};

const useGetMasterRundowns = () => {
  const { data, error, loading } = useQuery<ReturnType, InputType>(GET_RUNDOWNS, {
    variables: { mId: '' },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
  });

  return {
    data: data?.getRundowns,
    error,
    loading,
  };
};

export default useGetMasterRundowns;
