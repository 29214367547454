import React, { memo, useCallback, useEffect, useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import uuidv1 from 'uuid/v1';
import { Transforms } from 'slate';
import { useSelected, useSlate, ReactEditor } from 'slate-react';

import fieldEnums from 'utils/constants/fieldEnums';
import defaultAudioThumbnail from 'assets/images/default/defaultAudioThumbnail.png';
import defaultThumbnail from 'assets/images/default/defaultThumbnail.png';
import useGetFieldsForBlock from 'hooks/useGetFieldsForBlock';
import Dialog from 'components/dialog';
import CreateNew from 'components/createNew';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import SelectedElement from 'components/editor/components/selectedElement';
import { actionTypes, elementTypes } from 'components/editor/constants/types';
import {
  checkIfDragDisabled,
  generateName,
  removeBlock,
  updateBlock,
  refreshSelection,
} from 'components/editor/utils';
import { updateSlateData } from 'components/editor/components/placeholder/utils';
import variants from 'utils/instance/variants';
import generatePlaceholderProps from 'utils/generatePlaceholderProps';
import notifyChange from 'components/editor/utils/notifyChange';
import useGetAsset from 'hooks/useGetAsset';
import useGetSignedUrl from 'hooks/useGetSignedUrl';
import useStorageImage from 'hooks/useStorageImage';
import { getThumbnailKey } from 'utils/mediaKey';
import useFileUpload from 'hooks/useFileUpload';
import generateVideoThumbnail from 'utils/generateVideoThumbnail';
import useUploadProgress from 'hooks/useUploadProgress';
import MediaCard from 'components/mediaCard';
import useChangeCollapse from 'components/editor/hooks/useChangeCollapse';

import AudioVideoBase from '../audioVideoBase';
import MediaViewer from '../mediaViewer';
import MediaDropZone from './mediaDropZone';
import DragAndDrop from '../dragAndDrop';
import { openPreviewInProvider } from 'utils/openAssetInMimir';
import useOpenAssetInMimir from 'hooks/useOpenAssetInMimir';

const { select, setNodes } = Transforms;
const maxThumbnails = 4;

const mimeTypes = {
  [elementTypes.AUDIO]: ['audio/mp3'],
  [elementTypes.VIDEO]: ['video/mp4'],
};

const assetType = {
  audio: elementTypes.AUDIO,
  video: elementTypes.VIDEO,
};

const AudioVideo = ({ attributes, children, element, direction }) => {
  const { type: elementType, data } = element;
  const isVideoElement = elementType === elementTypes.VIDEO;

  const [getFieldsForBlock] = useGetFieldsForBlock();
  const fileRef = useRef();
  const editor = useSlate();
  const {
    containerRef,
    getPlaceholderConfig,
    update,
    isAllowed,
    variant,
    withSignedUrl,
    formsForThisPlatform,
    platformId,
  } = useEditorContext();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [asset, setAsset] = useState(null);
  const [uploadProgress, setUploadStatus, isUploading, initiateUploading] = useUploadProgress();
  const [showUploadProgress, setShowUploadProgress] = useState(false);

  const field = getFieldsForBlock(fieldEnums.CUSTOM_SOURCES, { options: [] });
  const sourceOptions = field?.options || [];

  const [onChangeCollapse] = useChangeCollapse(element);
  const openAssetInProvider = useOpenAssetInMimir();
  const placeholderConfigs = (dialogOpen && getPlaceholderConfig()) || {};

  const {
    src,
    showThumbnail,
    thumbnails,
    subtitles,
    cache,
    thumbnailUrl,
    mediaThumbnail,
    proxy,
    itemDuration,
    metadata,
    collapsed = false,
    mId,
    mRefId,
    mTitle: assetTitle = '',
  } = data;

  const isSelected = useSelected(element);
  const showHighlight = isSelected;
  const {
    showMetadata,
    description,
    title,
    source,
    isCoverphoto = false,
    editor: editors,
  } = metadata || {};

  const [localThumbnails, setLocalThumbnails] = useState(thumbnails || []);

  const [assetData] = useGetAsset(mId, mRefId, !mId || !mRefId);
  const cacheRef = useRef(cache || null);

  const updateAssetInfo = useCallback(() => {
    if (!data || !assetData) return;
    const updatedData = updateSlateData(data, assetData);
    const path = ReactEditor.findPath(editor, element);
    setNodes(editor, { ...updatedData }, { at: path });
  }, [assetData]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(updateAssetInfo, [assetData]);

  const provider =
    assetData?.mMetaData?.find(({ key }) => key === 'provider')?.value ?? data.mProvider;

  useEffect(() => {
    if (!proxy) return;

    cacheRef.current = proxy;
  }, [proxy]);

  const forceEditorFocus = useCallback(() => {
    ReactEditor.focus(editor);
  }, [editor]);

  const key = mId && mRefId && getThumbnailKey(mId, mRefId);
  const skipVideoLoad = !!cacheRef.current || !src;

  const { url: signedUrl, loading } = useGetSignedUrl(src, skipVideoLoad);
  const loadThumbnail = !src || key;
  const {
    data: thumbnailData,
    error: storageError,
    loading: storageLoading,
  } = useStorageImage(key, !loadThumbnail);

  const s3ThumbUrl = storageError && !storageLoading ? defaultThumbnail : thumbnailData;
  const videoSrc = cacheRef.current || proxy || (src && signedUrl);
  const mediaSrc = videoSrc ?? s3ThumbUrl;

  const removeCacheFromSlateData = useCallback(() => {
    if (cache) {
      const path = ReactEditor.findPath(editor, element);

      Transforms.setNodes(editor, { data: { ...element.data, cache: undefined } }, { at: path });
    }
  }, [cache, editor, element]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(removeCacheFromSlateData, []);

  /** dialog */
  const selectionCacheRef = useRef(editor.selection);

  const closeDialog = useCallback(() => setDialogOpen(false), []);
  const openDialog = useCallback(() => setDialogOpen(true), []);

  const addThumbnails = useCallback(
    async (newThumbnails, updatedLocalThumbnails) => {
      if (Array.isArray(newThumbnails)) {
        setLocalThumbnails(updatedLocalThumbnails);
        const thumbs = thumbnails || [];
        const updatedData = {
          ...data,
          thumbnails: [...thumbs, ...newThumbnails],
        };
        updateBlock(editor, element, updatedData, update);
        notifyChange(editor, update);
      }
    },
    [data, editor, element, thumbnails, update],
  );

  const onFileUpload = useCallback(
    async (file, fileUrl) => {
      initiateUploading(1);
      const fileName = generateName(file.type);
      const videoThumbnail = file.type.match('video')
        ? await generateVideoThumbnail(file, fileUrl)
        : null;
      cacheRef.current = videoThumbnail || defaultAudioThumbnail;

      if (withSignedUrl) {
        fileRef.current = file;
        setShowUploadProgress(true);
      }

      let updatedData = withSignedUrl
        ? {
            ...element.data,
            title: fileName,
          }
        : {};

      if (!withSignedUrl) {
        const result = await update({
          type: actionTypes.ASSET_INSERT,
          payload: {
            document: editor.children,
            file,
            fileName,
            uploadProgressCallback: setUploadStatus,
          },
        });

        updatedData = {
          ...data,
          ...result,
          title: fileName,
        };
      }

      updateBlock(editor, element, updatedData, update);
      notifyChange(editor, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, withSignedUrl, element, editor, update, setUploadStatus],
  );

  const uploadFinishedCallback = useCallback(() => setShowUploadProgress(false), []);

  const updateEditorOnAssetUpload = useCallback(
    (result) => {
      const updatedData = {
        ...element.data,
        ...result,
      };
      const path = ReactEditor.findPath(editor, element);
      setNodes(editor, { data: updatedData }, { at: path });
      notifyChange(editor, update);
      cacheRef.current = null;
    },
    [element, editor, update],
  );

  const updateEditorOnSubtitleUpload = useCallback(
    (result, fileName) => {
      const existingSubtitles = subtitles || [];
      const updatedData = {
        ...element.data,
        subtitles: [...existingSubtitles, { ...result, title: fileName }],
      };
      updateBlock(editor, element, updatedData, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor, element, subtitles, update],
  );

  const updateEditorOnUpload = useCallback(
    (result, file) => {
      const { name, type: fileType } = file;
      const [itemType] = fileType ? fileType.split('/') : '';
      if (['video', 'audio'].includes(itemType)) return updateEditorOnAssetUpload(result);
      return updateEditorOnSubtitleUpload(result, name);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateEditorOnAssetUpload, updateEditorOnSubtitleUpload],
  );

  const uploadFile = useFileUpload(mimeTypes[elementType], onFileUpload);

  const uploadSubtitle = useCallback(
    async (file) => {
      initiateUploading(1);
      const fileName = generateName(file.type);
      if (withSignedUrl) {
        fileRef.current = file;
        setShowUploadProgress(true);
      } else {
        const result = await update({
          type: actionTypes.ASSET_INSERT,
          payload: {
            document: editor.children,
            file,
            fileName,
            uploadProgressCallback: setUploadStatus,
          },
        });
        updateEditorOnSubtitleUpload(result, file.name);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor, update, setUploadStatus, updateEditorOnSubtitleUpload],
  );

  const updateSubtitles = useCallback(
    (updatedSubtitles) => {
      const updatedData = {
        ...element.data,
        subtitles: updatedSubtitles,
      };
      updateBlock(editor, element, updatedData, update);
    },
    [editor, element, update],
  );

  const handleOpenDialog = useCallback(
    (event) => {
      event.preventDefault();

      selectionCacheRef.current = editor.selection;
      openDialog();
    },
    [openDialog, editor],
  );

  const handleCreatePlaceholder = useCallback(
    async (mTitle) => {
      closeDialog();

      try {
        if (selectionCacheRef.current) select(editor, selectionCacheRef.current);

        const result = await update({
          type: actionTypes.CREATE_PLACEHOLDER,
          payload: {
            document: editor.children,
            title: mTitle,
            itemType: elementType,
            exportFormat: metadata?.exportFormat,
          },
        });

        const updatedData = {
          ...result,
          showThumbnail,
          thumbnails: thumbnails || [],
          mTitle,
          itemDuration: 0,
          itemType: elementType,
          mediaType: `${elementType}/placeholder`,
          itemId: uuidv1(),
          metadata: metadata || {},
          subtitles: subtitles || [],
        };

        const path = ReactEditor.findPath(editor, element);
        setNodes(editor, { data: updatedData, type: elementTypes.PLACEHOLDER }, { at: path });
        notifyChange(editor, update);
      } catch (error) {}
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [closeDialog, editor, element, metadata, showThumbnail, subtitles, thumbnails, update],
  );

  const handleCancel = useCallback(() => {
    if (selectionCacheRef.current) select(editor, selectionCacheRef.current);
    closeDialog();
  }, [closeDialog, editor]);

  /** Remove Video function to be implemented */
  const removeMedia = useCallback(
    (event) => {
      event.preventDefault();
      const updatedData = {
        showThumbnail: data.showThumbnail,
      };
      if (data.showThumbnail) updatedData.thumbnails = thumbnails || [];

      updateBlock(editor, element, updatedData, update);
      cacheRef.current = null;
      fileRef.current = null;
      notifyChange(editor, update);
    },
    [data.showThumbnail, editor, element, thumbnails, update],
  );

  const removeThumbnail = useCallback(
    (newThumbnails, newLocalThumbnails) => {
      const updatedData = {
        ...data,
        thumbnails: newThumbnails,
      };
      setLocalThumbnails(newLocalThumbnails);

      updateBlock(editor, element, updatedData, update);
    },
    [data, editor, element, update],
  );

  const updateMetadata = useCallback(
    (newValue, metaPropName) => {
      const oldMetaData = metadata || {};
      const updatedData = {
        ...data,
        metadata: {
          ...oldMetaData,
          [metaPropName]: newValue,
        },
      };
      const allAttributes =
        metaPropName === 'isCoverphoto' && newValue === true
          ? { key: 'isCoverphoto', value: false }
          : null;
      updateBlock(editor, element, updatedData, update, undefined, allAttributes);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, element, metadata],
  );

  const onMenuSelect = useCallback(
    ({ action }) => {
      if (action === 'delete-block') removeBlock(editor, element, update);
      if (action === 'show-metadata') updateMetadata(!showMetadata, 'showMetadata');
    },
    [editor, element, showMetadata, update, updateMetadata],
  );

  const titleProps = useMemo(
    () => ({
      value: title || '',
      placeholder: 'Add Title',
      description: `Add a Title to the ${elementType}`,
      propName: 'title',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [title],
  );

  const sourceProps = useMemo(
    () => ({
      value: source || [],
      placeholder: 'Start typing to find Source',
      options: sourceOptions,
      propName: 'source',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [source],
  );

  const inputProps = useMemo(
    () => ({
      value: editors || '',
      placeholder: 'Add Editor...',
      description: 'Who is the editor?',
      propName: 'editor',
    }),
    [editors],
  );

  const textareaProps = useMemo(
    () => ({
      value: description || '',
      placeholder: 'Add Description',
      description: `Describe the ${elementType}`,
      propName: 'description',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [description],
  );

  const onBlur = useCallback(() => {
    refreshSelection(editor, element);
  }, [editor, element]);

  const onPreviewMediaCard = useCallback(
    (e) => {
      if (openPreviewInProvider(provider)) {
        return openAssetInProvider({
          data: { assets: [{ ...assetData, mProvider: provider }] },
        });
      }

      e.stopPropagation();
      setAsset({
        type: assetType[elementType],
        filename: `${elementType} File`,
        previewUri: src && loading ? defaultThumbnail : mediaSrc,
        mId: !src ? mId : null,
        mRefId: !src ? mRefId : null,
      });
    },
    [assetData],
  );

  const renderContentWithoutBlock = useMemo(
    () => (
      <SelectedElement {...{ element }}>
        <div {...attributes}>
          {children}
          <div style={{ padding: '8px' }} contentEditable={false}>
            <MediaViewer
              handleClose={() => setAsset(null)}
              open={!!asset}
              asset={asset}
              accessToken=""
            />
            <MediaCard
              showHighlight={showHighlight}
              key={mediaSrc}
              onFocus={forceEditorFocus}
              onAssetSet={onPreviewMediaCard}
              thumbnailSrc={assetData?.mThumbnailUrl ?? s3ThumbUrl}
              src={loading ? defaultThumbnail : mediaSrc}
              type={assetType[elementType]}
              filename={`${elementType} File`}
              byline="Media Details"
            />
          </div>
        </div>
      </SelectedElement>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      asset,
      attributes,
      children,
      element,
      forceEditorFocus,
      loading,
      mId,
      mRefId,
      showHighlight,
      src,
      mediaSrc,
      onPreviewMediaCard,
    ],
  );

  if (!isAllowed) return renderContentWithoutBlock;

  const isTwitterVariant = variant === variants.TWITTER;
  const isYoutubeVariant = variant === variants.YOUTUBE;
  const isCmsVariant = variant === variants.CMS;
  const blockForm = formsForThisPlatform?.[elementType];
  const exportFormatField = (blockForm?.mProperties?.fields ?? []).find(
    (f) => f.id === 'DEFAULT#exportFormat',
  );
  const isSubtitleAllowed =
    variant !== variants.INSTAGRAM && variant !== variants.TWITTER && variant !== variants.TIKTOK;

  const isAddingThumbnailAllowed = isTwitterVariant
    ? false
    : !thumbnails || thumbnails.length < maxThumbnails;

  return (
    <div {...attributes} onBlur={onBlur}>
      <DragAndDrop element={element} isDragDisabled={checkIfDragDisabled(variant)}>
        <MediaDropZone
          {...{ element, variant }}
          canAddThumbnail={isAddingThumbnailAllowed}
          mergeNewThumbnail={(newThumbnail) => {
            if (isYoutubeVariant) setLocalThumbnails([newThumbnail]);
            else setLocalThumbnails([...localThumbnails, newThumbnail]);
          }}
        >
          {children}
          <AudioVideoBase
            blockForm={blockForm}
            createPlaceholder={handleOpenDialog}
            hideEllipsisButton={isYoutubeVariant}
            mediaThumbnail={thumbnailUrl || mediaThumbnail || assetData?.mThumbnailUrl}
            mediaDuration={itemDuration}
            thumbnails={localThumbnails}
            collapsed={collapsed}
            collapsedContent={src ? title || 'No title available' : ''}
            updateCollapsed={onChangeCollapse}
            onMenuSelect={onMenuSelect}
            removeMedia={removeMedia}
            subtitles={subtitles}
            uploadProgress={uploadProgress}
            isUploading={isUploading}
            direction={direction}
            assetTitle={assetTitle}
            {...{
              mediaSrc,
              platformId,
              uploadFile,
              addThumbnails,
              removeThumbnail,
              showThumbnail,
              maxThumbnails,
              showMetadata,
              sourceProps,
              textareaProps,
              titleProps,
              inputProps,
              element,
              isYoutubeVariant,
              isCmsVariant,
              metadata,
              updateMetadata,
              updateSubtitles,
              uploadSubtitle,
              isCoverphoto,
              mId,
              mRefId,
              isSubtitleAllowed,
              cacheRef,
              fileRef,
              updateEditorOnUpload,
              withSignedUrl,
              showUploadProgress,
              uploadFinishedCallback,
              elementType,
              isVideoElement,
            }}
          />
        </MediaDropZone>
      </DragAndDrop>
      {dialogOpen && (
        <Dialog container={containerRef.current} open={dialogOpen} onClose={handleCancel}>
          <CreateNew
            exportFormatField={exportFormatField}
            updateMetadata={updateMetadata}
            metadata={metadata}
            variant="placeholder"
            onCreate={handleCreatePlaceholder}
            onCancel={handleCancel}
            placeholderNameConfigs={generatePlaceholderProps(placeholderConfigs)}
          />
        </Dialog>
      )}
    </div>
  );
};

AudioVideo.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({
    type: PropTypes.string,
    data: {
      src: PropTypes.string,
      showThumbnail: PropTypes.bool,
      thumbnails: PropTypes.arrayOf(
        PropTypes.shape({
          mId: PropTypes.string,
          mRefId: PropTypes.string,
        }),
      ),
      subtitles: PropTypes.arrayOf(
        PropTypes.shape({
          mId: PropTypes.string,
          mRefId: PropTypes.string,
        }),
      ),
      cache: PropTypes.string,
      thumbnailUrl: PropTypes.string,
      mediaThumbnail: PropTypes.string,
      proxy: PropTypes.string,
      itemDuration: PropTypes.number,
      metadata: PropTypes.shape({
        exportFormat: PropTypes.bool,
      }),
      collapsed: PropTypes.bool,
      mId: PropTypes.string,
      mRefId: PropTypes.string,
      mTitle: PropTypes.string,
    },
  }),
  direction: PropTypes.string,
};

AudioVideo.defaultProps = {
  attributes: {},
  children: null,
  element: {
    type: elementTypes.VIDEO,
    data: {
      src: '',
      showThumbnail: false,
      thumbnails: [],
      subtitles: [],
      cache: '',
      thumbnailUrl: '',
      mediaThumbnail: '',
      proxy: '',
      itemDuration: 0,
      metadata: {
        exportFormat: false,
      },
      collapsed: false,
      mId: '',
      mRefId: '',
      mTitle: '',
    },
    children: [],
  },
  direction: 'auto',
};

export default memo(AudioVideo);
