import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import Tooltip from 'components/tooltip';
import TourPopup from 'components/tourPopup';
import memberTypes from 'graphql/memberTypes';
import useCheckUserRight, { useCanSeeGridDecks } from 'hooks/useCheckUserRight';
import useSettingsValue from 'hooks/useSettingsValue';
import useTabs from 'hooks/useTabs';
import { tours, useCurrentTabValue, useFeedTickerVisible, useExternalUrls } from 'store';
import { ReactComponent as ChatOff } from 'assets/icons/systemicons/ContainerRight_Navbar/story_rundown_chat_off.svg';
import { ReactComponent as ChatOn } from 'assets/icons/systemicons/ContainerRight_Navbar/story_rundown_chat_on.svg';

import { useHasUpdatedSubscription } from 'features/dailyNote';
import SelectionTypes from '../rightArea/selectionTypes';

import { leftMenuItems, rightMenuItems, getHtmlPanels } from './utils/menuItems';
import { navbarPosition } from './utils/navbarPosition';

import {
  ButtonPlaceholder,
  Container,
  StyledTab,
  StyledTabs,
  TabsWrapper,
  TickerButton,
  TickerIcon,
} from './verticalNavbar-styled';

const VerticalNavbar = ({ toggleSidebar, selectionChanged, selectionType, hidden, position }) => {
  const [checkUserRight] = useCheckUserRight();
  const { canAccessGridDecks } = useCanSeeGridDecks();
  const [getSettingsValue] = useSettingsValue();
  const [isTickerVisible, setTickerVisible] = useFeedTickerVisible();
  const [tabIndex, setTabIndex] = useState(0);
  const notificationRef = useRef();
  const feedsTickerRef = useRef();
  const [externalUrls] = useExternalUrls();
  const [hasUpdatedSub] = useHasUpdatedSubscription();
  const tour = position === navbarPosition.LEFT ? tours.LeftTabTour : tours.RightTabTour;
  const tabRefs = useRef({});

  const currentTab = useCurrentTabValue();
  const { resetTabNotification } = useTabs();
  const { type, notificationCount } = currentTab ?? {};

  const getTitleOfChatTooltip = () => {
    switch (type) {
      case memberTypes.STORY:
        return 'Story chat';
      case memberTypes.RUNDOWN:
        return 'Rundown chat';
      case memberTypes.PITCH:
        return 'Pitch chat';
      case memberTypes.SPACE:
        return 'Space chat';
      default:
        return 'Chat';
    }
  };

  const enabledItems = useMemo(() => {
    if (position === navbarPosition.LEFT) {
      return leftMenuItems.filter((item) => {
        switch (item.selectionType) {
          case 'search':
            return canAccessGridDecks;
          case memberTypes.INSTANCE:
            return checkUserRight('search', 'all-instances');
          case memberTypes.STORY:
            return checkUserRight('search', 'all-stories');
          case memberTypes.PITCH:
            return checkUserRight('search', 'all-pitches');
          default:
            return true;
        }
      });
    }

    if (position === navbarPosition.RIGHT) {
      let ret = [];

      if (checkUserRight('chat', 'access')) {
        ret.push({
          title: getTitleOfChatTooltip(),
          selectionType: SelectionTypes.CHAT,
          SelectedComponent: ChatOn,
          UnselectedComponent: ChatOff,
          divider: true,
        });
      }

      const otherItems = rightMenuItems.filter((item) =>
        checkUserRight('folder', `show-${item.title.toLowerCase()}`),
      );

      ret.push(...otherItems);

      if (getSettingsValue('mos.usemoshtmlautomationpanel') !== 'true') {
        ret = ret.filter((item) => item.selectionType !== 'mos');
      }

      const htmlPanels = getHtmlPanels(externalUrls);
      return [...htmlPanels, ...ret];
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position, type, externalUrls, canAccessGridDecks]);

  useEffect(() => {
    setTabIndex(enabledItems.findIndex((item) => item.selectionType === selectionType) ?? 0);
  }, [enabledItems, selectionType, externalUrls]);

  const handleTabChange = useCallback(
    (_event, index) => {
      if (enabledItems[index].selectionType === SelectionTypes.CHAT && currentTab) {
        resetTabNotification(currentTab);
      }
      if (tabIndex === index) {
        toggleSidebar();
      } else {
        setTabIndex(index);
        selectionChanged(enabledItems[index].selectionType);
        hidden && toggleSidebar();
      }
    },
    [enabledItems, hidden, selectionChanged, setTabIndex, tabIndex, toggleSidebar, currentTab],
  );

  const activateTicker = () => {
    setTickerVisible(true);
  };

  return (
    <Container $position={position}>
      <TabsWrapper>
        {position === navbarPosition.LEFT ? (
          <TourPopup
            position="right"
            anchor={notificationRef}
            tourStep={tours.MainTour.steps.LeftPanel}
            tourId={tours.MainTour.id}
          />
        ) : (
          <TourPopup
            position="left"
            anchor={notificationRef}
            tourStep={tours.MainTour.steps.RightPanel}
            tourId={tours.MainTour.id}
          />
        )}
        {enabledItems.map((viewItem) => {
          const tourStep = tour.steps[viewItem.title];
          if (!tourStep) return null;
          return (
            <TourPopup
              key={viewItem.title}
              position="right"
              anchor={tabRefs[viewItem.title]}
              tourStep={tourStep}
              tourId={tour.id}
            />
          );
        })}
        <StyledTabs
          ref={notificationRef}
          value={tabIndex}
          orientation="vertical"
          onChange={handleTabChange}
          $hidden={hidden}
          $position={position}
        >
          {enabledItems.map((viewItem) => {
            const { title, SelectedComponent, UnselectedComponent } = viewItem;
            const isSelected = !hidden && selectionType === viewItem.selectionType;

            return (
              <Tooltip title={title} noArrow key={title}>
                <StyledTab
                  ref={(element) => {
                    tabRefs[title] = element;
                  }}
                  key={title}
                  tabIndex={0}
                  disableRipple
                  label={isSelected ? <SelectedComponent /> : <UnselectedComponent />}
                  $divider={viewItem.divider}
                  $hidden={hidden}
                  $notificationCount={
                    viewItem.selectionType === SelectionTypes.CHAT && notificationCount
                      ? notificationCount
                      : 0
                  }
                  $hasUpdate={viewItem.selectionType === SelectionTypes.DAILYNOTE && hasUpdatedSub}
                />
              </Tooltip>
            );
          })}
        </StyledTabs>
      </TabsWrapper>
      {!isTickerVisible &&
        (position === navbarPosition.LEFT ? (
          <>
            <TourPopup
              position="top"
              anchor={feedsTickerRef}
              tourStep={tours.MainTour.steps.FeedsTicker}
              tourId={tours.MainTour.id}
            />
            <Tooltip key="ticker" title="Feeds Ticker" noArrow>
              <TickerButton role="button" onClick={activateTicker} ref={feedsTickerRef}>
                <TickerIcon />
              </TickerButton>
            </Tooltip>
          </>
        ) : (
          <ButtonPlaceholder />
        ))}
    </Container>
  );
};

VerticalNavbar.propTypes = {
  /**  call parent component func to hide sidebar */
  toggleSidebar: PropTypes.func.isRequired,
  /**  call parent component func to change selection */
  selectionChanged: PropTypes.func.isRequired,
  /** selection type */
  selectionType: PropTypes.string,
  /** is the sidebar hidden */
  hidden: PropTypes.bool.isRequired,
  /** position of the sidebar */
  position: PropTypes.oneOf(Object.values(navbarPosition)).isRequired,
};

VerticalNavbar.defaultProps = {
  selectionType: null,
};

export default memo(VerticalNavbar);
