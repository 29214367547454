import PropTypes from 'prop-types';
import { View, Text } from '@react-pdf/renderer';

import getDirection from 'utils/text/getDirection';

import RtlText from 'components/RtlText/RtlText';

const AutoDirText = ({ value, style }) => {
  const direction = getDirection(value);

  return direction === 'rtl' ? (
    <View style={style}>
      <RtlText>{value}</RtlText>
    </View>
  ) : (
    <Text style={style}>{value}</Text>
  );
};

AutoDirText.propTypes = {
  value: PropTypes.string.isRequired,
  style: PropTypes.oneOf(PropTypes.object, PropTypes.array),
};

AutoDirText.defaultProps = {
  value: '',
  style: {},
};

export default AutoDirText;
