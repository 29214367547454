import PropTypes from 'prop-types';
import capitalize from 'utils/capitalize';
import useImageUrl from 'hooks/useImageUrl';
import fallbackImage from 'assets/images/default/defaultThumbnail.png';
import { formatDateToLocaleString } from 'utils/formatDate';
import { Button, Typography } from '@material-ui/core';
import { ReactComponent as ArrowLeft } from 'assets/icons/systemicons/arrows/disclosurearrow_left.svg';
import { onImageLoadingError } from 'utils/image/imageUtils';
import useStyles from './header-styles';

const PreviewItem = ({ title, backButtonClicked, publishingtime, mThumbnailKey }) => {
  const thumbnail = useImageUrl(mThumbnailKey);
  const classes = useStyles(thumbnail)();
  const formattedPublishingTime = capitalize(formatDateToLocaleString(publishingtime));

  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <Button
          variant="text"
          classes={{ root: classes.buttonRoot }}
          onClick={backButtonClicked}
          disableRipple
        >
          <ArrowLeft />
          Back
        </Button>
      </div>
      <div className={classes.bottom}>
        <div className={classes.image}>
          <img
            className={classes.img}
            onError={onImageLoadingError}
            alt="ins_avtr"
            src={thumbnail ?? fallbackImage}
          />
        </div>
        <div>
          <Typography className={classes.title}>{title}</Typography>
          <Typography className={classes.title}>{formattedPublishingTime}</Typography>
        </div>
      </div>
    </div>
  );
};

PreviewItem.propTypes = {
  /** Rundown title */
  title: PropTypes.string.isRequired,
  /** Publishing time */
  publishingtime: PropTypes.string,
  /** back button click handler */
  backButtonClicked: PropTypes.func,
  /** thumbnail key for instance thumbnail */
  mThumbnailKey: PropTypes.string,
};

PreviewItem.defaultProps = {
  publishingtime: '',
  backButtonClicked: () => {},
  mThumbnailKey: undefined,
};

export default PreviewItem;
