import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'components/tooltip';
import tooltipEnum from 'components/editor/constants/tooltipEnum';
import useStyles from './styles';

const ButtonBase = ({ IconComponent, type, isActive, onMouseDown }) => {
  const classes = useStyles({ isActive });

  const renderIcon = useMemo(
    () => (
      <Tooltip title={tooltipEnum[type]} noArrow>
        <div className={classes.container}>
          <IconComponent className={classes.root} {...{ onMouseDown }} />
        </div>
      </Tooltip>
    ),
    [classes.container, classes.root, onMouseDown, type],
  );

  return renderIcon;
};

ButtonBase.propTypes = {
  /** Component to render as icon */
  IconComponent: PropTypes.elementType,
  /** Specifies if the button should show is active styles or not */
  isActive: PropTypes.bool,
  /** Callback to be invoked on mouse click */
  onMouseDown: PropTypes.func,
};

ButtonBase.defaultProps = {
  IconComponent: () => null,
  isActive: false,
  onMouseDown: () => {},
};

export default memo(ButtonBase);
