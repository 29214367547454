import { Font, StyleSheet } from '@react-pdf/renderer';

import Arial from 'assets/fonts/Arial.ttf';

Font.register({
  family: 'Arial',
  format: 'truetype',
  src: Arial,
});

export const styles = StyleSheet.create({
  body: {
    padding: 35,
    paddingBottom: 40,
    color: '#000',
    fontFamily: 'Arial',
    display: 'flex',
    flexDirection: 'column',
    fontSize: 16,
  },
  title: {
    fontSize: 20,
    lineHeight: '120%',
    fontWeight: 600,
    alignSelf: 'flex-start',
  },
  header: {
    flexDirection: 'column',
    marginBottom: 10,
  },
  headerContent: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: 8,
  },
  footer: {
    fontSize: 10,
    paddingTop: 8,
    paddingHorizontal: 35,
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 20,
    flexDirection: 'column',
  },
  footerContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  name: {
    fontSize: 8,
    fontWeight: 500,
    lineHeight: 'normal',
    textTransform: 'uppercase',
  },
  column: {
    flexDirection: 'column',
  },
  row: {
    flexDirection: 'row',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  scheduleInfo: {
    fontSize: 12,
  },
  timingInfoTitle: {
    fontSize: 8,
    textTransform: 'uppercase',
  },
  timingInfoValue: {
    fontSize: 20,
  },
  horizontalLine: {
    borderBottom: '0.5 solid black',
    width: '100%',
  },
  content: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '175%',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  primaryItems: {
    lineHeight: '200%',
    textTransform: 'uppercase',
    marginTop: 8,
  },
  camera: {
    color: '#46B963',
  },
  package: {
    color: '#0C69F3',
  },
  graphics: {
    color: '#E58E00',
  },
  live: {
    color: '#E459CE',
  },
  tableContainer: {
    width: '100%',
    border: '1px solid #000',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
  },
  tableCell: {
    textTransform: 'capitalize',
    flex: 1,
    borderRightWidth: 1,
    borderRightColor: '#000',
    padding: 8,
  },
  timingCell: {
    flex: 1,
    textAlign: 'center',
    borderRight: '0.5px solid #000',
    paddingVertical: 8,
    alignSelf: 'center',
  },
  bullet: {
    height: '100%',
  },
  child: {
    padding: 8,
  },
  mention: {
    backgroundColor: 'rgba(57, 151, 255, 0.35)',
    borderRadius: 8,
    paddingHorizontal: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  caption: {
    fontSize: 10,
    lineHeight: 12,
    letterSpacing: 0.5,
    color: 'rgba(15, 20, 26, 0.75)',
  },
  image: {
    objectFit: 'cover',
    width: '100%',
  },
});
