/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
type MessageValue = string;
type MessageFormatter = MessageValue | (() => MessageValue);

function toString(msg: MessageValue) {
  if (typeof msg === 'string') {
    return msg;
  }
  return JSON.stringify(msg);
}

export function invariant(value: unknown, message: MessageFormatter): asserts value {
  if (!value) {
    const msg = toString(typeof message === 'function' ? message() : message);
    // eslint-disable-next-line
    console.assert(value, toString(msg));
    throw new Error(msg);
  }
}

export function assert(condition: unknown, message: MessageFormatter): asserts condition {
  /*
        Warning: WebPack magic

        Webpack will make process.env.NODE_ENV code work in the browser.
    */
  if (process.env.NODE_ENV === 'development') {
    const value = typeof condition === 'function' ? condition() : condition;
    invariant(value, message);
  }
}

/*
    Evaluator will only be run and checked when in development mode.
*/
export async function asyncAssert(
  evaluator: () => Promise<unknown>,
  message: MessageFormatter,
): Promise<void> {
  /*
        Warning: WebPack magic

        Webpack will make process.env.NODE_ENV code work in the browser.
    */
  if (process.env.NODE_ENV === 'development') {
    const value = await evaluator();
    invariant(value, message);
  }
}
