import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 8px',
    width: '100%',
    display: 'flex',
    height: '40px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    width: '100%',
  },
  sidebarHeader: {
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.dina.surfaceAppBackgroundNavLevel1,
  },
  sidebarHeaderText: {
    ...theme.typography.dina.h5,
    lineHeight: '28px',
    color: theme.palette.dina.mediumEmphasis,
  },
}));

export default useStyles;
