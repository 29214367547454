import uuidv1 from 'uuid/v1';
import { Editor, Transforms, Range, Node } from 'slate';
import isSection from '../components/sectionDivider/utils/isSection';

const { removeNodes, insertNodes } = Transforms;

const stopDefaultPasteOperation = (event) => {
  event.preventDefault();
  event.stopPropagation();
};

const updateItemIds = (nodes) =>
  nodes.map((node) => {
    if (!node?.data?.itemId) return node;
    const updatedNode = { ...node, data: { ...node.data, itemId: uuidv1() } };

    if (isSection(node)) updatedNode.children = updateItemIds(node.children);

    return updatedNode;
  });

const checkIfStrictEqual = (elm1, elm2) => JSON.stringify(elm1) === JSON.stringify(elm2);

/**
 * Unwraps incomplete section divider
 * @param {Object[]} nodes Editor nodes
 * @param {Object} editor SlateJS editor instance
 * @returns {Object[]} unwrapped nodes
 */
const unwrapIncompleteSectionDividers = (nodes, editor) => {
  if (nodes?.length === 1) {
    const [node] = nodes;
    const copiedChildrenCount = node?.children?.length;
    if (isSection(node) && copiedChildrenCount) {
      const nodeInEditor = editor.children.find(
        (element) => isSection(element) && element.data.itemId === node.data.itemId,
      );
      const nodeChildrenCount = nodeInEditor?.children?.length;

      /** if copied data does not have all of the children of the source */
      if (nodeChildrenCount !== copiedChildrenCount) return [...node.children];

      /** Unwrap data if first and last child does not strictly match the source */
      if (
        !checkIfStrictEqual(nodeInEditor?.children?.[0], node?.children?.[0]) ||
        !checkIfStrictEqual(
          nodeInEditor?.children?.[nodeChildrenCount - 1],
          node?.children?.[copiedChildrenCount - 1],
        )
      ) {
        return [...node.children];
      }
    }
  }

  return nodes;
};

/**
 *
 * Paste operation is only handled when itemId of automation items needs to be changed
 * Otherwise slate handles it for us
 *
 */

const onPaste = (event, editor) => {
  const clipboardData = event.clipboardData.getData('application/x-slate-fragment');
  if (!clipboardData) return;

  const decoded = decodeURIComponent(window.atob(clipboardData));
  if (decoded) {
    const parsedData = JSON.parse(decoded);
    const shouldChangeItemId = parsedData.find((node) => node?.data?.itemId);

    if (shouldChangeItemId) {
      stopDefaultPasteOperation(event);

      /**
       *
       * Data copied from section-divider will always have itemId.
       *
       * If some (not all) children of a section-divider is copied, the data in the clipboard will be a
       * section-divider with the copied elements as children.
       * We have to unwrap the children if copied data only contains some of the children of that single section-divider.
       * If all of children of the section-divider are copied then the whole section-divider component is pasted.
       *
       */
      const unwrappedNodes = unwrapIncompleteSectionDividers(parsedData, editor);

      const updatedNodes = updateItemIds(unwrappedNodes);

      const { selection } = editor;
      if (Range.isCollapsed(selection)) {
        const { path } = selection.anchor;
        const newLocation = [path[0]];

        const node = Node.parent(editor, path);

        if (Editor.isVoid(editor, node)) {
          removeNodes(editor, { at: newLocation });
          insertNodes(editor, updatedNodes, { at: newLocation });
          return;
        }
      }

      Editor.deleteFragment(editor);
      Editor.insertFragment(editor, updatedNodes);
    }
  }
};

export default onPaste;
