import React from 'react';
import type { RenderHeaderCellProps } from 'react-data-grid';
import { keyBy } from 'lodash';

import { MTypeToMdfId, SupportedMetadataTypes } from 'api/mdf/useGetMdfs';
import MemberLabel from 'components/addMember/MemberLabel';
import { SearchIcons } from 'components/command/command-constants';
import { EditFieldValue } from 'components/mdfEditor/EditFieldValueDialog';
import { OpenDatePicker } from 'components/mdfEditor/fields/date/DatePicker';
import { DateLabel } from 'components/mdfEditor/fields/date/styled';
import { Link } from 'components/mdfEditor/fields/link/LinkDefault';
import { Tag, TagText } from 'components/mdfEditor/fields/multiplechoice/styled';
import TreeChoiceDefault from 'components/mdfEditor/fields/treechoice/TreeChoiceDefault';
import { isSingleArray } from 'components/mdfEditor/fields/utils';
import PlatformIcons from 'components/menu/createInstanceMenu/PlatformIcons';
import Tooltip from 'components/tooltip';
import { TreeChoice } from 'components/treeChoiceDialog/TreeChoiceDialog';
import GridActions, { GridActionType } from 'features/gridDeck/GridActions';
import LWCheckbox from 'features/orderForm/components/LWCheckbox';
import LWSelect from 'features/orderForm/components/LWSelect';
import LWTextField from 'features/orderForm/components/LWTextField';
import OpenPreviewButton from 'features/orderForm/components/OpenPreviewButton';
import SelectAssignee from 'features/orderForm/components/SelectAssignee';
import { InlineTagWrapper } from 'features/orderForm/styled';
import { HStack } from 'layouts/box/Box';
import {
  SortDownIcon,
  SortUpIcon,
} from 'screens/story/components/notes/components/searchBar/searchBar-styled';
// eslint-disable-next-line sort-imports
import { getDefaultValue, getLayoutSettings, Metadata, ResourceType } from 'types/forms/forms';
import {
  FieldTypeEnum,
  LayoutSettings,
  Mdf,
  MdfField,
  MemberType,
  MemberTypeEnum,
  SearchItemTypeEnum,
  ViewTypes,
} from 'types/graphqlTypes';
import { AssignedMember } from 'types/members';
import { isoToLocaleShort } from 'utils/datetimeHelpers';

import { MemberColumn, MemberRow } from './types';

export type FieldMap = Record<string, MdfField & { formId: string; settings: LayoutSettings }>;

export const defaultFieldsTitleMap: Record<string, string> = {
  __status: 'Status',
  __assignee: 'Assignee',
  __createdAt: 'Created',
  __updatedAt: 'Updated',
  __ownerId: 'Task owner',
};

export const keyDelimiter = '##';

export const acceptedMTypes = [
  MemberTypeEnum.Story,
  MemberTypeEnum.Instance,
  MemberTypeEnum.Rundown,
  MemberTypeEnum.Pitch,
  MemberTypeEnum.Note,
  MemberTypeEnum.User,
  MemberTypeEnum.Contact,
];

export const getFieldKey = (fieldId: string, formId: string) =>
  `${fieldId}${keyDelimiter}${formId}`;
export const getFieldIdFromKey = (composite: string) => composite.split(keyDelimiter)[0];

export const getFieldMap = (
  mdfs: Record<string, Mdf | undefined>,
  view: ViewTypes = 'story_create',
): FieldMap => {
  const fieldMap: FieldMap = {};
  for (const mdf of Object.values(mdfs)) {
    if (!mdf) continue;
    for (const field of mdf.fields) {
      const settings = getLayoutSettings(mdf, field, view);
      fieldMap[`${getFieldKey(field.fieldId, mdf.id)}`] = { ...field, formId: mdf.id, settings };
    }
  }
  return fieldMap;
};

export function Header<R>(props: Readonly<RenderHeaderCellProps<R>>) {
  const onClick = (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    props.onSort(ev.ctrlKey || ev.metaKey);
  };
  return (
    <>
      <div style={{ position: 'relative' }}>
        <div
          role="toolbar"
          onClick={onClick}
          onKeyDown={() => {}}
          style={{ margin: 'auto', cursor: 'pointer' }}
        >
          {props.column.name}
        </div>
      </div>
      {props.sortDirection &&
        (props.sortDirection == 'ASC' ? (
          <>
            <SortUpIcon
              style={{
                position: 'absolute',
                right: '3px',
                top: '4px',
                cursor: 'pointer',
                pointerEvents: 'none',
              }}
            />
            <span style={{ position: 'absolute', right: '28px', top: '13px' }}>
              {props.priority}
            </span>
          </>
        ) : (
          <>
            <SortDownIcon
              style={{
                position: 'absolute',
                right: '3px',
                top: '5px',
                cursor: 'pointer',
                pointerEvents: 'none',
              }}
            />
            <span style={{ position: 'absolute', right: '28px', top: '13px' }}>
              {props.priority}
            </span>
          </>
        ))}
    </>
  );
}

export const getMaxWidth = (fieldModel: MdfField) => {
  switch (fieldModel.type) {
    case FieldTypeEnum.checkbox:
      return 40;
    case FieldTypeEnum.choice:
      return 160;
    case FieldTypeEnum.text:
      return 300;
    default:
      return undefined;
  }
};

export const extractColumnsFromMemberType = (
  items: MemberType[],
  fieldMap: FieldMap,
  mdfMap: Record<SupportedMetadataTypes, Mdf | undefined>,
  userMap: Record<string, AssignedMember>,
  goToResource: (id: string, type: ResourceType, extraTitle?: string) => void,
  showPicker: (state: OpenDatePicker) => void,
  showPreview: (id: string) => void,
  showEditFieldDialog: (state: EditFieldValue) => void,
  openTreeChoiceDialog: (state: TreeChoice) => void,
  removeFromGrid: (itemId: string) => void,
): MemberColumn[] => {
  const mTypes: SupportedMetadataTypes[] = [];
  const uniqueKeys = new Set<string>();
  const cols: MemberColumn[] = [];

  for (const member of items) {
    if (MTypeToMdfId[member.mType as SupportedMetadataTypes]) {
      mTypes.push(member.mType as SupportedMetadataTypes);
    }
  }

  for (const mType of mTypes) {
    const mdf = mdfMap[mType];
    if (mdf) {
      for (const field of mdf.fields) {
        uniqueKeys.add(getFieldKey(field.fieldId, mdf.id));
      }
    }
  }

  const it = uniqueKeys.entries();

  cols.push({
    key: '__storyTitle',
    name: 'Title',
    resizable: true,
    renderHeaderCell(props) {
      return <Header {...props} />;
    },
    renderCell({ row }) {
      let Icon: React.FC<React.SVGProps<SVGSVGElement>> | undefined;
      if ((row.__type as SearchItemTypeEnum) !== SearchItemTypeEnum.instance) {
        Icon = SearchIcons[row.__type as SearchItemTypeEnum];
      } else {
        Icon = row.__platform ? PlatformIcons[row.__platform] : PlatformIcons.defaultIcon;
      }
      return (
        <OpenPreviewButton onPreviewClick={() => showPreview(row.id)}>
          <HStack alignItems="center" gap="4px" justifyContent="start">
            {Icon && (
              <Icon
                className="skipOverride"
                style={{ flexShrink: 0, flexGrow: 0, width: '20px', height: '20px' }}
              />
            )}
            {row.showWarning ? (row.showWarning as string) : row.__storyTitle}
          </HStack>
        </OpenPreviewButton>
      );
    },
  });

  for (const entry of it) {
    const key = entry[0];
    const fieldModel = fieldMap[key];

    if (!fieldModel) continue;

    cols.push({
      key,
      name: fieldModel.settings.label ?? 'unknown',
      sortable: true,
      resizable: true,
      maxWidth: getMaxWidth(fieldModel),
      renderHeaderCell(p) {
        return <Header {...p} />;
      },
      cellClass(row) {
        if (row.__mdfId !== fieldModel.formId) {
          return 'disable';
        }
      },
      renderCell({ row, onRowChange }) {
        if (row.__mdfId !== fieldModel.formId) {
          return null;
        }

        const value = row[key] || fieldModel.defaultValue.value;
        const openTreeChoice = () => {
          openTreeChoiceDialog({
            open: true,
            fieldId: fieldModel.fieldId,
            selected: (value ?? []) as string[],
            setSelected: (v: string[] | []) => onRowChange({ ...row, [key]: v }),
            treeAlternatives: fieldModel.treeAlternatives ?? [],
          });
        };
        const showFieldEditor = () => {
          showEditFieldDialog({
            startValue: value,
            fieldId: fieldModel.fieldId,
            headerText: `Edit ${fieldModel.settings.label}`,
            viewType: 'order_grid',
            mdf: mdfMap[row.__type as SupportedMetadataTypes]!,
            onConfirm: (v) => onRowChange({ ...row, [key]: v }),
          });
        };

        if (fieldModel.type === FieldTypeEnum.checkbox) {
          const curValue = Boolean(row[key]);
          const setValue = (val: boolean) => {
            onRowChange({ ...row, [key]: val });
          };
          return <LWCheckbox selected={curValue} setValue={setValue} />;
        }
        if (fieldModel.type === FieldTypeEnum.choice) {
          const curValue = row[key] as string;
          const setValue = (val: string) => {
            onRowChange({ ...row, [key]: val });
          };
          return (
            <LWSelect
              value={curValue}
              setValue={setValue}
              options={fieldModel?.alternatives ?? []}
            />
          );
        }
        if (fieldModel.type === FieldTypeEnum.text || fieldModel.type === FieldTypeEnum.number) {
          return (
            <div
              title={`${row[key] as string | number}`}
              onDoubleClick={fieldModel.settings.multiline ? showFieldEditor : () => {}}
              style={{ whiteSpace: 'pre', width: '100%', height: '100%' }}
            >
              {row[key] as string | number}
            </div>
          );
        }
        if (fieldModel.type === FieldTypeEnum.date) {
          return (
            <DateLabel
              onClick={(ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                showPicker({
                  anchorEl: ev.currentTarget,
                  selectDate: (vals) => {
                    onRowChange({ ...row, [key]: vals.startDate });
                  },
                });
              }}
            >
              {row[key] ? isoToLocaleShort(row[key]) : ' '}
            </DateLabel>
          );
        }
        if (fieldModel.type === FieldTypeEnum.multiplechoice) {
          if (isSingleArray(value)) {
            const alternativesByValue = keyBy(fieldModel.alternatives ?? [], (a) => a.value);
            if (!Array.isArray(value) || !value.length)
              return (
                <div
                  onClick={() => showFieldEditor()}
                  onKeyDown={() => {}}
                  style={{ width: '100%', height: '100%' }}
                />
              );
            return (
              <Tooltip title="Edit value">
                <InlineTagWrapper onClick={() => showFieldEditor()}>
                  {value.map((t) => (
                    <Tag key={t} style={{ height: '21px', lineHeight: '21px', padding: '0 4px' }}>
                      <TagText>{alternativesByValue[t]?.label ?? t}</TagText>
                    </Tag>
                  ))}
                </InlineTagWrapper>
              </Tooltip>
            );
          }
        }
        if (fieldModel.type === FieldTypeEnum.link) {
          return (
            <Link
              url={value as string}
              hint={fieldModel.settings.hint}
              onEditClick={showFieldEditor}
            />
          );
        }
        if (fieldModel.type === FieldTypeEnum.user) {
          const user: AssignedMember | null = userMap[row[key] as string] ?? null;
          return <MemberLabel variant="grid" member={user} />;
        }
        if (fieldModel.type === FieldTypeEnum.treechoice) {
          const parsed = Array.isArray(row[key]) ? (row[key] as string[]) : [];
          return (
            <InlineTagWrapper onClick={openTreeChoice} onKeyDown={() => {}}>
              <TreeChoiceDefault onClick={() => {}} choice={parsed} />
            </InlineTagWrapper>
          );
        }
      },
      renderEditCell({ row, onRowChange }) {
        if (row.__mdfId !== fieldModel.formId) {
          return null;
        }
        if (
          (fieldModel.type === FieldTypeEnum.text || fieldModel.type === FieldTypeEnum.number) &&
          !fieldModel.settings.multiline
        ) {
          const curValue = row[key] as string;
          const setValue = (val: string) => {
            onRowChange({ ...row, [key]: val });
          };
          return <LWTextField value={curValue} setValue={setValue} type={fieldModel.type} />;
        }
        if (fieldModel.type === FieldTypeEnum.user) {
          const selectAssignee = (m: AssignedMember) => {
            if (!m) return;
            onRowChange({ ...row, [key]: m.mId }, true);
          };
          return <SelectAssignee selectCallback={selectAssignee} />;
        }
      },
    });
  }

  cols.push({
    key: '__createdById',
    name: 'Created by',
    width: 120,
    cellClass(row) {
      if (row.__noMatchingForm) {
        return 'error';
      }
    },
    renderHeaderCell(props) {
      return <Header {...props} />;
    },
    renderCell({ row }) {
      const user: AssignedMember = userMap[row.__createdById];
      return <MemberLabel variant="grid" member={user} readOnly />;
    },
    sortable: true,
    resizable: true,
  });

  cols.push({
    key: '__createdAt',
    name: 'Created',
    resizable: true,
    minWidth: 120,
    width: 120,
    renderHeaderCell(p) {
      return <Header {...p} />;
    },
    cellClass(row) {
      if (row.__noMatchingForm) {
        return 'error';
      }
    },
    renderCell({ row }) {
      return <span>{isoToLocaleShort(row.__createdAt, true)}</span>;
    },
  });

  cols.push({
    key: '__updatedAt',
    name: 'Updated',
    resizable: true,
    minWidth: 120,
    width: 120,
    renderHeaderCell(p) {
      return <Header {...p} />;
    },
    cellClass(row) {
      if (row.__noMatchingForm) {
        return 'error';
      }
    },
    renderCell({ row }) {
      return <span>{isoToLocaleShort(row.__updatedAt, true)}</span>;
    },
  });

  cols.push({
    key: '__buttons',
    name: '',
    sortable: false,
    resizable: false,
    maxWidth: 56,
    renderHeaderCell(props) {
      return <div>{props.column.name}</div>;
    },
    cellClass(row) {
      if (row.__noMatchingForm) {
        return 'error';
      }
    },
    renderCell(props) {
      const { row } = props;
      const onClick = (type: GridActionType) => {
        if (type === 'go-to-resource') goToResource(row.id, 'story');
        else removeFromGrid(row.id);
      };

      return <GridActions onClick={onClick} mType={row.__type as MemberTypeEnum} />;
    },
  });
  return cols;
};

export const extractRowsFromMemberType = (
  items: MemberType[],
  mdfMap: Record<SupportedMetadataTypes, Mdf | undefined>,
): MemberRow[] => {
  const rows: MemberRow[] = [];

  const toString = (val: string | undefined, fallback = '') => val ?? fallback;

  for (const member of items) {
    const mdf = mdfMap[member.mType as SupportedMetadataTypes];
    const row: MemberRow = {
      id: toString(member.mId),
      mRefId: toString(member.mRefId),
      __platform: toString(
        (member.mProperties?.platformKind || member.mProperties?.platform) as string | undefined,
      ),
      __storyTitle: toString(member.mTitle, 'Missing title'),
      __mdfId: toString(mdf?.id, member.mdfId),
      __createdById: toString(member.mCreatedById),
      __createdAt: toString(member.mCreatedAt),
      __updatedAt: toString(member.mUpdatedAt),
      __type: toString(member.mType),
      __buttons: 'val', // dummy value to make the column show up
    };

    const mdfFieldsForMType = mdf?.fields ?? [];
    try {
      const md = JSON.parse(member.metadata ?? '{}') as Metadata;
      for (const field of mdfFieldsForMType) {
        row[getFieldKey(field.fieldId, MTypeToMdfId[member.mType as SupportedMetadataTypes])] =
          md[field.fieldId] ?? getDefaultValue(field);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
    rows.push(row);
  }

  return rows;
};
