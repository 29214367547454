import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import useInputEvents from 'hooks/useInputEvents';
import getDuration from './utils/getDurationValue';

import useStyles from './duration-styles';

const DurationView = ({
  duration,
  onUpdate,
  disableEdit,
  size,
  manualDurationField,
  manualDurationSettingsValue,
}) => {
  const [value, setValue] = useState(duration);
  const [error, setError] = useState(false);
  const customClipStyle = useMemo(() => {
    if (manualDurationField) return !!manualDurationField?.manual && manualDurationSettingsValue;
    return false;
  }, [manualDurationField, manualDurationSettingsValue]);
  const classes = useStyles({
    size: size || (duration?.trim().length > 5 ? 'large' : 'small'),
    error,
    customClipStyle,
  });

  useEffect(() => {
    const timeOut = setTimeout(() => {
      error && setError(false);
    }, 2000);
    return () => {
      clearTimeout(timeOut);
    };
  }, [error]);

  const onUpdateInput = (newValue) => {
    if (newValue === duration) setValue(newValue);
    else {
      const [isError, durationValue] = getDuration(newValue, manualDurationField);

      if (isError) setError(true);
      setValue(durationValue);
      !isError ? onUpdate(durationValue, true) : onUpdate(durationValue);
    }
  };

  const [inputRef, onKeyDown, onBlur] = useInputEvents(onUpdateInput, value, duration);

  useEffect(() => setValue(duration), [duration]);

  const onChange = (event) => setValue(event.target.value);

  return (
    <div className={classes.root}>
      <input
        className={classes.input}
        type="text"
        ref={inputRef}
        disabled={disableEdit}
        value={value || '00:00'}
        {...{ onChange, onBlur, onKeyDown }}
      />
    </div>
  );
};

DurationView.propTypes = {
  /** Callback to be invoked when user finishes updating duration value and
   * moves focus to elsewhere, with the updated value passed in
   */
  onUpdate: PropTypes.func,
  /** If true, disables editability of the duration value */
  disableEdit: PropTypes.bool,
};

DurationView.defaultProps = {
  onUpdate: (newDuration) => {},
  disableEdit: false,
};

export default DurationView;
