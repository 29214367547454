/* eslint-disable import/prefer-default-export */
import { atom, useAtom } from 'jotai';

/** Breaking feeds default item */
const BREAKING_FEED_DEFAULT = {
  mTitle: 'Breaking',
  mRefId: 'ALL_BREAKING_FEEDS',
  mMetaData: [
    {
      key: 'order',
      value: '0',
    },
  ],
  index: -1,
};

/** Feed ticker. */
const feedTickerStorageAtom = atom(JSON.parse(localStorage.getItem('tickerVisible')) ?? false);

export const feedTickerVisibleAtom = atom(
  (get) => get(feedTickerStorageAtom),
  (_get, set, val) => {
    set(feedTickerStorageAtom, val);
    localStorage.setItem('tickerVisible', JSON.stringify(val));
  },
);
export const useFeedTickerVisible = () => useAtom(feedTickerVisibleAtom);

/** ------------------- */

/** Feed sources, gathered from the backend. */
export const feedSources = atom(undefined);
const feedSourcesAtom = atom(
  (get) => get(feedSources),
  (get, set, feedResult) => {
    const mappedData = [
      ...feedResult.data.getMemberFromId.map(({ mTitle, mRefId, accessToken, mMetaData }) => ({
        mTitle,
        mRefId,
        accessToken,
        mMetaData,
      })),
    ];

    const getOrder = (source) => {
      let order = 9999;
      if (source.mMetaData) {
        const config = source.mMetaData?.find(({ key }) => key && key === 'order');
        order = config?.value ? parseFloat(config.value) : 9999;
      }
      return order;
    };

    mappedData.sort((f1, f2) => getOrder(f1) - getOrder(f2));
    const indexedSources = mappedData.map((item, index) => ({ index, ...item }));
    set(feedSources, indexedSources);
  },
);

export const useFeedSources = () => useAtom(feedSourcesAtom);

export const tickerFeedSources = atom((get) => {
  const sources = [...get(feedSources)];
  sources.unshift(BREAKING_FEED_DEFAULT);
  return sources;
});

export const useTickerFeedSources = () => useAtom(tickerFeedSources);
