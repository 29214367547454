import { useLoadingIndicator } from 'store';
import { useMutation } from '@apollo/client';
import useLogger from 'utils/useLogger';
import COPY_INSTANCE from 'graphql/mutations/moveInstanceToRundown';
import UPDATE_RUNDOWN_SYNC from 'graphql/mutations/updateRundownSync';

import { useStoryMolecule } from 'screens/storyV2/store/story';
import { useStoryPaneMolecule } from 'screens/storyV2/store/storyPane';

const findIndexInArray = (item) => (array) => array ? array.indexOf(item) : -1;

const returnOrderType = (instanceId, mOrder, mPreorder, isSameRundown) => {
  if (!isSameRundown) {
    return {
      orderType: 'preparing',
      index: 1000, // To add at the end of the preparing list of target rundown
    };
  }

  const returnIndex = findIndexInArray(instanceId);
  const isInReadyList = mOrder?.length > 0 && returnIndex(mOrder) >= 0;
  return isInReadyList
    ? { orderType: 'ready', index: returnIndex(mOrder) + 1 }
    : {
        orderType: 'preparing',
        index: returnIndex(mPreorder) >= 0 ? returnIndex(mPreorder) + 1 : mPreorder?.length || 0,
      };
};

const useDuplicateInstance = () => {
  const [, setLoadingIndicator] = useLoadingIndicator();
  const [duplicateInstance] = useMutation(COPY_INSTANCE);
  const [updateRundownSync] = useMutation(UPDATE_RUNDOWN_SYNC);
  const { isWithinStoryScope } = useStoryMolecule();
  const { useCreatingInstance, useNewlyAddedInstance } = useStoryPaneMolecule();
  const [, setCreatingInstance] = useCreatingInstance();
  const [, setNewlyAddedInstance] = useNewlyAddedInstance();
  const logger = useLogger('duplicate instance');

  const createDuplicate = async (instanceId, rundownId, rundownData) => {
    try {
      const { mOrder, mPreorder, mId } = rundownData;

      const input = {
        mIds: [instanceId],
        copy: true,
      };

      if (rundownId) {
        const { orderType, index } = returnOrderType(
          instanceId,
          mOrder,
          mPreorder,
          mId === rundownId,
        );

        input.orderType = orderType;
        input.index = index;
        input.targetRundown = {
          mId: rundownId,
          mRefId: rundownId,
        };
      }

      setLoadingIndicator(input.orderType || 'create');
      if (isWithinStoryScope) {
        setCreatingInstance(true);
      }

      await duplicateInstance({
        variables: {
          input,
        },
        fetchPolicy: 'no-cache',
        update: (_, mutationResult) => {
          if (!rundownId) return;
          const [newInstance] = mutationResult?.data?.moveInstanceToRundown;
          const variables = {
            input: { mId: rundownId, mRefId: rundownId },
          };
          const order = input.orderType === 'ready' ? 'mOrder' : 'mPreorder';
          const payloadInput = {
            crudAction: 'NONE',
            value: {
              mId: newInstance.mId,
              index: input.index,
              destination: order,
            },
          };
          const payloads = [payloadInput];
          variables.input.mPayload = payloads;
          updateRundownSync({
            variables,
            optimisticResponse: false,
          });

          if (isWithinStoryScope) {
            setNewlyAddedInstance(newInstance);
          }
        },
      });
    } catch (error) {
      logger.log(error);
    } finally {
      setLoadingIndicator(null);
    }
  };

  return [createDuplicate];
};

export default useDuplicateInstance;
