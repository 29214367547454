import { memo } from 'react';
import styled from '@emotion/styled';

import { elementTypes, markTypes } from 'components/editor/constants';

import ColorButton from '../colorButton';
import ElementButton from '../elementButton';
import HorizontalRuleButton from '../horizontalRuleButton';
import ImageButton from '../imageButton';
import LinkButton from '../linkButton';
import ListButton from '../listButton';
import MarkButton from '../markButton';
import PlaceholderButton from '../placeholderButton';
import QuoteButton from '../quoteButton';
import VerticalDivider from '../verticalDivider';
import VideoButton from '../videoButton';
import Wrapper from '../wrapper';

const { BOLD, ITALIC, UNDERLINE, STRIKE_THROUGH } = markTypes;

const { UNORDERED_LIST, HEADING_FOUR, HEADING_ONE, HEADING_THREE, HEADING_TWO, ORDERED_LIST } =
  elementTypes;

export const Container = styled('div')`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
  flex: 1;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  justify-content: space-between;
`;

interface GeneralToolbarProps {
  hideRightButtons?: boolean;
  isAllowed?: boolean;
}

function GeneralToolbar({ hideRightButtons, isAllowed }: Readonly<GeneralToolbarProps>) {
  return (
    <Wrapper showRightMargin>
      <MarkButton type={STRIKE_THROUGH} />
      <MarkButton type={BOLD} />
      <MarkButton type={ITALIC} />
      <MarkButton type={UNDERLINE} />
      <ElementButton type={HEADING_ONE} />
      <ElementButton type={HEADING_TWO} />
      <ElementButton type={HEADING_THREE} />
      <ElementButton type={HEADING_FOUR} />
      <ListButton type={UNORDERED_LIST} />
      <ListButton type={ORDERED_LIST} />
      <QuoteButton />
      <LinkButton />
      <HorizontalRuleButton />
      <ColorButton />
      <VerticalDivider />
      {!hideRightButtons && (
        <>
          {!isAllowed && <PlaceholderButton />}
          <VideoButton />
          <ImageButton />
          <ImageButton isGif />
        </>
      )}
    </Wrapper>
  );
}

export default memo(GeneralToolbar);
