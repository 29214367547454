import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'hidden',
    backgroundColor: theme.palette.dina.surfaceAppBackgroundNavLevel2,
  },
  headerWrapper: {
    height: 120,
  },
  bodyWrapper: {
    position: 'relative',
    height: 'calc(100% - 173px + 48px)',
  },
}));

export default useStyles;
