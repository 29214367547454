import { useState } from 'react';
import PropTypes from 'prop-types';

import memberTypes from 'graphql/memberTypes';
import { useRightSidebarSavedSearches } from 'store/sidebar';

import Searchbar from 'components/searchbar';
import RelevantHeader from '../../relevantHeader';
import Tabs from '../../tabs';
import MasterWrapper from '../../masterWrapper';
import useStyles from './list-styles';
import ListContainer from './list-container';
import menuItems from './menu-items';
import menuActions from './menu-actions';
import Divider from 'components/divider';
import CreateNewDialog from 'screens/main/components/rightArea/sidebar/contacts/createContact/CreateDialogV2';

const ListView = ({
  showDetail,
  contactTab,
  handleContactTabChange,
  filterModel,
  updateFilterModel,
  history,
  updateHistory,
  currentSearch,
  updateCurrentSearch,
  searchString,
}) => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [rightSidebarSavedSearches, setRightSidebarSavedSearches] = useRightSidebarSavedSearches();

  const handleCreateNewFilter = (newFilter) => {
    setRightSidebarSavedSearches([...rightSidebarSavedSearches, newFilter]);
  };

  const handleUpdateFilters = (newSavedFilters) => {
    setRightSidebarSavedSearches(newSavedFilters);
  };

  const tabItems = [
    {
      label: 'EXTERNAL',
      content: (
        <div className={classes.listWrapper}>
          <ListContainer
            searchString={searchString}
            onClick={showDetail}
            type={memberTypes.CONTACT}
          />
        </div>
      ),
    },
    {
      label: 'USERS',
      content: (
        <div className={classes.listWrapper}>
          <ListContainer searchString={searchString} onClick={showDetail} type={memberTypes.USER} />
        </div>
      ),
    },
  ];

  const onMenuItemClick = (action) => {
    if (action === menuActions.CREATE_CONTACT) {
      setDialogOpen(true);
    } else {
      setDialogOpen(false);
    }
  };

  const onTabChange = (_, newValue) => {
    handleContactTabChange(newValue);
  };

  return (
    <>
      <CreateNewDialog open={dialogOpen} setOpen={setDialogOpen} />

      <MasterWrapper
        header={<RelevantHeader headerTitle="Contacts" {...{ menuItems, onMenuItemClick }} />}
        body={
          <>
            <Divider />
            <Searchbar
              savedFilters={rightSidebarSavedSearches}
              onCreateFilter={handleCreateNewFilter}
              onUpdateFilters={handleUpdateFilters}
              {...{
                filterModel,
                updateFilterModel,
                history,
                updateHistory,
                currentSearch,
                updateCurrentSearch,
              }}
            />
            <Tabs disableElevation {...{ tabItems, onTabChange, tabIndex: contactTab }} />
          </>
        }
      />
    </>
  );
};

ListView.propTypes = {
  showDetail: PropTypes.func,
  contactTab: PropTypes.number,
  handleContactTabChange: PropTypes.func,
  filterModel: PropTypes.object,
  updateFilterModel: PropTypes.func,
  history: PropTypes.array,
  updateHistory: PropTypes.func,
  currentSearch: PropTypes.string,
  updateCurrentSearch: PropTypes.func,
  searchString: PropTypes.string,
};

export default ListView;
