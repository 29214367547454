import { useEffect, useMemo } from 'react';

import Editor from 'components/editor';
import Metadata from 'components/metadata';
import SplitBar from 'components/split';
import { useStoryToolbar } from 'screens/storyV2/store/toolbar';
import { useUsers } from 'store';
import { MMetaDataField } from 'types/graphqlTypes';
import clickIfNothingSelected from 'utils/clickIfNothingSelected';
import { getUserLockToken } from 'utils/lock/lockToken';

import useInstanceCore from '../hooks/useInstanceCore';
import useInstanceMetadata from '../hooks/useInstanceMetadata';
import useInstancePermissions from '../hooks/useInstancePermissions';
import useInstanceViewUtils from '../hooks/useInstanceViewUtils';
import useLoadAndSetContent from '../hooks/useLoadAndSetContent';
import { useInstanceMolecule } from '../store/instance';
import { EditorContentWrapper } from '../styled';

import EmbedView from './embedView';

const Body = () => {
  const {
    useInstanceValue,
    useDisableEdit,
    usePlatform,
    useWriteLock,
    useReadLock,
    useView,
    instanceRef,
    editorValueRef,
  } = useInstanceMolecule();
  const { canShowNewDesign, canShowCmsIframe, canUploadMediaBySignedURL } =
    useInstancePermissions();
  const {
    handleEditorUpdate,
    editorValue,
    handleLockInstance,
    cancelDebounce,
    saveInstanceWithContent,
    userId,
    onMetadataChanged,
  } = useInstanceCore();
  const {
    showMetadata,
    thumbnail,
    isCMSInstance,
    onCmsEditingClick,
    onSetEditor,
    placeholder,
    textDirection,
    variant,
    isCmsBlock,
    getPlaceholderConfigs,
    showFacebookEmbed,
    showTwitterEmbed,
    showInstagramEmbed,
    showYoutubeEmbed,
    resetSelection,
  } = useInstanceViewUtils();
  const { hostReadSpeed, fields, pairedMetadata, parameterFields } = useInstanceMetadata();

  const users = useUsers();
  const instance = useInstanceValue();
  const [disableEdit] = useDisableEdit();
  const [platform] = usePlatform();
  const [{ editorFontSize }] = useStoryToolbar();
  const [writeLock] = useWriteLock();
  const [readLock] = useReadLock();
  const [view] = useView();

  useLoadAndSetContent();

  useEffect(() => {
    resetSelection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [writeLock]);

  useEffect(() => {
    const unlockBeforeUnload = (e?: BeforeUnloadEvent) => {
      if (e) {
        e.preventDefault();
        delete e.returnValue;
      }

      if (instanceRef.current?.locked !== getUserLockToken(userId)) {
        return;
      }

      cancelDebounce();

      const params = {
        instance: { ...instanceRef.current },
        unlock: true,
        audit: { source: 'instanceBody:unlockBeforeUnload' },
        ...(editorValueRef.current && { content: editorValueRef.current }),
      };
      void saveInstanceWithContent(params);
    };

    window.addEventListener('beforeunload', (e) => {
      unlockBeforeUnload(e);
    });

    return () => {
      unlockBeforeUnload();
      window.removeEventListener('beforeunload', (e) => {
        unlockBeforeUnload(e);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderedEditor = useMemo(
    () => (
      <Editor
        height="100%"
        readOnly={!writeLock}
        update={handleEditorUpdate}
        thumbnail={thumbnail}
        platformStructure={platform?.mProperties?.platformStructure}
        value={editorValue}
        isAllowed={canShowNewDesign || (isCMSInstance && canShowCmsIframe)}
        onCmsEditing={onCmsEditingClick}
        setEditor={onSetEditor}
        placeholder={placeholder}
        withSignedUrl={canUploadMediaBySignedURL}
        direction={textDirection}
        users={users}
        variant={variant}
        hostReadSpeed={hostReadSpeed}
        isPublished={!!instance?.mPublishingAt}
        isCmsBlock={isCmsBlock}
        editorFontSize={editorFontSize}
        getPlaceholderConfigs={getPlaceholderConfigs}
        platformId={platform?.mRefId}
        platformKind={instance?.mProperties?.platformKind}
        renderToolbar={disableEdit ? () => null : undefined}
      />
    ),
    [
      writeLock,
      handleEditorUpdate,
      thumbnail,
      platform?.mProperties?.platformStructure,
      platform?.mRefId,
      editorValue,
      canShowNewDesign,
      isCMSInstance,
      canShowCmsIframe,
      onCmsEditingClick,
      onSetEditor,
      placeholder,
      canUploadMediaBySignedURL,
      textDirection,
      users,
      variant,
      hostReadSpeed,
      instance?.mPublishingAt,
      instance?.mProperties?.platformKind,
      isCmsBlock,
      editorFontSize,
      getPlaceholderConfigs,
      disableEdit,
    ],
  );

  const content =
    view === 'edit' ? (
      renderedEditor
    ) : (
      <EmbedView
        showTwitterEmbed={showTwitterEmbed}
        showYoutubeEmbed={showYoutubeEmbed}
        showFacebookEmbed={showFacebookEmbed}
        showInstagramEmbed={showInstagramEmbed}
        provider={instance?.mProperties?.provider}
        readLock={readLock}
        writeLock={writeLock}
      />
    );

  return (
    <SplitBar
      style={{ flex: '1 1 0' }}
      split="vertical"
      primary="second"
      pane1Style={{
        minWidth: showMetadata ? '40%' : '100%',
        maxWidth: showMetadata ? '70%' : '100%',
        display: 'flex',
        flex: 1,
      }}
      pane2Style={{
        minWidth: showMetadata ? '30%' : '0%',
        maxWidth: showMetadata ? '60%' : '0%',
        display: 'flex',
      }}
      hideResizer={!showMetadata}
    >
      <EditorContentWrapper
        role="presentation"
        onClick={(ev) => clickIfNothingSelected(ev, handleLockInstance)}
      >
        {content}
      </EditorContentWrapper>
      <Metadata
        fields={fields}
        metadata={pairedMetadata}
        parameterFields={parameterFields}
        onUpdateMeta={(newMetadata: MMetaDataField[]) => {
          if (instance) {
            void onMetadataChanged(newMetadata, instance);
          }
        }}
        disableEdit={disableEdit}
        onBackClick={undefined}
        usage={undefined}
      />
    </SplitBar>
  );
};

export default Body;
