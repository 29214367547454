import { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import AddMemberDropdown from 'components/addMember';
import { AssignedMember } from 'types/members';

interface Props {
  selectCallback: (m: AssignedMember) => void;
}

const StyledDropdown = styled(AddMemberDropdown)`
  .MuiAutocomplete-root {
    height: 32px !important;
  }
`;

const Wrapper = styled('div')`
  min-width: 250px;
`;

function SelectAssignee({ selectCallback }: Props) {
  const [selectedMembers, setSelectedMembers] = useState<AssignedMember[]>([]);

  useEffect(() => {
    selectCallback(selectedMembers[0]);
  }, [selectedMembers]);

  return (
    <Wrapper>
      <StyledDropdown
        variant="all"
        singleChoice
        autoFocus
        showChips
        selectOnFocus
        disableClearable={false}
        selectedMembers={selectedMembers}
        setSelectedMembers={setSelectedMembers}
        placeholderText="Find assignee"
        noOptionsText="Could not find assignee"
        injectedMembers={undefined}
      />
    </Wrapper>
  );
}

export default SelectAssignee;
