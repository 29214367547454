import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash';

import { useCreateMdf } from 'api/mdf/useCreateMdf';
import { useDeleteMdf } from 'api/mdf/useDeleteMdf';
import { useGetMdfs } from 'api/mdf/useGetMdfs';
import { ReactComponent as Add } from 'assets/icons/systemicons/add.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/systemicons/delete_small.svg';
import { ReactComponent as EditIcon } from 'assets/icons/systemicons/edit.svg';
import { IconButton } from 'components/buttons';
import { DeleteDialog } from 'components/dialogs/CommonDialogs';
import { EditMdf } from 'components/editMdfDialog/EditMdf';
import { useEditStringDialog } from 'components/editStringDialog/EditStringDialog';
import LoadingIndicator from 'components/loadingIndicator';
import SplitBar from 'components/split';
import Text from 'components/text/Text';
import Tooltip from 'components/tooltip/Tooltip';
import { Mdf } from 'types/graphqlTypes';

import { useChangedMdfs } from '../../atomsTs';

import {
  Actions,
  Form,
  FormsWrapper,
  Header,
  SchemaTitle,
  SideBarWrapper,
  Wrapper,
} from './styled';

interface MdfListItemProps {
  mdf: Mdf;
  isDefault: boolean;
  selectedForm: Mdf | null;
  changedMdfs: Record<string, Mdf>;
  setSelectedForm: React.Dispatch<React.SetStateAction<Mdf | null>>;
  openEditLabelDialog: (updatedMdf: Mdf | null, originalMdf: Mdf) => void;
  doDelete: (mdf: Mdf) => void;
}

function MdfListItem({
  changedMdfs,
  mdf,
  setSelectedForm,
  selectedForm,
  isDefault,
  openEditLabelDialog,
  doDelete,
}: Readonly<MdfListItemProps>) {
  const updatedMdf = changedMdfs[mdf.id];
  return (
    <Tooltip
      noArrow
      title={updatedMdf && `${updatedMdf?.label ?? mdf.label} has unsaved changes`}
      key={mdf.id}
    >
      <Form onClick={() => setSelectedForm(mdf)} $selected={mdf.id === selectedForm?.id}>
        <SchemaTitle>
          {updatedMdf ? (
            <Text variant="listItemLabelItalic" color="statusWarning">
              {`* ${updatedMdf?.label ?? mdf.label}`}
            </Text>
          ) : (
            <Text
              variant="listItemLabel"
              color={mdf.id === selectedForm?.id ? 'whiteHighEmphasis' : 'highEmphasis'}
            >
              {mdf.label}
            </Text>
          )}
        </SchemaTitle>
        {!isDefault && (
          <Actions>
            <IconButton
              title="Edit schema name"
              usage="text"
              size={24}
              iconSize={18}
              onClick={() => openEditLabelDialog(changedMdfs[mdf.id] ?? null, mdf)}
              className="editIcon"
            >
              <EditIcon />
            </IconButton>
            <IconButton
              title="Delete schema"
              usage="text"
              size={24}
              onClick={() => doDelete(mdf)}
              className="deleteIcon"
            >
              <DeleteIcon />
            </IconButton>
          </Actions>
        )}
      </Form>
    </Tooltip>
  );
}

export const MdfSchemas: React.FC = (): JSX.Element => {
  const { mdfsSeparated, loading, error } = useGetMdfs({ all: true });
  const { createMdf } = useCreateMdf();
  const { deleteMdf } = useDeleteMdf();
  const [, showEditStringDialog] = useEditStringDialog();
  const [mdfToDelete, setMdfToDelete] = useState<Mdf | null>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedForm, setSelectedForm] = useState<Mdf | null>(null);
  const [changedMdfs, setChangedMdfs] = useChangedMdfs();
  const [settingsHeight, setSettingsHeight] = useState(300);

  const mdfs = useMemo(() => {
    return [...mdfsSeparated.defaults, ...mdfsSeparated.custom];
  }, [mdfsSeparated]);

  useLayoutEffect(() => {
    if (mdfs?.length > 0 && !selectedForm) {
      setSelectedForm(mdfs[0]);
    }
  }, [mdfs]);

  const closeDeleteDialog = useCallback(() => {
    setDeleteModalOpen(false);
  }, []);

  const createNewMdf = useCallback(
    async (formTitle: string) => {
      const newMdf = await createMdf({ label: formTitle });
      if (newMdf) {
        setSelectedForm(newMdf);
      }
    },
    [setSelectedForm, createMdf],
  );

  const openCreateDialog = useCallback(() => {
    showEditStringDialog({
      headerText: 'Create new form',
      required: true,
      onConfirm: (val) => {
        void createNewMdf(val);
      },
    });
  }, [showEditStringDialog, createNewMdf]);

  const doDeleteForm = useCallback(async () => {
    if (mdfToDelete) {
      await deleteMdf(mdfToDelete.id);
      if (mdfToDelete.id === selectedForm?.id) {
        setSelectedForm(mdfs[0]);
      }
      setMdfToDelete(null);
      closeDeleteDialog();
    }
  }, [mdfToDelete]);

  const doDelete = useCallback((mdf: Mdf) => {
    setMdfToDelete(mdf);
    setDeleteModalOpen(true);
  }, []);

  const onMdfChange = useCallback(
    (mdf: Mdf | null, originalMdf: Mdf | null) => {
      if (mdf === null || originalMdf === null) return;
      if (!changedMdfs[mdf.id] && !isEqual(mdf, originalMdf)) {
        setChangedMdfs({
          ...changedMdfs,
          [mdf.id]: mdf,
        });
      } else if (changedMdfs[mdf.id]) {
        if (isEqual(mdf, originalMdf)) {
          const copy = { ...changedMdfs };
          delete changedMdfs[mdf.id];
          setChangedMdfs(copy);
        } else {
          setChangedMdfs((prevState) => {
            return {
              ...prevState,
              [mdf.id]: mdf,
            };
          });
        }
      }
      setSelectedForm(mdf);
    },
    [changedMdfs, setSelectedForm, setChangedMdfs],
  );

  const openEditLabelDialog = useCallback(
    (updatedMdf: Mdf | null, originalMdf: Mdf) => {
      showEditStringDialog({
        headerText: 'Edit form label',
        required: true,
        startValue: updatedMdf?.label ?? originalMdf.label,
        onConfirm: (val) => {
          const copy = { ...(updatedMdf ?? originalMdf) };
          copy.label = val;
          onMdfChange(copy, originalMdf);
        },
      });
    },
    [showEditStringDialog, createNewMdf, changedMdfs],
  );

  const resize = useCallback(() => {
    const wrapper = document.getElementById('settings-wrapper');
    // We are reducing the available height manually, the static numbers represent
    // header, tabs, sub header and footer which do not change in height.
    // Ideally the entire settings dialog layout is properly refactored to use
    // flex correctly at a later point.
    const subHeight = (wrapper?.getBoundingClientRect().height ?? 400) - 57 - 48 - 30 - 44;
    setSettingsHeight(subHeight);
  }, [setSettingsHeight]);

  useEffect(() => {
    const resizeListener = () => resize();
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, [resize]);

  useLayoutEffect(() => {
    resize();
  }, [mdfsSeparated]);

  const SideBar = useMemo(() => {
    return (
      <FormsWrapper>
        <Header>
          <Text variant="overline" color="highEmphasis">
            System defaults
          </Text>
        </Header>
        {loading || (error && <LoadingIndicator className={undefined} height={32} width={32} />)}
        <SideBarWrapper height={settingsHeight}>
          {mdfsSeparated.defaults.map((mdf) => (
            <MdfListItem
              key={mdf.id}
              mdf={mdf}
              isDefault={true}
              changedMdfs={changedMdfs}
              selectedForm={selectedForm}
              setSelectedForm={setSelectedForm}
              openEditLabelDialog={openEditLabelDialog}
              doDelete={doDelete}
            />
          ))}
          <Header inline>
            <Text variant="overline" color="highEmphasis">
              Custom schemas
            </Text>
            <IconButton
              title="Create schema"
              size={24}
              iconSize={20}
              usage="text"
              disabled={!!(loading || error)}
              onClick={openCreateDialog}
            >
              <Add />
            </IconButton>
          </Header>
          <div>
            {mdfsSeparated.custom.map((mdf) => (
              <MdfListItem
                key={mdf.id}
                mdf={mdf}
                isDefault={false}
                changedMdfs={changedMdfs}
                selectedForm={selectedForm}
                setSelectedForm={setSelectedForm}
                openEditLabelDialog={openEditLabelDialog}
                doDelete={doDelete}
              />
            ))}
          </div>
        </SideBarWrapper>
      </FormsWrapper>
    );
  }, [
    loading,
    error,
    settingsHeight,
    mdfsSeparated,
    changedMdfs,
    selectedForm,
    setSelectedForm,
    openEditLabelDialog,
    openCreateDialog,
    doDelete,
  ]);

  return (
    <Wrapper>
      <SplitBar
        split={undefined}
        style={{
          height: '100%',
        }}
        primary="first"
        pane1Style={{
          minWidth: '180px',
          maxWidth: '300px',
        }}
        pane2Style={{
          minWidth: '200px',
        }}
      >
        {SideBar}
        {selectedForm ? (
          <EditMdf mdf={changedMdfs[selectedForm.id] ?? selectedForm} onChange={onMdfChange} />
        ) : (
          <div />
        )}
      </SplitBar>
      <DeleteDialog
        open={deleteModalOpen}
        onClose={closeDeleteDialog}
        onClick={doDeleteForm}
        title="Delete schema?"
        message={`Are you sure you want to delete ${mdfToDelete?.label}?
        This may have unintended side effects places this schema is in use, and cannot be undone.`}
      />
    </Wrapper>
  );
};
