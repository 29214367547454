import { ReactComponent as Close } from 'assets/icons/systemicons/close.svg';
import { ReactComponent as DraftIcon } from 'assets/icons/systemicons/draft_off.svg';
import { ReactComponent as Pitch } from 'assets/icons/systemicons/HeaderNavbar/pitch_on.svg';
import { ReactComponent as Open } from 'assets/icons/systemicons/open.svg';
import { ReactComponent as OrderIcon } from 'assets/icons/systemicons/orders.svg';
import { IconButton } from 'components/buttons';
import Text from 'components/text/Text';
import PublishButton from 'features/publishButton';
import { HStack } from 'layouts/box/Box';
import { MemberType } from 'types/graphqlTypes';
import { isoToLocaleShort } from 'utils/datetimeHelpers';

import { Header, Icon, TitleInput } from './styled';

interface Props {
  onClose: () => void;
  member: MemberType | null;
  handleOpenStory?: () => void;
  icon: React.ReactNode;
  canOpenInTab?: boolean;
  actionButtons?: React.ReactNode;
  titleComponent?: React.ReactNode;
  handleTitleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  title?: string;
}

const BaseHeader = ({
  onClose,
  member,
  handleOpenStory,
  icon,
  canOpenInTab,
  actionButtons = null,
  handleTitleChange,
  title,
}: Props) => {
  return (
    <Header>
      <HStack gap="4px" flexGrow={1}>
        {icon}
        {handleTitleChange ? (
          <TitleInput name="edit-title" value={title} onChange={handleTitleChange} />
        ) : (
          <Text truncate variant="h6" color="highEmphasis">
            {member?.mTitle}
          </Text>
        )}
      </HStack>

      <HStack gap="8px">
        <Text variant="caption" style={{ whiteSpace: 'nowrap' }}>
          Modified {isoToLocaleShort(member?.mUpdatedAt ?? member?.mCreatedAt)}
        </Text>
        {actionButtons}
        {canOpenInTab && (
          <IconButton
            height={24}
            width={24}
            variant="discreet"
            usage="text"
            onClick={handleOpenStory}
            title="Open in tab"
          >
            <Open />
          </IconButton>
        )}
        <IconButton
          variant="discreet"
          usage="text"
          round
          onClick={onClose}
          title="Close"
          height={24}
          width={24}
        >
          <Close />
        </IconButton>
      </HStack>
    </Header>
  );
};

interface PitchHeaderType extends Pick<Props, 'onClose' | 'member' | 'handleOpenStory'> {}

export const PitchHeader = ({ ...props }: PitchHeaderType) => {
  return (
    <BaseHeader
      {...props}
      icon={
        <Icon type="pitch">
          <Pitch />
        </Icon>
      }
    />
  );
};

interface StoryHeaderType extends Pick<Props, 'onClose' | 'member' | 'handleOpenStory'> {
  canUpdatePublishing: boolean;
}

export const StoryHeader = ({
  onClose,
  member,
  handleOpenStory,
  canUpdatePublishing,
}: StoryHeaderType) => {
  return (
    <Header isStory>
      <div style={{ flexShrink: 0 }}>
        {member && <PublishButton member={member} canUpdatePublishing={canUpdatePublishing} />}
      </div>
      <Text truncate variant="h6" color="highEmphasis" style={{ textAlign: 'center' }}>
        {member?.mTitle}
      </Text>
      <HStack gap="8px" overflow="visible">
        <Text variant="caption" style={{ whiteSpace: 'nowrap' }}>
          Modified {isoToLocaleShort(member?.mUpdatedAt ?? member?.mCreatedAt)}
        </Text>
        <IconButton
          height={24}
          width={24}
          variant="discreet"
          usage="text"
          onClick={handleOpenStory}
          title="Open in tab"
        >
          <Open />
        </IconButton>
        <IconButton
          variant="discreet"
          usage="text"
          round
          onClick={onClose}
          title="Close"
          height={24}
          width={24}
        >
          <Close />
        </IconButton>
      </HStack>
    </Header>
  );
};

interface OrderDrawerContentProps {
  order: MemberType;
  onClose: () => void;
  handleOpenOrder: () => void;
  title: string;
}

export const OrderHeader = ({
  order,
  onClose,
  handleOpenOrder,
  title,
}: Readonly<OrderDrawerContentProps>) => {
  return (
    <BaseHeader
      onClose={onClose}
      canOpenInTab
      handleOpenStory={handleOpenOrder}
      member={order}
      title={title}
      icon={
        <HStack gap="8px" overflow="visible">
          <Icon type="draft">
            <OrderIcon />
          </Icon>
        </HStack>
      }
    />
  );
};

interface DraftDrawerContentProps {
  draft: MemberType;
  onClose: () => void;
  title: string;
  setTitle: (title: string) => void;
}

export const DraftHeader = ({
  draft,
  onClose,
  title,
  setTitle,
}: Readonly<DraftDrawerContentProps>) => {
  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  return (
    <BaseHeader
      onClose={onClose}
      member={draft}
      handleTitleChange={handleTitleChange}
      title={title}
      actionButtons={<PublishButton member={draft} canUpdatePublishing={true} />}
      icon={
        <Icon type="draft">
          <DraftIcon />
        </Icon>
      }
    />
  );
};
