import { css } from '@emotion/react';
import styled from '@emotion/styled';
import FilledInput from '@material-ui/core/FilledInput';

import Slot from 'lib/slot';
import transientOptions from 'theme/helpers';

import { TextProps } from './Text';

const defaultColor = 'mediumEmphasis';
const defaultFontStyles = 'body1';

interface TextStyleProps extends TextProps {
  $truncate?: boolean;
}

export const TextWrapper = styled(Slot, transientOptions)<TextStyleProps>`
  margin: 0;
  ${({ theme, variant }) => theme?.typography?.dina[variant ?? defaultFontStyles]};
  color: ${({ theme, color }) => theme?.palette?.dina[color ?? defaultColor]};

  ${({ $truncate }) => {
    return (
      $truncate &&
      css`
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      `
    );
  }};
`;

// Editable Text
export const Input = styled(FilledInput)<{
  $variant: TextProps['variant'];
  $color: TextProps['color'];
  $borderColor?: TextProps['color'];
  $textAlign?: 'left' | 'center' | 'right';
}>`
  width: 100%;
  .MuiFilledInput-input {
    height: auto;
    padding: 0.5rem 1rem;
    ${({ theme, $variant }) => theme?.typography?.dina[$variant ?? defaultFontStyles]};
    color: ${({ theme, $color }) => theme?.palette?.dina[$color ?? defaultColor]};
    border: 1px solid
      ${({ theme, $borderColor = 'inputBorderResting' }) => theme.palette.dina[$borderColor]};
    border-radius: 6px;
    background: ${({ theme }) => theme.palette.dina.inputBackground};
    text-align: ${({ $textAlign = 'left' }) => $textAlign};
  }

  &.MuiFilledInput-underline {
    &::before,
    &::after {
      border: none;
    }
  }
`;
