import { useContext, useState } from 'react';
import { FetchResult, useMutation } from '@apollo/client';
import isToday from 'date-fns/is_today';
import { debounce } from 'lodash';

import { ReactComponent as ArrowRight } from 'assets/icons/systemicons/arrows/disclosurearrow_right.svg';
import CategorizedMenu from 'components/menu/createInstanceMenu/MenuItem/CategorizedMenu';
import PlatformIcons from 'components/menu/createInstanceMenu/PlatformIcons';
import Popover from 'components/popover/Popover';
import Scrollbar from 'components/scrollbar/scrollbar';
import ConfigContext from 'contexts/configContext';
import CREATE_INSTANCE from 'graphql/mutations/createInstance';
import useGetRundown from 'hooks/useGetRundown';
import useSettingsValue from 'hooks/useSettingsValue';
import { MenuInfoWrapper, MenuItemText } from 'lib/Menu/MenuItem';
import { isBeforeToday } from 'screens/rundown/components/editor/utils';
import { useSelectedDate } from 'screens/story/components/header/atoms';
import { useStoryMolecule } from 'screens/storyV2/store/story';
import { useStoryPaneMolecule } from 'screens/storyV2/store/storyPane';
import usePanes from 'screens/storyV2/useStoryPanes';
import { AtomDateRange, usePolicies } from 'store';
import { Instance, Platform, PlatformAccountType } from 'types';
import { CreateInstanceInput, MemberType, MemberTypeEnum } from 'types/graphqlTypes';
import checkUserRight from 'utils/checkUserRight';
import { UNTITLED_STORY } from 'utils/constants';
import getEmptyMetadataForForm from 'utils/getEmptyMetadata';
import getIdentifier from 'utils/instance/getAccountIdentifier';
import updateStoryInstanceCache from 'utils/instance/updateStoryInstanceCache';
import { CustomFields, getTwitterMetaKey } from 'utils/metadata';
import preventDefaultAndPropagation from 'utils/preventDefaultAndStopPropagation';

import { MenuContainer, StyledMenuItem, SvgHoverWrapper } from './styled';

interface Props {
  platform: Platform;
  closeMenu: () => void;
  paneIndex?: number;
}

function PlatformItem({ platform, closeMenu, paneIndex }: Readonly<Props>) {
  const { updateStoryPane } = usePanes();
  const { mProperties, mTitle } = platform;
  const { platform: platformName, platformKind, accounts, platformIcon } = mProperties;
  const { metadataForms } = useContext(ConfigContext);
  const [policies] = usePolicies();
  const canScheduleInstance = checkUserRight(policies, 'instance', 'schedule');
  const [getSettingsValue] = useSettingsValue();
  const { useStory } = useStoryMolecule();
  const { useCreatingInstance, useNewlyAddedInstance } = useStoryPaneMolecule();
  const [story] = useStory();
  const [getRundown] = useGetRundown();
  const [selectedDate, setSelectedDate] = useSelectedDate();
  const [, setCreatingInstance] = useCreatingInstance();
  const [, setNewlyAddedInstance] = useNewlyAddedInstance();
  const [, setToggleRender] = useState(false);

  const defaultReadSpeed = getSettingsValue('rundown.defaultReadSpeed') as string;
  const blankMetaData = getEmptyMetadataForForm(metadataForms[0]);

  const [selected, setSelected] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const filteredAccounts =
    canScheduleInstance || mTitle !== 'current rundowns'
      ? (accounts as PlatformAccountType[])
      : // eslint-disable-next-line react/prop-types
        (accounts as PlatformAccountType[]).filter(
          (account) => account.accountTitle === 'Unassigned',
        );

  const Icon =
    PlatformIcons[platformIcon as keyof typeof PlatformIcons] || PlatformIcons.defaultIcon;

  const label = platformKind && platformKind !== 'tv' ? platformKind : platformName;

  const handlePlatformClick: React.MouseEventHandler<HTMLLIElement> = (e) => {
    setAnchorEl(e.currentTarget);
    setSelected(true);
  };

  const handleCloseSubMenu = () => {
    setAnchorEl(null);
    setSelected(false);
  };

  const [createInstanceForStory] = useMutation(CREATE_INSTANCE, {
    update: (proxy, mutationResult: Omit<FetchResult<{ createInstance: Instance }>, 'context'>) => {
      const { createInstance } = mutationResult.data as { createInstance: Instance };
      updateStoryInstanceCache(proxy, story?.mId as string, createInstance);
      setNewlyAddedInstance(createInstance);
      updateStoryPane(paneIndex ?? 0, 'instances', createInstance.mRefId);
      handleCloseSubMenu();
      closeMenu();
    },
  });

  const handleAccountClick = debounce((account: PlatformAccountType) => {
    if (!platform || !platformName || !account || !story) return;
    handleCloseSubMenu();
    closeMenu();
    const canCreateInstances = checkUserRight(
      policies,
      'platform',
      getIdentifier(platformName, account.accountTitle),
    );
    if (!canCreateInstances) return;

    setCreatingInstance(true);
    updateStoryPane(paneIndex ?? 0, 'instances');

    const platformProperties = {
      __typename: 'PlatformType',
      platform: platformName,
      account: {
        accountUrl: account.accountUrl,
        accountLogo: account.accountLogo,
        accountTitle: account.accountTitle,
        accountId: account.accountId,
      },
      platformKind: (platformKind as string) ?? undefined,
    };

    const title = story.mTitle === UNTITLED_STORY ? '' : story.mTitle;

    let input: CreateInstanceInput = {
      mId: story.mId,
      mTitle: title,
      mDescription: '',
      mProperties: platformProperties,
      mMetaData: [{ key: CustomFields.STORY_TITLE, value: title }],
      ...(story?.mType === MemberTypeEnum.ResStory ? { isRestricted: true } : {}),
    };

    if (platformName === 'linear') {
      if (account.isUnassigned) {
        createInstanceForStory({
          variables: {
            input,
          },
        }).then(
          () => {},
          () => {},
        );
        return;
      }

      getRundown(account.accountId as string, account.accountId as string)
        .then((rundown) => {
          if (rundown) {
            const hostMetaData = rundown?.mMetaData?.find((val) => val.key === 'host');
            const host =
              hostMetaData && hostMetaData.value
                ? (JSON.parse(hostMetaData.value) as MemberType)
                : null;

            input = {
              ...input,
              mPublishingAt: rundown.mPublishingAt,
              mProperties: {
                ...input.mProperties,
                account: { ...input.mProperties?.account, accountRefId: rundown.mRefId },
              },
              mMetaData: [
                ...(input?.mMetaData ?? []),
                {
                  key: 'hostReadSpeed',
                  value: (host?.mProperties?.readSpeed as string) ?? defaultReadSpeed,
                },
              ],
            };
          } else {
            input = {
              ...input,
              mProperties: {
                ...input.mProperties,
                account: { ...input.mProperties?.account, accountId: undefined },
              },
            };
          }

          createInstanceForStory({
            variables: {
              input,
            },
          }).then(
            () => {},
            () => {},
          );
        })
        .then(
          () => {},
          () => {},
        );
      return;
    }

    if (platformName === 'twitter') {
      input = {
        ...input,
        mMetaData: [
          ...(input?.mMetaData ?? []),
          {
            key: getTwitterMetaKey(blankMetaData),
            value: '1',
          },
        ],
      };
    }

    createInstanceForStory({
      variables: {
        input,
      },
    }).then(
      () => {},
      () => {},
    );
  }, 300);

  const onDateChanged = (e: AtomDateRange) => {
    setSelectedDate((e.endDate as unknown as string) || null);
  };

  return (
    <>
      <StyledMenuItem selected={selected} onClick={handlePlatformClick}>
        <Icon />
        <MenuItemText $isTitle primary={label.toLowerCase() === 'cms' ? 'CMS' : label} />
        {filteredAccounts.length && (
          <MenuInfoWrapper>
            <MenuItemText primary={filteredAccounts.length} />
            <SvgHoverWrapper>
              <ArrowRight />
            </SvgHoverWrapper>
          </MenuInfoWrapper>
        )}
      </StyledMenuItem>
      {filteredAccounts.length && (
        <Popover
          noMargin
          onClose={handleCloseSubMenu}
          anchorEl={anchorEl}
          position="right"
          style={{ zIndex: 1301 }}
        >
          <Scrollbar autoHeight autoHeightMin={0} autoHeightMax="60vh" top={8} bottom={8}>
            {platformName === 'linear' ? (
              <MenuContainer>
                <CategorizedMenu
                  onDateChanged={onDateChanged}
                  publishingAt={selectedDate ?? new Date().toISOString()}
                  disableDecrement={
                    isToday(selectedDate ?? new Date().toISOString()) ||
                    isBeforeToday(selectedDate ?? new Date().toISOString())
                  }
                  items={filteredAccounts}
                  onClose={(account) => {
                    handleAccountClick(account);
                  }}
                  onDoubleClick={preventDefaultAndPropagation}
                  setToggleRender={setToggleRender}
                  dense
                  darker
                >
                  <Icon />
                </CategorizedMenu>
              </MenuContainer>
            ) : (
              <>
                {filteredAccounts.map((account) => (
                  <StyledMenuItem
                    key={`${account.accountTitle} ${account.accountUrl}`}
                    $type="account"
                    $isUnassigned={account.isUnassigned && filteredAccounts.length > 1}
                    onClick={() => {
                      handleAccountClick(account);
                    }}
                    onDoubleClick={preventDefaultAndPropagation}
                    disabled={
                      !checkUserRight(
                        policies,
                        'platform',
                        getIdentifier(platformName, account.accountTitle),
                      )
                    }
                  >
                    <Icon />
                    <MenuItemText $isUnassigned={account.isUnassigned}>
                      {account.accountTitle}
                    </MenuItemText>
                  </StyledMenuItem>
                ))}
              </>
            )}
          </Scrollbar>
        </Popover>
      )}
    </>
  );
}

export default PlatformItem;
