import { useEffect, useRef, useState } from 'react';

type Size = {
  width: number;
  height: number;
};

const useCalculateContainerDimension = (targetRef: React.RefObject<Element>): Size => {
  const [size, setSize] = useState<Size>({ width: 0, height: 0 });
  const resizeObserverRef = useRef<ResizeObserver | null>(null);

  useEffect(() => {
    resizeObserverRef.current = new ResizeObserver((entries) => {
      if (!Array.isArray(entries)) return;

      const entry = entries[0];
      if (entry?.contentRect) {
        setSize({
          width: entry.contentRect.width,
          height: entry.contentRect.height,
        });
      }
    });

    const { current: resizeObserver } = resizeObserverRef;
    if (targetRef.current) {
      resizeObserver.observe(targetRef.current);
    }

    return () => {
      if (resizeObserverRef.current) {
        if (targetRef.current) {
          resizeObserverRef.current.unobserve(targetRef.current);
        }
        resizeObserverRef.current.disconnect();
      }
    };
  }, [targetRef]);

  return size;
};

export default useCalculateContainerDimension;
