import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { distanceInWordsToNow } from 'date-fns';
import capitalize from 'utils/capitalize';
import Divider from 'components/divider';
import MessageSeen from '../messageSeen';
import Button from './button-view';
import useStyles from './infoRow-styles';

const informationText = (isSelfMessage, timeDistance, mCreatedAt, mUpdatedAt) => {
  const timingInfo = isSelfMessage ? `${capitalize(timeDistance)}` : timeDistance;
  return mCreatedAt === mUpdatedAt ? timingInfo : `${timingInfo} (edited)`;
};

const InfoRow = ({
  isSelfMessage,
  onDelete,
  onEdit,
  onOpen,
  senderName,
  mCreatedAt,
  mUpdatedAt,
  messageSeen,
  userId,
  showEditButton,
  showDeleteButton,
  canOpenStory,
}) => {
  const classes = useStyles({ isSelfMessage });
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const confirmDelete = async () => {
    setShowDeleteConfirm(false);
    await onDelete();
  };

  const handleCancelClick = () => {
    setShowDeleteConfirm(false);
  };

  const handleDeleteClick = () => {
    setShowDeleteConfirm(true);
  };

  const ActionEventDiv = () => {
    if (isSelfMessage)
      return (
        <div className={classes.actionEventContainer}>
          {showDeleteConfirm ? (
            <>
              <Button text="Cancel" onClick={handleCancelClick} />
              <Button
                text="Click again to confirm deletion"
                onClick={confirmDelete}
                type="danger"
              />
            </>
          ) : (
            <>
              {showEditButton && <Button text="Edit" onClick={onEdit} />}
              {showDeleteButton && <Button text="Delete" onClick={handleDeleteClick} />}
            </>
          )}
        </div>
      );
    if (senderName)
      return (
        <div className={classes.messageInfo}>
          <Divider orientation="vertical" className={classes.divider} />
          {senderName}
        </div>
      );

    return null;
  };

  const timeDistance = distanceInWordsToNow(mCreatedAt, { addSuffix: true });

  return (
    <div className={classes.infoRow}>
      <div className={classes.messageInfo}>
        {canOpenStory && (
          <>
            <Button text="Open..." onClick={onOpen} type="emphasis" />
            <Divider orientation="vertical" className={classes.divider} />
          </>
        )}
        <div>{informationText(isSelfMessage, timeDistance, mCreatedAt, mUpdatedAt)}</div>
        <ActionEventDiv />
      </div>
      <MessageSeen messageSeen={messageSeen} userId={userId} />
    </div>
  );
};

InfoRow.propTypes = {
  /** boolean to indicate users own message */
  isSelfMessage: PropTypes.bool,
  /** callback to delete own message */
  onDelete: PropTypes.func,
  /** callback to edit own message */
  onEdit: PropTypes.func,
  /** name of the sender */
  senderName: PropTypes.string,
  /** time of message creation */
  mCreatedAt: PropTypes.string,
  /** info about the message seen */
  messageSeen: PropTypes.arrayOf(PropTypes.shape({})),
  /** id of the user */
  userId: PropTypes.string,
  /** boolean that shows the open button that takes to the story */
  canOpenStory: PropTypes.bool,
  /** callback to open story  */
  onOpen: PropTypes.func,
  /** boolean to show edit button */
  showEditButton: PropTypes.bool,
  /** boolean to show delete button */
  showDeleteButton: PropTypes.bool,
};

InfoRow.defaultProps = {
  isSelfMessage: false,
  onDelete: () => {},
  onEdit: () => {},
  senderName: '',
  mCreatedAt: '',
  messageSeen: [],
  userId: '',
  canOpenStory: false,
  onOpen: () => {},
  showEditButton: true,
  showDeleteButton: true,
};

export default InfoRow;
