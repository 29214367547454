import React, { useState, useEffect, memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import convert from 'utils/convertInputStringToTimeString';
import useInputEvents from 'hooks/useInputEvents';

import { StyledDurationWrapper, StyledDurationInput } from './styled';

const DurationView = ({ duration, onUpdate, disableEdit, placeholder, tabIndex }) => {
  const [value, setValue] = useState(duration);
  const [error, setError] = useState(false);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      error && setError(false);
    }, 2000);
    return () => {
      clearTimeout(timeOut);
    };
  }, [error]);

  const onUpdateInput = (newValue) => {
    if (newValue === duration) setValue(newValue);
    else {
      const { value: formattedValue, isError } = convert(newValue.trim(), true);
      if (isError) setError(true);
      setValue(formattedValue);
      onUpdate(formattedValue);
    }
  };

  const [inputRef, onKeyDown, onBlur] = useInputEvents(onUpdateInput, value, duration);

  useEffect(() => setValue(duration), [duration]);

  const onChange = useCallback((event) => setValue(event.target.value), []);

  return (
    <StyledDurationWrapper>
      <StyledDurationInput
        $error={error}
        type="text"
        ref={inputRef}
        disabled={disableEdit}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        placeholder={placeholder}
        inputProps={{ tabIndex }}
      />
    </StyledDurationWrapper>
  );
};

DurationView.propTypes = {
  /** duration value for the input */
  duration: PropTypes.string,
  /** Callback to be invoked when user finishes updating duration value and
   * moves focus to elsewhere, with the updated value passed in
   */
  onUpdate: PropTypes.func,
  /** If true, disables editability of the duration value */
  disableEdit: PropTypes.bool,
  /** type */
  placeholder: PropTypes.string.isRequired,
  /** should be accessible by tab */
  tabIndex: PropTypes.oneOf([0, -1]),
};

DurationView.defaultProps = {
  duration: '00:00:00',
  onUpdate: (newDuration) => {},
  disableEdit: false,
  tabIndex: 0,
};

export default memo(DurationView);
