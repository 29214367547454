import React from 'react';
import PropTypes from 'prop-types';
import Divider from 'components/divider';
import MessageInput from 'components/messageInput';
import Header from './header';
import Messages from './messages';
import { RootWrapper, MessageContainer, MessageInputWrapper } from './messageContents-styled';

const MessageContents = ({ createMessage, showInput }) => {
  const handleSendMessage = async (newMessage, mRefId, crudAction) => {
    try {
      await createMessage(JSON.stringify(newMessage), mRefId, crudAction);
    } catch (e) {
      // console.log(e);
    }
  };

  return (
    <RootWrapper>
      <Header showDeleteButton={false} showAddUser={false} />
      <MessageContainer>
        <Messages handleSendMessage={handleSendMessage} />
      </MessageContainer>
      {showInput && (
        <>
          <Divider />
          <MessageInputWrapper>
            <MessageInput onSend={handleSendMessage} />
          </MessageInputWrapper>
        </>
      )}
    </RootWrapper>
  );
};

MessageContents.propTypes = {
  /** message creating callback */
  createMessage: PropTypes.func,
  /** boolean to show messageInput */
  showInput: PropTypes.bool,
};

MessageContents.defaultProps = {
  createMessage: () => {},
  showInput: true,
};

export default MessageContents;
