import { useEffect, useState } from 'react';

import Tooltip from 'components/tooltip';
import { FieldValue } from 'types/forms/forms';
import { DefaultValueWrapper, FieldTypeEnum } from 'types/graphqlTypes';

import { FieldHeader } from '../../styled';
import { FieldProps } from '../fields';

import { StyledTextField, TextWrapper } from './styled';

export const getValue = (
  value: FieldValue,
  defaultValue: DefaultValueWrapper,
  ignoreDefaultValue: boolean,
  type: FieldTypeEnum,
) => {
  if (type === FieldTypeEnum.text) {
    if (typeof value === 'string') {
      return value;
    }
    if (typeof defaultValue.value === 'string' && !ignoreDefaultValue) {
      return defaultValue.value;
    }
  }

  if (type === FieldTypeEnum.number) {
    if (typeof value === 'number') {
      return value;
    }

    if (value) {
      return +value;
    }

    if (typeof defaultValue.value === 'number' && !ignoreDefaultValue) {
      return defaultValue.value;
    }
  }

  return null;
};

export function TextField({
  fieldModel,
  fieldSettings,
  defaultFieldSettings,
  value,
  setValue,
  style,
  errorMessage,
  setError,
  autoFocus,
  fireOnChange,
  ignoreDefaultValue,
}: Readonly<FieldProps>) {
  const [liveValue, setLiveValue] = useState<string | number | null>(null);
  const { fieldId, defaultValue, required, type } = fieldModel;
  const { hint, label, multiline = false, fieldHeight = 3 } = fieldSettings ?? defaultFieldSettings;

  useEffect(() => {
    setLiveValue(getValue(value, defaultValue, ignoreDefaultValue ?? false, type));
  }, [value, defaultValue]);

  useEffect(() => {
    if (required && !liveValue) {
      setError('Required value');
    } else if (required && liveValue && errorMessage) {
      setError(undefined);
    } else if (!required && errorMessage) {
      setError(undefined);
    }
  }, [liveValue, errorMessage, required]);

  return (
    <Tooltip title={hint ?? ''}>
      <TextWrapper key={fieldId} style={style}>
        <FieldHeader>{label}</FieldHeader>
        <StyledTextField
          variant="filled"
          type={type === FieldTypeEnum.text ? 'text' : 'number'}
          placeholder="Enter your value here"
          onChange={(ev) => {
            if (fireOnChange) {
              setValue(ev.target.value);
            } else {
              setLiveValue(ev.target.value);
            }
          }}
          value={(fireOnChange ? value : liveValue) ?? ''}
          multiline={multiline}
          minRows={fieldHeight}
          autoFocus={autoFocus}
          maxRows={20}
          error={Boolean(errorMessage)}
          helperText={errorMessage?.length ? errorMessage : undefined}
          inputProps={{
            onBlur: () => {
              setValue(liveValue ?? '');
            },
          }}
        />
      </TextWrapper>
    </Tooltip>
  );
}
