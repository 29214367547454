import { PlatformSectionConfig } from 'types/graphqlTypes';
import unwrapSection from './unwrapSection';
import wrapSection from './wrapSection';
import { getMetaDataValueFromConfig } from '../../../utils/getMetaDataValueFromConfig';
import { elementTypes } from '../../../constants';

/**
 *
 * @param {Object} editor SlateJS editor instance
 * @param {Function} update callback for updating states
 * @param {PlatformSectionConfig} config configuration data of the element from platform
 * @param {Boolean} unwrap Should unwrap the selection
 * @returns void
 */
const onWrapSection = (editor, update, config, unwrap) => {
  if (unwrap) {
    unwrapSection(editor, { split: true });
    return;
  }

  wrapSection({
    editor,
    update,
    data: {
      mTitle: getMetaDataValueFromConfig(config, elementTypes.SECTION_DIVIDER, 'defaultTitle'),
    },
  });
};

export default onWrapSection;
