import React, { useState, useEffect, memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSlate, useReadOnly } from 'slate-react';
import { checkIfDragDisabled, removeBlock, updateBlock } from 'components/editor/utils';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import { CheckboxWithLabel } from 'components/createNewV3/CreateNew';
import { elementTypes } from 'components/editor/constants/types';
import useChangeCollapse from 'components/editor/hooks/useChangeCollapse';
import AutoComplete from './components/autoComplete';
import Content from './components/content';
import Box from '../box';
import {
  NotificationIcon,
  CheckboxWrapper,
  ContentWrapper,
  AutocompleteWrapper,
  CollapsedWrapper,
  DestinationWrapper,
} from './styled';
import DragAndDrop from '../dragAndDrop';

const Checkbox = memo(CheckboxWithLabel);

const Notification = ({ attributes, children, element }) => {
  const editor = useSlate();
  const { update, variant } = useEditorContext();
  const readOnly = useReadOnly();

  const [onChangeCollapse] = useChangeCollapse(element);

  const { data } = element;
  const { collapsed, send, content, destinations } = data;

  const [boxType, setBoxType] = useState('default');

  useEffect(() => {
    if (send) setBoxType('error');
    else setBoxType('default');
  }, [send]);

  const onMenuSelect = useCallback(
    ({ action }) => {
      if (action === 'delete-block') removeBlock(editor, element, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleCheckboxClick = useCallback(
    (e) => {
      const updatedData = {
        ...data,
        send: !send,
      };
      updateBlock(editor, element, updatedData, update, false);
    },
    [data, editor, element, send, update],
  );

  const updateContent = useCallback(
    (newContent) => {
      const updatedData = {
        ...data,
        content: newContent,
      };
      updateBlock(editor, element, updatedData, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  const updateDestinations = useCallback(
    (newDestinations) => {
      const updatedData = {
        ...data,
        destinations: newDestinations,
      };
      updateBlock(editor, element, updatedData, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  const collapsedComponent = useMemo(() => {
    if (!send) return 'Not active...';
    return (
      <CollapsedWrapper>
        {content}
        {Array.isArray(destinations) && destinations.length > 0 ? (
          <span>
            DESTINATIONS:{' '}
            <DestinationWrapper>
              &quot;{destinations.map((d) => d.value).join('", "')}&quot;
            </DestinationWrapper>
          </span>
        ) : (
          ''
        )}
      </CollapsedWrapper>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, destinations, send]);

  const notificationView = useMemo(
    () => (
      <Box
        iconComponent={<NotificationIcon className="skipOverride" />}
        title="Notification message/Banner on publish time"
        readOnly={readOnly}
        type={boxType}
        updateCollapsed={onChangeCollapse}
        collapsed={collapsed}
        collapsedContent={collapsedComponent}
        hideEllipsisButton
        onMenuSelect={onMenuSelect}
      >
        <CheckboxWrapper>
          <Checkbox
            label="Send a Notification on publish time"
            onClick={handleCheckboxClick}
            selected={send}
          />
        </CheckboxWrapper>

        {send && (
          <ContentWrapper>
            <Content content={content} updateContent={updateContent} />
            <AutocompleteWrapper>
              <AutoComplete
                destinations={destinations}
                updateDestinations={updateDestinations}
                readOnly={readOnly}
              />
            </AutocompleteWrapper>
          </ContentWrapper>
        )}
      </Box>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      boxType,
      collapsed,
      collapsedComponent,
      content,
      destinations,
      handleCheckboxClick,
      onMenuSelect,
      readOnly,
      send,
      onChangeCollapse,
      updateContent,
      updateDestinations,
    ],
  );

  return (
    <div {...attributes}>
      <DragAndDrop element={element} isDragDisabled={checkIfDragDisabled(variant)}>
        {children}
        {notificationView}
      </DragAndDrop>
    </div>
  );
};

Notification.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

Notification.defaultProps = {
  attributes: {},
  children: null,
  element: {
    children: [],
    data: { src: '' },
    type: elementTypes.NOTIFICATION,
  },
};

export default Notification;
