import React from 'react';
import AddItem from 'screens/main/components/rightArea/sidebar/teams/listItem/addItem/addItem-view';
import { useMembersDialog } from 'store';
import DetailList from '../../../../detailList';

const Members = ({ teamMembers, addPeople, teamTitle, containerRef, ...rest }) => {
  const teamMembersExist = teamMembers.length > 0;
  const [, setGetMembers] = useMembersDialog();
  const doGetUsers = () => {
    setGetMembers({
      variant: 'user',
      open: true,
      startWith: [...teamMembers],
      dialogTitle: `Add members to ${teamTitle}`,
      removeTooltipText: `Remove from ${teamTitle}`,
      subHeader: `Members part of ${teamTitle}:`,
      onOk: (newMembers) => {
        addPeople(newMembers);
      },
    });
  };
  return (
    <div>
      {teamMembersExist && (
        <DetailList listTitle="Team Members" members={teamMembers} avatarVariant="user" {...rest} />
      )}

      <AddItem title="Team Members..." onClick={doGetUsers} />
    </div>
  );
};

export default Members;
