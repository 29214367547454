/* eslint-disable max-len */
import React from 'react';
import { Collapse, List, ListItem, ListItemText, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import useHover from 'hooks/useHover';
import MoreVertical from 'assets/icons/systemicons/more_vertical.svg';
import Tooltip from 'components/tooltip';
import { ReactComponent as ArrowDown } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_down.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_right.svg';
import { ReactComponent as PlusSmallCircle } from 'assets/icons/systemicons/plus_ultraSmall_circle.svg';
import loadingSpinnerSrc from 'assets/images/loadingSpinner/dina-loader-anpng-60frames.png';
import { Button } from 'components/buttons';

import useStyles from './group-styles';

const LoadingSpinner = styled('img')`
  height: 20px;
  width: 20px;
  margin-right: 8px;
`;

const ArrowWrapper = styled('div')`
  height: 24px;
  width: 24px;
  svg {
    path {
      fill: ${({ theme }) => theme.palette.dina.iconActive};
      fill-opacity: 0.54;
    }
  }
  :hover {
    svg {
      path {
        fill-opacity: 1;
      }
    }
  }
`;
const IconButtonWrapper = styled(IconButton)`
  padding: 0;
  svg {
    path {
      fill: ${({ theme }) => theme.palette.dina.iconActive};
      fill-opacity: 0.54;
    }
  }
`;

const Group = ({
  title,
  children,
  onEllipsis,
  open,
  toggleListOpen,
  hasMore,
  handleCreateClicked,
  isLoadingMore,
  type,
  onLoadMore,
  toolTipTitle,
  itemCount,
  hideCreateNewButton,
  disableCollapse,
}) => {
  const classes = useStyles({ disableCollapse });
  const moreItems = hasMore ? ' +' : '';
  const [hoverRef, isHovered] = useHover();

  const crateClicked = (e) => {
    e.stopPropagation();
    handleCreateClicked(e);
  };

  return (
    <>
      <ListItem
        button
        onClick={!disableCollapse ? toggleListOpen : undefined}
        className={classes.listHeader}
        ref={hoverRef}
        classes={{ button: classes.buttonOverride }}
        disableRipple={disableCollapse}
      >
        {!disableCollapse && <ArrowWrapper>{open ? <ArrowDown /> : <ArrowRight />}</ArrowWrapper>}
        <ListItemText primary={title} classes={{ primary: classes.listHeaderText }} />
        <div className={classes.rightItems}>
          {onEllipsis ? <img src={MoreVertical} alt='more icon' /> : null}
          <span className={classes.storyCount}>
            {children?.props ? children.props.children.length + moreItems : itemCount}
          </span>
          {type === 'left' && !hideCreateNewButton && isHovered && (
            <>
              {/** prevents ref error * */}
              <Tooltip title={toolTipTitle || 'Create Story'} noArrow>
                <IconButtonWrapper onClick={crateClicked} size='small'>
                  <PlusSmallCircle />
                </IconButtonWrapper>
              </Tooltip>
            </>
          )}
        </div>
      </ListItem>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {children}
          {hasMore && (
            <div className={classes.loadMoreButtonContainer}>
              <Button
                onClick={onLoadMore}
                variant='discreet'
                usage='text'
                title='Load more elements'
              >
                {isLoadingMore && <LoadingSpinner src={loadingSpinnerSrc} alt='_loading' />}
                Load more
              </Button>
            </div>
          )}
        </List>
      </Collapse>
    </>
  );
};

Group.propTypes = {
  /** Title of the list group */
  title: PropTypes.string,
  /** List items */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
  /** should the header have options? */
  onEllipsis: PropTypes.bool,
  /** Should the list be open or not */
  open: PropTypes.bool,
  /** toggle open/close */
  toggleListOpen: PropTypes.func,
  /** add button clicked */
  handleCreateClicked: PropTypes.func,
  /** where should the group be */
  type: PropTypes.string,
  /** load more elements */
  onLoadMore: PropTypes.func,
  /** have more token */
  hasMore: PropTypes.bool,
  /**  when explicit itemCount specified */
  itemCount: PropTypes.number,
  /** Hides the create new button & stops the sliding of rundown count */
  hideCreateNewButton: PropTypes.bool,
  /** show loading indicator on button after pressing */
  isLoadingMore: PropTypes.bool,
  /** disable collapse if true */
  disableCollapse: PropTypes.bool,
};

Group.defaultProps = {
  title: 'Group',
  onEllipsis: false,
  children: null,
  open: false,
  toggleListOpen: () => {},
  handleCreateClicked: () => {},
  type: '',
  hasMore: false,
  onLoadMore: () => {},
  itemCount: 0,
  hideCreateNewButton: false,
  isLoadingMore: false,
  disableCollapse: false,
};

export default Group;
