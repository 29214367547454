import { MemberTypeEnum } from 'types/graphqlTypes';

const previewEnabledItemType = new Set([
  MemberTypeEnum.Story,
  MemberTypeEnum.ResStory,
  MemberTypeEnum.Pitch,
  MemberTypeEnum.ResPitch,
  MemberTypeEnum.Instance,
]);

export const canOpenPreview = (mType?: MemberTypeEnum) =>
  !!mType && previewEnabledItemType.has(mType);
