import useCreateTemplate from 'hooks/useCreateTemplate';
import { uploadToS3 } from 'utils/s3Utils';
import { filter, CustomFields } from 'utils/metadata';

const DefaultContentFilename = 'content.data';

/**
 * Returns a valid file to upload to S3
 * @param {*} template - Template to upload
 * @return {*} Corresponding File object
 */
const getFile = (template, filename = DefaultContentFilename) =>
  new window.File([JSON.stringify(template)], filename, { type: 'text/plain' });

const useSaveTemplate = (editorValueRef) => {
  const [createTemplate] = useCreateTemplate();

  const save = async (folderId, title, overwriteData, metadata, mProperties) => {
    if (!editorValueRef.current) return;

    try {
      let tpldata = overwriteData;
      if (!tpldata) {
        const result = await createTemplate(folderId, title, mProperties);
        tpldata = result?.data?.createContentTemplate || {};
      }

      const template = { ...editorValueRef.current };
      template.metadata = filter(metadata || template.metadata, {
        ignoreNull: true,
        excludeKeys: [CustomFields.STORY_TITLE],
      });
      template.properties = {
        folder: folderId,
        template: tpldata.mRefId,
        title,
      };
      uploadToS3(tpldata.mContentKey, getFile(template));
    } catch (err) {
      //   logger.log(err);
    }
  };

  return [save];
};

export default useSaveTemplate;
