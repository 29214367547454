import { useState } from 'react';
import PropTypes from 'prop-types';
import { ListSubheader, List } from '@material-ui/core';
import EllipsisButton from 'components/buttons/ellipsisButton';
import Popover from 'components/popover';
import { ReactComponent as Padlock } from 'assets/icons/systemicons/padlock_off.svg';
import { ReactComponent as Delete } from 'assets/icons/systemicons/delete.svg';
import { ReactComponent as History } from 'assets/icons/systemicons/time.svg';
import { ReactComponent as Copy } from 'assets/icons/systemicons/copy.svg';
import Divider from 'components/divider';
import InstanceVersionHistory from 'features/versionHistory';
import { createInstanceLink } from 'components/instanceCard/utils/createInstanceLink';
import FoldersMenuList from '../foldersMenuList';
import MenuItem from '../menuItem';
import DeleteInstance from '../linearEllipsisMenu/components/deleteInstance';
import useStyles from './general-ellipsis-menu-styles';
import useCopyText from 'hooks/useCopyText';

function GeneralMenuList({ storyId, instanceId }) {
  const classes = useStyles();
  const { copyTooltip, onCopy } = useCopyText('Copy instance link');

  const onCopyClick = () => {
    const textToCopy = createInstanceLink(storyId, instanceId);
    onCopy(textToCopy, 'Link copied!');
  };
  return (
    <List disablePadding classes={{ root: classes.menuItem }}>
      <ListSubheader classes={{ root: classes.listSubheader }}>GENERAL</ListSubheader>
      <MenuItem
        label="Copy Link"
        title={copyTooltip}
        showArrow={false}
        icon={<Copy />}
        onClick={onCopyClick}
      />
    </List>
  );
}

GeneralMenuList.propTypes = {
  storyId: PropTypes.string,
  instanceId: PropTypes.string,
};

const topRight = {
  vertical: 'top',
  horizontal: 'right',
};

const centerLeft = {
  vertical: 'center',
  horizontal: 'left',
};

function MoreOptionsMenuList({ moreOptions, anchor, handleClosePopover, popoverComponent }) {
  const classes = useStyles();
  return (
    <List disablePadding classes={{ root: classes.menuItem }}>
      <ListSubheader classes={{ root: classes.listSubheader }}>MORE OPTIONS</ListSubheader>
      {moreOptions.map(({ icon, label, callback, disabled }) => (
        <MenuItem
          key={label}
          icon={icon}
          label={label}
          onClick={callback}
          showSecondaryItem={false}
          showArrow={false}
          disabled={disabled}
        />
      ))}
      <Popover
        anchorEl={anchor}
        anchorOrigin={centerLeft}
        transformOrigin={topRight}
        onClose={handleClosePopover}
      >
        <div className={classes.popover}>{popoverComponent}</div>
      </Popover>
    </List>
  );
}

MoreOptionsMenuList.propTypes = {
  moreOptions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element,
      label: PropTypes.string,
      callback: PropTypes.func,
      disabled: PropTypes.bool,
    }),
  ),
  anchor: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.shape({
      current: PropTypes.node,
    }),
  ]),
  handleClosePopover: PropTypes.func,
  popoverComponent: PropTypes.element,
};

const GeneralEllipsisMenu = ({
  isDeleteEnabled,
  onDeleteInstance,
  onForceUnlock,
  writeLock,
  lockedByUser,
  disableEdit,
  title,
  instanceId,
  onRestoreVersion,
  checkVersionRestorability,
  isSavingContent,
  canDeleteInstance,
  canUpdateInstance,
  folders,
  canCreateNewTemplate,
  canDeleteTemplateFolder,
  canPinTemplateFolder,
  canDeleteTemplate,
  canReorderTemplates,
  canSetDefaultTemplate,
  onDeleteFolder,
  onSaveTemplate,
  onSelectTemplate,
  onDeleteTemplate,
  onCreateFolder,
  hideTemplateOptions,
  onSetDefaultTemplate,
  defaultTemplateRefId,
  storyId,
  isContentLoaded,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const [anchor, setAnchor] = useState(null);
  const [popoverComponent, setPopoverComponent] = useState(null);
  const [historyDialogOpen, setHistoryDialogOpen] = useState(false);

  const handleOpenPopover = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchor(null);
    setPopoverComponent(null);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleForceUnlock = () => {
    closeMenu();
    onForceUnlock();
  };

  const handleDelete = () => {
    handleClosePopover();
    closeMenu();
    onDeleteInstance();
  };

  const handleHistory = async () => {
    setHistoryDialogOpen(true);
  };

  const handleRestoreVersion = async (content) => {
    await onRestoreVersion(content);
    setHistoryDialogOpen(false);
    setAnchorEl(null);
  };

  const OpenDelete = () => {
    setAnchor(anchorEl);
    setPopoverComponent(
      <DeleteInstance
        onCancel={handleClosePopover}
        onOk={handleDelete}
        isDeleteEnabled={isDeleteEnabled}
      />,
    );
  };

  const disableEditOrFalse = disableEdit || false;

  const moreOptions = [
    {
      icon: <History />,
      label: 'History',
      callback: handleHistory,
      disabled: !canUpdateInstance,
    },
    {
      icon: <Padlock />,
      label: 'Force unlock',
      callback: handleForceUnlock,
      disabled: disableEditOrFalse,
    },
    {
      icon: <Delete />,
      label: 'Delete Instance',
      callback: OpenDelete,
      disabled: disableEditOrFalse || !canDeleteInstance,
    },
  ];

  return (
    <div>
      {historyDialogOpen && (
        <InstanceVersionHistory
          title={title}
          id={instanceId}
          open={historyDialogOpen}
          onCancel={() => setHistoryDialogOpen(false)}
          onOk={handleRestoreVersion}
          checkVersionRestorability={checkVersionRestorability}
          lockedByUser={lockedByUser}
          isSavingContent={isSavingContent}
        />
      )}
      <div>
        <EllipsisButton onClick={handleOpenPopover} />
        <Popover
          anchorEl={anchorEl}
          onClose={closeMenu}
          anchorOrigin={topRight}
          transformOrigin={topRight}
        >
          <div className={classes.menu}>
            <GeneralMenuList storyId={storyId} instanceId={instanceId} />
            {!hideTemplateOptions && (
              <>
                <Divider className={classes.divider} />
                <FoldersMenuList
                  {...{
                    anchorEl,
                    folders,
                    canCreateNewTemplate,
                    canDeleteTemplateFolder,
                    canPinTemplateFolder,
                    canDeleteTemplate,
                    canReorderTemplates,
                    canSetDefaultTemplate,
                    onSetDefaultTemplate,
                    writeLock,
                    onDeleteFolder,
                    closeMenu,
                    onSaveTemplate,
                    onSelectTemplate,
                    onDeleteTemplate,
                    onCreateFolder,
                    defaultTemplateRefId,
                    isContentLoaded,
                  }}
                />
              </>
            )}
            <>
              <Divider className={classes.divider} />
              <MoreOptionsMenuList
                moreOptions={moreOptions}
                anchor={anchor}
                handleClosePopover={handleClosePopover}
                popoverComponent={popoverComponent}
              />
            </>
          </div>
        </Popover>
      </div>
    </div>
  );
};

GeneralEllipsisMenu.propTypes = {
  onForceUnlock: PropTypes.func,
  disableEdit: PropTypes.bool,
  storyId: PropTypes.string,
};

GeneralEllipsisMenu.defaultProps = {
  onForceUnlock: () => {},
  disableEdit: false,
};

export default GeneralEllipsisMenu;
