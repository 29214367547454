import styled from '@emotion/styled';

import Text, { TextProps } from 'components/text/Text';
import Tooltip from 'components/tooltip';

interface TreeChoiceDefaultPrpos extends TextProps {
  choice: string[];
  onClick: () => void;
}
const DefaultChoiceText = styled(Text)`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  :hover {
    cursor: pointer;
    color: ${({ theme }) => theme.palette.dina.highEmphasis} !important;
  }
`;

function TreeChoiceDefault({ choice, onClick, variant, color }: Readonly<TreeChoiceDefaultPrpos>) {
  return (
    <Tooltip title={choice?.length > 0 ? choice.join(' ▸ ') : 'Edit default value'}>
      {choice?.length > 0 ? (
        <DefaultChoiceText
          variant={variant ?? 'listItemLabel'}
          color={color ?? 'highEmphasis'}
          onClick={onClick}
        >
          {choice.join(' ▸ ')}
        </DefaultChoiceText>
      ) : (
        <DefaultChoiceText
          variant={variant ?? 'listItemLabel'}
          color={color ?? 'mediumEmphasis'}
          onClick={onClick}
        >
          Select a choice
        </DefaultChoiceText>
      )}
    </Tooltip>
  );
}

export default TreeChoiceDefault;
