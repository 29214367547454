import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  headerDiv: {
    position: 'relative',
    overflow: 'hidden',
    background: theme.palette.dina.surfaceAppBackgroundNavLevel1,
    paddingBottom: '12px',
  },
  image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectPosition: '0% 25%',
  },
  avatar: {
    position: 'absolute',
    background: theme.palette.mode === 'light' ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4)',
    backdropFilter: 'blur(35px)',
    inset: 0,
  },
}));

export default useStyles;
