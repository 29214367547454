import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { atom, useAtom } from 'jotai';

import Dialog from 'components/dialogs/DialogBuilder';
import { generateTooltipText } from 'components/editMdfDialog/utils';
import Tooltip from 'components/tooltip';
import { FieldValue, Metadata, NewFieldValue } from 'types/forms/forms';
import { Mdf, ViewTypes } from 'types/graphqlTypes';

import { MdfEditor } from './MdfEditor';

const HeaderWrapper = styled('div')`
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 380px;
`;

export interface EditFieldValue {
  fieldId: string;
  mdf: Mdf;
  viewType: ViewTypes;
  startValue?: FieldValue | null;
  headerText: string;
  required?: boolean;
  confirmLabel?: string;

  /**
   * Provide true to ignore required & visible workflows, useful for example to allow
   * editing a default value without distracting a user from irrelevant workflows.
   */
  editMode?: boolean;
  onConfirm: (val: FieldValue) => void;
  onCancel?: () => void;
}

const editFieldDialog = atom<EditFieldValue | null>(null);

export const useEditFieldValueDialog = () => useAtom(editFieldDialog);

export function EditFieldValueDialog() {
  const [open, setOpen] = useState(false);
  const [state, setState] = useEditFieldValueDialog();
  const [formPayload, setFormPayload] = useState<Metadata>({});
  const [errorMap, setErrorMap] = useState<Record<string, string | undefined>>({});

  const setPayload = useCallback(
    (p: NewFieldValue) => {
      setFormPayload((prevState) => ({
        ...prevState,
        [p.fieldId]: p.value,
      }));
    },
    [setFormPayload],
  );
  const onErrorUpdate = ({ fieldId, error }: { fieldId: string; error: string | undefined }) => {
    setErrorMap((prevState) => ({
      ...prevState,
      [fieldId]: error,
    }));
  };
  useEffect(() => {
    if (state === null) {
      setOpen(false);
    } else {
      setOpen(true);
      if (state?.startValue) {
        setPayload({ fieldId: state.fieldId, value: state.startValue });
      }
    }
  }, [state]);

  const onCancel = () => {
    if (state?.onCancel) {
      state.onCancel();
    }
    setFormPayload({});
    setErrorMap({});
    setState(null);
  };

  const onConfirm = () => {
    const value = state !== null ? formPayload[state.fieldId] : null;
    if (state?.onConfirm && value !== null) {
      state.onConfirm(value);
    }
    setOpen(false);
    onCancel();
  };

  const errorTooltip = useMemo(() => {
    if (!state) return undefined;
    const labelMap: Record<string, string> = {};
    for (const field of state.mdf.fields) {
      const layoutSettings = state.mdf.views[state.viewType] ?? state.mdf.views.default;
      labelMap[field.fieldId] =
        layoutSettings.find((s) => s.fieldId === field.fieldId)?.label ?? field.fieldId;
    }
    const errors = generateTooltipText(errorMap, labelMap);
    if (!errors.length) return undefined;
    return (
      <>
        <div>Please resolve errors:</div>
        <ul style={{ padding: '0 0 0 20px' }}>
          {errors.map((err) => (
            <li key={err}>{err}</li>
          ))}
        </ul>
      </>
    );
  }, [errorMap, state]);

  return (
    <Dialog open={open} onClose={onCancel} style={{ width: '450px' }}>
      <Dialog.Header>
        <Tooltip title={state?.headerText ?? ''}>
          <HeaderWrapper>{state?.headerText}</HeaderWrapper>
        </Tooltip>
      </Dialog.Header>
      <Dialog.Body>
        {state && (
          <MdfEditor
            fields={state.mdf.fields.filter((f) => f.fieldId === state.fieldId)}
            autoFocus={true}
            defaultLayoutSettings={state.mdf.views.default}
            layoutSettings={state.mdf.views[state.viewType]}
            metadata={formPayload}
            permissions={state.mdf.permissions}
            updateFieldValue={setPayload}
            errorMap={errorMap}
            updateErrorMap={onErrorUpdate}
            moreVerticalSpace={true}
            fieldEditMode={state.editMode}
          />
        )}
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton onCancel={onCancel} />
        <Tooltip title={errorTooltip ?? ''}>
          <Dialog.ConfirmButton
            label={state?.confirmLabel ?? 'Confirm'}
            onClick={onConfirm}
            disabled={Boolean(errorTooltip)}
          />
        </Tooltip>
      </Dialog.Footer>
    </Dialog>
  );
}
