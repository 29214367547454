import { useContext } from 'react';
import styled from '@emotion/styled';

import { ReactComponent as LeftArrow } from 'assets/icons/systemicons/arrows/disclosurearrow_left.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/systemicons/email.svg';
import { ReactComponent as ChatResting } from 'assets/icons/systemicons/HeaderNavbar/chat_resting.svg';
import { ReactComponent as MenuIcon } from 'assets/icons/systemicons/more_vertical.svg';
import { ReactComponent as SpaceIcon } from 'assets/icons/systemicons/spaces_on.svg';
import { Button, IconButton } from 'components/buttons';
import UserContext from 'contexts/UserContext';
import useCheckUserRight from 'hooks/useCheckUserRight';
import useDinaNavigate from 'hooks/useDinaNavigate';
import { Metadata } from 'types/forms/forms';
import { MProperties } from 'types/graphqlTypes';

import HeaderBase from '../headerBase';

export const Menu = styled('nav')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ActionButtons = styled('span')`
  display: flex;
  align-items: center;
`;
interface MenuItem {
  action: string;
  label: string;
  icon?: React.ReactNode;
}
interface DetailsHeaderProps {
  id: string;
  backButtonLabel: string;
  onBackButtonClick: () => void;
  menuItems: MenuItem[];
  title: string;
  isSummary: boolean;
  onClose: () => void;
  hideMenu: boolean;
  showMenu: boolean;
  type: string;
  metadata: string;
  properties: MProperties;
  onMenuButtonClick: () => void;
  onChatButtonClick: () => void;
  onEmailButtonClick: () => void;
  onMenuItemClick?: () => void;
}

function DetailHeader({
  backButtonLabel = 'Back',
  onBackButtonClick,
  isSummary,
  showMenu = false,
  onMenuButtonClick,
  onChatButtonClick,
  onEmailButtonClick,
  type,
  id,
  ...rest
}: Readonly<DetailsHeaderProps>) {
  const { navigateTo } = useDinaNavigate();
  const { properties, metadata } = rest;
  const [checkUserRight] = useCheckUserRight();
  const canAccessSpaces = checkUserRight('feature', 'spaces');
  const user = useContext(UserContext);

  const openSpace = () => {
    navigateTo('space', `${id}-${type}`);
  };

  const isTeamOrDepartment = type === 'team' || type === 'department';
  const isExternalContact = type === 'contact';
  const isCurrentUser = user.mId === id;

  const isEmailDisabled = () => {
    const parsedMetadata = metadata ? (JSON.parse(metadata) as Metadata) : null;
    return !(parsedMetadata?.email ?? properties?.email);
  };

  return (
    <HeaderBase isSummary={isSummary} {...rest}>
      <Menu>
        {onBackButtonClick && (
          <Button
            variant="discreet"
            usage="text"
            onClick={onBackButtonClick}
            padding={6}
            autoWidth
            startIcon={<LeftArrow />}
          >
            {backButtonLabel}
          </Button>
        )}

        <ActionButtons>
          {isTeamOrDepartment && canAccessSpaces && (
            <Button
              variant="discreet"
              usage="text"
              onClick={openSpace}
              autoWidth
              startIcon={<SpaceIcon />}
              title={`Go to ${type === 'team' ? 'team' : 'department'} space`}
            >
              View Space
            </Button>
          )}

          {!isExternalContact && !isTeamOrDepartment && !isCurrentUser && onChatButtonClick && (
            <IconButton
              onClick={onChatButtonClick}
              variant="discreet"
              usage="text"
              noBorder
              round
              size={24}
              iconSize={20}
              title="Start chat"
            >
              <ChatResting />
            </IconButton>
          )}
          {!isTeamOrDepartment && !isCurrentUser && onEmailButtonClick && (
            <IconButton
              disabledTitle="Email is not set"
              onClick={onEmailButtonClick}
              variant="discreet"
              usage="text"
              noBorder
              round
              size={24}
              iconSize={20}
              disabled={isEmailDisabled()}
              title="Send email"
            >
              <EmailIcon />
            </IconButton>
          )}

          {showMenu && (isExternalContact || isTeamOrDepartment) && (
            <IconButton
              onClick={onMenuButtonClick}
              variant="discreet"
              usage="text"
              noBorder
              round
              size={24}
              iconSize={16}
              title="More options"
            >
              <MenuIcon />
            </IconButton>
          )}
        </ActionButtons>
      </Menu>
    </HeaderBase>
  );
}

export default DetailHeader;
