import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import Text from 'components/text/Text';

type FormProps = {
  $selected?: boolean;
};

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  height: 100%;
  width: 100%;
  margin-right: 12px;
`;

export const FormsWrapper = styled('div')`
  height: 100%;
  border-right: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;

export const Header = styled('div')`
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 8px 4px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  background-color: ${({ theme }) => theme.palette.dina.hoverOverlay};
`;

export const CommandItem = styled('div')<FormProps>`
  cursor: pointer;
  height: 32px;
  padding-inline: 8px 4px;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  .deleteButton {
    opacity: 0;
  }
  &:hover {
    background: ${({ $selected, theme }) =>
      $selected ? theme.palette.dina.onSelected : theme.palette.dina.hoverOverlay};
    .deleteButton {
      opacity: 1;
    }
  }
  ${({ $selected, theme }) =>
    $selected
      ? css`
          background: ${theme.palette.dina.onSelected};
          color: ${theme.palette.dina.whiteHighEmphasis};
          .deleteButton {
            path {
              fill: ${theme.palette.dina.whiteMediumEmphasis};
            }
            :hover {
              path {
                fill: ${theme.palette.dina.whiteHighEmphasis};
              }
            }
          }
        `
      : css`
          background: transparent;
          color: ${theme.palette.dina.highEmphasis};
        `}
`;

export const Block = styled('div')`
  padding: 8px;
`;

export const BlockWrapper = styled('div')`
  border: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  border-radius: 6px;
  margin-top: 8px;
`;

export const BlockTitle = styled('div')`
  padding-left: 8px;
  display: flex;
  align-items: center;
  height: 28px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  background-color: ${({ theme }) => theme.palette.dina.blackHoverOverlay};
`;

interface StyledTextProps {
  $isEdited: boolean;
}

export const StyledText = styled(Text)<StyledTextProps>`
  flex: 1;
  ${({ $isEdited, theme }) =>
    $isEdited &&
    css`
      color: ${theme.palette.dina.statusWarning};
      font-style: italic;
      font-weight: bold;

      ::after {
        content: '*';
      }
    `}
`;

export const MdfDropdownWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

export const SelectWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  .MuiFormControl-root {
    width: 100%;
  }
`;

export const OptionWrapper = styled('div')`
  padding-left: 8px;
  height: 28px;
  display: flex;
  align-items: center;
`;
