import styled from '@emotion/styled';
import Typography from '@material-ui/core/Typography';

export const RightSideChatContainer = styled('div')`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const HeaderContainer = styled('div')`
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;

export const Title = styled(Typography)`
  ${({ theme }) => theme.typography.dina.h7};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const EmptyContainer = styled('div')`
  display: flex;
  margin: auto;
`;

export const CenterText = styled(Typography)`
  ${({ theme }) => theme.typography.dina.h6};
`;
