/* eslint-disable import/no-extraneous-dependencies */
import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Autolinker from 'autolinker';
import useReadSpeedValue from 'features/feedViewer/hooks/useReadSpeedValue';
import Tooltip from 'components/tooltip';
import useHighLightWords from 'hooks/useHighlightWords';

const StyledText = styled('div')`
  ${({ theme }) => css`
    ${theme.typography.dina.body1};
    color: ${theme.palette.dina.blackHighEmphasis};
  `}
  margin: 12px 24px 12px 16px;
  word-break: break-word;
  a: {
    color: ${({ theme }) => theme.palette.dina.url};
  }
`;
const getHostnameFromRegex = (url) => {
  // eslint-disable-next-line no-useless-escape
  const matches = url.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);

  return (matches && matches[1]) || '[External URL]';
};

const replaceUrlWithHostname = (input) => {
  if (input.length < 20) {
    return input;
  }

  const url = input.substring(1, input.indexOf('<'));

  return ` target="_blank">${getHostnameFromRegex(url)}</a>`;
};

const formatAngleBracketUrls = (input) => {
  const regex =
    // eslint-disable-next-line max-len
    />(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})<\/a>/gim;
  const output = input.replaceAll(regex, replaceUrlWithHostname);

  return output;
};

function Content({ text, searchWords }) {
  const contentRef = useRef(null);
  const [readSpeed] = useReadSpeedValue(contentRef, text);
  const highlight = useHighLightWords();

  const createMarkup = useCallback(() => {
    let input = text;

    if (input) {
      input = formatAngleBracketUrls(input);

      if (input.includes('<pre>') && input.includes('</pre>')) {
        input = input.replace('<pre>', '');
        input = input.replace('</pre>', '');
      }
    }

    return {
      __html: `${text && text !== 'null' ? highlight(Autolinker.link(input), searchWords) : ''}`,
    };
  }, [text, searchWords, highlight]);

  return (
    <Tooltip title={readSpeed} noArrow open={readSpeed !== '00:00'} placement="top">
      <StyledText ref={contentRef} dangerouslySetInnerHTML={createMarkup()} />
    </Tooltip>
  );
}

Content.propTypes = {
  text: PropTypes.string,
  searchWords: PropTypes.arrayOf(PropTypes.string),
};

Content.defaultProps = {
  text: '',
  searchWords: [],
};

export default Content;
