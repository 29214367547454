import Divider from 'components/divider';
import { MemberType } from 'types/graphqlTypes';

import Header from './header';
import UserList from './list';

import { RootWrapper } from './styled';

interface UserProps {
  groupPolicies: MemberType[];
}

const Users = ({ groupPolicies }: UserProps) => {
  return (
    <RootWrapper>
      <Header />
      <Divider />
      <UserList groupPolicies={groupPolicies} />
    </RootWrapper>
  );
};

export default Users;
