import ListItem from 'components/ellipsisDropdown/listItem-view';
import { useSelectedGroupCategoryAtom } from 'screens/main/components/header/navbar/settings/atoms';
import { useSelectedOrganization } from 'screens/main/components/header/navbar/settings/atomsTs';
import GROUP_SETTING_TYPES from 'screens/main/components/header/navbar/settings/utils/groupSettingTypes';

import { BlankDiv } from './users-styled';

const MenuItem = () => {
  const [selectedOrganizationMenu, setSelectedOrganizationMenu] = useSelectedOrganization();
  const [, setCategory] = useSelectedGroupCategoryAtom();
  return (
    <ListItem
      key={'Users'}
      text={'Users'}
      selected={selectedOrganizationMenu === 'users'}
      onClick={() => {
        setSelectedOrganizationMenu('users');
        setCategory(GROUP_SETTING_TYPES.USERS);
      }}
      excludeDivider
      iconChild={undefined}
      firstChild={<BlankDiv $margin={26} />}
    />
  );
};

export default MenuItem;
