import { createContext } from 'react';

import { PlatformSectionConfig } from 'types/graphqlTypes';

import { ResourceDetails } from './components/mdf/hook/useInsertBlock';

interface UpdateFunctionInput {
  type: string;
  payload?: unknown;
}

export type Update = (input: UpdateFunctionInput) => Promise<void> | void;

interface EditorContextStatesType {
  platformId?: string;
  update: Update;
  config?: PlatformSectionConfig[];
  resourceDetails?: ResourceDetails;
}

const editorContextInitialState: EditorContextStatesType = {
  update: function (): void {
    throw new Error('Function not implemented. ');
  },
};

const EditorContext = createContext(editorContextInitialState);

export default EditorContext;
