import convert from 'utils/convertInputStringToTimeString';

/**
 *
 * @param {String} inputValue - user entered value
 * @param {Object} field - instance footer field
 * @returns {Array} - [error,value]
 */
const getDuration = (inputValue, field) => {
  const { value: formattedValue, isError } = convert(inputValue.trim());
  const fieldAutoValue = field?.autoValue || field?.value;
  const durationValue = isError ? fieldAutoValue || formattedValue : formattedValue;

  return [isError, durationValue];
};
export default getDuration;
