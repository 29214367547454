import { useState, forwardRef } from 'react';
import { ReactComponent as CreateLinkIcon } from 'assets/icons/systemicons/create_link.svg';
import { ReactComponent as LinkIcon } from 'assets/icons/systemicons/link.svg';
import { Dialog, IconButton, Typography, Grid } from '@material-ui/core';
import { InstanceItemVariant } from 'screens/main/components/leftArea/sidebar/instances/list/listItem/listItem-view';
import { ReactComponent as ArrowDoubleIcon } from 'assets/icons/systemicons/arrows/arrow_double_unboxed.svg';
import useUpdateRelatedMembers from 'hooks/useUpdateRelatedMembers';
import useStyles from './instanceLink-container-styles';
import ExistingListContainer from './linkedInstances';
import useToast from 'components/toast/useToast';

import InstanceContainer from './instanceList';

const InstanceLinkContainer = forwardRef(
  ({ linkedInstances, instance, isLinkingDisabled }, ref) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [openLinkedInstances, setOpenLinkedInstances] = useState(false);
    const [openAllInstances, setOpenAllInstances] = useState(false);
    const [hasCreatedLink, setHasCreatedLink] = useState(false);

    const { toast } = useToast();

    const onOpenExistingInstanceList = () => {
      setOpen(true);
      setOpenLinkedInstances(true);
      setOpenAllInstances(false);
    };

    const onOpenAllInstances = () => {
      setOpen(true);
      setOpenLinkedInstances(false);
      setOpenAllInstances(true);
    };

    let iconButton = (
      <IconButton
        color="primary"
        aria-label="create link"
        component="span"
        onClick={onOpenAllInstances}
        disabled={isLinkingDisabled}
        ref={ref}
      >
        <CreateLinkIcon />
      </IconButton>
    );
    if (linkedInstances.length) {
      iconButton = (
        <IconButton
          color="primary"
          aria-label="show linked instance"
          component="span"
          onClick={onOpenExistingInstanceList}
          classes={{
            root: classes.btnRoot,
          }}
        >
          <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
            <div className={classes.link}>
              <LinkIcon />
            </div>
            <Typography className={classes.linkCounter}>x{linkedInstances.length}</Typography>{' '}
            <div className={classes.selectIcon}>
              <ArrowDoubleIcon />
            </div>
          </Grid>
        </IconButton>
      );
    }

    const onClose = () => {
      setOpen(false);
      setOpenLinkedInstances(false);
      setOpenAllInstances(false);
      setHasCreatedLink(false);
    };

    const [updateRelatedMembersMutation, loading] = useUpdateRelatedMembers();

    const onCreateLink = async (selections, isUniDirectional = false) => {
      if (isLinkingDisabled) return;
      const mRelatedMembers = selections
        .filter(
          (item) => !linkedInstances.includes(item.instanceId) && item.instanceId !== instance.mId,
        )
        .map((item) => ({
          mId: item.instanceId,
          crudAction: 'CREATE',
        }));
      await updateRelatedMembersMutation({
        variables: {
          input: {
            mId: instance.mId,
            mRelatedMembers,
            isUniDirectional,
          },
        },
      });

      toast({
        title: 'Link created',
        description: 'A link between instances has been successfully created.',
        type: 'success',
      });

      setHasCreatedLink(true);
    };

    return (
      <div className={classes.container}>
        {iconButton}
        <Dialog open={open} onClose={onClose}>
          {openLinkedInstances && (
            <ExistingListContainer
              linkedInstances={linkedInstances}
              onOpenAllInstances={onOpenAllInstances}
              parentInstance={instance}
              onClose={onClose}
              isLinkingDisabled={isLinkingDisabled}
            />
          )}
          {openAllInstances && (
            <InstanceContainer
              variant={InstanceItemVariant.CREATE_LINK_LIST_ITEM}
              onCancel={linkedInstances.length ? onOpenExistingInstanceList : onClose}
              onCreate={onCreateLink}
              loading={loading}
              linkedInstances={linkedInstances}
              parentInstance={instance}
              isLinkingDisabled={isLinkingDisabled}
              hasCreatedLink={hasCreatedLink}
            />
          )}
        </Dialog>
      </div>
    );
  },
);

export default InstanceLinkContainer;
