import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import { gql } from 'graphql-tag';

const QUERY = gql`
  query GetMember($input: GetMemberInput) {
    getMember(input: $input) {
      mStoryId
    }
  }
`;

interface FetchMember {
  getMember: { mStoryId: string };
}

const useGetStoryIdFromInstanceId = () => {
  const client = useApolloClient();
  const getStoryId = useCallback(
    async (instanceId: string) => {
      const result = await client.query<FetchMember>({
        query: QUERY,
        variables: {
          input: {
            mId: instanceId,
          },
        },
        fetchPolicy: 'network-only',
      });
      return result.data.getMember.mStoryId;
    },
    [client],
  );

  return { getStoryId };
};

export default useGetStoryIdFromInstanceId;
