import React from 'react';
import { useQuery } from '@apollo/client';
import useShareStory from 'hooks/useShareStory';
import GET_DEPARTMENTS from 'graphql/queries/getDepartments';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import memberTypes from 'graphql/memberTypes';
import { getMembersOfTypeQuery } from 'graphql/queryVariables';
import List from '../../list-view';

const DepartmentListContainer = (props) => {
  const { data, error, loading } = useQuery(GET_DEPARTMENTS, {
    variables: getMembersOfTypeQuery(memberTypes.DEPARTMENT),
    fetchPolicy: 'cache-and-network',
  });

  const [assignMemberToStory] = useShareStory();

  if (error) {
    return `Error! ${error.message}`;
  }

  if (data) {
    const departments = data.getMembersOftype;

    const addDepartmentToStory = async (departmentId, storyId) => {
      assignMemberToStory(storyId, departmentId, memberTypes.DEPARTMENT);
    };

    return (
      // type is required to differentiate between different types of drag source
      // in story list i.e  contact, teams
      <List
        members={departments}
        type={memberTypes.DEPARTMENT}
        dropHandler={(departmentId, storyId) => addDepartmentToStory(departmentId, storyId)}
        avatarVariant="department"
        {...props}
      />
    );
  }

  if (loading) {
    return (
      <div>
        <LoadingIndicator />
      </div>
    );
  }

  return null;
};

export default DepartmentListContainer;
