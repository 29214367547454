/* eslint-disable import/no-extraneous-dependencies */
import _get from 'lodash/get';
import { format } from 'date-fns';

// Used to store default values in enumerations
const DefaultEnum = 'default';
const DefaultLocaltimeFormat = 'yyyy/MM/dd';
const DefaultTimeZone = process.env.TimeZone || 'UTC';

/**
 * Returns a js object
 * @param {*} value
 */
const convertObject = (value) => {
  if (typeof value === 'object') return value;
  return { value };
};
/**
 * Converts the given value to an object using JSON.parse
 * @param {*} value - JSON formatted string representing an object
 */
const convertJSON = (value) => {
  try {
    return JSON.parse(value);
  } catch (err) {
    return null;
  }
};

const isValidDate = (d) => d instanceof Date && !isNaN(d);

/**
 * Returns the given timestamp as a formatted string
 * @param {*} value - time value as string
 * @param {*} timeformat - time format, date-fns syntax. Default: YYYY/MM/DD
 * @param {*} timeZone - time zone, optional time zonein (tz/IANA)
 */
const convertLocaltime = (value, timeformat, timeZone = DefaultTimeZone) => {
  const time = new Date(value);
  const localTime = format(time, timeformat);
  return isValidDate(time) ? localTime : value.replaceAll("'", '');
};
/**
 * Converts given value to corresponding enum value
 * @param {*} enumtype - Enumeration type
 * @param {*} value - Current value to be converted
 * @param {*} defaultEnum - Optionally default enum (used if value is not valid)
 * @returns Converted value, no change if not valid
 */
const getEnumValue = (enumtype, value, defaultEnum = DefaultEnum) => {
  if (Object.hasOwn(enumtype, value)) return enumtype[value];
  if (Object.hasOwn(enumtype, defaultEnum)) return enumtype[defaultEnum];
  return value;
};
/**
 * Converts given value to corresponding enum value
 * Additional lookup in enumtype.Values
 * @param {*} enumtype - Enumeration type
 * @param {*} value - Current value to be converted
 * @param {*} defaultEnum - Optionally default enum (used if value is not valid)
 * @returns Converted value, no change if not valid
 */
const convertEnumType = (enumtype, value, defaultEnum = DefaultEnum) => {
  const evalue = getEnumValue(enumtype, value, defaultEnum);
  if (!enumtype.Values) return evalue;
  if (Object.hasOwn(enumtype.Values, evalue)) return enumtype.Values[evalue];
  return evalue;
};
/**
 * Action used to carry out replacements on field values.
 * @typedef {Object} ReplacmentAction
 * @property {string} regex - The regular expression used in the replacement
 * @property {string} flags - Optional RegExp flags. Default 'gmi'
 * @property {string} subst - The replacement
 */
/**
 * Returns a new string with some or all matches of a pattern replaced by a replacement.
 * The action shall have the following properties:
 *
 * @param {string} value
 * @param {ReplacmentAction} action
 */
const replaceValue = (value, action) => {
  if (!action.regex || !action.subst) return value;
  const flags = action.flags || 'gmi';
  const regex = new RegExp(action.regex, flags);
  return value.replace(regex, action.subst);
};
/**
 * Replaces content of given value according to given replacements.
 * @param {*} value - Value
 * @param {*} replaceActions - replacements, separated by '+'
 * @param {*} replacements - global list of available replacements
 */
const replaceValues = (value, replaceActions, replacements) => {
  let replacedValue = value;
  for (const action of replaceActions.split('+')) {
    /* eslint-disable no-continue */
    if (!action || !replacements[action]) continue;
    replacedValue = replaceValue(replacedValue, replacements[action]);
  }
  return replacedValue;
};
/**
 * Converts the given value according to the given type.
 * Valid types are schema defined enums + 'json'
 * @param {*} value - value to convert
 * @param {*} type - the type to convert to
 * @param {*} kvstore - optional key/value store
 * @param {*} mdmap - How to map Dina payload to Mimir metadata
 */
const convertValue = (value, type, kvstore, mdmap) => {
  switch (type) {
    case 'object':
      return convertObject(value);
    case 'json':
      return convertJSON(value);
    case 'localtime':
      return convertLocaltime(
        value,
        _get(kvstore, 'format', DefaultLocaltimeFormat),
        _get(kvstore, 'timeZone', DefaultTimeZone),
      );
    default:
      break;
  }

  if (!mdmap) return value;

  const { Types, Replacements } = mdmap;

  // Check whether the given type is a enumeration type
  if (Types?.[type]) return convertEnumType(Types[type], value);

  // Check whether any replacements to be made
  const repl = _get(kvstore, 'replace');
  if (Replacements && repl) {
    /* eslint-disable no-param-reassign */
    value = replaceValues(value, repl, Replacements);
  }
  return value;
};

export default convertValue;
