// eslint-disable-next-line import/no-extraneous-dependencies
import { Document, Page, Text, View } from '@react-pdf/renderer';
import format from 'date-fns/format';

import { getDocumentComponent } from './utils';

import AutoDirText from '../components/AutoDirText';
import CommonDocFooter from '../components/CommonDocFooter';

import { styles } from './styles';

export const InstanceDocHeader = ({ instance, clipDuration, scriptDuration, totalDuration }) => {
  const { mProperties, mPublishingAt, mTitle } = instance;

  return (
    <View style={styles.header} fixed>
      <View style={styles.headerContent}>
        <View style={[styles.column, { width: '60%' }]}>
          <Text style={styles.name}>{mProperties.platform}</Text>
          <AutoDirText value={mTitle} style={styles.title} />
          <Text style={styles.scheduleInfo}>
            {mPublishingAt ? (
              <>Scheduled: {format(mPublishingAt, 'MMM D YYYY, HH:mm:ss (Z)')}</>
            ) : (
              <>Not Scheduled</>
            )}
          </Text>
        </View>
        <View style={[styles.row, { justifyContent: 'space-between', flex: 1 }]}>
          <View style={[styles.column, { gap: '0.5rem' }]}>
            <Text style={styles.timingInfoTitle}>Duration</Text>
            <Text style={styles.timingInfoValue}>{clipDuration || '00:00'}</Text>
          </View>
          <View style={[styles.column, { gap: '0.5rem' }]}>
            <Text style={styles.timingInfoTitle}>Script</Text>
            <Text style={styles.timingInfoValue}>{scriptDuration || '00:00'}</Text>
          </View>
          <View style={[styles.column, { gap: '0.5rem' }]}>
            <Text style={styles.timingInfoTitle}>Total</Text>
            <Text style={styles.timingInfoValue}>{totalDuration || '00:00'}</Text>
          </View>
        </View>
      </View>
      <View style={[styles.horizontalLine, { paddingTop: '8' }]} />
    </View>
  );
};

export const InstanceContentPages = ({
  instance,
  content,
  clipDuration,
  scriptDuration,
  totalDuration,
}) => {
  const { document = [] } = content || {};

  return (
    <Page style={styles.body}>
      <InstanceDocHeader
        instance={instance}
        clipDuration={clipDuration}
        scriptDuration={scriptDuration}
        totalDuration={totalDuration}
      />
      {document && document.length > 0 && (
        <View style={[styles.column, styles.content]}>
          {document.map((doc) => {
            return getDocumentComponent(doc);
          })}
        </View>
      )}
      <CommonDocFooter />
    </Page>
  );
};

export const InstanceMetadataPage = ({ instance, clipDuration, scriptDuration, totalDuration }) => {
  const { mMetaData } = instance;

  return (
    <Page style={styles.body}>
      <InstanceDocHeader
        instance={instance}
        clipDuration={clipDuration}
        scriptDuration={scriptDuration}
        totalDuration={totalDuration}
      />
      <View style={[styles.column, { width: '100%' }]}>
        <Text style={{ fontSize: 20, lineHeight: '200%', marginBottom: 8 }}>Metadata</Text>
        <View style={styles.tableContainer}>
          {mMetaData &&
            mMetaData.map((metadata) => {
              const displayName = () => {
                if (metadata.key.includes('total')) return 'Duration';
                if (metadata.key.includes('word')) return 'Words';
                if (metadata.key.includes('isFloat')) return 'Floated';
                return metadata.key.split('-').slice(1).join(' ');
              };
              if (
                metadata.key.includes('total') ||
                metadata.key.includes('word') ||
                metadata.key.includes('isFloat')
              )
                return (
                  <View key={mMetaData.key} style={styles.tableRow}>
                    <AutoDirText style={styles.tableCell} value={displayName()} />
                    <AutoDirText style={styles.tableCell} value={metadata.value} />
                  </View>
                );
            })}
        </View>
      </View>
      <CommonDocFooter />
    </Page>
  );
};

const InstancePrintDoc = ({
  instance,
  content = {},
  clipDuration,
  scriptDuration,
  totalDuration,
}) => (
  <Document>
    <InstanceContentPages
      instance={instance}
      content={content}
      clipDuration={clipDuration}
      scriptDuration={scriptDuration}
      totalDuration={totalDuration}
    />
    <InstanceMetadataPage
      instance={instance}
      clipDuration={clipDuration}
      scriptDuration={scriptDuration}
      totalDuration={totalDuration}
    />
  </Document>
);

export default InstancePrintDoc;
