import { atom, useAtom } from 'jotai';

interface ExternalUrl {
  mTitle: string;
  id: string;
  externalUrl: string;
}

export const externalUrlsAtom = atom<ExternalUrl[]>([]);
export const useExternalUrls = () => useAtom(externalUrlsAtom);
