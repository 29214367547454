import Tooltip from 'components/tooltip';

interface ButtonProps {
  title: string;
  children: React.ReactElement;
  disabled: boolean;
  disabledTitle?: string;
}

export function ButtonWrapper({ title, disabledTitle, children, disabled }: Readonly<ButtonProps>) {
  return (
    <Tooltip title={disabledTitle && disabled ? disabledTitle : title} noArrow>
      {disabled && disabledTitle ? <span>{children}</span> : children}
    </Tooltip>
  );
}
