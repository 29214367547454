import { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';

import UserContext from 'contexts/UserContext';
import { calculateUserRights } from 'graphql/global-functions/utils';
import UPDATE_GROUP_PERMISSIONS from 'graphql/mutations/updateGroupPermissions';
import { useChangedRows } from 'screens/main/components/header/navbar/settings/atomsTs';
import { usePolicies } from 'store';

// Find unique resource names
const getResourceNames = (policies) => [...new Set(policies.map((policy) => policy.resourceName))];

const getPoliciesForPayload = (policies, resourceNames) =>
  resourceNames.map((resourceName) => {
    const permissions = policies.filter((policy) => policy.resourceName === resourceName);
    return {
      resourceName,
      permissions: permissions.map((permission) => ({
        action: permission.action,
        access: permission.access === true ? 'allow' : 'deny',
      })),
    };
  });

const createPolicies = (groupPermissions) => {
  const { policies } = groupPermissions;
  const resourceNames = getResourceNames(policies);
  return getPoliciesForPayload(policies, resourceNames);
};

const getchangedPermissionsInGroups = (changedRows, groupPoliciesDb) => {
  const groupRefIds = [...new Set(changedRows.map((changedRow) => changedRow.groupRefId))];
  return groupRefIds.map((groupRefId) => {
    const policies = changedRows.filter((changedRow) => changedRow.groupRefId === groupRefId);
    // Get the group which is updated with permssions
    const group = groupPoliciesDb.find((groupPolicy) => groupPolicy.mRefId === groupRefId);
    return { group, policies };
  });
};

const getPayload = (changedPermissionsInGroups) =>
  changedPermissionsInGroups.map((groupPermissions) => {
    const { group } = groupPermissions;
    const policies = createPolicies(groupPermissions);
    return {
      mId: group?.mId,
      mRefId: group?.mRefId,
      mTitle: group?.mTitle,
      mProperties: {
        __typename: 'GroupPolicy',
        policies,
      },
    };
  });

const getGroupPolicyPayload = (changedRows, groupPoliciesDb) => {
  const changedPermissionsInGroups = getchangedPermissionsInGroups(changedRows, groupPoliciesDb);
  return getPayload(changedPermissionsInGroups);
};

const updatePoliciesInConfig = (user, members, setPolicies, groupPolicies) => {
  const groupPoliciesUpdated = groupPolicies.map((groupPolicy) => {
    const found = members.find((member) => member.mRefId === groupPolicy.mRefId);
    if (found) return found;
    return groupPolicy;
  });
  const userRights = calculateUserRights(user?.groups, groupPoliciesUpdated);
  setPolicies(userRights);
};

const useUpdateGroup = () => {
  const [changedRows] = useChangedRows();
  const user = useContext(UserContext);
  const [, setPolicies] = usePolicies();

  const [updateGroupPermissions] = useMutation(UPDATE_GROUP_PERMISSIONS);
  const [loading, setLoading] = useState(false);
  const updatePermissions = async (groupPolicies) => {
    // set access attribute of changedRows to access attributes of groupPolices in DB
    const payload = getGroupPolicyPayload(changedRows, groupPolicies);
    setLoading(true);
    const promise = await updateGroupPermissions({
      variables: {
        input: { payload },
      },
    });
    setLoading(false);
    const members = promise?.data?.updateGroupPermissions?.groupPermissions;
    updatePoliciesInConfig(user, members, setPolicies, groupPolicies);
    return promise;
  };
  return [updatePermissions, loading];
};

export default useUpdateGroup;
