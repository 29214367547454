import React from 'react';
import Button from 'components/buttons/button';
import TextArea from 'components/textArea';
import { auditFailure } from 'utils/sendErrorLogToBackend';
import {
  DetailsContainer,
  DetailsWrapper,
  DetailsHeader,
  DetailsSubHeader,
  DescWarpper,
  DescButtonWrapper,
  FWDivider,
  RefreshHeader,
  RefreshWrapper,
  RefreshButonWrapper,
  OrText,
  VersionDetails,
  CommonHeader,
  CommonText,
  ErrorWrapper,
} from './styled';

function Details(props) {
  const { client, timeStamp, isDevEnv, error, errorInfo, canSendError } = props;

  const dinaVersion = process.env.REACT_APP_VERSION || 'v0.0.0';
  const dinaVersionDate = process.env.REACT_APP_VERSION_DATE || new Date().toISOString();

  if (!isDevEnv && error) {
    auditFailure(
      client,
      {
        message: error.message,
        code: error.code,
        stack: error.stack,
        origin: 'ux',
      },
      timeStamp,
    );
  }
  return (
    <DetailsContainer>
      <DetailsWrapper>
        <DetailsHeader>Something went wrong on our end, we are sorry about that.</DetailsHeader>
        <DetailsSubHeader>
          We have already logged the error, and will fix it as soon as possible!
        </DetailsSubHeader>
        {canSendError && (
          <DescWarpper>
            <CommonHeader>Optional Description:</CommonHeader>
            <TextArea
              disableLabel
              rows="3"
              placeholder="Please describe what happened..."
              description={`
            It is very helpful if you tell us what happened just before the error happened.
            It helps us to solve the problem faster.`}
            />
            <DescButtonWrapper>
              <Button width={220}>Send optional Description</Button>
            </DescButtonWrapper>
          </DescWarpper>
        )}
        <FWDivider />
        <RefreshHeader>In the meantime, you can try two things:</RefreshHeader>
        <RefreshWrapper>
          <RefreshButonWrapper>
            <Button usage="significant" onClick={() => window.location.reload()}>
              Refresh page, and try again
            </Button>
            <CommonText>
              Will reload the page.
              <br />
              You can pick up where you left off.
            </CommonText>
          </RefreshButonWrapper>
          <OrText>...or</OrText>
          <RefreshButonWrapper>
            <Button
              usage="significant"
              onClick={async () => {
                await client.clearStore();
                window.location.reload();
              }}
            >
              Restart Dina
            </Button>
            <CommonText>
              Will start a new session of Dina.
              <br />
              You need to find and open your work again.
            </CommonText>
          </RefreshButonWrapper>
        </RefreshWrapper>
        <FWDivider />
        <VersionDetails>
          <CommonHeader>Technical information (already logged):</CommonHeader>
          <CommonText>Dina Version: {dinaVersion}</CommonText>
          <CommonText>Dina Version Date: {dinaVersionDate}</CommonText>
          <CommonText>Time of incident: {timeStamp.toString()}</CommonText>
          <CommonText>Time of incident (ISO): {timeStamp.toISOString()}</CommonText>
          <CommonText>Error.message: {error.toString()}</CommonText>
          {error && error.code && <CommonText>Error.code: {error.code}</CommonText>}
        </VersionDetails>
        {isDevEnv && error && (
          <ErrorWrapper>
            {error.toString()}
            <br />
            {errorInfo.componentStack}
          </ErrorWrapper>
        )}
      </DetailsWrapper>
    </DetailsContainer>
  );
}

export default Details;
