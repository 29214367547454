import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    backgroundColor: theme.palette.dina.surfaceAppBackgroundNavLevel1,
  },
  appBar: {
    backgroundColor: 'transparent',
  },
  tabsPaper: {
    backgroundColor: theme.palette.dina.surfaceAppBackgroundNavLevel1,
  },
  divider: {
    position: 'relative',
    top: -1,
  },
  contentWrapper: {
    height: 'calc(100% - 48px)',
  },
}));

export default useStyles;
