import styled from '@emotion/styled';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';

import Checkbox from 'components/checkbox';
import Input from 'components/input/Input';
import Text from 'components/text/Text';
import { HStack } from 'layouts/box/Box';
import { EditorCommandConfigType } from 'types/memberTypes/editorCommands';

import { ChangedEditorCommandType, useChangedEditorCommands } from '../../../../../atomsTs';

import ConfigDropdown, { ConfigType } from './ConfigDropdown';

// Color circle
export const ColorButtonWrapper = styled('span')<{ $color?: string }>`
  width: 16px;
  height: 16px;
  margin-right: 4px;
  background-color: ${({ $color, theme }) => $color ?? theme.palette.dina.borderHover};
  border: 1px solid ${({ theme }) => theme.palette.dina.buttonBorderOutlined};
  outline: none;
  border-radius: 50%;
  cursor: pointer;
  :focus {
    outline: 2px solid ${({ theme }) => theme.palette.dina.statusOnFocused};
  }
  :focus-visible {
    outline: 2px solid ${({ theme }) => theme.palette.dina.statusOnFocused};
  }
`;

// Brings up the color input
export const ColorButton = styled('input')`
  width: 100%;
  height: 100%;
  opacity: 0;
`;

const Root = styled('div')`
  padding-block: 8px;
  padding-inline: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const FormControlLabel = styled(MuiFormControlLabel)`
  margin-left: 0;
  .MuiCheckbox-root {
    width: 22px;
    height: 22px;
    margin-right: 6px;
  }
  .MuiFormControlLabel-label {
    font-size: 13px;
  }
`;

interface CommandPropertiesProps {
  readonly commandProperties: EditorCommandConfigType;
}

function EditCommandProperties({ commandProperties }: CommandPropertiesProps) {
  const [, setChangedCommands] = useChangedEditorCommands();

  const onChange = (key: keyof ChangedEditorCommandType, value: string | boolean) =>
    setChangedCommands((prev) => {
      const matchedProperty = prev.find((command) => command.mRefId === commandProperties.mRefId);

      if (!matchedProperty) return [...prev, { mRefId: commandProperties.mRefId, [key]: value }];

      return prev.map((command) =>
        command.mRefId === commandProperties.mRefId ? { ...command, [key]: value } : command,
      );
    });

  const updateConfig = (newConfig: ConfigType | null) => {
    if (newConfig?.id) onChange('mSecId', newConfig.id);
    if (newConfig?.type) onChange('mResourceType', newConfig.type);
  };

  const { mTitle, slashCommand, mActive, mSecId, mColor } = commandProperties;

  return (
    <Root>
      <ConfigDropdown disableClearable disabled value={mSecId} onSelectConfig={updateConfig} />
      <Text variant="overline">Title</Text>
      <Input initialValue={mTitle} updateValue={(value) => onChange('mTitle', value)} />
      <Text variant="overline">Slash command</Text>
      <Input initialValue={slashCommand} updateValue={(value) => onChange('slashCommand', value)} />
      <FormControlLabel
        key="active"
        label="Active"
        control={<Checkbox selected={mActive} onClick={() => onChange('mActive', !mActive)} />}
      />
      <HStack justifyContent="start" gap="10px">
        Block color:
        <ColorButtonWrapper $color={mColor}>
          <ColorButton
            type="color"
            value={mColor}
            onChange={(e) => onChange('mColor', e.currentTarget.value)}
          />
        </ColorButtonWrapper>
      </HStack>
    </Root>
  );
}

export default EditCommandProperties;
