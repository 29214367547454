import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import Divider from 'components/divider';

export const DetailsContainer = styled('div')`
  width: 100vw;
  height: 100vh;
  padding: 36px;
  background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DetailsWrapper = styled('div')`
  position: relative;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const DetailsHeader = styled(Typography)`
  ${({ theme }) => theme.typography.dina.h4};
`;

export const DetailsSubHeader = styled(Typography)`
  ${({ theme }) => theme.typography.dina.body2};
`;

export const DescWarpper = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

export const DescButtonWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
`;

export const FWDivider = styled(Divider)`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.palette.dina.dividerLight};
  margin-block: 8px;
`;

export const RefreshHeader = styled(Typography)`
  ${({ theme }) => theme.typography.dina.h6};
`;

export const RefreshWrapper = styled('div')`
  display: flex;
  margin-block: 8px;
`;

export const RefreshButonWrapper = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const OrText = styled(Typography)`
  ${({ theme }) => theme.typography.dina.listItemLabelItalic};
  text-align: center;
  line-height: 40px;
  width: 80px;
  height: 40px;
`;

export const VersionDetails = styled('div')`
  white-space: nowrap;
`;

export const CommonHeader = styled(Typography)`
  ${({ theme }) => theme.typography.dina.listItemLabelmedium};
  margin-bottom: 8px;
`;

export const CommonText = styled(Typography)`
  ${({ theme }) => theme.typography.dina.caption};
`;

export const ErrorWrapper = styled('details')`
  white-space: pre-wrap;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.dina.errorText};
`;
