import { eachDay, format } from 'date-fns';

import { SearchDateRange } from 'api/search/useSearch';
import { MemberType } from 'types/graphqlTypes';

const DATE_FORMAT = 'YYYY-MM-DD';

export type GroupedByDateData = {
  [x: string]: MemberType[];
};

export const getEachDaysInDateRange = (dateRange: SearchDateRange) =>
  eachDay(dateRange.from, dateRange.to);

export const getGroupedByDateData = (items: MemberType[], dateRange: SearchDateRange) => {
  const groups = {} as GroupedByDateData;
  getEachDaysInDateRange(dateRange).forEach((date) => {
    groups[format(date, DATE_FORMAT)] = [];
  });
  items.forEach((item) => {
    const groupKey = format(item.mPublishingAt as string, DATE_FORMAT);
    if (groups[groupKey]) groups[groupKey].push(item);
  });
  return groups;
};
