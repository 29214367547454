import { memo } from 'react';
import { isToday } from 'date-fns';

import { useCurrentTabValue, useToolbar } from 'store';

import { StyledAppBar as AppBar, StyledToolbar as ToolBar } from './styled';

function Toolbar() {
  const [toolbar] = useToolbar();
  const currentTab = useCurrentTabValue();

  return (
    <AppBar
      position="relative"
      $mType={currentTab?.type}
      $isToday={currentTab?.type === 'rundown' && isToday(currentTab?.selectedDate ?? '')}
      $isStory={currentTab?.type === 'story'}
    >
      <ToolBar>{toolbar}</ToolBar>
    </AppBar>
  );
}

export default memo(Toolbar);
