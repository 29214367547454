import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  listItem: {
    display: 'flex',
    minHeight: '54px',
    justifyContent: 'flex-start',
    overflow: 'auto',
  },
  divider: {
    backgroundColor: theme.palette.dina.dividerLight,
  },
  button: {
    '&:hover': {
      background: theme.palette.dina.hoverOverlay,
    },
    '&:focus': {
      backgroundColor: theme.palette.dina.onFocus,
    },
  },
  titleLine: {
    ...theme.typography.dina.listItemLabel,
    marginLeft: '8px',
  },
  icon: {
    height: '24px',
    width: '24px',
  },
}));

export default useStyles;
