import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import UPLOAD_ASSET from 'graphql/mutations/uploadAsset';
import useLogger from 'utils/useLogger';

const useUploadAsset = () => {
  const logger = useLogger('use upload Asset');
  const [uploadAsset] = useMutation(UPLOAD_ASSET);

  const getUploadUrl = useCallback(async (payload) => {
    const { mId, itemType, filename, mRefId, mTitle, fileType } = payload || {};

    const input = {
      mId,
      mRefId,
      mTitle,
      itemType,
      filename,
      fileType,
    };

    try {
      const {
        data: { uploadAsset: mutationResp },
      } = await uploadAsset({
        variables: { input },
      });

      logger.log('response from uploadAsset', mutationResp);
      const { mMetaData = [], mRefId: newAssetId } = mutationResp;
      const signedUrl = mMetaData?.find((obj) => obj.key === 'uploadSignedUrl')?.value;
      const result = { mId, mRefId: newAssetId };
      return { result, signedUrl };
    } catch (error) {
      logger.log('Fetching SignedUrl failed = ', error);
      return { result: { mId, mRefId }, signedUrl: null };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [getUploadUrl];
};

export default useUploadAsset;
