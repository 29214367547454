import React from 'react';
import useCheckUserRight from 'hooks/useCheckUserRight';
import ListItem from 'components/listItem';
import { ReactComponent as MobilePortrait } from 'assets/icons/systemicons/mobile_portait.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/systemicons/appMenu/info_off.svg';
import { ReactComponent as LegalIcon } from 'assets/icons/systemicons/appMenu/legal_icon.svg';
import { ReactComponent as Settings } from 'assets/icons/systemicons/settings_off.svg';
import { ReactComponent as Changelog } from 'assets/icons/systemicons/celebration.svg';

import URLS from 'utils/constants/urls';
import useStyles from './info-styles';

const Info = ({ setShowQR, setSettingOpen, onClose }) => {
  const classes = useStyles();
  const [checkUserRight] = useCheckUserRight();
  const mobileApp = checkUserRight('feature', 'mobile-app');
  const { ABOUT, TERMS_CONDITIONS, CHANGELOG_URL } = URLS;

  const openNewTab = (url) => window.open(url, '_blank').focus();

  return (
    <div className={classes.root}>
      <ListItem
        key={1}
        icon={<Settings />}
        text="Settings"
        onClick={() => {
          setSettingOpen(true);
          onClose();
        }}
        bottomDivider
      />
      {mobileApp && (
        <ListItem
          key={2}
          icon={<MobilePortrait />}
          text="Dina Mobile configuration"
          onClick={() => setShowQR(true)}
          bottomDivider
        />
      )}
      <ListItem key={3} icon={<InfoIcon />} text="About Dina" onClick={() => openNewTab(ABOUT)} />
      <ListItem
        key={4}
        icon={<LegalIcon />}
        text="Terms &amp; Conditions"
        onClick={() => openNewTab(TERMS_CONDITIONS)}
      />
      <ListItem
        key={5}
        icon={<Changelog />}
        text="Changelog"
        onClick={() => openNewTab(CHANGELOG_URL)}
      />
    </div>
  );
};

export default Info;
