import { useCallback, useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';

import { CommandToolbarProps } from 'components/command/command-types';
import { DateRange } from 'components/mdfEditor/fields/date/DatePicker';
import { isObject } from 'types';
import { FieldValue, Metadata, SearchMetadata } from 'types/forms/forms';
import { MemberType, PaginatedItemType, SearchItemInput } from 'types/graphqlTypes';

import {
  SEARCH,
  SEARCH_STORY_HUB_DEFAULT,
  SEARCH_STORY_HUB_HOUR,
  SEARCH_STORY_HUB_INSTANCES,
} from './queries';

export const Queries = {
  default: SEARCH,
  storyHub: SEARCH_STORY_HUB_DEFAULT,
  storyHubInstances: SEARCH_STORY_HUB_INSTANCES,
  storyHubHour: SEARCH_STORY_HUB_HOUR,
};

export interface SearchResult {
  searchItem: PaginatedItemType;
}

export interface GetInput {
  input: SearchItemInput;
}

export type SearchDateRange = {
  from: string;
  to: string;
};

export interface SearchProps {
  skip: boolean;
  searchString: string;
  toolbarState: CommandToolbarProps;
  perPagelimit?: number;
  metadataFilter?: Metadata;
  mdfId?: string;
  doPoll?: boolean;
  pollInterval?: number;
  fetchAll?: boolean;
  queryType?: keyof typeof Queries;

  // If provided, provided searchString will be applied
  // to the provided prop, for now only mTitle is supported
  searchableKey?: SearchableKeys;
}

/**
 * Currently only date type can be an object in Metadata values.
 */
export const isDateRange = (obj: FieldValue): obj is DateRange => {
  if (!isObject(obj) || Array.isArray(obj)) return false;
  return true;
};

const process = (metadata: Metadata): SearchMetadata => {
  const copy: SearchMetadata = {};
  Object.entries(metadata).forEach(([key, value]) => {
    if (typeof value === 'string') {
      if (value.trim().length > 0) copy[key] = value;
    } else if (isDateRange(value)) {
      copy[key] = {
        from: value.startDate,
        to: value.endDate ?? value.startDate,
      };
    } else if (Array.isArray(value) && value.length > 0) {
      copy[key] = value;
    } else {
      copy[key] = value;
    }
  });
  return copy;
};

export const getInput = (
  searchString: string,
  searchableKey: SearchableKeys | undefined,
  state: CommandToolbarProps,
  perPagelimit: number,
  metadataFilter: Metadata,
  mdfId: string | undefined,
): SearchItemInput => {
  const {
    rangeBy,
    semanticSearch,
    statusFilter,
    mTypes,
    platformTypes,
    sortBy,
    order,
    assignedIds,
    createdByIds,
    isScheduled,
  } = state;
  const derivedSearchString = searchString.length > 0 ? searchString : undefined;
  return {
    perPagelimit,
    mdfId,
    mTypes,
    rangeBy: rangeBy ?? undefined,
    searchString: !searchableKey ? derivedSearchString : undefined,
    mStates: statusFilter.length ? statusFilter : undefined,
    orderBy: sortBy !== 'best' ? { [sortBy]: order } : undefined,
    assignedMemberIds: assignedIds.length ? assignedIds : undefined,
    createdByIds: createdByIds.length ? createdByIds : undefined,
    metaDataFilter: !isEmpty(metadataFilter) ? JSON.stringify(process(metadataFilter)) : undefined,
    platformTypes: platformTypes?.length ? platformTypes : undefined,
    semanticSearch: semanticSearch === true ? true : undefined,
    ...(searchableKey && { [searchableKey]: derivedSearchString }),
    isScheduled,
  };
};

export type SearchParameters = Omit<SearchProps, 'doPoll' | 'queryType'>;
type SearchableKeys = keyof Pick<MemberType, 'mTitle'>;

export const useSearch = ({
  skip,
  searchString,
  searchableKey,
  perPagelimit = 25,
  metadataFilter = {},
  mdfId,
  toolbarState,
  doPoll,
  pollInterval = 30000,
  queryType = 'default',
}: SearchProps) => {
  const input = useMemo(
    () => getInput(searchString, searchableKey, toolbarState, perPagelimit, metadataFilter, mdfId),
    [searchString, toolbarState, perPagelimit, metadataFilter, mdfId],
  );

  const searchResult = useQuery<SearchResult, GetInput>(Queries[queryType], {
    variables: {
      input,
    },
    fetchPolicy: 'cache-and-network',
    skip,
  });

  const { data, error, loading, fetchMore, startPolling, stopPolling, refetch } = useMemo(
    () => searchResult,
    [searchResult],
  );

  useEffect(() => {
    if (doPoll) startPolling(pollInterval);

    return () => {
      if (doPoll) stopPolling();
    };
  }, [doPoll, pollInterval]);

  const items = useMemo(() => data?.searchItem?.items ?? [], [data?.searchItem?.items]);

  const handleLoadMore = useCallback(async () => {
    if (data?.searchItem.nextToken && items.length < data?.searchItem.total) {
      await fetchMore({
        variables: {
          input: {
            ...input,
            searchAfter: data?.searchItem.nextToken,
          },
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          return {
            searchItem: {
              items: [...previousResult.searchItem.items, ...fetchMoreResult.searchItem.items],
              nextToken: fetchMoreResult?.searchItem.nextToken,
              total: fetchMoreResult?.searchItem.total,
            },
          };
        },
      });
    }
  }, [data?.searchItem.nextToken, data?.searchItem.total, items.length, fetchMore, input]);

  return {
    items,
    error,
    loading,
    total: data?.searchItem?.total ?? 0,
    handleLoadMore,
    refetch,
  };
};
