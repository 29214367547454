import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { DragPreviewImage, useDrag, useDrop } from 'react-dnd';
import { Editor } from 'slate';
import { ReactEditor, useReadOnly, useSelected, useSlate } from 'slate-react';
import styled from '@emotion/styled';
import useDragEnd from 'components/editor/hooks/useDragEnd';
import dndTypes from 'utils/dndTypes';
import transientOptions from 'theme/helpers';
import isSection from '../sectionDivider/utils/isSection';

const DropZone = styled('div', transientOptions)`
  position: relative;
  opacity: ${(props) => (props.$isDragging ? 0.4 : 1)};
  pointer-events: ${(props) => (props.readOnly ? 'none' : 'all')};
  border-radius: 4px;
  background: ${({ $showHighlight, theme }) =>
    $showHighlight && theme.palette.dina.blackHoverOverlay};
  ::before {
    content: '';
    position: absolute;
    background-color: ${({ $hovered, $isDragging, theme }) =>
      $hovered && !$isDragging ? theme.palette.dina.onFocus : 'transparent'};
    width: calc(100% - 16px);
    left: 8px;
    bottom: 0;
    height: 3px;
  }
`;

const DragAndDrop = ({
  element,
  children,
  isDragDisabled,
  hideHighlight,
  dragPreviewImageSrc,
  enableReadOnly,
}) => {
  const isEditorReadonly = useReadOnly();
  const readOnly = enableReadOnly && isEditorReadonly;
  const isSelected = useSelected(element);
  const editor = useSlate();
  const showHighlight = !readOnly && !hideHighlight && isSelected;

  const [onDragEnd] = useDragEnd();

  const [{ isDragging }, drag, preview] = useDrag({
    type: dndTypes.EDITOR_BLOCK,
    item: () => ({ type: dndTypes.EDITOR_BLOCK, payload: element }),
    canDrag: () => !readOnly,
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  const [{ hovered }, drop] = useDrop({
    accept: dndTypes.EDITOR_BLOCK,
    canDrop: (item) => {
      if (isSection(element)) return false;
      if (isSection(item.payload)) {
        const path = ReactEditor.findPath(editor, element);
        if (path.length > 0) {
          const [parentNode] = Editor.parent(editor, path);
          if (isSection(parentNode)) {
            return parentNode?.data?.itemId !== item.payload?.data?.itemId;
          }
        }
      }
      return !readOnly && !isDragging;
    },

    drop: (item) => onDragEnd(item.payload, element),
    collect: (monitor) => ({ hovered: monitor.canDrop() && monitor.isOver() }),
  });

  const attachRef = useCallback((elm) => {
    if (!isDragDisabled) drag(elm);
    drop(elm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {dragPreviewImageSrc && <DragPreviewImage connect={preview} src={dragPreviewImageSrc} />}
      <DropZone
        $hovered={hovered}
        $isDragging={isDragging}
        readOnly={readOnly}
        $showHighlight={showHighlight}
        ref={attachRef}
      >
        {children}
      </DropZone>
    </>
  );
};

DragAndDrop.propTypes = {
  /** Drag and drop wrapped children */
  children: PropTypes.node.isRequired,
  /** SlateJS element */
  element: PropTypes.shape({}).isRequired,
  /** Whether block is disabled or not */
  isDragDisabled: PropTypes.bool,
  /** Whether to show highlight around the wrapper */
  hideHighlight: PropTypes.bool,
  dragPreviewImageSrc: PropTypes.string,
  enableReadOnly: PropTypes.bool,
};

DragAndDrop.defaultProps = {
  isDragDisabled: false,
  hideHighlight: false,
  dragPreviewImageSrc: undefined,
  enableReadOnly: true,
};

export default memo(DragAndDrop);
