import { Dispatch, SetStateAction, useCallback } from 'react';
import { useMutation } from '@apollo/client';

import memberTypes from 'graphql/memberTypes';
import CREATE_USER from 'graphql/mutations/createUsers';
import GET_ALL_USERS from 'graphql/queries/getAllDbUsers';
import { useMembers } from 'store';
import { AssignedMember } from 'types';
import { MemberType } from 'types/graphqlTypes';
import useLogger from 'utils/useLogger';

interface QueryUsersList {
  getUsers: MemberType[];
}

interface CreateUserType {
  createUsers: MemberType[];
}

const useCreateUser = () => {
  const logger = useLogger('CreateUser');
  const [createUser] = useMutation<CreateUserType>(CREATE_USER);
  const [members, setMembers] = useMembers();

  const createNewUser = useCallback(
    async (
      email: string,
      username: string,
      setErrorText: Dispatch<SetStateAction<string>>,
      handleOnCancel: () => void,
    ) => {
      const variables = {
        input: {
          users: [
            {
              email,
              username,
            },
          ],
        },
      };
      await createUser({
        variables,
        update: (client, mutationResult) => {
          const [createSingleUser] = mutationResult?.data?.createUsers || [];
          if (createSingleUser) {
            if (!createSingleUser?.mTitle) {
              createSingleUser.mTitle = username;
            }
            try {
              setMembers({
                ...members,
                [memberTypes.USER]: [
                  ...members[memberTypes.USER],
                  createSingleUser as AssignedMember,
                ],
              });
              const usersList = client.readQuery({
                query: GET_ALL_USERS,
              }) as QueryUsersList;
              const usersInCache = usersList?.getUsers ? [...usersList.getUsers] : [];

              const isAlreadyInCache = usersInCache?.find((user) => {
                return user.mId === createSingleUser.mId;
              });

              if (isAlreadyInCache) return;

              const updatedUsers = [...usersInCache, createSingleUser];
              client.writeQuery({
                query: GET_ALL_USERS,
                data: {
                  getUsers: updatedUsers,
                },
              });
              handleOnCancel();
            } catch (err) {
              logger.log(err);
            }
          }
        },
        onError: (error) => {
          logger.log(error.message);
          setErrorText('Something went wrong. Please try again');
        },
      });
    },
    [createUser, logger, members, setMembers],
  );
  return createNewUser;
};

export default useCreateUser;
