import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';

import { getMembersQuery } from 'graphql/queryVariables';
import { MemberType } from 'types/graphqlTypes';
import useLogger from 'utils/useLogger';

import { ChangedWebhookType, useChangedWebhooks } from '../atomsTs';

import { GET_WEBHOOKS, GetMembersType } from './useGetWebhooks';

const UPDATE_WEBHOOK = gql`
  mutation updateWebhook($input: UpdateMemberInput) {
    updateMember(input: $input) {
      mId
      mRefId
      mType
      mTitle
      mAllowedEntities {
        mType
      }
      metadata
      mInvokeUrl
    }
  }
`;

interface UpdateWebhookType {
  updateMember: MemberType;
}

const useUpdateWebhooks = () => {
  const logger = useLogger('useUpdateWebhooks');
  const [changedWebhooks] = useChangedWebhooks();
  const [loading, setLoading] = useState(false);
  const [updateHookMutation] = useMutation<UpdateWebhookType>(UPDATE_WEBHOOK);

  const updateWebhook = async (changedHook: ChangedWebhookType) => {
    const variables = {
      input: {
        mId: 'endpoint',
        mRefId: changedHook.key,
        mInvokeUrl: changedHook.value,
      },
    };

    return updateHookMutation({
      variables,
      update: (cache, result) => {
        const { updateMember: updatedHook } = result?.data ?? {};
        if (!updatedHook) return;

        try {
          const getMembersVariables = getMembersQuery('endpoint', 'hook');

          const { getMembers: currentWebhooks = [] } =
            cache.readQuery<GetMembersType>({
              query: GET_WEBHOOKS,
              variables: getMembersVariables,
            }) ?? {};

          if (
            !currentWebhooks.length &&
            !currentWebhooks?.find((hook) => hook.mRefId === updatedHook.mRefId)
          )
            return;

          cache.writeQuery<GetMembersType>({
            query: GET_WEBHOOKS,
            variables: getMembersVariables,
            data: {
              getMembers: currentWebhooks.map((hook) =>
                hook.mRefId === updatedHook.mRefId
                  ? { ...hook, mInvokeUrl: updatedHook.mInvokeUrl }
                  : hook,
              ),
            },
          });
        } catch (err: unknown) {
          logger.log(err);
        }
      },
    });
  };

  /* update multiple webhook endpoints */
  const updateWebhooks = async () => {
    setLoading(true);
    const promises = changedWebhooks.map((changedHook) => updateWebhook(changedHook));

    await Promise.allSettled(promises);
    setLoading(false);
  };

  return [updateWebhooks, loading];
};

export default useUpdateWebhooks;
