import { useNavigate } from 'react-router-dom';

import { ReactComponent as FeedsOff } from 'assets/icons/systemicons/HeaderNavbar/feeds_off.svg';
import { ReactComponent as FeedsOn } from 'assets/icons/systemicons/HeaderNavbar/feeds_on.svg';
import { ReactComponent as HomeOff } from 'assets/icons/systemicons/HeaderNavbar/home_off.svg';
import { ReactComponent as HomeOn } from 'assets/icons/systemicons/HeaderNavbar/home_on.svg';
import { ReactComponent as NavigateOff } from 'assets/icons/systemicons/HeaderNavbar/navigate_off.svg';
import { ReactComponent as NavigateOn } from 'assets/icons/systemicons/HeaderNavbar/navigate_on.svg';
import { ReactComponent as LocationOff } from 'assets/icons/systemicons/location_off.svg';
import { ReactComponent as LocationOn } from 'assets/icons/systemicons/location_on.svg';
import Tooltip from 'components/tooltip';
import { IconTab } from 'store';

import TabToolTip from '../tabTooltip';

import { StyledTab as Tab } from './styled';

const icons = {
  home: [HomeOff, HomeOn],
  feeds: [FeedsOff, FeedsOn],
  plans: [NavigateOff, NavigateOn],
  storyHub: [NavigateOff, NavigateOn],
  maps: [LocationOff, LocationOn],
};

interface Props {
  tab: IconTab;
  isSelected: boolean;
}

const IconTabView = ({ tab, isSelected }: Props) => {
  const navigate = useNavigate();
  const Icon = icons[tab.type][isSelected ? 1 : 0];

  return (
    <Tooltip title={<TabToolTip title={tab.title} />} noArrow maxWidth={80}>
      <Tab $isSelected={isSelected} onClick={() => navigate(`/${tab.type}`)}>
        <Icon className="skipOverride" />
      </Tab>
    </Tooltip>
  );
};

export default IconTabView;
