import { useMemo } from 'react';

import Dialog from 'components/dialog';
import LoadingIndicator from 'components/loadingIndicator';
import PdfViewer from 'components/pdfViewer/PdfViewer';
import useContentResolver from 'hooks/useContentResolver';
import { Instance } from 'types';

import InstancePrintDoc from './docs/InstancePrintDoc';

import { LoadingWrapper } from './styled';

interface InstancePrintProps {
  instance: Instance;
  isDialogOpen: boolean;
  onCloseDialog: () => void;
  clipDuration: string;
  scriptDuration: string;
  totalDuration: string;
}

function InstancePrint({
  instance,
  isDialogOpen,
  onCloseDialog,
  clipDuration,
  scriptDuration,
  totalDuration,
}: Readonly<InstancePrintProps>) {
  const { data, loading } = useContentResolver(instance?.mContentKey);

  const renderView = useMemo(() => {
    if (loading)
      return (
        <LoadingWrapper>
          <LoadingIndicator />
        </LoadingWrapper>
      );

    if (data)
      return (
        <PdfViewer>
          <InstancePrintDoc
            instance={instance}
            content={data}
            clipDuration={clipDuration}
            scriptDuration={scriptDuration}
            totalDuration={totalDuration}
          />
        </PdfViewer>
      );

    return null;
  }, [loading, data, clipDuration, scriptDuration, totalDuration, instance]);

  return (
    <Dialog container={null} open={isDialogOpen} onClose={onCloseDialog}>
      {renderView}
    </Dialog>
  );
}

export default InstancePrint;
