import styled from '@emotion/styled';
import { List } from '@material-ui/core';
import groupBy from 'lodash/groupBy';

import Divider from 'components/divider';
import useFuseSearch from 'hooks/useFuseSearch';
import { MemberType } from 'types/graphqlTypes';

import ListHeader from './listHeader';
import Member from './listItem';
import { ListType } from './types';

const DividerWrapper = styled('div')<{ isLast: boolean }>`
  padding-left: ${({ isLast }) => (isLast ? '0' : '56px')};
  background-color: ${({ theme, isLast }) =>
    isLast ? 'transparent' : theme?.palette?.dina?.surfaceAppBackgroundNavLevel2};
`;

interface MembersListProps {
  members: MemberType[];
  dropHandler: (userId: string, stroyId: string) => void;
  searchString: string;
  type: ListType;
}

const MembersList = (props: MembersListProps) => {
  const { members, dropHandler, type, searchString, ...rest } = props;
  const search = useFuseSearch();

  const filteredMembers = search(
    members,
    ['mTitle', 'mDescription', 'mProperties.email', 'mProperties.phone'],
    searchString,
  ) as MemberType[];

  const sortedMembers = filteredMembers
    .filter(({ mTitle }) => !!mTitle)
    .sort((a, b) => ((a?.mTitle ?? '').toLowerCase() > (b?.mTitle ?? '').toLowerCase() ? 1 : -1));

  const groupedMembers = groupBy(sortedMembers, ({ mTitle }) => mTitle?.charAt(0).toUpperCase());

  return Object.entries(groupedMembers).map(([groupTitle, groupMembers]) => {
    const numberOfItems = groupMembers.length;

    return numberOfItems > 0 ? (
      <List key={groupTitle} disablePadding>
        <ListHeader title={groupTitle} numberOfItems={numberOfItems} />
        {groupMembers.map((groupMember, index) => {
          const {
            mId,
            mTitle,
            mCreatedAt,
            mAvatarUrl,
            mDescription,
            mProperties,
            mAvatarKey,
            metadata,
          } = groupMember;

          return (
            <div key={mId}>
              <Member
                id={mId ?? ''}
                name={mTitle ?? ''}
                image={mAvatarUrl ?? ''}
                imageKey={mAvatarKey ?? ''}
                title={mCreatedAt ?? ''}
                description={mDescription ?? ''}
                properties={mProperties ?? {}}
                metadata={metadata ?? ''}
                type={type}
                handleDrop={(memberId, storyId) => dropHandler(memberId, storyId)}
                {...rest}
              />

              <DividerWrapper isLast={numberOfItems === index + 1}>
                <Divider />
              </DividerWrapper>
            </div>
          );
        })}
      </List>
    ) : null;
  });
};

export default MembersList;
