import { elementTypes } from 'components/editor/constants/types';
import isHotkey from 'is-hotkey';
import { Editor, Transforms, Range } from 'slate';
import onContentKeyDown from 'components/editor/components/content/utils/onContentKeyDown';
import preventDefaultEvent from 'utils/preventDefaultEvent';
import matchRestriction from './matchRestriction';
import selectElement from './selectElement';
import isSection from '../components/sectionDivider/utils/isSection';
import getSelectedElement from './getSelectedElement';
import isList from '../components/list/utils/isList';
import { insertParagraph } from '../components/paragraph/utils';
import { conditionalBlocksPlatformVariants } from './getConditionalToolbarBlocks';

const { nodes, isEmpty, previous, next } = Editor;
const { setNodes, removeNodes, insertText, move } = Transforms;
const elementTypeValues = Object.values(elementTypes);
const { isExpanded, isCollapsed, edges } = Range;

/**
 * Handles onKeyDown event on all elements
 *
 * @param {Object} editor SlateJS editor instance
 * @param {Object} event React synthetic event
 * @returns {Object} SlateJS editor instance
 */

const anchorInPath = (editor, anchor, path) => (isEdge) => isEdge(editor, anchor, path);

const checkIfStrictEqual = (arr1, arr2) => JSON.stringify(arr1) === JSON.stringify(arr2);

const onElementKeyDown = (
  editor,
  event,
  variant,
  isAllowed,
  isCmsBlock,
  isMessageVariant,
  onDone,
  onSectionWrap,
) => {
  try {
    const [match] = nodes(editor, {
      match: ({ type }) => elementTypeValues.includes(type),
    });

    const [element, path] = match;
    const { isVoid } = editor;
    const { key, altKey, ctrlKey, metaKey, shiftKey, code } = event;

    const shouldPrevent = !isCmsBlock && isAllowed && matchRestriction(variant);

    const isContentElement = element.type === elementTypes.CONTENT;
    const isShiftEnter = isHotkey('shift+enter')(event);

    const isEnter = key === 'Enter';
    const isBackspace = key === 'Backspace';
    const isDelete = key === 'Delete';
    const isSectionWrapperKey = altKey && (ctrlKey || metaKey) && code === 'KeyG';
    const isAddParagraphAfterSectionKey = altKey && (ctrlKey || metaKey) && isEnter;

    const { selection } = editor;
    const [start, end] = edges(selection);
    const isSectionDivider = isSection(element);

    const deleteNodes = () => {
      try {
        if (start.offset === 0 && end.offset === 0) {
          const depth = isSectionDivider ? 1 : 0;
          preventDefaultEvent(event);

          // if selection expands outside of one section divider
          if (isSectionDivider && start.path[0] !== end.path[0]) {
            Transforms.unwrapNodes(editor, {
              match: isSection,
              split: true,
            });
            Editor.deleteFragment(editor);
          } else {
            const limit = end.path[depth] + (Editor.node(editor, end)[depth].text === '' ? 1 : 0);
            for (let step = start.path[depth]; step < limit; step += 1) {
              removeNodes(editor, {
                at: depth ? [...path, start.path[depth]] : path,
              });
            }
          }
        } else if (isSectionDivider) {
          preventDefaultEvent(event);
          if (start.path[0] !== end.path[0]) {
            insertParagraph(editor, { at: start, mode: 'lowest' });
            Transforms.unwrapNodes(editor, {
              match: isSection,
              split: true,
            });
          }
          Editor.deleteFragment(editor);
        }
      } catch (e) {
        // console.log(e);
      }
    };

    const cursorAt = anchorInPath(editor, selection.anchor, path);

    const handleDeleteKeyPress = () => {
      const isEnd = cursorAt(Editor.isEnd);
      const isStart = cursorAt(Editor.isStart);

      if (shouldPrevent) onContentKeyDown(event, isContentElement, isEnd);

      const [nextNode] = next(editor, { at: path }) || [];

      if (!selection) return;

      if (isCollapsed(selection) && isEnd) {
        if (isSectionDivider) {
          const selectedChild = getSelectedElement(editor, { depth: 2 });
          if (editor.isVoid(selectedChild)) {
            if (isStart) insertParagraph(editor, { at: [...path, 1] });
            removeNodes(editor, { at: isStart ? [...path, 0] : undefined });
          }
          return preventDefaultEvent(event);
        }
        if (nextNode && isVoid(nextNode)) {
          preventDefaultEvent(event);
          if ((isEmpty(editor, element) || isVoid(element)) && !shouldPrevent) {
            selectElement(editor, nextNode);
            removeNodes(editor, { at: path });
          }
        }
      }
      if (isExpanded(selection)) {
        if (isContentElement && checkIfStrictEqual(start.path, end.path)) {
          Transforms.delete(editor);
        }

        deleteNodes();

        if (isContentElement && checkIfStrictEqual(start.path, end.path)) {
          Transforms.delete(editor);
        }
      }
    };

    if (match) {
      if (isCmsBlock || conditionalBlocksPlatformVariants.includes(variant)) {
        if (isSectionWrapperKey) onSectionWrap(shiftKey);
        if (isSectionDivider && isAddParagraphAfterSectionKey) {
          preventDefaultEvent(event);
          insertParagraph(editor, {
            at: [path[0] + 1],
            select: true,
          });
        }
      }

      if (isMessageVariant && isEnter && !isShiftEnter) onDone();

      /** handle enter or shift+enter key press */
      if (shouldPrevent && isContentElement) {
        /** on shift+enter press enter a newline on content block */
        if (isShiftEnter || isEnter) {
          preventDefaultEvent(event);
          insertText(editor, '\n');
        }
      }

      /** handle backspace keypress */
      if (isBackspace) {
        const isStart = cursorAt(Editor.isStart);
        const previousNode = previous(editor, { at: path });
        if (isCollapsed(selection) && isStart && isSectionDivider) {
          const selectedChild = getSelectedElement(editor, { depth: 2 });
          const hasSingleChild = element.children.length === 1;

          if (isEmpty(editor, selectedChild)) {
            const targetPath = hasSingleChild ? path : [...path, 0];
            preventDefaultEvent(event);
            removeNodes(editor, { at: targetPath });
          } else if (
            isList(selectedChild) &&
            selectedChild.children.length === 1 &&
            isEmpty(editor, selectedChild.children[0])
          ) {
            preventDefaultEvent(event);
            removeNodes(editor, { at: [...path, 0] });
          } else if (!editor.isVoid(selectedChild)) {
            preventDefaultEvent(event);
            move(editor, { reverse: true });
          } else if (editor.isVoid(selectedChild)) {
            preventDefaultEvent(event);
            const targetPath = [...path, 0];
            removeNodes(editor, { at: targetPath });
            if (hasSingleChild) insertParagraph(editor, { at: targetPath, select: true });
          }
        }

        if (shouldPrevent) onContentKeyDown(event, isContentElement, isStart);

        if (!isContentElement && isEmpty(editor, element) && !previousNode) {
          setNodes(editor, { type: elementTypes.PARAGRAPH });
        }

        if (selection && isExpanded(selection)) {
          deleteNodes();
        }
      }

      if (isDelete) handleDeleteKeyPress();
    }
  } catch (e) {
    // useLogger.log(e);
  }

  return editor;
};

export default onElementKeyDown;
