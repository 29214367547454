import BooleanField from './boolean/BooleanField';
import SelectField from './select/SelectField';
import TextField from './text/TextField';
import ErrorField from './error/ErrorField';

const FieldComponents = {
  select: SelectField,
  boolean: BooleanField,
  text: TextField,
  error: ErrorField,
};

export default FieldComponents;
