import { PlatformAccountType } from './members';

export type WithRequired<Type, Key extends keyof Type> = Type & {
  [Property in Key]-?: Type[Property];
};

export type WithOptional<Type, Key extends keyof Type> = Omit<Type, Key> & Partial<Pick<Type, Key>>;

/**
 * Useful in switch statements to make them exhaustive; eg if a new
 * item is added to an enum or type that is used in a switch, using
 * this function in the default clause will cause typescript to
 * complain if you do not explicitly implement all possible cases.
 */
export const assertUnreachable = (val: never): never => {
  throw new Error('Should never get here', val);
};

export type DateRange = {
  startDate: Date | null;
  endDate: Date | null;
};

export type KanbanStatus = {
  id: string;
  name: string;
  description: string;
  icon: string;
  backgroundColor?: string;
  opacity?: number;
};

export interface DestinationAccount extends PlatformAccountType {
  publishingAt?: string;
  platformKind?: string;
  rundownTemplateId?: string;
}

export type Destination = {
  id: string | null;
  value: string;
  title: string;
  publishingTime?: string | null;
  startTime?: string;
  timeZone?: string;
  account?: DestinationAccount;
  rundownTemplateId?: string;
};

export type AutomationItem = {
  itemId: string;
  templateType: string;
  templateVariant: string;
  title: string;
};

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };

export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};
