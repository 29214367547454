import { useContext, useState } from 'react';
import { Reference, StoreObject, useApolloClient } from '@apollo/client';
import DeleteIcon from '@material-ui/icons/Delete';

import { DeleteDialog } from 'components/dialogs/CommonDialogs';
import UserCtx from 'contexts/UserContext';
import useCheckUserRight from 'hooks/useCheckUserRight';
import useCreateConvo from 'hooks/useCreateConvo';
import useDeleteContact from 'hooks/useDeleteContact';
import useGetUser from 'hooks/useGetUser';
import useStorageImage from 'hooks/useStorageImage';
import MenuCreator from 'screens/main/components/rightArea/menuCreator/MenuCreator';
import { useIsMessageHubOpen, useSelectedConversationId } from 'store';
import { AssignedMember } from 'types';
import { Metadata } from 'types/forms/forms';
import { MProperties } from 'types/graphqlTypes';
import { uploadToS3 } from 'utils/s3Utils';
import useLogger from 'utils/useLogger';

import DetailView from './detail-view';

interface ContactType {
  id: string | StoreObject | Reference;
  name?: string;
  title?: string;
  description?: string;
  image?: string;
  imageKey?: string;
  type: string;
  properties: MProperties;
  metadata?: string;
}

interface DetailContainerProps {
  showMaster: () => void;
  selectedItem: ContactType;
  contactTab: number;
}

function DetailContainer({
  showMaster,
  selectedItem,
  contactTab,
  ...rest
}: Readonly<DetailContainerProps>) {
  const logger = useLogger('contact detail container');
  const [showTopMenu, setShowTopMenu] = useState<HTMLButtonElement | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const client = useApolloClient();
  const [checkUserRight] = useCheckUserRight();
  const allowDeleteUser = checkUserRight('feature', 'disable-users');
  const user = useContext(UserCtx);
  const currentUserId = user.mId;

  const { getUser } = useGetUser();

  const { createConvo } = useCreateConvo();
  const [, setIsMessageHubOpen] = useIsMessageHubOpen();
  const [, setSelectedConvoId] = useSelectedConversationId();

  const getDisabled = () => {
    if (!allowDeleteUser && contactTab === 0) return true;
    if (currentUserId === selectedItem.id) return true;
    return false;
  };

  const getTopRowOptions = () => {
    return [
      {
        label: 'Delete Contact',
        action: 'deleteContact',
        disabled: getDisabled(),
        Icon: DeleteIcon,
      },
    ];
  };

  const { image, imageKey: imageStoragePath } = selectedItem;
  const { data: imageSrc } = useStorageImage(imageStoragePath, !!image);

  const updateAvatar = async (imageFile: string, imageUrl: string) => {
    try {
      await uploadToS3(imageStoragePath, imageFile);

      client.cache.modify({
        id: client.cache.identify(selectedItem.id as Reference),
        fields: {
          mAvatarUrl: () => imageUrl,
        },
      });
    } catch (e) {
      logger.log(e);
    }
  };

  const [deleteContact] = useDeleteContact();

  const onDeleteContact = async (id: string) => {
    await deleteContact(id);
    showMaster();
  };

  const getDialogText = (type: string) => {
    switch (type) {
      case 'title':
        return 'Contact';
      case 'confirmButtonLabel':
        return 'Delete';
      case 'info':
        return `"${selectedItem.name}" will be deleted.`;
      default:
        return '';
    }
  };

  const handleChat = async () => {
    const member = getUser(selectedItem.id as string) as AssignedMember;
    await createConvo(member, (convo) => {
      setSelectedConvoId(convo?.mId);
      setIsMessageHubOpen(true);
    });
  };

  const handleEmailSend = () => {
    const parsedMetadata = JSON.parse(
      selectedItem?.metadata && selectedItem.metadata !== '' ? selectedItem.metadata : '{}',
    ) as Metadata;
    const emailTo = (parsedMetadata?.email ?? selectedItem?.properties?.email) as string;
    window.location.assign(`mailto: ${emailTo}`);
  };

  const handleMenuButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShowTopMenu(event.currentTarget);
  };

  const handleMenuCreatorClose = ({ action }: Record<string, string>) => {
    if (action === 'deleteContact') setDeleteDialogOpen(true);
    setShowTopMenu(null);
  };

  return (
    <>
      <DetailView
        {...rest}
        showMaster={showMaster}
        contactTab={contactTab}
        updateAvatar={updateAvatar}
        selectedItem={selectedItem}
        user={user}
        showMenu={true}
        avatarUrl={imageSrc ?? image}
        onMenuButtonClick={handleMenuButtonClick}
        onChatButtonClick={handleChat}
        onEmailButtonClick={handleEmailSend}
      />
      <>
        <MenuCreator
          onClose={handleMenuCreatorClose}
          anchorEl={showTopMenu}
          menuOptions={getTopRowOptions()}
          position="right-top"
        />
        <DeleteDialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          onClick={() => onDeleteContact(selectedItem.id as string)}
          title={getDialogText('title')}
          confirmLabel={getDialogText('confirmButtonLabel')}
          message={getDialogText('info')}
        />
      </>
    </>
  );
}

export default DetailContainer;
