import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSlate, ReactEditor, useSelected, useReadOnly } from 'slate-react';
import { Transforms } from 'slate';
import twitterSrc from 'assets/icons/systemicons/publishing_points/twitter.svg';
import { onImageLoadingError } from 'utils/image/imageUtils';
import Divider from 'components/divider';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import FallbackImage from 'assets/images/default/defaultThumbnail.png';
import { elementTypes } from 'components/editor/constants/types';
import { selectElement } from 'components/editor/utils';
import {
  RootWrapper,
  ContentWrapper,
  Header,
  ThumbnailWrapper,
  ThumbnailImage,
  PublishingPointImage,
  ThreadWrapper,
  ThreadHeader,
  ThreadLabel,
  CloseIcon,
  ConfirmButton,
} from './styled';

const { TWEET_THREAD } = elementTypes;
const { removeNodes } = Transforms;

const TweetThread = ({ attributes, children, element }) => {
  const readOnly = useReadOnly();
  const [buttonType, setButtonType] = useState('icon');
  const { thumbnail } = useEditorContext();
  const isSelected = useSelected(element);
  const editor = useSlate();
  const path = ReactEditor.findPath(editor, element);

  useEffect(() => {
    if (buttonType !== 'icon') setButtonType('icon');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected]);

  const confirmDelete = useCallback(() => {
    removeNodes(editor, { at: path });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMouseOver = useCallback(() => {
    selectElement(editor, element);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onThumbnailLoadingError = useCallback(onImageLoadingError, []);

  const [index] = path;

  const count = editor.children.reduce(
    (acc, cur) => (cur.type === TWEET_THREAD ? acc + 1 : acc),
    0,
  );
  const showHeader = count > 1;

  return (
    <RootWrapper {...attributes}>
      <ContentWrapper showHeader={showHeader}>{children}</ContentWrapper>
      {showHeader && (
        <Header contentEditable={false}>
          <ThumbnailWrapper>
            <ThumbnailImage
              src={thumbnail || FallbackImage}
              onError={onThumbnailLoadingError}
              alt="rundown-thumbnail"
            />
            <PublishingPointImage src={twitterSrc} alt="publishing-point" />
          </ThumbnailWrapper>
          <ThreadWrapper>
            <Divider />
            <ThreadHeader>
              <ThreadLabel>{`Thread Item #${index + 1}`}</ThreadLabel>
              {buttonType === 'icon' && (
                <CloseIcon
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    if (buttonType === 'icon') setButtonType('confirm');
                  }}
                  readOnly={readOnly}
                  onFocus={() => {}}
                  onMouseOver={handleMouseOver}
                  title="Delete Thread item"
                />
              )}
              {buttonType === 'confirm' && (
                <ConfirmButton readOnly={readOnly} onClick={confirmDelete}>
                  Click again to Delete
                </ConfirmButton>
              )}
            </ThreadHeader>
          </ThreadWrapper>
        </Header>
      )}
    </RootWrapper>
  );
};

TweetThread.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

TweetThread.defaultProps = {
  attributes: {},
  children: null,
  element: {
    type: elementTypes.TWEET_THREAD,
    children: [],
  },
};

export default memo(TweetThread);
