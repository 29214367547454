import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import memberTypes from 'graphql/memberTypes';
import capitilize from 'utils/capitalize';
import Dialog from 'components/dialog';
import DepartmentCard from './department-card';
import ContactCard from './contact-card';
import TeamCard from './team-card';

const useStyles = makeStyles(() => ({
  backdropRoot: {
    position: 'absolute',
  },
}));

const DetailsCardContainer = ({ item, containerRef }) => {
  const classes = useStyles();

  const [cardContent, setCardContent] = useState(<div />);
  const [displayCardContent, setDisplayCardContent] = useState(false);

  const handleClose = () => {
    setDisplayCardContent(false);
  };

  useEffect(() => {
    if (item) {
      const { type } = item;
      let popupCard;
      switch (type) {
        case memberTypes.DEPARTMENT:
          popupCard = (
            <DepartmentCard
              subtitle={capitilize(item.title)}
              onClose={handleClose}
              variant="Department"
              item={item}
            />
          );
          break;
        case memberTypes.USER:
        case memberTypes.CONTACT:
          popupCard = (
            <ContactCard
              subtitle={capitilize(item.title)}
              onClose={handleClose}
              variant="People"
              item={item}
            />
          );
          break;
        case memberTypes.TEAM:
          popupCard = (
            <TeamCard
              subtitle={capitilize(item.title)}
              onClose={handleClose}
              variant="Team"
              item={item}
            />
          );
          break;
        default:
          popupCard = <div />;
          break;
      }

      setCardContent(popupCard);
      setDisplayCardContent(true);
    }
  }, [item]);

  return (
    <Dialog
      open={displayCardContent}
      container={containerRef?.current}
      BackdropProps={{ classes: { root: classes.backdropRoot } }}
      onClose={handleClose}
    >
      {cardContent}
    </Dialog>
  );
};

export default DetailsCardContainer;
