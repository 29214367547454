import SelectionTypes from 'screens/main/components/rightArea/selectionTypes';
import { useRightHidden, useRightSelection } from 'store/sidebar';

const postMessage = (message: unknown, frameId: string) => {
  const ifrm = document.getElementById(frameId) as HTMLIFrameElement | null;
  if (ifrm) {
    ifrm.contentWindow?.postMessage(message, '*');
  }
};

const openMimirId = (id: string) => {
  const message = {
    action: 'open_item',
    payload: id,
    destination: 'MIMIR',
  };
  postMessage(message, 'mimir');
};

const useOpenMimirItem = () => {
  const [, setRightHidden] = useRightHidden();
  const [, setRightSelection] = useRightSelection();
  const openInMimir = (id: string) => {
    setRightHidden(false);
    setRightSelection(SelectionTypes.STORYBOX);
    openMimirId(id);
  };
  return openInMimir;
};

export default useOpenMimirItem;
