import React, { useState, useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';

import { getTime, getSeconds } from 'screens/rundown/components/editor/utils';
import useSettingsValue from 'hooks/useSettingsValue';
import { getWords } from 'components/editor/utils';
import { Button } from 'components/buttons';
import Dialog from 'components/dialog';
import readTime from 'utils/getReadTime';
import InfoSummary from 'components/instanceCard/components/footer/components/infoSummary';

import ScriptEditorView from './view';

import {
  ScriptWrapper,
  ScriptHeaderWrapper,
  StyledScriptTitle,
  ScriptFooter,
  SummaryWrapper,
  ButtonWrapper,
} from './styled';

const addTimes = (t0 = '00:00:00', t1 = '00:00:00') => getTime(getSeconds(t0) + getSeconds(t1));

const getWordCount = (nodes) => getWords(nodes, true).length;

const ScriptContainer = ({ isOpen, onClose, onSave, scriptBlock: { name, script } }) => {
  const [editorState, setEditorState] = useState(script);
  const [getSettingsValue] = useSettingsValue();

  const speakDuration = useMemo(() => {
    const defaultReadSpeed = getSettingsValue('rundown.defaultReadSpeed');
    const wordCount = getWordCount(editorState.document);
    return readTime(defaultReadSpeed, wordCount);
  }, [editorState.document, getSettingsValue]);

  const scriptDuration = useMemo(
    () =>
      editorState.document.reduce(
        (acc, doc) => (doc.type === 'script-info' ? addTimes(doc.data.duration, acc) : acc),
        '00:00:00',
      ),
    [editorState.document],
  );

  const totalDuration = useMemo(
    () => addTimes(speakDuration, scriptDuration),
    [scriptDuration, speakDuration],
  );

  const handleEditorUpdate = useCallback(async ({ type, payload }) => {
    setEditorState(payload);
  }, []);

  const handleSave = () => {
    onSave(editorState);
  };

  return (
    <Dialog open={isOpen} onClose={onClose} disableEscapeKeyDown>
      <ScriptWrapper>
        <ScriptHeaderWrapper>
          <StyledScriptTitle>Script for {name}</StyledScriptTitle>
        </ScriptHeaderWrapper>
        {script && (
          <ScriptEditorView handleEditorUpdate={handleEditorUpdate} editorValue={script} />
        )}
        <ScriptFooter>
          <SummaryWrapper>
            <InfoSummary disableEdit value={scriptDuration} title="Dur" />
            <InfoSummary disableEdit value={speakDuration} title="Script" />
            <InfoSummary disableEdit value={totalDuration} title="Total" />
          </SummaryWrapper>
          <ButtonWrapper>
            <Button autoWidth padding={40} variant="outlined" usage="outlined" onClick={onClose}>
              Cancel
            </Button>
            <Button
              autoWidth
              padding={40}
              variant="contained"
              usage="significant"
              onClick={handleSave}
            >
              Save & Close
            </Button>
          </ButtonWrapper>
        </ScriptFooter>
      </ScriptWrapper>
    </Dialog>
  );
};

ScriptContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  scriptBlock: PropTypes.oneOf([
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      script: PropTypes.any,
    }),
    null,
  ]),
};

ScriptContainer.defaultProps = {
  scriptBlock: null,
};

export default memo(ScriptContainer);
