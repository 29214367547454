import styled from '@emotion/styled';

// eslint-disable-next-line import/prefer-default-export
export const MessageBubbleWrapper = styled('div')`
  padding-left: ${({ isSelfMessage }) => (isSelfMessage ? '88px' : '16px')};
  padding-right: ${({ isSelfMessage }) => (isSelfMessage ? '24px' : '64px')};
  display: flex;
  flex-direction: column;
`;

export const MessageEditContainer = styled('div')`
  width: 100%;
  padding-left: 4px;
  border-radius: 8px;
  min-width: 28px;
  box-sizing: border-box;
`;
