import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import PlaceHolder from 'assets/images/Team_Placeholder.png';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/styles';
import InputField from 'components/inputField';

const useStyles = makeStyles((theme) => ({
  avatarDiv: {
    marginLeft: '6px',
    display: 'flex',
    position: 'relative',
  },
  iconButtonRoot: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  typographyDiv: {
    marginLeft: '6px',
  },
  teamName: {
    ...theme.typography.dina.teamInfoName,
    marginTop: '20px',
  },
  type: {
    ...theme.typography.dina.teamInfoType,
    marginTop: '3px',
  },
  memberCount: {
    ...theme.typography.dina.caption,
    marginTop: '6px',
  },
  avatarImage: {
    height: '94px',
    width: '94px',
    borderRadius: '25%',
  },
}));
const AvatarRow = ({
  type,
  noOfMembers,
  title: initialTitle,
  onAvatarClick,
  avatarUrl,
  teamId,
  updateTeamTitle,
}) => {
  const classes = useStyles();
  const [showInputField, setShowInputField] = useState(false);
  const [title, setTitle] = useState(initialTitle);

  const onTitleClick = () => {
    setShowInputField(true);
  };

  const onTitleChange = (value) => {
    setTitle(value);
  };

  const handleEnterKey = (e) => {
    if (e.keyCode === 13) {
      updateTeamTitle(teamId, title);
      setShowInputField(false);
    }
    if (e.keyCode === 27) {
      setTitle(initialTitle);
      setShowInputField(false);
    }
  };

  return (
    <div className={classes.avatarDiv}>
      <IconButton classes={{ root: classes.iconButtonRoot }} onClick={onAvatarClick}>
        <img alt="placeholder" src={avatarUrl || PlaceHolder} className={classes.avatarImage} />
      </IconButton>
      <div className={classes.typographyDiv}>
        {showInputField ? (
          <InputField value={title} autoFocus onChange={onTitleChange} onKeyDown={handleEnterKey} />
        ) : (
          <Typography classes={{ root: classes.teamName }} onClick={onTitleClick}>
            {title}
          </Typography>
        )}
        <Typography classes={{ root: classes.type }}>{type}</Typography>
        <Typography classes={{ root: classes.memberCount }}>{`${noOfMembers} ${
          type === 'Team' ? 'member' : 'teams'
        }`}</Typography>
      </div>
    </div>
  );
};

AvatarRow.defaultProps = {
  type: 'Team',
  noOfMembers: 0,
  name: 'Team',
};

export default AvatarRow;
