import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import difference from 'lodash/difference';

import memberTypes from 'graphql/memberTypes';
import UPDATE_CONTACT from 'graphql/mutations/updateContact';
import UPDATE_MEMBERS from 'graphql/mutations/updateMembers';
import GET_MEMBERS_OF from 'graphql/queries/getMembersOf';
import { getMembersOfQuery } from 'graphql/queryVariables';
import { useMembers } from 'store';
import { AssignedMember } from 'types';
import { uploadToS3 } from 'utils/s3Utils';
import useLogger from 'utils/useLogger';

import { ChangedProfilePictureProps, ChangedTeamsOrDeptsTypes } from '../atomsTs';

interface UpdateContactType {
  updateContact: AssignedMember;
}

const useUpdateProfile = () => {
  const logger = useLogger('update-profile-hook');
  const [members, setMembers] = useMembers();
  const [updateContent] = useMutation<UpdateContactType>(UPDATE_CONTACT);
  const [updateMembersMutation] = useMutation(UPDATE_MEMBERS);

  const updateProfileContent = useCallback(
    async (mId: string, content: Partial<AssignedMember>) => {
      await updateContent({
        variables: {
          input: {
            mId,
            ...content,
          },
        },
        update: (_proxy, updatedUser) => {
          const { updateContact } = updatedUser?.data ?? {};
          setMembers({
            ...members,
            [memberTypes.USER]: members[memberTypes.USER]?.map((member) => {
              if (member?.mId === updateContact?.mId) {
                return updateContact;
              }
              return member;
            }),
          });
        },
      });
    },
    [updateContent],
  );

  const updateProfilePicture = useCallback(
    async (changedPicture: ChangedProfilePictureProps) => {
      try {
        await uploadToS3(changedPicture.key, changedPicture.file);
      } catch (error) {
        logger.log(error);
      }
    },
    [logger],
  );

  const updateProfileTeamsOrDepts = useCallback(
    async (
      userId: string,
      resourceType: 'team' | 'department',
      changedMembers: ChangedTeamsOrDeptsTypes,
    ) => {
      const mType =
        resourceType === 'department' ? memberTypes.DEPARTMENT_USER : memberTypes.TEAM_USER;
      const existingIds = changedMembers.old?.map(({ mId }) => mId);
      const updatedIds = changedMembers.new.map(({ mId }) => mId);

      const addedIds = difference(updatedIds, existingIds);

      const newMembers = {
        members: addedIds.map((mId) => ({
          mId,
          mRefId: userId,
          mType,
        })),
      };

      const removedIds = difference(existingIds, updatedIds);
      const removedMembers = {
        members: removedIds.map((mId) => ({
          mId,
          mRefId: userId,
        })),
      };

      // Cache update for team and department
      try {
        await updateMembersMutation({
          variables: {
            newMembers,
            removedMembers,
          },
          update: (proxy) => {
            const newMembersWithType = changedMembers.new.map((member) => ({
              ...member,
              mType: resourceType,
            }));

            proxy.writeQuery({
              query: GET_MEMBERS_OF,
              variables: getMembersOfQuery(userId, mType),
              data: {
                getMembersOf: newMembersWithType,
              },
            });

            proxy.writeQuery({
              query: GET_MEMBERS_OF,
              variables: {
                input: {
                  membersOfType: mType,
                  mId: userId,
                },
              },
              data: {
                getMembersOf: newMembersWithType,
              },
            });
          },
        });
      } catch (error) {
        logger.log(error);
      }
    },
    [updateMembersMutation, logger],
  );

  return { updateProfileContent, updateProfilePicture, updateProfileTeamsOrDepts };
};

export default useUpdateProfile;
