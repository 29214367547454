import { useEffect, useRef, useState } from 'react';
import { Editor, Node, Range } from 'slate';
import { useSlate } from 'slate-react';

import { useGetAiPrompts } from 'api/config/useGetAiPrompts';
import { ReactComponent as AddIcon } from 'assets/icons/systemicons/add_small.svg';
import { useAddInstanceDialog } from 'components/addInstanceDialog/AddInstanceDialog';
import { StyledCreateButton } from 'components/createStoryItems/styled';
import Divider from 'components/divider';
import movePortalToViewPort from 'components/editor/utils/movePortalToViewPort';
import stopAllPropagation from 'components/editor/utils/stopAllPropagation';
import { CloseIcon } from 'components/orderFormDialog/styled';
import Portal from 'components/portal';
import Tooltip from 'components/tooltip';
import useCheckUserRight from 'hooks/useCheckUserRight';
import getTime from 'screens/rundown/components/editor/utils/getTime';

import getWords from '../../utils/getWords';

import AskAI from './AskAI';

import { AIButton, AIContainer, AIIcon, Container, More, MoreItem, TextButton } from './styled';

const getWordCount = (nodes: Node[]) => getWords(nodes, true).length;

function HoveringTooltip({
  hostReadSpeed,
  writeLock,
}: {
  hostReadSpeed: number;
  writeLock: boolean;
}) {
  const ref = useRef<HTMLDivElement | null>(null);
  const editor = useSlate();

  const [checkUserRight] = useCheckUserRight();
  const [, setAddInstance] = useAddInstanceDialog();
  const canUseAI = checkUserRight('feature', 'ChatGPT');
  const { prompts } = useGetAiPrompts();
  const showMore = prompts.length > 4;
  const selectedText = window?.getSelection()?.toString();

  const [showMoreOptions, setShowMoreOptions] = useState<boolean>(false);
  const [textReadSpeed, setTextReadSpeed] = useState('0');
  const [isAsking, setIsAsking] = useState(false);
  const [question, setQuestion] = useState('');

  const onDone = () => {
    setQuestion('');
    setShowMoreOptions(false);
    setIsAsking(false);
  };

  const { selection } = editor;

  useEffect(() => {
    if (selection === null) return;
    setQuestion('');
    setShowMoreOptions(false);
    setIsAsking(false);
  }, [selection]);

  const doDismiss = () => {
    const el = ref.current;
    if (el) el.removeAttribute('style');
  };

  const doCreate = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    stopAllPropagation(ev);
    setAddInstance({ text: selectedText ?? null });
  };

  useEffect(() => {
    const el = ref.current;

    if (!el) {
      return;
    }

    if (!selection || Range.isCollapsed(selection) || Editor.string(editor, selection) === '') {
      el.removeAttribute('style');
      return;
    }

    const nodes = Editor.fragment(editor, selection);

    const wordCount = getWordCount(nodes);
    const wordsPerSecond = hostReadSpeed / 60;
    const speakDuration = Math.ceil(wordCount / wordsPerSecond);
    setTextReadSpeed(getTime(speakDuration));
    movePortalToViewPort(el);
  });

  if (isAsking)
    return (
      <AskAI
        question={question}
        canReplace={writeLock}
        isAsking={isAsking}
        selection={selection}
        editor={editor}
        selectedText={selectedText || ''}
        onDone={onDone}
        setAddInstance={setAddInstance}
      />
    );

  return (
    <Portal>
      <Container
        ref={ref}
        onMouseDown={(e) => {
          // prevent toolbar from taking focus away from editor
          e.preventDefault();
        }}
      >
        <AIContainer>
          <Tooltip title="Create instance from text">
            <StyledCreateButton
              aria-haspopup="true"
              aria-owns="create-menu"
              onClick={doCreate}
              $notAllowed={false}
              $size={24}
              $margin="0px"
            >
              <AddIcon />
            </StyledCreateButton>
          </Tooltip>
          {canUseAI && (
            <>
              <AIButton role="presentation" onClick={() => setIsAsking(true)}>
                <AIIcon className="skipOverride" />
                Ask AI
              </AIButton>
              <Divider orientation="vertical" flexItem />
              {(showMore ? prompts.slice(0, 3) : prompts).map((prompt) => (
                <>
                  <TextButton
                    key={prompt.id}
                    onClick={() => {
                      setQuestion(prompt.value);
                      setIsAsking(true);
                    }}
                  >
                    {prompt.label}
                  </TextButton>
                  <Divider orientation="vertical" flexItem />
                </>
              ))}
              {showMore && (
                <>
                  <TextButton onClick={() => setShowMoreOptions(!showMoreOptions)}>
                    More...
                    <More showMoreOptions={showMoreOptions}>
                      {prompts.slice(4).map((prompt) => (
                        <MoreItem
                          key={prompt.id}
                          button={true}
                          onClick={() => {
                            setQuestion(prompt.value);
                            setIsAsking(true);
                          }}
                        >
                          {prompt.label}
                        </MoreItem>
                      ))}
                    </More>
                  </TextButton>
                  <Divider orientation="vertical" flexItem />
                </>
              )}
            </>
          )}
          {textReadSpeed}
          <Tooltip title="Dismiss">
            <CloseIcon onClick={doDismiss} />
          </Tooltip>
        </AIContainer>
      </Container>
    </Portal>
  );
}

export default HoveringTooltip;
