import styled from '@emotion/styled';
import { Tooltip } from '@material-ui/core';
import { keyBy } from 'lodash';

import { SupportedMetadataTypes } from 'api/mdf/useGetMdfs';
import { Block } from 'api/mdfBlocks/types';
import { ReactComponent as MetadataIcon } from 'assets/icons/search/metadata.svg';
import MemberLabel from 'components/addMember/MemberLabel';
import { DateLabel } from 'components/contextMenu/styled';
import { EditFieldValue } from 'components/mdfEditor/EditFieldValueDialog';
import { OpenDatePicker } from 'components/mdfEditor/fields/date/DatePicker';
import { Link } from 'components/mdfEditor/fields/link/LinkDefault';
import { Tag, TagText } from 'components/mdfEditor/fields/multiplechoice/styled';
import TreeChoiceDefault from 'components/mdfEditor/fields/treechoice/TreeChoiceDefault';
import { isSingleArray } from 'components/mdfEditor/fields/utils';
import { TreeChoice } from 'components/treeChoiceDialog/TreeChoiceDialog';
import { GridActionType } from 'features/gridDeck/GridActions';
// eslint-disable-next-line sort-imports
import { FieldMap, getFieldKey, getMaxWidth, Header } from 'features/gridDeck/utils';
import LWCheckbox from 'features/orderForm/components/LWCheckbox';
import LWSelect from 'features/orderForm/components/LWSelect';
import LWTextField from 'features/orderForm/components/LWTextField';
import OpenPreviewButton from 'features/orderForm/components/OpenPreviewButton';
import SelectAssignee from 'features/orderForm/components/SelectAssignee';
import { InlineTagWrapper } from 'features/orderForm/styled';
import { BlockColumn, BlockRow } from 'features/orderForm/types';
import { HStack } from 'layouts/box/Box';
import { AssignedMember } from 'types';
import { getDefaultValue } from 'types/forms/forms';
import { FieldTypeEnum, Mdf } from 'types/graphqlTypes';
import { EditorCommandConfigType } from 'types/memberTypes/editorCommands';
import { isoToLocaleShort } from 'utils/datetimeHelpers';

import BlockGridActions from './BlockGridActions';

const IconWrapper = styled('div')<{ $color?: string }>`
  width: 20px;
  height: 20px;
  background-color: ${({ theme, $color }) => $color ?? theme.palette.dina.borderHover};
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg path {
    fill-opacity: 1;
  }
`;

export const defaultFieldsTitleMap: Record<string, string> = {
  __status: 'Status',
  __assignee: 'Assignee',
  __createdAt: 'Created',
  __updatedAt: 'Updated',
  __ownerId: 'Task owner',
};

export const extractColumnsFromBlock = (
  blocks: Block[],
  fieldMap: FieldMap,
  mdfMap: Record<string, Mdf | undefined>,
  userMap: Record<string, AssignedMember>,
  showPicker: (state: OpenDatePicker) => void,
  showPreview: (id: string) => void,
  showEditFieldDialog: (state: EditFieldValue) => void,
  openTreeChoiceDialog: (state: TreeChoice) => void,
  removeFromGrid: (blockKeys: { mId: string; mRefId: string }) => void,
): BlockColumn[] => {
  const mdfs: Mdf[] = [];
  const uniqueKeys = new Set<string>();
  const cols: BlockColumn[] = [];

  for (const block of blocks) {
    if (mdfMap[block.mdfId]) {
      mdfs.push(mdfMap[block.mdfId]!);
    }
  }

  for (const mdf of mdfs) {
    for (const field of mdf.fields) {
      uniqueKeys.add(getFieldKey(field.fieldId, mdf.id));
    }
  }

  const it = uniqueKeys.entries();
  cols.push({
    key: '__type',
    name: 'Type',
    maxWidth: 180,
    resizable: true,
    renderHeaderCell(props) {
      return <Header {...props} />;
    },
    renderCell({ row }) {
      return (
        <OpenPreviewButton onPreviewClick={() => showPreview(row.id)}>
          <HStack justifyContent="start" gap="4px">
            <IconWrapper $color={row.__color}>
              <MetadataIcon className="skipOverride" style={{ width: '16px', height: '16px' }} />
            </IconWrapper>
            {row.__type}
          </HStack>
        </OpenPreviewButton>
      );
    },
  });

  cols.push({
    key: '__storyTitle',
    name: 'Title',
    resizable: true,
    renderHeaderCell(props) {
      return <Header {...props} />;
    },
    renderCell({ row }) {
      return <span>{row.__storyTitle}</span>;
    },
  });

  for (const entry of it) {
    const key = entry[0];
    const fieldModel = fieldMap[key];

    if (!fieldModel) continue;

    cols.push({
      key,
      name: fieldModel.settings.label ?? 'unknown',
      sortable: true,
      resizable: true,
      maxWidth: getMaxWidth(fieldModel),
      renderHeaderCell(p) {
        return <Header {...p} />;
      },
      cellClass(row) {
        if (row.__mdfId !== fieldModel.formId) {
          return 'disable';
        }
      },
      renderCell({ row, onRowChange }) {
        if (row.__mdfId !== fieldModel.formId) {
          return null;
        }

        const value = row[key] || fieldModel.defaultValue.value;
        const openTreeChoice = () => {
          openTreeChoiceDialog({
            open: true,
            fieldId: fieldModel.fieldId,
            selected: (value ?? []) as string[],
            setSelected: (v: string[] | []) => onRowChange({ ...row, [key]: v }),
            treeAlternatives: fieldModel.treeAlternatives ?? [],
          });
        };
        const showFieldEditor = () => {
          showEditFieldDialog({
            startValue: value,
            fieldId: fieldModel.fieldId,
            headerText: `Edit ${fieldModel.settings.label}`,
            viewType: 'order_grid',
            mdf: mdfMap[row.__type as SupportedMetadataTypes]!,
            onConfirm: (v) => onRowChange({ ...row, [key]: v }),
          });
        };

        if (fieldModel.type === FieldTypeEnum.checkbox) {
          const curValue = Boolean(row[key]);
          const setValue = (val: boolean) => {
            onRowChange({ ...row, [key]: val });
          };
          return <LWCheckbox selected={curValue} setValue={setValue} />;
        }
        if (fieldModel.type === FieldTypeEnum.choice) {
          const curValue = row[key] as string;
          const setValue = (val: string) => {
            onRowChange({ ...row, [key]: val });
          };
          return (
            <LWSelect
              value={curValue}
              setValue={setValue}
              options={fieldModel?.alternatives ?? []}
            />
          );
        }
        if (fieldModel.type === FieldTypeEnum.text || fieldModel.type === FieldTypeEnum.number) {
          return (
            <div
              title={`${row[key] as string | number}`}
              onDoubleClick={fieldModel.settings.multiline ? showFieldEditor : () => {}}
              style={{ whiteSpace: 'pre', width: '100%', height: '100%' }}
            >
              {row[key] as string | number}
            </div>
          );
        }
        if (fieldModel.type === FieldTypeEnum.date) {
          return (
            <DateLabel
              onClick={(ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                showPicker({
                  anchorEl: ev.currentTarget,
                  selectDate: (vals) => {
                    onRowChange({ ...row, [key]: vals.startDate });
                  },
                });
              }}
            >
              {row[key] ? isoToLocaleShort(row[key]) : ' '}
            </DateLabel>
          );
        }
        if (fieldModel.type === FieldTypeEnum.multiplechoice) {
          if (isSingleArray(value)) {
            const alternativesByValue = keyBy(fieldModel.alternatives ?? [], (a) => a.value);
            if (!Array.isArray(value) || !value.length)
              return (
                <div
                  onClick={() => showFieldEditor()}
                  onKeyDown={() => {}}
                  style={{ width: '100%', height: '100%' }}
                />
              );
            return (
              <Tooltip title="Edit value">
                <InlineTagWrapper onClick={() => showFieldEditor()}>
                  {value.map((t) => (
                    <Tag key={t} style={{ height: '21px', lineHeight: '21px', padding: '0 4px' }}>
                      <TagText>{alternativesByValue[t]?.label ?? t}</TagText>
                    </Tag>
                  ))}
                </InlineTagWrapper>
              </Tooltip>
            );
          }
        }
        if (fieldModel.type === FieldTypeEnum.link) {
          return (
            <Link
              url={value as string}
              hint={fieldModel.settings.hint}
              onEditClick={showFieldEditor}
            />
          );
        }
        if (fieldModel.type === FieldTypeEnum.user) {
          const user: AssignedMember | null = userMap[row[key] as string] ?? null;
          return <MemberLabel variant="grid" member={user} />;
        }
        if (fieldModel.type === FieldTypeEnum.treechoice) {
          const parsed = Array.isArray(row[key]) ? (row[key] as string[]) : [];
          return (
            <InlineTagWrapper onClick={openTreeChoice} onKeyDown={() => {}}>
              <TreeChoiceDefault onClick={() => {}} choice={parsed} />
            </InlineTagWrapper>
          );
        }
      },
      renderEditCell({ row, onRowChange }) {
        if (row.__mdfId !== fieldModel.formId) {
          return null;
        }
        if (
          (fieldModel.type === FieldTypeEnum.text || fieldModel.type === FieldTypeEnum.number) &&
          !fieldModel.settings.multiline
        ) {
          const curValue = row[key] as string;
          const setValue = (val: string) => {
            onRowChange({ ...row, [key]: val });
          };
          return <LWTextField value={curValue} setValue={setValue} type={fieldModel.type} />;
        }
        if (fieldModel.type === FieldTypeEnum.user) {
          const selectAssignee = (m: AssignedMember) => {
            if (!m) return;
            onRowChange({ ...row, [key]: m.mId }, true);
          };
          return <SelectAssignee selectCallback={selectAssignee} />;
        }
      },
    });
  }

  cols.push({
    key: '__createdAt',
    name: 'Created',
    resizable: true,
    minWidth: 120,
    width: 120,
    renderHeaderCell(p) {
      return <Header {...p} />;
    },
    cellClass(row) {
      if (row.__noMatchingForm) {
        return 'error';
      }
    },
    renderCell({ row }) {
      return <span>{isoToLocaleShort(row.__createdAt, true)}</span>;
    },
  });

  cols.push({
    key: '__updatedAt',
    name: 'Updated',
    resizable: true,
    minWidth: 120,
    width: 120,
    renderHeaderCell(p) {
      return <Header {...p} />;
    },
    cellClass(row) {
      if (row.__noMatchingForm) {
        return 'error';
      }
    },
    renderCell({ row }) {
      return <span>{isoToLocaleShort(row.__updatedAt, true)}</span>;
    },
  });

  cols.push({
    key: '__buttons',
    name: '',
    sortable: false,
    resizable: false,
    maxWidth: 56,
    renderHeaderCell(props) {
      return <div>{props.column.name}</div>;
    },
    cellClass(row) {
      if (row.__noMatchingForm) {
        return 'error';
      }
    },
    renderCell(props) {
      const { row } = props;
      const onClick = (type: GridActionType) => {
        if (type === 'remove') removeFromGrid({ mId: row.__resourceId, mRefId: row.id });
      };

      return <BlockGridActions onClick={onClick} />;
    },
  });
  return cols;
};

export const extractRowsFromBlock = (
  blocks: Block[],
  mdfMap: Record<string, Mdf | undefined>,
  commandsMap: Record<string, EditorCommandConfigType | undefined>,
): BlockRow[] => {
  const rows: BlockRow[] = [];
  const toString = (val: string | undefined, fallback = '') => val ?? fallback;

  for (const block of blocks) {
    const mdf = mdfMap[block.mdfId];
    const command = commandsMap[block.mSecId];
    const row: BlockRow = {
      id: toString(block.mRefId),
      __resourceId: toString(block.mId),
      __mdfId: toString(mdf?.id, block.mdfId),
      __storyTitle: toString(block.mTitle, 'Missing title'),
      __createdAt: toString(block.mCreatedAt),
      __updatedAt: toString(block.mUpdatedAt),
      __color: toString(command?.mColor),
      __type: toString(command?.mTitle),
      __buttons: 'val', // dummy value to make the column show up
    };

    const mdfFieldsForMType = mdf?.fields ?? [];
    for (const field of mdfFieldsForMType) {
      row[getFieldKey(field.fieldId, block.mdfId)] =
        block.metadata[field.fieldId] ?? getDefaultValue(field);
    }
    rows.push(row);
  }

  return rows;
};
