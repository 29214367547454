const SelectionType = {
  MOS: 'mos',
  GRAPHICSBOX: 'graphicsbox',
  EDITBOX: 'editbox',
  STORYBOX: 'storybox',
  CONTACTS: 'contacts',
  TEAMS: 'tea_usr',
  DEPARTMENT: 'dpr_usr',
  CHAT: 'chat',
  DAILYNOTE: 'dailyNote',
};

export default SelectionType;
