import inlineTypes from './inlineTypes';
import voidTypes from './voidTypes';

const elementTypes = {
  HEADING_FOUR: 'heading-four',
  HEADING_ONE: 'heading-one',
  HEADING_THREE: 'heading-three',
  HEADING_TWO: 'heading-two',
  LIST_ITEM: 'list-item',
  ORDERED_LIST: 'ordered-list',
  PARAGRAPH: 'paragraph',
  CONTENT: 'content',
  QUOTE: 'block-quote',
  UNORDERED_LIST: 'unordered-list',
  TWEET_THREAD: 'tweet-thread',
  SECTION_DIVIDER: 'section-divider',
  ...inlineTypes,
  ...voidTypes,
};

export default elementTypes;
