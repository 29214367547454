import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import Icon from 'components/icon';
import ICONS from 'assets/icons/icons';
import fallbackImage from 'assets/images/default/defaultCoverPhoto.png';
import MediaCard from 'components/mediaCard';

import MediaViewer from './mediaViewer';

const AssetWrapper = styled('div')`
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
  flex-wrap: wrap;
  & div:not(:first-of-type) {
    margin-top: 8px;
  }
`;

const Link = styled('a')`
  width: 100%;
`;

const FileThumbnail = styled('div')`
  width: 60px;
  height: 60px;
  margin-right: 8px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const FileDuration = styled('span')`
  ${({ theme }) => css`
    ${theme.typography.dina.caption};
    color: ${theme.palette.dina.blackMediumEmphasis};
  `}
`;

const Assets = ({ feedItem, accessToken }) => {
  const { assets, byline, ...rest } = feedItem;

  const [previewAsset, setPreviewAsset] = useState(null);

  const handleOpen = (asset) => {
    setPreviewAsset(asset);
  };

  const handleClose = () => {
    setPreviewAsset(null);
  };

  return (
    <AssetWrapper>
      <MediaViewer
        handleClose={() => handleClose()}
        open={previewAsset !== null}
        asset={previewAsset}
        accessToken={accessToken}
      />
      {assets.map((asset) => {
        const { type = '', renditions = [] } = asset;
        const assetType = type.toLowerCase();

        if (!renditions.length || !assetType) return null;

        if (assetType === 'application') {
          const rendition = renditions[renditions.length - 1];
          const { filename, href, mimetype, uri } = rendition;

          return (
            <Link href={href} key={href || uri} target="_blank" rel="noopener noreferrer">
              <MediaCard
                type={type}
                filename={filename || asset.headline || 'Document'}
                byline={mimetype}
                mimetype={mimetype}
                fullWidth
              />
            </Link>
          );
        }

        if (assetType === 'image') {
          const rendition = renditions[renditions.length - 1];
          const { href, thumbnailUri, filename, uri } = rendition;

          return (
            <MediaCard
              key={(href, uri)}
              onClick={() => handleOpen({ ...rest, ...rendition, type })}
              thumbnailSrc={`${thumbnailUri || href}${accessToken}`}
              type={type}
              filename={filename || 'image'}
              byline={byline}
              fullWidth
            />
          );
        }

        if (assetType === 'sound file' || assetType === 'audio') {
          return (
            <Link
              key={renditions[0].href}
              href={renditions[0].href}
              target="_blank"
              rel="noopener noreferrer"
              title={asset.headline || 'Audio file'}
            >
              <FileThumbnail src={renditions[0].href} alt={asset.title}>
                <Icon icon={ICONS.AUDIO} width={36} height={36} />
                <FileDuration>{asset.duration || null}</FileDuration>
              </FileThumbnail>
            </Link>
          );
        }

        if (assetType === 'video') {
          const videoRenditions = renditions.filter(
            ({ mimetype }) => mimetype && mimetype.includes('video'),
          );

          return videoRenditions.map((rendition) => {
            const { thumbnailUri } = rendition;
            const thumbImage = thumbnailUri ? `${thumbnailUri}${accessToken}` : fallbackImage;

            return (
              <MediaCard
                key={rendition.uri}
                onClick={() => handleOpen({ ...rest, ...rendition, type })}
                thumbnailSrc={thumbImage}
                type={type}
                filename={rendition.filename}
                duration={rendition.duration}
                byline={byline}
                fullWidth
              />
            );
          });
        }

        return null;
      })}
    </AssetWrapper>
  );
};

Assets.propTypes = {
  feedItem: PropTypes.object,
  accessToken: PropTypes.string,
};

Assets.defaultProps = {
  feedItem: null,
  accessToken: '',
};

export default Assets;
