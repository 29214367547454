import React, { memo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import InputField from 'components/inputField';
import useInputEvents from 'hooks/useInputEvents';

const Input = memo(InputField);

const InputComponent = ({ input, readOnly, ...rest }) => {
  const {
    value: inputValue,
    onChange,
    disableLabel,
    disabled,
    placeholder,
    label,
    direction,
    ...restOfInputProps
  } = input;

  const initialValue = inputValue;
  const [value, setValue] = useState(initialValue);

  const onUpdate = useCallback(
    (newValue) => {
      if (newValue === initialValue) setValue(initialValue);
      else onChange(newValue);
    },
    [initialValue, onChange],
  );

  const [inputRef, handleKeydown, handleBlur] = useInputEvents(onUpdate, value, initialValue);

  return (
    <Input
      disableLabel={disableLabel}
      onChange={setValue}
      value={value}
      usage="editor"
      disabled={readOnly || disabled}
      placeholder={placeholder}
      label={label}
      inputRef={inputRef}
      onKeyDown={handleKeydown}
      onBlur={handleBlur}
      direction={direction}
      {...rest}
      {...restOfInputProps}
    />
  );
};

const InputGroup = ({ inputProps, readOnly, ...rest }) => (
  <div>
    {inputProps.map((item, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <InputComponent key={index} input={item} {...{ readOnly, ...rest }} />
    ))}
  </div>
);

InputGroup.propTypes = {
  /** props of input */
  inputProps: PropTypes.arrayOf(
    PropTypes.shape({
      /** boolean to disable label */
      disableLabel: PropTypes.bool,
      /** callback for changing the input value  */
      onChange: PropTypes.func,
      /** value of input */
      value: PropTypes.string,
      /** placeholder of input */
      placeholder: PropTypes.string,
      /** label of input */
      label: PropTypes.string,
    }),
  ),
  /** Boolean to disable input */
  readOnly: PropTypes.bool,
};

InputGroup.defaultProps = {
  inputProps: [],
  readOnly: false,
};

export default memo(InputGroup);
