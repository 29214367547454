import { FetchPolicy, useApolloClient } from '@apollo/client';

import GET_RUNDOWN from 'graphql/queries/getRundown';
import { Rundown } from 'types/graphqlTypes';

const useGetRundown = (fetchPolicy?: FetchPolicy) => {
  const client = useApolloClient();

  const getRundown = (mId: string, mRefId: string) =>
    client
      .query({
        query: GET_RUNDOWN,
        variables: { input: { mId, mRefId } },
        fetchPolicy: fetchPolicy ?? 'network-only',
      })
      .then((r: { data: { getRundown: Rundown } }) => {
        const { data } = r;

        if (data && data.getRundown) {
          return data.getRundown;
        }
        return null;
      });

  return [getRundown] as const;
};

export default useGetRundown;
