import { useEffect } from 'react';
import PropTypes from 'prop-types';

import useInstance from 'hooks/useInstance';

import InstanceCard from 'components/instanceCard';
import syncSyncProviders from 'utils/syncSyncProviders';
import { useMimirProviderForInstance, useSetBackgroundSyncFolder } from 'store/assetProvider';

const InstancePreviewContainer = ({
  onOpenStory,
  isOpenStoryDisabled,
  isLinkingDisabled,
  onClose,
  instance,
  thumbnail,
}) => {
  const instanceCardProperties = useInstance(instance);

  const { story, thumbnailUrl } = instanceCardProperties;

  const setBackgroundSyncFolder = useSetBackgroundSyncFolder();
  const [syncProviders, setSyncProviders] = useMimirProviderForInstance();

  useEffect(() => {
    if (story) {
      const newSyncProviders = story.mSyncProviders;
      const mimirProvider = newSyncProviders?.find((sp) => sp.provider === 'Mimir');
      const newFolder = mimirProvider?.mMetaData?.find((mdata) => mdata.key === 'folder');
      if (!newFolder) return;
      const currentMimirProvider = syncProviders?.find((sp) => sp.provider === 'Mimir');
      const currentFolder = currentMimirProvider?.mMetaData?.find(
        (mdata) => mdata.key === 'folder',
      );
      if (currentFolder !== newFolder) {
        setSyncProviders(newSyncProviders);
        syncSyncProviders(newSyncProviders);
      }
    }

    return () => {
      setSyncProviders(null);
      setBackgroundSyncFolder();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [story?.mSyncProviders, setSyncProviders]);

  return (
    <InstanceCard
      isPreview
      backgroundImage={thumbnail ?? thumbnailUrl}
      initiallyCollapsed={false}
      onClose={onClose}
      onOpenStory={onOpenStory}
      disableOpenStory={isOpenStoryDisabled}
      isLinkingDisabled={isLinkingDisabled}
      showOpenStoryButton
      showCloseButton
      {...instanceCardProperties}
    />
  );
};

InstancePreviewContainer.propTypes = {
  onOpenStory: PropTypes.func,
  isOpenStoryDisabled: PropTypes.bool,
  isLinkingDisabled: PropTypes.bool,
  onClose: PropTypes.func,
  instance: PropTypes.shape(PropTypes.object),
  thumbnail: PropTypes.string,
};

export default InstancePreviewContainer;
