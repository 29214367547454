import variants from '../constants/types/editorVariants';

const { TWITTER, FACEBOOK, INSTAGRAM, LINKEDIN } = variants;

/** Allowed Platform variants which will have extra blocks if configured */
export const conditionalBlocksPlatformVariants = [TWITTER, FACEBOOK, INSTAGRAM, LINKEDIN];

/**
 * Find out unique extra blocks from Db platformStructure's blocks[]
 *
 * @param {String} variant
 * @param {Object} platformStructure
 * @returns
 */
export const getConditionalBlocks = (variant, platformStructure) => {
  if (!conditionalBlocksPlatformVariants.includes(variant) || !platformStructure) return;

  const { blocks } = platformStructure;
  if (!blocks || !Array.isArray(blocks)) return;

  return [...new Set(blocks)];
};
