import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Typography } from '@material-ui/core';
import transientOptions from 'theme/helpers';

export const Wrapper = styled('div')`
  flex: 1;
  height: 36px;
  display: flex;
  align-items: center;
  min-width: 0;
  cursor: pointer;
`;

export const Destination = styled(Typography, transientOptions)`
  ${({ theme }) => theme.typography.dina.captionRegular};
  margin-right: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  ${({ $canOpenRundown }) =>
    $canOpenRundown &&
    css`
      cursor: pointer;
      :hover {
        transform: scale(1.015);
        text-decoration: underline;
      }
    `}
`;
