import styled from '@emotion/styled';
import { css } from '@emotion/react';

const BoxWrapper = styled.div`
  border: ${({ isSelected, theme }) =>
    `1px solid ${
      isSelected ? theme.palette.dina.onFocus : theme.palette.dina.buttonBackgroundOutlined
    }`};
  position: relative;
  border-radius: 4px;
  width: 100%;
  height: 42px;
  display: flex;
  &:hover {
    background-color: ${({ theme }) => theme.palette.dina.whiteHoverOverlay};
    .primaryAutomationMenu {
      opacity: 1;
      width: 32px;
    }
  }
`;

const ContentWrapper = styled.div`
  padding-left: 42px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  .primaryAutomationMenu {
    width: 0px;
    opacity: 0;
    transition: all 0.3s ease;
    overflow: hidden;
    will-change: width, opacity;
  }
`;

const RootWrapper = styled.div`
  pointer-events: ${(props) => (props.readOnly ? 'none' : 'all')};
  padding: 8px;
  width: 100%;
`;

const TitleWrapper = styled.div`
  ${({ theme }) => css`
    ${theme.typography.dina.caption};
    color: ${theme.palette.dina.highEmphasis};
  `}
  width: calc(100% - 32px);
  margin-left: 12px;
  will-change: width;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
const Icon = styled.img`
  max-width: 100%;
  height: 40px;
  object-fit: cover;
  border-radius: 4px 0px 0px 4px;
`;

const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  position: absolute;
  user-select: none;
  border-radius: 4px 0px 0px 4px;
`;

export { BoxWrapper, ContentWrapper, RootWrapper, TitleWrapper, Icon, IconWrapper };
