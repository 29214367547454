import styled from '@emotion/styled';
import ListItem from '@material-ui/core/ListItem';

import Text from 'components/text/Text';

interface FocusDivProps {
  hovered?: boolean;
}

export const FocusDiv = styled('div')<FocusDivProps>`
  transform: translateZ(0);
  border: ${({ theme, hovered }) =>
    hovered ? `1px solid ${theme.palette.dina.statusWarning}` : null};
`;

export const AvatarWrapper = styled('div')`
  width: 40px;
  height: 40px;
`;

export const IconWrapper = styled('div')`
  color: ${({ theme }) => theme.palette.dina.iconInactive};
`;

export const ChatEmailIconWrapper = styled('div')`
  display: flex;
  flex-direction: row;
`;

export const NameText = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const TitleWrapper = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const ButtonListItem = styled(ListItem)`
  background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel2};
  max-height: 64px;
  display: flex;
  align-items: center;
  padding-block: 8px;
  padding-inline: 12px;
  gap: 8px;
  &:hover {
    background: ${({ theme }) => theme.palette.dina.storyCardHover};
  }
  &:focus {
    background-color: ${({ theme }) => theme.palette.dina.onFocus};
  }
`;
