import { useState } from 'react';

import { MProperties } from 'types/graphqlTypes';
import getInitials from 'utils/getInitials';

import DetailHeader from '../detailHeader';

import AvatarRow from './avatarRow';

import { AvatarBackground, HeaderWrapper } from './styled';

interface MenuItem {
  action: string;
  label: string;
  icon?: React.ReactNode;
}

interface AvatarHeaderProps {
  id: string;
  hideControls: boolean;
  backButtonLabel: string;
  onBackButtonClick: () => void;
  onMenuItemClick: () => void;
  menuItems: MenuItem[];
  variant: string;
  disableUpload: boolean;
  avatarUrl: string;
  title: string;
  subtitle: string;
  additionalInfo: string;
  updateAvatar: (file: File, url: string) => void;
  style: Record<string, string>;
  isSummary: boolean;
  onClose: () => void;
  hideMenu: boolean;
  updateDepartmentTitle: (id: string, title: string) => void;
  showMenu: boolean;
  type: string;
  metadata: string;
  properties: MProperties;
  onMenuButtonClick: () => void;
  onChatButtonClick: () => void;
  onEmailButtonClick: () => void;
}

function AvatarHeaderView({
  id,
  hideControls,
  backButtonLabel = 'Back',
  onBackButtonClick,
  onMenuItemClick,
  menuItems,
  variant,
  disableUpload = false,
  avatarUrl,
  title = '',
  subtitle = '',
  additionalInfo,
  updateAvatar,
  style,
  isSummary = false,
  onClose,
  hideMenu,
  updateDepartmentTitle,
  showMenu,
  type,
  metadata,
  properties,
  onMenuButtonClick,
  onChatButtonClick,
  onEmailButtonClick,
}: Readonly<AvatarHeaderProps>) {
  const [backgroundAvatar, setBackgroundAvatar] = useState(avatarUrl);
  return (
    <HeaderWrapper
      content="about"
      style={{
        backgroundImage: backgroundAvatar
          ? `url(${backgroundAvatar})`
          : `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='100%' width='100%'><text x='10' y='155' fill='lightcoral' font-size='200' font-weight='700'>${getInitials(
              title,
            )}</text></svg>"`,
        ...style,
      }}
    >
      <AvatarBackground />
      {!hideControls && (
        <DetailHeader
          title={title}
          id={id}
          type={type}
          onMenuItemClick={onMenuItemClick}
          backButtonLabel={backButtonLabel}
          onBackButtonClick={onBackButtonClick}
          menuItems={menuItems}
          isSummary={isSummary}
          onClose={onClose}
          hideMenu={hideMenu}
          showMenu={showMenu}
          metadata={metadata}
          properties={properties}
          onMenuButtonClick={onMenuButtonClick}
          onChatButtonClick={onChatButtonClick}
          onEmailButtonClick={onEmailButtonClick}
        />
      )}

      <AvatarRow
        id={id}
        variant={variant}
        disableUpload={disableUpload}
        avatarUrl={avatarUrl}
        title={title}
        subtitle={subtitle}
        additionalInfo={additionalInfo}
        updateDepartmentTitle={updateDepartmentTitle}
        onImageUpload={(imageFile: File, imageUrl: string) => {
          updateAvatar(imageFile, imageUrl);
          setBackgroundAvatar(imageUrl);
        }}
      />
    </HeaderWrapper>
  );
}

export default AvatarHeaderView;
