import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import useUpdateMetadata from 'api/useUpdateMetadata';
import Collapse from 'components/collapse';
import { MdfEditor } from 'components/mdfEditor/MdfEditor';
import ResizableBox from 'components/resizableBox';
import Scrollbar from 'components/scrollbar';
import { Story } from 'types';
import { Metadata, NewFieldValue } from 'types/forms/forms';
import { MemberTypeEnum } from 'types/graphqlTypes';
import { BOUNDS, ENABLE_RESIZING, INITIAL_SIZE, MIN_HEIGHT } from 'utils/constants/resizableBox';

const Wrapper = styled('div')`
  padding: 8px 20px 8px 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

interface Props {
  story: Story;
  useCollapse: boolean;
}

const getParsedMD = (val?: string | null) => {
  try {
    return JSON.parse(val ?? '{}') as Metadata | null | undefined;
  } catch (err) {
    return null;
  }
};

export const StoryMetadata: React.FC<Props> = ({ story, useCollapse }): JSX.Element => {
  const [open, setOpen] = useState(false);
  const updateMetadata = useUpdateMetadata();
  const [metadata, setMetadata] = useState<Metadata | null>(null);
  const { mdfsByMType } = useGetMdfs({ all: true });
  const parsedMetadata = useMemo(() => {
    return getParsedMD(story.metadata) ?? {};
  }, [story.metadata]);

  useEffect(() => {
    setMetadata(parsedMetadata);
  }, [parsedMetadata]);

  const updateFieldValue = useCallback(
    async (val: NewFieldValue) => {
      const newMetadata = {
        [val.fieldId]: val.value,
      };

      setMetadata((prev) => {
        return {
          ...prev,
          ...newMetadata,
        };
      });

      await updateMetadata(
        story.mId,
        story.mRefId,
        newMetadata,
        parsedMetadata,
        MemberTypeEnum.Story,
      );
    },
    [parsedMetadata, story.mId, setMetadata, updateMetadata],
  );

  return useCollapse ? (
    <Collapse title="Metadata" open={open} setOpen={() => setOpen(!open)}>
      <ResizableBox
        initialSize={INITIAL_SIZE}
        enableResizing={ENABLE_RESIZING}
        minHeight={MIN_HEIGHT}
        bounds={BOUNDS}
        onResize={() => {}}
      >
        <Scrollbar>
          <Wrapper>
            {mdfsByMType?.story && (
              <MdfEditor
                fields={mdfsByMType.story.fields}
                defaultLayoutSettings={mdfsByMType.story.views.default}
                layoutSettings={mdfsByMType.story.views.story_view}
                metadata={metadata ?? parsedMetadata}
                permissions={mdfsByMType.story.permissions}
                updateFieldValue={(fieldValue) => void updateFieldValue(fieldValue)}
              />
            )}
          </Wrapper>
        </Scrollbar>
      </ResizableBox>
    </Collapse>
  ) : (
    <Scrollbar style={{ maxWidth: '650px' }}>
      <Wrapper>
        {mdfsByMType?.story && (
          <MdfEditor
            fields={mdfsByMType.story.fields}
            defaultLayoutSettings={mdfsByMType.story.views.default}
            layoutSettings={mdfsByMType.story.views.story_view}
            metadata={metadata ?? parsedMetadata}
            permissions={mdfsByMType.story.permissions}
            updateFieldValue={(fieldValue) => void updateFieldValue(fieldValue)}
          />
        )}
      </Wrapper>
    </Scrollbar>
  );
};
