import React, { useState, memo, useCallback, useRef } from 'react';
import { ReactEditor, useSlate } from 'slate-react';
import { Transforms } from 'slate';
import PropTypes from 'prop-types';

import TextAreaComponent from 'components/textArea';

const TextArea = memo(TextAreaComponent);

const Description = ({
  readOnly = false,
  description: initialDescription,
  updateDescription,
  tabIndex,
}) => {
  const [description, setDescription] = useState(initialDescription);
  const editor = useSlate();
  const inputRef = useRef(null);

  const onUpdateDescription = useCallback(
    (newDescription) => updateDescription(newDescription),
    [updateDescription],
  );

  const handleDescriptionChange = useCallback((newDescription) => {
    setDescription(newDescription);
  }, []);

  const handleEnterPress = useCallback(
    (event) => {
      if ((event.key === 'Enter' || event.keyCode === 9) && !event.shiftKey) {
        event.preventDefault();
        const { selection } = editor;
        const updatedSelection = {
          ...selection,
          anchor: {
            offset: 0,
            path: [selection.anchor.path[0] + 1, selection.anchor.path[1]],
          },
          focus: {
            offset: 0,
            path: [selection.focus.path[0] + 1, selection.focus.path[1]],
          },
        };
        Transforms.move(editor);
        Transforms.select(editor, updatedSelection);
        ReactEditor.focus(editor);
      }
    },
    [editor],
  );

  const onKeyDown = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        onUpdateDescription(description);
        handleEnterPress(event);
        event.stopPropagation();
      }

      if (event.key === 'Escape') {
        inputRef.current.blur();
        onUpdateDescription(initialDescription);
      }
    },
    [description, handleEnterPress, initialDescription, onUpdateDescription],
  );

  const onBlur = useCallback(
    (event) => {
      event.preventDefault();
      if (event.relatedTarget) onUpdateDescription(description);
    },
    [description, onUpdateDescription],
  );

  return (
    <TextArea
      severity="regular"
      type="SoMe"
      disableLabel
      rows={1}
      value={description}
      onChange={handleDescriptionChange}
      placeholder="Description..."
      inputRef={inputRef}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      disabled={readOnly}
      multiline={false}
      inputProps={{ tabIndex }}
    />
  );
};

Description.propTypes = {
  readOnly: PropTypes.bool,
  description: PropTypes.string,
  updateDescription: PropTypes.func,
  tabIndex: PropTypes.oneOf([0, -1]),
};

Description.defaultProps = {
  readOnly: false,
  description: '',
  updateDescription: () => {},
  tabIndex: 0,
};

export default memo(Description);
