import { gql } from 'graphql-tag';

export default gql`
  mutation DeleteUser($input: DeleteUserInput) {
    deleteUser(input: $input) {
      mId
      mRefId
      mLastLogin
      mTitle
      mProperties {
        __typename
        ... on ContactType {
          dateOfBirth
          email
          firstName
          jobTitle
          phone
          surname
          username
          readSpeed
          notListed
        }
      }
      mMetaData {
        value
        key
      }
      user {
        email
        provider
        status
      }
      metadata
    }
  }
`;
