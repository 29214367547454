import React, { useState, useRef, useEffect } from 'react';
import Searchbar from 'components/searchbar';
import CheckBox from 'components/checkbox';
import Tooltip from 'components/tooltip';
import Scrollbar from 'screens/main/components/scrollbar';
import { Typography, Grid, Button } from '@material-ui/core';
import useGetPlatforms from 'hooks/useGetPlatforms';
import useSearchBar from 'hooks/useSearchBar';
import useSettingsValue from 'hooks/useSettingsValue';
import InstanceListContainer from 'screens/main/components/leftArea/sidebar/instances/list';
import { InstanceItemVariant } from 'screens/main/components/leftArea/sidebar/instances/list/listItem/listItem-view';
import useStyles from './instanceList-styles';

// eslint-disable-next-line max-len
const checkBoxTooltip = `When checked, the instance you are linking to will not link back to this instance.`;

const InstanceList = ({
  variant = InstanceItemVariant.CREATE_LINK_LIST_ITEM,
  onCancel,
  onCreate,
  loading,
  linkedInstances,
  parentInstance,
  isLinkingDisabled,
}) => {
  const classes = useStyles({ variant });
  const [filters] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [getSettingsValue] = useSettingsValue();
  const isDefaultOneWayLink = getSettingsValue('app.defaultOneWayInstanceLinks') === 'true';
  const [isUniDirectional, setIsUniDirectional] = useState(isDefaultOneWayLink);

  const scrollbarRef = useRef(null);

  const {
    filterModel,
    updateFilterModel,
    history,
    updateHistory,
    currentSearch,
    updateCurrentSearch,
    searchString,
    instanceAssignedMembers: assignedMembers,
    platforms,
  } = useSearchBar({ historyLimit: 5 });

  const [anchor, setAnchor] = useState(0);
  const [focus, setFocus] = useState(0);
  const [selection] = useState(new Set());

  const [platformsData, setPlatformsData] = useState();
  const [data, error, pLoading] = useGetPlatforms(new Date());

  useEffect(() => {
    if (!pLoading && !error) setPlatformsData(data);
  }, [data, pLoading, error]);

  const handleClick = (event, index, instanceId, instances) => {
    if (event.metaKey) {
      commandClick(index, instanceId);
    } else if (event.shiftKey) {
      shiftClick(index, instances);
    } else {
      click(index, instanceId);
    }
  };

  const click = (index, instanceId) => {
    // anchor and focus collapse to the element
    setAnchor(index);
    setFocus(index);

    // only the clicked element is selected
    selection.clear();
    selection.add(index);
    setSelectedIds([{ index, instanceId }]);
  };

  const commandClick = (index, instanceId) => {
    if (selection.has(index)) {
      let isFound;

      const values = Array.from(selection.values());
      const max = Math.max(...values);
      for (let i = index + 1; i < max; i += 1) {
        if (selection.has(i)) {
          setAnchor(i);
          setFocus(i);
          isFound = true;
          break;
        }
      }

      // search backwards
      if (!isFound) {
        for (let i = index - 1; i > -1; i -= 1) {
          if (selection.has(i)) {
            setAnchor(i);
            setFocus(i);
            isFound = true;
            break;
          }
        }
      }

      // nothing selected
      if (!isFound) {
        setAnchor(0);
        setFocus(0);
      }

      selection.delete(index);
      setSelectedIds(selectedIds.filter((item) => item.index !== index));
    } else {
      setAnchor(index);
      setFocus(index);
      selection.add(index);
      selectedIds.push({ index, instanceId });
    }
  };

  const shiftClick = (index, instances) => {
    let start = Math.min(anchor, focus);
    let end = Math.max(anchor, focus);
    let tempSelectedIds = [...selectedIds];

    // remove items between anchor and focus
    for (let i = start; i <= end; i += 1) {
      selection.delete(i);
      tempSelectedIds = tempSelectedIds.filter((item) => item.index !== i);
    }

    start = Math.min(anchor, index);
    end = Math.max(anchor, index);

    for (let i = start; i <= end; i += 1) {
      selection.add(i);
      tempSelectedIds.push({ index: i, instanceId: instances[i].mId });
    }

    setSelectedIds(tempSelectedIds);
    setFocus(index);
  };

  return (
    <div className={classes.container}>
      <Typography className={classes.sidebarHeaderText}>Link to another Instance</Typography>
      <Scrollbar ref={scrollbarRef}>
        <InstanceListContainer
          searchString={searchString}
          assignedMembers={assignedMembers}
          platforms={platforms}
          variant={variant}
          onSelectInstance={handleClick}
          parentInstance={parentInstance}
          linkedInstances={linkedInstances}
          selectedIndices={selection}
        />
      </Scrollbar>
      <Searchbar
        chipTypes={['user', 'platform']}
        savedFilters={filters}
        hideSavedFilters
        platforms={platformsData}
        {...{
          filterModel,
          updateFilterModel,
          history,
          updateHistory,
          currentSearch,
          updateCurrentSearch,
        }}
      />
      <div className={classes.checkBoxWrapper}>
        <CheckBox
          key="create link"
          size={24}
          disableRipple
          disableHoverEffect
          value="isUniDirectional"
          selected={!!isUniDirectional}
          onClick={() => setIsUniDirectional(!isUniDirectional)}
        />
        <Tooltip title={checkBoxTooltip} noArrow>
          <Typography className={classes.checkBoxLabel}>Create one-way link</Typography>
        </Tooltip>
      </div>
      <Grid
        container
        justifyContent="space-around"
        alignItems="center"
        className={classes.buttonContainer}
      >
        <Button
          disabled={loading}
          classes={{
            root: classes.cancelButton,
            label: classes.buttonLabel,
            disabled: classes.disabled,
          }}
          onClick={onCancel}
        >
          Cancel
        </Button>

        <Button
          disabled={isLinkingDisabled || loading || selectedIds.length === 0}
          onClick={() => onCreate(selectedIds, isUniDirectional)}
          classes={{
            root: classes.createButton,
            label: classes.buttonLabel,
            disabled: classes.disabled,
          }}
        >
          Create Link
        </Button>
      </Grid>
    </div>
  );
};

export default InstanceList;
