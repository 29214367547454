import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Drawer from '@material-ui/core/Drawer';

export const RightContainer = styled('div')`
  position: relative;
  width: 100%;

  & .MuiDrawer-root.MuiDrawer-docked {
    width: calc(100% - 48px);
  }
`;

export const StyledDrawer = styled(Drawer)`
  z-index: 50;
  position: relative;
  transition: all 200ms;
  height: 100%;
  width: 100%;
  overflow: hidden;

  & .MuiBackdrop-root {
    display: none;
  }

  & .MuiDrawer-paper {
    position: relative;
    width: 100%;
  }

  & .MuiDrawer-paperAnchorRight {
    ${({ theme }) => css`
      box-shadow: -8px 0px 8px ${theme.palette.dina.boxShadowDark},
        0px 0px 1px ${theme.palette.dina.boxShadowDark};
    `}
  }
`;
