import { useCallback, useEffect, useMemo, useState } from 'react';

import { useGetMdf } from 'api/mdf/useGetMdf';
import { useGetOrderForms } from 'api/order_forms/useGetOrderForms';
import AddMemberDropdown from 'components/addMember';
import MemberLabel from 'components/addMember/MemberLabel';
import Dialog from 'components/dialogs/DialogBuilder';
import DraggableDialog from 'components/dialogs/Draggable';
import { generateTooltipText, getDefaultPayload } from 'components/editMdfDialog/utils';
import LoadingIndicator from 'components/loadingIndicator';
import { MdfEditor } from 'components/mdfEditor/MdfEditor';
import { FieldHeader } from 'components/mdfEditor/styled';
import { useOrderFormDialog, useOrderFormOpenDialog } from 'store';
import { NewFieldValue, Payload } from 'types/forms/forms';
import { AssignedMember } from 'types/members';

import useCreateOrder from './api/useCreateOrder';

import { ContentWrapper, FormWrapper, SelectAssigneeWrapper } from './styled';

function OrderFormDialog() {
  const [dimension, setDimension] = useState({
    width: `${window.innerWidth < 300 ? window.innerWidth : '350'}`,
    height: `${window.innerHeight < 600 ? window.innerHeight : '600'}`,
  });

  const [open, setOpen] = useOrderFormOpenDialog();
  const [state] = useOrderFormDialog();
  const [payload, setPayload] = useState<Payload>({});
  const [assignee, setAssignee] = useState<AssignedMember | null>(null);
  const [isFindingAssignee, setIsFindingAssignee] = useState(false);
  const [errorMap, setErrorMap] = useState<Record<string, string | undefined>>({});
  const [isCreating, setIsCreating] = useState(false);
  const [createOrder] = useCreateOrder();
  const { orderForms, loading: orderFormsLoading } = useGetOrderForms();

  const { mdf, loading: mdfLoading } = useGetMdf(state.mdfId ?? undefined);

  const loading = orderFormsLoading || mdfLoading;

  const orderForm = useMemo(() => {
    return orderForms.find((o) => o.mRefId === state.mFormId);
  }, [orderForms, state.mFormId]);

  useEffect(() => {
    if (!open) {
      setErrorMap({});
    }
  }, [open]);

  useEffect(() => {
    if (mdf) {
      setPayload(getDefaultPayload(mdf));
    }
  }, [mdf]);

  const updatePayload = useCallback(
    (p: NewFieldValue) => {
      setPayload((prevState) => ({
        ...prevState,
        [p.fieldId]: p.value,
      }));
    },
    [setPayload],
  );

  const onCloseDialog = () => {
    setOpen(false);
    if (state.onCancel) state.onCancel();
  };

  const onConfirm = async () => {
    setIsCreating(true);
    if (!isCreating && mdf) {
      const result = await createOrder(state.resourceId, state.mFormId, payload, assignee?.mId);
      if (state.onConfirm && result) state.onConfirm(result);
    }
    if (mdf) setPayload(getDefaultPayload(mdf));
    setAssignee(null);
    setIsCreating(false);
    setOpen(false);
  };

  const onClose = () => {
    setIsFindingAssignee(false);
  };

  const onRemoveClick = (ev: React.MouseEvent<Element, MouseEvent>) => {
    ev.stopPropagation();
    setAssignee(null);
  };

  const onSelectAssignee = (newAssignee: AssignedMember[]) => {
    setAssignee(newAssignee[0]);
  };

  const onErrorUpdate = ({ fieldId, error }: { fieldId: string; error: string | undefined }) => {
    if (open) {
      setErrorMap((prevState) => ({
        ...prevState,
        [fieldId]: error,
      }));
    }
  };

  const errorTooltip = useMemo(() => {
    const labelMap: Record<string, string> = {};
    if (!mdf) return undefined;
    for (const field of mdf.fields) {
      const layoutSettings = mdf.views.order_form ?? mdf.views.default;
      labelMap[field.fieldId] =
        layoutSettings.find((s) => s.fieldId === field.fieldId)?.label ?? field.fieldId;
    }
    const errors = generateTooltipText(errorMap, labelMap);
    if (!errors.length) return undefined;
    return (
      <>
        <div>Please resolve errors:</div>
        <ul style={{ padding: '0 0 0 20px' }}>
          {errors.map((err) => (
            <li key={err}>{err}</li>
          ))}
        </ul>
      </>
    );
  }, [errorMap, mdf]);

  const hasResources = orderForm !== undefined && mdf !== undefined;

  return (
    <DraggableDialog
      open={open}
      onClose={onCloseDialog}
      initialPosition={{
        x: 400,
        y: 100,
      }}
      minWidth={300}
      minHeight={300}
      dimension={dimension}
      setDimension={setDimension}
    >
      <Dialog.Header className="dragHandler">
        {`${orderForm?.mTitle} > ${orderForm?.mDescription}`}
      </Dialog.Header>
      <Dialog.Body bodyHeight="calc(100% - 84px)" overflow="auto">
        <ContentWrapper>
          {loading ? (
            <div style={{ width: '350px', height: '30vh' }}>
              <LoadingIndicator />
            </div>
          ) : hasResources ? (
            <FormWrapper>
              <MdfEditor
                fields={mdf.fields}
                defaultLayoutSettings={mdf.views.default}
                layoutSettings={mdf.views.order_form}
                metadata={payload}
                permissions={mdf.permissions}
                updateFieldValue={updatePayload}
                errorMap={errorMap}
                updateErrorMap={onErrorUpdate}
              />
              <SelectAssigneeWrapper>
                <FieldHeader>Assignee</FieldHeader>
                {isFindingAssignee ? (
                  <AddMemberDropdown
                    variant="all"
                    singleChoice
                    autoFocus
                    disableClearable={true}
                    selectedMembers={assignee ? [assignee] : []}
                    setSelectedMembers={onSelectAssignee}
                    placeholderText="Set assignee"
                    noOptionsText="No matches found"
                    onClose={onClose}
                    injectedMembers={undefined}
                  />
                ) : (
                  <MemberLabel
                    variant="form"
                    member={assignee}
                    onClick={() => setIsFindingAssignee(true)}
                    onRemoveClick={onRemoveClick}
                  />
                )}
              </SelectAssigneeWrapper>
            </FormWrapper>
          ) : (
            <div>Error: Missing resources</div>
          )}
        </ContentWrapper>
      </Dialog.Body>

      <Dialog.Footer>
        <Dialog.CancelButton onCancel={onCloseDialog} />
        <Dialog.ConfirmButton
          title={errorTooltip ?? 'Submit'}
          label={'Submit'}
          onConfirm={onConfirm}
          disabled={!!errorTooltip || loading || isCreating || !orderForm}
        />
      </Dialog.Footer>
    </DraggableDialog>
  );
}

export default OrderFormDialog;
