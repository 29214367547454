import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useContextMenu } from 'react-contexify';
import { Skeleton } from '@material-ui/lab';
import { ReactEditor, RenderElementProps, useReadOnly, useSelected, useSlate } from 'slate-react';

import useDeleteBlock from 'api/mdfBlocks/useDeleteMdfBlock';
import { useGetBlock } from 'api/mdfBlocks/useGetMdfBlock';
import { ReactComponent as Metadata } from 'assets/icons/search/metadata.svg';
import Avatar from 'components/avatar/Avatar';
import { DeleteDialog } from 'components/dialogs/CommonDialogs';
import { elementTypes } from 'components/editor/constants';
import { useEditorMolecule } from 'components/editor/store';
import Text from 'components/text/Text';
import useToast from 'components/toast/useToast';
import Tooltip from 'components/tooltip';
import ContextMenu from 'features/contextMenu';
import useCheckUserRight from 'hooks/useCheckUserRight';
import { Box, VStack } from 'layouts/box/Box';
import { useAllMembersKeyed, useEditorCommandsKeyed } from 'store';
import accessibleOnClick from 'utils/accessibleOnClick';
import preventDefaultAndPropagation from 'utils/preventDefaultAndStopPropagation';

import DragAndDrop from '../dragAndDrop';

import {
  FocusWrapper,
  IconWrapper,
  MdfLabelWrapper,
  Options,
  SelectWrapper,
  TitleWrapper,
} from './styled';

type MenuClickType = {
  id: string;
};

type MenuItem = {
  id: string;
  label: string;
};

const menuItems: MenuItem[] = [
  {
    id: 'removeBlock',
    label: 'Delete block',
  },
];

function MdfBlock({
  attributes = { 'data-slate-node': 'element', ref: null },
  children = null,
  element = {
    type: elementTypes.PARAGRAPH,
    data: {},
    children: [],
  },
}: Readonly<RenderElementProps>) {
  const { errorToast } = useToast();
  const { deleteBlock } = useDeleteBlock();
  const { show } = useContextMenu({ id: 'mdfBlockMenu' });
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [editorCommands] = useEditorCommandsKeyed();
  const [checkUserRight] = useCheckUserRight();
  const [membersKeyed] = useAllMembersKeyed();
  const canShowMdfBlocks = checkUserRight('feature', 'mdfBlocks');
  const editor = useSlate();
  const isReadOnly = useReadOnly();
  const isSelected = useSelected();
  const selected = !isReadOnly && isSelected;
  const { useSelectedBlock, useShowSidePanel } = useEditorMolecule();

  const [selectedBlock, setSelectedBlock] = useSelectedBlock();

  const [, setShowSidePanel] = useShowSidePanel();

  useEffect(() => {
    if (isSelected) {
      setSelectedBlock(element);
      setShowSidePanel(true);
    }
  }, [isSelected, isReadOnly, setShowSidePanel]);

  const selectBlock = useCallback(
    (event: React.MouseEvent) => {
      if (isReadOnly) preventDefaultAndPropagation(event);
      ReactEditor.focus(editor);
      if (canShowMdfBlocks) setShowSidePanel(true);
      setSelectedBlock(element);
    },
    [isReadOnly],
  );

  const data = element.data as { mId: string; mResourceId: string; mFormId: string; mdfId: string };
  const { mId, mResourceId, mFormId } = data ?? {};
  const { block, refetch, loading } = useGetBlock(mResourceId, mId);
  const isSelectedBlock = selectedBlock?.data?.mId === mId;

  const command = editorCommands[mFormId];

  const updatedBy = useMemo(() => {
    if (block) {
      return membersKeyed[block.mUpdatedById];
    }
    return null;
  }, [block?.mUpdatedById, membersKeyed]);

  const onOptionsClick = (ev: React.MouseEvent<SVGSVGElement>) => {
    ev.stopPropagation();
    if (!isReadOnly) {
      show({
        event: ev,
        props: {
          block,
        },
      });
    }
  };

  const handleMenuItemClicked = useCallback(({ id }: MenuClickType) => {
    if (id === 'removeBlock') {
      setShowDeleteDialog(true);
    }
  }, []);

  const handleDeleteBlock = useCallback(() => {
    if (block) {
      deleteBlock({ mId: block.mId, mRefId: block.mRefId })
        .catch(errorToast)
        .finally(() => {
          refetch().catch(errorToast);
          setShowDeleteDialog(false);
        });
    }
  }, [block, deleteBlock, setShowDeleteDialog, refetch]);

  return (
    <div {...attributes}>
      <DragAndDrop element={element} enableReadOnly={false}>
        {children}
        <FocusWrapper contentEditable={false}>
          <SelectWrapper
            $selected={isReadOnly ? isSelectedBlock : selected}
            {...accessibleOnClick(selectBlock, 'presentation')}
          >
            {command ? (
              <>
                <IconWrapper $color={command.mColor}>
                  <Metadata className="skipOverride" />
                </IconWrapper>
                <VStack width="100%" height="100%" alignItems="start">
                  <MdfLabelWrapper>
                    <Text variant="overline">{command.mTitle}</Text>
                  </MdfLabelWrapper>
                  <TitleWrapper>
                    {loading && (
                      <>
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" width="80%" />
                        <Skeleton animation="wave" width="60%" />
                      </>
                    )}
                    {block ? (
                      <Text variant="listItemLabel">{block.mTitle ?? ''}</Text>
                    ) : (
                      <div>This block appears to be deleted.</div>
                    )}
                  </TitleWrapper>
                </VStack>
                {updatedBy && (
                  <Avatar
                    tooltipContent={`Last updated by ${updatedBy.mTitle}`}
                    className="avatar"
                    variant="user"
                    src={updatedBy.mAvatarUrl}
                    size={20}
                    title={updatedBy.mTitle}
                  />
                )}
              </>
            ) : (
              <Box padding="0 8px" width="100%">
                Command appears to be deleted.
              </Box>
            )}
            <Tooltip title={isReadOnly ? '' : 'Options'}>
              <Options onClick={onOptionsClick} $disabled={isReadOnly} className="skipOverride" />
            </Tooltip>
          </SelectWrapper>
        </FocusWrapper>
      </DragAndDrop>
      {/*  TODO - pull these up a level instead of having this logic for each block.  */}
      <ContextMenu id={'mdfBlockMenu'} menuItems={menuItems} onClick={handleMenuItemClicked} />
      <DeleteDialog
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        onClick={handleDeleteBlock}
        title="Delete block?"
        message="Are you sure you want to delete this block from the story? This cannot be undone!"
      />
    </div>
  );
}

export default memo(MdfBlock);
