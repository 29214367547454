import Tooltip from 'components/tooltip';

import { DisableWrapper, StyledButton } from './styled';

function TooltipWrapper({
  children,
  title,
  disabled,
}: {
  children: React.ReactElement;
  title?: string | React.ReactElement;
  disabled?: boolean;
}) {
  return title ? (
    <Tooltip title={title}>
      {disabled ? <DisableWrapper>{children}</DisableWrapper> : children}
    </Tooltip>
  ) : (
    children
  );
}

export interface ButtonProps {
  // Accessibility
  ariaLabel?: string;
  tabIndex?: number;

  // Functionality
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  type?: 'button' | 'submit' | 'reset';

  // Styling
  align?: 'left' | 'center' | 'right';
  autoWidth?: boolean;
  className?: string;
  dark?: boolean;
  padding?: number;
  required?: boolean;
  selected?: boolean;
  style?: React.CSSProperties;
  usage?: 'cta' | 'danger' | 'outlined' | 'pitch' | 'significant' | 'story' | 'text' | 'warning';
  variant?: 'contained' | 'discreet' | 'outlined' | 'dashed';
  width?: number | string;
  height?: number | string;

  // Content
  children: React.ReactNode;
  startIcon?: React.ReactElement;
  title?: string | React.ReactElement;
  autoFocus?: boolean;
}

function Button({
  align = 'center',
  ariaLabel = '',
  autoWidth = false,
  className = '',
  dark = false,
  disabled = false,
  tabIndex = 0,
  title = '',
  usage = 'cta',
  variant = 'contained',
  type = 'button',
  onClick = () => {},
  children,
  padding,
  required,
  selected,
  startIcon,
  width,
  height,
  style,
  autoFocus = false,
}: Readonly<ButtonProps>) {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!disabled) onClick?.(e);
  };

  return (
    <TooltipWrapper title={title} disabled={disabled}>
      <StyledButton
        // accessibility
        aria-label={ariaLabel}
        tabIndex={tabIndex}
        // functionality
        disabled={disabled}
        onClick={handleClick}
        type={type}
        // styling
        align={align}
        autoWidth={autoWidth}
        className={className}
        usage={usage}
        dark={dark}
        padding={padding}
        required={required}
        selected={selected}
        style={style}
        variant={variant}
        height={height}
        width={width}
        autoFocus={autoFocus}
      >
        {startIcon}
        {children}
      </StyledButton>
    </TooltipWrapper>
  );
}

export default Button;
