import React, { useState } from 'react';

import { useScratchpadData, useNewScratchpadItemCount } from 'store/scratchpad';
import { ReactComponent as ScratchPadOn } from 'assets/icons/systemicons/scratchpad_on.svg';
import { ReactComponent as ScratchPadOff } from 'assets/icons/systemicons/scratchpad_off.svg';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import Tooltip from 'components/tooltip';
import NotificationIndicator from 'components/statusIndicators/notification/Notification';
import ScratchPad from './scratchPad-view';

import {
  ScratchPadIconWrapper,
  NotificationIndicatorWrapper,
  ScratchpadWrapper,
} from './scratchpad-styled';

const ScratchPadContainer = () => {
  const [openScratchPad, setOpenScratchPad] = useState(false);
  const [scratchpadData, setScratchpadData] = useScratchpadData();
  const [newScratchpadItemCount, setNewScratchpadItemCount] = useNewScratchpadItemCount();

  const handleScratchPadClose = () => {
    setOpenScratchPad(false);
    setNewScratchpadItemCount(0);
    const newScratchpadData = scratchpadData.map((data) =>
      data.isNew ? { ...data, isNew: false } : data,
    );
    setScratchpadData(newScratchpadData);
  };

  const toggleScratchPadOpen = () => {
    setOpenScratchPad((prev) => !prev);
    setNewScratchpadItemCount(0);
  };
  return (
    <>
      <Tooltip title="ScratchPad" noArrow>
        <ScratchPadIconWrapper onClick={toggleScratchPadOpen}>
          {openScratchPad ? <ScratchPadOn /> : <ScratchPadOff />}
          <NotificationIndicatorWrapper>
            <NotificationIndicator notificationCount={newScratchpadItemCount} />
          </NotificationIndicatorWrapper>
        </ScratchPadIconWrapper>
      </Tooltip>
      {openScratchPad && (
        <ClickAwayListener onClickAway={handleScratchPadClose}>
          <ScratchpadWrapper>
            <ScratchPad data={scratchpadData} />
          </ScratchpadWrapper>
        </ClickAwayListener>
      )}
    </>
  );
};

export default ScratchPadContainer;
