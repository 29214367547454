import { forwardRef } from 'react';

import { ReactComponent as Down } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_down.svg';
import { ReactComponent as Right } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_right.svg';
import Text from 'components/text/Text';
import { KanbanLaneReadOnlyProps } from 'features/kanban/types/kanban';

import { Arrow, ColorButtonWrapper, Header, LeftSideItems, Wrapper } from './styled';

interface AriaProps extends KanbanLaneReadOnlyProps {
  'aria-controls'?: string;
  'aria-expanded'?: boolean;
  'data-state'?: string;
  disabled?: boolean;
  type?: 'button';
}

type Props = AriaProps;

type Ref = HTMLButtonElement;

const ReadOnlyLaneHeader = forwardRef<Ref, Props>(
  (
    { count = 0, label, mRefId, color = '#ffffff', dense = false, darker = false, ...rest },
    ref,
  ) => {
    return (
      <Wrapper key={mRefId}>
        <Header tabIndex={0} {...rest} ref={ref} $dense={dense} $darker={darker}>
          <LeftSideItems>
            <Arrow>{rest['aria-expanded'] ? <Down /> : <Right />}</Arrow>
            <ColorButtonWrapper color={color} />
            <Text variant="listItemLabel" color="highEmphasis">
              {label}
            </Text>
          </LeftSideItems>
          <Text variant="caption" color="mediumEmphasis">
            {count}
          </Text>
        </Header>
      </Wrapper>
    );
  },
);

ReadOnlyLaneHeader.displayName = 'ReadOnlyLaneHeader';

export default ReadOnlyLaneHeader;
