import React, { memo, useCallback, useMemo } from 'react';
import { useSlate, useSelected } from 'slate-react';
import PropTypes from 'prop-types';

import { updateBlock } from 'components/editor/utils';
import useEditorContext from 'components/editor/hooks/useEditorContext';

import Box from '../box';
import SelectedElement from '../selectedElement';
import Description from './components/description';
import DurationView from './components/duration';
import FileName from './components/fileName/view';

import {
  ScriptContent,
  ScriptTitle,
  ScriptDescriptionWrapper,
  ContentTop,
  TimeInputContainer,
  MediaInputContainer,
  CollapsedScriptContent,
  CollapsedContentTop,
  CollapsedScriptDescription,
  InfoIconComponent,
} from './styled';

const timeToSecs = (time) => {
  const b = time.split(':');
  return Number(b[0] * 60 * 60) + Number(b[1] * 60) + Number(b[2]);
};

const timeFromSecs = (secs) => {
  const format = (n) => (n < 10 ? '0' : '') + n;

  const h = Math.floor(secs / 3600);
  const m = Math.floor((secs % 3600) / 60);
  const s = Math.floor((secs % 3600) % 60);

  return `${format(h)}:${format(m)}:${format(s)}`;
};

const normalizeTimingFields = (time) => {
  if (time === null || time === 0 || time === undefined) return '00:00:00';
  return time;
};

// Add two times in hh:mm:ss format
const addTimes = (t0 = '00:00:00', t1 = '00:00:00') =>
  timeFromSecs(timeToSecs(normalizeTimingFields(t0)) + timeToSecs(normalizeTimingFields(t1)));

const ScriptInfoView = ({ attributes, children, element }) => {
  const editor = useSlate();
  const { update } = useEditorContext();
  const isSelected = useSelected();

  const { data } = element;
  const { name, inTime, duration, description, mediaUrl, collapsed } = data;

  const outTime = useMemo(() => {
    if (inTime && duration) return addTimes(inTime, duration);
    return null;
  }, [duration, inTime]);

  const toggleCollapse = useCallback(() => {
    const updatedData = {
      ...data,
      collapsed: !collapsed,
    };
    updateBlock(editor, element, updatedData, update, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element]);

  const updateFileName = useCallback(
    (newFileName) => {
      const updatedData = {
        ...data,
        mediaUrl: newFileName,
      };
      updateBlock(editor, element, updatedData, update, false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  const updateDuration = useCallback(
    (newDuration) => {
      const updatedData = { ...data, duration: newDuration };
      updateBlock(editor, element, updatedData, update, false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  const updateInTime = useCallback(
    (newInTime) => {
      const updatedData = { ...data, inTime: newInTime };
      updateBlock(editor, element, updatedData, update, false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  const updateDescription = useCallback(
    (newDescription) => {
      const updatedData = {
        ...data,
        description: newDescription,
      };

      updateBlock(editor, element, updatedData, update, false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  const collapsedContent = useMemo(
    () => (
      <CollapsedScriptContent>
        <CollapsedContentTop>
          {`[${name}] | ${mediaUrl.length ? mediaUrl : '--'} | In: ${inTime ?? '--'} | Dur: ${
            duration ?? '--'
          } | Out: ${outTime ?? '--'}`}
        </CollapsedContentTop>
        <CollapsedScriptDescription>{description}</CollapsedScriptDescription>
      </CollapsedScriptContent>
    ),
    [description, duration, inTime, mediaUrl, name, outTime],
  );

  const mainContent = useMemo(
    () => (
      <ScriptContent>
        <ContentTop>
          <ScriptTitle>{`[${name}]`}</ScriptTitle>
          <MediaInputContainer>
            <FileName
              readOnly={false}
              fileName={mediaUrl}
              updateFileName={updateFileName}
              tabIndex={isSelected ? 0 : -1}
            />
          </MediaInputContainer>
          <TimeInputContainer>
            <DurationView
              duration={inTime}
              onUpdate={updateInTime}
              placeholder="In time..."
              tabIndex={isSelected ? 0 : -1}
            />
          </TimeInputContainer>
          +
          <TimeInputContainer>
            <DurationView
              duration={duration}
              onUpdate={updateDuration}
              placeholder="Duration..."
              tabIndex={isSelected ? 0 : -1}
            />
          </TimeInputContainer>
          =
          <TimeInputContainer>
            <DurationView
              duration={outTime}
              onUpdate={() => {}}
              placeholder="Out time..."
              disableEdit
            />
          </TimeInputContainer>
        </ContentTop>
        <ScriptDescriptionWrapper>
          <Description
            readOnly={false}
            description={description}
            updateDescription={updateDescription}
            tabIndex={isSelected ? 0 : -1}
          />
        </ScriptDescriptionWrapper>
      </ScriptContent>
    ),
    [
      description,
      duration,
      inTime,
      isSelected,
      mediaUrl,
      name,
      outTime,
      updateDescription,
      updateDuration,
      updateFileName,
      updateInTime,
    ],
  );

  return (
    <div {...attributes}>
      <SelectedElement element={element} contentEditable={false}>
        <Box
          iconComponent={<InfoIconComponent className="skipOverride" />}
          title="Script Info"
          updateCollapsed={toggleCollapse}
          collapsed={collapsed}
          collapsedContent={collapsedContent || ''}
          hideEllipsisButton
        >
          {mainContent}
        </Box>
        {children}
      </SelectedElement>
    </div>
  );
};

ScriptInfoView.propTypes = {
  attributes: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  element: PropTypes.object.isRequired,
};

export default memo(ScriptInfoView);
