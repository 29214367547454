import React, { memo, useCallback, useMemo, useState } from 'react';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import { useReadOnly, useSlate } from 'slate-react';

import AutoComplete from 'components/autoCompleteBase/view';
import ChipComponent from 'components/chip';
import { elementTypes } from 'components/editor/constants';
import useChangeCollapse from 'components/editor/hooks/useChangeCollapse';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import { updateBlock } from 'components/editor/utils';
import useGetFieldsForBlock from 'hooks/useGetFieldsForBlock';
import { CustomData, EditorElementProps, SelectBlockOption } from 'types/editor';

import Box from '../box/view';
import DragAndDrop from '../dragAndDrop';

import { AutoCompleteWrapper, ChipListWrapper, IconComponent } from './styled';

const Chip = memo(ChipComponent);
const AutoCompleteBase = memo(AutoComplete);

/**
 * Converts an array of SelectBlockOption objects into a comma-separated string of their 'value'
 * properties.
 *
 * @param {SelectBlockOption[]} list - The array of SelectBlockOption objects to be converted.
 * @returns {string} The comma-separated string of 'value' properties from the provided list.
 */
const stringifyList = (list: SelectBlockOption[] = []): string =>
  list.map((item) => item.value).join(', ');

type AutocompleteValue = { id: string; value: string } | null;

function Select({
  attributes = { 'data-slate-node': 'element', ref: null },
  children = null,
  element = {
    type: elementTypes.PARAGRAPH,
    data: {},
    children: [],
  },
  direction,
}: EditorElementProps) {
  const editor = useSlate();
  const readOnly = useReadOnly();
  const { update } = useEditorContext();
  const [getFieldsForBlock] = useGetFieldsForBlock();
  const [onChangeCollapse] = useChangeCollapse(element);

  const [value, setValue] = useState<AutocompleteValue>(null);
  const [inputValue, setInputValue] = useState<string>('');

  const { data = {}, type = 'default' } = element;
  const { content = [], collapsed = false, mTitle = '', fieldId = 'default' } = data;

  const Icon = useMemo(() => IconComponent(type), [type]);

  const field = getFieldsForBlock(fieldId, { options: [] }) as {
    options: SelectBlockOption[];
  };
  const options = useMemo(
    () => (field?.options || []).map((option) => ({ id: option.id, value: option.value })),

    [],
  );

  const updateSelect = useCallback(
    (updatedData: CustomData) =>
      updateBlock(editor, element, updatedData, update, false, undefined),
    [element, update],
  );

  const onChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: SelectBlockOption | null) => {
      event.preventDefault();
      if (newValue) {
        const alreadyExist = content.find((opt) => opt.value === newValue.value);
        if (!alreadyExist) {
          updateSelect({
            ...data,
            content: [...content, newValue],
          });
          setInputValue('');
          setValue(null);
        }
      }
    },
    [data, content, updateSelect],
  );

  const onInputChange = useCallback(
    (_: React.ChangeEvent<{}>, val: string) => setInputValue(val),
    [],
  );

  const removeSelect = useCallback(
    (index: number) =>
      updateSelect({
        ...data,
        content: content.filter((_: SelectBlockOption, pos: number) => pos !== index),
      }),
    [content, data, updateSelect],
  );

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    getClearProps,
    groupedOptions,
    getPopupIndicatorProps,
    popupOpen,
  } = useAutocomplete({
    id: 'select-box',
    options,
    autoHighlight: true,
    getOptionLabel: (option: SelectBlockOption) => option.value,
    value,
    onChange,
    inputValue,
    onInputChange,
  });

  const renderContent = useMemo(
    () => {
      const collapsedContent = stringifyList(content);

      return (
        <Box
          iconComponent={<Icon className="skipOverride" />}
          title={mTitle}
          hideEllipsisButton
          updateCollapsed={onChangeCollapse}
          collapsed={collapsed || false}
          collapsedContent={collapsedContent}
          direction={direction}
          element={element}
        >
          <AutoCompleteWrapper>
            <AutoCompleteBase
              content={content}
              readOnly={readOnly}
              value={inputValue}
              placeholder={`Start typing to find ${mTitle}`}
              showAvatarOnList
              {...{
                getRootProps,
                getInputProps,
                getListboxProps,
                getOptionProps,
                getClearProps,
                groupedOptions,
                getPopupIndicatorProps,
                popupOpen,
              }}
            />
            <ChipListWrapper>
              {content.map((option, idx) => (
                <li key={`${option.id}-${idx}`}>
                  <Chip
                    showAvatar={false}
                    label={option.value}
                    width="fit-content"
                    height={32}
                    onDelete={() => removeSelect(idx)}
                  />
                </li>
              ))}
            </ChipListWrapper>
          </AutoCompleteWrapper>
        </Box>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [collapsed, content, inputValue, popupOpen, readOnly, removeSelect, onChangeCollapse],
  );

  return (
    <div {...attributes}>
      <DragAndDrop element={element}>
        {children}
        {renderContent}
      </DragAndDrop>
    </div>
  );
}

export default memo(Select);
