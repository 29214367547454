import React from 'react';
import PropTypes from 'prop-types';

import useHover from 'hooks/useHover';

import { ReactComponent as Edit } from 'assets/icons/systemicons/edit_on.svg';
import { ReactComponent as EditingLocked } from 'assets/icons/systemicons/editingLocked.svg';

import {
  Container,
  LockedWrapper,
  LockedIcon,
  LockedText,
  ActionButtonsWrapper,
  CancelButton,
  SaveButton,
  MessageContainer,
  Progress,
  ProgressText,
} from './lockedIndicator-styled';

const LockedIndicator = ({
  readLock,
  writeLock,
  hasChanges,
  onBackgroundClick,
  onDone,
  lockedByUser,
  isSavingContent,
  collapsed,
  onCancel,
  isCancelled,
}) => {
  const [hoverRef, isHovered] = useHover();
  const onDoneClick = (event) => {
    event.stopPropagation();
    onDone();
  };

  const iconComponent = () => {
    if (readLock) return <EditingLocked />;
    if (writeLock) return <Edit />;
    return null;
  };

  const lockedText = () => {
    if (readLock) return `${lockedByUser} is editing`;
    if (hasChanges) return 'Not saved';
    if (writeLock) return 'You are editing';
    return null;
  };

  return (
    (readLock || writeLock) && (
      <Container
        collapsed={collapsed}
        readLock={readLock}
        hasChanges={hasChanges}
        writeLock={writeLock}
      >
        <LockedWrapper>
          <LockedIcon>{iconComponent()}</LockedIcon>
          <LockedText>{lockedText()}</LockedText>
        </LockedWrapper>
        {writeLock && (
          <ActionButtonsWrapper>
            {onCancel && (
              <div ref={hoverRef}>
                <CancelButton
                  variant="outlined"
                  usage={isHovered ? 'danger' : 'significant'}
                  onClick={onCancel}
                >
                  Cancel
                </CancelButton>
              </div>
            )}
            <SaveButton variant="contained" onClick={onDoneClick}>
              Save
            </SaveButton>
          </ActionButtonsWrapper>
        )}
        {(isSavingContent || isCancelled) && (
          <MessageContainer>
            <Progress variant="indeterminate" disableShrink size={24} thickness={1} />
            <ProgressText>{isCancelled ? `Cancelling` : `Saving`}</ProgressText>
          </MessageContainer>
        )}
      </Container>
    )
  );
};

LockedIndicator.propTypes = {
  /** Name of the user who is currently editing this instance */
  lockedByUser: PropTypes.string,
  /** Boolean that indicates that the instance is locked by some other user */
  readLock: PropTypes.bool,
  /** Boolean that indicates that the user has a writeLock for this instance */
  writeLock: PropTypes.bool,
  /** Callback to be invoked when the footer is clicked */
  onBackgroundClick: PropTypes.func,
  /** Callback to be invoked when the done button is clicked */
  onDone: PropTypes.func,
  /** Boolean that indicates that the content is being saved */
  isSavingContent: PropTypes.bool,
  /** Boolean that indicates that the content is being cancelled */
  isCancelled: PropTypes.bool,
};

LockedIndicator.defaultProps = {
  lockedByUser: 'Someone',
  readLock: false,
  writeLock: false,
  isSavingContent: false,
  onBackgroundClick: () => {},
  onDone: () => {},
  isCancelled: false,
};

export default LockedIndicator;
