import { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import { ReactEditor } from 'slate-react';
import PropTypes from 'prop-types';
import { Paper, Collapse, Input } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import clickIfNothingSelected from 'utils/clickIfNothingSelected';
import useStoryPanes from 'screens/storyV2/useStoryPanes';

import Metadata from 'components/metadata';
import ResizableDialog from 'components/resizableDialog';
import getFormBySpec from 'utils/rundown/getFormBySpec';
import variants from 'utils/instance/variants';
import { entityTypes, specTypes } from 'utils/metadata/defaultViewTypes';
import returnFieldPairs from 'utils/rundown/returnFieldPairs';
import Editor from 'components/editor';
import SplitBar from 'components/split';
import { publishingPoints } from 'assets/icons/publishingPoints';
import DebouncedLoadingIndicator from 'components/debouncedLoadingIndicator/DebouncedLoadingIndicator';
import Download from 'components/download/download';
import useSettingsValue from 'hooks/useSettingsValue';
import { useFeedTickerVisible } from 'store';
import { DEFAULT_EDITOR_PLACEHOLDER } from 'components/editor/utils/constants';
import Header from './components/header';
import Footer from './components/footer';
import CmsEditing from './components/cmsEditing';
import scrollAfterTimeout from './utils/scrollAfterTimeout';
import getDefaultTemplateKey from 'utils/instance/templates/getDefaultTemplateKey';
import usePublishMetadata from './hooks/usePublishMetadata';
import toggleViews from './utils/toggleViews';
import useStyles from './instance-card-styles';
import EmbedView from './components/embedView';
import InstancePrint from 'features/print/InstancePrint';

const toggleViewConstraint = [
  variants.FACEBOOK,
  variants.YOUTUBE,
  variants.TWITTER,
  variants.INSTAGRAM,
];

const InstanceCardView = (props) => {
  const {
    assignees,
    backgroundImage,
    canShowNewDesign,
    canCreateNewTemplate,
    canDeleteTemplate,
    canDeleteTemplateFolder,
    canPinTemplateFolder,
    canReorderTemplates,
    canSetDefaultTemplate,
    clipDuration,
    currentDestination,
    placeholderConfigs,
    description,
    disableEdit,
    editorValue,
    folders,
    form,
    handleUpdateMetadata,
    hostReadSpeed,
    hasChanges,
    initiallyCollapsed,
    instance,
    isSavingContent,
    isTimelineShown,
    loading,
    lockedByUser,
    mId,
    mMetaData,
    publishingDate,
    onAssigneeUpdate,
    onClipDurationChange,
    onScriptDurationChange,
    onCreateDuplicate,
    onCreateFolder,
    onDeleteFolder,
    onDeleteInstance,
    onDeleteTemplate,
    onDescriptionChange,
    onDone,
    onEditorUpdate,
    onForceUnlock,
    onLockInstance,
    onOpenStory,
    onOpenRundown,
    onPublishSettingsChange,
    onSaveTemplate,
    onSelectTemplate,
    onStatusChange,
    onTitleUpdate,
    onUnlockInstance,
    publishingPoint,
    publishingPointIcon,
    readLock,
    schedule,
    expiryTime,
    scriptDuration,
    setSkipDownload,
    shouldResetEditorSelection,
    statusId,
    storyId,
    summarize,
    title,
    totalDuration,
    users,
    variant,
    writeLock,
    hideToolbar,
    disableCollapse,
    twitterThreadCount,
    provider,
    onRestoreVersion,
    checkVersionRestorability,
    platform,
    canShowCmsIframe,
    disableOpenStory,
    editorFontSize,
    showMasterEditView,
    canUpdateInstance,
    updatingRelatedMembers,
    canUploadMediaBySignedURL,
    relatedMembers,
    showCloseButton,
    onClose,
    showOpenStoryButton,
    hideTemplateOptions,
    autoClipDurationSettingsValue,
    canUpdateScriptDurationSettingsValue,
    openInstancesUpdate,
    focusedInstanceId,
    isPreview,
    accountIdentifier,
    onCancel,
    isCancelled,
    onSetDefaultTemplate: updateDefaultTemplate,
    isContentLoaded,
    isFirstInstance,
  } = props;
  const { storyPanes, updateStoryPane } = useStoryPanes();
  const containerRef = useRef();
  const [collapsed, setCollapsed] = useState(initiallyCollapsed);
  const [openCmsEditDialog, setOpenCmsEditDialog] = useState(false);
  const [toggleView, setToggleView] = useState(toggleViews.EDIT);
  const [isShowingDownload, setIsShowingDownload] = useState(false);
  const [download, setDownload] = useState({});
  const [descriptionValue, setDescriptionValue] = useState(description);
  const [editor, setEditor] = useState();
  const [readOnly, setReadOnly] = useState(!writeLock);
  const [showPrintDialog, setShowPrintDialog] = useState(false);
  const [showMetadata, setShowMetadata] = useState(false);
  const paramsId = useParams().instanceId;
  const currentClicked = storyPanes.length
    ? storyPanes[0].tab === 'instances'
      ? storyPanes[0].tId
      : 'content'
    : paramsId;

  const classes = useStyles({ writeLock, readLock });
  const isCMSInstance = useMemo(() => publishingPoint === variants.CMS, [publishingPoint]);
  const isYoutubeInstance = publishingPoint === variants.YOUTUBE;
  const publishMetadata = usePublishMetadata(mMetaData);
  const isTwitterInstance = publishingPoint === variants.TWITTER;
  const isFacebookInstance = publishingPoint === variants.FACEBOOK;
  const isInstagramInstance = publishingPoint === variants.INSTAGRAM;
  const [getSettingsValue] = useSettingsValue();
  const applicationTitleFormat = getSettingsValue('app.title');
  const textDirection = getSettingsValue('app.textDirection') || 'auto';
  const placeholderConfigsRef = useRef(placeholderConfigs);
  const [feedTickerVisible] = useFeedTickerVisible();
  const platformKind = instance?.mProperties?.platformKind;

  const defaultTemplateKey = getDefaultTemplateKey(instance?.mProperties?.platform, platformKind);

  const defaultTemplateRefId = defaultTemplateKey
    ? getSettingsValue(defaultTemplateKey)
    : undefined;

  const onSetDefaultTemplate = useCallback(
    (newDefaultTemplateRefId) => {
      updateDefaultTemplate([{ key: defaultTemplateKey, value: newDefaultTemplateRefId }]);
    },
    [updateDefaultTemplate, defaultTemplateKey],
  );

  const hideAutomationTemplates = useMemo(
    () => variant !== variants.LINEAR || Boolean(platformKind),
    [platformKind, variant],
  );

  const [fields, parameterFields] = useMemo(
    () =>
      getFormBySpec(form, {
        target: publishingPoint,
        entity: entityTypes.INSTANCE,
        type: specTypes.METADATA,
      }),
    [form, publishingPoint],
  );

  const metadata = useMemo(() => returnFieldPairs(mMetaData, fields), [fields, mMetaData]);
  const clipAutoDurationField = useMemo(
    () => mMetaData?.find(({ key }) => key === '05-clip-duration'),
    [mMetaData],
  );
  const scriptDurationField = useMemo(
    () => mMetaData?.find(({ key }) => key === '08-speak-duration'),
    [mMetaData],
  );

  const onSetEditor = useCallback(
    (value) => {
      setEditor(value);
    },
    [setEditor],
  );

  const getPlaceholderConfigs = useCallback(() => placeholderConfigsRef.current, []);

  const validUpdateScenario = (crudAction) => {
    if (crudAction === 'INSERT') return mId !== focusedInstanceId;
    return true;
  };

  useEffect(() => {
    placeholderConfigsRef.current = placeholderConfigs;
  }, [placeholderConfigs]);

  // NOTE: Work here
  useEffect(() => {
    if (currentClicked && currentClicked === mId) {
      const target = document.getElementById(`${storyId}-${mId}`);
      if (target) scrollAfterTimeout(target);
      setCollapsed(false);
    } else if (!currentClicked && focusedInstanceId === mId) {
      setCollapsed(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClicked, mId]);

  useEffect(() => {
    setDescriptionValue(description);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description]);

  const editorRef = useRef();
  const timeOutRef = useRef();

  // NOTE: This is not ideal solution. Need to reset
  // editor before changing readonly property. Otherwise
  // the app will crash in case of force unlock.
  const resetSelection = () => {
    if (editor) {
      const { deselect, blur } = ReactEditor;
      if (editor.selection) {
        deselect(editor);
        blur(editor);
      }
    }
  };

  useEffect(() => {
    const resetTimeOut = () => {
      timeOutRef.current = true;
    };

    resetSelection();
    setReadOnly(!writeLock);

    if (editorRef.current) {
      editorRef.current.addEventListener('keydown', resetTimeOut, false);
      editorRef.current.addEventListener('mousedown', resetTimeOut, false);
    }
    return () => {
      if (editorRef.current) {
        editorRef.current.removeEventListener('keydown', resetTimeOut, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        editorRef.current.removeEventListener('mousedown', resetTimeOut, false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [writeLock]);

  useEffect(() => {
    if (writeLock) {
      window.onbeforeunload = async (e) => {
        e.preventDefault();
        await onUnlockInstance();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [writeLock]);

  const onMetadataSelect = useCallback(() => {
    if (collapsed) setCollapsed(false);
    setShowMetadata(!showMetadata);
  }, [setShowMetadata, showMetadata, collapsed, setCollapsed]);

  const updateInstanceMetadata = useCallback(
    (newMetadata) => handleUpdateMetadata(newMetadata, instance), // TODO: fix it
    [handleUpdateMetadata, instance],
  );

  const toggleCollapse = useCallback(() => {
    if (!disableCollapse) {
      setCollapsed(!collapsed);
      collapsed ? updateStoryPane(0, 'instances', mId) : updateStoryPane(0, 'content');

      if (openInstancesUpdate) {
        const crudAction = collapsed ? 'INSERT' : 'REMOVE';
        if (validUpdateScenario(crudAction)) openInstancesUpdate(storyId, mId, crudAction);
      }
      if (setSkipDownload) setSkipDownload(!collapsed);
    }
  }, [disableCollapse, collapsed, storyId, mId, openInstancesUpdate, updateStoryPane]);

  const handleDownload = useCallback(
    (contentType) => {
      if (!mId || mId.startsWith('-')) return;
      const reqData = { mId, mRefId: mId, contentType, rundownSection: '-' };
      setDownload(reqData);
      setIsShowingDownload(true);
    },
    [mId],
  );

  const handleClientPrint = useCallback(() => {
    setShowPrintDialog(true);
  }, []);

  const closePrintDialog = () => {
    setShowPrintDialog(false);
  };

  const handleOnEditorClick = useCallback(() => {
    !readLock && !writeLock && !disableEdit && onLockInstance();
  }, [readLock, writeLock, disableEdit, onLockInstance]);

  const handleDone = useCallback(
    (e) => {
      onDone(e);
      isCMSInstance && onDescriptionChange(descriptionValue);
    },
    [descriptionValue, isCMSInstance, onDescriptionChange, onDone],
  );

  const handleSelectTemplate = useCallback(
    (templateObj) => {
      writeLock && onSelectTemplate(templateObj);
    },
    [onSelectTemplate, writeLock],
  );

  const handleDescriptionChange = useCallback((e) => setDescriptionValue(e.target.value), []);

  const platformStructure =
    platform && platform.mProperties && platform.mProperties.platformStructure;

  const platformId = platform?.mRefId;
  const editInExternalTab =
    platformStructure &&
    variant === variants.CMS &&
    platformStructure.variant === 'editInExternalTab';

  const {
    previewEndpoint,
    embeddedEndpoint,
    state: providerState,
    message: providerMessage,
  } = provider || {};

  const onCmsEditingClick = useCallback(() => {
    if (editInExternalTab) {
      window.open(embeddedEndpoint, 235711131719);
    } else {
      setOpenCmsEditDialog(!openCmsEditDialog);
    }
  }, [embeddedEndpoint, editInExternalTab, openCmsEditDialog]);

  const showTwitterEmbed = useMemo(
    () => canShowNewDesign && isTwitterInstance && !!previewEndpoint,
    [canShowNewDesign, isTwitterInstance, previewEndpoint],
  );

  const showYoutubeEmbed = useMemo(
    () => canShowNewDesign && isYoutubeInstance && !!embeddedEndpoint,
    [canShowNewDesign, embeddedEndpoint, isYoutubeInstance],
  );

  const showFacebookEmbed = useMemo(
    () => canShowNewDesign && isFacebookInstance && !!embeddedEndpoint,
    [canShowNewDesign, embeddedEndpoint, isFacebookInstance],
  );

  const showInstagramEmbed = useMemo(
    () => canShowNewDesign && isInstagramInstance && !!embeddedEndpoint,
    [canShowNewDesign, embeddedEndpoint, isInstagramInstance],
  );

  const isEmbedPresent = useMemo(
    () => showTwitterEmbed || showYoutubeEmbed || showFacebookEmbed || showInstagramEmbed,
    [showFacebookEmbed, showInstagramEmbed, showTwitterEmbed, showYoutubeEmbed],
  );

  const shouldShowLoading = loading && !showTwitterEmbed && !showYoutubeEmbed;

  const showToggleButton = useMemo(
    () => canShowNewDesign && toggleViewConstraint.includes(variant),
    [canShowNewDesign, variant],
  );

  const handleAddDocumentToClipBoard = useCallback(() => {
    const clipBoardData = {
      mTitle: instance?.mTitle,
      instanceId: instance?.mId,
      storyId: instance?.mStoryId,
      s3Data: { ...editorValue },
      instanceData: { ...instance },
    };

    navigator.clipboard.writeText(JSON.stringify(clipBoardData, null, 2));
  }, [editorValue, instance]);

  const onKeyDown = (ev) => {
    if ((ev.metaKey || ev.ctrlKey) && ev.shiftKey && ev.key === 'S') {
      handleAddDocumentToClipBoard();
    }
  };

  const isPublished = !!publishingDate;

  const isCmsBlock =
    platformStructure && variant === variants.CMS && platformStructure.variant === 'blocks';

  const masterEditOffset = showMasterEditView ? 80 : 0;
  const applicationTitleHeight = applicationTitleFormat ? 20 : 0;
  const writeReadLockOffset = writeLock || readLock ? 40 : 0;
  const timelineHeight = isTimelineShown ? 0 : 125;
  const feedTickerHeight = feedTickerVisible ? 40 : 0;
  const heightOffset =
    360 +
    writeReadLockOffset -
    (timelineHeight - applicationTitleHeight - masterEditOffset - feedTickerHeight);
  const height = window.innerHeight - heightOffset - 20;

  const shouldRenderToolbar = !disableEdit && !hideToolbar;
  const editorPlaceholder =
    canUpdateInstance && variant !== variants.CMS ? DEFAULT_EDITOR_PLACEHOLDER : '';

  const renderedEditor = useMemo(
    () => (
      <Editor
        height={height}
        readOnly={readOnly}
        shouldResetSelection={shouldResetEditorSelection}
        update={onEditorUpdate}
        thumbnail={backgroundImage}
        platformStructure={platformStructure}
        value={editorValue}
        isAllowed={canShowNewDesign || (isCMSInstance && canShowCmsIframe)}
        onCmsEditing={onCmsEditingClick}
        setEditor={onSetEditor}
        placeholder={editorPlaceholder}
        withSignedUrl={canUploadMediaBySignedURL}
        direction={textDirection}
        {...(!shouldRenderToolbar && { renderToolbar: () => null })}
        users={users}
        variant={variant}
        hostReadSpeed={hostReadSpeed}
        isPublished={isPublished}
        isCmsBlock={isCmsBlock}
        editorFontSize={editorFontSize}
        getPlaceholderConfigs={getPlaceholderConfigs}
        platformId={platformId}
        platformKind={platformKind}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      height,
      readOnly,
      shouldResetEditorSelection,
      backgroundImage,
      editorValue,
      canShowNewDesign,
      canShowCmsIframe,
      shouldRenderToolbar,
      onCmsEditingClick,
      onSetEditor,
      users,
      variant,
      hostReadSpeed,
      isPublished,
      platformStructure,
      isCmsBlock,
      editorFontSize,
      getPlaceholderConfigs,
      canUploadMediaBySignedURL,
    ],
  );

  const content =
    toggleView === toggleViews.EDIT ? (
      renderedEditor
    ) : (
      <EmbedView
        showTwitterEmbed={showTwitterEmbed}
        showYoutubeEmbed={showYoutubeEmbed}
        showFacebookEmbed={showFacebookEmbed}
        showInstagramEmbed={showInstagramEmbed}
        provider={provider}
        readLock={readLock}
        writeLock={writeLock}
        height={height}
      />
    );

  const showCmsDescriptionInput = useMemo(
    () => isCMSInstance && !canShowNewDesign && !canShowCmsIframe,
    [canShowCmsIframe, canShowNewDesign, isCMSInstance],
  );

  const header = useMemo(
    () => (
      <Header
        instanceId={mId}
        onBackgroundClick={toggleCollapse}
        onDownload={handleDownload}
        onSelectTemplate={handleSelectTemplate}
        publishingPoint={publishingPoint}
        publishingPointIcon={publishingPointIcon}
        platformKind={platformKind}
        onClientPrint={handleClientPrint}
        isContentLoaded={isContentLoaded}
        assignees={assignees}
        onAssigneeUpdate={onAssigneeUpdate}
        toggleView={toggleView}
        setToggleView={setToggleView}
        showToggleButton={showToggleButton}
        backgroundImage={backgroundImage}
        canCreateNewTemplate={canCreateNewTemplate}
        canDeleteTemplate={canDeleteTemplate}
        canReorderTemplates={canReorderTemplates}
        canSetDefaultTemplate={canSetDefaultTemplate}
        onSetDefaultTemplate={onSetDefaultTemplate}
        canDeleteTemplateFolder={canDeleteTemplateFolder}
        canPinTemplateFolder={canPinTemplateFolder}
        containerRef={containerRef}
        currentDestination={currentDestination}
        embeddedEndpoint={embeddedEndpoint}
        folders={folders}
        onCreateDuplicate={onCreateDuplicate}
        onCreateFolder={onCreateFolder}
        onDeleteFolder={onDeleteFolder}
        onDeleteInstance={onDeleteInstance}
        onDeleteTemplate={onDeleteTemplate}
        onForceUnlock={onForceUnlock}
        onMetadataSelect={onMetadataSelect}
        onOpenStory={onOpenStory}
        onOpenRundown={onOpenRundown}
        onPublishSettingsChange={onPublishSettingsChange}
        onSaveTemplate={onSaveTemplate}
        onTitleUpdate={onTitleUpdate}
        previewEndpoint={previewEndpoint}
        schedule={schedule}
        expiryTime={expiryTime}
        showMetadata={showMetadata}
        statusId={statusId}
        summarize={summarize}
        title={title}
        variant={variant}
        writeLock={writeLock}
        readLock={readLock}
        lockedByUser={lockedByUser}
        disableEdit={disableEdit}
        canShowNewDesign={canShowNewDesign}
        canShowCmsIframe={canShowCmsIframe}
        onRestoreVersion={onRestoreVersion}
        checkVersionRestorability={checkVersionRestorability}
        isSavingContent={isSavingContent}
        onCmsEditingClick={onCmsEditingClick}
        isCmsBlock={isCmsBlock}
        disableOpenStory={disableOpenStory}
        providerState={providerState}
        isEmbedPresent={isEmbedPresent}
        publishMetadata={publishMetadata}
        updateInstanceMetadata={updateInstanceMetadata}
        canUpdateInstance={canUpdateInstance}
        isPreview={isPreview}
        accountIdentifier={accountIdentifier}
        showCloseButton={showCloseButton}
        onClose={onClose}
        showOpenStoryButton={showOpenStoryButton}
        hideTemplateOptions={hideTemplateOptions}
        hideAutomationTemplates={hideAutomationTemplates}
        defaultTemplateRefId={defaultTemplateRefId}
        storyId={storyId}
        isFirstInstance={isFirstInstance}
      />
    ),
    [
      mId,
      toggleCollapse,
      handleDownload,
      handleSelectTemplate,
      publishingPoint,
      publishingPointIcon,
      platformKind,
      assignees,
      onAssigneeUpdate,
      toggleView,
      showToggleButton,
      backgroundImage,
      canCreateNewTemplate,
      canDeleteTemplate,
      canReorderTemplates,
      canSetDefaultTemplate,
      onSetDefaultTemplate,
      canDeleteTemplateFolder,
      canPinTemplateFolder,
      currentDestination,
      embeddedEndpoint,
      folders,
      onCreateDuplicate,
      onCreateFolder,
      onDeleteFolder,
      onDeleteInstance,
      onDeleteTemplate,
      onForceUnlock,
      onMetadataSelect,
      onOpenStory,
      onOpenRundown,
      onPublishSettingsChange,
      onSaveTemplate,
      onTitleUpdate,
      previewEndpoint,
      schedule,
      expiryTime,
      showMetadata,
      statusId,
      summarize,
      title,
      variant,
      writeLock,
      readLock,
      lockedByUser,
      disableEdit,
      canShowNewDesign,
      canShowCmsIframe,
      onRestoreVersion,
      checkVersionRestorability,
      isSavingContent,
      onCmsEditingClick,
      isCmsBlock,
      disableOpenStory,
      providerState,
      isEmbedPresent,
      publishMetadata,
      updateInstanceMetadata,
      canUpdateInstance,
      isPreview,
      accountIdentifier,
      showCloseButton,
      onClose,
      showOpenStoryButton,
      hideTemplateOptions,
      hideAutomationTemplates,
      defaultTemplateRefId,
      storyId,
      isContentLoaded,
      isFirstInstance,
    ],
  );

  const footer = useMemo(
    () => (
      <Footer
        collapsed={collapsed}
        variant={variant}
        statusId={statusId}
        onStatusChange={onStatusChange}
        onClipDurationChange={onClipDurationChange}
        onScriptDurationChange={onScriptDurationChange}
        clipDuration={clipDuration}
        clipAutoDurationField={clipAutoDurationField}
        scriptDurationField={scriptDurationField}
        scriptDuration={scriptDuration}
        totalDuration={totalDuration}
        isSavingContent={isSavingContent}
        onUnlockInstance={onUnlockInstance}
        readLock={readLock}
        writeLock={writeLock}
        lockedByUser={lockedByUser}
        publishingPoint={publishingPoint}
        platformKind={platformKind}
        disableEdit={disableEdit}
        hasChanges={hasChanges}
        twitterThreadCount={twitterThreadCount}
        canShowNewDesign={canShowNewDesign}
        providerState={providerState}
        providerMessage={providerMessage}
        instance={instance}
        canUpdateInstance={canUpdateInstance}
        autoClipDurationSettingsValue={autoClipDurationSettingsValue}
        canUpdateScriptDurationSettingsValue={canUpdateScriptDurationSettingsValue}
        isPreview={isPreview}
        instanceId={mId}
        relatedMembers={relatedMembers}
        onDone={handleDone}
        accountIdentifier={accountIdentifier}
        onCancel={onCancel}
        isCancelled={isCancelled}
      />
    ),
    [
      mId,
      canShowNewDesign,
      clipDuration,
      collapsed,
      disableEdit,
      handleDone,
      hasChanges,
      isSavingContent,
      lockedByUser,
      onClipDurationChange,
      onScriptDurationChange,
      clipAutoDurationField,
      scriptDurationField,
      onStatusChange,
      onUnlockInstance,
      providerMessage,
      providerState,
      publishingPoint,
      platformKind,
      readLock,
      scriptDuration,
      statusId,
      totalDuration,
      twitterThreadCount,
      variant,
      writeLock,
      instance,
      canUpdateInstance,
      relatedMembers,
      autoClipDurationSettingsValue,
      canUpdateScriptDurationSettingsValue,
      isPreview,
      accountIdentifier,
      onCancel,
      isCancelled,
    ],
  );

  return (
    <div className={classes.root} ref={containerRef}>
      <Paper
        elevation={24}
        classes={{ root: classes.paper }}
        id={`${storyId}-${mId}`}
        onKeyDown={onKeyDown}
      >
        {(shouldShowLoading || updatingRelatedMembers) && <DebouncedLoadingIndicator />}
        {header}
        <div ref={editorRef}>
          <Collapse
            in={!collapsed}
            classes={{ container: classes.collapseContainer }}
            timeout={0}
            mountOnEnter
          >
            <SplitBar
              style={{ height }}
              split="vertical"
              primary="second"
              pane1Style={{
                minWidth: showMetadata ? '40%' : '100%',
                maxWidth: showMetadata ? '70%' : '100%',
              }}
              pane2Style={{
                minWidth: showMetadata ? '30%' : '0%',
                maxWidth: showMetadata ? '60%' : '0%',
              }}
            >
              <div
                onClick={(e) => clickIfNothingSelected(e, () => handleOnEditorClick(e))}
                role="presentation"
                className={classes.editorDiv}
              >
                {showCmsDescriptionInput && (
                  <Input
                    className={classes.description}
                    placeholder="Write a short description..."
                    fullWidth
                    multiline
                    disableUnderline
                    onChange={handleDescriptionChange}
                    value={descriptionValue}
                    rows={3}
                    readOnly={!writeLock || readLock}
                  />
                )}
                {content}
              </div>

              <Metadata
                fields={fields}
                metadata={metadata}
                parameterFields={parameterFields}
                onUpdateMeta={updateInstanceMetadata}
                disableEdit={disableEdit}
              />
            </SplitBar>
          </Collapse>
        </div>
        {footer}
      </Paper>
      <ResizableDialog open={openCmsEditDialog}>
        <CmsEditing id={mId} title={title} closeDialog={onCmsEditingClick} />
      </ResizableDialog>
      {isShowingDownload ? (
        <Download download={download} setIsShowingDownload={setIsShowingDownload} />
      ) : null}
      {showPrintDialog && (
        <InstancePrint
          instance={instance}
          isDialogOpen={showPrintDialog}
          onCloseDialog={closePrintDialog}
          clipDuration={clipDuration}
          scriptDuration={scriptDuration}
          totalDuration={totalDuration}
        />
      )}
    </div>
  );
};

InstanceCardView.propTypes = {
  /** mId of the instance */
  mId: PropTypes.string,
  /** Variant of the instance card */
  variant: PropTypes.oneOf(Object.values(variants)),
  /** Current status id of the instance */
  statusId: PropTypes.string,
  /** Callback to be invoked on status change */
  onStatusChange: PropTypes.func,
  /** Publishing platform of the instance */
  publishingPoint: PropTypes.oneOf(Object.keys(publishingPoints)),
  /** Currently selected destination of this instance */
  currentDestination: PropTypes.shape({
    title: PropTypes.string,
    publishingTime: PropTypes.string,
  }),
  /** provider information from mProperties */
  provider: PropTypes.shape({
    embeddedEndpoint: PropTypes.string,
    message: PropTypes.string,
    previewEndpoint: PropTypes.string,
    requestId: PropTypes.string,
    state: PropTypes.string,
    updateEndpoint: PropTypes.string,
  }),
  /** Callback to be invoked on publish settings change */
  onPublishSettingsChange: PropTypes.func,
  /** Title of the instance */
  title: PropTypes.string,
  /** Callback to be invoked on title update */
  onTitleUpdate: PropTypes.func,
  /** Publishing time for general instance variant */
  schedule: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  /** Expire time for cms instance variant */
  expiryTime: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  /** List of users assigned to current instance */
  assignees: PropTypes.arrayOf(
    PropTypes.shape({
      mAvatarUrl: PropTypes.string,
    }),
  ),
  /** Callback to be invoked on assignee update */
  onAssigneeUpdate: PropTypes.func,
  /** Callback to be invoked on template selection,
   * with the selected template passed in
   */
  onSelectTemplate: PropTypes.func,
  /** Callback to be invoked when save as template option is clicked,
   * with the templateName passed in
   */
  onSaveTemplate: PropTypes.func,
  /** Callback to be invoked on template deletion,
   * with the template to be deleted passed in
   */
  onDeleteTemplate: PropTypes.func,
  /** Clip duration for the instance in milliseconds */
  clipDuration: PropTypes.string,
  /** Callback to be invoked on clip duration change */
  onClipDurationChange: PropTypes.func,
  /** Callback to be invoked on script duration change */
  onScriptDurationChange: PropTypes.func,
  /** Script duration for the instance in milliseconds */
  scriptDuration: PropTypes.string,
  /** Total duration for the instance in milliseconds */
  totalDuration: PropTypes.string,
  /** Initial of the editor */
  editorValue: PropTypes.shape({}),
  /** List of users that can be mentioned on the editor */
  users: PropTypes.arrayOf(
    PropTypes.shape({
      mId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      mTitle: PropTypes.string,
    }),
  ),
  /** Defines if the card should start collapsed */
  initiallyCollapsed: PropTypes.bool,
  /** List of template folders */
  folders: PropTypes.arrayOf(PropTypes.any),
  /** Callback to be invoked while creating new folder */
  onCreateFolder: PropTypes.func,
  /** Callback to be invoked when opening a story */
  onOpenStory: PropTypes.func,
  /** Callback to be invoked when rundown from a linear instance */
  onOpenRundown: PropTypes.func,
  /** Callback to be invoked while deleting a folder */
  onDeleteFolder: PropTypes.func,
  /** boolean to indicate tat user cab only read the value */
  readLock: PropTypes.bool,
  /** Callback to be invoked to lock the instance */
  onLockInstance: PropTypes.func,
  /** Callback to be invoked to unlock the instance */
  onUnlockInstance: PropTypes.func,
  /** Boolean that indicates that editor value is saving */
  isSavingContent: PropTypes.bool,
  /** Name of the instance's locking user */
  lockedByUser: PropTypes.string,
  /** Boolean to indicates that user can edit the value */
  writeLock: PropTypes.bool,
  /** callback function to be invoked wen done button is clicked */
  onDone: PropTypes.func,
  /** description of the cms instance */
  description: PropTypes.string,
  /** Callback to invoke when description changes */
  onDescriptionChange: PropTypes.func,
  /** boolean that hides create new template from menu */
  canCreateNewTemplate: PropTypes.bool,
  /** boolean that hides delete template from menu */
  canDeleteTemplate: PropTypes.bool,
  /** boolean that hides delete template folder from menu */
  canDeleteTemplateFolder: PropTypes.bool,
  /** Boolean that stops an user from editing an instance */
  disableEdit: PropTypes.bool,
  /** Hides the toolbar of editor */
  hideToolbar: PropTypes.bool,
  /** editorFontSize defined for paragraph: small, medium, large, xLarge */
  editorFontSize: PropTypes.string,
  /** Hides template select options from ellipsis menu */
  hideTemplateOptions: PropTypes.bool,
  /** is inside a preview container */
  isPreview: PropTypes.bool,
  /** should reset editor selection */
  shouldResetEditorSelection: PropTypes.bool,
};

InstanceCardView.defaultProps = {
  assignees: [],
  clipDuration: undefined,
  currentDestination: {},
  provider: {},
  description: '',
  editorValue: undefined,
  folders: [],
  initiallyCollapsed: false,
  isSavingContent: false,
  lockedByUser: 'Someone',
  mId: undefined,
  onAssigneeUpdate: (updatedAssignees) => {},
  onClipDurationChange: (newDuration) => {},
  onScriptDurationChange: (newDuration) => {},
  onCreateFolder: () => {},
  onDeleteFolder: () => {},
  onDeleteTemplate: (template) => {},
  onDescriptionChange: () => {},
  onDone: () => {},
  onLockInstance: () => {},
  onOpenStory: () => {},
  onOpenRundown: () => {},
  onPublishSettingsChange: (newPublishingSettings) => {},
  onSaveTemplate: (templateName) => {},
  onSelectTemplate: (template) => {},
  onStatusChange: (newStatusId) => {},
  onTitleUpdate: (newTitle) => {},
  onUnlockInstance: () => {},
  publishingPoint: 'linear',
  readLock: false,
  schedule: null,
  expiryTime: undefined,
  scriptDuration: undefined,
  statusId: 'todo',
  title: '',
  totalDuration: undefined,
  users: [],
  variant: variants.LINEAR,
  writeLock: false,
  canCreateNewTemplate: false,
  canDeleteTemplate: false,
  canDeleteTemplateFolder: false,
  disableEdit: false,
  hideToolbar: false,
  editorFontSize: '',
  hideTemplateOptions: false,
  isPreview: false,
  shouldResetEditorSelection: false,
};

export default InstanceCardView;
