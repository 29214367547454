import Tooltip from 'components/tooltip';

interface Props {
  children: React.ReactElement;
  disableEdit?: boolean;
  readonly?: boolean;
}
function TooltipWrapper({ children, disableEdit, readonly }: Readonly<Props>) {
  if (readonly) {
    return <div style={{ pointerEvents: 'none' }}>{children}</div>;
  }

  if (disableEdit) {
    return (
      <Tooltip title="You don't have permission to edit.">
        <div>
          <div style={{ pointerEvents: 'none' }}>{children}</div>
        </div>
      </Tooltip>
    );
  }

  return children;
}

export default TooltipWrapper;
