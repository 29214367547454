import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { ReactComponent as Ellipsis } from 'assets/icons/systemicons/more_vertical.svg';
import transientOptions from 'theme/helpers';

export const Options = styled(Ellipsis)<{ $disabled: boolean }>`
  width: 20px;
  ${({ $disabled }) => css`
    cursor: ${$disabled ? 'default' : 'pointer'};
    path {
      fill-opacity: ${$disabled ? '.3' : '.6'};
    }
    &:hover path {
      fill-opacity: ${$disabled ? '.3' : '1'};
    }
  `};
`;

export const FocusWrapper = styled('div', transientOptions)`
  padding: 8px;
`;

export const TitleWrapper = styled('div')`
  width: 100%;
  height: 20px;
  margin-bottom: 3px;
`;

export const SelectWrapper = styled('div', transientOptions)<{ $selected: boolean }>`
  border-radius: 4px;
  height: 40px;
  display: flex;
  gap: 6px;
  align-items: center;
  max-width: 600px;
  padding-right: 8px;
  cursor: pointer;
  box-shadow: ${({ $selected, theme }) =>
    $selected
      ? `0 0 0 2px ${theme.palette.dina.statusOnFocused}`
      : `0 0 0 2px ${theme.palette.dina.borderResting}`};
  background: ${({ $selected, theme }) =>
    $selected ? theme.palette.dina.backgroundSelected : theme.palette.dina.blackHoverOverlay};
  :hover {
    box-shadow: ${({ $selected, theme }) =>
      $selected
        ? `0 0 0 2px ${theme.palette.dina.statusOnFocused}`
        : `0 0 0 2px ${theme.palette.dina.blockBorderHover}`};
    background: ${({ theme }) => theme.palette.dina.tableAlternatingRowOverlay};
  }
`;

export const MdfLabelWrapper = styled('div')`
  height: 20px;
`;

export const IconWrapper = styled('div', transientOptions)<{ $color?: string }>`
  width: 32px;
  height: 100%;
  background-color: ${({ theme, $color }) => $color ?? theme.palette.dina.borderHover};
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg path {
    fill-opacity: 1;
  }
`;
