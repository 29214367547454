import { useEffect, useState } from 'react';
import { DialogActions, DialogContent } from '@material-ui/core';

import AddMemberDropdown from 'components/addMember';
import { StyledTextField } from 'components/addMember/styled';
import { Button } from 'components/buttons';
import Switch from 'components/switch';
import { useMembersDialog, useMembersFilterMatchAll, useMembersOpenDialog } from 'store';
import { AssignedMember } from 'types';

import SelectedMembers from './SelectedMembers';

import {
  Label,
  StyledCancelButtonWrapper,
  StyledConfirmButtonWrapper,
  StyledDialog,
  StyledDialogTitle,
  StyledFormControlLabel,
} from './styled';

const DIALOG_ANIMATION_DURATION = 500;

const hasNewSelectedMembers = (startWith: AssignedMember[], currentSelected: AssignedMember[]) => {
  for (const member of currentSelected) {
    if (!startWith.includes(member)) return true;
  }
  return false;
};

function AddMemberDialog() {
  const [open, setOpen] = useMembersOpenDialog();
  const [state] = useMembersDialog();
  const [selectedByDropdown, setSelectedByDropdown] = useState<AssignedMember[] | []>([]);
  const [messageAssign, setMessageAssign] = useState('');
  const [membersFilterByAndLogic, setMembersFilterByAndLogic] = useMembersFilterMatchAll();
  const [canFilterByAndLogic, setCanFilterByAndLogic] = useState(membersFilterByAndLogic);

  const handleOk = () => {
    const { onOk } = state;
    if (onOk) {
      onOk(selectedByDropdown, messageAssign, canFilterByAndLogic);
    }
    setMembersFilterByAndLogic(canFilterByAndLogic);
  };

  const handleCancel = () => {
    if (state.onCancel) {
      state.onCancel();
    }
    setCanFilterByAndLogic(membersFilterByAndLogic);
  };

  const closeDialog = () => {
    setOpen(false);
    setMessageAssign('');

    // Clear after a duration to let the dialog animate out without it hopping
    setTimeout(() => {
      setSelectedByDropdown([]);
    }, DIALOG_ANIMATION_DURATION);
  };

  const onConfirm = (isCancel: boolean) => {
    if (state.onOk) {
      if (!isCancel) {
        handleOk();
      } else {
        handleCancel();
      }
    }
    closeDialog();
  };

  const onRemove = (member: AssignedMember) => {
    setSelectedByDropdown(selectedByDropdown?.filter((m) => m.mId !== member.mId));
  };

  const toggleCanFilterByAndLogic = () => {
    setCanFilterByAndLogic((prev) => !prev);
  };

  useEffect(() => {
    setSelectedByDropdown(state.startWith);
  }, [state]);

  const buttonText = state.buttonText ?? 'Confirm';
  const showTextArea =
    state.showMessage && hasNewSelectedMembers(state.startWith, selectedByDropdown);

  return (
    <StyledDialog open={open} onClose={() => onConfirm(true)} maxWidth="md" container={undefined}>
      <StyledDialogTitle variant="h7" color="highEmphasis">
        {state.dialogTitle}
      </StyledDialogTitle>
      <DialogContent>
        <AddMemberDropdown
          variant={state.variant}
          autoFocus={true}
          showChips={false}
          singleChoice={state.singleChoice}
          injectedMembers={state.injectedMembers}
          selectedMembers={selectedByDropdown}
          setSelectedMembers={setSelectedByDropdown}
        />
        <SelectedMembers
          selectedMembers={selectedByDropdown}
          existingMembers={state.startWith}
          onRemove={onRemove}
          removeTooltipText={state.removeTooltipText}
          subHeader={state.subHeader}
          disableRemoveOnlyAssignee={state.disableRemoveOnlyAssignee}
          disabledRemoveTooltipText={state.disabledRemoveTooltipText}
        />
        {state.matchAll && !!selectedByDropdown.length && (
          <StyledFormControlLabel
            control={
              <Switch
                selected={canFilterByAndLogic}
                onClick={toggleCanFilterByAndLogic}
                disabled={false}
              />
            }
            label="Match all"
            labelPlacement="start"
          />
        )}
        {showTextArea && (
          <>
            <Label>Add an optional message to new assignees</Label>
            <StyledTextField
              fullWidth
              variant="filled"
              placeholder="Type a message..."
              value={messageAssign ?? ''}
              onChange={(e) => setMessageAssign(e.target.value)}
              multiline
              minRows={4}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <StyledCancelButtonWrapper>
          <Button onClick={() => onConfirm(true)} variant="outlined" usage="outlined">
            Cancel
          </Button>
        </StyledCancelButtonWrapper>
        <StyledConfirmButtonWrapper>
          <Button onClick={() => onConfirm(false)}>{buttonText}</Button>
        </StyledConfirmButtonWrapper>
      </DialogActions>
    </StyledDialog>
  );
}

export default AddMemberDialog;
