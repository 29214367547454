import { useMemo } from 'react';
import { useAtomValue } from 'jotai';

import { isPitch as checkIsPitch } from 'features/storyHub/utils';
import useDinaNavigate from 'hooks/useDinaNavigate';
import { Flex, HStack } from 'layouts/box/Box';
import StoryView from 'screens/storyV2';
import { Content, policiesAtom } from 'store';
import { MemberType } from 'types/graphqlTypes';
import checkUserRight from 'utils/checkUserRight';

import { PitchHeader, StoryHeader } from './Headers';

interface Props {
  member: MemberType | null;
  onClose: () => void;
}

const WIDTH = '1100px';

export default function StoryDrawer({ member, onClose }: Readonly<Props>) {
  const { navigateTo } = useDinaNavigate();

  const policies = useAtomValue(policiesAtom);
  const canUpdateStory = checkUserRight(policies, 'story', 'update');
  const canUpdatePitch = checkUserRight(policies, 'pitch', 'update');

  const handleOpenStory = () => {
    if (!member) return;
    onClose();
    if (member.mId) navigateTo(member.mType as Content, member.mId);
  };

  const isPitch = useMemo(() => checkIsPitch(member?.mType ?? ''), [member?.mType]);

  const canUpdatePublishing = isPitch ? canUpdatePitch : canUpdateStory;

  return (
    <>
      <Flex maxWidth={WIDTH} width={WIDTH}>
        {isPitch && member ? (
          <PitchHeader onClose={onClose} member={member} handleOpenStory={handleOpenStory} />
        ) : (
          <StoryHeader
            onClose={onClose}
            member={member}
            handleOpenStory={handleOpenStory}
            canUpdatePublishing={canUpdatePublishing}
          />
        )}
      </Flex>
      <HStack alignItems="stretch" height="100%" width={WIDTH} maxWidth={WIDTH}>
        <StoryView storyId={member?.mId} />
      </HStack>
    </>
  );
}
