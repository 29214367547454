import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { createInstanceLink } from 'components/instanceCard/utils/createInstanceLink';
import TitleInput from '../titleInput';
import { Title, TitleWrapper } from './titleBox-styled';

const TitleBox = ({
  initialTitle,
  instanceId,
  storyId,
  onTitleUpdate,
  disableEdit,
  disableOpenStory,
}) => {
  const [title, setTitle] = useState(initialTitle);
  const [showTitleInput, setShowTitleInput] = useState(false);

  useEffect(() => {
    setTitle(initialTitle);
  }, [initialTitle]);

  useEffect(() => {
    setShowTitleInput(false);
  }, [instanceId]);

  const handleShowInput = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!disableOpenStory && (event.ctrlKey || event.metaKey)) {
      window.open(createInstanceLink(storyId, instanceId), '_blank', 'noopener,noreferrer');
      return;
    }
    !disableEdit && setShowTitleInput(!showTitleInput);
  };

  const handleCloseInput = (event) => {
    setShowTitleInput(!showTitleInput);
  };

  const onClickAway = () => {
    setTitle(initialTitle);
    setShowTitleInput(!showTitleInput);
  };

  if (showTitleInput)
    return (
      <TitleInput
        title={title}
        setTitle={setTitle}
        initialTitle={initialTitle}
        onClickAway={onClickAway}
        onClose={handleCloseInput}
        onUpdate={onTitleUpdate}
      />
    );

  return (
    <>
      <TitleWrapper>
        <Title onClick={handleShowInput} disableEdit={disableEdit}>
          {title}
        </Title>
      </TitleWrapper>
    </>
  );
};

TitleBox.propTypes = {
  /** Initial title for the instance */
  initialTitle: PropTypes.string,
  /** mId of the instance */
  instanceId: PropTypes.string,
  /** onTitleUpdate function */
  onTitleUpdate: PropTypes.func,
  /** parent entity id of the instance */
  storyId: PropTypes.string,
  /** onClose callback: will always show */
  onClose: PropTypes.func,
  /** whether to allow opening instance link on cmd/ctrl click */
  disableOpenStory: PropTypes.bool,
};

TitleBox.defaultProps = {
  initialTitle: '',
  instanceId: '',
  onTitleUpdate: () => {},
  storyId: '',
  onClose: () => {},
  disableOpenStory: false,
};

export default TitleBox;
