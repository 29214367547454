import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button } from '@material-ui/core';
import close from 'assets/icons/systemicons/close.svg';
import Divider from 'components/divider';
import InputField from 'components/inputField';
import TextArea from 'components/textArea';
import Popover from 'components/popover';
import Warning from 'components/warning';
import useStyles from './createNew-styles';

const getProperties = (type) => {
  const properties = {
    folder: {
      headerTitle: 'Create new Template folder',
      label: 'Template folder title',
      subTitle: 'FOLDER NAME',
      assistiveText: '',
      okButtonText: 'Create Folder',
      severity: 'regular',
      inputStyle: null,
    },
    template: {
      headerTitle: 'Save as new Instance Template',
      label: null,
      subTitle: 'TEMPLATE NAME',
      assistiveText: 'Type the name of the Instance Template',
      okButtonText: 'Save',
      severity: 'editing',
      inputStyle: 'templateInput',
    },
  };
  return properties[type];
};

const CreateNew = (props) => {
  const { onCancel, onOk, variant, data } = props;
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [showWarning, setShowWarning] = useState(false);
  const [existingItem, setExistingItem] = useState(null);
  const rootRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const { headerTitle, subTitle, label, assistiveText, okButtonText, severity, inputStyle } =
    getProperties(variant);

  const handleOk = () => {
    if (title) {
      const foundItem = data.find((item) => item.mTitle === title);
      if (foundItem) {
        setExistingItem(foundItem);
        setShowWarning(true);
        setAnchorEl(rootRef.current);
      } else {
        onOk(title, description);
      }
    }
  };

  const handleHideWarning = () => {
    setAnchorEl(null);
  };

  const handleOverwrite = () => {
    handleHideWarning();
    onOk(title, description, existingItem);
  };

  return (
    <div className={classes.root} ref={rootRef}>
      <div className={classes.header}>
        <Typography classes={{ root: classes.headerText }}>{headerTitle}</Typography>
        <img src={close} alt="close" role="presentation" onKeyDown={onCancel} onClick={onCancel} />
      </div>
      <Divider />
      <div className={classes.body}>
        <Typography classes={{ root: classes.labelText }}>{subTitle}</Typography>
        <div className={classes.inputContainer}>
          <InputField
            value={title}
            autoFocus
            label={label}
            onChange={(value) => setTitle(value)}
            severity={severity}
            description={assistiveText}
            className={classes[inputStyle]}
          />
        </div>
        <Divider />
        <Typography classes={{ root: classes.labelText }}>DESCRIPTION</Typography>
        <div className={classes.descriptionContainer}>
          <TextArea
            value={description}
            onChange={(value) => setDescription(value)}
            label="Type a description here"
            className={classes.descriptionInput}
          />
        </div>
      </div>
      <Divider />
      <div className={classes.buttonContainer}>
        <Button
          classes={{ root: classes.createButton, label: classes.buttonLabel }}
          onClick={handleOk}
        >
          {okButtonText}
        </Button>
        <Button
          classes={{ root: classes.cancelButton, label: classes.buttonLabel }}
          onClick={onCancel}
        >
          Cancel
        </Button>
      </div>
      {showWarning && (
        <Popover anchorEl={anchorEl} onClose={handleHideWarning} position="center">
          <div className={classes.warning}>
            <Warning
              title={title}
              onClose={handleHideWarning}
              onConfirm={handleOverwrite}
              variant={variant}
            />
          </div>
        </Popover>
      )}
    </div>
  );
};

CreateNew.propTypes = {
  /** Callback to be invoked when cancel button is clicked */
  onCancel: PropTypes.func,
  /** Callback to be invoked when create button is clicked */
  onOk: PropTypes.func,
  /** Variant of the createNew dialog */
  variant: PropTypes.oneOf(['folder', 'template']),
  /** data to check redundancy */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      mId: PropTypes.string,
      mRefId: PropTypes.string,
      mTitle: PropTypes.string,
    }),
  ),
};

CreateNew.defaultProps = {
  onCancel: () => {},
  onOk: () => {},
  variant: 'folder',
  data: [],
};

export default CreateNew;
