import React, { useState, useCallback, memo } from 'react';
import { useSlate } from 'slate-react';
import { Editor } from 'slate';
import { Fade, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { elementTypes } from 'components/editor/constants/types';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import Tooltip from 'components/tooltip';
import tooltipEnum from 'components/editor/constants/tooltipEnum';
import iconComponents from './constants/iconComponents';
import insertBlock from './utils/insertBlock';
import useStyles from './styles';

const Alert = memo((props) => <MuiAlert elevation={6} variant="filled" {...props} />);

const IconButton = ({ type, data = {} }) => {
  const classes = useStyles();
  const editor = useSlate();
  const { update } = useEditorContext();
  const IconComponent = iconComponents[type];
  const [open, setOpen] = useState(false);

  const insertItem = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (editor.selection) {
        const [currentNode] = Editor.node(editor, editor.selection, { depth: 1 });
        if (currentNode.type === elementTypes.PARAGRAPH) setOpen(!open);
        else insertBlock({ editor, update, type, data });
      } else setOpen(!open);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleClose = useCallback((event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  }, []);

  return (
    <>
      <Tooltip title={tooltipEnum[type]} noArrow>
        <IconComponent onClick={insertItem} className="skipOverride" />
      </Tooltip>
      <Snackbar
        TransitionComponent={Fade}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert className={classes.alert} onClose={handleClose} severity="info">
          Select a Valid block to insert items
        </Alert>
      </Snackbar>
    </>
  );
};

export default memo(IconButton);
