import { useMutation } from '@apollo/client';

import EditableText from 'components/text/EditableText';
import UPDATE_STORY from 'graphql/mutations/updateStory';
import useTabs from 'hooks/useTabs';
import { UNTITLED_STORY } from 'utils/constants';

function Title({ storyId, storyTitle }: Readonly<{ storyId: string; storyTitle: string }>) {
  const { updateTab } = useTabs();
  const [updateStory] = useMutation(UPDATE_STORY);

  const handleTitleUpdate = (title: string) => {
    const mTitle = title || UNTITLED_STORY;

    updateTab({ id: storyId, title: mTitle });
    void updateStory({
      variables: {
        input: {
          mId: storyId,
          mTitle,
        },
      },
    });
  };

  return (
    <EditableText
      value={storyTitle || UNTITLED_STORY}
      onUpdate={handleTitleUpdate}
      variant="storyHeaderTitle"
      color="whiteHighEmphasis"
      borderColor="whiteWatermark"
      textAlign="center"
      truncate
    />
  );
}

export default Title;
