import { createContext, useCallback, useEffect } from 'react';

import BREAKING_FEED from 'graphql/subscriptions/notifyBreakingFeeds';
import useApolloSubscription from 'hooks/useApolloSubscription';
import useSettingsValue from 'hooks/useSettingsValue';
import useNotification from 'hooks/useNotification';
import { useLocalNotifications } from 'store/notifications';

export const BreakingFeedContext = createContext([]);

const BreakingFeedProvider = ({ children }) => {
  const sendNotification = useNotification();
  const [getSettingsValue] = useSettingsValue();
  const [, setLocalNotifications] = useLocalNotifications();
  const showBreakingFeedNotification =
    getSettingsValue('user.breakingFeedNotifications')?.toString() === 'true';

  const onBreakingFeedData = useCallback(
    ({ subscriptionData }) => {
      if (!showBreakingFeedNotification) return;

      const data = subscriptionData?.data?.notifyBreakingFeedSubscription || {};
      const { mTitle: title, provider, mRefId } = data;

      const notification = {
        alertTitle: 'Breaking News',
        mType: 'breaking',
        storyId: mRefId,
        mId: mRefId,
        text: `${provider}: ${title}`,
        itemTitle: `${provider}: ${title}`,
        time: `${new Date()}`,
        provider,
      };

      sendNotification(notification);
      setLocalNotifications((prev) => [...prev, notification]);
    },
    [showBreakingFeedNotification, sendNotification, setLocalNotifications],
  );

  const [subscribe, unsubscribe] = useApolloSubscription(BREAKING_FEED, {
    variables: {},
    onSubscriptionData: onBreakingFeedData,
  });

  useEffect(() => {
    subscribe();
    return () => {
      unsubscribe();
    };
  }, []);

  return <BreakingFeedContext.Provider value={null}>{children}</BreakingFeedContext.Provider>;
};

export default BreakingFeedProvider;
