import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { format } from 'date-fns';
import DateTimePicker from 'components/dateTimePicker';
import ShowItem from '../showItem';
import AddItem from '../addItem';
import useStyles from './add-date-styles';

const AddDateView = ({ addItemTitle, showItemTitle, content, onUpdate, inputLabel }) => {
  const classes = useStyles();
  const [showInputField, setShowInputField] = useState(false);
  const [contentValue, setContentValue] = useState(content || '');

  const onChange = (newValue) => {
    if (newValue) {
      setContentValue(newValue);
      onUpdate(newValue);
    } else {
      setContentValue(newValue);
    }
  };

  return (
    <div
      onBlur={(event) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
          setShowInputField(false);
        }
      }}
    >
      {!showInputField && contentValue && (
        <ShowItem
          title={showItemTitle}
          content={contentValue}
          onClick={() => setShowInputField(true)}
        />
      )}

      {!showInputField && !contentValue && (
        <AddItem title={addItemTitle} onClick={() => setShowInputField(true)} />
      )}

      {showInputField && (
        <div className={classes.wrapper}>
          <Typography classes={{ root: classes.inputHeadline }}>{inputLabel}</Typography>

          <div className={classes.textAreaDiv}>
            <DateTimePicker
              autoFocus
              type="date"
              defaultDate={content || format(new Date(), 'YYYY-MM-DD')}
              onChange={(event) => {
                onChange(event.target.value);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

AddDateView.propTypes = {
  /** Defines the title text for AddItem component  */
  addItemTitle: PropTypes.string,
  /** Defines the title text for ShowItem component  */
  showItemTitle: PropTypes.string,
  /** Defines the passed date  */
  content: PropTypes.string,
  /** Defines the label when input is shown */
  inputLabel: PropTypes.string,
  /** Callback to be invoked on text input change */
  onUpdate: PropTypes.func,
};

AddDateView.defaultProps = {
  addItemTitle: '',
  showItemTitle: '',
  content: '',
  onUpdate: () => {},
  inputLabel: '',
};

export default AddDateView;
