import { useCallback, useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as CloseSmall } from 'assets/icons/systemicons/close_small.svg';
import { ReactComponent as MasterRundownOff } from 'assets/icons/systemicons/HeaderNavbar/master_rundown_off.svg';
import { ReactComponent as MasterRundownOn } from 'assets/icons/systemicons/HeaderNavbar/master_rundown_on.svg';
import { ReactComponent as PitchOff } from 'assets/icons/systemicons/HeaderNavbar/pitch_off.svg';
import { ReactComponent as PitchOn } from 'assets/icons/systemicons/HeaderNavbar/pitch_on.svg';
import { ReactComponent as RundownOff } from 'assets/icons/systemicons/HeaderNavbar/rundown_off.svg';
import { ReactComponent as RundownOn } from 'assets/icons/systemicons/HeaderNavbar/rundown_on.svg';
import { ReactComponent as SpacesOff } from 'assets/icons/systemicons/HeaderNavbar/spaces_off.svg';
import { ReactComponent as SpacesOn } from 'assets/icons/systemicons/HeaderNavbar/spaces_on.svg';
import { ReactComponent as StoryOff } from 'assets/icons/systemicons/HeaderNavbar/stories_off.svg';
import { ReactComponent as StoryOn } from 'assets/icons/systemicons/HeaderNavbar/stories_on.svg';
import LinearProgress from 'components/linearProgress';
import NotificationIndicator from 'components/statusIndicators/notification/Notification';
import Tooltip from 'components/tooltip';
import useTabs from 'hooks/useTabs';
import { ContentTab, useSetOnDropTabItem } from 'store/tabs';
import capitalize from 'utils/capitalize';
import dndTypes from 'utils/dndTypes';
import { formatDateToLocaleString } from 'utils/formatDate';

import ContextMenu from '../contextMenu';
import TabToolTip from '../tabTooltip';

import {
  NotificationWrapper,
  StyledIconButton,
  StyledLabel,
  StyledTabContentWrapper,
  StyledTabIconWrapper,
  StyledTabSubtitle,
  StyledTabTitle,
} from './styled';

export const icons = {
  create: [StoryOff, StoryOn],
  story: [StoryOff, StoryOn],
  pitch: [PitchOff, PitchOn],
  rundown: [RundownOff, RundownOn],
  rundowntemplate: [MasterRundownOff, MasterRundownOn],
  space: [SpacesOff, SpacesOn],
};

const initialContextMenuPos = {
  mouseX: null,
  mouseY: null,
};

interface Props {
  index: number;
  width: number;
  tab: ContentTab;
  isSelected: boolean;
  notificationCount?: number;
}

const ContentTabView = ({ index, width, tab, isSelected, notificationCount }: Props) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { updateTab, closeTab } = useTabs();
  const onDropTabItem = useSetOnDropTabItem();
  const tabRef = useRef(null);
  const [dropEffect, setDropEffect] = useState(false);

  const [, dragRef] = useDrag({
    type: dndTypes.CONTENT_TAB,
    item: () => ({ index }),
  });

  const handleDropEffect = () => {
    setDropEffect(true);

    setTimeout(() => {
      setDropEffect(false);
    }, 1500);
  };

  useEffect(() => {
    if (isSelected && search.length > 1) {
      updateTab({ id: tab.id, search });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.id, isSelected, search]);

  const [{ hovered, handlerId }, dropRef] = useDrop({
    accept: [dndTypes.CONTENT_TAB, dndTypes.FEED_ITEM],
    drop: (item: { index: number }, monitor) => {
      const isFeedType = monitor.getItemType()?.valueOf() === dndTypes.FEED_ITEM;
      if (isFeedType && tab.type !== 'rundown' && tab.type !== 'space') {
        handleDropEffect();
        return { storyId: tab.id, storyType: tab.type };
      }

      if (!isFeedType) {
        onDropTabItem({ itemIndex: item.index, dropIndex: index });
      }
    },
    collect: (monitor) => {
      const isFeedType = monitor.getItemType()?.valueOf() === dndTypes.FEED_ITEM;
      const disableHover =
        (isFeedType && tab.type === 'rundown') || (isFeedType && tab.type === 'space');
      return {
        hovered: !disableHover && monitor.isOver(),
        handlerId: monitor.getHandlerId(),
      };
    },
  });

  const [contextMenuPos, setContextMenuPos] = useState<{
    mouseX: number | null;
    mouseY: number | null;
  }>(initialContextMenuPos);

  const fallbackTitle = `[Untitled ${capitalize(tab.type)}]`;
  const tabTitle = tab.title ?? fallbackTitle;
  const shortDate = tab.type === 'rundown' ? formatDateToLocaleString(tab.selectedDate) : '';

  const Icon = icons[tab.type][isSelected ? 1 : 0];

  const handleCloseIconClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      closeTab(tab);
    },
    [closeTab, tab],
  );

  const handleContextMenuClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setContextMenuPos({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  }, []);

  const handleContextMenuClose = useCallback(() => {
    setContextMenuPos(initialContextMenuPos);
  }, []);

  dragRef(dropRef(tabRef));

  return (
    <>
      <Tooltip
        title={contextMenuPos.mouseX === null && <TabToolTip title={tabTitle} />}
        noArrow
        maxWidth={256}
      >
        <StyledTabContentWrapper
          tabIndex={0}
          ref={tabRef}
          width={width}
          $tab={tab}
          $isSelected={isSelected}
          onClick={() => navigate(`/${tab.type}/${tab.id}${tab?.search ?? ''}`)}
          data-handler-id={handlerId}
          $hovered={hovered}
          onContextMenu={handleContextMenuClick}
        >
          {dropEffect && <LinearProgress right={10} position="bottom" height={1} />}
          {Boolean(notificationCount && notificationCount > 0) && (
            <NotificationWrapper>
              <NotificationIndicator notificationCount={notificationCount} />
            </NotificationWrapper>
          )}
          <StyledTabIconWrapper>
            <Icon className="skipOverride" />
          </StyledTabIconWrapper>
          <StyledLabel>
            <StyledTabTitle>{tabTitle}</StyledTabTitle>
            <StyledTabSubtitle>{shortDate}</StyledTabSubtitle>
          </StyledLabel>
          <StyledIconButton
            usage="text"
            size={24}
            iconSize={10}
            onClick={handleCloseIconClick}
            tabIndex={-1}
          >
            <CloseSmall className="skipOverride" />
          </StyledIconButton>
        </StyledTabContentWrapper>
      </Tooltip>
      {contextMenuPos.mouseX !== null && (
        <ContextMenu contextMenuPos={contextMenuPos} tab={tab} onClose={handleContextMenuClose} />
      )}
    </>
  );
};

export default ContentTabView;
