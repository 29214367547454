import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Typography } from '@material-ui/core';
import transientOptions from 'theme/helpers';

export const StyledListItem = styled('div', transientOptions)`
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  ${({ theme, $disableInteraction }) => {
    if ($disableInteraction)
      return css`
        pointer-events: none;
      `;
    return css`
      &:hover {
        background: ${theme.palette.dina.storyCardHover};
      }
      &:focus {
        background-color: ${theme.palette.dina.onFocus};
      }
    `;
  }}
`;

export const StyledTitle = styled(Typography)`
  ${({ theme }) => theme.typography.dina.overline};
`;

export const StyledContent = styled(Typography)`
  ${({ theme }) => css`
    ${theme.typography.dina.rightColumnHeaderText};
    color: ${theme.palette.dina.highEmphasis};
  `}
`;
