import { useEffect } from 'react';
import { endOfDay, startOfDay, isWithinRange } from 'date-fns';
import { useApolloClient } from '@apollo/client';

import { useSidebarDatePickerAtom } from 'store/sidebar';

import useApolloSubscription from 'hooks/useApolloSubscription';

import GET_RUNDOWNS_BY_PUBLISHING_DATE from 'graphql/queries/getRundownByPublishingDate';
import GET_RUNDOWNS from 'graphql/queries/getRundowns';
import GET_ALL_RUNDOWN_TEMPLATES from 'graphql/queries/getAllRundownTemplates';
import CREATE_RUNDOWNS_SUBSCRIPTION from 'graphql/subscriptions/createRundowns';
import memberTypes from 'graphql/memberTypes';

import useLogger from 'utils/useLogger';

const rundownAlreadyExists = (queriedRundowns, rundown) =>
  queriedRundowns.find((r) => r.mId === rundown.mId && r.mRefId === rundown.mRefId);

const useSubscribeRundownsUpdate = () => {
  const logger = useLogger('use Subscribe Rundowns Update');

  const [selectedDates] = useSidebarDatePickerAtom();

  const client = useApolloClient();

  const updateMasterRundownQuery = (masterRundown) => {
    try {
      const variables = { mId: '' };
      const { getRundowns } = client.readQuery({
        query: GET_RUNDOWNS,
        variables,
      });
      const { getAllRundownTemplates } = client.readQuery({
        query: GET_ALL_RUNDOWN_TEMPLATES,
        variables,
      });

      if (rundownAlreadyExists(getRundowns, masterRundown)) return;

      const items = [masterRundown, ...(getRundowns ?? [])];
      const templateItems = [masterRundown, ...(getAllRundownTemplates ?? [])];

      client.writeQuery({
        query: GET_RUNDOWNS,
        variables,
        data: { getRundowns: items },
      });
      client.writeQuery({
        query: GET_ALL_RUNDOWN_TEMPLATES,
        variables,
        data: { getAllRundownTemplates: templateItems },
      });
    } catch (err) {
      logger.error(err);
    }
  };

  const updateRundownQuery = (rundown) => {
    const { startDate, endDate } = selectedDates;

    const sDate = startOfDay(startDate).toISOString();
    const eDate = endOfDay(endDate).toISOString();

    if (!isWithinRange(rundown?.mPublishingAt, sDate, eDate)) {
      return;
    }

    const variables = {
      input: {
        mType: memberTypes.RUNDOWN,
        startDate: startOfDay(startDate).toISOString(),
        endDate: endOfDay(endDate).toISOString(),
      },
    };

    try {
      const { getRundownsByPublishingDate } = client.readQuery({
        query: GET_RUNDOWNS_BY_PUBLISHING_DATE,
        variables,
      });

      if (rundownAlreadyExists(getRundownsByPublishingDate, rundown)) return;

      const { recurrence } = rundown;
      delete recurrence.dailyExclusive;
      delete recurrence.startTime;

      const items = [rundown, ...getRundownsByPublishingDate];

      client.writeQuery({
        query: GET_RUNDOWNS_BY_PUBLISHING_DATE,
        variables,
        data: {
          getRundownsByPublishingDate: items,
        },
      });
    } catch (err) {
      logger.error(err);
    }
  };

  const [subscribe, unsubscribe] = useApolloSubscription(CREATE_RUNDOWNS_SUBSCRIPTION, {
    onSubscriptionData: ({ _, subscriptionData }) => {
      const rundown = subscriptionData?.data?.createRundownsSubscription;
      const { mType } = rundown || {};
      if (mType === memberTypes.RUNDOWN) updateRundownQuery(rundown);
      if (mType === memberTypes.RUNDOWN_TEMPLATE) updateMasterRundownQuery(rundown);
    },
  });

  useEffect(() => {
    subscribe();
    return () => {
      unsubscribe();
    };
  }, []);
};

export default useSubscribeRundownsUpdate;
