import React, { useState, useEffect, memo, useCallback, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import uuidv1 from 'uuid/v1';
import { Transforms } from 'slate';
import { useSelected, useSlate, ReactEditor } from 'slate-react';
import { ReactComponent as AtomOff } from 'assets/icons/systemicons/editor/atom_off.svg';
import defaultThumbnail from 'assets/images/default/defaultThumbnail.png';
import useStorageImage from 'hooks/useStorageImage';
import Dialog from 'components/dialog';
import MetadataEditor from 'components/metadataEditor';
import fieldEnums from 'utils/constants/fieldEnums';
import CreateNew from 'components/createNew';
import { menuOptions } from 'components/editor/constants';
import useGetFieldsForBlock from 'hooks/useGetFieldsForBlock';
import generateName from 'components/editor/utils/generateName';
import { elementTypes } from 'components/editor/constants/types';
import actionTypes from 'components/editor/constants/types/actionTypes';
import notifyChange from 'components/editor/utils/notifyChange';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import SelectedElement from 'components/editor/components/selectedElement';
import {
  checkIfDragDisabled,
  removeBlock,
  updateBlock,
  refreshSelection,
} from 'components/editor/utils';
import variants from 'utils/instance/variants';
import imageTypes from 'utils/constants/imageTypes';
import generatePlaceholderProps from 'utils/generatePlaceholderProps';
import useImageUpload from 'hooks/useImageUpload';
import { getThumbnailKey } from 'utils/mediaKey';
import useGetSignedUrl from 'hooks/useGetSignedUrl';
import MediaCard from 'components/mediaCard';
import useChangeCollapse from 'components/editor/hooks/useChangeCollapse';
import UploadProgress from '../uploadProgress';
import Box from '../box';
import AddMedia from '../addMedia';
import Metadata from '../metadata';
import MediaViewer from '../mediaViewer';
import MediaDropZone from './mediaDropZone';
import { ImageIcon, MediaWrapper } from './styled';
import DragAndDrop from '../dragAndDrop';

const { select, setNodes } = Transforms;

const Image = ({ attributes, children, element, readOnly, direction }) => {
  const fileRef = useRef();
  const [getFieldsForBlock] = useGetFieldsForBlock();
  const editor = useSlate();
  const {
    containerRef,
    update,
    isAllowed,
    platformId,
    variant,
    getPlaceholderConfig,
    withSignedUrl,
    formsForThisPlatform,
  } = useEditorContext();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [asset, setAsset] = useState(null);
  const [onChangeCollapse] = useChangeCollapse(element);

  const field = getFieldsForBlock(fieldEnums.CUSTOM_SOURCES, { options: [] });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sourceOptions = useMemo(() => field?.options || [], []);

  /** Image data fetch */
  const { data, type } = element;

  const { mId, mRefId, src, cache, proxy, metadata, itemId, collapsed, title: imageTitle } = data;

  const { showMetadata, title, caption, source, isCoverphoto, photographer, coverOnly } =
    metadata || {};

  const cacheRef = useRef(cache || null);
  const key = src || getThumbnailKey(mId, mRefId);
  const { loading } = useGetSignedUrl(key, !key || cacheRef.current);

  const loadThumbnail = !src || key;
  const {
    data: thumbnailData,
    error: storageError,
    loading: storageLoading,
  } = useStorageImage(key, !loadThumbnail);
  const s3ThumbUrl = storageError && !storageLoading ? defaultThumbnail : thumbnailData;
  const imageSrc = cacheRef.current || s3ThumbUrl || proxy;

  const isSelected = useSelected(element);
  const showHighlight = isSelected;
  const [boxType, setBoxType] = useState('media');
  useEffect(() => {
    if (isCoverphoto) setBoxType('iscoverphoto');
    else setBoxType('media');
  }, [isCoverphoto]);

  const placeholderConfigs = (dialogOpen && getPlaceholderConfig()) || {};

  const removeCacheFromSlateData = useCallback(() => {
    if (cache) {
      const path = ReactEditor.findPath(editor, element);

      Transforms.setNodes(editor, { data: { ...element.data, cache: undefined } }, { at: path });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(removeCacheFromSlateData, []);

  const forceEditorFocus = useCallback(() => {
    ReactEditor.focus(editor);
  }, [editor]);

  /** Dialog */
  const { selection } = editor;
  const selectionCacheRef = useRef(selection);

  const closeDialog = useCallback(() => setDialogOpen(false), []);
  const openDialog = useCallback(() => setDialogOpen(true), []);

  const onImageLoad = useCallback(
    async (file, fileUrl) => {
      const fileName = generateName(file.type);
      cacheRef.current = fileUrl;
      if (withSignedUrl) fileRef.current = file;

      let updatedData = withSignedUrl
        ? {
            ...element.data,
            title: fileName,
          }
        : {};

      if (!withSignedUrl) {
        const result = await update({
          type: actionTypes.ASSET_INSERT,
          payload: { document: editor.children, file, fileName },
        });

        updatedData = {
          ...element.data,
          ...result,
          title: fileName,
        };
      }
      const path = ReactEditor.findPath(editor, element);
      setNodes(editor, { data: updatedData }, { at: path });
      notifyChange(editor, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [withSignedUrl, element],
  );

  const updateEditorOnUpload = useCallback(
    (result) => {
      const updatedData = {
        ...element.data,
        ...result,
      };
      const path = ReactEditor.findPath(editor, element);
      setNodes(editor, { data: updatedData }, { at: path });
    },
    [element, editor],
  );

  const uploadImage = useImageUpload({
    disableResize: true,
    imageTypes,
    onImageLoad,
  });

  const handleOpenDialog = useCallback(
    (event) => {
      event.preventDefault();

      selectionCacheRef.current = selection;
      openDialog();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selection],
  );

  const handleCreatePlaceholder = useCallback(
    async (mTitle) => {
      closeDialog();

      try {
        if (selectionCacheRef.current) select(editor, selectionCacheRef.current);

        const result = await update({
          type: actionTypes.CREATE_PLACEHOLDER,
          payload: { document: editor.children, title: mTitle, itemType: 'image' },
        });

        const updatedData = {
          ...result,
          mTitle,
          itemType: 'image',
          mediaType: 'image/placeholder',
          showThumbnail: false,
          itemId: uuidv1(),
          metadata,
        };

        const path = ReactEditor.findPath(editor, element);
        setNodes(editor, { data: updatedData, type: elementTypes.PLACEHOLDER }, { at: path });
        notifyChange(editor, update);
      } catch (error) {
        // logger.log(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  const handleCancel = useCallback(() => {
    if (selectionCacheRef.current) select(editor, selectionCacheRef.current);
    closeDialog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** to be discussed and implemented later */
  const removeImage = useCallback(
    (event) => {
      event.preventDefault();
      updateBlock(editor, element, { itemId }, update);
      cacheRef.current = null;
      notifyChange(editor, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  const updateMetadata = useCallback(
    (newValue, metaPropName) => {
      let updatedMetadata = metadata;
      /** If isCoverphoto meta value is false, we will remove the key coverOnly from the metadata */
      if (metaPropName === 'isCoverphoto' && !newValue) {
        updatedMetadata = Object.keys(metadata)
          .filter((k) => k !== 'coverOnly')
          .reduce((prev, curr) => ({ ...prev, [curr]: metadata[curr] }), {});
      }

      const updatedData = {
        ...data,
        metadata: {
          ...updatedMetadata,
          [metaPropName]: newValue,
        },
      };

      const allAttributes =
        (metaPropName === 'isCoverphoto' || metaPropName === 'coverOnly') && newValue === true
          ? { key: metaPropName, value: false }
          : null;

      updateBlock(editor, element, updatedData, update, undefined, allAttributes);
    },
    [data, editor, element, metadata, update],
  );

  const onMenuSelect = useCallback(
    ({ action }) => {
      if (action === 'delete-block') removeBlock(editor, element, update);
      if (action === 'show-metadata') {
        updateMetadata(!showMetadata, 'showMetadata');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showMetadata, updateMetadata],
  );

  const onBlur = useCallback(() => {
    refreshSelection(editor, element);
  }, [editor, element]);

  const isCmsVariant = variant === variants.CMS;
  const blockForm = formsForThisPlatform?.[type];

  const renderMetaData = useMemo(() => {
    const titleProps = {
      value: title || '',
      placeholder: 'Add Title',
      description: 'Add a Title to the photo',
      propName: 'title',
    };

    const sourceProps = {
      value: source || [],
      placeholder: 'Start typing to find Source',
      options: sourceOptions,
      propName: 'source',
    };

    const inputProps = {
      value: photographer || '',
      placeholder: 'Add Photographer',
      description: 'Who is the photographer?',
      propName: 'photographer',
    };

    const textareaProps = {
      value: caption || '',
      placeholder: 'Add Caption',
      description: 'Describe the photo',
      propName: 'caption',
    };

    return (
      <>
        <Metadata
          {...{
            sourceProps,
            titleProps,
            textareaProps,
            inputProps,
            isCmsVariant,
            updateMetadata,
            isCoverphoto,
            coverOnly,
            direction,
          }}
        />
        {blockForm && (
          <MetadataEditor
            model={blockForm}
            setPayload={(u) => updateMetadata(u.value, u.fieldId)}
            payload={metadata}
          />
        )}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    caption,
    isCoverphoto,
    photographer,
    source,
    sourceOptions,
    title,
    updateMetadata,
    blockForm,
  ]);

  const renderAddMedia = useMemo(
    () => (
      <AddMedia
        addMedia={uploadImage}
        removeMedia={removeImage}
        createPlaceholder={handleOpenDialog}
        mediaSrc={imageSrc}
        mediaType="image"
        mediaWidth={156}
        {...{ withSignedUrl }}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [imageSrc, removeImage, withSignedUrl],
  );

  const renderContent = useMemo(
    () => {
      const extendedOptions = [
        {
          title: `${showMetadata ? 'Hide' : 'Show'} Metadata`,
          action: 'show-metadata',
          icon: <AtomOff />,
          divider: true,
        },
      ];

      const menuItems = [...extendedOptions, ...menuOptions];

      return (
        <MediaDropZone {...{ element }}>
          {children}
          <Box
            iconComponent={<ImageIcon className="skipOverride" />}
            title="Image"
            readOnly={readOnly}
            menuItems={menuItems}
            onMenuSelect={onMenuSelect}
            type={boxType}
            boxType={type}
            updateCollapsed={onChangeCollapse}
            collapsed={Boolean(collapsed)}
            collapsedContent={imageTitle}
            onBlur={onBlur}
            platformId={platformId}
          >
            {renderAddMedia}

            {withSignedUrl && fileRef.current && cacheRef.current !== null && (
              <UploadProgress {...{ fileRef, updateEditorOnUpload }} />
            )}

            {showMetadata && renderMetaData}
          </Box>
        </MediaDropZone>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      boxType,
      children,
      collapsed,
      element,
      imageTitle,
      onMenuSelect,
      readOnly,
      renderAddMedia,
      renderMetaData,
      showMetadata,
      onChangeCollapse,
      withSignedUrl,
    ],
  );

  const renderContentWithoutBlock = useMemo(
    () => (
      <SelectedElement {...{ element }}>
        <div {...attributes}>
          {children}
          <MediaWrapper contentEditable={false}>
            <MediaViewer
              handleClose={() => setAsset(null)}
              open={!!asset}
              asset={asset}
              accessToken=""
            />
            <MediaCard
              showHighlight={showHighlight}
              key={imageSrc}
              onFocus={forceEditorFocus}
              onAssetSet={(e) => {
                e.stopPropagation();
                setAsset({
                  type: elementTypes.IMAGE,
                  filename: 'Image File',
                  previewUri: loading ? defaultThumbnail : imageSrc,
                });
              }}
              thumbnailSrc={loading ? defaultThumbnail : imageSrc}
              type={elementTypes.IMAGE}
              filename="Image File"
              byline="Media Details"
            />
          </MediaWrapper>
        </div>
      </SelectedElement>
    ),
    [asset, attributes, children, element, forceEditorFocus, imageSrc, loading, showHighlight],
  );

  if (!isAllowed) return renderContentWithoutBlock;

  return (
    <div {...attributes}>
      <DragAndDrop element={element} isDragDisabled={checkIfDragDisabled(variant)}>
        {renderContent}
      </DragAndDrop>
      {dialogOpen && (
        <Dialog container={containerRef.current} open={dialogOpen} onClose={handleCancel}>
          <CreateNew
            variant="placeholder"
            onCreate={handleCreatePlaceholder}
            onCancel={handleCancel}
            placeholderNameConfigs={generatePlaceholderProps(placeholderConfigs)}
          />
        </Dialog>
      )}
    </div>
  );
};

Image.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

Image.defaultProps = {
  attributes: {},
  children: null,
  element: {
    children: [],
    data: { src: '' },
    type: elementTypes.IMAGE,
  },
};
export default memo(Image);
