import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    background: 'transparent',
    maxWidth: ({ size }) => (size === 'large' ? 68 : 50),
    border: 'none',
    outline: 'none',
    padding: 0,
    ...theme.typography.dina.listItemLabelMedium,
    lineHeight: '16px',
    letterSpacing: '0.25px',
    color: ({ error, customClipStyle }) => {
      if (error) return theme.palette.dina.errorText;
      if (customClipStyle) return theme.palette.dina.isFixed;
      return theme.palette.dina.highEmphasis;
    },
    fontWeight: ({ customClipStyle }) => {
      if (customClipStyle) return 'bold';
      return null;
    },
    '&:focus': {
      fontStyle: 'italic',
    },
  },
}));

export default useStyles;
