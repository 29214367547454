import { LinearPlatform, Platform } from 'types';

export const defaultLinearPlatformKind = undefined;

export const linearPlatformKinds = [defaultLinearPlatformKind, 'audio'];

export const getPlatform = (
  platforms: (Platform | LinearPlatform)[],
  platformName: string,
  platformKind?: string,
) => {
  const platformsByType = platforms.filter((p) => p?.mProperties?.platform === platformName);
  if (platformsByType.length === 1) return platformsByType[0];
  return platformsByType.find(
    (p) => p?.mProperties?.platformKind === (platformKind || defaultLinearPlatformKind),
  );
};
