import Divider from 'components/divider';
import LockedIndicator from 'components/lockedIndicator';
import Tooltip from 'components/tooltip';
import OrderButton from 'features/orderForm/components/OrderButton';
import { useCanSeeOrderManagement } from 'hooks/useCheckUserRight';
import { HStack } from 'layouts/box/Box';

import useInstanceCore from '../hooks/useInstanceCore';
import useInstanceMetadata from '../hooks/useInstanceMetadata';
import useInstancePermissions from '../hooks/useInstancePermissions';
import useInstanceViewUtils from '../hooks/useInstanceViewUtils';
import { useInstanceMolecule } from '../store/instance';
import { FooterTop, FooterWrapper, StyledFooterText } from '../styled';

import InfoSummary from './infoSummary/InfoSummary';
import InstanceLink from './instanceLink';
import StatusSelector from './statusSelector';

const Footer = () => {
  const {
    useDisableEdit,
    useInstanceValue,
    usePublishingPointValue,
    useReadLock,
    useWriteLock,
    useLockedBy,
    useIsCancelled,
    useIsSavingInstance,
    useDurations,
  } = useInstanceMolecule();
  const {
    isLinearInstance,
    isTwitterInstance,
    footerMessage,
    backgroundColor,
    opacity,
    variant,
    accountIdentifier,
  } = useInstanceViewUtils();
  const {
    clipAutoDurationField,
    scriptDurationField,
    autoClipDurationSettingsValue,
    canUpdateScriptDurationSettingsValue,
  } = useInstanceMetadata();
  const {
    handleClipDurationChange,
    handleScriptDurationChange,
    twitterThreadCount,
    releaseWriteLock,
    handleCancel,
    onInstanceChanged,
    onForceUnlock,
  } = useInstanceCore();
  const { canShowNewDesign, canUpdateInstance } = useInstancePermissions();
  const { canAccessOrderManagement } = useCanSeeOrderManagement();

  const [disableEdit] = useDisableEdit();
  const instance = useInstanceValue();
  const publishingPoint = usePublishingPointValue();
  const [readLock] = useReadLock();
  const [writeLock] = useWriteLock();
  const [lockedBy] = useLockedBy();
  const [isSaving] = useIsSavingInstance();
  const [isCancelled] = useIsCancelled();
  const [durations] = useDurations();

  return (
    <FooterWrapper $backgroundColor={backgroundColor} $opacity={opacity} alignItems="flex-start">
      <FooterTop
        justifyContent="space-between"
        alignItems="center"
        flexGrow={1}
        $backgroundColor={backgroundColor}
        gap="0.5rem"
      >
        <HStack gap="0.5rem">
          {isLinearInstance && (
            <>
              <InfoSummary
                disabled={disableEdit}
                duration={durations.clip}
                manualDurationField={clipAutoDurationField}
                title="Dur"
                onUpdate={handleClipDurationChange}
                manualDurationSettingsValue={autoClipDurationSettingsValue}
              />

              <InfoSummary
                disabled={!canUpdateScriptDurationSettingsValue || disableEdit}
                duration={durations.script}
                manualDurationField={scriptDurationField}
                title="Script"
                onUpdate={handleScriptDurationChange}
                manualDurationSettingsValue={canUpdateScriptDurationSettingsValue}
              />

              <InfoSummary disabled duration={durations.total} title="Total" />
            </>
          )}
          {canShowNewDesign && isTwitterInstance && (
            <InfoSummary disabled duration={String(twitterThreadCount)} title="Threads" />
          )}
          <StyledFooterText variant="captionMedium" title={footerMessage}>
            {footerMessage}
          </StyledFooterText>
        </HStack>
        <HStack gap="0.5rem" minWidth="min-content">
          {canAccessOrderManagement && instance && (
            <OrderButton
              resourceId={instance?.mId}
              resourceTitle={instance?.mTitle}
              resourceType="instance"
              formTypes={['instance', publishingPoint]}
            />
          )}
          <Tooltip title="Link instance" noArrow>
            <InstanceLink
              linkedInstances={instance?.mRelatedMembers || []}
              instance={instance}
              isLinkingDisabled={!canUpdateInstance}
            />
          </Tooltip>
          <Divider orientation="vertical" flexItem />
          <StatusSelector
            variant={variant}
            statusId={instance?.mState}
            publishingPoint={publishingPoint}
            accountIdentifier={accountIdentifier}
            disableEdit={disableEdit}
            onChange={(status: string) => onInstanceChanged({ changeType: 'status', status })}
          />
        </HStack>
      </FooterTop>
      <LockedIndicator
        readLock={readLock}
        writeLock={writeLock}
        lockedBy={lockedBy}
        onDone={releaseWriteLock}
        onCancel={handleCancel}
        isSaving={isSaving}
        isCancelled={isCancelled}
        lockedId={instance?.locked}
        onForceUnlock={onForceUnlock}
      />
    </FooterWrapper>
  );
};

export default Footer;
