import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Avatar from 'components/avatar/Avatar';

const AvatarCellWrapper = styled('div')`
  display: flex;
  min-width: 325px;
  align-items: center;
  > :first-of-type {
    margin-right: 8px;
  }
`;

const AvatarText = styled('span')`
  ${({ theme }) => theme.typography.dina.listItemLabel}
  font-size: 10px;
`;

const AvatarCell = ({ mTitle, mAvatarUrl }) => (
  <AvatarCellWrapper>
    <Avatar src={mAvatarUrl} size={24} title={mTitle} />
    {mTitle}
  </AvatarCellWrapper>
);

AvatarCell.propTypes = {
  /** text in the cell */
  mTitle: PropTypes.string,
  /** avatar image */
  mAvatarUrl: PropTypes.string,
};

AvatarCell.defaultProps = {
  mTitle: '',
  mAvatarUrl: '',
};

export default AvatarCell;
