import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';

import { Button } from 'components/buttons';
import Divider from 'components/divider/divider-view';
import InputField from 'components/inputField/InputField';
import TextArea from 'components/textArea/TextArea';

const useStyles = makeStyles((theme) => ({
  textRoot: {
    ...theme.typography.dina.formTitle,
    marginBottom: '19px',
    paddingTop: '24px',
  },
  inputContainer: {
    width: '279px',
    margin: 'auto',
  },
  inputDiv: {
    marginBottom: '18px',
  },
  textAreaDiv: {
    marginBottom: '17px',
  },
  formContainer: {
    width: '309px',
  },
  buttonContainer: {
    marginTop: '8px',
    padding: '0 8px 8px 8px',
    display: 'flex',
    justifyContent: 'space-around',
    gap: '8px',
  },
}));

const CreateDepartment = ({ onSubmit, onCancel }) => {
  const classes = useStyles();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [preventSubmission, setPreventSubmission] = useState(false);

  const submitForm = (e) => {
    e.preventDefault();
    if (title && !preventSubmission) {
      setPreventSubmission(true);
      onSubmit(title, description);
    }
  };

  return (
    <form className={classes.formContainer} onSubmit={submitForm}>
      <div className={classes.inputContainer}>
        <Typography classes={{ root: classes.textRoot }}>Create New Department</Typography>
        <div className={classes.inputDiv}>
          <InputField
            label="Department Name"
            description="The name of the department"
            value={title}
            severity="mandatory"
            onChange={setTitle}
            autoFocus
          />
        </div>
        <div className={classes.textAreaDiv}>
          <TextArea
            label="Description"
            type="descriptionField"
            value={description}
            optionalLabel="Description"
            onChange={setDescription}
            description="Short description of the department"
            rows="4"
          />
        </div>
      </div>
      <Divider />
      <div className={classes.buttonContainer}>
        <Button variant="discreet" usage="text" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          usage="significant"
          disabled={preventSubmission || !title}
          onClick={submitForm}
        >
          Create Department
        </Button>
      </div>
    </form>
  );
};

CreateDepartment.propTypes = {
  /** Cancel button */
  onCancel: PropTypes.func,
  /** Create button */
  onSubmit: PropTypes.func,
};

CreateDepartment.defaultProps = {
  onCancel: () => {},
  onSubmit: () => {},
};

export default CreateDepartment;
