import { useCallback, useEffect, useState } from 'react';

import NOTIFY_MEMBER_UPDATE_SUBSCRIPTION from 'graphql/subscriptions/notifyMemberUpdate';
import useApolloSubscription from 'hooks/useApolloSubscription';
import useBatchGetMembers from 'hooks/useBatchGetRundownInstances';

interface LoadInstancesProps {
  rundownId: string;
}

export default function useLoadInstances({ rundownId }: LoadInstancesProps) {
  const [loadItems] = useBatchGetMembers();
  const [isLoading, setLoading] = useState(true);

  // Subscribe
  const [subscribe, unSubscribe] = useApolloSubscription(NOTIFY_MEMBER_UPDATE_SUBSCRIPTION, {
    variables: {
      mIdSubscribed: rundownId,
    },
    skip: !rundownId,
    source: 'useLoadInstances',
  });

  // Load instances by id. Populates the cache.
  const loadInstances = useCallback(
    async (instanceIds: string[]) => {
      setLoading(true);
      await loadItems(instanceIds);
      setLoading(false);
    },
    [loadItems],
  );

  // Subscribe on rundownId change
  useEffect(() => {
    subscribe();
    return () => {
      unSubscribe();
    };
  }, [rundownId]);

  return { loadInstances, isLoading };
}
