import { useId, useMemo } from 'react';
import styled from '@emotion/styled';
import { keyBy } from 'lodash';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import { useGetOrderForms } from 'api/order_forms/useGetOrderForms';
import { ChoiceField } from 'components/mdfEditor/fields/choice/ChoiceField';
import { FieldTypeEnum, LayoutSettings, MdfField } from 'types/graphqlTypes';
import { OrderFormMemberType } from 'types/memberTypes/order_form';

interface Props {
  onSelect: (form: OrderFormMemberType | null) => void;
  selectedOrderForm: OrderFormMemberType | null;
}

const layoutSettingsType: LayoutSettings = {
  visible: true,
  fieldId: 'form',
  hint: '',
  label: '',
};

const StyledDiv = styled('div')`
  width: 100%;
`;

function OrderFormDropdown({ onSelect, selectedOrderForm }: Readonly<Props>) {
  const uniqueId = useId();
  const { orderForms } = useGetOrderForms();
  const { mdfs } = useGetMdfs({ all: true });
  const mdfMap = useMemo(() => {
    return keyBy(mdfs, (mdf) => mdf.id);
  }, [mdfs]);
  const orderMap = useMemo(() => {
    const orderFormMap: Record<string, OrderFormMemberType> = {};
    orderForms.forEach((o) => {
      if (o.mSecId && mdfMap[o.mSecId]) {
        orderFormMap[o.mRefId] = o;
      }
    });
    return orderFormMap;
  }, [orderForms, mdfMap]);

  const fieldModelTypes: MdfField = {
    fieldId: 'form',
    type: FieldTypeEnum.choice,
    defaultValue: { value: null },
    alternatives: orderForms.map((o) => {
      return {
        id: o.mRefId,
        value: o.mRefId,
        label: o.mDescription,
      };
    }),
  };
  return (
    <StyledDiv>
      <ChoiceField
        editorId={uniqueId}
        fieldModel={fieldModelTypes}
        fieldSettings={null}
        defaultFieldSettings={layoutSettingsType}
        value={selectedOrderForm?.mRefId ?? ''}
        setValue={(val) => onSelect(orderMap[val as string])}
        errorMessage={undefined}
        setError={() => {}}
        style={{}}
      />
    </StyledDiv>
  );
}

export default OrderFormDropdown;
