import { useState } from 'react';

import { ReactComponent as FeedIcon } from 'assets/icons/search/feed.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search/search.svg';
import { ReactComponent as AssetsIcon } from 'assets/icons/systemicons/ContainerRight_Navbar/storybox_off.svg';
import { ReactComponent as LinkIcon } from 'assets/icons/systemicons/internet.svg';
import { ReactComponent as KanbanIcon } from 'assets/icons/systemicons/kanban.svg';
import { ReactComponent as GridIcon } from 'assets/icons/systemicons/list.svg';
import { ReactComponent as Maximize } from 'assets/icons/systemicons/maximize.svg';
import { ReactComponent as Minimize } from 'assets/icons/systemicons/minimize.svg';
import { ReactComponent as PluginIcon } from 'assets/icons/systemicons/plugin_off.svg';
import { ReactComponent as TaskIcon } from 'assets/icons/systemicons/tasks.svg';
import { WIDGETS } from 'features/widget/constants/index';

const widgetIconMap = {
  [WIDGETS.FEED]: FeedIcon,
  [WIDGETS.ASSETS]: AssetsIcon,
  [WIDGETS.MIMIR]: AssetsIcon,
  [WIDGETS.SEARCH]: SearchIcon,
  [WIDGETS.TASKS]: TaskIcon,
  [WIDGETS.KANBAN]: KanbanIcon,
  [WIDGETS.STORYGRID]: GridIcon,
  [WIDGETS.IFRAME]: LinkIcon,
  [WIDGETS.SEARCH_PLUGIN]: PluginIcon,
};

export const useSelectIcon = (type: WIDGETS, isMinimized: boolean = false) => {
  const [isHovered, setIsHovered] = useState(false);
  const MinMaxIcon = isMinimized ? Maximize : Minimize;

  const Icon = isHovered ? MinMaxIcon : widgetIconMap[type];

  return { Icon, isHovered, setIsHovered };
};
