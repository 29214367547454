/* eslint-disable sort-imports */
// eslint-disable-next-line sort-imports
import { isEmpty } from 'lodash';

import { isDateRange } from 'api/search/useSearch';
import { getFieldKey, getFieldMap } from 'features/orderForm/utils';
import { AssignedMember } from 'types';
import { FieldValue, Metadata } from 'types/forms/forms';
import {
  DateRangeQL,
  FieldTypeEnum,
  LayoutSettings,
  Mdf,
  MdfField,
  MemberType,
  MMetaDataField,
  RangeBy,
} from 'types/graphqlTypes';
import { isoToLocaleShort } from 'utils/datetimeHelpers';

import {
  CommandToolbarProps,
  CSVDetails,
  CSVHeader,
  CSVMemberType,
  isAccountType,
} from './command-types';

export const getMetadataValue = (metadata: MMetaDataField[] | null | undefined, key: string) => {
  if (!metadata) return '';
  return metadata.find((kv) => kv.key === key)?.value ?? '';
};

/**
 * CMDK will put all content in the data attribute
 * If there are quotes or newlines in there, dina crashes.
 */
export const safeForHTML = (html: string) => {
  return html?.replace(/['"]+/g, '');
};

export const getAccountTitle = (item: MemberType) => {
  if (isAccountType(item.mProperties?.account)) {
    return item.mProperties?.account.accountTitle;
  }
  return '';
};

export const getDateRange = (
  val: RangeBy | null,
): { dateRange: DateRangeQL; key: keyof RangeBy; label: string } | null => {
  if (!val) return null;
  if (val.createdAt) return { dateRange: val.createdAt, key: 'createdAt', label: 'Created' };
  if (val.publishingAt)
    return { dateRange: val.publishingAt, key: 'publishingAt', label: 'Sched.' };
  if (val.updatedAt) return { dateRange: val.updatedAt, key: 'updatedAt', label: 'Upd.' };
  return null;
};

export const isFiltering = (state: CommandToolbarProps): boolean => {
  if (state.sortBy !== 'best') return true;
  if (state.rangeBy) return true;
  if (state.statusFilter.length > 0) return true;
  if (state.assignedIds.length > 0 || state.createdByIds.length > 0) return true;
  if (state.mTypes.length > 0) {
    return true;
  }
  return false;
};

export const getPrettyValue = (
  value: FieldValue,
  allMembersKeyed: Record<string, AssignedMember>,
  field:
    | (MdfField & {
        formId: string;
        settings: LayoutSettings;
      })
    | undefined,
) => {
  if (isDateRange(value)) {
    return `${isoToLocaleShort(value.startDate)} - ${isoToLocaleShort(value.endDate)}`;
  }
  if (Array.isArray(value)) {
    return field?.type === FieldTypeEnum.multiplechoice ? value.join(', ') : value.join(' ▸ ');
  }

  if (field?.type === FieldTypeEnum.user && typeof value === 'string') {
    const member = allMembersKeyed[value];
    if (member) {
      return member;
    }
  }

  return `${value}`;
};

const defaultHeaders = [
  {
    label: 'Id',
    key: 'id',
  },
  {
    label: 'Title',
    key: 'title',
  },
  {
    label: 'Description',
    key: 'description',
  },
  {
    label: 'Created',
    key: 'createdAt',
  },
  {
    label: 'Updated',
    key: 'updatedAt',
  },
  {
    label: 'Created by',
    key: 'createdBy',
  },
  {
    label: 'Assigned to',
    key: 'assignees',
  },
  {
    label: 'Status',
    key: 'status',
  },
];

export const generateCSVFromMembers = (items: MemberType[], mdfs: Mdf[]): CSVDetails => {
  const fieldMap = getFieldMap(mdfs, 'default');
  const headers: CSVHeader[] = [...defaultHeaders];
  const data: CSVMemberType[] = [];

  const addedCustomHeaders: Record<string, true> = {};

  items.forEach((item) => {
    const metadata: Metadata = JSON.parse(item?.metadata ?? '{}') as Metadata;
    const mdfId = item?.mdfId;
    const dataItem: CSVMemberType = {
      id: item.mId ?? '',
      title: item.mTitle ?? '',
      description: item.mDescription ?? '',
      createdAt: item.mCreatedAt ?? '',
      updatedAt: item.mUpdatedAt ?? '',
      createdBy: item.mCreatedById ?? '',
      assignees: item.mAssignedMembers?.map((a) => a.mId).join(', ') ?? '',
      status: item.mState ?? '',
    };
    if (!isEmpty(metadata) && mdfId) {
      Object.entries(metadata).forEach(([key, value]) => {
        const field = fieldMap[`${getFieldKey(key, mdfId)}`];
        dataItem[key] = value;
        if (!addedCustomHeaders[key]) {
          headers.push({
            key,
            label: field?.settings.label ?? key,
          });
          addedCustomHeaders[key] = true;
        }
      });
    }

    data.push(dataItem);
  });

  return {
    headers,
    data,
  };
};
