import { useEffect, useState } from 'react';

import Dialog from 'components/dialogs/DialogBuilder';
import InputField from 'components/inputField/InputField';
import { programmaticIdRegex } from 'screens/main/components/header/navbar/settings/components/integrations/EditActions';
import { MdfField } from 'types/graphqlTypes';

import { createNewField } from '../utils';

interface Props {
  onNewField: (field: MdfField) => void;
  fields: MdfField[];
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function NewFieldDialog({ onNewField, fields, open, setOpen }: Readonly<Props>) {
  const [id, setId] = useState('');
  const [severity, setSeverity] = useState('');
  const [description, setDescription] = useState('');

  const existingFieldIds = fields.map((f) => f.fieldId);

  const onCreate = () => {
    const field = createNewField();
    field.fieldId = id;
    onNewField(field);
    setOpen(false);
  };

  useEffect(() => {
    if (!open) {
      setId('');
    }
  }, [open, setId]);

  useEffect(() => {
    if (!id) {
      setSeverity('mandatory');
      setDescription('Id is required');
    } else if (existingFieldIds.includes(id)) {
      setSeverity('error');
      setDescription('Id already exists');
    } else if (!programmaticIdRegex.test(id)) {
      setSeverity('error');
      setDescription('Only alphanumeric characters allowed');
    } else {
      setSeverity('');
      setDescription("Programmatic id - Can't be changed after creating the field");
    }
  }, [id, existingFieldIds]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <Dialog.Header>Create new field</Dialog.Header>
      <Dialog.Body>
        <InputField
          severity={severity}
          placeholder="Enter a unique id"
          description={description}
          value={id}
          disableLabel
          usage="editor"
          hideEndAdornment
          onChange={setId}
        />
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton />
        <Dialog.ConfirmButton label="Create" onClick={onCreate} />
      </Dialog.Footer>
    </Dialog>
  );
}

export default NewFieldDialog;
