import gql from 'graphql-tag';

export const CREATE_DAILY_NOTE = gql`
  mutation CreateNote($input: DailyNoteInput) {
    createDailyNote(input: $input) {
      mId
      mRefId
      mType
      mTitle
      locked
      mContentKey
      mUpdatedAt
      mCreatedAt
      mDescription
      mAvatarUrl
      mAvatarKey
      mProperties {
        __typename
      }
      mOrder
    }
  }
`;

export const UPDATE_DAILY_NOTE = gql`
  mutation updateDailyNote($input: DailyNoteInput) {
    updateDailyNote(input: $input) {
      mId
      mRefId
      mType
      mTitle
      locked
      mContentKey
      mUpdatedAt
      mCreatedAt
      mDescription
      mAvatarUrl
      mAvatarKey
      mProperties {
        __typename
      }
      mOrder
    }
  }
`;
