/* eslint-disable no-param-reassign */
import voidTypes from 'components/editor/constants/types/voidTypes';

const voidTypeValues = Object.values(voidTypes);

/**
 * Wraps editor with overriden void plugin functionalites
 *
 * @param {Object} editor SlateJS editor instance
 * @returns {Object} SlateJS editor instance
 */

const withVoid = (editor) => {
  const { isVoid } = editor;

  editor.isVoid = (element) => voidTypeValues.includes(element.type) || isVoid(element);

  return editor;
};

export default withVoid;
