import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Button } from 'components/buttons';

export const CalenderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledButton = styled(Button)<{ $dark?: boolean }>`
  ${({ $dark, theme }) =>
    $dark
      ? css`
          border-color: ${theme.palette.dina.whiteButtonBorderOutlined};
          :hover {
            border-color: ${theme.palette.dina.whiteButtonBorderOutlined};
            background-color: ${theme.palette.dina.whiteHoverOverlay};
          }
          svg {
            path {
              fill: ${theme.palette.dina.whiteMediumEmphasis};
            }
          }
        `
      : ''}
`;

StyledButton.defaultProps = {
  variant: 'outlined',
  usage: 'text',
  height: 32,
};
