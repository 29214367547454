import { memo, useContext, useEffect, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { DocumentNode, useQuery } from '@apollo/client';
import { ScopeProvider } from 'jotai-molecules';
import isEqual from 'lodash/isEqual';

import DebouncedLoadingIndicator from 'components/debouncedLoadingIndicator/DebouncedLoadingIndicator';
import FallbackComponent from 'components/fallbackComponent';
import Infobar from 'components/infobar/Infobar';
import UserContext from 'contexts/UserContext';
import memberTypes from 'graphql/memberTypes/memberTypes';
import GET_STORY from 'graphql/queries/getStory';
import { getMemberQuery } from 'graphql/queryVariables';
import NOTIFY_MEMBER_UPDATE_SUBSCRIPTION from 'graphql/subscriptions/notifyMemberUpdate';
import updateCacheWithTransaction from 'graphql/utils/cache/notification/transaction/updateCacheWithTransaction';
import useApolloSubscription from 'hooks/useApolloSubscription';
import useGetPlatforms from 'hooks/useGetPlatforms';
import useTabs from 'hooks/useTabs';
import { Flex } from 'layouts/box/Box';
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from 'lib/resize';
import { useCurrentTabValue, useToolbar } from 'store';
import { useMimirLoaded } from 'store/mimir';
import { Story } from 'types';

import { StoryScope, useStoryMolecule } from './store/story';
import { PaneScope } from './store/storyPane';
import Toolbar from './toolbar/Toolbar';
import { storyTypesSet } from './utils/typeSets';
import Description from './Description';
import StoryTabs from './StoryTabs';
import Timeline from './Timeline';
import usePanes from './useStoryPanes';

import { Container, TabContainer } from './styled';

interface ApolloClientType {
  query: (props: {
    query: DocumentNode;
    variables: { input: Record<string, string> };
    fetchPolicy: string;
  }) => void;
}

interface SubscirptionData {
  data: {
    notifyMemberUpdateSubscription: Story;
  };
}

interface StoryViewProps {
  storyId?: string;
}

const StoryComponent = ({ storyId: storyIdFromProp }: StoryViewProps) => {
  const { updateTab } = useTabs();
  const currentTab = useCurrentTabValue();
  const storyId = storyIdFromProp ?? currentTab?.id ?? '';

  const { mId: userId } = useContext(UserContext);
  const [, setToolbar] = useToolbar();

  const {
    useStory,
    usePlatforms,
    useSetSyncProvider,
    useCanUpdateStoryValue,
    useIsPitchValue,
    useIsDescriptionHiddenSettingsValue,
  } = useStoryMolecule();

  const [story, setStory] = useStory();
  const isPitch = useIsPitchValue();
  const canUpdateStory = useCanUpdateStoryValue();
  const isDescriptionSettingsHidden = useIsDescriptionHiddenSettingsValue();
  const [platformsAtomValue, setPlatformsAtomValue] = usePlatforms();
  const [mimirLoaded] = useMimirLoaded();
  const setSyncProviders = useSetSyncProvider();
  const { storyPanes } = usePanes();

  const [resourcesLoading, setResourcesLoading] = useState(true);

  const {
    data: storyData,
    error: storyError,
    loading: storyLoading,
  } = useQuery<{ getStory: Story }>(GET_STORY, {
    variables: getMemberQuery(storyId),
    fetchPolicy: 'cache-and-network',
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const thumbnail = useMemo(() => storyData?.getStory?.mAvatarUrl ?? '', [storyId]);
  const [platforms, platformsError, platformsLoading] = useGetPlatforms(new Date());

  const storyLockReleasedEvent = (item: Story) => {
    if (!item) return false;
    const { mType, locked } = item;
    return (
      [
        memberTypes.STORY,
        memberTypes.RESTRICTED_STORY,
        memberTypes.PITCH,
        memberTypes.RESTRICTED_PITCH,
      ].includes(mType) && locked === null
    );
  };

  const shouldRefetchStory = (oldData: Story, item: Story) => {
    if (oldData.mType !== item.mType) return true;

    return !!(
      item.mType.startsWith('res_') && !item.mAssignedMembers?.find((m) => m.mId === userId)
    );
  };

  const updateStoryInCache = (client: ApolloClientType, item: Story) => {
    if (!storyTypesSet.has(item.mType) || !storyData?.getStory) return;
    if (!shouldRefetchStory(storyData?.getStory, item)) return;

    client.query({
      query: GET_STORY,
      variables: getMemberQuery(storyId),
      fetchPolicy: 'network-only',
    });
  };

  const [subscribe, unSubscribe] = useApolloSubscription(NOTIFY_MEMBER_UPDATE_SUBSCRIPTION, {
    variables: {
      // notifyMemberUpdateSubscription here deals with either of these:
      // story, pitch, instances (including restricted ones for those), and notes.
      // For instance and notes related updates, we do a cache update with relevant data.
      // For story and pitch related update (also with restricted story and pitch) only processing
      // needs to be done is refetching the updated mContentUrl when a write lock has been released.
      mIdSubscribed: storyId,
    },
    fetchPolicy: 'no-cache',
    onSubscriptionData: ({
      client,
      subscriptionData,
    }: {
      client: ApolloClientType;
      subscriptionData: SubscirptionData;
    }) => {
      const item = subscriptionData.data.notifyMemberUpdateSubscription;

      updateStoryInCache(client, item);

      if (!storyLockReleasedEvent(item)) {
        updateCacheWithTransaction(client, { ...item, storyId });
      }
    },
  });

  useEffect(() => {
    subscribe();

    return () => {
      unSubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storyId]);

  useEffect(() => {
    if (storyData && !isEqual(story, storyData?.getStory)) {
      setStory(storyData?.getStory);
    }
    if (mimirLoaded) {
      setSyncProviders();
    }
  }, [setStory, setSyncProviders, story, storyData, mimirLoaded]);

  useEffect(() => {
    if (storyId === story?.mId && currentTab?.title !== story?.mTitle) {
      updateTab({ id: storyId, title: story.mTitle });
    }
  }, [story, currentTab?.title, storyId, updateTab]);

  useEffect(() => {
    if (platforms && !isEqual(platformsAtomValue, platforms)) setPlatformsAtomValue(platforms);
  }, [platforms, platformsAtomValue, setPlatformsAtomValue]);

  useEffect(() => {
    if (resourcesLoading && story && platformsAtomValue) {
      setResourcesLoading(false);
    }
  }, [resourcesLoading, story, platforms, platformsAtomValue]);

  useEffect(() => {
    if (!storyIdFromProp && story) {
      setToolbar(
        <Toolbar
          story={story}
          isPitch={isPitch}
          canUpdateStory={canUpdateStory}
          isDescriptionSettingsHidden={isDescriptionSettingsHidden}
        />,
      );
    }
  }, [storyIdFromProp, setToolbar, story, isPitch, canUpdateStory, isDescriptionSettingsHidden]);

  if (story && platformsAtomValue)
    return (
      <ErrorBoundary fallback={<FallbackComponent />}>
        <Container $imageUrl={thumbnail}>
          <Timeline />
          <Description />
          <TabContainer>
            <ResizablePanelGroup autoSaveId="storyPane_v_1" direction="vertical">
              <ResizablePanel>
                <ResizablePanelGroup autoSaveId="storyPane_h_1" direction="horizontal">
                  <ResizablePanel>
                    <ScopeProvider scope={PaneScope} value={`${story.mId}:${0}`}>
                      <StoryTabs pane={storyPanes[0]} />
                    </ScopeProvider>
                  </ResizablePanel>
                  {storyPanes.length > 1 && (
                    <>
                      <ResizableHandle style={{ width: 8 }} />
                      <ResizablePanel>
                        <ScopeProvider scope={PaneScope} value={`${story.mId}:${1}`}>
                          <StoryTabs pane={storyPanes[1]} />
                        </ScopeProvider>
                      </ResizablePanel>
                    </>
                  )}
                </ResizablePanelGroup>
              </ResizablePanel>
              {storyPanes.length > 2 && (
                <>
                  <ResizableHandle style={{ height: 8, width: '100%' }} />
                  <ResizablePanel>
                    <ResizablePanelGroup autoSaveId="storyPane_h_2" direction="horizontal">
                      <ResizablePanel>
                        <ScopeProvider scope={PaneScope} value={`${story.mId}:${2}`}>
                          <StoryTabs pane={storyPanes[2]} />
                        </ScopeProvider>
                      </ResizablePanel>
                      {storyPanes.length > 3 && (
                        <>
                          <ResizableHandle style={{ width: 8 }} />
                          <ResizablePanel>
                            <ScopeProvider scope={PaneScope} value={`${story.mId}:${3}`}>
                              <StoryTabs pane={storyPanes[3]} />
                            </ScopeProvider>
                          </ResizablePanel>
                        </>
                      )}
                    </ResizablePanelGroup>
                  </ResizablePanel>
                </>
              )}
            </ResizablePanelGroup>
          </TabContainer>
        </Container>
      </ErrorBoundary>
    );

  if (!storyId) {
    return (
      <Flex padding="0.5rem">
        <Infobar>No Story Selected.</Infobar>
      </Flex>
    );
  }

  if (storyLoading || platformsLoading) {
    return <DebouncedLoadingIndicator isLoading={resourcesLoading} />;
  }

  if (storyError || platformsError || !storyData?.getStory) {
    return (
      <Flex padding="0.5rem">
        <Infobar>{`There was an error fetching ${currentTab?.type}.`}</Infobar>
      </Flex>
    );
  }

  return null;
};

const StoryView = (props: StoryViewProps) => {
  const currentTab = useCurrentTabValue();
  const storyId = props?.storyId ?? currentTab?.id ?? '';

  return (
    <ScopeProvider scope={StoryScope} value={`${storyId}:${props?.storyId ? 'preview' : ''}`}>
      <StoryComponent {...props} />
    </ScopeProvider>
  );
};

export default memo(StoryView);
