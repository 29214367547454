import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Popper, TextField } from '@material-ui/core';

import Text from 'components/text/Text';

export const ChipWrapper = styled('div')`
  margin: 2px;
`;

export const StyledOptionWrapper = styled('div')`
  ${({ theme }) => theme.typography.dina.listItemLabel};
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 36px;
`;

export const StyledOption = styled(Text)`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;

export const StyledPopper = styled(Popper)`
  pointer-events: auto;
  * {
    pointer-events: auto;
  }
  z-index: 4000;
  .MuiPaper-root.MuiAutocomplete-paper {
  }
  .MuiAutocomplete-groupLabel {
    top: 0px !important;
  }

  .MuiAutocomplete-groupUl {
    background: ${({ theme }) => theme.palette.dina.surfaceCard};
    .MuiAutocomplete-option {
      padding: 0 8px;
    }
  }
  .MuiAutocomplete-groupLabel {
    ${({ theme }) => css`
      ${theme.typography.dina.captionMedium};
      background: ${theme.palette.dina.surfaceAppBackgroundNavLevel1};
      color: ${theme.palette.dina.highEmphasis};
    `}
    height: 22px;
    text-transform: capitalize;
    line-height: 22px;
    padding-left: 16px;
  }
  .MuiAutocomplete-listbox {
    padding: 0;
  }
  .MuiAutocomplete-option {
    background: ${({ theme }) => theme.palette.dina.surfaceCard};
    &:hover,
    &[data-focus='true'] {
      background: ${({ theme }) => theme.palette.dina.storyCardHover};
      .styled-option {
        border: none;
      }
    }
  }

  .MuiAutocomplete-option:last-child .styled-option {
    border: none;
  }
  .MuiAutocomplete-noOptions {
    ${({ theme }) => theme.typography.dina.captionMedium};
  }
`;

export const StyledTextField = styled(TextField)`
  .MuiFormHelperText-contained {
    ${({ theme }) => theme.typography.dina.chipLabel};
    white-space: prewrap;
  }
  .MuiInputBase-root {
    padding: 2px;
    width: 100%;
    padding: 2px;
    display: inline-flex;
    flex-flow: row wrap;
    min-height: 24px;
    border: 1px solid ${({ theme }) => theme.palette.dina.inputBorderResting};
    border-radius: 4px;
    &:hover {
      background-color: ${({ theme }) => theme.palette.dina.outlineButtonOnHover};
    }
    &.Mui-focused {
      ${({ theme }) => css`
        border-color: ${theme.palette.dina.statusOnFocused};
        background: ${theme.palette.dina.statusOnFocusedDiscreetFill};
      `}
    }
  }
  input,
  textarea {
    width: 100%;
    font-size: 14px;
    padding: 4px !important;
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &::-ms-input-placeholder {
      color: ${({ theme }) => theme.palette.dina.disabled};
    }
  }
  .MuiFilledInput-underline {
    &:hover:not($disabled):not($focused):not($error):before {
      border-bottom: none;
    }
    &:after {
      border-color: transparent;
    }
    &:before {
      border-color: transparent;
    }
  }
`;
