/* eslint-disable import/no-extraneous-dependencies */
import get from 'lodash/get';

const getVersionDate = (sdate = process.env.REACT_APP_VERSION_DATE) => {
  if (!sdate) return undefined;
  const vdate = new Date(sdate);
  const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };
  return `${vdate.toLocaleDateString(undefined, dateOptions)} ${vdate.toLocaleTimeString(
    undefined,
  )}`;
};

const transform = (templateString = '', templateVariables = {}) =>
  templateString.replace(/\${(.*?)}/g, (_, key) => get(templateVariables, key));

export const formatAppTitle = (applicationTitleFormat) => {
  if (!applicationTitleFormat) return '';

  return transform(applicationTitleFormat, {
    version: process.env.REACT_APP_VERSION || '',
    versionDate: getVersionDate() || '',
    branchName: process.env.REACT_APP_REPO_BRANCHNAME || '',
    region: process.env.REACT_APP_AWS_APPSYNC_REGION || '',
  });
};
