import React, { memo } from 'react';
import PropTypes from 'prop-types';

import elementTypes from 'components/editor/constants/types/elementTypes';
import variants from 'components/editor/constants/types/editorVariants';

import Editor from 'components/editor';

const ScriptEditorView = ({ handleEditorUpdate, editorValue }) => (
  <Editor
    variant={variants.SCRIPT}
    renderToolbar={undefined}
    readOnly={false}
    value={editorValue}
    update={handleEditorUpdate}
    isAllowed
    placeholder="Type Something..."
  />
);

ScriptEditorView.propTypes = {
  /** height of the wrapper div */
  handleEditorUpdate: PropTypes.func.isRequired,
  /** scriptBlock info */
  editorValue: PropTypes.shape({}),
};

ScriptEditorView.defaultProps = {
  editorValue: {
    document: [
      {
        type: elementTypes.PARAGRAPH,
        children: [{ text: '' }],
      },
    ],
    version: '0.1.0',
  },
};

export default memo(ScriptEditorView);
