import { useEffect, useMemo, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { keyBy } from 'lodash';

import { ReactComponent as ArrowDown } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_down.svg';
import { ReactComponent as Close } from 'assets/icons/systemicons/close.svg';
import { ReactComponent as Copy } from 'assets/icons/systemicons/copy.svg';
import { IconButton } from 'components/buttons';
import Tooltip from 'components/tooltip';

import { FieldHeader } from '../../styled';
import {
  OptionCheck,
  SelectWrapper,
  StyledOption,
  StyledOptionWrapper,
  StyledPopper,
  StyledTextField,
} from '../choice/styled';
import { FieldProps } from '../fields';
import { isSingleArray } from '../utils';

import { IconWrapper, Item, ItemHeader, ItemTitle, Tag, TagText } from './styled';

export function MultipleChoiceField({
  fieldModel,
  value,
  setValue,
  fieldSettings,
  defaultFieldSettings,
  style,
  errorMessage,
  setError,
  moreVerticalSpace,
  ignoreDefaultValue,
  disableEdit,
}: Readonly<FieldProps>) {
  const [copyTooltip, setCopyTooltip] = useState('Copy value');
  const [tooltipPlacement, setTooltipPlacement] = useState<'bottom' | 'top'>('bottom');
  const [hasMadeChange, setHasMadeChange] = useState(false);
  const { fieldId, defaultValue: rawDefaultValue, required, freeform } = fieldModel;
  const { label, hint } = fieldSettings ?? defaultFieldSettings;

  const defaultValue = useMemo(() => {
    if (!ignoreDefaultValue && Array.isArray(rawDefaultValue.value)) {
      return rawDefaultValue.value;
    }
    return [];
  }, [fieldModel, ignoreDefaultValue]);

  const alternatives = useMemo(() => fieldModel.alternatives ?? [], [fieldModel]);
  const alternativesByValue = useMemo(() => {
    return keyBy(alternatives, (a) => a.value);
  }, [alternatives]);

  const parsedValue: string[] = useMemo(() => {
    if (isSingleArray(value)) {
      return value;
    }
    if (isSingleArray(defaultValue) && !hasMadeChange) {
      return defaultValue;
    }
    return [];
  }, [value, defaultValue, hasMadeChange]);

  const options = useMemo(() => {
    return Array.from(new Set([...alternatives.map((a) => a.value), ...parsedValue]));
  }, [alternatives, parsedValue]);

  useEffect(() => {
    if (required && !parsedValue.length) {
      setError('Required value');
    } else if (required && parsedValue.length && errorMessage) {
      setError(undefined);
    } else if (!required && errorMessage) {
      setError(undefined);
    }
  }, [parsedValue, errorMessage, required]);

  const onFocus = () => {
    setTooltipPlacement('top');
  };

  const onBlur = () => {
    setTooltipPlacement('bottom');
  };

  const removeValue = (t: string) => {
    setValue(parsedValue.filter((v) => v !== t));
    setHasMadeChange(true);
  };

  const renderInput = (params: object) => (
    <StyledTextField
      {...params}
      variant="filled"
      hiddenLabel
      placeholder={disableEdit ? '' : 'Select'}
      error={Boolean(errorMessage)}
      helperText={errorMessage?.length ? errorMessage : undefined}
    />
  );

  const renderOption = (v: string) => (
    <StyledOptionWrapper $selected={parsedValue.includes(v)}>
      <StyledOption>{alternativesByValue[v]?.label ?? v}</StyledOption>
      {parsedValue.includes(v) && <OptionCheck />}
    </StyledOptionWrapper>
  );

  const renderTags = (tags: string[]) => {
    return tags.map((t) => (
      <Tag key={t}>
        <TagText>{alternativesByValue[t]?.label ?? t}</TagText>
        {!disableEdit && (
          <IconButton usage="text" size={18} iconSize={17} onClick={() => removeValue(t)}>
            <Close />
          </IconButton>
        )}
      </Tag>
    ));
  };

  const doCopy = (v: string) => {
    void navigator.clipboard.writeText(v);
    setCopyTooltip('Copied!');
    setTimeout(() => setCopyTooltip('Copy value'), 2000);
  };

  return (
    <Tooltip title={hint ?? ''} placement={tooltipPlacement}>
      <SelectWrapper key={fieldId} style={style}>
        <FieldHeader>{label}</FieldHeader>
        <Autocomplete
          onFocus={onFocus}
          onBlur={onBlur}
          openOnFocus
          limitTags={2}
          disableClearable={required ?? (freeform && moreVerticalSpace)}
          fullWidth
          forcePopupIcon
          multiple
          freeSolo={freeform}
          disableCloseOnSelect={true}
          blurOnSelect={!freeform}
          noOptionsText="No options available"
          selectOnFocus={false}
          options={options}
          value={parsedValue}
          getOptionSelected={(a: string, b: string) => {
            return a === b;
          }}
          onChange={(_ev, newValue) => {
            setValue(newValue);
            setHasMadeChange(true);
          }}
          renderInput={renderInput}
          renderOption={renderOption}
          renderTags={moreVerticalSpace ? () => null : renderTags}
          PopperComponent={StyledPopper}
          getOptionLabel={(alt) => {
            return alternativesByValue[alt]?.label ?? alt;
          }}
          popupIcon={!disableEdit && <ArrowDown />}
        />
        {moreVerticalSpace && parsedValue.length > 0 && (
          <div>
            <ItemHeader>Selected values</ItemHeader>
            {parsedValue.map((v) => (
              <Item key={v}>
                <ItemTitle key={v}>{alternativesByValue[v]?.label ?? v}</ItemTitle>
                <IconWrapper className="unassign-icn">
                  <IconButton
                    title="Remove"
                    usage="text"
                    size={24}
                    iconSize={20}
                    onClick={() => removeValue(v)}
                  >
                    <Close />
                  </IconButton>
                  <IconButton
                    title={copyTooltip}
                    usage="text"
                    size={24}
                    iconSize={16}
                    onClick={() => doCopy(v)}
                  >
                    <Copy />
                  </IconButton>
                </IconWrapper>
              </Item>
            ))}
          </div>
        )}
        <div></div>
      </SelectWrapper>
    </Tooltip>
  );
}
