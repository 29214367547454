import React, { useCallback, useEffect, useMemo } from 'react';
import { format } from 'date-fns';

import { isDateRange } from 'api/search/useSearch';
import { ReactComponent as Close } from 'assets/icons/systemicons/close.svg';
import { IconButton } from 'components/buttons';
import Text from 'components/text/Text';
import Tooltip from 'components/tooltip';
import { HStack } from 'layouts/box/Box';

import { FieldHeader } from '../../styled';
import { FieldProps } from '../fields';
import { TextWrapper } from '../text/styled';

import DateDefault from './DateDefault';
import { DateRange, useDatePicker } from './DatePicker';

import { DateWrapper } from './styled';

export function DateField({
  fieldModel,
  value,
  setValue,
  forceDateRange,
  fieldSettings,
  defaultFieldSettings,
  style,
  ignoreDefaultValue,
  errorMessage,
  setError,
  disableEdit,
}: Readonly<FieldProps>) {
  const { fieldId, defaultValue, required } = fieldModel;
  const { label, hint } = fieldSettings ?? defaultFieldSettings;
  const [, openPicker] = useDatePicker();

  const parsedValue = useMemo(() => {
    if (isDateRange(value)) {
      return value;
    }

    const hasValue = ignoreDefaultValue ? Boolean(value) : Boolean(value ?? defaultValue.value);
    if (hasValue) {
      return format((value ?? defaultValue.value) as string, 'dddd, MMM D YYYY hh:mm A');
    }
    return undefined;
  }, [value, defaultValue.value, ignoreDefaultValue]);

  useEffect(() => {
    if (required && !parsedValue) {
      setError('Required value');
    } else if (required && parsedValue && errorMessage) {
      setError(undefined);
    } else if (!required && errorMessage) {
      setError(undefined);
    }
  }, [parsedValue, errorMessage, required]);

  const openDatePicker = useCallback(
    (ev: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      openPicker({
        anchorEl: ev.currentTarget,
        startValue: parsedValue,
        selectRange: forceDateRange,
        selectDate: (val: DateRange) => {
          setValue(forceDateRange ? val : val.startDate);
        },
      });
    },
    [forceDateRange, openPicker, parsedValue, setValue],
  );

  return (
    <Tooltip title={hint ?? ''} key={fieldId}>
      <TextWrapper key={fieldId} style={style}>
        <FieldHeader>{label}</FieldHeader>
        <DateWrapper>
          <HStack justifyContent="space-between">
            <DateDefault date={parsedValue} onClick={openDatePicker} />
            {!disableEdit && parsedValue && (
              <IconButton title="Clear date" usage="text" size={24} onClick={() => setValue('')}>
                <Close />
              </IconButton>
            )}
          </HStack>
          {errorMessage && (
            <Text variant="caption" color="statusWarning">
              {errorMessage}
            </Text>
          )}
        </DateWrapper>
      </TextWrapper>
    </Tooltip>
  );
}
