/* eslint-disable import/prefer-default-export */
import styled from '@emotion/styled';

export const RootWrapper = styled('span')`
  display: inline-block;
  margin: 2px 0;
  background-color: ${({ theme }) => theme.palette.dina.storyTimelineCurrentTimeIndicator};
  border-radius: 8px;
  padding: 2px 6px;
  box-shadow: ${({ showBorder, theme }) =>
    showBorder ? `0 0 0 1px ${theme.palette.dina.onFocus}` : 'none'};
`;
