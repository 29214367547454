import useGetUser from 'hooks/useGetUser';
import { useContact } from 'store';

/**
 * Get Contact Information From either mType = 'user' or mType = 'contact'
 *
 * @param {*} userId - mId of User
 * @returns {}
 */
const useGetContactInformation = (userId) => {
  const { getUser } = useGetUser();
  const contacts = useContact();

  const userDetails = getUser(userId);
  const contactDetails = contacts?.find((usr) => usr.mId === userId);

  const response = userDetails ?? contactDetails;

  return {
    description: response.mDescription,
    email: response.mProperties.email,
    phone: response.mProperties.phone,
  };
};

export default useGetContactInformation;
