import React, { useState, useCallback, memo } from 'react';
import PropTypes from 'prop-types';

import InternetIcon from 'assets/icons/systemicons/internet.svg';
import Tooltip from 'components/tooltip';

import { Wrapper, ThumbAndText, Title, Description, Thumbnail, NewTabText } from './styled';

function Link({ link, onClick }) {
  const { id, url, title, description, iframe } = link;

  const [thumbnailUrl, setThumbnailUrl] = useState(`${url}/favicon.ico`);
  const [showTooltip, setShowTooltip] = useState(false);

  const tooltipTitle = `Open ${title || url} in ${iframe ? 'an iframe' : 'a new tab'}`;

  const onLinkClick = () => onClick(url);
  const onMissingThumbnail = () => setThumbnailUrl(InternetIcon);

  const ClickArea = useCallback(
    ({ children }) => {
      if (iframe) {
        return <ThumbAndText onClick={onLinkClick}>{children}</ThumbAndText>;
      }

      return (
        <ThumbAndText underline="none" href={url} target="_blank" rel="noopener noreferrer">
          {children}
        </ThumbAndText>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, url, iframe],
  );

  return (
    <Tooltip
      title={tooltipTitle}
      maxWidth={400}
      placement="bottom"
      noArrow
      open={showTooltip}
      key={id}
    >
      <Wrapper
        key={id}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <ClickArea>
          <Thumbnail src={thumbnailUrl} onError={onMissingThumbnail} />
          <span>
            <Title>{title || url}</Title>
            <Description>{description}</Description>
          </span>
        </ClickArea>
        {!iframe && <NewTabText>Opens in a new tab</NewTabText>}
      </Wrapper>
    </Tooltip>
  );
}

Link.propTypes = {
  link: PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    iframe: PropTypes.bool,
  }).isRequired,
  onClick: PropTypes.func,
};

Link.defaultProps = {
  onClick: () => {},
};

export default memo(Link);
