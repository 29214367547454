/**
 *  Hook for getting a single member
 */
import { useQuery, WatchQueryFetchPolicy } from '@apollo/client';

import memberTypes from 'graphql/memberTypes';
import GET_MEMBER from 'graphql/queries/getMember';
import GET_INSTANCE from 'graphql/queries/getSingleInstance';
import GET_SPACE from 'graphql/queries/getSpace';
import { GetMemberInput, MemberType } from 'types/graphqlTypes';

const QUERIES = {
  [memberTypes.SPACE]: GET_SPACE,
  [memberTypes.INSTANCE]: GET_INSTANCE,
  default: GET_MEMBER,
};

type GetMemberReturnType = {
  getMember: MemberType;
};

type GetMemberInputType = {
  input: GetMemberInput;
};

interface GetMemberProps {
  mId: string;
  mRefId: string;
  type?: string;
  skip?: boolean;
  fetchPolicy?: WatchQueryFetchPolicy;
}

const useGetMember = ({
  mId,
  mRefId,
  type = 'default',
  skip = false,
  fetchPolicy = 'cache-and-network',
}: GetMemberProps) => {
  const { data, error, loading, refetch } = useQuery<GetMemberReturnType, GetMemberInputType>(
    QUERIES[type],
    {
      variables: {
        input: {
          mId,
          mRefId,
        },
      },
      fetchPolicy,
      skip,
    },
  );

  const member = data?.getMember;

  return { data: member, error, loading, refetch };
};

export default useGetMember;
