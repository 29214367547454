import { useCallback } from 'react';
import { useMutation } from '@apollo/client';

import { UPDATE_DRAFT } from './graphql';

const useUpdateDraft = () => {
  const [updateDraft] = useMutation(UPDATE_DRAFT);

  const update = useCallback(
    async ({ mId, mRefId, mTitle, mDescription }) => {
      try {
        await updateDraft({
          variables: {
            input: {
              mId,
              mRefId,
              mTitle,
              mDescription,
            },
          },
        });
      } catch (err) {
        throw Error('Could not update draft item: ', err);
      }
    },
    [updateDraft],
  );

  return [update];
};

export default useUpdateDraft;
