import { useState } from 'react';
import PropTypes from 'prop-types';
import Group from 'screens/storybox/components/group';
import LoadingIndicator from 'components/loadingIndicator';
import Scrollbar from 'components/scrollbar';
import Header from './header';
import InstanceItem from './instanceItem';
import { GroupWrapper } from './details-styled';

const Groups = ({ filteredMOrder, label, ...rest }) => (
  <Group label={label} itemCount={filteredMOrder.length}>
    {filteredMOrder.map((mId, index) => (
      <InstanceItem key={mId} mId={mId} showDivider index={index} {...rest} />
    ))}
  </Group>
);

const Details = ({ setPreviewRundown, loading, data, title, publishingtime }) => {
  const [currentOrder, setCurrentOrder] = useState('mOrder');
  const [currentAnchor, setCurrentAnchor] = useState(0);
  const [selectedIds, setSelectedIds] = useState({});

  if (loading) return <LoadingIndicator />;

  const { mOrder, mPreorder, platformKind, mThumbnailKey } = data.getRundown;

  const filteredMorder = mOrder ? mOrder.filter((mId) => mId[0] !== '-') : [];
  const filteredPreorder = mPreorder ? mPreorder.filter((mId) => mId[0] !== '-') : [];

  const toggleCurrentItemFromSelectedIds = (id, index) => {
    const newSelectedIds = { ...selectedIds };
    if (selectedIds[index]) delete newSelectedIds[index];
    else {
      newSelectedIds[index] = id;
      setCurrentAnchor(index);
    }
    setSelectedIds(newSelectedIds);
  };

  /** already selected ids will be ignored if they are from another order */
  const shiftItemsToSelectedIds = (index, orderType, ignoreSelectedIds) => {
    const [startPosition, endPosition] = [index, ignoreSelectedIds ? 0 : currentAnchor].sort(
      (a, b) => a - b,
    );
    const order = orderType === 'mOrder' ? filteredMorder : filteredPreorder;
    const newlySelectedIds = {};
    order.slice(startPosition, endPosition + 1).forEach((id, offset) => {
      newlySelectedIds[startPosition + offset] = id;
    });
    const alreadySelectedIds = ignoreSelectedIds ? {} : { ...selectedIds };
    setSelectedIds({ ...alreadySelectedIds, ...newlySelectedIds });
    setCurrentAnchor(index);
  };

  const selectSingleItem = (id, index) => {
    const newSelectedIds = {};
    newSelectedIds[index] = id;
    setSelectedIds(newSelectedIds);
    setCurrentAnchor(index);
  };

  const changeCurrentOrder = (newOrder) => setCurrentOrder(newOrder);

  return (
    <>
      <Header
        title={title}
        mThumbnailKey={mThumbnailKey}
        publishingtime={publishingtime}
        backButtonClicked={() => setPreviewRundown(null)}
      />
      <GroupWrapper>
        <Scrollbar top={5} bottom={5} autoHide>
          <Groups
            label="Ready To Air"
            order="mOrder"
            filteredMOrder={filteredMorder}
            platformKind={platformKind}
            publishingtime={publishingtime}
            shiftItemsToSelectedIds={shiftItemsToSelectedIds}
            selectSingleItem={selectSingleItem}
            changeCurrentOrder={changeCurrentOrder}
            currentOrder={currentOrder}
            currentAnchor={currentAnchor}
            selectedIds={selectedIds}
            toggleCurrentItemFromSelectedIds={toggleCurrentItemFromSelectedIds}
          />
          <Groups
            label="Preparing"
            order="preMOrder"
            filteredMOrder={filteredPreorder}
            platformKind={platformKind}
            publishingtime={publishingtime}
            shiftItemsToSelectedIds={shiftItemsToSelectedIds}
            selectSingleItem={selectSingleItem}
            changeCurrentOrder={changeCurrentOrder}
            currentOrder={currentOrder}
            currentAnchor={currentAnchor}
            selectedIds={selectedIds}
            toggleCurrentItemFromSelectedIds={toggleCurrentItemFromSelectedIds}
          />
        </Scrollbar>
      </GroupWrapper>
    </>
  );
};

Details.propTypes = {
  data: PropTypes.shape({
    getRundown: PropTypes.shape({
      mOrder: PropTypes.arrayOf(PropTypes.string),
      mPreorder: PropTypes.arrayOf(PropTypes.string),
      platformKind: PropTypes.string,
      mThumbnailKey: PropTypes.string,
    }),
  }),
  setPreviewRundown: PropTypes.func,
  title: PropTypes.string,
  publishingtime: PropTypes.string,
  loading: PropTypes.bool,
};

Details.defaultProps = {
  data: {},
  setPreviewRundown: () => {},
  title: '',
  publishingtime: '',
  loading: false,
};

export default Details;
