import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import * as EmailValidator from 'email-validator';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import Dialog from 'components/dialogs/DialogBuilder';
import InputField from 'components/inputField/InputField';
import InputFieldTypes from 'components/inputField/InputFieldTypes';
import Scrollbar from 'components/scrollbar';
import TextArea from 'components/textArea/TextArea';
import memberTypes from 'graphql/memberTypes';
import CREATE_CONTACT from 'graphql/mutations/createContact';
import { getMembersOfTypeQuery } from 'graphql/queryVariables';
import useCheckUserRight from 'hooks/useCheckUserRight';
import MetadataView from 'screens/main/components/rightArea/sidebar/metadata/editMetadata/EditMetadata';
import { useMembers } from 'store';
import { User } from 'types/members';
import useLogger from 'utils/useLogger';

import { ContentWrapper, FormContainer, InputDiv, MetadataContent, TextAreaDiv } from './styled';

interface CreateNewDialogProps {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
}

interface ContactData {
  firstName: string;
  surname: string;
  email?: string;
  phone?: string;
  mDescription?: string;
  readSpeed?: string;
  [key: string]: unknown;
}

interface CreateContactInput {
  mTitle?: string;
  mProperties?: ContactData;
  mDescription?: string;
  mType?: string;
  metadata?: string;
}

interface ContactList {
  getMembersOftype: User[];
}

interface CreateContact {
  createContact: User;
}

const GET_USERS = gql`
  query GetUsers($input: GetMemberOfTypeInput) {
    getMembersOftype(input: $input) {
      mId
      mRefId
    }
  }
`;

function CreateNewDialog({ open, setOpen }: Readonly<CreateNewDialogProps>) {
  const contactType = memberTypes.CONTACT;
  const logger = useLogger('create contact dialog v2');
  const [members, setMembers] = useMembers();
  const [firstName, setFirstName] = useState('');
  const [metadata, setMetadata] = useState<ContactData>();
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [readSpeed, setReadSpeed] = useState('');
  const [description, setDescription] = useState('');
  const [errorMap, setErrorMap] = useState<Record<string, string | undefined>>({});
  const [checkUserRight] = useCheckUserRight();
  const canUseContactMetadata = checkUserRight('feature', 'contact-metadata');

  const { mdfs } = useGetMdfs({ all: true });
  const externalContactMdf = mdfs.find((m) => m.id === 'contact-mdf');

  const [createContact] = useMutation(CREATE_CONTACT, {
    update: (proxy, mutationResult) => {
      const { createContact: newContact } = mutationResult?.data as CreateContact;
      // Read contacts from the cache
      if (newContact) {
        setMembers({
          ...members,
          contact: [...members.contact, newContact],
        });
        try {
          const contactList = proxy.readQuery({
            query: GET_USERS,
            variables: getMembersOfTypeQuery(contactType),
          }) as ContactList;

          const list = contactList?.getMembersOftype?.filter(
            (contact) => contact !== null && contact?.mId === newContact?.mId,
          );
          const contacts = [...contactList.getMembersOftype];
          // Add new contact to the contact-list if it is not the cache
          if (list && list?.length === 0) {
            contacts.push(newContact);
          }
          // Write updated contact-list in the cache.
          proxy.writeQuery({
            query: GET_USERS,
            variables: getMembersOfTypeQuery(contactType),
            data: {
              getMembersOftype: contacts,
            },
          });
        } catch (error) {
          // eslint-disable-next-line no-console
          logger.log(error);
        }
      }
    },
  });

  const handleOnClose = () => {
    setOpen(false);
    setFirstName('');
    setSurname('');
    setDescription('');
    setEmail('');
    setPhoneNumber('');
    setReadSpeed('');
  };

  const createNewContact = async ({
    firstName: updatedFirstName,
    surname: updatedSurname,
    email: updatedEmail,
    phone: updatedPhone,
    mDescription: updatedDescription,
    readSpeed: updatedReadSpeed,
    ...rest
  }: ContactData) => {
    if (!(updatedFirstName && updatedSurname)) {
      return;
    }

    const mProperties = {
      firstName: updatedFirstName,
      surname: updatedSurname,
      jobTitle: 'Contact',
    } as ContactData;

    if (updatedEmail) {
      mProperties.email = updatedEmail;
    }
    if (updatedPhone) {
      mProperties.phone = updatedPhone;
    }
    if (updatedReadSpeed) {
      mProperties.readSpeed = updatedReadSpeed;
    }

    const input = {
      mTitle: `${updatedFirstName} ${updatedSurname}`,
      mProperties: { ...mProperties, __typename: 'ContactType' },
      mType: contactType,
    } as CreateContactInput;

    const metadataObject = {
      ...mProperties,
      ...rest,
    };
    if (updatedDescription) {
      input.mDescription = updatedDescription;
      metadataObject.mDescription = updatedDescription;
    }
    input.metadata = JSON.stringify(metadataObject);
    createContact({
      variables: {
        input,
      },
    })
      .then(() => {
        handleOnClose();
      })
      .catch((e) => {
        logger.log(e);
      });
  };

  const onUpdate = (value?: string) => {
    const parsedValue = JSON.parse(value ?? '{}') as ContactData;
    setMetadata(parsedValue);
  };

  const onCreate = async () => {
    if (!metadata) return;

    const dynamicSubmitData: Partial<ContactData> = {};

    Object.keys(metadata).forEach((key) => {
      const value = metadata[key];
      const defaultValue = (externalContactMdf?.fields?.find((item) => item?.fieldId === key) ?? {})
        ?.defaultValue?.value;

      dynamicSubmitData[key as keyof ContactData] =
        value !== undefined && value !== null && value !== ''
          ? (value as string)
          : (defaultValue as string);
    });

    handleOnClose();
    await createNewContact({
      ...(dynamicSubmitData as ContactData),
    });
  };

  const submitForm = () => {
    if (firstName && surname && (!email || EmailValidator.validate(email))) {
      createNewContact({
        firstName,
        surname,
        email,
        phone: phoneNumber,
        mDescription: description,
        readSpeed,
      }).then(
        () => {},
        () => {},
      );
    }
  };

  const onErrorUpdate = ({ fieldId, error }: { fieldId: string; error: string | undefined }) => {
    setErrorMap((prevState) => ({
      ...prevState,
      [fieldId]: error,
    }));
  };

  return (
    <Dialog open={open} onClose={handleOnClose}>
      <Dialog.Header>Create External Contact</Dialog.Header>
      <Dialog.Body>
        <ContentWrapper>
          <Scrollbar>
            <MetadataContent>
              {externalContactMdf && canUseContactMetadata ? (
                <MetadataView
                  data={{}}
                  onUpdate={(newValue) => {
                    onUpdate(newValue);
                  }}
                  mdf={externalContactMdf}
                  errorMap={errorMap}
                  onErrorUpdate={onErrorUpdate}
                />
              ) : (
                <FormContainer>
                  <InputDiv>
                    <InputField
                      label="First name"
                      description="Type first name here"
                      value={firstName}
                      severity="mandatory"
                      onChange={setFirstName}
                      autoFocus
                    />
                  </InputDiv>
                  <InputDiv>
                    <InputField
                      label="Surname"
                      description="Type surname here"
                      value={surname}
                      severity="mandatory"
                      onChange={setSurname}
                    />
                  </InputDiv>
                  <InputDiv>
                    <InputField
                      label="E-mail"
                      description="Type e-mail address here"
                      value={email}
                      type={InputFieldTypes.EMAIL}
                      onChange={setEmail}
                    />
                  </InputDiv>
                  <InputDiv>
                    <InputField
                      label="Phone Number"
                      description="Type phone number here"
                      value={phoneNumber}
                      type={InputFieldTypes.TELEPHONE}
                      onChange={setPhoneNumber}
                    />
                  </InputDiv>
                  <InputDiv>
                    <InputField
                      label="Read Speed"
                      description="Type read speed here"
                      value={readSpeed}
                      onChange={setReadSpeed}
                    />
                  </InputDiv>
                  <TextAreaDiv>
                    <TextArea
                      label="Description"
                      value={description}
                      optionalLabel="Description"
                      onChange={setDescription}
                      description="Type a short bio here"
                      rows="4"
                      severity={undefined}
                      disabled={undefined}
                      type={undefined}
                      subDescription={undefined}
                      disableLabel={undefined}
                      onBlur={undefined}
                      onFocus={undefined}
                      lineHeight={undefined}
                      multiline={undefined}
                      direction={undefined}
                    />
                  </TextAreaDiv>
                </FormContainer>
              )}
            </MetadataContent>
          </Scrollbar>
        </ContentWrapper>
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton onCancel={handleOnClose} />
        <Dialog.ConfirmButton
          label="Create"
          onConfirm={externalContactMdf && canUseContactMetadata ? onCreate : submitForm}
          disabled={
            externalContactMdf && canUseContactMetadata
              ? Object.values(errorMap).some((value) => value !== undefined)
              : !firstName || !surname || (!!email && !EmailValidator.validate(email))
          }
        />
      </Dialog.Footer>
    </Dialog>
  );
}

export default CreateNewDialog;
