import { ApolloClient } from '@apollo/client';

import GET_MEMBER from 'graphql/queries/getMember';
import { DailyNote } from 'types';

export const updateDailyNoteCache = (client: ApolloClient<object>, updatedDailyNote: DailyNote) => {
  client.writeQuery({
    query: GET_MEMBER,
    variables: { mId: updatedDailyNote.mId, mRefId: updatedDailyNote.mRefId },
    data: {
      getMember: updatedDailyNote,
    },
  });
};

export default updateDailyNoteCache;
