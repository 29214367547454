// eslint-disable-next-line sort-imports
import { useQuery, type WatchQueryFetchPolicy } from '@apollo/client';

import GET_INSTANCE from 'graphql/queries/getInstance';
import { getStoryInstancesQuery } from 'graphql/queryVariables';
import { Instance } from 'types';
import { GetMemberInput, MemberTypeEnum } from 'types/graphqlTypes';

type GetInstancesReturnType = {
  getMembers: Instance[];
};

type GetInstancesInputType = {
  input: GetMemberInput;
};

const useInstances = (storyId: string, fetchPolicy: WatchQueryFetchPolicy | undefined) => {
  const { data, error, loading, refetch } = useQuery<GetInstancesReturnType, GetInstancesInputType>(
    GET_INSTANCE,
    {
      variables: getStoryInstancesQuery(storyId) as {
        input: { mId: string; mType: MemberTypeEnum };
      },
      fetchPolicy,
    },
  );

  let instances: Instance[] = [];
  if (data?.getMembers) {
    const { getMembers } = data;
    instances = [...getMembers];
  }

  return { instances, error, loading, refetch };
};

export default useInstances;
