import { useCallback, useEffect, useMemo } from 'react';

import { ReactComponent as Close } from 'assets/icons/systemicons/close.svg';
import { ReactComponent as EditOff } from 'assets/icons/systemicons/edit_off.svg';
import { ReactComponent as EditOn } from 'assets/icons/systemicons/edit_on.svg';
import { ReactComponent as VisibilityOff } from 'assets/icons/systemicons/editor/visibility.svg';
import { ReactComponent as VisibilityOn } from 'assets/icons/systemicons/editor/visibility_on.svg';
import { ReactComponent as Open } from 'assets/icons/systemicons/open.svg';
import { ReactComponent as SidebarActive } from 'assets/icons/systemicons/sidebar_active.svg';
import { ReactComponent as SidebarInactive } from 'assets/icons/systemicons/sidebar_inactive.svg';
import Assignees from 'components/assignees/Assignees';
import { IconButton } from 'components/buttons';
import Divider from 'components/divider';
import NotificationPopup, { notificationIdentifiers } from 'components/notificationPopup';
import ToggleButtons from 'components/toggleButtons';
import Tooltip from 'components/tooltip/Tooltip';
import { HStack } from 'layouts/box/Box';
import { onImageLoadingError } from 'utils/image/imageUtils';

import useInstanceCardHeader from '../hooks/useInstanceCardHeader';
import useInstanceViewUtils from '../hooks/useInstanceViewUtils';
import { useInstanceMolecule } from '../store/instance';
import {
  Destination,
  DestinationAvatar,
  HeaderBottom,
  HeaderTop,
  HeaderWrapper,
  PublishingPointImg,
  ThumbnailImg,
} from '../styled';

import Menu from './menu/Menu';
import SchedulingButtons from './schedulingButtons/SchedulingButtons';
import PublishSettings from './publishSettings';
import TitleBox from './TitleBox';

const Header = ({ onOpen, onClose }: { onOpen?: () => void; onClose?: () => void }) => {
  const { useInstanceValue, usePublishingPointValue, notificationRef } = useInstanceMolecule();
  const {
    assignedMembers,
    onAssigneesClick,
    publishingIcon,
    destination,
    canOpenDestination,
    openDestination,
    showToggleButton,
    publishAnchorEl,
    publishingTime,
    canRepublishInstance,
    publishMetadata,
    updateInstanceMetadata,
    onPublishConfirm,
    onPublishCancel,
    canOpenStory,
    openStory,
  } = useInstanceCardHeader();
  const {
    view,
    setView,
    showMetadata,
    toggleMetadataView,
    thumbnail,
    variant,
    isCmsBlock,
    isEmbedPresent,
    backgroundColor,
    opacity,
  } = useInstanceViewUtils();

  const instance = useInstanceValue();
  const publishingPoint = usePublishingPointValue();

  const toggleButtonList = useMemo(
    () => [
      {
        icon: (
          <IconButton usage="text" size={28} iconSize={20} selected={view === 'edit'}>
            {view === 'edit' ? <EditOn className="skipOverride" /> : <EditOff />}
          </IconButton>
        ),
        value: 'edit',
      },
      {
        icon: (
          <IconButton usage="text" size={28} iconSize={20} selected={view === 'preview'}>
            {view === 'preview' ? <VisibilityOn /> : <VisibilityOff />}
          </IconButton>
        ),
        value: 'preview',
        disabled: !isEmbedPresent,
      },
    ],
    [isEmbedPresent, view],
  );

  useEffect(() => {
    if (isEmbedPresent) {
      setView('preview');
    }
  }, [isEmbedPresent, setView]);

  const handleOpenStory = useCallback(() => {
    if (onOpen) {
      openStory();
      onOpen();
    }
  }, [onOpen, openStory]);

  const handleOpenDestination = useCallback(() => {
    openDestination();
    if (onClose) onClose();
  }, [onClose, openDestination]);

  return (
    <HeaderWrapper $backgroundColor={backgroundColor} $opacity={opacity} alignItems="flex-start">
      <HeaderTop gap="0.5rem">
        <TitleBox />
        {canOpenStory && onOpen && (
          <IconButton usage="text" variant="contained" onClick={handleOpenStory} round size={32}>
            <Tooltip title="open">
              <Open />
            </Tooltip>
          </IconButton>
        )}
        <Assignees members={assignedMembers} size={24} onClick={onAssigneesClick} />
        <Menu />
        {onClose && (
          <IconButton usage="text" variant="contained" onClick={onClose} round size={32}>
            <Tooltip title="close">
              <Close />
            </Tooltip>
          </IconButton>
        )}
      </HeaderTop>
      <Divider style={{ width: '100%' }} />
      <HeaderBottom justifyContent="flex-start" gap="0.5rem">
        <HStack gap="0.5rem" flex="1 0 auto" justifyContent="flex-start">
          <DestinationAvatar>
            <ThumbnailImg
              src={thumbnail ?? ''}
              onError={onImageLoadingError}
              alt="destination-thumbnail"
            />
            <PublishingPointImg src={publishingIcon} alt="publishing-thumbnail" />
          </DestinationAvatar>
          <Destination $canOpenDestination={canOpenDestination} onClick={handleOpenDestination}>
            {destination?.title}
          </Destination>
        </HStack>
        {showToggleButton && (
          <ToggleButtons selectedValue={view} list={toggleButtonList} onChange={setView} />
        )}
        <SchedulingButtons />
        <div ref={notificationRef}>
          <IconButton
            height={30}
            width={30}
            onClick={toggleMetadataView}
            title="Toggle metadata sidebar"
            usage={showMetadata ? 'story' : 'text'}
          >
            {showMetadata ? <SidebarActive /> : <SidebarInactive />}
          </IconButton>
          <NotificationPopup
            position="bottom"
            anchor={notificationRef}
            id={notificationIdentifiers.MetadataSlide}
            title="Improved metadata editing"
            // eslint-disable-next-line max-len
            text="We have moved editing metadata from the drop down menu to a side panel for easier access."
          />
        </div>
        <PublishSettings
          publishingPoint={publishingPoint}
          platformKind={instance?.mProperties?.platformKind}
          publishingTime={publishingTime}
          onCancel={onPublishCancel}
          variant={variant}
          selectedDestination={destination}
          canRepublishInstance={canRepublishInstance}
          publishMetadata={publishMetadata}
          publishingPointIcon={publishingIcon}
          anchorEl={publishAnchorEl}
          expiryTime={instance?.mPublishingEnd}
          isCmsBlock={isCmsBlock}
          updateInstanceMetadata={updateInstanceMetadata}
          onOK={onPublishConfirm}
        />
      </HeaderBottom>
    </HeaderWrapper>
  );
};

export default Header;
