import React from 'react';

import { ReactComponent as Close } from 'assets/icons/systemicons/close.svg';
import { Button } from 'components/buttons';

import Avatar from 'components/avatar/Avatar';

import {
  Root,
  CloseButton,
  LogoutButtonWrapper,
  QRDialogHeader,
  QRDialogHeaderText,
  BackIcon,
  HeaderContainer,
  AvatarBackgroundContainer,
  AvatarContainer,
  AvatarBackground,
  AvatarFallback,
  AvatarWrapper,
  Title,
} from './header-styled';

const QR_DIALOG_HEADER_TEXT = 'Dina Mobile login quick start';
const LOGOUT_BTN_TEXT = 'Log out';

const Header = ({ avatar, username, onLogout, onClose, showQR, setShowQR }) => {
  const qrBackButtonPressed = () => {
    setShowQR(false);
  };

  return (
    <Root>
      <CloseButton onClick={onClose} role="presentation">
        <Close />
      </CloseButton>
      {showQR ? (
        <QRDialogHeader>
          <BackIcon onClick={qrBackButtonPressed} role="presentation" />
          <QRDialogHeaderText>{QR_DIALOG_HEADER_TEXT}</QRDialogHeaderText>
        </QRDialogHeader>
      ) : (
        <HeaderContainer>
          <AvatarBackgroundContainer>
            {avatar ? (
              <AvatarBackground src={avatar} alt="Background Avatar" />
            ) : (
              <AvatarFallback />
            )}
          </AvatarBackgroundContainer>

          <AvatarContainer>
            <AvatarWrapper>
              <Avatar size={48} src={avatar} variant="user" />
            </AvatarWrapper>
            <Title>{username}</Title>
          </AvatarContainer>
          <LogoutButtonWrapper>
            <Button variant="contained" usage="danger" onClick={onLogout}>
              {LOGOUT_BTN_TEXT}
            </Button>
          </LogoutButtonWrapper>
        </HeaderContainer>
      )}
    </Root>
  );
};

export default Header;
