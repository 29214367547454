import React, { forwardRef, useLayoutEffect, useRef } from 'react';

import Slot from 'lib/slot';

import { List, Root, Trigger } from './styled';

export type StyleProps = {
  /** Have the tabs fill the width of the container */
  fullWidth?: boolean;
};
interface Tab {
  id: string;
  active: boolean;
  children: React.ReactNode;
}

interface RootProps {
  tabs?: Tab[];
  children?: React.ReactNode;
  activeTab?: string;
  defaultTab?: string;
  setActiveTab?: (tab: string) => void;
  styleProps?: StyleProps;
}

/* Tabs component contained inside a border */
function TabRoot({
  children,
  defaultTab,
  activeTab,
  setActiveTab,
  styleProps,
}: Readonly<RootProps>) {
  const { fullWidth } = styleProps ?? {};

  return (
    <Root
      defaultValue={defaultTab}
      value={activeTab ?? defaultTab}
      onValueChange={setActiveTab}
      fullWidth={fullWidth}
    >
      {children}
    </Root>
  );
}

interface TabListProps {
  children: React.ReactNode;
  styleProps?: StyleProps;
}

const TabsList = forwardRef<HTMLDivElement, TabListProps>(({ children, styleProps }, ref) => {
  const [listWidth, setListWidth] = React.useState<number>(0);
  const listRef = useRef<HTMLDivElement>(null);

  const { fullWidth } = styleProps ?? {};

  /** Calculate the width of the tabs */
  useLayoutEffect(() => {
    if (!listRef.current) return;

    const childElements = Array.from(listRef.current.childNodes) as HTMLElement[];
    let width = 0;

    childElements.forEach((child) => {
      if (!child) return;

      if (child.getBoundingClientRect().width > width) {
        width = child.getBoundingClientRect().width;
      }
    });

    const widestTabTimesItems = width * childElements.length;
    setListWidth(widestTabTimesItems);
  }, []);

  return (
    <Slot ref={listRef}>
      <List ref={ref} $width={listWidth} fullWidth={fullWidth}>
        {children}
      </List>
    </Slot>
  );
});

TabsList.displayName = 'TabsList';

function TabsTrigger({ id, active, children }: Readonly<Tab>) {
  return (
    <Trigger data-state={active ? 'active' : 'inactive'} value={id}>
      {children}
    </Trigger>
  );
}

const TabsNamespace = Object.assign(TabRoot, {
  List: TabsList,
  Trigger: TabsTrigger,
});

export default TabsNamespace;
