import Divider from 'components/divider';
import memberTypes from 'graphql/memberTypes';
import distanceInWord from 'utils/distanceInWords';

import DetailComponent from './detailComponent';
import IconComponent from './Icon';

import {
  AlertContainer,
  Button,
  Buttons,
  IconWrapper,
  Message,
  StyledDivider,
  Time,
  Title,
} from './styled';

interface AlertProps {
  variant: string;
  onOk: (canOpenStory: boolean) => void;
  onCancel: () => void;
  mCreatedAt?: string;
  contentValue?: string;
  mAvatarUrl: string;
  contentHighlightedText?: string;
  alertTitle?: string;
  assignmentMessage?: string;
  user?: string;
  canOpenStory: boolean;
  updatedBy?: string;
}

const getConfirmButtonLabel = (variant?: string, canOpenStory?: boolean) => {
  if (canOpenStory) return 'Open';
  switch (variant) {
    case memberTypes.CONVERSATION:
    case memberTypes.MESSAGE:
    case memberTypes.TEAM:
    case memberTypes.DEPARTMENT:
      return 'Show';
    default:
      return 'Open';
  }
};

function Alert({
  mAvatarUrl,
  onOk,
  onCancel,
  mCreatedAt,
  variant,
  contentValue,
  contentHighlightedText,
  alertTitle,
  assignmentMessage,
  user,
  canOpenStory,
  updatedBy,
}: Readonly<AlertProps>) {
  const confirmButtonLabel = getConfirmButtonLabel(variant, canOpenStory);
  const handleConfirm = () => onOk(canOpenStory);

  return (
    <AlertContainer>
      <IconWrapper>
        <IconComponent variant={variant} mAvatarUrl={mAvatarUrl} />
      </IconWrapper>
      <Message>
        <Time>{distanceInWord(mCreatedAt)}</Time>
        <Title>{alertTitle}</Title>
        <DetailComponent
          contentValue={contentValue}
          variant={variant}
          contentHighlightedText={contentHighlightedText}
          user={user}
          updatedBy={updatedBy}
        />
        {!!assignmentMessage && (
          <DetailComponent
            variant={variant}
            user={user}
            contentHighlightedText={assignmentMessage}
            updatedBy={updatedBy}
          />
        )}
      </Message>
      <Divider orientation="vertical" flexItem />
      <Buttons>
        <Button onClick={handleConfirm} disabled={variant === 'error'}>
          {confirmButtonLabel}
        </Button>
        <StyledDivider />
        <Button onClick={onCancel}>Dismiss</Button>
      </Buttons>
    </AlertContainer>
  );
}

export default Alert;
