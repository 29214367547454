import styled from '@emotion/styled';
import { css } from '@emotion/react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import Divider from 'components/divider';

export const StyledAppBar = styled(AppBar)`
  height: 44px;
  ${({ theme }) => css`
    z-index: ${theme.zIndex.drawer + 1};
    background-color: ${theme.palette.dina.surfaceAppBackgroundNavLevel1Dark};
  `}
`;

export const StyledToolbar = styled(Toolbar)`
  min-height: 44px;
  padding: 0;
  display: flex;
  gap: 0.5rem;
`;

export const StyledTabDivider = styled(Divider)`
  margin-block: 12px;
`;
