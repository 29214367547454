import { atom, useAtom } from 'jotai';

const selectedDateAtom = atom(new Date());

selectedDateAtom.onMount = (set) => {
  set(new Date());
};

export const useSelectedDailyNoteDate = () => useAtom(selectedDateAtom);

const hasUpdatedSubscriptionAtom = atom(false);
export const useHasUpdatedSubscription = () => useAtom(hasUpdatedSubscriptionAtom);
