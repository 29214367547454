import React, { memo, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ReactComponent as SectionIconComponent } from 'assets/icons/systemicons/editor/section.svg';
import { elementTypes } from 'components/editor/constants/types';
import { useSlate, useReadOnly } from 'slate-react';
import fieldEnums from 'utils/constants/fieldEnums';
import AutoComplete from 'components/autoCompleteBase';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import useGetFieldsForBlock from 'hooks/useGetFieldsForBlock';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import updateBlock from 'components/editor/utils/updateBlock';
import getContent from 'components/editor/components/tags/utils/getContent';
import ChipGroup from 'components/editor/components/tags/components/chipGroup';
import stringifyList from 'components/editor/components/tags/utils/stringifyList';
import useChangeCollapse from 'components/editor/hooks/useChangeCollapse';
import Box from '../box';
import DragAndDrop from '../dragAndDrop';

const AutoCompleteBase = memo(AutoComplete);

export const SectionIcon = styled(SectionIconComponent)`
  margin: 8px;
`;

export const ContentWrapper = styled('div')`
  width: 100%;
`;

const Section = ({ attributes, children, element, direction }) => {
  const [getFieldsForBlock] = useGetFieldsForBlock();
  const readOnly = useReadOnly();
  const { data } = element;
  const editor = useSlate();
  const { update } = useEditorContext();
  const field = getFieldsForBlock(fieldEnums.CUSTOM_SECTIONS, { options: [] });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const options = useMemo(() => field?.options || [], []);

  const [onChangeCollapse] = useChangeCollapse(element);

  const { content: dataContent, collapsed = false } = data;
  // console.log('element', element);

  const content = useMemo(() => getContent(dataContent || []), [dataContent]);

  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');

  const updateSection = useCallback(
    (updatedData) => {
      updateBlock(editor, element, updatedData, update, false);
    },
    [editor, element, update],
  );

  const handleChange = useCallback(
    (event, newSection) => {
      event.preventDefault();
      if (newSection && newSection !== null) {
        const isIncluded = content.find((opt) => opt.value === newSection.value);

        if (!isIncluded) {
          const updatedData = {
            ...data,
            content: [...content, { id: newSection.id, value: newSection.value }],
          };
          updateSection(updatedData);
        }
        setInputValue('');
        setValue(null);
      }
    },
    [content, data, updateSection],
  );

  const removeSection = useCallback(
    (event, index) => {
      event.preventDefault();
      const updatedData = {
        ...data,
        content: content.filter((_, pos) => pos !== index),
      };
      updateSection(updatedData);
    },
    [content, data, updateSection],
  );

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    getClearProps,
    groupedOptions,
    getPopupIndicatorProps,
    popupOpen,
  } = useAutocomplete({
    id: 'section-box',
    options,
    autoHighlight: true,
    getOptionLabel: (option) => option.value,
    value,
    onChange: handleChange,
    inputValue,
    onInputChange: (_, newValue) => setInputValue(newValue),
  });

  const sectionView = useMemo(
    () => {
      const collapsedContent = stringifyList(content);

      return (
        <Box
          iconComponent={<SectionIcon className="skipOverride" />}
          title="Section"
          readOnly={readOnly}
          hideEllipsisButton
          collapsed={collapsed}
          collapsedContent={collapsedContent}
          updateCollapsed={onChangeCollapse}
        >
          <ContentWrapper>
            <AutoCompleteBase
              content={content}
              readOnly={readOnly}
              value={inputValue}
              placeholder="Start typing to find Section"
              direction={direction}
              {...{
                getRootProps,
                getInputProps,
                getListboxProps,
                getOptionProps,
                getClearProps,
                groupedOptions,
                getPopupIndicatorProps,
                popupOpen,
              }}
            />
            <ChipGroup list={content} removeChip={removeSection} />
          </ContentWrapper>
        </Box>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [collapsed, content, inputValue, popupOpen, readOnly, removeSection, onChangeCollapse],
  );

  return (
    <div {...attributes}>
      <DragAndDrop element={element}>
        {children}
        {sectionView}
      </DragAndDrop>
    </div>
  );
};

Section.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

Section.defaultProps = {
  attributes: {},
  children: null,
  element: {
    children: [],
    data: { content: [] },
    type: elementTypes.SECTION,
  },
};

export default memo(Section);
