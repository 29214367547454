import styled from '@emotion/styled';

import Text from 'components/text/Text';

export const ContentWrapper = styled('div')`
  height: 70vh;
`;

export const MetadataContent = styled('div')`
  margin-right: 18px;
  height: 100%;
`;

export const Label = styled(Text)`
  margin-bottom: 19px;
  padding-top: 24px;
`;

export const InputContainer = styled.div`
  width: 279px;
  margin: auto;
`;

export const InputDiv = styled.div`
  margin-bottom: 18px;
`;

export const TextAreaDiv = styled.div`
  margin-bottom: 17px;
`;

export const FormContainer = styled('form')`
  width: 100%;
`;

export const ButtonContainer = styled.div`
  margin-top: 8px;
  padding: 0 8px 8px 8px;
  display: flex;
  justify-content: space-around;
  gap: 8px;
`;
