import React, { Component } from 'react';
import Details from './details';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null, timeStamp: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
      timeStamp: new Date(),
    });
  }

  render() {
    const { errorInfo, error, timeStamp } = this.state;
    const { children, apolloClient } = this.props;
    const isDevEnv = process.env.NODE_ENV === 'development';

    if (errorInfo) {
      return (
        <Details
          client={apolloClient}
          timeStamp={timeStamp}
          isDevEnv={isDevEnv}
          error={error}
          errorInfo={errorInfo}
        />
      );
    }
    return children;
  }
}

export default ErrorBoundary;
