import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import Tooltip from 'components/tooltip';
import { ReactComponent as NewStory } from 'assets/icons/systemicons/HeaderNavbar/Resting_Outline.svg';

const CreateButtonContainer = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  ${({ theme }) => css`
    svg {
      path {
        fill: ${theme.palette.dina.whiteHighEmphasis};
        fill-opacity: ${theme.palette.mode === 'light' ? 0.65 : 0.54};
      }
    }
  `}
  :hover {
    svg {
      /* transition: all 0.2s; */
      transform: scale(1.1);
      path {
        fill-opacity: 1;
      }
    }
  }
`;

const CreateTab = (props) => (
  <CreateButtonContainer>
    <Tooltip title="Create new story" noArrow>
      <NewStory {...props} />
    </Tooltip>
  </CreateButtonContainer>
);

export default CreateTab;
