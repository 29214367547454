import Tabs, { StyleProps } from './Parts';

interface TabsProps {
  tabs: string[];
  activeTab: string;
  setActiveTab: (tab: string) => void;
  defaultTab?: string;
  styleProps?: StyleProps;
}

export default function ContainedTabs({
  tabs,
  defaultTab,
  activeTab,
  setActiveTab,
  styleProps,
}: Readonly<TabsProps>) {
  return (
    <Tabs
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      defaultTab={defaultTab ?? tabs[0]}
      styleProps={styleProps}
    >
      <Tabs.List styleProps={styleProps}>
        {tabs.map((tab) => {
          return (
            <Tabs.Trigger key={tab} active={activeTab === tab} id={tab}>
              {tab}
            </Tabs.Trigger>
          );
        })}
      </Tabs.List>
    </Tabs>
  );
}
