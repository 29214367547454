import { useState } from 'react';
import PropTypes from 'prop-types';
import viewTypes from '../viewTypes';

const MasterDetail = ({
  MasterComponent,
  DetailComponent,
  initViewType,
  initSelectedItem,
  ...rest
}) => {
  const [viewType, setViewType] = useState(initViewType);
  const [selectedItem, setSelectedItem] = useState(initSelectedItem);

  const showDetail = (item) => {
    setSelectedItem(item);
    setViewType(viewTypes.DETAIL);
  };

  const showMaster = () => {
    setViewType(viewTypes.MASTER);
    setSelectedItem(null);
  };

  return viewType === viewTypes.MASTER ? (
    <MasterComponent {...{ showDetail }} {...rest} />
  ) : (
    <DetailComponent {...{ showMaster, selectedItem }} {...rest} />
  );
};

MasterDetail.propTypes = {
  /** Defines the master component to be rendered  */
  MasterComponent: PropTypes.elementType.isRequired,
  /** Defines the detail component to be rendered  */
  DetailComponent: PropTypes.elementType.isRequired,
  /** Defines the initial component type to show, values: 'master' | 'detail'  */
  initViewType: PropTypes.oneOf(Object.values(viewTypes)),
  /** Defines the initially selected item for the detail view  */
  initSelectedItem: PropTypes.objectOf(PropTypes.any),
};

MasterDetail.defaultProps = {
  initViewType: viewTypes.MASTER,
  initSelectedItem: null,
};

export default MasterDetail;
