import styled from '@emotion/styled';
import TextField from '@material-ui/core/TextField';
import transientOptions from 'theme/helpers';

export const StyledDurationWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 120px;
  height: 40px;
`;

export const StyledDurationInput = styled(TextField, transientOptions)`
  width: 100%;
  height: 100%;
  background: transparent;

  .MuiInputBase-root {
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.palette.dina.inputBackground};
    border-radius: 2px;

    ::before {
      border-bottom: 1px solid ${({ theme }) => theme.palette.dina.disabled};
      border-bottom-right-radius: 2px;
      border-bottom-left-radius: 2px;
    }

    ::after {
      border-bottom: 2px solid ${({ theme }) => theme.palette.dina.onFocus};
      border-bottom-right-radius: 2px;
      border-bottom-left-radius: 2px;
    }

    &:hover:not(.Mui-disabled) {
      background-color: ${({ theme }) => theme.palette.dina.outlineButtonOnHover};
      ::before {
        border-bottom: 1px solid ${({ theme }) => theme.palette.dina.inputBorderResting};
      }
    }
  }

  input {
    ${({ theme }) => theme.typography.dina.listItemLabelMedium}
    width: 100%;
    height: 100%;
    text-align: center;
    border: none;
    outline: none;
    padding: 0;
    line-height: 16px;
    letter-spacing: 0.25px;
    color: ${({ $error, theme }) =>
      $error ? theme.palette.dina.errorText : theme.palette.dina.highEmphasis};

    &:focus {
      font-style: italic;
    }
  }
`;
