/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { useCallback, useMemo, useState } from 'react';
import { DragPreviewImage, useDrag } from 'react-dnd';
import { RenderElementProps, useReadOnly, useSlate } from 'slate-react';

import { ReactComponent as ArrowDown } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_down.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_right.svg';
import { ReactComponent as UnGroupIcon } from 'assets/icons/systemicons/is_grouped_false.svg';
import DragSingle from 'assets/images/rundown/DragSingle.png';
import { elementTypes } from 'components/editor/constants';
import useChangeCollapse from 'components/editor/hooks/useChangeCollapse';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import selectElement from 'components/editor/utils/selectElement';
import updateBlock from 'components/editor/utils/updateBlock';
import useCheckUserRight from 'hooks/useCheckUserRight';
import dndTypes from 'utils/dndTypes';
import useLogger from 'utils/useLogger';

import DragAndDrop from '../dragAndDrop';
import Menu from '../menu';

import unwrapSection from './utils/unwrapSection';

import {
  ArrowWrapper,
  HeaderWrapper,
  HiddenChildrenWrapper,
  Input,
  NormalChildWrapper,
  Wrapper,
} from './styled';

interface MenuItemType {
  title: string;
  action: string;
  icon?: React.ReactNode;
  divider?: boolean;
}

const menuItems: MenuItemType[] = [
  {
    title: 'Expand/Collapse section',
    action: 'toggleCollapse',
    divider: true,
  },
  { title: 'Ungroup Section', action: 'unwrap', icon: <UnGroupIcon /> },
];

interface ArrowProps {
  collapsed: boolean;
  onClick: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}

function Arrow({ collapsed, onClick }: ArrowProps) {
  return (
    <ArrowWrapper>
      {collapsed ? (
        <ArrowRight onClick={onClick} className="skipOverride" />
      ) : (
        <ArrowDown onClick={onClick} className="skipOverride" />
      )}
    </ArrowWrapper>
  );
}

function SectionDivider({
  attributes = { 'data-slate-node': 'element', ref: null },
  children = null,
  element = {
    type: elementTypes.PARAGRAPH,
    children: [{ text: '' }],
  },
}: RenderElementProps) {
  const editor = useSlate();
  const { platformId, update } = useEditorContext();
  const [checkUserRight] = useCheckUserRight();
  const [onChangeCollapse] = useChangeCollapse(element);
  const logger = useLogger('Section Divider Component');

  const readOnly = useReadOnly();

  const canShowSettings = checkUserRight('feature', 'settings');

  const { data = {}, type } = element;
  const { mTitle = '', collapsed: sectionCollapsed = false } = data;

  const [collapsed, setCollapsed] = useState(sectionCollapsed);

  const toggleCollapse = useCallback(
    (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      setCollapsed((pre) => !pre);
      onChangeCollapse(event);
    },
    [onChangeCollapse],
  );

  const handleUnwrapSection = useCallback(() => {
    selectElement(editor, element);
    unwrapSection(editor);
  }, [editor, element]);

  const handleMenuSelect = useCallback(
    (val: MenuItemType, event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      switch (val.action) {
        case 'configure':
          if (!platformId || !type) {
            logger.error(
              `Missing parameters to edit form, platformId: ${platformId}, boxType: ${type}`,
            );
            return;
          }
          return;
        case 'unwrap':
          handleUnwrapSection();
          return;
        case 'toggleCollapse':
          toggleCollapse(event);
          return;
        default:
      }
    },
    [platformId, type, handleUnwrapSection, toggleCollapse, logger],
  );

  const handleSaveTitle = useCallback(
    (newTitle: string) => {
      const updatedData = {
        ...data,
        mTitle: newTitle,
      };
      updateBlock(editor, element, updatedData, update, false, undefined);
    },
    [data, editor, element, update],
  );

  const ChildrenWrapper = useMemo(
    () => (collapsed ? HiddenChildrenWrapper : NormalChildWrapper),
    [collapsed],
  );

  const [{ isDragging }, drag, preview] = useDrag({
    type: dndTypes.EDITOR_BLOCK,
    item: () => ({ type: dndTypes.EDITOR_BLOCK, payload: element }),
    canDrag: () => !readOnly,
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  return (
    <div {...attributes}>
      <Wrapper $isDragging={isDragging}>
        <DragPreviewImage connect={preview} src={DragSingle} />
        <HeaderWrapper contentEditable={false} ref={drag}>
          <Arrow collapsed={collapsed} onClick={toggleCollapse} />
          <Input value={mTitle} placeholder="Section name" onUpdate={handleSaveTitle} />
          {canShowSettings && <Menu items={menuItems} onSelect={handleMenuSelect} />}
        </HeaderWrapper>
        <DragAndDrop element={element} isDragDisabled>
          <ChildrenWrapper contentEditable={collapsed ? false : undefined}>
            {children}
          </ChildrenWrapper>
        </DragAndDrop>
      </Wrapper>
    </div>
  );
}

export default SectionDivider;
