import { useCallback } from 'react';

import { EditOrderProperties } from 'components/editor/Sidepanel';
import useToast from 'components/toast/useToast';
import useGoToOrderResource from 'features/orderForm/components/useGoToOrderResource';
import { Box } from 'layouts/box/Box';
import { ResourceType } from 'types/forms/forms';
import { MemberType } from 'types/graphqlTypes';

import { OrderHeader } from './Headers';

interface Props {
  member: MemberType | null;
  onClose: () => void;
}

export default function OrderPreview({ member, onClose }: Readonly<Props>) {
  const { goToResource } = useGoToOrderResource();
  const { errorToast } = useToast();

  const doGoToResource = useCallback(() => {
    if (member?.mId && member?.mResourceType)
      goToResource(member.mId, member.mResourceType as ResourceType)
        .then(() => {
          onClose();
        })
        .catch(errorToast);
  }, [goToResource, onClose, member]);

  if (!member) {
    return <div>No preview found</div>;
  }

  return (
    <Box width="800px" height="100%">
      <OrderHeader
        order={member}
        onClose={onClose}
        title={member?.mTitle ?? ''}
        handleOpenOrder={doGoToResource}
      />
      <Box padding="10px" overflow="auto" height="calc(100% - 42px)">
        <EditOrderProperties orderId={member.mRefId} />
      </Box>
    </Box>
  );
}
