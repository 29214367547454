import { MMetaDataField } from 'types/graphqlTypes';

const getHostReadSpeed = (metadata: MMetaDataField[], defaultReadSpeed = 150) => {
  if (!Array.isArray(metadata)) return defaultReadSpeed;
  const readSpeedField = metadata.find((val) => val.key === 'hostReadSpeed');
  return readSpeedField && !isNaN(Number(readSpeedField.value))
    ? Number(readSpeedField.value)
    : defaultReadSpeed;
};

export default getHostReadSpeed;
