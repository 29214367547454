import { useState } from 'react';

import useCheckUserRight from 'hooks/useCheckUserRight';
import { useRightSidebarSavedSearches } from 'store/sidebar';

import Dialog from 'components/dialog';
import Divider from 'components/divider';
import Searchbar from 'components/searchbar';
import Scrollbar from 'screens/main/components/scrollbar';
import RelevantHeader from '../../relevantHeader';
import MasterWrapper from '../../masterWrapper';
import useStyles from './list-styles';
import ListContainer from './list-container';
import menuItems from './menu-items';
import menuActions from './menu-actions';
import CreateDepartment from '../createDepartment';

const ListView = ({
  containerRef,
  showDetail,
  filterModel,
  updateFilterModel,
  history,
  updateHistory,
  currentSearch,
  updateCurrentSearch,
  searchString,
}) => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState(<div />);
  const [rightSidebarSavedSearches, setRightSidebarSavedSearches] = useRightSidebarSavedSearches();
  const [checkUserRight] = useCheckUserRight();
  const canCreateDepartment = checkUserRight('folder', 'create-departments');
  const filteredMenuItems = canCreateDepartment
    ? menuItems
    : menuItems.filter((item) => item.action !== menuActions.CREATE_DEPARTMENT);

  const handleCreateNewFilter = (newFilter) => {
    setRightSidebarSavedSearches([...rightSidebarSavedSearches, newFilter]);
  };

  const handleUpdateFilters = (newSavedFilters) => {
    setRightSidebarSavedSearches(newSavedFilters);
  };

  const onMenuItemClick = (action) => {
    switch (action) {
      case menuActions.CREATE_DEPARTMENT:
        setDialogContent(
          <CreateDepartment
            onCancel={() => setDialogOpen(false)}
            onSubmit={(department) => {
              showDetail({
                id: department.mId,
                name: department.mTitle,
                image: department.mAvatarUrl,
                description: department.mDescription,
              });
              setDialogOpen(false);
            }}
          />,
        );
        setDialogOpen(true);
        break;
      default:
        setDialogContent(<div />);
        setDialogOpen(false);
        break;
    }
  };

  return (
    <>
      <Dialog
        open={dialogOpen}
        container={containerRef.current}
        BackdropProps={{ classes: { root: classes.backdropRoot } }}
        onClose={() => setDialogOpen(false)}
      >
        {dialogContent}
      </Dialog>

      <MasterWrapper
        header={
          <RelevantHeader
            headerTitle="Departments"
            menuItems={filteredMenuItems}
            {...{ onMenuItemClick }}
          />
        }
        body={
          <>
            <Divider />
            <Scrollbar>
              <ListContainer onClick={showDetail} searchString={searchString} />
            </Scrollbar>
          </>
        }
      />
      <Searchbar
        savedFilters={rightSidebarSavedSearches}
        onCreateFilter={handleCreateNewFilter}
        onUpdateFilters={handleUpdateFilters}
        {...{
          filterModel,
          updateFilterModel,
          history,
          updateHistory,
          currentSearch,
          updateCurrentSearch,
        }}
      />
    </>
  );
};

export default ListView;
