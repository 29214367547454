import { useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';

import useHover from 'hooks/useHover';

import Popover from 'components/popover';
import Tooltip from 'components/tooltip/Tooltip';

import { ReactComponent as PinOn } from 'assets/icons/systemicons/pin_on.svg';
import { ReactComponent as Delete } from 'assets/icons/systemicons/delete_small.svg';
import { ReactComponent as Folder } from 'assets/icons/systemicons/folder.svg';
import { ReactComponent as Arrow } from 'assets/icons/systemicons/arrows/disclosurearrow_right_opaque.svg';

import ConfirmationComponent from '../linearEllipsisMenu/components/confirmationComponent';
import {
  OptionsWrapper,
  DisplayWrapper,
  MenuItem,
  IconWrapper,
  ListItemText,
  PinOff,
} from './menu-item-styled';

const handleEvent = (event) => {
  event.preventDefault();
  event.stopPropagation();
};

const MenuItemFolder = forwardRef(
  (
    {
      anchorEl,
      label,
      data,
      onClick,
      icon,
      secondaryItem,
      showSecondaryItem,
      disabled,
      showDeleteButton,
      onDeleteButtonClick,
      isMuted,
      selected,
      pinned,
      togglePin,
      showPin,
      showArrow,
      title,
    },
    ref,
  ) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [isPinned, setIsPinned] = useState(false);
    const [hoverRef, isHovered] = useHover();

    useEffect(() => {
      setIsPinned(pinned);
    }, [pinned]);

    const handlePinned = (event) => {
      handleEvent(event);
      togglePin(!pinned);
      setIsPinned((previousState) => !previousState);
    };

    const handleDeleteButtonClick = (event) => {
      handleEvent(event);
      setOpenDialog(true);
    };

    const handleDialogClose = (event) => {
      handleEvent(event);
      setOpenDialog(false);
    };

    const handleConfirm = (event) => {
      handleEvent(event);
      onDeleteButtonClick();
      handleDialogClose(event);
    };

    const handleClick = (event) => {
      handleEvent(event);
      onClick(event);
    };

    return (
      <>
        <Tooltip title={title}>
          <MenuItem
            onClick={handleClick}
            $isMuted={isMuted}
            disabled={disabled}
            selected={selected}
            ref={ref}
          >
            <IconWrapper>{icon}</IconWrapper>
            <ListItemText primary={label} title={disabled && 'Not implemented yet'} />
            {(showSecondaryItem && secondaryItem) ||
              (data && (
                <OptionsWrapper>
                  {data.length > 0 && data.length}
                  {showPin && (
                    <div ref={hoverRef}>
                      {isPinned ? (
                        <>
                          {isHovered ? <PinOff onClick={handlePinned} title="Unpin" /> : <PinOn />}
                        </>
                      ) : (
                        <DisplayWrapper>
                          <PinOn title="Pin" onClick={handlePinned} />
                        </DisplayWrapper>
                      )}
                    </div>
                  )}
                  {showDeleteButton && (
                    <DisplayWrapper>
                      <Delete title="Delete" onClick={handleDeleteButtonClick} />
                    </DisplayWrapper>
                  )}
                  {showArrow && <Arrow />}
                </OptionsWrapper>
              ))}
          </MenuItem>
        </Tooltip>
        {openDialog && (
          <Popover anchorEl={anchorEl} onClose={handleDialogClose} noMargin>
            <ConfirmationComponent
              label={label}
              onOk={handleConfirm}
              onCancel={handleDialogClose}
            />
          </Popover>
        )}
      </>
    );
  },
);

MenuItemFolder.propTypes = {
  /** name of menuItem */
  label: PropTypes.string,
  /** contents of menuItem */
  data: PropTypes.arrayOf(PropTypes.any),
  /** callback to open menuItem folder */
  onClick: PropTypes.func,
  /** icon of menuItem */
  icon: PropTypes.node,
  /** Secondary Action item */
  secondaryItem: PropTypes.node,
  /** Boolean to show secondary action item */
  showSecondaryItem: PropTypes.bool,
  /** Boolean to disable the MenuItem */
  disabled: PropTypes.bool,
  /** Boolean to show the delete button */
  showDeleteButton: PropTypes.bool,
  /** Callback to be invoked when delete is clicked */
  onDeleteButtonClick: () => {},
  /** Node for the popover to attach to */
  anchorEl: PropTypes.instanceOf(Element),
  /** boolean to reduce the opacity to half */
  isMuted: PropTypes.bool,
  /** boolean to indicate a selected item */
  selected: PropTypes.bool,
  /** show arrow on right for menu item */
  showArrow: PropTypes.bool,
  /** tooltip title */
  title: PropTypes.string,
};

MenuItemFolder.defaultProps = {
  label: '',
  data: [],
  onClick: () => {},
  icon: <Folder />,
  secondaryItem: null,
  showSecondaryItem: true,
  disabled: false,
  showDeleteButton: false,
  onDeleteButtonClick: () => {},
  anchorEl: null,
  isMuted: false,
  selected: false,
  showArrow: true,
  title: undefined,
};

export default MenuItemFolder;
