import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.dina.surfaceAppBackgroundNavLevel2,
  },
  text: {
    ...theme.typography.dina.overline,
    paddingTop: '8px',
    marginLeft: '16px',
  },
  divider: {
    paddingLeft: '72px',
  },
  listItem: {
    paddingInline: '16px',
  },
}));

export default useStyles;
