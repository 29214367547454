import Editor from 'components/editor';
import editorVariants from 'components/editor/constants/types/editorVariants';
import memberTypes from 'graphql/memberTypes';

import { HighlightedText, StyledRegularContent } from './styled';

interface DetailComponentProps {
  contentValue?: string;
  contentHighlightedText?: string;
  variant?: string;
  user?: string;
  updatedBy?: string;
}

function DetailComponent({
  contentValue,
  contentHighlightedText,
  variant,
  user,
  updatedBy,
}: Readonly<DetailComponentProps>) {
  if (variant === memberTypes.MESSAGE && user !== memberTypes.SYSTEM_USER) {
    return (
      <Editor
        placeholder=""
        renderToolbar={() => null}
        variant={editorVariants.MESSAGE}
        background="transparent"
        height="100%"
        readOnly
        padding={0}
        value={contentValue ? (JSON.parse(contentValue) as string) : {}}
        hostReadSpeed={undefined}
        thumbnail={undefined}
        isAllowed={undefined}
        setEditor={undefined}
        platformStructure={undefined}
        platformId={undefined}
        isCmsBlock={undefined}
        editorFontSize={undefined}
        getPlaceholderConfigs={undefined}
        withSignedUrl={undefined}
        direction={undefined}
        platformKind={undefined}
      />
    );
  }

  return (
    <StyledRegularContent>
      {updatedBy && variant !== memberTypes.MESSAGE && (
        <HighlightedText>{updatedBy}</HighlightedText>
      )}
      {contentValue}

      {contentHighlightedText && <HighlightedText>{contentHighlightedText}</HighlightedText>}
    </StyledRegularContent>
  );
}

export default DetailComponent;
