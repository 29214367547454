import { useMutation } from '@apollo/client';

import { MemberType, MemberTypeEnum } from 'types/graphqlTypes';

import { CREATE_DRAFT } from './graphql';

export interface CreateDraftProps {
  mTitle?: string;
  mDescription?: string;
}

type CreateDraftReturnType = {
  createMember: MemberType;
};

/**
 * Create a new draft item
 * @param {string} mTitle - Title of the draft
 * @param {string} mDescription - Description of the draft
 * @returns {MemberType} - The newly created draft
 */
const useCreateDraft = () => {
  const [createMember] = useMutation<CreateDraftReturnType>(CREATE_DRAFT);

  const createDraft = async ({ mTitle = 'New draft', mDescription = '' }: CreateDraftProps) => {
    const { data } = await createMember({
      variables: {
        input: {
          mTitle,
          mDescription,
          mType: MemberTypeEnum.Draft,
        },
      },
    });

    const newDraft = data?.createMember as MemberType;

    return newDraft;
  };

  return { createDraft };
};

export default useCreateDraft;
