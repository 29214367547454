import { useState } from 'react';
import MenuCreator from 'screens/main/components/rightArea/menuCreator/MenuCreator';
import DeleteIcon from '@material-ui/icons/Delete';
import { ReactComponent as Upload } from 'assets/icons/systemicons/upload.svg';
import useImageUpload from 'hooks/useImageUpload';
import useArchiveMember from 'hooks/useArchiveMember';
import memberTypes from 'graphql/memberTypes';
import { DeleteDialog } from 'components/dialogs/CommonDialogs';
import getInitials from 'utils/getInitials';
import AvatarRow from './avatar/AvatarRow';
import DetailHeader from '../../../detailHeader';

import useStyles from './header-styles';

const topRowOptions = [{ label: 'Archive Team', action: 'archiveTeam', Icon: DeleteIcon }];

const avatarOptions = [{ label: 'Upload from Computer...', action: 'upload', Icon: Upload }];

const Header = ({
  title,
  type,
  noOfMembers,
  onBackButtonClick,
  avatarUrl,
  updateAvatar,
  teamId,
  updateTeamTitle,
  canDeleteTeam,
}) => {
  const [showTopMenu, setShowTopMenu] = useState(null);
  const [showAvatarMenu, setShowAvatarMenu] = useState(null);
  const [uploadedAvatar, setUploadedAvatar] = useState(null);
  const [isArchiveDialogOpen, setArchiveDialogOpen] = useState(false);

  const classes = useStyles();

  const onImageLoad = (imageFile) => {
    const imageSrc = URL.createObjectURL(imageFile);

    setUploadedAvatar(imageSrc);
    updateAvatar(imageFile, imageSrc);
  };

  const captureImage = useImageUpload({ onImageLoad });

  const [archiveMember] = useArchiveMember();

  const avatarImage = uploadedAvatar || avatarUrl;

  const archiveTeam = (id) => {
    archiveMember(id, memberTypes.TEAM).then(() => {
      setArchiveDialogOpen(false);
      onBackButtonClick();
    });
  };

  return (
    <div
      className={classes.headerDiv}
      style={{
        backgroundImage: avatarImage
          ? `url(${avatarImage})`
          : `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='100%' width='100%'><text x='10' y='155' fill='lightcoral' font-size='200' font-weight='700'>${getInitials(
              title,
            )}</text></svg>"`,
      }}
    >
      <div className={classes.avatar} />

      <DetailHeader
        backButtonLabel="Teams"
        id={teamId}
        type={memberTypes.TEAM}
        title={title}
        showMenu
        hideMenu
        onBackButtonClick={onBackButtonClick}
        onMenuButtonClick={
          canDeleteTeam &&
          ((event) => {
            setShowTopMenu(event.currentTarget);
          })
        }
      />
      <MenuCreator
        onClose={({ action }) => {
          if (action) {
            if (action === 'archiveTeam') setArchiveDialogOpen(true);
          }
          setShowTopMenu(null);
        }}
        anchorEl={showTopMenu}
        menuOptions={topRowOptions}
        position="right-top"
      />

      <AvatarRow
        teamId={teamId}
        title={title}
        avatarUrl={avatarImage}
        type={type}
        noOfMembers={noOfMembers}
        onAvatarClick={(event) => setShowAvatarMenu(event.currentTarget)}
        updateTeamTitle={updateTeamTitle}
      />
      <MenuCreator
        onClose={({ action }) => {
          if (action) {
            if (action === 'upload') captureImage();
          }
          setShowAvatarMenu(null);
        }}
        anchorEl={showAvatarMenu}
        menuOptions={avatarOptions}
        position="left-center"
      />
      <DeleteDialog
        open={isArchiveDialogOpen}
        onClose={() => setArchiveDialogOpen(false)}
        onClick={() => archiveTeam(teamId)}
        title="Archive team?"
        confirmLabel="Archive"
        message={`Team: "${title}" will be deleted.`}
      />
    </div>
  );
};

export default Header;
