import { memo } from 'react';
import { ClickAwayListener } from '@material-ui/core';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Button } from 'components/buttons';

const ButtonWrapper = styled('div')`
  ${({ width }) =>
    css`
      width: ${width}px;
    `}
`;

const ActionButton = ({ label, icon, onClick, type, onClickAway, width }) => {
  const isConfirm = type === 'confirm';
  const renderedLabel = isConfirm ? 'Click again to remove' : label;

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <ButtonWrapper width={width}>
        <Button
          align="left"
          variant={isConfirm ? 'contained' : 'outlined'}
          usage={isConfirm ? 'danger' : 'outlined'}
          startIcon={icon}
          onClick={onClick}
        >
          {renderedLabel}
        </Button>
      </ButtonWrapper>
    </ClickAwayListener>
  );
};

ActionButton.propTypes = {
  /** Label of the button */
  label: PropTypes.string,
  /** Icon React component */
  icon: PropTypes.element,
  /** onClick callback function */
  onClick: PropTypes.func,
  /** type of the button */
  type: PropTypes.string,
  /** click away from button callback */
  onClickAway: PropTypes.func,
  /** width of the component */
  width: PropTypes.number,
};

ActionButton.defaultProps = {
  label: '',
  icon: null,
  onClick: () => {},
  type: 'default',
  onClickAway: () => {},
  width: 220,
};

export default memo(ActionButton);
