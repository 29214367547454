import React, { useMemo, memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSlate } from 'slate-react';

import Tooltip from 'components/tooltip';
import IframeViewer from 'components/iframeViewer';
import { menuOptions } from 'components/editor/constants';
import { removeBlock, updateBlock, makeValidUrl } from 'components/editor/utils';
import MetadataEditor from 'components/metadataEditor';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import { elementTypes } from 'components/editor/constants/types';
import TitleInput from 'components/instanceCard/components/header/components/titleInput';

import Box from '../box';

import {
  AtomOffIcon,
  UrlIcon,
  OpenIcon,
  PreviewIcon,
  UrlWrapper,
  IconsWrapper,
  TitleWrapper,
  Title,
} from './styled';

const defaultDescription = {
  urlPlaceholder: 'Type or paste an URL...',
};

function Url({ attributes, children, element }) {
  const { update, formsForThisPlatform, platformId } = useEditorContext();
  const editor = useSlate();

  const { type: elementType, data } = element;
  const { href = '', metadata } = data;
  const { showMetadata, title } = metadata || {};

  const [url, setUrl] = useState(href);
  const [showUrlInput, setShowUrlInput] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(false);

  const blockForm = formsForThisPlatform?.[elementType];

  const handleShowInput = useCallback(() => setShowUrlInput((prop) => !prop), []);

  const handleOnClickAway = useCallback(() => {
    setShowUrlInput((prop) => !prop);
    setUrl(href);
  }, [href]);

  const handleInternalPreview = useCallback(() => setPreviewUrl((prop) => !prop), []);

  const handleOpenExternally = useCallback(() => window.open(url, '_blank'), [url]);

  const onMenuSelect = useCallback(
    ({ action }) => {
      if (action === 'delete-block') removeBlock(editor, element, update);
      if (action === 'show-metadata') updateBlockMetadata(!showMetadata, 'showMetadata');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor, element],
  );

  const blockMenuItems = useMemo(() => {
    const hasSetMetadata = blockForm?.mProperties?.fields?.length;
    const extendedOptions = [
      {
        title: `${showMetadata ? 'Hide' : 'Show'} Metadata`,
        action: 'show-metadata',
        icon: <AtomOffIcon />,
        divider: true,
      },
    ];
    return hasSetMetadata ? [...extendedOptions, ...menuOptions] : [...menuOptions];
  }, [blockForm, showMetadata]);

  const updateBlockMetadata = useCallback(
    (newValue, metaPropName) => {
      const oldMetaData = metadata || {};
      const updatedData = {
        ...data,
        metadata: {
          ...oldMetaData,
          [metaPropName]: newValue,
        },
      };
      updateBlock(editor, element, updatedData, update, undefined, null);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor, element],
  );

  const handleUrlUpdate = useCallback(
    (value) => {
      const validUrl = value && makeValidUrl(value);
      setUrl(validUrl);

      const updatedData = {
        ...element.data,
        href: validUrl,
      };
      updateBlock(editor, element, updatedData, update, undefined, null);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor, element],
  );

  const RenderUrlBlock = useMemo(
    () => (
      <Box
        title={elementType}
        iconComponent={<UrlIcon className="skipOverride" />}
        boxType={elementType}
        element={element}
        platformId={platformId}
        menuItems={blockMenuItems}
        onMenuSelect={onMenuSelect}
        showArrowIcon={false}
      >
        <UrlWrapper $hasMetadata={showMetadata} $isInput={showUrlInput}>
          {showUrlInput ? (
            <TitleInput
              title={url}
              setTitle={setUrl}
              initialTitle={href}
              onClickAway={handleOnClickAway}
              onClose={handleShowInput}
              onUpdate={handleUrlUpdate}
              isUsedByBlock
            />
          ) : (
            <TitleWrapper>
              <Title onClick={handleShowInput} $default={url || undefined}>
                {url || defaultDescription.urlPlaceholder}
              </Title>
            </TitleWrapper>
          )}

          {url && !showUrlInput && (
            <IconsWrapper>
              <Tooltip title="Preview">
                <PreviewIcon className="skipOverride" onClick={handleInternalPreview} />
              </Tooltip>
              <Tooltip title="Open">
                <OpenIcon className="skipOverride" onClick={handleOpenExternally} />
              </Tooltip>
            </IconsWrapper>
          )}
        </UrlWrapper>

        {blockForm && showMetadata && (
          <MetadataEditor
            model={blockForm}
            setPayload={(u) => updateBlockMetadata(u.value, u.fieldId)}
            payload={metadata}
          />
        )}

        {previewUrl && (
          <IframeViewer src={url} title={title || url} open closeDialog={handleInternalPreview} />
        )}
      </Box>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      url,
      showUrlInput,
      blockForm,
      showMetadata,
      previewUrl,
      metadata,
      title,
      element,
      updateBlockMetadata,
    ],
  );

  return (
    <div {...attributes}>
      {children}
      {RenderUrlBlock}
    </div>
  );
}

Url.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

Url.defaultProps = {
  attributes: {},
  children: null,
  element: {
    type: elementTypes.URL,
    children: [],
  },
};

export default memo(Url);
