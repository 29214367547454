import { isHighlight } from 'components/command/command-types';
import { safeForHTML } from 'components/command/command-utils';
import { MemberType } from 'types/graphqlTypes';

export const getHighlightedTitle = (item: MemberType) => {
  if (item.highlight) {
    try {
      const text = JSON.parse(item.highlight) as unknown;
      if (isHighlight(text)) {
        if (text.mTitle?.length) {
          return {
            __html: `${safeForHTML(text.mTitle[0])}`,
          };
        }
      }
    } catch (err: unknown) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }

  return {
    __html: `${item.mTitle}`,
  };
};
