export const STANDARD_MDF_KEYS = `
  id
  label
  views {
    default {
      fieldId
      hint
      visible
      multiline
      fieldHeight
      label
    }
    order_grid {
      fieldId
      hint
      visible
      multiline
      fieldHeight
      label
    }
    order_form {
      fieldId
      hint
      visible
      multiline
      fieldHeight
      label
    }
    story_view {
      fieldId
      hint
      visible
      label
    }
    story_create {
      fieldId
      hint
      visible
      label
    }
  }
  fields {
    fieldId
    required
    systemDefault
    defaultValue
    freeform
    type
    filter
    alternatives {
      id
      label
      value
    }
    treeAlternatives
  }
  permissions {
    read
    write
  }
`;

export const STANDARD_ORDER_FORM_KEYS = `
  mId
  mRefId
  mTitle
  mDescription
  mSecId
  configs {
    key
    name
    values
    alternatives {
      id
      label
      value
    }
  }
`;

export const STANDARD_AI_PROMPT_KEYS = `
  mId
  mRefId
  configs {
    key
    alternatives {
      id
      label
      value
    }
  }
`;

export const STANDARD_ACTION_ADMIN_KEYS = `
  mRefId
  mTitle
  mDescription
  mUpdatedAt
  mTypes
  mActive
  externalId
  endpoint
  iconUrl
  auth {
    type
  }
`;

export const STANDARD_ACTION_KEYS = `
  mRefId
  mTitle
  mDescription
  mTypes
  iconUrl
`;

export const getErrorMessage = <E, I>(err: E, input: I, type?: string): string =>
  `${type ?? ''} ${err instanceof Error ? err.message : ''} - input: ${JSON.stringify(input)}`;
