import React, { useContext, useEffect, useMemo, useState } from 'react';

import { ReactComponent as CloseIcon } from 'assets/icons/systemicons/close.svg';
import { Button } from 'components/buttons';
import CheckBox from 'components/checkbox';
import QuickSearch from 'components/command/QuickSearch';
import AssignMembers from 'components/createNewV3/assignMember';
import { generateTooltipText } from 'components/editMdfDialog/utils';
import { LoadingButtonIndicator } from 'components/loadingIndicator';
import { DateRange } from 'components/mdfEditor/fields/date/DatePicker';
import { MdfEditor } from 'components/mdfEditor/MdfEditor';
import SplitBar from 'components/split';
import Text from 'components/text/Text';
import Tooltip from 'components/tooltip';
import UserCtx from 'contexts/UserContext';
import useCheckUserRight from 'hooks/useCheckUserRight';
import { CreateStoryInput } from 'hooks/useCreateStory';
import { Box } from 'layouts/box/Box';
import { AssignedMember } from 'types';
import { Metadata, NewFieldValue } from 'types/forms/forms';
import { Mdf } from 'types/graphqlTypes';

import DatePickerButton from './datePicker/scheduleDatePicker';

import {
  ButtonsWrapper,
  CheckBoxLabel,
  CheckBoxWithOptions,
  CheckBoxWrapper,
  CloseButton,
  ContentWrapper,
  Footer,
  Header,
  MetadataWrapper,
  RestrictedInfo,
  RestrictedText,
  WarningIcon,
  Wrapper,
} from './styled';

interface CheckboxWithLabelProps {
  selected: boolean;
  onClick: (val: boolean) => void;
  label: string;
  disabled?: boolean;
}

export const CheckboxWithLabel = ({
  selected,
  onClick,
  label,
  disabled,
}: CheckboxWithLabelProps) => (
  <CheckBoxWrapper onClick={() => onClick(!selected)} role="presentation">
    <CheckBox disabled={disabled} selected={selected} disableHoverEffect size={24} />
    <CheckBoxLabel>{label}</CheckBoxLabel>
  </CheckBoxWrapper>
);

interface CreateNewProps {
  canCreateStory: boolean;
  canCreatePitch: boolean;
  doCreate: (props: CreateStoryInput) => Promise<void>;
  onCancel: () => void;
  hideRestrictedOption: boolean;
  loading: boolean;
  setDimension: React.Dispatch<
    React.SetStateAction<{
      width: string;
      height: string;
    }>
  >;
  storyMdf: Mdf | undefined;
  preselectedMember?: AssignedMember;
  preselectedMetadata?: {
    metadata: Metadata;
  };
  scheduleDate?: string;
  mTitle?: string;
  mContent?: string;
}

const CreateNew = ({
  canCreateStory,
  canCreatePitch,
  doCreate,
  onCancel,
  loading,
  hideRestrictedOption,
  setDimension,
  preselectedMember,
  preselectedMetadata,
  scheduleDate,
  mTitle,
  mContent,
  storyMdf,
}: CreateNewProps) => {
  const [metadata, setMetadata] = useState<Metadata>(preselectedMetadata?.metadata ?? {});
  const [keepOpen, setKeepOpen] = useState(false);
  const [showCreated, setShowCreated] = useState(false);
  const [creatingType, setCreatingType] = useState<'story' | 'pitch'>('story');
  const [openStory, setOpenStory] = useState(true);
  const publishingTimes = { startDate: scheduleDate ?? new Date().toISOString(), endDate: null };
  const [errorMap, setErrorMap] = useState<Record<string, string | undefined>>({});
  // type useContext/UserContext
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  const { attributes: user } = useContext(UserCtx) as unknown as { attributes: AssignedMember };
  const [title, setTitle] = useState('');
  const [restricted, setRestricted] = useState(false);
  const [scheduled, setScheduled] = useState<DateRange | null>(publishingTimes);
  const [submitting, setSubmitting] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState<AssignedMember[]>([
    ...(preselectedMember ? [preselectedMember] : []),
    { ...user, isCreator: true },
  ]);

  const [messageAssignees, setMessageAssignees] = useState('');
  const [checkUserRight] = useCheckUserRight();
  const canSelectRange = checkUserRight('feature', 'story-date-range');
  const canSelectMetadata =
    checkUserRight('feature', 'story-metadata') && (storyMdf?.fields ?? []).length > 0;
  const onErrorUpdate = ({ fieldId, error }: { fieldId: string; error: string | undefined }) => {
    setErrorMap((prevState) => ({
      ...prevState,
      [fieldId]: error,
    }));
  };

  const titleErrorTooltip = !title?.trim() ? 'Title is required' : '';

  const errorTooltip = useMemo(() => {
    const labelMap: Record<string, string> = {};
    if (!storyMdf) return undefined;
    for (const field of storyMdf.fields) {
      const layoutSettings = storyMdf.views.order_form ?? storyMdf.views.default;
      labelMap[field.fieldId] =
        layoutSettings.find((s) => s.fieldId === field.fieldId)?.label ?? field.fieldId;
    }
    const errors = generateTooltipText(errorMap, labelMap);
    if (!errors.length) return undefined;
    return (
      <>
        <div>Please resolve errors:</div>
        <ul style={{ padding: '0 0 0 20px' }}>
          {errors.map((err) => (
            <li key={err}>{err}</li>
          ))}
        </ul>
      </>
    );
  }, [errorMap, storyMdf]);

  useEffect(() => {
    setDimension({ width: '400px', height: selectedMembers.length > 1 ? '480px' : '410px' });
    setTitle(mTitle?.trim() ?? '');
  }, []);

  const handleCreate = (type: 'story' | 'pitch') => {
    if (submitting || !title.trim().length) return;
    setSubmitting(true);
    setCreatingType(type);
    const { startDate, endDate } = scheduled ?? { startDate: undefined, endDate: undefined };
    const assignedMembers = selectedMembers.map(({ mId, mType }) => ({ mId, mType }));
    if (title)
      void doCreate({
        type,
        keepOpen,
        openStory,
        mId: user.mId,
        mCreatedById: user.mId,
        mTitle: title.trim(),
        isRestricted: restricted,
        mPublishingAt: startDate,
        mPublishingEnd: endDate ?? undefined,
        mAssignedMembers: assignedMembers,
        messageAssign: messageAssignees,
        metadata: JSON.stringify(metadata),
        mContent: mContent ?? undefined,
      }).finally(() => {
        setSubmitting(false);
        setShowCreated(true);
        setTimeout(() => {
          setShowCreated(false);
        }, 2000);
      });
  };

  const toggleRestriction = () => setRestricted((pre) => !pre);

  const updateFieldValue = (val: NewFieldValue) => {
    const newMetadata: Metadata = {
      ...metadata,
      [val.fieldId]: val.value,
    };
    setMetadata(newMetadata);
  };

  const onKeyDown = (ev: React.KeyboardEvent) => {
    if (
      ev.key !== 'Enter' ||
      !(canCreateStory || canCreatePitch) ||
      Boolean(errorTooltip ?? titleErrorTooltip)
    )
      return;
    if (ev.shiftKey && canCreatePitch) return handleCreate('pitch');
    handleCreate(canCreateStory ? 'story' : 'pitch');
  };

  const MainStoryLayout = useMemo(() => {
    return (
      <ContentWrapper>
        <Text variant="overline">Title</Text>
        <QuickSearch
          searchString={title}
          setSearchString={setTitle}
          onKeyDown={onKeyDown}
          titleErrorTooltip={titleErrorTooltip}
        />
        <Text variant="overline">Schedule date</Text>
        <DatePickerButton
          dateRange={scheduled}
          onDateRangeChange={setScheduled}
          canSelectRange={canSelectRange}
          disableUpdate={false}
        />
        {!hideRestrictedOption && (
          <CheckBoxWithOptions>
            <Tooltip
              title={
                <RestrictedInfo>
                  <WarningIcon className="skipOverride" />
                  <RestrictedText>
                    Only you and assigned members
                    <br /> can access this story.
                  </RestrictedText>
                </RestrictedInfo>
              }
            >
              <div style={{ width: '150px' }}>
                <CheckboxWithLabel
                  selected={restricted}
                  disabled={false}
                  onClick={toggleRestriction}
                  label="Restrict access"
                />
              </div>
            </Tooltip>
          </CheckBoxWithOptions>
        )}
        <CheckBoxWithOptions>
          <Text variant="overline">Members</Text>
          <AssignMembers
            assignedMembers={selectedMembers}
            setAssignedMembers={setSelectedMembers}
            messageAssignees={messageAssignees}
            setMessageAssignees={setMessageAssignees}
            placeholder="Type an optional message to new members.."
          />
        </CheckBoxWithOptions>
      </ContentWrapper>
    );
  }, [
    setTitle,
    setScheduled,
    canSelectRange,
    scheduled,
    hideRestrictedOption,
    selectedMembers,
    setSelectedMembers,
    messageAssignees,
    setMessageAssignees,
    restricted,
    toggleRestriction,
  ]);

  return (
    <Wrapper>
      <Header className={'dragHandler'}>
        Create new {submitting && '- creating...'}
        {showCreated && !submitting && '- created!'}
        <CloseButton onClick={onCancel}>
          <CloseIcon />
        </CloseButton>
      </Header>
      {canSelectMetadata ? (
        <SplitBar
          style={undefined}
          split={undefined}
          primary="first"
          pane1Style={{
            minWidth: '300px',
            maxWidth: '80%',
          }}
          pane2Style={{
            minWidth: '10%',
            maxWidth: '70%',
          }}
        >
          {MainStoryLayout}
          <MetadataWrapper>
            {storyMdf && (
              <MdfEditor
                fields={storyMdf.fields}
                defaultLayoutSettings={storyMdf.views.default}
                layoutSettings={storyMdf.views.story_create}
                metadata={metadata}
                permissions={storyMdf.permissions}
                updateFieldValue={updateFieldValue}
                errorMap={errorMap}
                updateErrorMap={onErrorUpdate}
              />
            )}
          </MetadataWrapper>
        </SplitBar>
      ) : (
        MainStoryLayout
      )}
      <Footer>
        <Box container margin="0 0 0 -3px">
          <Tooltip title="Keep dialog open to create more stories/pitches">
            <span>
              <CheckboxWithLabel
                selected={keepOpen}
                disabled={false}
                onClick={() => setKeepOpen(!keepOpen)}
                label="Keep open"
              />
            </span>
          </Tooltip>
          <Tooltip title="Opens new story/pitch in a tab">
            <span style={{ marginLeft: '5px' }}>
              <CheckboxWithLabel
                selected={openStory}
                disabled={false}
                onClick={() => setOpenStory(!openStory)}
                label="Open in tab"
              />
            </span>
          </Tooltip>
        </Box>
        <ButtonsWrapper>
          {canCreatePitch && (
            <Tooltip title={errorTooltip ?? titleErrorTooltip}>
              <span>
                <Button
                  ariaLabel="Create Pitch"
                  disabled={Boolean(errorTooltip ?? titleErrorTooltip) || submitting}
                  onClick={() => handleCreate('pitch')}
                  width={100}
                  height={28}
                  usage="pitch"
                  variant="contained"
                >
                  {loading && creatingType === 'pitch' ? (
                    <LoadingButtonIndicator />
                  ) : (
                    'Create pitch'
                  )}
                </Button>
              </span>
            </Tooltip>
          )}
          {canCreateStory && (
            <Tooltip title={errorTooltip ?? titleErrorTooltip}>
              <span>
                <Button
                  ariaLabel="Create Story"
                  disabled={Boolean(errorTooltip ?? titleErrorTooltip) || submitting}
                  onClick={() => handleCreate('story')}
                  usage="story"
                  width={100}
                  height={28}
                  variant="contained"
                >
                  {loading && creatingType === 'story' ? (
                    <LoadingButtonIndicator />
                  ) : (
                    'Create story'
                  )}
                </Button>
              </span>
            </Tooltip>
          )}
        </ButtonsWrapper>
      </Footer>
    </Wrapper>
  );
};

export default CreateNew;
