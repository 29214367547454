/* eslint-disable max-len */
import { Transforms } from 'slate';
import uuidv1 from 'uuid/v1';

import { elementTypes } from 'components/editor/constants/types';

const { delete: deleteText, insertNodes, move } = Transforms;

export const insertScript = (editor, name, update) => {
  const scriptElement = {
    type: elementTypes.SCRIPT_INFO,
    data: {
      name,
      itemId: uuidv1(),
      inTime: null,
      duration: null,
      description: '',
      mediaUrl: '',
      collapsed: false,
    },
    children: [{ text: '' }],
  };

  insertNodes(editor, scriptElement);
  move(editor);
  update({
    type: `${elementTypes.SCRIPT_INFO}-insert`,
    payload: {
      document: editor.children,
      insertedElement: scriptElement,
    },
  });

  return editor;
};

/**
 * @param  {Object} editor - editor instance
 * @param  {Object} event - event instance
 * @param  {string} variant - editor variant
 * @param  {import('slate').BaseRange} target - target match
 * @param  {number} index - index of search item
 * @param  {string[]} scripts - array of available script
 * @param  {React.Dispatch<React.SetStateAction<number>>} setIndex - callback fn for setting index
 * @param  {React.Dispatch<React.SetStateAction<import('slate').BaseRange>>} setTarget - callback fn for setting the target
 * @param  {callback fn} handleUpdate - callback fn to update editor
 */
const onScriptKeyDown = async (
  editor,
  event,
  target,
  index,
  scripts,
  setIndex,
  setTarget,
  handleUpdate,
) => {
  const prevIndex = index <= 0 ? scripts.length - 1 : index - 1;
  const nextIndex = index >= scripts.length - 1 ? 0 : index + 1;
  if (target) {
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        setIndex(nextIndex);
        break;
      case 'ArrowUp':
        event.preventDefault();
        setIndex(prevIndex);
        break;
      case 'Tab':
      case 'Enter':
        event.preventDefault();
        deleteText(editor, { at: target });
        insertScript(editor, scripts[index], handleUpdate);
        setTarget(null);
        break;
      case 'Escape':
        event.preventDefault();
        setTarget(null);
        break;
      default:
        break;
    }
  }

  return editor;
};

export default onScriptKeyDown;
