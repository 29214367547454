import { ReactComponent as BookMarksOff } from 'assets/icons/systemicons/ContainerLeft_Navbar/bookmarks_off.svg';
import { ReactComponent as BookMarksOn } from 'assets/icons/systemicons/ContainerLeft_Navbar/bookmarks_on.svg';
import { ReactComponent as LeftDepartmentsOff } from 'assets/icons/systemicons/ContainerLeft_Navbar/departments_off.svg';
import { ReactComponent as LeftDepartmentsOn } from 'assets/icons/systemicons/ContainerLeft_Navbar/departments_on.svg';
import { ReactComponent as AllInstancesOff } from 'assets/icons/systemicons/ContainerLeft_Navbar/instances_off.svg';
import { ReactComponent as AllInstancesOn } from 'assets/icons/systemicons/ContainerLeft_Navbar/instances_on.svg';
import { ReactComponent as AllStoriesOff } from 'assets/icons/systemicons/ContainerLeft_Navbar/Left_AllStories_off.svg';
import { ReactComponent as AllStoriesOn } from 'assets/icons/systemicons/ContainerLeft_Navbar/Left_AllStories_on.svg';
import { ReactComponent as MyStoriesOff } from 'assets/icons/systemicons/ContainerLeft_Navbar/mystories_off.svg';
import { ReactComponent as Search } from 'assets/icons/search/search.svg';
import { ReactComponent as MyStoriesOn } from 'assets/icons/systemicons/ContainerLeft_Navbar/mystories_on.svg';
import { ReactComponent as RundownsOff } from 'assets/icons/systemicons/ContainerLeft_Navbar/rundowns_off.svg';
import { ReactComponent as RundownsOn } from 'assets/icons/systemicons/ContainerLeft_Navbar/rundowns_on.svg';
import { ReactComponent as AllPitchesOff } from 'assets/icons/systemicons/ContainerLeft_Navbar/storymarket_off.svg';
import { ReactComponent as AllPitchesOn } from 'assets/icons/systemicons/ContainerLeft_Navbar/storymarket_on.svg';
import { ReactComponent as TeamStoriesOff } from 'assets/icons/systemicons/ContainerLeft_Navbar/teamStories_off.svg';
import { ReactComponent as TeamStoriesOn } from 'assets/icons/systemicons/ContainerLeft_Navbar/teamStories_on.svg';
import { ReactComponent as MyContactsOff } from 'assets/icons/systemicons/ContainerRight_Navbar/contacts_off.svg';
import { ReactComponent as MyContactsOn } from 'assets/icons/systemicons/ContainerRight_Navbar/contacts_on.svg';
import { ReactComponent as RightDepartmentsOff } from 'assets/icons/systemicons/ContainerRight_Navbar/departments_off.svg';
import { ReactComponent as RightDepartmentsOn } from 'assets/icons/systemicons/ContainerRight_Navbar/departments_on.svg';
import { ReactComponent as GraphicsOff } from 'assets/icons/systemicons/ContainerRight_Navbar/graphics_off.svg';
import { ReactComponent as GraphicsOn } from 'assets/icons/systemicons/ContainerRight_Navbar/graphics_on.svg';
import { ReactComponent as MosOff } from 'assets/icons/systemicons/ContainerRight_Navbar/mos_off.svg';
import { ReactComponent as MosOn } from 'assets/icons/systemicons/ContainerRight_Navbar/mos_on.svg';
import { ReactComponent as DailyNoteOn } from 'assets/icons/systemicons/ContainerRight_Navbar/dailyNotes_on.svg';
import { ReactComponent as DailyNoteOff } from 'assets/icons/systemicons/ContainerRight_Navbar/dailyNotes_off.svg';
import { ReactComponent as StoryBoxOff } from 'assets/icons/systemicons/ContainerRight_Navbar/storybox_off.svg';
import { ReactComponent as StoryBoxOn } from 'assets/icons/systemicons/ContainerRight_Navbar/storybox_on.svg';
import { ReactComponent as TeamsOff } from 'assets/icons/systemicons/ContainerRight_Navbar/teams_off.svg';
import { ReactComponent as TeamsOn } from 'assets/icons/systemicons/ContainerRight_Navbar/teams_on.svg';
import { ReactComponent as EvervizOff } from 'assets/icons/systemicons/ContainerRight_Navbar/everviz_red.svg';
import { ReactComponent as EvervizOn } from 'assets/icons/systemicons/ContainerRight_Navbar/everviz_blue.svg';
import { ReactComponent as PluginOn } from 'assets/icons/systemicons/ContainerRight_Navbar/plugin_on.svg';
import { ReactComponent as PluginOff } from 'assets/icons/systemicons/ContainerRight_Navbar/plugin_off.svg';

import memberTypes from 'graphql/memberTypes';

import selectionTypes from '../../rightArea/selectionTypes';
import { StyledImage } from '../verticalNavbar-styled';

/**
 * Title's are mapped to the LeftTabTour, see tours.js
 */
export const leftMenuItems = [
  {
    title: 'Search',
    selectionType: 'search',
    SelectedComponent: Search,
    UnselectedComponent: Search,
    divider: true,
  },
  {
    title: 'Bookmarks',
    selectionType: memberTypes.USER_BOOKMARK,
    SelectedComponent: BookMarksOn,
    UnselectedComponent: BookMarksOff,
    divider: true,
  },
  {
    title: 'My Stories',
    selectionType: memberTypes.USER,
    SelectedComponent: MyStoriesOn,
    UnselectedComponent: MyStoriesOff,
  },
  {
    title: 'My Teams',
    selectionType: memberTypes.TEAM_USER,
    SelectedComponent: TeamStoriesOn,
    UnselectedComponent: TeamStoriesOff,
  },
  {
    title: 'My Departments',
    selectionType: memberTypes.DEPARTMENT_USER,
    SelectedComponent: LeftDepartmentsOn,
    UnselectedComponent: LeftDepartmentsOff,
    divider: true,
  },
  {
    title: 'All Stories',
    selectionType: memberTypes.STORY,
    SelectedComponent: AllStoriesOn,
    UnselectedComponent: AllStoriesOff,
  },
  {
    title: 'All Pitches',
    selectionType: memberTypes.PITCH,
    SelectedComponent: AllPitchesOn,
    UnselectedComponent: AllPitchesOff,
  },
  {
    title: 'All Instances',
    selectionType: memberTypes.INSTANCE,
    SelectedComponent: AllInstancesOn,
    UnselectedComponent: AllInstancesOff,
    divider: true,
  },
  {
    title: 'Rundowns',
    selectionType: memberTypes.RUNDOWN_USER,
    SelectedComponent: RundownsOn,
    UnselectedComponent: RundownsOff,
  },
];

/**
 * Title's are mapped to the RightTabTour, see tours.js
 */
export const rightMenuItems = [
  {
    title: 'Daily Note',
    selectionType: selectionTypes.DAILYNOTE,
    SelectedComponent: DailyNoteOn,
    UnselectedComponent: DailyNoteOff,
  },
  {
    title: 'Mos',
    selectionType: selectionTypes.MOS,
    SelectedComponent: MosOn,
    UnselectedComponent: MosOff,
  },
  {
    title: 'Graphics',
    selectionType: selectionTypes.GRAPHICSBOX,
    SelectedComponent: GraphicsOn,
    UnselectedComponent: GraphicsOff,
  },
  {
    title: 'Assets',
    selectionType: selectionTypes.STORYBOX,
    SelectedComponent: StoryBoxOn,
    UnselectedComponent: StoryBoxOff,
    divider: true,
  },
  {
    title: 'Contacts',
    selectionType: selectionTypes.CONTACTS,
    SelectedComponent: MyContactsOn,
    UnselectedComponent: MyContactsOff,
  },
  {
    title: 'Teams',
    selectionType: selectionTypes.TEAMS,
    SelectedComponent: TeamsOn,
    UnselectedComponent: TeamsOff,
  },
  {
    title: 'Departments',
    selectionType: selectionTypes.DEPARTMENT,
    SelectedComponent: RightDepartmentsOn,
    UnselectedComponent: RightDepartmentsOff,
  },
];

const setIcons = (cur) => {
  const { label: title, mTitle: selectionType, panelIcon } = cur || {};
  const panel = { title, selectionType };
  if (panelIcon && panelIcon.startsWith('http')) {
    panel.SelectedComponent = () => <StyledImage src={panelIcon} />;
    panel.UnselectedComponent = () => <StyledImage src={panelIcon} $unselected={true} />;
    return panel;
  }
  let switchVal = panelIcon || title;
  switch (switchVal?.toLowerCase()) {
    case 'everviz':
      panel.SelectedComponent = EvervizOn;
      panel.UnselectedComponent = EvervizOff;
      break;
    case 'graphics':
      panel.SelectedComponent = GraphicsOn;
      panel.UnselectedComponent = GraphicsOff;
      break;
    case 'assets':
      panel.SelectedComponent = StoryBoxOn;
      panel.UnselectedComponent = StoryBoxOff;
      break;
    case 'mos':
      panel.SelectedComponent = MosOn;
      panel.UnselectedComponent = MosOff;
      break;
    default:
      panel.SelectedComponent = PluginOn;
      panel.UnselectedComponent = PluginOff;
  }
  return panel;
};

/**
 * Returns an array of html panels that should be displayed in the right panel.
 */
export const getHtmlPanels = (externalUrls) => {
  if (!externalUrls) return [];

  const htmlPanels = externalUrls.reduce((acc, cur) => {
    if (cur.showInRightPanel === 'true') {
      const urlWithIcons = setIcons(cur);
      acc.push(urlWithIcons);

      return acc;
    }
    return acc;
  }, []);

  if (htmlPanels.length > 0) {
    htmlPanels.at(-1).divider = true;
  }

  return htmlPanels;
};
