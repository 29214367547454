import memberTypes from 'graphql/memberTypes';
import { useMutation } from '@apollo/client';
import useLogger from 'utils/useLogger';
import ASSIGN_USER_TO_INSTANCE from 'graphql/mutations/assignUserToInstance';

const memberRelationEnum = {
  [memberTypes.USER]: memberTypes.USER_INSTANCE,
  [memberTypes.TEAM]: memberTypes.TEAM_INSTANCE,
  [memberTypes.DEPARTMENT]: memberTypes.DEPARTMENT_INSTANCE,
};

const useAssignInstance = () => {
  const [assignUserToInstance] = useMutation(ASSIGN_USER_TO_INSTANCE);
  const logger = useLogger('use assign instance');
  const assignMembersToInstance = async (
    instanceId,
    addedIds,
    removedIds,
    updatedIds,
    messageAssign,
  ) => {
    const addedMembersInput = addedIds.map(({ mId, mType }) => ({
      mId,
      mRefId: instanceId,
      mType: memberRelationEnum[mType],
      messageAssign,
    }));

    const removedMembersInput = removedIds.map(({ mId, mType }) => ({
      mId,
      mRefId: instanceId,
      mType: memberRelationEnum[mType],
    }));

    const updateAssigneesInput = {
      mId: instanceId,
      mRefId: instanceId,
      mAssignedMembers: updatedIds.map(({ mId, mType }) => ({
        mId,
        mType,
      })),
    };

    const newMembers = {
      members: addedMembersInput,
    };

    const removedMembers = {
      members: removedMembersInput,
    };

    try {
      await assignUserToInstance({
        variables: { newMembers, removedMembers, updateAssigneesInput },
        optimisticResponse: {
          __typename: 'Mutation',
          addMembers: newMembers.members.map((member) => ({
            ...member,
            __typename: 'MemberType',
          })),
          removeMembers: removedMembers.members.map((member) => ({
            ...member,
            __typename: 'MemberType',
          })),
          updateAssignees: {
            ...updateAssigneesInput,
            mAssignedMembers: updateAssigneesInput.mAssignedMembers.map((member) => ({
              ...member,
              __typename: 'AssignedMemberType',
            })),
            mUpdatedAt: new Date().toISOString(),
            __typename: 'MemberType',
          },
        },
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      logger.log(e);
    }
  };
  return [assignMembersToInstance];
};

export default useAssignInstance;
