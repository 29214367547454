import uuidv1 from 'uuid/v1';
import { Transforms } from 'slate';
import normalizeOnListFocus from 'components/editor/utils/normalizeOnListFocus';

const { insertNodes } = Transforms;

/**
 * Inserts block element
 *
 * @param {Object} options
 * @param {Object} options.editor SlateJS editor instance
 * @param {Function} options.update Callback to be invoked on update
 * @returns {Object} SlateJS editor instance
 */
const insertBlock = ({
  editor,
  update,
  type,
  data = {},
  children = [{ text: '' }],
  options = {},
}) => {
  const blockElement = {
    type,
    data: { ...data, itemId: uuidv1() },
    children,
  };
  normalizeOnListFocus(editor);

  insertNodes(editor, blockElement, options);
  update({
    type: `${type}-insert`,
    payload: {
      document: editor.children,
      insertedElement: blockElement,
    },
  });
  return editor;
};

export default insertBlock;
