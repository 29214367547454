/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Fade } from '@material-ui/core';
import { ReactComponent as CalendarOff } from 'assets/icons/systemicons/calendar_off.svg';
import { ReactComponent as CalendarOn } from 'assets/icons/systemicons/calendar_on.svg';
import Divider from 'components/divider';
import Select from 'components/select';
import Popover from 'components/popover';
import CategorizedMenu from 'components/menu/createInstanceMenu/MenuItem/CategorizedMenu';
import PlatformIcons from 'components/menu/createInstanceMenu/PlatformIcons';
import { isPast, isThisMinute, isAfter, differenceInMinutes, addMinutes } from 'date-fns';
import getRundownPublishingTime from 'utils/getRundownPublishingTime';
import variants from 'utils/instance/variants';
import useGetPlatforms from 'hooks/useGetPlatforms';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import { scheduleNow, setHoursIsoString, setDateIsoString } from './utils';
import Picker from './components/picker';
import Footer from './components/footer';
import {
  Header,
  DialogContainer,
  Content,
  SelectLabel,
  LinearDateTimePicker,
  AccountLabel,
  AccountInfo,
} from './publishSettings-styled';
import { ResetTextButton } from './components/scheduleOptions/styled';
import scheduleOptions from './utils/scheduleOptions';
import RadioButtons from './components/radioButtons';
import ScheduleOptions from './components/scheduleOptions';

const stringToBool = (str) => str === 'true';

const stopEventBubble = (event) => {
  event.preventDefault();
  event.stopPropagation();
};

const { UNPUBLISH, UNSCHEDULE, SCHEDULE, UPDATE, REPUBLISH } = scheduleOptions;

const getSelectedOption = (publishTime, isCmsRepublish) => {
  if (isCmsRepublish) return UPDATE;
  return publishTime ? SCHEDULE : UNSCHEDULE;
};

const AccountSelect = ({
  isLinear,
  items = [],
  selectedValue,
  onChange,
  publishingAt,
  children,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const actionRef = useRef(null);

  if (!isLinear)
    return <Select items={items} hideLabel selectedValue={selectedValue} onChange={onChange} />;

  return (
    <>
      <div role="presentation" onClick={(event) => setAnchorEl(event.currentTarget)}>
        <Select open={false} items={items} hideLabel selectedValue={selectedValue} />
      </div>
      <Popover
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        action={actionRef}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div style={{ width: '332px' }}>
          <CategorizedMenu
            hideDatePicker
            publishingAt={publishingAt}
            items={items.map((item) => item.account)}
            updateOnLoading={() => actionRef?.current?.updatePosition()}
            onClose={(account) => {
              setAnchorEl(null);
              onChange(account.accountId || account.accountTitle);
            }}
            selectedValue={selectedValue}
            dense
            darker
          >
            {children}
          </CategorizedMenu>
        </div>
      </Popover>
    </>
  );
};

AccountSelect.propTypes = {
  isLinear: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  selectedValue: PropTypes.string,
  onChange: PropTypes.func,
  publishingAt: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
};

function PublishSettings({
  variant,
  publishingPoint,
  platformKind,
  selectedDestination,
  publishingTime,
  expiryTime,
  onOK,
  onCancel,
  anchorEl,
  isCmsBlock,
  publishMetadata,
  updateInstanceMetadata,
  canRepublishInstance,
  publishingPointIcon,
}) {
  /**
   * Returns the initial timestamp to be used to set the time value
   * If the initial publishing time is in the past, the value is set to now().
   */
  const getInitialTimeISOString = (forceReset) => {
    const idatestr = publishingTime || selectedDestination.publishingTime;
    if (!idatestr || forceReset) return new Date().toISOString();
    const pdate = new Date(idatestr);
    const idate = isPast(pdate) ? new Date() : pdate;
    return idate.toISOString();
  };

  const offsetInMinutes = expiryTime ? differenceInMinutes(expiryTime, publishingTime) : 0;
  const isCmsRepublish = canRepublishInstance && publishingTime;

  const [forcePublishMetadata, silentUpdateMetadata] = publishMetadata;

  const [expiryOffset, setExpiryOffset] = useState(offsetInMinutes);
  const [newPublishingTime, setNewPublishingTime] = useState(getInitialTimeISOString());
  const [showExpiryDate, setShowExpiryDate] = useState(Boolean(expiryTime));
  const [forcePublish, setForcePublish] = useState(forcePublishMetadata.value);
  const [newExpiryTime, setNewExpiryTime] = useState(expiryTime);
  const [isPublishingTimeChanged, setIsPublishingTimeChanged] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    getSelectedOption(publishingTime, isCmsRepublish),
  );

  useEffect(() => {
    setSelectedOption(getSelectedOption(publishingTime, isCmsRepublish));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publishingTime, isCmsRepublish]);

  const toggleShowExpiryDate = () => setShowExpiryDate((previousState) => !previousState);

  const [publishNow, setPublishNow] = useState(scheduleNow(newPublishingTime));

  const [confirmPublish, setConfirmPublish] = useState(false);

  const [, error, loading, destinations] = useGetPlatforms(
    newPublishingTime,
    publishingPoint,
    platformKind,
    !anchorEl,
  );

  const PlatformIcon =
    PlatformIcons[publishingPointIcon] ??
    PlatformIcons[platformKind ?? publishingPoint] ??
    PlatformIcons.defaultIcon;

  const items = destinations.map((item) => ({
    ...item,
    icon: <PlatformIcon />,
  }));

  const [selectedValue, setSelectedValue] = useState(
    selectedDestination.value || (destinations.length > 0 && destinations[0].value),
  );

  useEffect(() => {
    setSelectedValue(selectedDestination.value);
  }, [selectedDestination]);

  const isLinear = variant === variants.LINEAR;

  const handleForcePublishClick = () => {
    const newForcePublishValue = stringToBool(forcePublish) ? 'false' : 'true';
    updateInstanceMetadata([
      {
        key: forcePublishMetadata.key,
        value: newForcePublishValue,
      },
    ]);
    setForcePublish(newForcePublishValue);
  };

  const onResetDateTime = () => {
    setIsPublishingTimeChanged(true);
    const calculatedTime = getInitialTimeISOString(true);
    setNewPublishingTime(calculatedTime);
    setNewExpiryTime(addMinutes(calculatedTime, expiryOffset));
  };

  const getLinearPublishingTime = (destination, date) => {
    const { startTime, timeZone } = destination;
    return getRundownPublishingTime(new Date(date), startTime, timeZone);
  };

  const calculateExpiryTime = () => {
    if (
      selectedOption === UNSCHEDULE ||
      selectedOption === UNPUBLISH ||
      !showExpiryDate ||
      !newExpiryTime
    )
      return null;
    if (isPast(newExpiryTime)) return new Date().toISOString();
    return newExpiryTime;
  };

  const calculatePublishingTime = (destination) => {
    if (isLinear) return getLinearPublishingTime(destination, newPublishingTime);
    if (selectedOption === UNSCHEDULE || selectedOption === UNPUBLISH) return null;

    if (
      !isPublishingTimeChanged &&
      showExpiryDate &&
      publishingTime &&
      expiryTime !== newExpiryTime
    )
      return publishingTime;

    if (isPast(newPublishingTime)) return new Date().toISOString();
    return newPublishingTime;
  };

  const handleOK = async (event) => {
    stopEventBubble(event);

    /* If publish/unpublish button clicked, set the button to confirm publish */
    if (
      !isLinear &&
      (selectedOption === SCHEDULE || selectedOption === UNPUBLISH) &&
      !confirmPublish
    ) {
      setConfirmPublish(true);
      return;
    }

    const destination = destinations.find((d) => d.value === selectedValue);

    /* If update or republish, send silent-update and if expiry date is selected,
    update schedule with that */
    if (!isLinear && (selectedOption === UPDATE || selectedOption === REPUBLISH)) {
      const silentMetadataValue = selectedOption === UPDATE ? 'true' : 'false';
      await updateInstanceMetadata([{ key: silentUpdateMetadata.key, value: silentMetadataValue }]);
    }

    /* If no destination selected (unassigned) for linear instance
       return with publishing time to be null */
    if (isLinear && !destination.id) {
      onOK({
        selectedDestination: destination,
        publishingTime: null,
      });
      return;
    }

    /* calculate publishing time with selected destination */
    const newPublishingSettings = {
      selectedDestination: destination,
      publishingTime: calculatePublishingTime(destination),
    };

    /* calculate expiry time if it's not a linear instance */
    if (!isLinear) {
      newPublishingSettings.expiryTime = calculateExpiryTime();
    }
    onOK(newPublishingSettings);
    setIsPublishingTimeChanged(false);

    if (selectedOption === UNPUBLISH) setSelectedOption(UNSCHEDULE);
    setConfirmPublish(false);
  };

  const handleCancel = (event) => {
    stopEventBubble(event);
    setNewPublishingTime(getInitialTimeISOString());
    setConfirmPublish(false);
    onCancel();
  };

  const handleDateChange = (value) => {
    if (!value) return;
    const calculatedDate = setDateIsoString(newPublishingTime, value);
    if (newExpiryTime) setNewExpiryTime(addMinutes(calculatedDate, expiryOffset));
    setNewPublishingTime(calculatedDate);
    setIsPublishingTimeChanged(true);
  };

  const handleTimeChange = (value) => {
    if (!value) return;
    const calculatedTime = setHoursIsoString(newPublishingTime, value);
    if (newExpiryTime) setNewExpiryTime(addMinutes(calculatedTime, expiryOffset));
    setNewPublishingTime(calculatedTime);
    setIsPublishingTimeChanged(true);
  };

  const handleExpiryDateChange = (value) => {
    if (!value) return;
    const calculatedExpiryDate = setDateIsoString(
      newExpiryTime || addMinutes(new Date(), 1),
      value,
    );
    if (
      isAfter(calculatedExpiryDate, newPublishingTime) &&
      isAfter(calculatedExpiryDate, new Date())
    ) {
      setExpiryOffset(differenceInMinutes(calculatedExpiryDate, newPublishingTime));
      setNewExpiryTime(calculatedExpiryDate);
    }
  };

  const handleExpiryTimeChange = (value) => {
    if (!value) return;
    const calculatedExpiryDate = setHoursIsoString(
      newExpiryTime || new Date().toISOString(),
      value,
    );
    if (
      isAfter(calculatedExpiryDate, newPublishingTime) &&
      isAfter(calculatedExpiryDate, new Date())
    ) {
      setExpiryOffset(differenceInMinutes(calculatedExpiryDate, newPublishingTime));
      setNewExpiryTime(calculatedExpiryDate);
    }
  };

  const onDestinationChange = (value) => {
    setSelectedValue(value);
  };

  useEffect(() => {
    setPublishNow(scheduleNow(newPublishingTime));
    !selectedValue &&
      setSelectedValue(
        selectedDestination.value || (destinations.length > 0 && destinations[0].value),
      );
  }, [newPublishingTime, selectedDestination, destinations, selectedValue]);

  const shouldDisablePublish = destinations.some(
    (destination) => destination.value === selectedValue,
  );

  if (error) return <div>{error.message}</div>;

  return (
    <Popover
      anchorEl={anchorEl}
      position="publish-setting-custom"
      onClose={handleCancel}
      TransitionComponent={Fade}
      noMargin
      disableEnforceFocus
    >
      <DialogContainer>
        <Header>Schedule Settings</Header>
        <Divider />
        <Content>
          {loading && <LoadingIndicator />}
          <SelectLabel>Account</SelectLabel>
          {isCmsRepublish ? (
            <AccountInfo>
              <PlatformIcon />
              <AccountLabel>{selectedDestination.value}</AccountLabel>
            </AccountInfo>
          ) : (
            <AccountSelect
              items={items}
              isLinear={isLinear}
              selectedValue={selectedValue || null}
              onChange={onDestinationChange}
              publishingAt={newPublishingTime}
            >
              <PlatformIcon />
            </AccountSelect>
          )}
          {!isLinear && (
            <RadioButtons
              selectedOption={selectedOption}
              onSelectOption={setSelectedOption}
              setConfirmPublish={setConfirmPublish}
              isCmsRepublish={isCmsRepublish}
            />
          )}

          {!isLinear && selectedOption !== UNSCHEDULE && selectedOption !== UNPUBLISH && (
            <ScheduleOptions
              toggleShowExpiryDate={toggleShowExpiryDate}
              publishingTime={publishingTime}
              newPublishingTime={newPublishingTime}
              handleDateChange={handleDateChange}
              isLinear={isLinear}
              handleTimeChange={handleTimeChange}
              expiryTime={expiryTime}
              newExpiryTime={newExpiryTime}
              handleExpiryDateChange={handleExpiryDateChange}
              handleExpiryTimeChange={handleExpiryTimeChange}
              handleForcePublishClick={handleForcePublishClick}
              forcePublish={forcePublish}
              isCmsBlock={isCmsBlock}
              showExpiryDate={showExpiryDate}
              onResetDateTime={onResetDateTime}
              publishNow={publishNow}
              isCmsRepublish={isCmsRepublish}
            />
          )}

          {isLinear && (
            <LinearDateTimePicker>
              <Picker
                Icon={CalendarOff}
                SecondaryIcon={CalendarOn}
                displaySecondaryIcon={publishingTime || !isThisMinute(newPublishingTime)}
                type="date"
                label="Publish Date"
                timeValue={newPublishingTime}
                onChange={handleDateChange}
              />
              <ResetTextButton onClick={onResetDateTime} disabled={publishNow}>
                Set to Today
              </ResetTextButton>
            </LinearDateTimePicker>
          )}
        </Content>
        <Divider />
        <Footer
          selectedOption={selectedOption}
          handleCancel={handleCancel}
          isLinear={isLinear}
          handleOK={handleOK}
          publishNow={publishNow}
          confirmPublish={confirmPublish}
          shouldDisablePublish={shouldDisablePublish}
        />
      </DialogContainer>
    </Popover>
  );
}

PublishSettings.propTypes = {
  /** Variant of component */
  variant: PropTypes.oneOf(Object.values(variants)),
  /** Publishing time of the instance */
  publishingTime: PropTypes.string,
  /** Type of the instance */
  publishingPoint: PropTypes.string,
  /** Configured publishing point icon */
  publishingPointIcon: PropTypes.string,
  /** Selected destination of this instance */
  selectedDestination: PropTypes.shape({}),
  /** Callback to be invoked when ok button is clicked */
  onOK: PropTypes.func,
  /** Callback to be invoked when cancel button is clicked */
  onCancel: PropTypes.func,
  /** Default DOM element to anchor */
  anchorEl: PropTypes.objectOf(PropTypes.object),
  /** Expiry time of the instance */
  expiryTime: PropTypes.string,
  /** Whether CMS block structure is being used or not */
  isCmsBlock: PropTypes.bool,
};

PublishSettings.defaultProps = {
  variant: 'General',
  publishingTime: '',
  publishingPoint: '',
  selectedDestination: {},
  onOK: () => {},
  onCancel: () => {},
  anchorEl: null,
  expiryTime: '',
  isCmsBlock: false,
  publishingPointIcon: '',
};

export default PublishSettings;
