import React, { useState, useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import useGetAutomationTemplates from 'hooks/useGetAutomationTemplates';
import { useSlate, useReadOnly, useSelected } from 'slate-react';
import { elementTypes, outTimingTypes } from 'components/editor/constants/types';
import SelectedElement from 'components/editor/components/selectedElement';
import PlaceholderDialog from 'components/editor/components/placeholderDialog';
import removePlaceholder from 'components/editor/components/placeholderDialog/utils/removePlaceholder';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import useSettingsValue from 'hooks/useSettingsValue';
import { refreshSelection } from 'components/editor/utils';
import iconComponents from './constants/iconComponents';
import Box from './components/box';
import InTimingSelect from './components/inTimingSelect';
import OutTimingSelect from './components/outTimingSelect';
import Dialog from './components/dialog';
import SecondaryDropZone from './components/secondaryDropZone';
import GraphicsDropZone from './components/graphicsDropZone';
import ClipDropZone from './components/clipDropZone';
import CharacterGraphics from './components/characterGrapgics';
import TitleBox from './components/title';
import getInitialData from './utils/getInitialData';
import {
  BoxWrapper,
  ContentWrapper,
  SelectWrapper,
  VerticalBorder,
  TitleBoxWrapper,
  EllipsisMenuWrapper,
  EllipsisMenu,
} from './styled';
import DragAndDrop from '../dragAndDrop';

const { MANUAL_OUT } = outTimingTypes;
const { OVERLAY_GRAPHICS } = elementTypes;

const SecondaryAutomation = ({ attributes, children, element }) => {
  const editor = useSlate();
  const readOnly = useReadOnly();
  const { update } = useEditorContext();
  const { data, type } = element;
  const isSelected = useSelected(element);
  const { mosobj, protocol } = data;
  const [openDetails, setOpenDetails] = useState(false);
  const [placeholderDialogOpen, setPlaceholderDialogOpen] = useState(false);
  const initialData = useMemo(() => getInitialData(data), [data]);
  const { inTiming, outTiming, templateVariant, assets } = initialData;
  const Icon = iconComponents[type];
  const isGraphic = type === OVERLAY_GRAPHICS;
  const [getSettingsValue] = useSettingsValue();

  const hideInOutTimingInGraphics = getSettingsValue('app.hideInOutTimingInGraphics') === 'true';
  const showInoutTiming = isGraphic ? !hideInOutTimingInGraphics : true;

  const { templates, resources } = useGetAutomationTemplates();

  const placeholder = useMemo(
    () => assets.find(({ mediaType }) => mediaType === 'video/placeholder') || false,
    [assets],
  );

  const template = useMemo(
    () =>
      templates && templates.find((item) => item.type === type && item.variant === templateVariant),
    [templateVariant, templates, type],
  );

  const { graphics = false, video = false, image = false } = template?.requires || {};

  const hasPlaceholder = Boolean(placeholder);

  const onBlur = useCallback(() => refreshSelection(editor, element), [editor, element]);

  const handleOpenDetails = useCallback(() => setOpenDetails(true), []);

  const handleCloseDetails = useCallback(() => setOpenDetails(false), []);

  const openPlaceholderDialog = useCallback(() => setPlaceholderDialogOpen(true), []);

  const closePlaceholderDialog = useCallback(() => setPlaceholderDialogOpen(false), []);

  const handleRemovePlaceholder = useCallback(
    () => removePlaceholder(editor, initialData, placeholder, update),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialData, placeholder],
  );

  return (
    <div {...attributes} onBlur={onBlur}>
      <DragAndDrop element={element} hideHighlight>
        <SelectedElement element={element}>
          <SecondaryDropZone element={element}>
            <GraphicsDropZone element={element} canDropGraphics={graphics}>
              <ClipDropZone element={element} canDropVideo={video} canDropImage={image}>
                {children}
                <BoxWrapper contentEditable={false} readOnly={readOnly}>
                  <Box
                    inTimingType={inTiming}
                    outTimingType={isGraphic ? outTiming : MANUAL_OUT}
                    isGraphic={isGraphic}
                    isSelected={isSelected}
                  >
                    <ContentWrapper>
                      {showInoutTiming && (
                        <>
                          <SelectWrapper>
                            <InTimingSelect element={element} />
                            {isGraphic && <OutTimingSelect element={element} />}
                          </SelectWrapper>
                          <VerticalBorder />
                        </>
                      )}
                      <TitleBoxWrapper $fullWidth={!showInoutTiming}>
                        {protocol === 'starcg' ? (
                          <CharacterGraphics
                            data={data}
                            resources={resources}
                            isGraphic={isGraphic}
                            initialData={initialData}
                          />
                        ) : (
                          <TitleBox
                            initialData={initialData}
                            type={type}
                            templates={templates}
                            resources={resources}
                            isGraphic={isGraphic}
                          />
                        )}
                      </TitleBoxWrapper>

                      <EllipsisMenuWrapper>
                        <Icon className="automationIcon" />
                        <EllipsisMenu
                          isGraphic={isGraphic}
                          removePlaceholder={handleRemovePlaceholder}
                          mosobj={mosobj}
                          element={element}
                          hasPlaceholder={hasPlaceholder}
                          handleOpenDetails={handleOpenDetails}
                          openPlaceholderDialog={openPlaceholderDialog}
                        />
                      </EllipsisMenuWrapper>
                    </ContentWrapper>
                  </Box>
                </BoxWrapper>
              </ClipDropZone>
            </GraphicsDropZone>
          </SecondaryDropZone>
        </SelectedElement>
      </DragAndDrop>

      {!isGraphic && openDetails && (
        <Dialog
          open={openDetails}
          onClose={handleCloseDetails}
          initialData={initialData}
          type={type}
          element={element}
        />
      )}

      {placeholderDialogOpen && (
        <PlaceholderDialog
          open={placeholderDialogOpen}
          onClose={closePlaceholderDialog}
          element={element}
        />
      )}
    </div>
  );
};

SecondaryAutomation.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

SecondaryAutomation.defaultProps = {
  attributes: {},
  children: null,
  element: {
    type: elementTypes.ACCESSORY,
    children: [],
  },
};

export default memo(SecondaryAutomation);
