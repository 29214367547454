/* eslint-disable sort-imports */
import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';

import { IconButton as IButton } from 'components/buttons';
import ToolbarPositions from 'components/editor/constants/toolbarPositions';
import transientOptions from 'theme/helpers';

const commonStyle = css`
  min-height: 40px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface EmotionCSS {
  theme: Theme;
}

const readOnlyStyles = ({ theme }: EmotionCSS) => css`
  background: ${theme.palette.dina.dividerDark};
  pointer-events: none;
`;

const generalStyles = ({ theme }: EmotionCSS) => css`
  background: ${theme.palette.dina.blackHoverOverlay};
  pointer-events: all;
`;

const autoHeightStyles = css`
  height: auto;
  padding: 0;
  border: none;
`;

export const ToolbarWrapper = styled('div')`
  position: relative;
`;

interface ContainerProps {
  $readOnly: boolean;
  $disableGeneralToolbar?: boolean;
  $toolbarPosition?: string;
  $autoHeight?: boolean;
}
export const Container = styled('div', transientOptions)<ContainerProps>`
  ${commonStyle};
  ${({ $readOnly, $disableGeneralToolbar }) =>
    $readOnly || $disableGeneralToolbar ? readOnlyStyles : generalStyles};
  ${({ $toolbarPosition, theme }) => {
    if ($toolbarPosition === ToolbarPositions.BOTTOM)
      return css`
        border-top: 1px solid ${theme.palette.dina.dividerLight};
      `;
    if ($toolbarPosition === ToolbarPositions.TOP)
      return css`
        border-bottom: 1px solid ${theme.palette.dina.dividerLight};
      `;
    return '';
  }};
  ${({ $autoHeight }) => $autoHeight && autoHeightStyles};
`;

export const CMSBlockContainer = styled('div', transientOptions)<ContainerProps>`
  min-height: 80px;
  ${({ $readOnly }) => ($readOnly ? readOnlyStyles : generalStyles)}
`;

export const CMSToolbarWrapper = styled('div', transientOptions)<ContainerProps>`
  ${commonStyle};
  ${({ $readOnly }) => ($readOnly ? readOnlyStyles : generalStyles)};
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;

export const GeneralToolbarWrapper = styled('div', transientOptions)<ContainerProps>`
  ${commonStyle};
  ${({ $readOnly, $disableGeneralToolbar }) =>
    $readOnly || $disableGeneralToolbar ? readOnlyStyles : generalStyles};
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;

export const IconButton = styled(IButton)`
  position: absolute;
  right: 4px;
  top: 6px;
`;
