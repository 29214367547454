/* eslint-disable no-console */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable sort-imports */
import { ApolloClient, gql, useQuery } from '@apollo/client';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import { Metadata, Order, RawOrder } from 'types/forms/forms';
import { CrudActionEnum, GetOrderEnum, TaskStatusEnum } from 'types/graphqlTypes';

export const ORDER_PROPS = `
  mId
  mResourceId
  metadata
  mOwner
  mAssignee
  mType
  mFormId
  mdfId
  mCreatedAt
  mUpdatedAt
  mCreatedById
  mResourceType
  mSpaceId
  mStatus
  mActive
  crudAction
`;

const GET_ORDERS = gql`
  query GetOrders($input: GetOrdersInput) {
    getOrders(input: $input) {
      ${ORDER_PROPS}
    }
  }
`;

const GET_ORDER = gql`
query GetOrder($input: GetOrderInput) {
  getOrder(input: $input){
    ${ORDER_PROPS}
  }
}
`;

interface FetchOrders {
  getOrders: RawOrder[];
}

const rawToOrder = (rawOrder: RawOrder): Order => {
  let metadata: Metadata = {};
  try {
    metadata = JSON.parse(rawOrder.metadata ?? '{}') as Metadata;
  } catch (err) {
    console.warn('Could not parse metadata', rawOrder.metadata);
  }
  return {
    ...rawOrder,
    metadata,
  };
};

export const useGetOrders = (mId: string, requestType: GetOrderEnum, mStatus: TaskStatusEnum) => {
  const { data, error, loading } = useQuery<FetchOrders>(GET_ORDERS, {
    variables: {
      input: {
        mId,
        requestType,
        mStatus,
      },
    },
    fetchPolicy: 'cache-and-network',
    skip: !mId || !requestType,
  });
  return { orders: data?.getOrders.map(rawToOrder) ?? [], error, loading };
};

interface FetchOrder {
  getOrder: RawOrder;
}
export const useGetOrder = (mId: string | undefined, requestType: GetOrderEnum) => {
  const { data, error, loading } = useQuery<FetchOrder>(GET_ORDER, {
    variables: {
      input: {
        mId,
        requestType,
      },
    },
    fetchPolicy: 'cache-and-network',
    skip: !mId || !requestType,
  });
  return { order: data?.getOrder ? rawToOrder(data.getOrder) : null, error, loading };
};

const readOrderCacheForResource = (
  client: ApolloClient<object>,
  mId: string,
  requestType: GetOrderEnum,
  mStatus: TaskStatusEnum = TaskStatusEnum.all,
) => {
  const orders = client.readQuery<FetchOrders>({
    query: GET_ORDERS,
    variables: {
      input: {
        mId,
        requestType,
        mStatus,
      },
    },
  });
  return orders?.getOrders ?? [];
};

export const writeOrderToCacheByType = (
  client: ApolloClient<object>,
  resourceId: string,
  order: RawOrder,
  requestType: GetOrderEnum,
  mStatus: TaskStatusEnum = TaskStatusEnum.all,
  crudAction?: CrudActionEnum,
) => {
  const orders = readOrderCacheForResource(client, resourceId, requestType, mStatus);
  const existingOrder = orders.find((o) => o.mId === order.mId);
  const index = existingOrder ? orders.indexOf(existingOrder) : -1;
  let updated: RawOrder[] = [];
  const copy = [...orders];

  // Order exists in cache
  if (index >= 0) {
    copy.splice(index, 1, order);
    updated = [...copy];
  } else {
    updated = [order, ...copy];
  }

  updated = updated.map((o) => {
    return { ...o, crudAction: o.crudAction ?? crudAction };
  });

  const data = { getOrders: updated };

  client.writeQuery({
    query: GET_ORDERS,
    data,
    variables: {
      input: {
        mId: resourceId,
        requestType,
        mStatus,
      },
    },
  });
};

export const removeOrderFromCacheByType = (
  client: ApolloClient<object>,
  resourceId: string,
  orderId: string,
  requestType: GetOrderEnum,
  mStatus: TaskStatusEnum = TaskStatusEnum.all,
) => {
  const orders = readOrderCacheForResource(client, resourceId, requestType, mStatus);
  const updated = [...orders.filter((o) => o.mId !== orderId)];
  client.writeQuery({
    query: GET_ORDERS,
    data: { getOrders: updated },
    variables: {
      input: {
        mId: resourceId,
        requestType,
        mStatus,
      },
    },
  });
};

export const writeOrderToCache = (
  client: ApolloClient<object>,
  resourceId: string,
  order: RawOrder,
  mStatus: TaskStatusEnum = TaskStatusEnum.all,
  crudAction?: CrudActionEnum,
) => {
  for (const type of Object.values(GetOrderEnum)) {
    if (type === GetOrderEnum.Assignee && mStatus === TaskStatusEnum.inactive) {
      removeOrderFromCacheByType(client, resourceId, order.mId, type, TaskStatusEnum.active);
    } else {
      const status = type === GetOrderEnum.Resource ? TaskStatusEnum.all : mStatus;
      writeOrderToCacheByType(client, resourceId, order, type, status, crudAction);
    }
  }
};

export const removeOrderFromCache = (
  client: ApolloClient<object>,
  resourceId: string,
  orderId: string,
  mStatus: TaskStatusEnum = TaskStatusEnum.all,
) => {
  for (const type of Object.values(GetOrderEnum)) {
    const status = type === GetOrderEnum.Resource ? TaskStatusEnum.all : mStatus;
    removeOrderFromCacheByType(client, resourceId, orderId, type, status);
  }
};

export const useGetOrdersAndForms = (
  id: string,
  type: GetOrderEnum,
  mStatus: TaskStatusEnum = TaskStatusEnum.all,
) => {
  const { orders, loading: ordersLoading } = useGetOrders(id, type, mStatus);
  const { mdfs, loading: formsLoading } = useGetMdfs({ all: true });

  if (formsLoading && !mdfs.length) {
    return { orders: [], mdfs: [], loading: true };
  }

  return { orders, mdfs, loading: formsLoading || ordersLoading };
};
