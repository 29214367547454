import styled from '@emotion/styled/macro';

export const InfoSummaryWrapper = styled('div')`
  display: flex;
  align-items: center;

  &:first-of-type {
    margin-left: -4px;
  }
`;

export const ViewContainer = styled('div')<{ $disabled: boolean }>`
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;
  cursor: ${({ $disabled = false }) => ($disabled ? 'auto' : 'pointer')};
`;

export const TitleBoxWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    width: 12px;
    height: 12px;
  }
`;

export const TitleWrapper = styled('p')`
  ${({ theme }) => theme.typography.dina.overline};
  letter-spacing: 1px;
  line-height: 1rem;
  margin: 0;
`;

export const DurationInput = styled('input')<{
  $size?: 'large' | 'small';
  $error?: boolean;
  $customClipStyle?: boolean;
}>`
  pointer-events: none;
  width: ${({ $size = 'small' }) => ($size === 'large' ? '68px' : '50px')};
  border: none;
  outline: none;
  padding: 0;
  background: transparent;
  line-height: 1rem;
  letter-spacing: 0.25px;
  color: ${({ theme, $error = false, $customClipStyle = false }) => {
    if ($error) return theme.palette.dina.errorText;
    if ($customClipStyle) return theme.palette.dina.isFixed;
    return theme.palette.dina.highEmphasis;
  }};
  font-weight: ${({ $customClipStyle = false }) => ($customClipStyle ? 'bold' : 'normal')};
`;

export const EditableInputWrapper = styled('div')<{ $size?: 'large' | 'small' }>`
  padding: 4px;
  width: ${({ $size = 'small' }) => ($size === 'large' ? '76px' : '58px')};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: ${({ theme }) =>
    theme.palette.mode === 'light'
      ? theme.palette.dina.whiteHighEmphasis
      : theme.palette.dina.blackHighEmphasis};
  height: 40px;

  ${DurationInput} {
    border: 2px solid ${({ theme }) => theme.palette.dina.onFocus};
    background: ${({ theme }) => theme.palette.dina.inputBackgroundFocused};
    border-radius: 6px;
    pointer-events: all;
    text-align: center;
    padding: 4px 0;
  }
`;
