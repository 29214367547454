export const isProductionMode = process.env.NODE_ENV === 'production';

export const emptySignedCookiePromise = new Promise((resolve) => {
  resolve({
    loading: false,
    data: {
      getSignedCookie: {
        cookies: [],
      },
    },
  });
});
