import gql from 'graphql-tag';

export default gql`
  mutation updateStory($input: UpdateMemberInput) {
    updateMember(input: $input) {
      mId
      mRefId
      mSecId
      mTitle
      mDescription
      mContent
      mUpdatedAt
      mCreatedAt
      mPriority
      mPublishingAt
      mPublishingEnd
      locked
      mThumbnailUrl
      mAvatarUrl
      mAvatarKey
      mType
      metadata
      mdfId
      mMetaData {
        key
        value
      }
      mCategories {
        label
        score
        categoryId
      }
      mAssignedMembers {
        mId
        mType
      }
      ttl
    }
  }
`;
