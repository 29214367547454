import React, { useContext, useEffect, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { Menu, Fade } from '@material-ui/core';
import { useSelectedConversationId } from 'store';
import ListItem from 'components/ellipsisDropdown/listItem-view';
import Notification from 'components/statusIndicators/notification';
import Avatar from 'components/avatar/Avatar';
import IconButton from 'components/buttons/iconButton';
import ICONS from 'assets/icons/icons';
import useDidMount from 'hooks/useDidMount';
import useMenuAnchor from 'hooks/useMenuAnchor';
import useHover from 'hooks/useHover';
import useGetUser from 'hooks/useGetUser';
import UserContext from 'contexts/UserContext';
import useConversationContext from 'screens/main/components/header/navbar/messageHub/hooks/useConversationContext';
import getConversationInformation from '../utils/getConversationInformation';
import useStyles from './conversationListItem-styles';

const ConversationListItem = ({
  member,
  showAvatar,
  avatarVariant,
  showGroupMemberCount,
  menuItems,
  onMenuSelect,
}) => {
  const user = useContext(UserContext);
  const classes = useStyles({ avatarVariant });
  const { getUser } = useGetUser();
  const [selectedConversationId] = useSelectedConversationId();
  const {
    currentConversation,
    teams,
    departments,
    removeMessageNotification,
    notifications,
    onConversationSelect,
  } = useConversationContext();

  const { mId, convoLeaveOption } = member;

  const selected = currentConversation.mId === mId;

  const conversation = getConversationInformation(member, user.mId, getUser, teams, departments);
  const didMount = useDidMount();

  const { anchorEl, closeMenu, toggleMenu } = useMenuAnchor();
  const [hoverRef, isHovered] = useHover();

  const handleToggleMenu = (event) => {
    event.preventDefault();
    event.stopPropagation();
    toggleMenu(event);
  };

  const notificationCount = notifications[mId] || 0;

  useEffect(() => {
    if (didMount && notificationCount > 0 && currentConversation.mId === mId)
      removeMessageNotification(mId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [didMount, notificationCount, mId, currentConversation.mId]);

  useEffect(() => {
    if (
      conversation.mId === selectedConversationId &&
      conversation.mId !== currentConversation.mId
    ) {
      onConversationSelect(member);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentConversation.mId, selectedConversationId]);

  const handleConversationSelect = (event) => {
    event.preventDefault();
    notificationCount > 0 && removeMessageNotification(mId);
    onConversationSelect(member);
  };

  const menuOptions = useMemo(
    () =>
      menuItems.map(({ title, icon, action }) => (
        <ListItem
          key={`${title}_${action}`}
          text={title}
          firstChild={<div className={classes.menuIcon}>{icon}</div>}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            closeMenu();
            onMenuSelect(action, mId);
          }}
          excludeDivider
          maxHeight={40}
          disabled={action === 'leave-conversation' && !convoLeaveOption}
        />
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [menuItems, convoLeaveOption, mId],
  );

  return (
    <div ref={hoverRef}>
      <ListItem
        text={conversation.name}
        firstChild={
          showAvatar && (
            <div className={classes.avatar}>
              <Avatar
                size={24}
                src={conversation.mAvatarUrl}
                title={conversation.name}
                variant={avatarVariant}
                tooltipContent={conversation.name}
              />
            </div>
          )
        }
        iconChild={
          <div className={classes.iconChild}>
            {showGroupMemberCount && (
              <div className={classes.info}>{member.mAssignedMembers.length}</div>
            )}
            {notificationCount > 0 && (
              <Notification
                notificationCount={notificationCount > 10 ? '10+' : notificationCount}
              />
            )}
            {menuItems?.length > 0 && (
              <>
                <Fade in={isHovered} timeout={{ enter: 250, exit: 250 }} mountOnEnter unmountOnExit>
                  <div>
                    {/** prevents ref error * */}
                    <IconButton icon={ICONS.MORE_VERTICAL} onClick={handleToggleMenu} />
                  </div>
                </Fade>

                <Menu
                  open={Boolean(anchorEl)}
                  onClose={closeMenu}
                  getContentAnchorEl={null}
                  classes={{ paper: classes.menu, list: classes.menuList }}
                  PaperProps={{
                    onMouseLeave: closeMenu,
                  }}
                  {...{ anchorEl }}
                >
                  {menuOptions}
                </Menu>
              </>
            )}
          </div>
        }
        excludeDivider
        maxHeight={40}
        selected={selected}
        onClick={handleConversationSelect}
        whiteSpace="nowrap"
      />
    </div>
  );
};

ConversationListItem.propTypes = {
  /** an object containing information of a conversation */
  member: PropTypes.shape({ mid: PropTypes.string }).isRequired,
  /** boolean to show avatar of the member */
  showAvatar: PropTypes.bool,
  /** variant of the member */
  avatarVariant: PropTypes.string,
  /** boolean to show group member count in list item */
  showGroupMemberCount: PropTypes.bool,
  /** a list of items in the menu */
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      action: PropTypes.string,
      icon: PropTypes.element,
    }),
  ),
  /** callback to execute the actions from menu */
  onMenuSelect: PropTypes.func,
};

ConversationListItem.defaultProps = {
  showAvatar: true,
  avatarVariant: 'team',
  showGroupMemberCount: false,
  menuItems: [],
  onMenuSelect: () => {},
};

export default memo(ConversationListItem);
