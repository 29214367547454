import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { focusAtom } from 'jotai-optics';

import { policiesAtom } from 'store/config';
import { ResourcePolicy } from 'types/graphqlTypes';
import { moveArrayItem } from 'utils/arrayUtils';
import checkUserRight from 'utils/checkUserRight';

// Story/Pitch type tabs
export const storyTabs = {
  content: 'content',
  notes: 'notes',
  instances: 'instances',
  bookings: 'bookings',
  locations: 'locations',
  assets: 'assets',
  resources: 'resources',
  metadata: 'metadata',
  tasks: 'tasks',
  blocks: 'blocks',
} as const;

export type StoryTab = keyof typeof storyTabs;

// Icon Tabs
export type Icon = 'home' | 'feeds' | 'plans' | 'storyHub' | 'maps';

export type IconTab = {
  type: Icon;
  title: string;
};

const iconTabs: IconTab[] = [
  { type: 'home', title: 'Home' },
  { type: 'feeds', title: 'Feeds' },
  { type: 'plans', title: 'StoryHub' },
  { type: 'storyHub', title: 'StoryHub' },
  { type: 'maps', title: 'Maps' },
];

const hasIconTabPermission = (tab: IconTab, policies: ResourcePolicy[]) => {
  switch (tab.type) {
    case 'home':
      return true;
    case 'feeds':
      return checkUserRight(policies, 'feeds', 'access');
    case 'plans':
      return (
        checkUserRight(policies, 'storyhub', 'access') &&
        !checkUserRight(policies, 'feature', 'hide-storyhub-v1')
      );
    case 'storyHub':
      return (
        checkUserRight(policies, 'storyhub', 'access') &&
        checkUserRight(policies, 'feature', 'storyhub-v2')
      );
    case 'maps':
      return checkUserRight(policies, 'feature', 'maps');
    default:
      return false;
  }
};

const iconTabsAtom = atom((get) => {
  const policies = get(policiesAtom);
  return iconTabs.filter((tab) => hasIconTabPermission(tab, policies));
});
export const useIconTabs = () => useAtomValue(iconTabsAtom);

// Content Tabs
export type Content = 'pitch' | 'story' | 'rundown' | 'rundowntemplate' | 'space' | 'create';

export const contentTypes: Content[] = ['pitch', 'story', 'rundown', 'rundowntemplate', 'space'];

export const isContentType = (type: unknown): type is Content => {
  return typeof type === 'string' && contentTypes.includes(type as unknown as Content);
};

export type RundownTab = {
  type: 'rundown' | 'rundowntemplate';
  id: string;
  title?: string;
  selectedDate?: string;
  notificationCount?: number;
  search?: string;
};

export type StoryPitchTab = {
  type: 'pitch' | 'story' | 'create';
  id: string;
  title?: string;
  image?: string;
  restricted?: boolean;
  notificationCount?: number;
  search?: string;
};

export type SpaceTab = {
  type: 'space';
  id: string;
  title?: string;
  notificationCount?: number;
  search?: string;
};

export type ContentTab = RundownTab | StoryPitchTab | SpaceTab;

export type NavbarTabs = {
  contentTabs: ContentTab[];
  maxVisibleTabs: number;
};

export const initialValues: NavbarTabs = {
  contentTabs: [],
  maxVisibleTabs: 0,
};

const localNavbarTabs = localStorage.getItem('navbar_tabs');
const navbarTabsStorageAtom = atom<NavbarTabs>(
  localNavbarTabs ? (JSON.parse(localNavbarTabs) as NavbarTabs) : initialValues,
);

const navbarTabsAtom = atom(
  (get) => {
    const { contentTabs, maxVisibleTabs } = get(navbarTabsStorageAtom);
    return {
      maxVisibleTabs,
      contentTabs: contentTabs.filter((tab) => 'type' in tab && 'id' in tab),
    };
  },
  (_get, set, nextValue: NavbarTabs) => {
    set(navbarTabsStorageAtom, nextValue);
    localStorage.setItem('navbar_tabs', JSON.stringify(nextValue));
  },
);
export const useNavbarTabs = () => useAtom(navbarTabsAtom);

// contentTabs atom
const contentTabsAtom = focusAtom(navbarTabsAtom, (optic) => optic.prop('contentTabs'));
export const useContentTabs = () => useAtom(contentTabsAtom);

// currentTab atom
export const currentTab = atom<ContentTab | null>(null);
export const useCurrentTabValue = () => useAtomValue(currentTab);
export const useSetCurrentTab = () => useSetAtom(currentTab);

// max content tabs
const maxVisibleTabsAtom = focusAtom(navbarTabsAtom, (optic) => optic.prop('maxVisibleTabs'));
export const useMaxVisibleTabs = () => useAtom(maxVisibleTabsAtom);

// Only get the story / pitch content tabs
const storyTabsAtom = atom((get) => {
  const defaultValues = get(navbarTabsStorageAtom);
  const { contentTabs } = defaultValues;

  return contentTabs.filter(
    (tab) => tab.type === 'pitch' || tab.type === 'story',
  ) as StoryPitchTab[];
});
export const useStoryTabs = () => useAtom(storyTabsAtom);

const onDropTabItemAtom = atom(
  null,
  (get, set, nextValue: { itemIndex: number; dropIndex: number }) => {
    const { itemIndex, dropIndex } = nextValue;
    const contentTabs = get(contentTabsAtom);

    set(contentTabsAtom, moveArrayItem(contentTabs, itemIndex, dropIndex));
  },
);
export const useSetOnDropTabItem = () => useSetAtom(onDropTabItemAtom);
