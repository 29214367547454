import { useCallback } from 'react';
import BATCH_GET_MEMBERS from 'graphql/queries/batchGetRundownInstances';
import { useApolloClient } from '@apollo/client';
import { chunkArray } from 'utils/arrayUtils';

const useBatchGetMembers = () => {
  const client = useApolloClient();

  const loadItems = useCallback(
    async (instanceIds = []) => {
      const ids = [...instanceIds];

      const idGroups = chunkArray(
        ids.filter((id) => id && id[0] !== '-'),
        100,
      );

      const promises = idGroups.map((mIds) =>
        client.query({
          query: BATCH_GET_MEMBERS,
          variables: {
            input: { mIds },
          },
          fetchPolicy: 'network-only',
        }),
      );

      await Promise.all(promises);
    },
    [client],
  );

  return [loadItems];
};

export default useBatchGetMembers;
