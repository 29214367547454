import React, { memo } from 'react';
import { markTypes, elementTypes } from 'components/editor/constants';
import { ToolbarWrapper } from 'components/messageInput/messageInput-styled';
import MarkButton from '../markButton';
import ListButton from '../listButton';
import LinkButton from '../linkButton';
import DoneButton from '../doneButton';
import EmojiPicker from '../emojiPicker';
import VerticalDivider from '../verticalDivider';
import Wrapper from '../wrapper';

const { BOLD, ITALIC, UNDERLINE, STRIKE_THROUGH } = markTypes;

const { UNORDERED_LIST, ORDERED_LIST } = elementTypes;

const MessageToolbar = ({ showDoneButton }) => (
  <>
    <ToolbarWrapper>
      <Wrapper showRightMargin rightMarginWidth={8}>
        <MarkButton type={BOLD} />
        <MarkButton type={ITALIC} />
        <MarkButton type={UNDERLINE} />
        <MarkButton type={STRIKE_THROUGH} />
      </Wrapper>
      <VerticalDivider />
      <LinkButton />
      <Wrapper className='list-button' showRightMargin rightMarginWidth={8}>
        <VerticalDivider />
        <ListButton type={ORDERED_LIST} />
        <ListButton type={UNORDERED_LIST} />
      </Wrapper>
      <Wrapper>
        <VerticalDivider />
        <EmojiPicker pickerPlacement='top' />
      </Wrapper>
    </ToolbarWrapper>
    {showDoneButton && (
      <Wrapper>
        <DoneButton title='Send Message' />
      </Wrapper>
    )}
  </>
);

export default memo(MessageToolbar);
