import React, { useState, useMemo, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import ToggleButtons from 'components/toggleButtons';
import { publishingPoints as publishingPointIcons } from 'assets/icons/publishingPoints';
import { ReactComponent as EditOff } from 'assets/icons/systemicons/edit_off.svg';
import { ReactComponent as EditOn } from 'assets/icons/systemicons/edit_on.svg';
import { ReactComponent as VisibilityOff } from 'assets/icons/systemicons/editor/visibility.svg';
import { ReactComponent as VisibilityOn } from 'assets/icons/systemicons/editor/visibility_on.svg';
import { ReactComponent as Close } from 'assets/icons/systemicons/close.svg';
import { ReactComponent as Open } from 'assets/icons/systemicons/open.svg';
import { ReactComponent as SidebarActive } from 'assets/icons/systemicons/sidebar_active.svg';
import { ReactComponent as SidebarInactive } from 'assets/icons/systemicons/sidebar_inactive.svg';
import Divider from 'components/divider';
import toggleViews from 'components/instanceCard/utils/toggleViews';
import accessibleOnClick from 'utils/accessibleOnClick';
import providerStates from 'utils/constants/providerStates';
import FallbackImage from 'assets/images/default/defaultThumbnail.png';
import { IconButton } from 'components/buttons';
import variants from 'utils/instance/variants';
import useCheckUserRight from 'hooks/useCheckUserRight';
import defaultStateValues from 'screens/planning/components/status/utils/statusBoards/defaultStateValues';
import { onImageLoadingError } from 'utils/image/imageUtils';
import configCtx from 'contexts/configContext';
import PublishSettings from './components/publishSettings';
import publishingPoints from '../../utils/publishingPoints';
import DestinationIndicator from './components/destinationIndicator';
import LinearEllipsisMenu from './components/linearEllipsisMenu';
import GeneralEllipsisMenu from './components/generalEllipsisMenu';
import SchedulingButtons from './components/schedulingButtons';
import NotificationPopup, { notificationIdentifiers } from 'components/notificationPopup';
import TitleBox from './components/titleBox';
import Assignees from './components/assignees';
import useStyles from './header-styles';

const HeaderView = ({
  backgroundImage,
  canCreateNewTemplate,
  canDeleteTemplate,
  canDeleteTemplateFolder,
  canPinTemplateFolder,
  canReorderTemplates,
  canSetDefaultTemplate,
  currentDestination,
  embeddedEndpoint,
  folders,
  instanceId,
  onBackgroundClick,
  onCreateDuplicate,
  onCreateFolder,
  onDeleteFolder,
  onDeleteInstance,
  onDeleteTemplate,
  onDownload,
  onClientPrint,
  onForceUnlock,
  onMetadataSelect,
  onOpenStory,
  onOpenRundown,
  onPublishSettingsChange,
  onSaveTemplate,
  onSelectTemplate,
  onTitleUpdate,
  previewEndpoint,
  publishingPoint,
  publishingPointIcon,
  platformKind,
  schedule,
  expiryTime,
  showMetadata,
  statusId,
  summarize,
  title,
  variant,
  writeLock,
  readLock,
  lockedByUser,
  onClose,
  disableEdit,
  editorValue,
  canShowNewDesign,
  onRestoreVersion,
  checkVersionRestorability,
  isSavingContent,
  onCmsEditingClick,
  isCmsBlock,
  disableOpenStory,
  assignees,
  onAssigneeUpdate,
  toggleView,
  setToggleView,
  showToggleButton,
  providerState,
  isEmbedPresent,
  publishMetadata,
  updateInstanceMetadata,
  canUpdateInstance,
  showCloseButton,
  showOpenStoryButton,
  hideTemplateOptions,
  hideAutomationTemplates,
  isPreview,
  accountIdentifier,
  defaultTemplateRefId,
  onSetDefaultTemplate,
  storyId,
  isContentLoaded,
  isFirstInstance,
}) => {
  const notificationRef = useRef();
  const { kanbanBoardStates = [] } = useContext(configCtx);
  const state = kanbanBoardStates.find((s) => s.id === statusId);
  const isFailedState = statusId === 'failed';
  const backgroundColor = state?.backgroundColor || defaultStateValues.backgroundColor;
  const opacity = state?.opacity || defaultStateValues.opacity;
  const classes = useStyles({ backgroundColor, opacity });

  const [checkUserRight] = useCheckUserRight();
  const canDisableSchedule = checkUserRight('feature', 'disable-schedule-on-edit');
  const hasPermissionForPlatform = checkUserRight('platform', accountIdentifier);
  const canCreateInstance = checkUserRight('instance', 'create') && hasPermissionForPlatform;
  const canDeleteInstance = checkUserRight('instance', 'delete') && hasPermissionForPlatform;
  const canScheduleInstance = checkUserRight('instance', 'schedule') && hasPermissionForPlatform;
  const canRepublish = checkUserRight('feature', 'republish-workflow');

  const canRepublishInstance =
    canRepublish && variant === variants.CMS && statusId?.includes('published');
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (canShowNewDesign && showToggleButton) {
      if (providerState === providerStates.PUBLISHED) setToggleView(toggleViews.PREVIEW);
      if (providerState === providerStates.ASSIGNED) setToggleView(toggleViews.EDIT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerState]);

  const openPublishingSettings = (event) => !disableEdit && setAnchorEl(event.currentTarget);

  const onOK = (newPublishingSettings) => {
    setAnchorEl(null);
    onPublishSettingsChange(newPublishingSettings);
  };

  const handleCancel = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onBackgroundClick();
  };

  const { publishingTime } = currentDestination;

  const datetime = schedule;
  const isDeleteEnabled =
    variant === variants.LINEAR ? currentDestination.id !== null : schedule !== null;

  const memoizedLinearMenu = useMemo(
    () => (
      <LinearEllipsisMenu
        disableEdit={disableEdit || isPreview}
        storyId={storyId}
        {...{
          disableOpenStory,
          folders,
          onSelectTemplate,
          onSaveTemplate,
          onDeleteTemplate,
          onCreateFolder,
          onDeleteFolder,
          onDownload,
          onClientPrint,
          onCreateDuplicate,
          onForceUnlock,
          writeLock,
          lockedByUser,
          canCreateNewTemplate,
          canDeleteTemplate,
          canDeleteTemplateFolder,
          canPinTemplateFolder,
          canReorderTemplates,
          canSetDefaultTemplate,
          isContentLoaded,
          onDeleteInstance,
          isDeleteEnabled,
          title,
          instanceId,
          editorValue,
          onRestoreVersion,
          checkVersionRestorability,
          isSavingContent,
          canCreateInstance,
          canScheduleInstance,
          canDeleteInstance,
          canUpdateInstance,
          hideTemplateOptions,
          schedule,
          hideAutomationTemplates,
          platformKind,
          defaultTemplateRefId,
          onSetDefaultTemplate,
        }}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      canCreateNewTemplate,
      canDeleteTemplate,
      canDeleteTemplateFolder,
      canPinTemplateFolder,
      canReorderTemplates,
      canSetDefaultTemplate,
      onSetDefaultTemplate,
      disableEdit,
      folders,
      isDeleteEnabled,
      writeLock,
      lockedByUser,
      onRestoreVersion,
      checkVersionRestorability,
      isSavingContent,
      canCreateInstance,
      canScheduleInstance,
      canDeleteInstance,
      canUpdateInstance,
      hideTemplateOptions,
      hideAutomationTemplates,
      isPreview,
      schedule,
      defaultTemplateRefId,
      storyId,
      disableOpenStory,
      isContentLoaded,
    ],
  );

  const updateButtonList = [
    {
      icon:
        toggleView === toggleViews.EDIT ? (
          <EditOn className={`${classes.toggleEditIcon} skipOverride`} />
        ) : (
          <EditOff />
        ),
      value: toggleViews.EDIT,
    },
    {
      icon: toggleView === toggleViews.PREVIEW ? <VisibilityOn /> : <VisibilityOff />,
      value: toggleViews.PREVIEW,
      disabled: !isEmbedPresent,
    },
  ];

  const isSchedulingDisabled = useMemo(() => {
    if (!canScheduleInstance) return true;
    if (canDisableSchedule && variant !== variants.LINEAR) {
      return readLock || writeLock;
    }
    // TODO: remove below condition later
    return !!(isPreview && variant === variants.LINEAR);
  }, [canDisableSchedule, canScheduleInstance, isPreview, readLock, variant, writeLock]);

  return (
    <div className={classes.root}>
      <div className={classes.backgroundColor} />

      <div className={classes.container}>
        <div className={classes.titleWrapper}>
          <div className={classes.titleBox}>
            <TitleBox
              instanceId={instanceId}
              storyId={storyId}
              initialTitle={title || '-'}
              onTitleUpdate={onTitleUpdate}
              disableEdit={disableEdit}
              disableOpenStory={disableOpenStory}
            />
          </div>
          {!disableOpenStory && showOpenStoryButton && (
            <Open className={classes.iconButton} onClick={onOpenStory} />
          )}
          <Assignees
            assignees={assignees}
            disableEdit={disableEdit}
            onAssigneeUpdate={onAssigneeUpdate}
          />
          {publishingPoints.LINEAR === publishingPoint ? (
            memoizedLinearMenu
          ) : (
            <GeneralEllipsisMenu
              disableEdit={disableEdit || isPreview}
              storyId={storyId}
              {...{
                isDeleteEnabled,
                onDeleteInstance,
                onForceUnlock,
                summarize,
                writeLock,
                lockedByUser,
                title,
                instanceId,
                onRestoreVersion,
                checkVersionRestorability,
                isSavingContent,
                canDeleteInstance,
                canUpdateInstance,
                folders,
                canCreateNewTemplate,
                canDeleteTemplateFolder,
                canPinTemplateFolder,
                canDeleteTemplate,
                canReorderTemplates,
                canSetDefaultTemplate,
                onDeleteFolder,
                onSaveTemplate,
                onSelectTemplate,
                onDeleteTemplate,
                onCreateFolder,
                hideTemplateOptions,
                defaultTemplateRefId,
                onSetDefaultTemplate,
                isContentLoaded,
              }}
            />
          )}
          {showCloseButton && <Close className={classes.iconButton} onClick={onClose} />}
        </div>
        <div className={classes.scheduleDestination}>
          <div className={classes.avatar} {...accessibleOnClick(handleClick, 'presentation')}>
            <img
              src={backgroundImage || FallbackImage}
              onError={onImageLoadingError}
              alt="_thumbnail_"
              className={classes.thumbnail}
            />
            <img
              className={classes.publishingPoint}
              src={
                publishingPointIcons[platformKind || publishingPointIcon] ??
                publishingPointIcons.default
              }
              alt="publishing-point"
            />
          </div>
          <DestinationIndicator
            destination={currentDestination.title}
            onClick={handleClick}
            canOpenRundown={
              publishingPoint === variants.LINEAR && currentDestination?.title !== 'Unassigned'
            }
            onOpenRundown={onOpenRundown}
          />
          {showToggleButton && (
            <div className={classes.toggleContainer}>
              <ToggleButtons
                selectedValue={toggleView}
                list={updateButtonList}
                onChange={setToggleView}
              />
            </div>
          )}
          <SchedulingButtons
            datetime={datetime}
            isCMS={publishingPoint === publishingPoints.CMS}
            openPublishingSettings={openPublishingSettings}
            onCmsEditingClick={onCmsEditingClick}
            id={instanceId}
            title={title}
            isCmsBlock={isCmsBlock}
            embeddedEndpoint={embeddedEndpoint}
            previewEndpoint={previewEndpoint}
            isFailedState={isFailedState}
            isDisabled={isSchedulingDisabled}
            destinationId={currentDestination.id}
            isLinear={publishingPoints.LINEAR === publishingPoint}
          />
          <div ref={notificationRef}>
            <IconButton
              height={30}
              width={30}
              onClick={onMetadataSelect}
              title="Toggle metadata sidebar"
              usage={showMetadata ? 'story' : 'text'}
            >
              {showMetadata ? <SidebarActive /> : <SidebarInactive />}
            </IconButton>
          </div>
          {isFirstInstance && (
            <NotificationPopup
              position="bottom"
              anchor={notificationRef}
              id={notificationIdentifiers.MetadataSlide}
              title="Improved metadata editing"
              text="We have moved editing metadata from the drop down menu to a side panel for easier access."
            />
          )}
        </div>
        <Divider className={classes.divider} />
      </div>
      <PublishSettings
        publishingPoint={publishingPoint}
        platformKind={platformKind}
        publishingTime={variant === variants.LINEAR ? publishingTime : schedule}
        onCancel={handleCancel}
        variant={variant}
        selectedDestination={currentDestination}
        canRepublishInstance={canRepublishInstance}
        publishMetadata={publishMetadata}
        publishingPointIcon={publishingPointIcon}
        {...{
          anchorEl,
          onOK,
          expiryTime,
          isCmsBlock,
          updateInstanceMetadata,
        }}
      />
    </div>
  );
};

HeaderView.propTypes = {
  /** Reference to the container element */
  containerRef: PropTypes.shape({ current: PropTypes.shape({}) }),
  /** Variant of the instance card */
  variant: PropTypes.oneOf(Object.values(variants)),
  /** Current status id of the instance */
  statusId: PropTypes.string,
  /** Publishing platform of the instance */
  publishingPoint: PropTypes.oneOf(Object.keys(publishingPointIcons)),
  /** Currently selected destination of this instance */
  currentDestination: PropTypes.shape({
    title: PropTypes.string,
    publishingTime: PropTypes.string,
  }),
  /** Callback to be invoked on publish settings change */
  onPublishSettingsChange: PropTypes.func,
  /** Title of the instance */
  title: PropTypes.string,
  /** Callback to be invoked on title update */
  onTitleUpdate: PropTypes.func,
  /** Publishing time for general instance variant */
  schedule: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  /** Expire time for cms instance variant */
  expiryTime: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  /** List of users assigned to current instance */
  assignees: PropTypes.arrayOf(
    PropTypes.shape({
      mAvatarUrl: PropTypes.string,
    }),
  ),
  /** Callback to be invoked on assignee update */
  onAssigneeUpdate: PropTypes.func,
  /** Callback to be invoked on template selection,
   * with the selected template passed in
   */
  onSelectTemplate: PropTypes.func,
  /** Callback to be invoked when save as template option is clicked,
   * with the templateName passed in
   */
  onSaveTemplate: PropTypes.func,
  /** Callback to be invoked on template deletion,
   * with the template to be deleted passed in
   */
  onDeleteTemplate: PropTypes.func,
  /** Callback to be invoked on click for non-editable elements */
  onBackgroundClick: PropTypes.func,
  /** onCmsEditing callback */
  onCmsEditingClick: PropTypes.func,
  /** List of template folders */
  folders: PropTypes.arrayOf(PropTypes.any),
  /** Callback to be invoked while creating new folder */
  onCreateFolder: PropTypes.func,
  /** Callback to be invoked when opening a story */
  onOpenStory: PropTypes.func,
  /** Callback to be invoked while deleting a folder */
  onDeleteFolder: PropTypes.func,
  /** Callback to be invoked when downloading an instance */
  onDownload: PropTypes.func,
  /** Boolean that indicates that user can change content */
  writeLock: PropTypes.bool,
  /** Boolean that indicates other user currently has write lock on this content */
  readLock: PropTypes.bool,
  /** boolean that hides create new template from menu */
  canCreateNewTemplate: PropTypes.bool,
  /** boolean that hides delete template from menu */
  canDeleteTemplate: PropTypes.bool,
  /** boolean that hides delete template folder from menu */
  canDeleteTemplateFolder: PropTypes.bool,
  /** Boolean that stops an user from editing an instance */
  disableEdit: PropTypes.bool,
  /** toggle bewtween edit and embed view */
  toggleView: PropTypes.string,
  /** set whether edit or preview state */
  setToggleView: PropTypes.func,
  /** either to show toggle buttons */
  showToggleButton: PropTypes.bool,
  /** Id of the entity */
  storyId: PropTypes.string,
};

HeaderView.defaultProps = {
  containerRef: { current: null },
  variant: variants.LINEAR,
  statusId: 'todo',
  publishingPoint: 'linear',
  currentDestination: {},
  onPublishSettingsChange: (newPublishingSettings) => {},
  title: '',
  onTitleUpdate: (newTitle) => {},
  schedule: null,
  expiryTime: undefined,
  assignees: [],
  onAssigneeUpdate: (updatedAssignees) => {},
  onSelectTemplate: (template) => {},
  onSaveTemplate: (templateName) => {},
  onDeleteTemplate: (template) => {},
  onBackgroundClick: () => {},
  onCmsEditingClick: () => {},
  folders: [],
  onCreateFolder: () => {},
  onOpenStory: () => {},
  onDeleteFolder: () => {},
  onDownload: () => {},
  writeLock: false,
  readLock: false,
  canCreateNewTemplate: false,
  canDeleteTemplate: false,
  canDeleteTemplateFolder: false,
  disableEdit: false,
  toggleView: 'edit',
  setToggleView: () => {},
  showToggleButton: false,
  storyId: undefined,
};

export default HeaderView;
