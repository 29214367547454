import styled from '@emotion/styled/macro';

import { ReactComponent as Email } from 'assets/icons/systemicons/email.svg';
import { ReactComponent as ChatResting } from 'assets/icons/systemicons/HeaderNavbar/chat_resting.svg';
import Avatar, { AvatarVariant } from 'components/avatar/Avatar';
import Button from 'components/buttons/button';
import Divider from 'components/divider';
import Text from 'components/text/Text';
import useCreateConvo from 'hooks/useCreateConvo';
import { useIsMessageHubOpen, useSelectedConversationId } from 'store';
import { AssignedMember } from 'types';
import capitalize from 'utils/capitalize';

interface DetailsProps {
  userDetails: AssignedMember;
  isCurrentUser: boolean;
}

const Container = styled('div')`
  overflow: hidden;
  background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
  width: 338px;
  height: 78px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  margin: -8px -16px;
`;

const Body = styled('div')`
  flex: 1;
  padding: 8px;
  display: flex;
  gap: 8px;
`;

const TitleWrapper = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const Description = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const ButtonWrapper = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 90px;
`;

const TextButton = styled(Button)`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 4px;
  border: none;
  border-radius: 0px;
  path {
    fill-opacity: 0.54;
  }
  :hover {
    cursor: pointer;
    border: none;
    border-radius: 0px;
    span {
      color: ${({ theme }) => theme.palette.dina.highEmphasis} !important;
    }
    path {
      fill-opacity: 1;
    }
  }
`;

const VDivider = styled(Divider)`
  width: 1px;
  height: 100%;
`;

const HDivider = styled(Divider)`
  width: 100%;
  height: 1px;
`;

function Details({ userDetails, isCurrentUser }: DetailsProps) {
  const { createConvo } = useCreateConvo();
  const [, setIsMessageHubOpen] = useIsMessageHubOpen();
  const [, setSelectedConvoId] = useSelectedConversationId();
  const { email, phone } = userDetails?.mProperties ?? {};

  const handleChat = async () => {
    await createConvo(userDetails, (convo) => {
      setSelectedConvoId(convo?.mId);
      setIsMessageHubOpen(true);
    });
  };

  return (
    <Container>
      <Body>
        <Avatar
          variant={userDetails.mType as AvatarVariant}
          size={56}
          src={userDetails.mAvatarUrl}
          title={userDetails.mTitle}
        />
        <TitleWrapper>
          <Title as="span" variant="h7" color="highEmphasis">
            {userDetails?.mTitle}
          </Title>
          {(userDetails?.mDescription || userDetails?.mType) && (
            <Description as="span" variant="listItemLabelMedium" color="highEmphasis">
              {userDetails?.mDescription !== ''
                ? userDetails?.mDescription
                : capitalize(userDetails?.mType)}
            </Description>
          )}
          {phone && (
            <Text as="span" variant="caption" color="mediumEmphasis">
              Mob: {phone}
            </Text>
          )}
        </TitleWrapper>
      </Body>
      <VDivider />
      <ButtonWrapper>
        <TextButton onClick={() => void handleChat()} disabled={isCurrentUser} usage="text">
          <ChatResting />
          <Text variant="button" color="mediumEmphasis">
            Chat
          </Text>
        </TextButton>
        {email && (
          <>
            <HDivider />
            <TextButton
              onClick={() => window.location.assign(`mailto: ${email}`)}
              disabled={isCurrentUser}
              usage="text"
            >
              <Email />
              <Text variant="button" color="mediumEmphasis">
                Email
              </Text>
            </TextButton>
          </>
        )}
      </ButtonWrapper>
    </Container>
  );
}

export default Details;
