/* eslint-disable no-console */
/* eslint-disable no-useless-catch */
import React, { createContext, useState, useEffect, useContext } from 'react';
import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';

import { resetLockToken } from 'utils/lock/lockToken';
import { UseHostedUI, getAuthState, setAuthState, AuthStates } from 'features/auth';
import { setCookies } from 'store';
import CREATE_SESSION from 'graphql/mutations/createSession';
import GET_SESSION from 'graphql/queries/getSession';
import LOGOUT from 'graphql/mutations/logout';
import uuidv1 from 'uuid/v1';
import isValidSession from 'utils/session/isValidSession';
import useSessionExpirationHandler from 'hooks/useSessionExpirationHandler';

const initialState = { user: null, groups: [], verified: false, loading: false };

export const AuthContext = createContext(initialState);

const SESSION_ID_KEY = 'sessionId';

const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [userState, setUserState] = useState(initialState);

  const [handleExpiration, resetExpirationHandler] = useSessionExpirationHandler();

  const getExistingSessionId = () => window.localStorage.getItem(SESSION_ID_KEY);

  const setSessionId = (id) => window.localStorage.setItem(SESSION_ID_KEY, id);

  const setLoggedInUser = (loggedinUser, verified = false) => {
    if (loggedinUser) {
      const idToken = loggedinUser.getSignInUserSession().getIdToken();
      if (idToken && idToken.payload) {
        setUserState({
          user: loggedinUser,
          groups: idToken.payload['cognito:groups'] || [],
          verified,
        });
      } else {
        setUserState({ user: loggedinUser, groups: [], verified });
      }
    } else {
      console.log(`setLoggedInUser: initialState`);
      setUserState({ ...initialState, verified });
    }
  };

  const getUserSub = (loggedInUser) => {
    const idToken = loggedInUser?.getSignInUserSession().getIdToken();
    const { payload: { 'custom:subalias': subalias, sub } = {} } = idToken ?? {};
    return subalias || sub;
  };

  const getUserSession = async (loggedInUser) => {
    // eslint-disable-next-line no-case-declarations
    const sub = getUserSub(loggedInUser);
    // eslint-disable-next-line no-case-declarations
    const sessionId = getExistingSessionId();
    if (!sub || !sessionId) return null;
    try {
      const result = await children.props.client.query({
        query: GET_SESSION,
        variables: {
          input: {
            mId: sub,
            mRefId: sessionId,
          },
        },
        fetchPolicy: 'network-only',
      });

      const { data, error } = result;
      if (!error) return data?.getMember;
    } catch (e) {
      console.log(e);
    }

    return null;
  };

  const verifyUser = async () => {
    console.log(`AuthContext: verifyUser: start ${!!userState.user}`);
    try {
      if (!userState.user) {
        const loggedInUser = await Auth.currentAuthenticatedUser();
        const userSession = await getUserSession(loggedInUser);

        if (!isValidSession(userSession)) {
          console.log('session is not valid');
          setAuthState(AuthStates.NOT_VERIFIED);
          logout(getUserSub(loggedInUser));
          setLoading(false);
          return;
        }

        setLoggedInUser(loggedInUser, true);
        handleExpiration(userSession, logout);
        setAuthState(AuthStates.VERIFIED);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      setAuthState(AuthStates.NOT_VERIFIED);
      console.error(`AuthContext: verifyUser: catch ${!!userState.user}: ${error}`);
    }
    setLoading(false);
  };

  const createSession = async (loggedInUser) => {
    const sub = getUserSub(loggedInUser);
    if (!sub) {
      console.log('no valid sub, ignores session');
      setLoading(false);
      return;
    }

    console.log(`creating session`);

    // To filter multiple event from sign in event
    if (getExistingSessionId()) {
      console.log('session entry already exists. verifying session');
      const session = await getUserSession(loggedInUser);
      if (isValidSession(session)) {
        setLoggedInUser(loggedInUser, true);
        handleExpiration(session, logout);
        setLoading(false);
        return;
      }
      console.log('session is not valid. creating new session');
    }

    const sessionId = uuidv1();
    setSessionId(sessionId);

    try {
      const result = await children.props.client.mutate({
        mutation: CREATE_SESSION,
        variables: {
          input: {
            mId: sub,
            mRefId: sessionId,
          },
        },
      });

      const { data } = result;
      setLoggedInUser(loggedInUser, true);
      resetLockToken();
      handleExpiration(data.createSession, logout);
    } catch (e) {
      await logout(sub);
      setSessionId('');
      console.log(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data: loggedInUser } }) => {
      window.localStorage.setItem('referrer', '');
      switch (event) {
        case 'signIn':
          setAuthState(AuthStates.VERIFIED);
          createSession(loggedInUser);
          break;
        case 'signOut':
          setAuthState(AuthStates.NOT_VERIFIED);
          setSessionId('');
          resetLockToken();
          setLoggedInUser(null);
          resetExpirationHandler();
          setLoading(false);
          break;
        case 'oAuthSignOut':
          setSessionId('');
          resetLockToken();
          resetExpirationHandler();
          break;
        default:
          console.log(`Amplify Auth event ${event} not processed`);
      }
    });

    const authState = getAuthState();
    if (!UseHostedUI || authState === AuthStates.VERIFIED) {
      console.log('verifying user');
      verifyUser();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logOutUserSession = async (userId) => {
    try {
      await children.props.client.mutate({
        mutation: LOGOUT,
        variables: {
          input: {
            mId: userId,
            mRefId: getExistingSessionId(),
          },
        },
      });
    } catch (e) {
      // console.log(e)
    }
  };

  const logout = async (userId) => {
    if (!UseHostedUI) setAuthState(AuthStates.NOT_VERIFIED);
    window.onbeforeunload = null;

    try {
      if (userId) await logOutUserSession(userId);
      await Auth.signOut();
      setCookies([]);
      // clearStore clears the store without refetching active queries.
      children.props.client.clearStore();
    } catch (error) {
      console.error(`logout failed: ${error}`);
    }

    setSessionId('');
    resetLockToken();
    setLoggedInUser(null);
  };

  const login = async (email, password) => {
    try {
      const cognitoUser = await Auth.signIn(email, password);
      if (cognitoUser.challengeName !== 'NEW_PASSWORD_REQUIRED') {
        setLoggedInUser(cognitoUser);
      }
      resetLockToken();
      return cognitoUser;
    } catch (error) {
      console.error(`login failed: ${error}`);
      throw error;
    }
  };

  const forgotPassword = async (username) => {
    try {
      return await Auth.forgotPassword(username);
    } catch (error) {
      console.error(`forgotPassword failed: ${error}`);
      throw error;
    }
  };

  const forgotPasswordSubmit = async (username, verificationCode, newPassword) => {
    try {
      return await Auth.forgotPasswordSubmit(username, verificationCode, newPassword);
    } catch (error) {
      console.error(`forgotPasswordSubmit failed: ${error}`);
      throw error;
    }
  };

  const completeSignup = async (userToConnfirm, firstName, lastName, password) => {
    try {
      console.log('Auth.completeNewPassword');
      const cognitoUser = await Auth.completeNewPassword(userToConnfirm, password, {
        given_name: firstName,
        family_name: lastName,
      });

      setLoggedInUser(cognitoUser);
    } catch (error) {
      // console.log(error);
      if (error.code === 'InvalidPasswordException') {
        throw error;
      }
    }
  };

  /**
   * Returns an object with properties reflecting the url parameters.
   * url parameters are located in window.location.search if present
   * Sample: url: https://dina.7mountains.com?k1=v1&k2=v2
   * Returns: { k1: "v1", k2: "v2" }
   */
  const getUrlParams = () => {
    const params = window.location.search;
    if (!params) return {};
    return params
      .slice(1)
      .split('&')
      .reduce((kv, kvpair) => {
        const [key, value] = kvpair.split('=');
        // eslint-disable-next-line no-param-reassign
        kv[key] = value == null ? true : value;
        return kv;
      }, {});
  };

  return (
    <AuthContext.Provider
      value={{
        loading,
        user: userState.user,
        groups: userState.groups,
        verified: userState.verified,
        urlParams: getUrlParams(),
        showFederatedSignIn: UseHostedUI,
        setUser: setLoggedInUser,
        login,
        forgotPassword,
        forgotPasswordSubmit,
        logout,
        completeSignup,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthConsumer = AuthContext.Consumer;

const useAuthContext = () => useContext(AuthContext);

export { AuthProvider, AuthConsumer, useAuthContext };
