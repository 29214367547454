import { useEffect, useMemo, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';

import { ReactComponent as ArrowDown } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_down.svg';
import Tooltip from 'components/tooltip';
import { Alternative } from 'types/graphqlTypes';

import { FieldHeader } from '../../styled';
import { FieldProps } from '../fields';

import {
  SelectWrapper,
  StyledOption,
  StyledOptionWrapper,
  StyledPopper,
  StyledTextField,
} from './styled';

export function ChoiceField({
  fieldModel,
  value,
  setValue,
  fieldSettings,
  defaultFieldSettings,
  style,
  errorMessage,
  setError,
  placeholder,
  ignoreDefaultValue,
  disableEdit,
}: Readonly<FieldProps>) {
  const [selectedValue, setSelectedValue] = useState<Alternative | null>(null);
  const [tooltipPlacement, setTooltipPlacement] = useState<'bottom' | 'top'>('bottom');
  const { fieldId, defaultValue, required } = fieldModel;
  const { label, hint } = fieldSettings ?? defaultFieldSettings;

  const alternatives = useMemo(() => fieldModel.alternatives ?? [], [fieldModel]);

  useEffect(() => {
    if (!value || value !== selectedValue?.value) {
      let alternative = alternatives.find((op) => op.value === value);
      if (!alternative && !ignoreDefaultValue) {
        alternative = alternatives.find((op) => op.value === defaultValue.value);
      }
      if (alternative) {
        setSelectedValue(alternative);
      } else {
        setSelectedValue(null);
      }
    }
  }, [value, alternatives, selectedValue, defaultValue, ignoreDefaultValue]);

  useEffect(() => {
    if (required && !selectedValue) {
      setError('Required value');
    } else if (required && selectedValue && errorMessage) {
      setError(undefined);
    } else if (!required && errorMessage) {
      setError(undefined);
    }
  }, [selectedValue, errorMessage, required]);

  const onFocus = () => {
    setTooltipPlacement('top');
  };

  const onBlur = () => {
    setTooltipPlacement('bottom');
  };

  const renderInput = (params: object) => (
    <StyledTextField
      {...params}
      variant="filled"
      placeholder={disableEdit ? '' : placeholder ?? 'Select'}
      error={Boolean(errorMessage)}
      helperText={errorMessage?.length ? errorMessage : undefined}
    />
  );

  const renderOption = (alternative: Alternative) => (
    <StyledOptionWrapper $selected={selectedValue?.value === alternative.value}>
      <StyledOption>{alternative.label ?? alternative.value ?? 'Invalid option!'}</StyledOption>
    </StyledOptionWrapper>
  );

  return (
    <Tooltip title={hint || ''} placement={tooltipPlacement}>
      <SelectWrapper key={fieldId} style={style}>
        <FieldHeader>{label}</FieldHeader>
        <Autocomplete
          onFocus={onFocus}
          onBlur={onBlur}
          openOnFocus
          disableClearable={required}
          fullWidth
          blurOnSelect
          noOptionsText="No options available"
          selectOnFocus={false}
          options={alternatives}
          value={selectedValue}
          getOptionSelected={(alternative: Alternative, v: Alternative) => {
            if (!v) return false;
            return alternative.value === v.value;
          }}
          onChange={(_ev, alternative) => setValue(alternative?.value ?? '')}
          renderInput={renderInput}
          renderOption={renderOption}
          PopperComponent={StyledPopper}
          getOptionLabel={(alternative) => alternative?.label ?? alternative?.value}
          popupIcon={!disableEdit && <ArrowDown />}
        />
      </SelectWrapper>
    </Tooltip>
  );
}
