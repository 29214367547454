import { useCallback } from 'react';
import {
  ConnectableElement,
  DragSourceMonitor,
  DropTargetMonitor,
  useDrag,
  useDrop,
} from 'react-dnd';

import dndTypes from 'utils/dndTypes';

import { MemberProps } from '../types';

import Member from './MemberInfo';

import { FocusDiv } from './styled';

const DraggableMember = (props: MemberProps) => {
  const { handleDrop, id, ...rest } = props;
  const [, dragRef] = useDrag({
    type: dndTypes.MEMBER,
    item: () => props,
    end: (_, monitor: DragSourceMonitor<MemberProps, MemberProps>) => {
      if (!monitor.didDrop() || !handleDrop) return;
      if (typeof monitor?.getDropResult()?.id === 'string') {
        const dropResId = monitor?.getDropResult()?.id;
        if (dropResId) handleDrop(id, dropResId);
      }
    },
  });

  const [{ hovered }, dropRef] = useDrop({
    accept: [dndTypes.STORY, dndTypes.PITCH],
    drop: (_, monitor: DropTargetMonitor<MemberProps, MemberProps>) => {
      if (!handleDrop) return;
      handleDrop(id, monitor?.getItem()?.id);
    },
    collect: (monitor) => ({ hovered: monitor.isOver() }),
  });

  const attachRef = useCallback((el: ConnectableElement) => {
    dragRef(el);
    dropRef(el);
  }, []);

  return (
    <FocusDiv hovered={hovered} ref={attachRef}>
      <Member id={id} {...rest} />
    </FocusDiv>
  );
};

export default DraggableMember;
