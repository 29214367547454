/* eslint-disable import/no-extraneous-dependencies */
import { useMemo, useCallback, useRef, useEffect, memo, useLayoutEffect } from 'react';
import { createEditor } from 'slate';
import { Slate, withReact, ReactEditor } from 'slate-react';
import { withHistory } from 'slate-history';
import PropTypes from 'prop-types';
import pipe from 'lodash/fp/pipe';
import isNull from 'lodash/isNull';
import { ScopeProvider } from 'jotai-molecules';
import { ErrorBoundary } from 'react-error-boundary';
import { useBlockForms } from 'store';

import useCheckUserRight from 'hooks/useCheckUserRight';
import FallbackComponent from 'components/fallbackComponent';
import EmojiCombobox from './components/emojiCombobox';
import UserCommands from './components/mdf/components/userCommands';
import variants from './constants/types/editorVariants';
import { initialValues, actionTypes } from './constants';
import { EditorScope } from './store';
import toolbarPositions from './constants/toolbarPositions';
import Toolbar from './components/toolbar';
import onParagraphKeyDown from './components/paragraph/utils/onParagraphKeyDown';
import onLeafKeyDown from './components/leaf/utils/onLeafKeydown';
import onQuoteKeyDown from './components/quote/utils/onQuoteKeyDown';
import onListKeyDown from './components/list/utils/onListKeyDown';
import onMentionKeyDown from './components/mention/utils/onMentionKeyDown';
import { withLink, onLinkKeyDown } from './components/link/utils';
import onHorizontalRuleKeyDown from './components/horizontalRule/utils/onHorizontalRuleKeyDown';
import onPrimaryKeyDown from './components/primaryAutomation/utils/onPrimaryKeyDown';
import EditorContext from './EditorContext';
import Editable from './CustomEditable';

import Suggestions from './components/mention/components/suggestions';
import HoveringTooltip from './components/hoveringTooltip';
import HoveringToolbar from './components/hoveringToolbar/view';
import ScriptDropdown from './components/scriptInfo/components/scriptDropdown';
import useMosItemReplaceHandler from './hooks/useMosItemReplace';
import {
  onAssetElementKeyDown,
  onElementKeyDown,
  onVoidKeyDown,
  onWrapSection,
  withInline,
  withNormalization,
  withVoid,
} from './utils';

import { ToolbarWrapper, EditorWrapper } from './styled';

const wrappedEditor = pipe(
  createEditor,
  withReact,
  withHistory,
  withNormalization,
  withVoid,
  withInline,
  withLink,
);

const Editor = ({
  background,
  height,
  hostReadSpeed,
  placeholder,
  readOnly,
  renderToolbar,
  update,
  users,
  value,
  variant,
  shouldResetSelection,
  width,
  thumbnail,
  isAllowed,
  onCmsEditing,
  isPublished,
  toolbarPosition,
  onDone,
  setEditor,
  showHoveringTooltip,
  padding,
  platformStructure,
  platformId,
  isCmsBlock,
  editorFontSize,
  getPlaceholderConfigs,
  withSignedUrl,
  onFocus,
  onBlur,
  direction,
  fallbackText,
  platformKind,
  showDoneButton,
  enableEmoji,
  keepFocus,
  enableEditorCommand,
  resourceDetails,
  showSidepanelButton,
  writeLock,
  suppressChangeEvent,
}) => {
  const containerRef = useRef(null);
  const [blockForms] = useBlockForms();
  const formsForThisPlatform = blockForms?.[platformId];
  const editor = useMemo(() => wrappedEditor(), []);
  const [checkUserRight] = useCheckUserRight();
  const canUseSectionDivider = checkUserRight('feature', 'section-divider');
  const canUseEditorCommand = enableEditorCommand && checkUserRight('feature', 'mdfBlocks');

  const initialValue = useMemo(() => {
    return initialValues(variant, isAllowed, isCmsBlock);
  }, [variant, isAllowed, isCmsBlock]);

  const { document: initialDocument, ...rest } = value || initialValue;

  const resetSelection = () => {
    if (!editor) return;

    const { deselect, blur } = ReactEditor;
    editor.history = {
      redos: [],
      undos: [],
    };

    if (editor.selection) {
      deselect(editor);
      blur(editor);
    }
  };

  const shouldClearEditorContent = () => variant === variants.MESSAGE;

  const clearEditorContent = () => {
    const { document: emptyDocument } = initialValues(variant);
    editor.children = emptyDocument;
  };

  useMosItemReplaceHandler(editor, update, readOnly, variant);

  useLayoutEffect(() => {
    if (setEditor && editor) {
      setEditor(editor);
    }
  }, [setEditor, editor]);

  useEffect(() => {
    if (shouldResetSelection || !readOnly) {
      resetSelection();
    }
    if (!suppressChangeEvent && (initialDocument || isNull(initialDocument))) {
      editor.children = initialDocument;
      editor.onChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, initialDocument]);

  useLayoutEffect(() => {
    if (shouldResetSelection || !readOnly) {
      resetSelection();
    }
  }, [readOnly, shouldResetSelection]);

  const handleDone = useCallback(() => {
    onDone({ document: editor.children, ...rest });
    resetSelection();
    if (shouldClearEditorContent()) clearEditorContent();
    if (keepFocus) {
      ReactEditor.focus(editor);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor.children, onDone, keepFocus]);

  const getDefaultPlaceholderConfigs = useCallback(
    () => ({
      template: {},
      s3Content: null,
      variables: {},
    }),
    [],
  );

  const handleUpdate = useCallback(
    ({ type, payload }) => update({ type, payload: { ...payload, ...rest } }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [update],
  );

  const handleChange = useCallback(
    (newValue) => {
      const isAstChange = editor.operations.some((op) => op.type !== 'set_selection');
      if (isAstChange)
        handleUpdate({
          type: actionTypes.CHANGE,
          payload: { document: newValue },
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor.children],
  );

  const handleSectionWrap = useCallback(
    (unwrap = false) => {
      canUseSectionDivider &&
        onWrapSection(editor, handleUpdate, platformStructure?.config, unwrap);
    },
    [canUseSectionDivider, platformStructure, editor],
  );

  const onKeyDown = useCallback(
    (event) => {
      onLeafKeyDown(editor, event);
      onLinkKeyDown(editor, event);
      onMentionKeyDown(editor, event);
      onElementKeyDown(
        editor,
        event,
        variant,
        isAllowed,
        isCmsBlock,
        variant === variants.MESSAGE,
        handleDone,
        handleSectionWrap,
      );
      onParagraphKeyDown(editor, event, variant, isAllowed, isCmsBlock);
      onQuoteKeyDown(editor, event);
      onListKeyDown(editor, event);
      onHorizontalRuleKeyDown(editor, event);
      onAssetElementKeyDown(editor, event, handleUpdate);
      onPrimaryKeyDown(editor, event, handleUpdate);
      onVoidKeyDown(editor, event, variant, isAllowed, isCmsBlock);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleDone, handleSectionWrap],
  );

  const toolbar = useMemo(
    () =>
      renderToolbar({
        variant,
        readOnly,
        isAllowed,
        platformStructure,
        isCmsBlock,
        toolbarPosition,
        platformKind,
        showDoneButton,
        showSidepanelButton,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [readOnly, renderToolbar, variant, platformKind, platformStructure, showDoneButton],
  );

  return (
    <ScopeProvider scope={EditorScope} uniqueValue={true}>
      <EditorWrapper $background={background} $width={width} $height={height} ref={containerRef}>
        <ErrorBoundary
          fallback={<FallbackComponent fallbackText={fallbackText} />}
          resetKeys={[value]}
        >
          <Slate onChange={handleChange} initialValue={initialDocument} editor={editor}>
            <EditorContext.Provider
              value={{
                update: handleUpdate,
                getPlaceholderConfig: getPlaceholderConfigs || getDefaultPlaceholderConfigs,
                containerRef,
                users,
                variant,
                thumbnail,
                isAllowed,
                onCmsEditing,
                isPublished,
                isCmsBlock,
                onDone: handleDone,
                editorFontSize,
                withSignedUrl,
                platformId,
                resourceDetails,
                formsForThisPlatform,
                allowVideoInPhotogallery: platformStructure?.allowVideoInPhotogallery,
                config: platformStructure?.config,
              }}
            >
              {toolbarPosition === toolbarPositions.TOP && toolbar}
              <HoveringToolbar />
              <Editable
                editor={editor}
                variant={variant}
                height={height}
                padding={padding}
                editorFontSize={editorFontSize}
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                placeholder={placeholder}
                readOnly={readOnly}
                direction={direction}
              />
              {toolbarPosition === toolbarPositions.BOTTOM && toolbar}
              {showHoveringTooltip && (
                <HoveringTooltip hostReadSpeed={hostReadSpeed} writeLock={writeLock} />
              )}
              <Suggestions />
              {enableEmoji && <EmojiCombobox />}
              {canUseEditorCommand && <UserCommands />}
              <ScriptDropdown />
            </EditorContext.Provider>
          </Slate>
        </ErrorBoundary>
      </EditorWrapper>
    </ScopeProvider>
  );
};

Editor.propTypes = {
  /** Suppress editor.onChange on initialDoc load to avoid rerender issues causing problems related to losing selection. */
  suppressChangeEvent: PropTypes.bool,
  /** Background color for the editor text area */
  background: PropTypes.string,
  /** Height of the editor view */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Placeholder text to show on empty editable area */
  placeholder: PropTypes.string,
  /** Boolean that indicates a read only editor */
  readOnly: PropTypes.bool,
  /** Render custom toolbar */
  renderToolbar: PropTypes.func,
  /** onCmsEditing callback to show cms iframe */
  onCmsEditing: PropTypes.func,
  /** on done button click callback */
  onDone: PropTypes.func,
  /** whether the instance is published or not */
  isPublished: PropTypes.bool,
  /** If true, deselects editor  */
  shouldResetSelection: PropTypes.bool,
  /** Callback to invoked when text editor's value updates,
   * with the update type and relevant payload passed in
   */
  update: PropTypes.func,
  /** List of users to show as suggestions */
  users: PropTypes.arrayOf(
    PropTypes.shape({
      mId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      mTitle: PropTypes.string,
    }),
  ),
  /** JSON value for the editor content */
  value: PropTypes.shape({
    version: PropTypes.string,
    document: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        children: PropTypes.arrayOf(PropTypes.shape({})),
        data: PropTypes.shape({}),
      }),
    ),
  }),
  /** Variant of the editor */
  variant: PropTypes.oneOf(Object.values(variants)),
  /** Width of the editor view */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Determines the position of toolbar  */
  toolbarPosition: PropTypes.oneOf(Object.values(toolbarPositions)),
  /** Boolean to show/hide hovering tooltip indicating read time */
  showHoveringTooltip: PropTypes.bool,
  /** amount of padding for editable area */
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** callback fired when editor is focused */
  onFocus: PropTypes.func,
  /** callback fired when editor is blurred */
  onBlur: PropTypes.func,
  /** text to be shown in fallback component */
  fallbackText: PropTypes.string,
  writeLock: PropTypes.bool,
  /** If true, show send button  */
  showDoneButton: PropTypes.bool,
  /** enable emoji combobox to write emoji */
  enableEmoji: PropTypes.bool,
  /** keep focus after on done */
  keepFocus: PropTypes.bool,
  /** enable editor command to insert command block */
  enableEditorCommand: PropTypes.bool,
  /** whether to show side panel button or not */
  showSidepanelButton: PropTypes.bool,
  /** entity data */
  resourceDetails: PropTypes.shape({
    resourceId: PropTypes.string,
    resourceType: PropTypes.string,
    resourceTitle: PropTypes.string,
    orderFormMap: PropTypes.object,
  }),
};

Editor.defaultProps = {
  suppressChangeEvent: false,
  background: undefined,
  height: 670,
  placeholder: 'Type something here...',
  readOnly: true,
  isPublished: false,
  showSidepanelButton: false,
  renderToolbar: ({
    variant,
    readOnly,
    isAllowed,
    platformStructure,
    isCmsBlock,
    toolbarPosition,
    platformKind,
    showDoneButton,
    showSidepanelButton,
  }) => (
    <ToolbarWrapper>
      <Toolbar
        variant={variant}
        readOnly={readOnly}
        isAllowed={isAllowed}
        platformStructure={platformStructure}
        isCmsBlock={isCmsBlock}
        toolbarPosition={toolbarPosition}
        platformKind={platformKind}
        showDoneButton={showDoneButton}
        showSidepanelButton={showSidepanelButton}
      />
    </ToolbarWrapper>
  ),
  shouldResetSelection: false,
  update: ({ type, payload }) => {},
  onFocus: () => {},
  onBlur: () => {},
  users: [],
  onCmsEditing: () => {},
  onDone: () => {},
  value: initialValues(variants.GENERAL),
  variant: variants.GENERAL,
  width: '100%',
  toolbarPosition: 'top',
  showHoveringTooltip: true,
  padding: 16,
  fallbackText: 'Content can not be loaded',
  showDoneButton: true,
  enableEmoji: false,
  enableEditorCommand: false,
  keepFocus: false,
  writeLock: false,
  resourceDetails: undefined,
};

export default memo(Editor);
