import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { ReactComponent as EditIcon } from 'assets/icons/systemicons/edit_alt.svg';
import UPDATE_GROUP from 'graphql/mutations/updateGroup';
import InputWithButtons from 'components/InputWithButtons/inputWithButtons-view';
import { RootWrapper, GroupHeaderWrapper } from './groupHeader-styled';
import { useSelectedGroupName } from '../../../../../../atoms';

const GroupHeader = (props) => {
  const { groupPolicy } = props;
  const groupName = groupPolicy?.mTitle;
  const [selectedGroupName, setSelectedGroupName] = useSelectedGroupName();
  const [showTitleInput, setShowTitleInput] = useState(false);
  const [updateGroup] = useMutation(UPDATE_GROUP);

  const onTitleUpdate = async (newGroupName) => {
    const { mId, mRefId } = groupPolicy;
    await updateGroup({
      variables: {
        input: { mId, mRefId, mTitle: newGroupName },
      },
    });
  };

  const handleShowInput = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setShowTitleInput(!showTitleInput);
  };

  const handleCloseInput = () => {
    setShowTitleInput(!showTitleInput);
  };

  const onClickAway = () => {
    setSelectedGroupName(groupName);
    setShowTitleInput(!showTitleInput);
  };

  const viewHelper = () => {
    if (showTitleInput)
      return (
        <InputWithButtons
          title={selectedGroupName}
          setTitle={setSelectedGroupName}
          initialTitle={groupName}
          onClickAway={onClickAway}
          onClose={handleCloseInput}
          onUpdate={onTitleUpdate}
        />
      );
    return (
      <GroupHeaderWrapper onClick={handleShowInput}>
        {selectedGroupName}
        <EditIcon />
      </GroupHeaderWrapper>
    );
  };

  return <RootWrapper>{viewHelper()}</RootWrapper>;
};
GroupHeader.propTypes = {
  groupPolicy: PropTypes.object,
};

GroupHeader.defaultProps = {
  groupPolicy: [],
};

export default GroupHeader;
