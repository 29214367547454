import React, { useCallback, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSlate } from 'slate-react';
import { mediaTypes } from 'utils/rundownItemTypes';
import dndTypes from 'utils/dndTypes';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import { elementTypes } from 'components/editor/constants/types';
import DropZone from 'components/editor/components/dropZone';
import addMedia from './utils/addClip';

const { OVERLAY_GRAPHICS } = elementTypes;
const { CLIP, IMAGE } = mediaTypes;
const { ASSET } = dndTypes;

const ClipDropZone = ({ children, element, canDropVideo, canDropImage }) => {
  const editor = useSlate();
  const { update } = useEditorContext();
  const { type } = element;

  const clipDropZoneAcceptedTypes = useMemo(() => {
    if (type === OVERLAY_GRAPHICS) return [];
    const acceptedTypes = [ASSET];
    if (canDropVideo || canDropImage) acceptedTypes.push(CLIP);
    return acceptedTypes;
  }, [type, canDropVideo, canDropImage]);

  const onDrop = useCallback(
    ({ payload }) => 
    {
      if (
        (canDropVideo && payload.itemType === 'video') ||
        (canDropImage && payload.itemType === 'image')
      )
        addMedia(editor, element, payload, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  return (
    <DropZone accept={clipDropZoneAcceptedTypes} {...{ onDrop, element }}>
      {children}
    </DropZone>
  );
};

ClipDropZone.propTypes = {
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
  canDropImage: PropTypes.bool,
};

ClipDropZone.defaultProps = {
  children: null,
  element: {
    children: [],
  },
  canDropImage: false,
};

export default memo(ClipDropZone);
