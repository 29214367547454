import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  messageRow: {
    margin: '8px 0',
    display: 'flex',
    alignItems: 'flex-end',
    width: '100%',
    position: 'relative',
  },
  avatarText: {
    ...theme.typography.dina.listItemLabel,
    fontSize: '10px',
  },
  messageContainer: {
    width: '100%',
    marginLeft: '8px',
    paddingLeft: '4px',
    borderRadius: '8px',
    minWidth: '28px',
    backgroundColor: ({ isSelfMessage }) =>
      isSelfMessage ? 'rgba(148, 138, 248, 0.35)' : theme.palette.dina.backgroundResting,
    ...theme.typography.dina.body2,
    border: `1px solid ${theme.palette.dina.buttonBorderOutlined}`,
    boxSizing: 'border-box',
    clipPath: ({ isSelfMessage }) =>
      isSelfMessage
        ? `polygon(0 0, 100% 0, 100% calc(100% - 14px), 
          calc(100% - 8px) calc(100% - 14px), calc(100% - 8px) 100%, 0 100%)`
        : `polygon(0px 0px, 100% 0px, 100% 100%, 8px 100%, 
          8px calc(100% - 14px), 0px calc(100% - 14px));`,
  },
  leftPointer: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    transform: 'translate3d(30px, 0, 0)',
    '& path:nth-of-type(1)': {
      fill: theme.palette.dina.backgroundResting,
      fillOpacity: 1,
    },
    '& path:nth-of-type(2)': {
      fill: theme.palette.dina.buttonBorderOutlined,
      fillOpacity: 1,
    },
  },
  rightPointer: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    transform: 'translate3d(6px, 0, 0)',
    '& path:nth-of-type(1)': {
      fill: 'rgba(148, 138, 248, 0.35)',
      fillOpacity: 1,
    },
    '& path:nth-of-type(2)': {
      fill: theme.palette.dina.buttonBorderOutlined,
      fillOpacity: 1,
    },
  },
}));

export default useStyles;
