import styled from '@emotion/styled';

import transientOptions from 'theme/helpers';

interface BlankDivTypes {
  $margin: string | number;
}

export const BlankDiv = styled('div', transientOptions)<BlankDivTypes>`
  margin-left: ${({ $margin }) => $margin};
`;
