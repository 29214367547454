import { useState } from 'react';

import { ReactComponent as AddIcon } from 'assets/icons/systemicons/add_small.svg';
import { Button } from 'components/buttons';
import Tooltip from 'components/tooltip';

import CreateUserDialog from '../createNew';

import { RootWrapper, Text, TextButtonWrapper } from './styled';
const isHostedUI = process.env.REACT_APP_USE_HOSTED_UI;
const UsersHeader = () => {
  const [showAddUser, setShowAddUser] = useState(false);

  const handleAdd = () => {
    setShowAddUser(true);
  };

  return (
    <RootWrapper>
      <TextButtonWrapper>
        <Text>Users</Text>
        <Tooltip
          title={
            !isHostedUI
              ? 'create new user'
              : 'user creation is not possible as user is created by external identity provider'
          }
        >
          <div>
            <Button
              usage="outlined"
              variant="outlined"
              height={32}
              onClick={handleAdd}
              disabled={!!isHostedUI}
            >
              <AddIcon />
              Add User
            </Button>
          </div>
        </Tooltip>
      </TextButtonWrapper>
      <CreateUserDialog open={showAddUser} setOpen={setShowAddUser} />
    </RootWrapper>
  );
};

export default UsersHeader;
