import { Metadata } from 'types/forms/forms';
import { MemberType, MemberTypeEnum } from 'types/graphqlTypes';

import { Block } from './types';

export const BLOCK_PROPS = `
  mId
  mRefId
  mTitle
  metadata
  mType
  mdfId
  mSecId
  mCreatedAt
  mUpdatedAt
  mUpdatedById
  mResourceType
  crudAction
`;

export const memberToBlock = (raw: MemberType): Block => {
  let metadata: Metadata = {};
  try {
    metadata = JSON.parse(raw.metadata ?? '{}') as Metadata;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn('Could not parse metadata', raw.metadata);
  }

  return {
    mId: raw.mId!,
    mRefId: raw.mRefId!,
    mdfId: raw.mdfId!,
    mTitle: raw.mTitle!,
    mCreatedAt: raw.mCreatedAt!,
    mUpdatedAt: raw.mUpdatedAt!,
    mUpdatedById: raw.mUpdatedById!,
    mResourceType: raw.mResourceType!,
    crudAction: raw.crudAction!,
    mType: MemberTypeEnum.Block,
    metadata,
    mSecId: raw.mSecId!,
  };
};
