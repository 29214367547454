import { useMutation } from '@apollo/client';

import UPDATE_RELATED_MEMBERS from 'graphql/mutations/updateRelatedMembers';

const useUpdateRelatedMembers = () => {
  const [updateRelatedMembersMutation, { loading }] = useMutation(UPDATE_RELATED_MEMBERS);

  return [updateRelatedMembersMutation, loading] as const;
};

export default useUpdateRelatedMembers;
