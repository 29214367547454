import { FieldValue } from 'types/forms/forms';

import { BaseRow } from '../../orderForm/types';

type Comparator = (a: BaseRow, b: BaseRow) => number;

const compareStrings = (valA: string, valB: FieldValue) => {
  if (typeof valB !== 'string') return 1;
  if (valA < valB) return -1;
  return valA > valB ? 1 : 0;
};

const compareArrayOfStrings = (valA: string[], valB: FieldValue) => {
  if (!Array.isArray(valB)) return 1;
  if (!valA.length && !valB.length) return 0;
  if (!valA.length && valB.length) return -1;
  if (valA.length && !valB.length) return 1;
  return compareStrings(valA[0], valB[0]);
};

const compareBoolean = (valA: boolean, valB: FieldValue) => {
  if (typeof valB !== 'boolean') return 1;
  if (valA === valB) {
    return 0;
  }
  return valA ? 1 : -1;
};

const compareValues = (valA: FieldValue, valB: FieldValue) => {
  if (typeof valA === 'boolean') {
    return compareBoolean(valA, valB);
  }

  if (typeof valA === 'string') {
    return compareStrings(valA, valB);
  }

  if (Array.isArray(valA)) {
    if (Array.isArray(valA[0])) {
      return 0; // as far as I know, values can never be string[][].
    } else {
      return compareArrayOfStrings(valA as string[], valB);
    }
  }
  return 1;
};

const defaultStringComparisons: (keyof BaseRow)[] = [
  '__storyTitle',
  '__createdAt',
  '__createdById',
  '__storyTitle',
  '__updatedAt',
];

export function getComparator(sortColumn: string): Comparator {
  return (a, b) => {
    const valA = a[sortColumn] ?? '';
    const valB = b[sortColumn] ?? '';

    if (defaultStringComparisons.includes(sortColumn)) {
      return compareValues(valA, valB);
    }

    if (a.__type !== b.__type) {
      return compareStrings(a.__type, b.__type);
    }

    return compareValues(valA, valB);
  };
}
