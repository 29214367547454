import React from 'react';
import PropTypes from 'prop-types';

import { StyledListItem, StyledTitle, StyledContent } from './showItem-styled';

const ShowItem = ({ title, content, onClick, disableInteraction }) => (
  <StyledListItem onClick={onClick} $disableInteraction={disableInteraction}>
    <StyledTitle>{title}</StyledTitle>
    <StyledContent>{content}</StyledContent>
  </StyledListItem>
);

ShowItem.propTypes = {
  /** Defines the title of the item  */
  title: PropTypes.string,
  /** Defines the content of the item  */
  content: PropTypes.node,
  /** disables user interaction with the component */
  disableInteraction: PropTypes.bool,
};

ShowItem.defaultProps = {
  title: 'title',
  content: 'content',
  disableInteraction: false,
};

export default ShowItem;
