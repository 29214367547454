import styled from '@emotion/styled';

export const HeaderWrapper = styled('div')`
  background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 120px;
  position: relative;
`;

export const AvatarBackground = styled('div')`
  position: absolute;
  background: ${({ theme }) => theme.palette.dina.avatarBackdrop};
  backdrop-filter: blur(35px);
  inset: 0;
`;
