import { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';

import DebouncedLoadingIndicator from 'components/debouncedLoadingIndicator/DebouncedLoadingIndicator';
import Tabs from 'components/tabs/oldTabs';
import GET_PLATFORM from 'graphql/queries/getPlatform';
import { Platform } from 'types';

import DialogWrapper from '../dialogWrapper';

import {
  DesktopIcon,
  Iframe,
  Iframe0,
  Iframe1,
  Iframe2,
  Iframe3,
  IframeContainer,
  LandscapeIcon,
  PortraitIcon,
  TabletIcon,
  TabsContainer,
} from './styled';

const PreviewContainer = ({
  id,
  title,
  closeDialog,
}: {
  id?: string;
  title?: string;
  closeDialog: () => void;
}) => {
  const [tabIndex, setTabIndex] = useState(3);

  const { data, loading } = useQuery<{ getPlatform: Platform }>(GET_PLATFORM, {
    variables: {
      input: {
        mId: id,
        refresh: true,
      },
    },
    fetchPolicy: 'network-only',
  });

  const src = data?.getPlatform?.mProperties?.provider?.previewEndpoint;

  const tabs = [
    {
      icon: <PortraitIcon />,
      title: 'Mobile, Portrait',
    },
    {
      icon: <LandscapeIcon />,
      title: 'Mobile, Landscape',
    },
    {
      icon: <TabletIcon />,
      title: 'Tablet',
    },
    {
      icon: <DesktopIcon />,
      title: 'Desktop  ',
    },
  ];

  const onTabChange = (_value: number, index: number) => {
    setTabIndex(index);
  };

  const IframeWrapper = useMemo(() => {
    switch (tabIndex) {
      case 0:
        return Iframe0;
      case 1:
        return Iframe1;
      case 2:
        return Iframe2;
      case 3:
        return Iframe3;
      default:
        return Iframe3;
    }
  }, [tabIndex]);

  if (loading) return <DebouncedLoadingIndicator />;

  return (
    <DialogWrapper
      closeDialog={closeDialog}
      label={title}
      mode="Previewing"
      isPreview
      isDirty={undefined}
    >
      <>
        <TabsContainer>
          <Tabs
            tabWidth={undefined}
            scrollButtonRootClass={undefined}
            variant="icon"
            {...{ tabs, tabIndex, onTabChange }}
          />
        </TabsContainer>
        <IframeContainer>
          <IframeWrapper>
            <Iframe
              id={id}
              src={src}
              title={title}
              sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
            />
          </IframeWrapper>
        </IframeContainer>
      </>
    </DialogWrapper>
  );
};

export default PreviewContainer;
