/**
 * Converts hh:mm:ss formatted time string to milliseconds
 *
 * @param {String} time Formatted time string hh:mm:ss
 * @returns {Number} Converted milliseconds
 */

const getMilliseconds = (time = '') => {
  if (!time) return 0;

  const parsedTime = time.toString().split(':').map(Number);

  if (parsedTime.some(Number.isNaN)) return 0;

  const [firstItem] = parsedTime;
  const sign = firstItem === 0 ? 1 : Math.sign(firstItem);

  const seconds = parsedTime.reduce(
    (accumulator, currentValue) => 60 * accumulator + Math.abs(currentValue),
    0,
  );

  return sign * seconds * 1000;
};

export const msToTimecode = (ms) => {
  let seconds = ms / 1000;
  const hours = parseInt(seconds / 3600, 10);
  seconds %= 3600;
  const minutes = parseInt(seconds / 60, 10);
  seconds %= 60;
  if (hours === 0) {
    return `${padNumber(minutes.toFixed(0))}:${padNumber(seconds.toFixed(0))}`;
  }
  return `${padNumber(hours.toFixed(0))}:${padNumber(minutes.toFixed(0))}:${seconds.toFixed(0)}`;
};

const padNumber = (n) => {
  const number = Math.floor(n);
  if (number < 10) {
    return `0${number}`;
  }
  return number.toFixed(0);
};

export default getMilliseconds;
