import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import uuidv1 from 'uuid/v1';
import PlatformIcons from 'components/menu/createInstanceMenu/PlatformIcons';
import RundownIcon, { iconUsage } from 'components/rundownIcons';
import Assignees from 'components/assignees/Assignees';
import Tooltip from 'components/tooltip';
import { ReactComponent as LinkIcon } from 'assets/icons/systemicons/link.svg';
import { ReactComponent as ScheduledIcon } from 'assets/icons/systemicons/scheduled_ultrasmall_on.svg';
import { ReactComponent as UnscheduledIcon } from 'assets/icons/systemicons/scheduled_ultrasmall_off.svg';
import statusIcons from 'assets/icons/systemicons/status/statusIcon';
import getFormattedPublishedDate from 'utils/getFormattedPublishedDate';
import distanceInAbbreviatedWords from 'utils/distanceInAbbreviatedWords';
import configCtx from 'contexts/configContext';
import kanbanDefaultState from 'utils/constants/kanbanDefaultState';

import {
  PublishingInfoWrapper,
  TopRowWrapper,
  Title,
  TitleRowWrapper,
  BottomRowWrapper,
  ItemsWrapper,
  Divider,
  StoryTitle,
  KanbanCardWrapper,
  TitleWrapper,
  DetailsWrapper,
  DestinationWrapper,
} from './styled';

const KanbanCard = (props) => {
  const {
    destination,
    mRelatedMembers,
    updatedAt,
    storyTitle,
    blockTitle,
    title,
    mPublishingAt,
    items,
    mState,
    assignedUsers,
    autoHeight,
    platform,
    platformKind,
    hideStatusInfo,
    openDestination,
    canOpenDestination,
  } = props;

  const { kanbanBoardStates = [] } = useContext(configCtx);
  const state = kanbanBoardStates.find((s) => s.id === mState) || kanbanDefaultState;

  const { icon, name } = state;
  const statusIcon = statusIcons[`${icon}_small`];

  const PlatformIcon = PlatformIcons[platformKind || platform] ?? PlatformIcons.defaultIcon;

  const lastUpdatedAt = distanceInAbbreviatedWords(updatedAt);

  const hasRelatedMembers = mRelatedMembers?.length > 0;

  const handleDestinationClick = (event) => {
    if (canOpenDestination) {
      openDestination();
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <KanbanCardWrapper $storyTitle={storyTitle} $autoHeight={autoHeight}>
      <Tooltip title={destination || title} noArrow>
        <TopRowWrapper>
          <TitleWrapper>
            <PlatformIcon className="skipOverride" />
            <DestinationWrapper
              role="presentation"
              onClick={handleDestinationClick}
              $canOpenDestination={canOpenDestination}
            >
              {destination}
            </DestinationWrapper>
          </TitleWrapper>
          <DetailsWrapper>
            {hasRelatedMembers && (
              <>
                <span className="relationNumber">{mRelatedMembers?.length}x</span>
                <LinkIcon />
                <Divider orientation="vertical" flexItem />
              </>
            )}
            {lastUpdatedAt}
          </DetailsWrapper>
        </TopRowWrapper>
      </Tooltip>
      <Tooltip title={title} noArrow>
        <TitleRowWrapper>
          {Boolean(storyTitle) && <StoryTitle title={storyTitle}>{storyTitle}</StoryTitle>}
          <Title $autoHeight={autoHeight}>{blockTitle || title}</Title>
        </TitleRowWrapper>
      </Tooltip>
      <BottomRowWrapper>
        <div>
          <PublishingInfoWrapper $hasPublishingDate={Boolean(mPublishingAt)}>
            {mPublishingAt ? (
              <>
                <ScheduledIcon />
                <span className="publishedText">{getFormattedPublishedDate(mPublishingAt)}</span>
              </>
            ) : (
              <>
                <UnscheduledIcon />
                <span>Unscheduled</span>
              </>
            )}
          </PublishingInfoWrapper>
          {items?.length > 0 && (
            <ItemsWrapper>
              {items.slice(0, 3).map((item) => (
                <RundownIcon
                  key={uuidv1()}
                  variant={item.title}
                  iconUrl={item.iconUrl}
                  width={16}
                  height={16}
                  usage={iconUsage.META}
                />
              ))}
            </ItemsWrapper>
          )}
          {!hideStatusInfo && (
            <>
              <img src={statusIcon} alt="" />
              <span>{name}</span>
            </>
          )}
        </div>
        <Assignees members={assignedUsers} size={20} maxAvatarToShow={3} />
      </BottomRowWrapper>
    </KanbanCardWrapper>
  );
};

KanbanCard.propTypes = {
  /** Account of platform */
  destination: PropTypes.string,
  /** List of related instances */
  mRelatedMembers: PropTypes.array,
  /** Last updated at */
  updatedAt: PropTypes.string,
  /** Title of the story that the instance is part of */
  storyTitle: PropTypes.string,
  /** Block title within the instance */
  blockTitle: PropTypes.string,
  /** title of instance itself */
  title: PropTypes.string,
  /** publishing time */
  mPublishingAt: PropTypes.string,
  /** List of items within the instance */
  items: PropTypes.array,
  /** state of the instance */
  mState: PropTypes.string,
  /** List of users assigned to the instance */
  assignedUsers: PropTypes.array,
  /** sets the height of the Kanban card depending on contents */
  autoHeight: PropTypes.bool,
  /** Platform of instance */
  platform: PropTypes.string,
  /** hides mState info */
  hideStatusInfo: PropTypes.bool,
  /** callback to open destination of instance */
  openDestination: PropTypes.func,
  /** boolean to open destination */
  canOpenDestination: PropTypes.bool,
};

KanbanCard.defaultProps = {
  destination: '',
  mRelatedMembers: [],
  updatedAt: '',
  storyTitle: '',
  blockTitle: '',
  title: '',
  mPublishingAt: '',
  items: [],
  mState: '',
  assignedUsers: [],
  autoHeight: true,
  platform: '',
  hideStatusInfo: false,
  openDestination: () => {},
  canOpenDestination: false,
};

export default KanbanCard;
