import { startOfMinute, getMinutes, setMinutes } from 'date-fns';

/**
 * Rounds the given date to the nearest minute (or number of minutes).
 * Rounds up when the given date is exactly between the nearest round minutes.
 * @param {Date} date
 * @param {{nearestTo: number}} options
 */
// eslint-disable-next-line import/prefer-default-export
export const roundToNearestMinutes = (date, options) => {
  const { nearestTo } = options;
  const roundedMinutes = Math.ceil(getMinutes(date) / nearestTo) * nearestTo;
  return setMinutes(startOfMinute(date), roundedMinutes);
};

/**
 * Take an ISO string and turn it into a date and hour format.
 * Will return the date as: 14:05
 * @param {Date} date
 */
// eslint-disable-next-line import/prefer-default-export
export const isoDateToHoursAndMinutes = (date) => {
  const dateObject = new Date(date);
  const hours = dateObject.getHours();
  const minutes = String(dateObject.getMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`;
};

const isToday = (date) => {
  const today = new Date();
  return today.toDateString() === date.toDateString();
};

const isYesterday = (date) => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return yesterday.toDateString() === date.toDateString();
};

/**
 * Format ISO date to locale
 * today: hh:mm
 * yesterday: Yesterday, hh:mm
 * older: mm-dd hh:mm
 */
export const isoToLocaleShort = (isoDate, year = false) => {
  const date = new Date(isoDate);

  if (isToday(date)) {
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  }

  if (isYesterday(date)) {
    return `Yesterday, ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
  }

  return `${date.toLocaleDateString([], {
    year: year ? 'numeric' : undefined,
    month: 'short',
    day: '2-digit',
  })} ${date.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  })}`;
};
