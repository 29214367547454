import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from '@emotion/styled/macro';
import startOfDay from 'date-fns/start_of_day';

import useGetMember from 'api/useGetMember';
import { ReactComponent as Edit } from 'assets/icons/systemicons/edit.svg';
import { ReactComponent as PrintIcon } from 'assets/icons/systemicons/print.svg';
import { Button } from 'components/buttons';
import DebouncedLoadingIndicator from 'components/debouncedLoadingIndicator/DebouncedLoadingIndicator';
import Editor from 'components/editor';
import variants from 'components/editor/constants/types/editorVariants';
import LockedIndicator from 'components/lockedIndicator';
import Text from 'components/text/Text';
import TimeNavigator from 'components/timeNavigator';
import Tooltip from 'components/tooltip';
import DailyNotePrint from 'features/print/DailyNotePrint';
import { HStack } from 'layouts/box/Box';
import TimeIndicatorPicker from 'screens/planning/components/header/timeIndicatorPicker';
import { useAllMembersKeyed, useFeedTickerVisible, useUsers } from 'store';
import { DailyNote } from 'types';
import clickIfNothingSelected from 'utils/clickIfNothingSelected';
import { isoToLocaleShort } from 'utils/datetimeHelpers';
import { timeVariants } from 'utils/planningViews';

import useDailyNoteEditor from './hooks/useDailyNoteEditor';
import getUTCDateString from './utils/getUTCDateString';
import { useHasUpdatedSubscription, useSelectedDailyNoteDate } from './store';

const DailyNoteWrapper = styled('aside')<{ $isTickerVisible: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;
  inset: 0;
  margin-bottom: ${({ $isTickerVisible }) => ($isTickerVisible ? '40px' : 0)};
`;

const DailyNoteHeader = styled('header')`
  background: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
`;

const DateWrapper = styled('nav')`
  display: flex;
  align-items: center;
  height: 40px;
  background: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;

const EditorWrapper = styled('main')`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel2};
  flex: 1;
  overflow: hidden;
`;

const DailyNoteComponent = () => {
  const [selectedDate, setSelectedDate] = useSelectedDailyNoteDate();
  const users = useUsers();

  const utcDateString = getUTCDateString(selectedDate);
  const { data, loading } = useGetMember({ mId: 'dailyNote', mRefId: utcDateString });
  const [isTickerVisible] = useFeedTickerVisible() as [boolean, (val: boolean) => void];
  const [membersKeyed] = useAllMembersKeyed();

  const wrapperRef = useRef<HTMLElement | null>(null);
  const [inViewport, setInViewport] = useState(false);
  const [showPrintDialog, setShowPrintDialog] = useState(false);
  const [hasUpdatedSub, setHasUpdatedSub] = useHasUpdatedSubscription();

  const onOpenPrintDialog = () => {
    setShowPrintDialog(true);
  };

  const onClosePrintDialog = () => {
    setShowPrintDialog(false);
  };

  const {
    loading: contentLoading,
    content,
    writeLock,
    readLock,
    isSavingContent,
    isCancelled,
    lockedByUser,
    locking,
    lockedId,
    shouldResetSelection,
    onEditorUpdate,
    onFocusEditor,
    onSavePress,
    onCancelPress,
    beforeunloadFn,
    refetchContent,
    onForceUnlock,
  } = useDailyNoteEditor(data as DailyNote | undefined);

  useEffect(() => {
    const element = wrapperRef.current;
    const observer = new IntersectionObserver(([entry]) => {
      setInViewport(entry.isIntersecting);
    });

    if (element) {
      observer.observe(element);
    }

    // Cleanup observer on component unmount
    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  });

  useLayoutEffect(() => {
    window.addEventListener('beforeunload', (e) => {
      void beforeunloadFn(e);
    });

    return () => {
      void beforeunloadFn();
      window.removeEventListener('beforeunload', (e) => {
        void beforeunloadFn(e);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.mRefId, inViewport]);

  useEffect(() => {
    if (hasUpdatedSub && inViewport) {
      refetchContent();
      setHasUpdatedSub(false);
    }
  }, [hasUpdatedSub, inViewport, refetchContent, setHasUpdatedSub]);

  return (
    <DailyNoteWrapper $isTickerVisible={isTickerVisible}>
      <DailyNoteHeader>
        <Tooltip
          title={
            <HStack>
              <Edit /> {membersKeyed[data?.mUpdatedById ?? '']?.mTitle ?? 'Unknown'},
              {` ${isoToLocaleShort(data?.mUpdatedAt)}`}
            </HStack>
          }
        >
          <Text variant="h7">Daily note</Text>
        </Tooltip>
        <Button
          width="min-content"
          usage="text"
          onClick={onOpenPrintDialog}
          disabled={!data || writeLock}
        >
          <PrintIcon />
          Print
        </Button>
      </DailyNoteHeader>
      <Tooltip title={writeLock ? 'Unsaved changes' : ''}>
        <DateWrapper>
          <TimeNavigator
            onChange={(date) => setSelectedDate(startOfDay(new Date(date)))}
            timeVariant={timeVariants.DAY}
            time={selectedDate}
            disabled={writeLock}
          />
          <TimeIndicatorPicker
            isScheduleView
            onChange={(date: string) => setSelectedDate(startOfDay(new Date(date)))}
            timeVariant={timeVariants.DAY}
            time={new Date(selectedDate).toISOString()}
            disabled={writeLock}
          />
        </DateWrapper>
      </Tooltip>
      <DebouncedLoadingIndicator
        isLoading={loading || contentLoading || locking}
        debounceTime={locking ? 0 : undefined}
      />
      <EditorWrapper ref={wrapperRef} onClick={(e) => clickIfNothingSelected(e, onFocusEditor)}>
        <Editor
          variant={variants.DAILYNOTE}
          renderToolbar={writeLock ? undefined : () => null}
          readOnly={!writeLock}
          value={content}
          update={onEditorUpdate}
          users={users}
          isAllowed
          placeholder="Type Something..."
          height={readLock || writeLock ? 'calc(100% - 40px)' : '100%'}
          shouldResetSelection={shouldResetSelection}
          direction={undefined}
          hostReadSpeed={undefined}
          thumbnail={undefined}
          setEditor={undefined}
          platformStructure={undefined}
          platformId={undefined}
          isCmsBlock={undefined}
          editorFontSize={undefined}
          getPlaceholderConfigs={undefined}
          withSignedUrl={undefined}
          platformKind={undefined}
          showSidepanelButton
        />
        <LockedIndicator
          readLock={readLock}
          writeLock={writeLock}
          lockedBy={lockedByUser}
          isSaving={isSavingContent}
          isCancelled={isCancelled}
          onDone={onSavePress}
          onCancel={onCancelPress}
          lockedId={lockedId}
          onForceUnlock={onForceUnlock}
        />
      </EditorWrapper>
      {showPrintDialog && data && (
        <DailyNotePrint
          isDialogOpen={true}
          onCloseDialog={onClosePrintDialog}
          dailyNote={data as DailyNote}
          date={selectedDate}
        />
      )}
    </DailyNoteWrapper>
  );
};

export default DailyNoteComponent;
