import React, { useRef, memo, lazy, Suspense, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useExternalUrls } from 'store';
import useSearchBar from 'hooks/useSearchBar';
import LoadingIndicator from 'components/loadingIndicator';

import useSettingsValue from 'hooks/useSettingsValue';
import selectionTypes from '../selectionTypes';
import useStyles from './sidebar-styles';
import ViewComponent from './viewComponent';
import Chat from './chat';
import DailyNote from 'features/dailyNote';

const Iframe = lazy(() => import('./iframe'));
const IframeTrint = lazy(() => import('./iframe/iframe-trint'));

const viewsForViewComponent = [
  selectionTypes.CONTACTS,
  selectionTypes.TEAMS,
  selectionTypes.DEPARTMENT,
];

const SidebarView = (props) => {
  const classes = useStyles();
  const rootRef = useRef(null);
  const { selectionType } = props;
  const OtherProps = {};
  const {
    filterModel,
    updateFilterModel,
    history,
    updateHistory,
    currentSearch,
    updateCurrentSearch,
    searchString,
  } = useSearchBar({ historyLimit: 5 });

  const showChat = selectionType === selectionTypes.CHAT;
  const showGraphicsBox = selectionType === selectionTypes.GRAPHICSBOX;
  const showStoryBox = selectionType === selectionTypes.STORYBOX;
  const showAutomation = selectionType === selectionTypes.MOS;
  const showDailyNote = selectionType === selectionTypes.DAILYNOTE;
  const showViewBox = viewsForViewComponent.includes(selectionType);
  const [getSettingsValue] = useSettingsValue();
  const [externalUrls] = useExternalUrls();
  const useMosAutomation = getSettingsValue('mos.usemoshtmlautomationpanel') === 'true';
  const externalUrlsToShow = useMemo(
    () => externalUrls.filter((url) => url.showInRightPanel === 'true'),
    [externalUrls],
  );

  return (
    <>
      <div className={classes[showChat ? 'shown' : 'hidden']}>
        <Chat />
      </div>
      <div className={classes[showDailyNote ? 'shown' : 'hidden']}>
        <DailyNote />
      </div>
      {externalUrlsToShow.map((url) => (
        <div key={url.id} className={classes[url.mTitle === selectionType ? 'shown' : 'hidden']}>
          <Suspense fallback={<LoadingIndicator />}>
            {url.label === 'Trint' ? (
              <IframeTrint
                {...props}
                containerRef={rootRef}
                otherprops={{ ExternalName: url.mTitle }}
              />
            ) : (
              <Iframe {...props} containerRef={rootRef} otherprops={{ ExternalName: url.mTitle }} />
            )}
          </Suspense>
        </div>
      ))}
      {useMosAutomation && (
        <div className={classes[showAutomation ? 'shown' : 'hidden']}>
          <Suspense fallback={<LoadingIndicator />}>
            <Iframe {...props} containerRef={rootRef} otherprops={{ ExternalName: 'mos' }} />
          </Suspense>
        </div>
      )}
      <div className={classes[showGraphicsBox ? 'shown' : 'hidden']}>
        <Suspense fallback={<LoadingIndicator />}>
          <Iframe {...props} containerRef={rootRef} otherprops={{ ExternalName: 'pilotedge' }} />
        </Suspense>
      </div>
      <div className={classes[showStoryBox ? 'shown' : 'hidden']}>
        <Suspense fallback={<LoadingIndicator />}>
          <Iframe {...props} containerRef={rootRef} otherprops={{ ExternalName: 'mimir' }} />
        </Suspense>
      </div>
      <div className={classes[showViewBox ? 'shown' : 'hidden']}>
        <ViewComponent
          {...props}
          ref={rootRef}
          otherprops={OtherProps}
          filterModel={filterModel}
          updateFilterModel={updateFilterModel}
          history={history}
          updateHistory={updateHistory}
          currentSearch={currentSearch}
          updateCurrentSearch={updateCurrentSearch}
          searchString={searchString}
        />
      </div>
    </>
  );
};

SidebarView.propTypes = {
  /** Currently selected view type,
   * for each type a corresponding component is rendered
   * that's specified on viewComponents */
  selectionType: PropTypes.string,
  /** Show/hide the sidebar list  */
  sidebarHidden: PropTypes.bool,
};

SidebarView.defaultProps = {
  selectionType: null,
  sidebarHidden: false,
};

export default memo(SidebarView);
