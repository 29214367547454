import { atom, useAtom } from 'jotai';

export const rundownFocusScopes = {
  ASSETS: 'assets',
  INSTANCE_LISTS: 'instanceLists',
};

/* Atom for keeping track of current selected instances from rundown */
const selectedInstancesAtom = atom([]);
export const useSelectedInstances = () => useAtom(selectedInstancesAtom);

const instanceLockedIdAtom = atom(null);
export const useInstanceLockedId = () => useAtom(instanceLockedIdAtom);

const saveDialogOpenAtom = atom(false);
export const useSaveDialogOpen = () => useAtom(saveDialogOpenAtom);

const currentFocusAtom = atom('');
export const useCurrentFocus = () => useAtom(currentFocusAtom);

const refreshCurrentFocusAtom = atom(new Date().toISOString());
export const useRefreshCurrentFocus = () => useAtom(refreshCurrentFocusAtom);
