/* eslint-disable import/prefer-default-export */
import React, { lazy, Suspense } from 'react';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';

const GHPicker = lazy(() =>
  import('react-color').then((mod) => ({
    default: mod.GithubPicker,
  })),
);

export const GithubPicker = (props) => (
  <Suspense fallback={<LoadingIndicator />}>
    <GHPicker {...props} />
  </Suspense>
);
