import React from 'react';
import styled from '@emotion/styled';
import { TextField as MuiInput } from '@material-ui/core';

import { getKeyString } from 'utils/keyboardShortcuts';

type InputProps = {
  placeHolder: string;
  value: string;
  description: string;
  disabled: boolean;
  onChange: (value: string) => void;
};

const StyledInput = styled(MuiInput)`
  width: 100%;
`;

const reservedKeysSet = new Set<string>(['Alt', 'Shift', 'Control', 'Backspace', 'Delete', 'Meta']);

const ShortcutInput: React.FC<InputProps> = ({
  onChange,
  placeHolder,
  value,
  description,
  disabled,
}) => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    const key = event.key;

    if (reservedKeysSet.has(key)) return;

    const shortCut: string = getKeyString(event);

    onChange(shortCut);
  };

  return (
    <StyledInput
      placeholder={placeHolder}
      value={value}
      onKeyDown={handleKeyDown}
      helperText={description}
      disabled={disabled}
    />
  );
};

ShortcutInput.defaultProps = {
  placeHolder: 'Press a key combination',
};

export default ShortcutInput;
