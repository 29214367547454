import {
  ChangeEvent,
  KeyboardEvent,
  KeyboardEventHandler,
  MouseEvent,
  useEffect,
  useState,
} from 'react';
import styled from '@emotion/styled';

import useInputEvents from 'hooks/useInputEvents';
import preventDefaultAndPropagation from 'utils/preventDefaultAndStopPropagation';

import Text, { TextProps } from './Text';

import { Input } from './styled';

type TextAlign = 'left' | 'center' | 'right';

interface EditableTextProps extends TextProps {
  value: string;
  onUpdate: (value: string) => void;
  onInputClick?: (e: MouseEvent | KeyboardEvent) => void;
  disabled?: boolean;
  borderColor?: TextProps['color'];
  textAlign?: TextAlign;
}

export const StyledText = styled(Text)<{ $canUpdate: boolean; $textAlign: TextAlign }>`
  cursor: ${({ $canUpdate }) => ($canUpdate ? 'pointer' : 'select')};
  text-align: ${({ $textAlign = 'left' }) => $textAlign} !important;

  :hover {
    text-decoration: underline;
  }
`;

function EditableText({
  value,
  onUpdate,
  onInputClick,
  disabled,
  variant = 'listItemLabel',
  color = 'highEmphasis',
  borderColor = 'inputBorderResting',
  textAlign = 'left',
  ...rest
}: EditableTextProps) {
  const [showInput, setShowInput] = useState(false);
  const [localText, setLocalText] = useState(value);

  const onUpdateInput = (newValue: string) => {
    const update = newValue?.trim();
    if (!update) setLocalText(value);
    else if (update === value) setLocalText(value);
    else {
      setLocalText(update);
      onUpdate(update);
    }
    setShowInput(false);
  };

  const [inputRef, handleKeyDown] = useInputEvents(onUpdateInput, localText, value, true);

  const onChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setLocalText(event.target.value);

  const handleInputOnClick = (e: MouseEvent | KeyboardEvent) => {
    preventDefaultAndPropagation(e);
    if (onInputClick) {
      onInputClick(e);
    }
  };

  useEffect(() => {
    if (localText !== value) {
      setLocalText(value);
    }
  }, [value]);

  return showInput ? (
    <Input
      autoFocus
      type="text"
      $variant={variant}
      $color={color}
      $borderColor={borderColor}
      $textAlign={textAlign}
      inputRef={inputRef}
      value={localText}
      onKeyDown={
        handleKeyDown as unknown as KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>
      }
      onBlur={() => {
        setLocalText(value);
        setShowInput(false);
      }}
      onClick={handleInputOnClick}
      onChange={onChange}
      disabled={disabled}
    />
  ) : (
    <StyledText
      title={`${localText}\n\nClick to edit`}
      onClick={(e: MouseEvent | KeyboardEvent) => {
        if (!disabled) {
          preventDefaultAndPropagation(e);
          setShowInput(true);
        }
      }}
      $canUpdate={!disabled}
      $textAlign={textAlign}
      variant={variant}
      color={color}
      truncate
      {...rest}
    >
      {localText}
    </StyledText>
  );
}

export default EditableText;
