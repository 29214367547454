import PropTypes from 'prop-types';
import { publishingPoints as publishingPointIcons } from 'assets/icons/publishingPoints';
import { ReactComponent as ArrowRightSmall } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_right.svg';
import fallbackImage from 'assets/images/default/defaultThumbnail.png';
import uuidv1 from 'uuid/v1';
import RundownIcon, { iconUsage } from 'components/rundownIcons';
import { onImageLoadingError } from 'utils/image/imageUtils';
import {
  Wrapper,
  Avatar,
  ThumbnailImage,
  PublishingPointImage,
  CenterContent,
  Title,
  Icons,
  TimingDiv,
  Divider,
} from './instanceItem-styled';

const PreviewItem = ({
  title,
  thumbnail,
  showFocus,
  items,
  timingInfo,
  showDivider,
  selected,
  isFloat,
  platformKind,
}) => (
  <Wrapper $showFocus={showFocus} $selected={selected} $isFloat={isFloat}>
    <Avatar>
      <ThumbnailImage
        src={thumbnail ?? fallbackImage}
        onError={onImageLoadingError}
        alt="rnd_thmb"
      />
      <PublishingPointImage
        src={publishingPointIcons[platformKind] || publishingPointIcons.linear}
        alt="publishing-point"
      />
    </Avatar>
    <CenterContent>
      <Title>{title}</Title>
      <Icons>
        {items?.length > 0 &&
          items?.map((item) => (
            <RundownIcon
              key={uuidv1()}
              variant={item.title}
              width={16}
              height={16}
              usage={iconUsage.META}
            />
          ))}
      </Icons>
    </CenterContent>
    <TimingDiv>
      {timingInfo}
      <ArrowRightSmall />
    </TimingDiv>
    {showDivider && <Divider />}
  </Wrapper>
);

PreviewItem.propTypes = {
  /** Instance title */
  title: PropTypes.string,
  /** Url for thumbnail */
  thumbnail: PropTypes.string,
  /** automation items for that instance */
  items: PropTypes.arrayOf(PropTypes.object),
  /** last updated time */
  timingInfo: PropTypes.string,
  /** float status of the instance */
  isFloat: PropTypes.bool,
};

PreviewItem.defaultProps = {
  title: '',
  thumbnail: fallbackImage,
  items: [],
  timingInfo: '',
  isFloat: false,
};

export default PreviewItem;
