import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './detail-wrapper-styles';

const DetailWrapperView = ({ header, body }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.headerWrapper}>{header}</div>
      <div className={classes.bodyWrapper}>{body}</div>
    </div>
  );
};

DetailWrapperView.propTypes = {
  /** View to be shown on the head of detail views */
  header: PropTypes.node,
  /** View to be shown on the body of detail views */
  body: PropTypes.node,
};

DetailWrapperView.defaultProps = {
  header: <div>Header</div>,
  body: <div>Body</div>,
};

export default DetailWrapperView;
