import { MemberType } from 'types/graphqlTypes';
import providerStates from 'utils/constants/providerStates';
import variants from 'utils/instance/variants';

export const toggleViewConstraint = [
  variants.FACEBOOK,
  variants.YOUTUBE,
  variants.TWITTER,
  variants.INSTAGRAM,
];

export const validVariants = [
  variants.LINEAR,
  variants.TWITTER,
  variants.CMS,
  variants.YOUTUBE,
  variants.FACEBOOK,
  variants.INSTAGRAM,
  variants.TIKTOK,
  variants.LINKEDIN,
];

export const publishingPoints = {
  LINEAR: 'linear',
  CMS: 'cms',
  TWIITER: 'twitter',
  FACEBOOK: 'facebook',
  TIKTOK: 'tiktok',
};

/**
 * Return variant name if specified on validVariants, otherwise returns general variant
 *
 * @param {String} variant name of the variant i.e. 'cms', 'linear' etc.
 * @returns {String} if exists in validVariant list, returns the variant or returns general
 */

export const getVariant = (variant: string) => {
  if (validVariants.includes(variant)) return variant;
  return variants.GENERAL;
};

export const getMessage = (variant: string, providerState: string, providerMessage: string) => {
  if (variant === variants.LINEAR || variant === variants.GENERAL) return '';
  if (
    providerState === providerStates.FAILED ||
    providerState === providerStates.PENDING ||
    providerState === providerStates.EXPIRED ||
    providerState === providerStates.ASSIGNED ||
    providerState === providerStates.DELETED ||
    providerState === providerStates.PUBLISHED ||
    providerState === providerStates.DRAFT ||
    providerState === providerStates.UNKNOWN
  )
    return providerMessage;
  if (providerState === providerStates.SCHEDULED)
    return 'The instance will be published at scheduled time.';
  return `Unable to handle state. ${providerMessage}`;
};

export const propertiesToMapIntoCache: (keyof MemberType)[] = [
  'mTitle',
  'mThumbnailKey',
  'mContent',
  'mContentKey',
  'mContentUrl',
  'mCreatedAt',
  'mUpdatedAt',
  'mState',
  'mPublishingAt',
  'mType',
  'mStoryId',
  'mTemplateId',
  'isTemplateInstance',
  'items',
  'mProperties',
  'mMetaData',
  'mAssignedMembers',
  'mRelatedMembers',
  'locked',
];
