import { useCurrentTabValue } from 'store/tabs';
import conversationTypes from 'utils/constants/conversationTypes';

import Messages from './messages';

import {
  RightSideChatContainer,
  HeaderContainer,
  EmptyContainer,
  CenterText,
  Title,
} from './chat-styled';

const ChatContainer = () => {
  const currentTab = useCurrentTabValue();
  const { id, title, type, restricted } = currentTab ?? {};
  const shouldShowMessages = id && !restricted;

  const { conversationId, conversationType } = (() => {
    if (!type === conversationTypes.SPACE) return { conversationId: id, conversationType: type };

    if (id?.includes('-team'))
      return { conversationId: id.replace('-team', ''), conversationType: conversationTypes.TEAM };

    if (id?.includes('-department'))
      return {
        conversationId: id.replace('-department', ''),
        conversationType: conversationTypes.DEPARTMENT,
      };

    return { conversationId: id, conversationType: type };
  })();

  if (!shouldShowMessages)
    return (
      <EmptyContainer>
        <CenterText>Nothing to show</CenterText>
      </EmptyContainer>
    );

  return (
    <RightSideChatContainer>
      <HeaderContainer>
        <Title>{title}</Title>
      </HeaderContainer>
      <Messages mId={conversationId} convoType={conversationType} />
    </RightSideChatContainer>
  );
};

export default ChatContainer;
