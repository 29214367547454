import React from 'react';
import PropTypes from 'prop-types';
import { distanceInWordsToNow } from 'date-fns';
import Avatar from 'components/avatar/Avatar';
import useStyles from './messageSeen-styles';

const getReadTimeInfo = (mId, mTitle, mUpdatedAt, userId) => {
  const readTime = mUpdatedAt ? distanceInWordsToNow(mUpdatedAt, { addSuffix: true }) : '';
  if (mId === userId) return `You read this ${readTime}`;
  return `Read by ${mTitle} ${readTime}`;
};

const MessageSeen = ({ messageSeen, userId }) => {
  const classes = useStyles();

  return (
    <div className={classes.seenInfo}>
      {messageSeen.map((user) => {
        const { mId, mTitle, mAvatarUrl, mUpdatedAt } = user;
        return (
          <Avatar
            key={mId}
            variant="user"
            size={16}
            src={mAvatarUrl}
            title={mTitle}
            tooltipContent={getReadTimeInfo(mId, mTitle, mUpdatedAt, userId)}
          />
        );
      })}
    </div>
  );
};

MessageSeen.propTypes = {
  /** list of message seen info */
  messageSeen: PropTypes.arrayOf(
    PropTypes.shape({
      mId: PropTypes.string,
      mTitle: PropTypes.string,
      mAvatarUrl: PropTypes.string,
      mUpdatedAt: PropTypes.string,
    }),
  ),
  /** ID of the user */
  userId: PropTypes.string,
};

MessageSeen.defaultProps = {
  messageSeen: [],
  userId: '',
};

export default MessageSeen;
