// eslint-disable-next-line sort-imports
import { lazy, Suspense, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import styled from '@emotion/styled';

import DebouncedLoadingIndicator from 'components/debouncedLoadingIndicator/DebouncedLoadingIndicator';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import UPDATE_INSTANCE from 'graphql/mutations/updateInstance';
import useCheckPublishingPermission from 'hooks/useCheckPublishingPermission';
import useCheckUserRight from 'hooks/useCheckUserRight';
import useUnscheduleInstance from 'hooks/useUnscheduleInstance';
import mDefaultProperties from 'utils/constants/mDefaultProperties';
import getIdentifier from 'utils/instance/getAccountIdentifier';
import { getPlatform } from 'utils/instance/platform';
import {
  inReviewState,
  isApproved,
  restrictedPlatformProps,
} from 'utils/instance/restrictedPlatformPropsHelper';

import { useStoryMolecule } from './store/story';
import { useIsTimelineShown } from './store/toolbar';
import useInstances from './utils/useInstances';

const StoryTimeline = lazy(() => import('components/storyTimeline'));

const TimelineContainer = styled('div')`
  margin: -12px -12px 0px;
  width: calc(100% + 24px);
`;

interface InputProps {
  mId: string;
  mPublishingAt: string;
  mState?: string;
  mProperties?: {
    __typename: string;
    provider: {
      message: string;
    };
  };
}

const Timeline = () => {
  const { useStory, useIsPitchValue, usePlatforms } = useStoryMolecule();
  const [story] = useStory();
  const [platforms] = usePlatforms();
  const isPitch = useIsPitchValue();
  const { instances, error, loading } = useInstances(story?.mId ?? '', 'cache-first');
  const [updateInstance] = useMutation(UPDATE_INSTANCE);
  const [unschedule, unscheduleRundownInstances] = useUnscheduleInstance();
  const [restrictedPlatform] = useCheckPublishingPermission();
  const [checkUserRight] = useCheckUserRight();
  const [isTimelineShown] = useIsTimelineShown();
  const canScheduleInstance = checkUserRight('instance', 'schedule');

  const onSchedule = ({ id, start }: { id: string; start: string }) => {
    if (!canScheduleInstance) return false;
    try {
      const instanceToUpdate = instances.find(({ mId }) => mId === id);
      if (!instanceToUpdate) return false;
      const { mState, mProperties } = instanceToUpdate;
      const { platform, account } = mProperties;

      const hasPermissionForPlatform = checkUserRight(
        'platform',
        getIdentifier(platform, account?.accountTitle ?? ''),
      );
      if (!hasPermissionForPlatform) return false;

      if (!start) {
        if (platform === 'linear') {
          void unscheduleRundownInstances(instanceToUpdate);
          return true;
        }

        void unschedule(instanceToUpdate);
        return true;
      }

      const input: InputProps = {
        mId: id,
        mPublishingAt: start,
      };

      if (restrictedPlatform(platform) && start && !isApproved(mState)) {
        input.mState = inReviewState;
        input.mProperties = restrictedPlatformProps(platform);
      }

      const updatedInstance = {
        ...instanceToUpdate,
        mPublishingAt: start,
      };

      void updateInstance({
        variables: {
          input,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateInstance: updatedInstance,
        },
      });

      return true;
    } catch (e) {
      return false;
    }
  };

  const memoizedStoryTimeline = useMemo(
    () => (
      <StoryTimeline
        instances={instances.map(({ mId, mPublishingAt, mProperties, mStoryId }) => ({
          id: mId,
          mStoryId,
          start: mPublishingAt,
          publishingPoint: mProperties?.platform,
          accountIdentifier: getIdentifier(
            mProperties?.platform,
            mProperties?.account?.accountTitle ?? '',
          ),
          publishingPlatform: getPlatform(
            platforms,
            mProperties.platform,
            mProperties.platformKind,
          ),
          mDefaultProperties,
        }))}
        {...{ onSchedule, mPublishingAt: story?.mPublishingAt, canScheduleInstance }}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [instances],
  );

  if (isPitch || !isTimelineShown) return null;

  if (error) return `Error! ${error.message}`;

  return (
    <Suspense fallback={<LoadingIndicator />}>
      <DebouncedLoadingIndicator isLoading={loading} />
      <TimelineContainer>{memoizedStoryTimeline}</TimelineContainer>
    </Suspense>
  );
};

export default Timeline;
