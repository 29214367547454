/* eslint-disable import/no-extraneous-dependencies */
import { useContext, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import difference from 'lodash/difference';

import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import UserCtx from 'contexts/UserContext';
import memberTypes from 'graphql/memberTypes';
import UPDATE_CONTACT from 'graphql/mutations/updateContact';
import UPDATE_MEMBERS from 'graphql/mutations/updateMembers';
import GET_MEMBERS_OF from 'graphql/queries/getMembersOf';
import { getMembersOfQuery } from 'graphql/queryVariables';
import { useMembers } from 'store';
import { AssignedMember } from 'types';
import { MProperties } from 'types/graphqlTypes';
import useLogger from 'utils/useLogger';

import Info from './Info';

interface TeamDept {
  getMembersOf: AssignedMember[];
}

interface UpdateInput {
  mId: string;
  metadata?: string;
  mProperties?: MProperties;
  mDescription?: string;
}

interface InfoContainerProps {
  id: string;
  metadata?: string;
  properties: MProperties;
  type: 'user' | 'contact';
}

interface UpdatedMDFInput {
  metadata: string;
  mProperties: MProperties;
  mType: string;
  mDescription: string;
  mId: string;
  mTitle?: string;
}

function InfoContainer(props: Readonly<InfoContainerProps>) {
  const { id, properties, metadata, type } = props;
  const {
    __typename: typeName,
    email,
    firstName,
    surname,
    phone,
    dateOfBirth,
    readSpeed,
    username,
    notListed,
  } = properties;
  const input: UpdateInput = {
    mId: id,
  };
  const user = useContext(UserCtx);
  const [errorMap, setErrorMap] = useState<Record<string, string | undefined>>({});
  const [mProperties, setMProperties] = useState<MProperties>({
    __typename: typeName,
    firstName,
    surname,
    username,
    notListed,
    email,
    phone,
    dateOfBirth,
    jobTitle: type === memberTypes.USER ? 'User' : 'Contact',
    readSpeed,
  });
  const logger = useLogger('info-container');
  const [update] = useMutation(UPDATE_CONTACT);
  const [updateMembers] = useMutation(UPDATE_MEMBERS);
  const [allMembers, setAllMembers] = useMembers();

  const {
    data: teamData,
    error: teamError,
    loading: teamLoading,
  } = useQuery<TeamDept>(GET_MEMBERS_OF, {
    variables: {
      input: {
        membersOfType: memberTypes.TEAM_USER,
        mId: id,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: departmentData,
    error: departmentError,
    loading: departmentLoading,
  } = useQuery<TeamDept>(GET_MEMBERS_OF, {
    variables: {
      input: {
        membersOfType: memberTypes.DEPARTMENT_USER,
        mId: id,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  if (teamLoading || departmentLoading) return <LoadingIndicator />;
  if (teamError) return <div> No Team Data. </div>;
  if (departmentError) return <div> No department data </div>;

  const { getMembersOf: getTeams } = teamData as TeamDept;
  const { getMembersOf: getDepartments } = departmentData as TeamDept;

  const getMTitle = (value: Record<string, string>) => {
    const oldMetadata = JSON.parse(metadata ?? '{}') as Record<string, string>;
    if (oldMetadata.firstName !== value.firstName || oldMetadata.surname !== value.surname)
      return `${value.firstName} ${value.surname}`;
  };

  const updateContact = debounce((key: string, newValue: string | number) => {
    const updatedMProperties = {
      ...mProperties,
      [key]: newValue,
    };
    setMProperties(updatedMProperties);

    if (type === memberTypes.USER || type === memberTypes.CONTACT) {
      const updatedContactInput = {
        ...input,
        mProperties: updatedMProperties,
      };
      update({
        variables: {
          input: updatedContactInput,
        },
      }).then(
        () => {},
        () => {},
      );
    }
  }, 300);

  const updateMdf = debounce((newValue: string) => {
    if ((type === memberTypes.USER || type === memberTypes.CONTACT) && newValue) {
      const newMetadata = JSON.parse(newValue ?? '{}') as Record<string, string>;

      const mTitle = getMTitle(newMetadata);
      const updatedMDFInput = {
        ...input,
        metadata: newValue,
        mProperties: mProperties,
        mType: type,
        mDescription: newMetadata.mDescription,
      } as UpdatedMDFInput;
      if (mTitle) {
        updatedMDFInput.mTitle = mTitle;
      }
      const hasError = Object.values(errorMap).some((value) => value !== undefined);
      if (hasError) return;
      update({
        variables: {
          input: updatedMDFInput,
        },
      }).then(
        () => {},
        () => {},
      );
      setAllMembers({
        ...allMembers,
        [type]: allMembers[type]?.map((member) => {
          if (member?.mId === id) {
            return {
              ...member,
              metadata: newValue,
            };
          }
          return member;
        }),
      });
    }
  }, 300);

  const updateDescription = debounce((newDescription: string) => {
    input.mDescription = newDescription;
    update({
      variables: {
        input,
      },
    }).then(
      () => {},
      () => {},
    );
  }, 300);

  const addMembersToContact = async (resourceType: string, updatedMembers: AssignedMember[]) => {
    let mType: string;
    let currentMembers;
    if (resourceType === 'team') {
      mType = memberTypes.TEAM_USER;
      currentMembers = getTeams;
    } else if (resourceType === 'department') {
      mType = memberTypes.DEPARTMENT_USER;
      currentMembers = getDepartments;
    } else return;

    const existingMemberIds = currentMembers.map(({ mId }) => mId);
    const updatedMemberIds = updatedMembers.map(({ mId }) => mId);

    const addedMemberIds = difference(updatedMemberIds, existingMemberIds);
    const newMembers = {
      members: addedMemberIds.map((mId) => ({
        mId,
        mRefId: id,
        mType,
      })),
    };

    const removedMemberIds = difference(existingMemberIds, updatedMemberIds);
    const removedMembers = {
      members: removedMemberIds.map((mId) => ({
        mId,
        mRefId: id,
      })),
    };

    // Cache update for team and department
    try {
      await updateMembers({
        variables: {
          newMembers,
          removedMembers,
        },
        update: (proxy) => {
          const newMembersWithType = updatedMembers.map((member) => ({
            ...member,
            mType: resourceType,
          }));

          // updating the my teams or my departments cache on the left sidebar

          proxy.writeQuery({
            query: GET_MEMBERS_OF,
            variables: getMembersOfQuery(user.mId, mType),
            data: {
              getMembersOf: newMembersWithType,
            },
          });

          // updating the teams or departments cache on contact details in the right sidebar
          proxy.writeQuery({
            query: GET_MEMBERS_OF,
            variables: {
              input: {
                membersOfType: mType,
                mId: id,
              },
            },
            data: {
              getMembersOf: newMembersWithType,
            },
          });
        },
      });
    } catch (error) {
      logger.log(error);
    }
  };

  const onErrorUpdate = ({ fieldId, error }: { fieldId: string; error: string | undefined }) => {
    setErrorMap((prevState) => ({
      ...prevState,
      [fieldId]: error,
    }));
  };

  return (
    <Info
      {...props}
      teams={getTeams}
      departments={getDepartments}
      addTeamsToContact={(members) => addMembersToContact(memberTypes.TEAM, members)}
      addDepartmentsToContact={(members) => addMembersToContact(memberTypes.DEPARTMENT, members)}
      updateMdf={updateMdf}
      updateContact={updateContact}
      updateDescription={updateDescription}
      editDisabled={(type === memberTypes.USER && id !== user.mId) || false}
      errorMap={errorMap}
      onErrorUpdate={onErrorUpdate}
    />
  );
}

export default InfoContainer;
