import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps } from '@material-ui/core';

import transientOptions from 'theme/helpers';

interface TooltipProps extends MuiTooltipProps {
  noArrow?: boolean;
  severity?: string;
  maxWidth?: number;
  $maxWidth?: number;
  backgroundColor?: string;
  $backgroundColor?: string;
}

const StyledTooltip = styled(
  (props: TooltipProps) => (
    <MuiTooltip classes={{ popper: props.className }} {...props}>
      {props.children}
    </MuiTooltip>
  ),
  transientOptions,
)`
  & .MuiTooltip-tooltip {
    ${({ severity, $backgroundColor, $maxWidth, theme }) => css`
      ${theme.typography.dina.caption};
      color: ${severity === 'regular'
        ? theme.palette.dina.whiteHighEmphasis
        : theme.palette.dina.blackHighEmphasis};
      background-color: ${$backgroundColor ??
      (severity === 'regular'
        ? theme.palette.dina.tooltip
        : theme.palette.dina.tooltipWarningBackground)};
      border: 1px solid ${theme.palette.dina.tooltipBorder};
      box-shadow: 0px 0px 4px ${theme.palette.dina.boxShadowDark},
        0px 4px 4px ${theme.palette.dina.boxShadowDark};
      max-width: ${$maxWidth}px;
    `}
  }
  & .MuiTooltip-arrow {
    color: ${({ $backgroundColor, theme }) => $backgroundColor ?? theme.palette.dina.tooltip};
  }
`;

const Tooltip = ({
  children,
  title = '',
  noArrow,
  severity = 'regular',
  maxWidth = 300,
  backgroundColor,
  placement = 'bottom',
  ...rest
}: TooltipProps) => (
  <StyledTooltip
    arrow={!noArrow}
    severity={severity}
    $maxWidth={maxWidth}
    $backgroundColor={backgroundColor}
    title={title}
    placement={placement}
    {...rest}
  >
    {children}
  </StyledTooltip>
);

export default Tooltip;
