/* eslint-disable sort-imports */
import { useCallback } from 'react';
import { ApolloCache, gql, useMutation } from '@apollo/client';

import useToast from 'components/toast/useToast';
import { Maybe } from 'graphql/jsutils/Maybe';
import { Scalars } from 'types';
import {
  Auth,
  IntegrationEnum,
  IntegrationType,
  IntegrationUserType,
  ListActionType,
  MemberTypeEnum,
} from 'types/graphqlTypes';
import useLogger from 'utils/useLogger';

import { getErrorMessage, STANDARD_ACTION_ADMIN_KEYS } from '../commonKeys';

import {
  GET_ADMIN_ACTIONS,
  GET_USER_ACTIONS,
  GetActions,
  GetAdminActions,
} from './useGetIntegrations';

const CREATE_INTEGRATION = gql`
  mutation CreateIntegration($input: CreateIntegrationInput) {
    createIntegration(input: $input) {
      ${STANDARD_ACTION_ADMIN_KEYS}
    }
  }
`;

export type UpdateIntegrationInput = {
  mRefId: Scalars['String'];
  integrationType: IntegrationEnum;
  externalId?: Scalars['String'];
  mTitle?: Maybe<Scalars['String']>;
  auth?: Maybe<Auth>;
  mActive?: Maybe<Scalars['Boolean']>;
  mDescription?: Maybe<Scalars['String']>;
  mTypes?: Maybe<Array<Maybe<MemberTypeEnum>>>;
  endpoint?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
};

export type CreateIntegrationInput = {
  mTitle: Scalars['String'];
  externalId: Scalars['String'];
  integrationType: IntegrationEnum;
  auth?: Maybe<Auth>;
  mDescription?: Maybe<Scalars['String']>;
  mTypes?: Maybe<Array<Maybe<MemberTypeEnum>>>;
  endpoint?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
};

interface CreateIntegration {
  createIntegration: IntegrationType;
}

interface CreateInput {
  input: CreateIntegrationInput;
}

const adminIntegrationToUserIntegration = (integration: IntegrationType): IntegrationUserType => {
  return {
    iconUrl: integration.iconUrl,
    mDescription: integration.mDescription,
    mRefId: integration.mRefId,
    mTitle: integration.mTitle,
    mTypes: integration.mTypes,
    __typename: integration.__typename,
  };
};

export const writeIntegrationToCache = (
  client: ApolloCache<unknown>,
  updatedIntegration: IntegrationType,
  integrationType: IntegrationEnum,
) => {
  const cachedUserIntegrations = client.readQuery<GetActions>({
    query: GET_USER_ACTIONS,
    variables: {
      input: { type: ListActionType.User, integrationType },
    },
  });
  const cachedIntegrations = client.readQuery<GetAdminActions>({
    query: GET_ADMIN_ACTIONS,
    variables: {
      input: { type: ListActionType.Admin, integrationType },
    },
  });

  const integrations = cachedIntegrations?.getIntegrations ?? [];
  let newIntegrations: IntegrationType[] = [];
  const existingIntgIdx = integrations.findIndex((a) => a.mRefId === updatedIntegration.mRefId);
  if (existingIntgIdx >= 0) {
    newIntegrations = [...integrations];
    newIntegrations.splice(existingIntgIdx, 1, updatedIntegration);
  } else {
    newIntegrations = [updatedIntegration, ...integrations];
  }

  const userActions = cachedUserIntegrations?.getIntegrations ?? [];
  let newUserIntegrations: IntegrationUserType[] = [];
  const existingUserActionIdx = userActions.findIndex(
    (a) => a.mRefId === updatedIntegration.mRefId,
  );
  if (existingUserActionIdx >= 0) {
    if (updatedIntegration.mActive) {
      newUserIntegrations = [...userActions];
      newUserIntegrations.splice(
        existingUserActionIdx,
        1,
        adminIntegrationToUserIntegration(updatedIntegration),
      );
    } else {
      newUserIntegrations = userActions.filter((act) => act.mRefId !== updatedIntegration.mRefId);
    }
  } else if (updatedIntegration.mActive) {
    newUserIntegrations = [adminIntegrationToUserIntegration(updatedIntegration), ...userActions];
  }

  client.writeQuery<GetAdminActions>({
    query: GET_ADMIN_ACTIONS,
    variables: {
      input: { type: ListActionType.Admin, integrationType },
    },
    data: {
      getIntegrations: newIntegrations,
    },
  });

  client.writeQuery<GetActions>({
    query: GET_USER_ACTIONS,
    variables: {
      input: { type: ListActionType.User, integrationType },
    },
    data: {
      getIntegrations: newUserIntegrations,
    },
  });
};

export const useCreateIntegration = () => {
  const { toast, errorToast } = useToast();
  const [create] = useMutation<CreateIntegration, CreateInput>(CREATE_INTEGRATION);
  const logger = useLogger('CreateIntegration');
  const createIntegration = useCallback(
    async (externalId: string, type: IntegrationEnum) => {
      try {
        const result = await create({
          variables: {
            input: {
              externalId,
              integrationType: type,
              mTitle: `New ${type}`,
            },
          },
          update: (proxy, mutationResult) => {
            toast({
              title: 'Created ' + externalId,
              description: 'Action created successfully',
              type: 'success',
            });
            const newIntegration = mutationResult.data?.createIntegration;
            if (newIntegration) {
              writeIntegrationToCache(proxy, newIntegration, type);
            }
          },
          onError: (error: unknown) => {
            errorToast(error);
          },
        });
        return result.data?.createIntegration;
      } catch (err) {
        logger.log(getErrorMessage(err, externalId));
      }
    },
    [create],
  );
  return { createIntegration };
};
