/* eslint-disable import/no-extraneous-dependencies */
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';

import Avatar, { AvatarVariant } from 'components/avatar/Avatar';
import Chip from 'components/chip';
import { StyledTextField } from 'components/mdfEditor/fields/text/styled';
import Tooltip from 'components/tooltip';
import { AssignedMember } from 'types/members';

import { ChipWrapper, StyledOption, StyledOptionWrapper, StyledPopper } from './styled';

interface MemberAutoCompleteProps {
  selectedMembers: AssignedMember[];
  allMembers: AssignedMember[];
  setSelectedMembers: (values: AssignedMember[]) => void;
  onRemove: (value: AssignedMember, index: number) => void;
  onClose: () => void;
  autoFocus: boolean;
  selectOnFocus: boolean;
  placeholderText: string;
  noOptionsText: string;
  singleChoice: boolean;
  disableClearable: boolean;
  showChips: boolean;
}

const MemberAutoComplete = ({
  selectedMembers,
  allMembers,
  setSelectedMembers,
  onRemove,
  onClose,
  autoFocus,
  selectOnFocus,
  placeholderText,
  noOptionsText,
  singleChoice,
  disableClearable,
  showChips,
}: MemberAutoCompleteProps) => {
  const renderOption = (member: AssignedMember) => (
    <StyledOptionWrapper>
      <Avatar
        variant={member.mType as AvatarVariant}
        src={member.mAvatarUrl}
        size={24}
        title={member.mTitle}
      />
      <StyledOption className="styled-option" variant="listItemLabel" color="highEmphasis">
        {member.mTitle}
      </StyledOption>
    </StyledOptionWrapper>
  );

  const renderTags = (value: AssignedMember[]) =>
    value.map((member, index) => (
      <Tooltip title={member.mTitle} key={member.mId}>
        <ChipWrapper>
          <Chip
            variant={member?.mType as AvatarVariant}
            onDelete={() => {
              onRemove(member, index);
            }}
            label={member.mTitle}
            avatarUrl={member.mAvatarUrl}
          />
        </ChipWrapper>
      </Tooltip>
    ));

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <StyledTextField
      {...params}
      autoFocus={autoFocus}
      variant="filled"
      placeholder={placeholderText}
    />
  );

  const onChange = (newValue: AssignedMember[] | AssignedMember | null) => {
    const updatedMembers = newValue ?? [];
    setSelectedMembers(Array.isArray(updatedMembers) ? updatedMembers : [updatedMembers]);
  };

  return (
    <Autocomplete
      noOptionsText={noOptionsText}
      fullWidth
      selectOnFocus={selectOnFocus}
      filterSelectedOptions
      multiple={!singleChoice}
      options={allMembers}
      openOnFocus
      clearOnBlur={false}
      disableClearable={disableClearable}
      forcePopupIcon={false}
      onClose={onClose}
      value={selectedMembers}
      getOptionSelected={(option, value) => option.mId === value.mId}
      PopperComponent={StyledPopper}
      onChange={(_ev, value) => onChange(value)}
      renderTags={showChips ? renderTags : () => null}
      renderInput={renderInput}
      renderOption={renderOption}
      groupBy={(option) => option.mType}
      getOptionLabel={(option) => option?.mTitle ?? ''}
    />
  );
};

MemberAutoComplete.defaultProps = {
  selectedMembers: [],
  setSelectedMembers: () => {},
  onClose: () => {},
  helperText: '',
  showChips: true,
  singleChoice: true,
  selectOnFocus: false,
  disableClearable: true,
  autoFocus: false,
  placeholderText: 'Type to search, or press down to browse',
  noOptionsText: 'None found, or all available resources already added',
};

export default MemberAutoComplete;
