import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    borderBottom: `1px solid ${theme.palette.dina.dividerLight}`,
  },
  inputHeadline: {
    ...theme.typography.dina.overline,
    width: '100%',
    marginLeft: '18px',
    paddingTop: '12px',
  },
  textAreaDiv: {
    margin: '8px 16px',
  },
}));

export default useStyles;
