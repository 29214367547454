import { Storage } from '@aws-amplify/storage';

const DEFAULT_CONTENT_TYPE = 'text/plain';

const uploadToS3 = async (key, file, progressCallback) => {
  const options = {
    customPrefix: {
      public: '',
    },

    contentType: file.type || DEFAULT_CONTENT_TYPE,
  };

  if (progressCallback) options.progressCallback = progressCallback;

  return Storage.put(key, file, options);
};

const getItems = async (key) =>
  Storage.list(key, {
    customPrefix: {
      public: '',
    },
  });

const getSignedUrl = async (key, checkExistence) => {
  if (checkExistence) {
    const { results } = await getItems(key);
    if (Array.isArray(results) && !results.length) {
      return null;
    }
  }

  return Storage.get(key, {
    customPrefix: {
      public: '',
    },
  });
};

const getMediaKey = (id, file, fileName) => `${id}/media/${fileName || file.name}`;

const deleteFromS3 = async (key) =>
  Storage.remove(key, {
    customPrefix: {
      public: '',
    },
  });

export { uploadToS3, getMediaKey, getItems, getSignedUrl, deleteFromS3 };
