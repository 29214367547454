import { useCallback, useState } from 'react';
import { FetchPolicy, useApolloClient, useMutation } from '@apollo/client';

import useToast from 'components/toast/useToast';
import useBatchGetItems from 'features/kanban/api/useBatchGetItems';
import memberTypes from 'graphql/memberTypes';
import SUBSCRIBE_TO_RUNDOWN from 'graphql/subscriptions/updateRundown';
import { KanbanBoardType } from 'screens/space/types/space';
import { useUserConfig } from 'store';
import { GetMemberInput, MemberType, MemberTypeEnum, Rundown } from 'types/graphqlTypes';

import { noRundownCategoryId, useKanbanMolecule } from '../store/kanban';

import { CREATE_KANBAN_BOARD, GET_KANBAN_BOARD, SUBSCRIBE_TO_KANBAN } from './graphql';

type CreateKanbanInputType = {
  input: {
    mId: string;
    mType: MemberTypeEnum.Group;
  };
};

type CreateKanbanReturnType = {
  createMember: MemberType;
};

type GetMemberReturnType = {
  getMembersOf: KanbanBoardType[];
};

type GetMemberInputType = {
  input: GetMemberInput;
};

type BoardSubscriptionType = {
  data: {
    notifyMemberUpdateSubscription: KanbanBoardType | MemberType;
  };
};

type RundownSubscriptionType = {
  data: {
    updateRundownSubscription: Rundown;
  };
};

interface Props {
  mId: string;
  members?: MemberType[];
  fetchPolicy?: FetchPolicy;
}

export interface KanbanMemberType {
  [key: string]: MemberType;
}

type BoardMetadata = {
  mId: string;
  mRefId: string;
  mOrder: string[];
  mTitle: string;
};

type LaneMetadata = {
  [key: string]: BoardMetadata;
};

interface KanbanBoardMetadata {
  lanes: LaneMetadata;
}

export interface SubscriptionType {
  unsubscribe: () => void;
}

/** Convert metadata string to lanes array
 * @param metadata - metadata string
 * @returns lanes array
 */
const metadataToLanesArray = (metadata: string): BoardMetadata[] | [] => {
  try {
    const parsedMetadata = JSON.parse(metadata) as KanbanBoardMetadata;
    const { lanes: parsedLanes } = parsedMetadata;
    if (parsedLanes) return Object.values(parsedLanes);
  } catch (e) {
    return [];
  }

  return [];
};

/**  Remove deleted ids from lanes
 * @param lanes - lanes array
 * @param members - members array
 * @returns lanes array
 */
const removeDeletedIdsFromLanes = (lanes: BoardMetadata[], members: MemberType[]) => {
  const isRundownTemplate = members[0]?.mType === MemberTypeEnum.Rundowntemplate;
  if (!isRundownTemplate) return lanes;

  const updatedLanes = lanes.map((lane) => {
    const filtered = lane?.mOrder?.filter((id) => {
      const member = members.find(
        (m) => m.mType === MemberTypeEnum.Rundowntemplate && m.mRefId === id,
      );
      return member;
    });

    return { ...lane, mOrder: filtered };
  });

  return updatedLanes;
};

/** Flatten lanes array
 * @param lanes - lanes array
 * @returns flattened string array of lane mOrder ids
 */
const flattenLanes = (lanes: BoardMetadata[]): string[] => {
  const flattened = Object.values(lanes ?? []).reduce((acc, lane) => {
    const mOrder = lane?.mOrder ?? [];
    return [...acc, ...mOrder];
  }, [] as string[]);

  const noDupes = flattened.filter((item, index, self) => {
    return index === self.findIndex((i) => i === item);
  });

  return noDupes;
};

/** Convert lanes array to lanes object
 * @param lanes - lanes array
 * @returns lanes object
 */
const lanesArrayToObject = (lanes: BoardMetadata[]) => {
  const lanesObject = lanes.reduce((acc, lane) => {
    if (!lane?.mRefId) return acc;
    return {
      ...acc,
      [lane.mRefId]: lane,
    };
  }, {} as LaneMetadata);

  return lanesObject;
};

/** Get kanban board and members
 * @returns getKanban function and loading boolean
 */
const useGetKanban = () => {
  const [loading, setLoading] = useState<boolean>(true);

  const client = useApolloClient();
  const [user] = useUserConfig();
  const { errorToast } = useToast();
  const [getMembers] = useBatchGetItems();
  const [createKanbanBoard] = useMutation<CreateKanbanReturnType, CreateKanbanInputType>(
    CREATE_KANBAN_BOARD,
  );

  const { useCreateDefaultLane, useKanbanBoard, useKanbanLanes, useKanbanMembers } =
    useKanbanMolecule();

  const createDefaultLane = useCreateDefaultLane();
  const [, setKanbanBoard] = useKanbanBoard();
  const [, setKanbanLanes] = useKanbanLanes();
  const [, setKanbanMembers] = useKanbanMembers();

  // ─── Load Members ────────────────────────────────────────────────────
  const loadKanbanMembers = useCallback(
    async (kanbanBoard: KanbanBoardType) => {
      const metadata = kanbanBoard?.metadata as unknown;
      const metadataString = metadata as string;

      const lanesArray = metadata ? metadataToLanesArray(metadataString) : [];

      // Put the lanes into the store
      const lanesObject = lanesArrayToObject(lanesArray);
      setKanbanLanes(lanesObject);

      // Get member ids that were in the lanes and fetch them
      const flattened = flattenLanes(lanesArray);
      if (flattened.length === 0) return;
      const loadedMembers = (await getMembers(flattened)) as MemberType[];

      // Put the members into the store
      const members: KanbanMemberType = {};
      loadedMembers.forEach((member) => {
        if (!member?.mRefId) return;
        members[member.mRefId] = member;
      });

      setKanbanMembers(members);
    },
    [getMembers],
  );

  interface LaneMemberType extends MemberType {
    mRundownTemplateId?: string;
  }

  // ─── Put Members In Lanes ────────────────────────────────────────────
  const categorizeRundownMembers = useCallback(
    async (kanbanBoard: KanbanBoardType, members: LaneMemberType[]) => {
      const metadata = kanbanBoard?.metadata as unknown;
      const metadataString = metadata as string;
      const initialLanesArray = metadata ? metadataToLanesArray(metadataString) : [];

      // Remove ids from lanes that are not found in members array (archived/deleted).
      const lanesWithUpdatedIds = removeDeletedIdsFromLanes(initialLanesArray, members);

      // Filter away the no category lane if it is present
      const lanesArray =
        lanesWithUpdatedIds?.filter((lane) => lane.mId !== noRundownCategoryId) ?? [];

      // Put the lanes into the store
      const lanesObject = lanesArrayToObject(lanesArray);
      setKanbanLanes(lanesObject);

      // Remove duplicate members
      members?.filter((member, index, self) => {
        return index === self.findIndex((m) => m.mId === member.mId);
      });

      // Flattened list of no dupe rundown template ids
      const flattened = flattenLanes(lanesArray);

      // Members that are not in any lanes
      const notInLanes = members.filter((member: LaneMemberType) => {
        // Regular members
        if (member?.mRundownTemplateId) return !flattened.includes(member?.mRundownTemplateId);
        // Rundown templates
        return !flattened.includes(member.mRefId as string);
      });

      // Ids of the members that are not in any lanes
      const notInLaneIds = notInLanes.map(
        (member) => member?.mRundownTemplateId ?? (member.mRefId as string),
      );

      // Create a default lane with notInLanesIds
      createDefaultLane({ mOrder: notInLaneIds });

      // The members that are in the lanes
      const kanbanMembers: KanbanMemberType = {};
      members.forEach((item) => {
        if (!item?.mRefId) return;
        kanbanMembers[item.mRefId] = item;
      });

      setKanbanMembers(kanbanMembers);
    },
    [getMembers],
  );

  // ─── Create A Kanban Board ───────────────────────────────────────────
  const createKanban = useCallback(
    async (mId: string) => {
      const result = await createKanbanBoard({
        variables: {
          input: {
            mId,
            mType: MemberTypeEnum.Group,
          },
        },
        fetchPolicy: 'network-only',
      });

      return result?.data?.createMember as KanbanBoardType;
    },
    [createKanbanBoard],
  );

  // ─── Update Board With Subscribed Data ───────────────────────────────
  const updateBoardWithSubscribedData = useCallback(
    (newBoard: KanbanBoardType) => {
      if (!newBoard) return;

      setKanbanBoard(newBoard);
      loadKanbanMembers(newBoard).catch((error: unknown) => errorToast(error));
    },
    [client, setKanbanBoard, loadKanbanMembers],
  );

  // ─── Start Subscription ──────────────────────────────────────────────
  const startSubscription = useCallback(
    (mId: string): [SubscriptionType, SubscriptionType] => {
      const observer = client.subscribe({
        query: SUBSCRIBE_TO_KANBAN,
        variables: {
          mIdSubscribed: mId,
        },
      });

      const subscription = observer.subscribe({
        next: (data: BoardSubscriptionType) => {
          const updatedMember = data?.data?.notifyMemberUpdateSubscription;
          if (!updatedMember) return;

          if (updatedMember.mType === memberTypes.SPACE_GROUP) {
            // prevent update if the user is the one who updated the board
            if (updatedMember?.mUpdatedById === user?.mId) return;
            updateBoardWithSubscribedData(updatedMember as KanbanBoardType);
          } else {
            setKanbanMembers((prevState) => {
              const newMembers = prevState ?? {};
              newMembers[updatedMember.mId as string] = updatedMember as MemberType;
              return { ...newMembers };
            });
          }
        },
      });

      const rundownObserver = client.subscribe({
        query: SUBSCRIBE_TO_RUNDOWN,
        variables: {
          mIdSubscribed: mId,
        },
      });

      const rundownSubscription = rundownObserver.subscribe({
        next: (data: RundownSubscriptionType) => {
          const updatedMember = data?.data?.updateRundownSubscription;
          setKanbanMembers((prevState) => {
            const newMembers = prevState ?? {};
            if (updatedMember?.mId) newMembers[updatedMember.mId] = updatedMember;
            return { ...newMembers };
          });
        },
      });

      return [subscription, rundownSubscription];
    },
    [client, updateBoardWithSubscribedData],
  );

  // ─── Load The Board ──────────────────────────────────────────────────
  const loadKanbanBoard = useCallback(
    async (mId: string, fetchPolicy?: FetchPolicy) => {
      const result = await client.query<GetMemberReturnType, GetMemberInputType>({
        query: GET_KANBAN_BOARD,
        variables: {
          input: {
            mId,
            membersOfType: MemberTypeEnum.Group,
          },
        },
        fetchPolicy,
      });

      const board = result?.data?.getMembersOf;

      if (board?.length > 0) return board[0];

      // If board does not exist, create one
      const newBoard = await createKanban(mId);
      return newBoard;
    },
    [client],
  );

  // ─── Get Kanban Board And Members ────────────────────────────────────
  const getKanban = useCallback(
    async ({ mId, members, fetchPolicy }: Props) => {
      if (!mId) return;
      setLoading(true);

      // Load the board
      const board: KanbanBoardType = await loadKanbanBoard(mId, fetchPolicy);
      setKanbanBoard(board);

      // If input includes members, put them in lanes. Else, load board members.
      if (members) {
        await categorizeRundownMembers(board, members);
      } else {
        await loadKanbanMembers(board);
      }

      setLoading(false);
    },
    [loadKanbanBoard, categorizeRundownMembers, loadKanbanMembers],
  );

  const getKanbanWithoutLoading = useCallback(
    async ({ mId, members }: Props) => {
      if (!mId) return;

      const board = await loadKanbanBoard(mId);
      setKanbanBoard(board);
      if (members) await categorizeRundownMembers(board, members);
      else await loadKanbanMembers(board);
    },
    [loadKanbanBoard, categorizeRundownMembers, loadKanbanMembers],
  );

  return { getKanban, loading, getKanbanWithoutLoading, startSubscription };
};

export default useGetKanban;
