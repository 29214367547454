import { useMutation } from '@apollo/client';
import UPDATE_MEMBER from 'graphql/mutations/updateMember';

const usePublishDateUpdate = () => {
  const [updateStoryPublishingTime] = useMutation(UPDATE_MEMBER);

  const update = async (mId, mPublishingAt, mPublishingEnd) => {
    const input = {
      mId,
      mPublishingAt: mPublishingAt || null,
      mPublishingEnd: mPublishingEnd || null,
    };

    await updateStoryPublishingTime({
      variables: {
        input,
      },
    });
  };
  return [update];
};

export default usePublishDateUpdate;
