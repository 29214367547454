import { useCallback, useEffect, useMemo, useState } from 'react';

import { CheckboxWithLabel } from 'components/createNewV3/CreateNew';
import Dialog from 'components/dialogs/DialogBuilder';
import Tooltip from 'components/tooltip';
import { FieldTypeEnum, MdfField } from 'types/graphqlTypes';

interface Props {
  onConfirm: (field: MdfField) => void;
  fieldToConfig: MdfField | null;
  open: boolean;
  setOpen: (val: boolean) => void;
}

export const supportsAdditionalConfig = (field: MdfField) => {
  return field.type === FieldTypeEnum.multiplechoice || field.type === FieldTypeEnum.user;
};

const defaultModel: MdfField = {
  fieldId: 'someId',
  type: FieldTypeEnum.text,
  defaultValue: { value: null },
};

type Member = 'user' | 'department' | 'team' | 'contact';

export function ConfigFieldDialog({ onConfirm, open, setOpen, fieldToConfig }: Readonly<Props>) {
  const [field, setField] = useState<MdfField>(defaultModel);

  const shouldShow = useMemo(() => {
    return fieldToConfig ? supportsAdditionalConfig(fieldToConfig) : false;
  }, [fieldToConfig]);

  useEffect(() => {
    setField(fieldToConfig ? { ...fieldToConfig } : defaultModel);
  }, [fieldToConfig]);

  const toggleMember = useCallback(
    (val: Member) => {
      let filter = field.filter ?? [];
      if (filter.includes(val)) {
        filter = [...filter.filter((f) => f !== val)];
      } else {
        filter = [...filter, val];
      }
      setField({ ...field, filter });
    },
    [field, setField],
  );

  const AdditionalConfig = useMemo(() => {
    switch (field.type) {
      case FieldTypeEnum.multiplechoice: {
        return (
          <Tooltip title="Allow users to add values that are not present in the model">
            <span>
              <CheckboxWithLabel
                selected={field.freeform ?? false}
                disabled={undefined}
                onClick={() => {
                  setField({ ...field, freeform: !field.freeform });
                }}
                label="Freeform"
              />
            </span>
          </Tooltip>
        );
      }
      case FieldTypeEnum.user: {
        return (
          <div>
            <CheckboxWithLabel
              selected={field.filter?.includes('user') ?? false}
              onClick={() => toggleMember('user')}
              label="Users"
            />
            <CheckboxWithLabel
              selected={field.filter?.includes('team') ?? false}
              onClick={() => toggleMember('team')}
              label="Teams"
            />
            <CheckboxWithLabel
              selected={field.filter?.includes('department') ?? false}
              onClick={() => toggleMember('department')}
              label="Departments"
            />
            <CheckboxWithLabel
              selected={field.filter?.includes('contact') ?? false}
              onClick={() => toggleMember('contact')}
              label="Contacts"
            />
          </div>
        );
      }
      case FieldTypeEnum.text:
      case FieldTypeEnum.checkbox:
      case FieldTypeEnum.choice:
      case FieldTypeEnum.date:
        return null;
    }
  }, [field]);

  const doConfirm = () => {
    onConfirm(field);
    setField(defaultModel);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <Dialog.Header>Additional field options</Dialog.Header>
      <Dialog.Body>{shouldShow && AdditionalConfig}</Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton />
        <Dialog.ConfirmButton label="Confirm" onConfirm={doConfirm} />
      </Dialog.Footer>
    </Dialog>
  );
}
