import instanceVariants from 'utils/instance/variants';

const variants = {
  ...instanceVariants,
  MESSAGE: 'message',
  SCRIPT: 'script',
  DAILYNOTE: 'dailyNote',
};

export default variants;
