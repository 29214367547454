import { useState } from 'react';
import { useDrag, DragPreviewImage } from 'react-dnd';
import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import GET_INSTANCE_FROM_CACHE from 'graphql/queries/getInstanceFromLocalResolver';
import dndTypes from 'utils/dndTypes';
import useDinaNavigate from 'hooks/useDinaNavigate';
import useGetAssignedMembers from 'hooks/useGetAssignedMembers';
import Popover from 'components/popover';
import useImageUrl from 'hooks/useImageUrl';
import distanceInWord from 'utils/distanceInWords';
import instanceVariants from 'utils/instance/variants';
import DragSingle from 'assets/images/rundown/DragSingle.png';
import DragMultiple from 'assets/images/rundown/DragMultiple.png';
import { defaultLinearPlatformKind } from 'utils/instance/platform';
import isFloated from 'utils/instance/isFloat';
import InstancePreview from '../../instancePreview';
import InstanceItem from './instanceItem-view';

const PreviewWrapper = styled('div')`
  width: 560px;
`;

const EmptyDiv = styled('div')`
  display: none;
`;

const anchorOrigin = {
  vertical: 'center',
  horizontal: 'right',
};

const transformOrigin = {
  vertical: 'center',
  horizontal: 'left',
};

const getSortedArrayByKey = (selectedIds = {}) =>
  Object.keys(selectedIds)
    .sort((a, b) => a - b)
    .map((key) => selectedIds[key]);

const DraggableGridItem = ({
  mId,
  defaultThumb,
  publishingtime,
  currentOrder,
  currentAnchor,
  selectedIds,
  toggleCurrentItemFromSelectedIds,
  shiftItemsToSelectedIds,
  selectSingleItem,
  index,
  order,
  changeCurrentOrder,
  platformKind,
  ...rest
}) => {
  const { navigateTo } = useDinaNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const selected = selectedIds[index] === mId;

  const { data } = useQuery(GET_INSTANCE_FROM_CACHE, {
    variables: {
      input: {
        mId,
        mRefId: mId,
      },
    },
  });

  const handleClick = (event) => {
    const dataObject = { ignoreSelectedIds: false };
    if (order !== currentOrder) {
      changeCurrentOrder(order);
      dataObject.ignoreSelectedIds = true;
    }

    if (!(event.altKey || event.ctrlKey || event.metaKey || event.shiftKey)) {
      setAnchorEl(event.currentTarget);
      selectSingleItem(mId, index);
      return;
    }

    if (event.ctrlKey || event.metaKey) {
      dataObject.ignoreSelectedIds
        ? selectSingleItem(mId, index)
        : toggleCurrentItemFromSelectedIds(mId, index);
      return;
    }
    if (event.shiftKey) {
      shiftItemsToSelectedIds(index, order, dataObject.ignoreSelectedIds);
    }
  };

  const payload = {
    id: mId,
    ids: selected ? getSortedArrayByKey(selectedIds) : [mId],
    platform: instanceVariants.LINEAR,
    platformKind: platformKind || defaultLinearPlatformKind,
  };

  const [, dragRef, preview] = useDrag({
    type: dndTypes.INSTANCE,
    item: { type: dndTypes.INSTANCE, payload },
  });

  const {
    mTitle,
    items,
    mUpdatedAt,
    mStoryId,
    mContentKey,
    mProperties,
    mState,
    mMetaData,
    isTemplateInstance,
    mTemplateId,
    mAssignedMembers,
    mThumbnailKey,
  } = data?.instance || {};

  const thumbnail = useImageUrl(mThumbnailKey);

  const isFloat = isFloated(mMetaData || []);

  const [getAssignedMembers] = useGetAssignedMembers(mAssignedMembers || []);

  if (!data) return <EmptyDiv />;

  const { account, platform } = mProperties || {};
  const { accountId } = account || {};

  const timeString = distanceInWord(mUpdatedAt);
  const isOpenStoryDisabled = isTemplateInstance || mTemplateId;

  const [assignedUsers] = getAssignedMembers();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenStory = () => {
    mStoryId && navigateTo('story', mStoryId, { tab: 'instances', entityId: mId });
  };

  const handleOpenRundown = () => {
    navigateTo(isTemplateInstance ? 'rundowntemplate' : 'rundown', accountId);
  };

  const onOpenStory = () => {
    handleClose();
    handleOpenStory();
  };

  const onOpenRundown = () => {
    handleClose();
    handleOpenRundown();
  };

  const onDragStart = () => {
    if (!selected) selectSingleItem(mId, index);
  };

  const getPreviewImage = () => {
    if (!selected) return DragSingle;
    return Object.entries(selectedIds).length > 1 ? DragMultiple : DragSingle;
  };

  const previewImage = getPreviewImage();
  return (
    <>
      <div ref={dragRef} onClick={handleClick} role="presentation" onDragStart={onDragStart}>
        <DragPreviewImage connect={preview} src={previewImage} />
        <InstanceItem
          title={mTitle}
          thumbnail={thumbnail}
          timingInfo={timeString}
          showFocus={Boolean(anchorEl)}
          selected={selected}
          items={items}
          isFloat={isFloat}
          platformKind={platformKind}
          {...rest}
        />
      </div>
      <Popover
        anchorEl={anchorEl}
        noMargin
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        type="surfaceCardDark"
      >
        <PreviewWrapper>
          <InstancePreview
            id={mId}
            title={mTitle}
            mContentKey={mContentKey}
            onOpenStory={onOpenStory}
            onOpenRundown={onOpenRundown}
            assignees={assignedUsers}
            state={mState}
            metaData={mMetaData}
            destination={account.accountTitle}
            platform={platform}
            publishingAt={publishingtime}
            isOpenStoryDisabled={isOpenStoryDisabled}
            onClose={handleClose}
            instance={data?.instance}
            thumbnail={thumbnail}
          />
        </PreviewWrapper>
      </Popover>
    </>
  );
};

export default DraggableGridItem;
