import { useEffect, useRef } from 'react';
import styled from '@emotion/styled';

import { FieldTypeEnum } from 'types/graphqlTypes';

interface Props {
  setValue: (val: string) => void;
  value: string;
  type?: FieldTypeEnum;
}

const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  padding-left: 6px;
`;

function LWTextField({ setValue, value, type }: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, []);
  return (
    <StyledInput
      ref={inputRef}
      type={type === FieldTypeEnum.number ? 'number' : 'text'}
      value={value}
      onChange={(ev) => setValue(ev.target.value)}
    />
  );
}

export default LWTextField;
