import { useCallback, useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { endOfDay, startOfDay } from 'date-fns';

import UserContext from 'contexts/UserContext';
import GET_PLATFORMS from 'graphql/queries/getPlatforms';
import GET_RUNDOWNS_BY_PUBLISHING_DATE from 'graphql/queries/getRundownByPublishingDate';
import useSettingsValue from 'hooks/useSettingsValue';
import { Destination, DestinationAccount, Platform, PlatformAccountType } from 'types';
import { GetMembersByPublishingDateInput, MemberTypeEnum, Rundown } from 'types/graphqlTypes';
import {
  defaultLinearPlatformKind,
  getPlatform,
  linearPlatformKinds,
} from 'utils/instance/platform';
import { isRundownEditable } from 'utils/rundown/isRundownEditable';

type GetPlatformsQueryReturn = {
  getPlatforms: Platform[];
};

type GetRundownsByPublishingDateInput = {
  input: GetMembersByPublishingDateInput;
};

type GetRundownsByPublishingDateQueryReturn = {
  getRundownsByPublishingDate: Rundown[];
};

const getAccountFromRundown = (rundown: Rundown): DestinationAccount => ({
  accountId: rundown.mId,
  accountTitle: rundown.mTitle as string,
  accountUrl: rundown.mTitle as string,
  accountLogo: 'url@tolog',
  recurrence: rundown.recurrence,
  publishingAt: rundown.mPublishingAt,
  platformKind: rundown.platformKind,
  rundownTemplateId: rundown.mRundownTemplateId,
});

const emptyAccount: PlatformAccountType = {
  accountId: null,
  accountTitle: 'Unassigned',
  accountUrl: 'Unassigned',
  accountLogo: 'url@tolog',
  isUnassigned: true,
  recurrence: {},
};

const useGetPlatforms = (
  date: string | Date | null,
  instanceType?: string,
  platformKind?: string,
  skip = false,
) => {
  const [destinations, setDestinations] = useState<Destination[]>([]);
  const [platforms, setPlatforms] = useState<Platform[]>([]);
  const linearPlatformId = 'linear';
  const [getSettingsValue] = useSettingsValue();
  const { groups } = useContext(UserContext);

  const { data, error, loading } = useQuery<GetPlatformsQueryReturn>(GET_PLATFORMS, {
    skip,
  });

  const startDate = startOfDay(date ?? new Date()).toISOString();
  const endDate = endOfDay(date ?? new Date()).toISOString();

  const {
    data: rundownsByDateData,
    error: errorRundownsByDate,
    loading: loadingRundownsByDate,
  } = useQuery<GetRundownsByPublishingDateQueryReturn, GetRundownsByPublishingDateInput>(
    GET_RUNDOWNS_BY_PUBLISHING_DATE,
    {
      variables: {
        input: {
          mType: 'rundown' as MemberTypeEnum,
          startDate,
          endDate,
        },
      },
      skip: !data || !date || skip,
    },
  );

  const getDefaultLinerPlatform = (linearPlatformKind?: string) => {
    const linear = {
      id: linearPlatformId,
      mTitle: 'current rundowns',
      mProperties: {
        platform: linearPlatformId,
        platformIcon: linearPlatformKind ?? linearPlatformId,
        accounts: [emptyAccount],
        platformKind: linearPlatformKind ?? defaultLinearPlatformKind,
      },
    };

    return linear;
  };

  const getLinearPlatforms = useCallback(() => {
    const enableLinearPlatform = getSettingsValue('rundown.audioEnabled') === 'true';
    if (!enableLinearPlatform) return [getDefaultLinerPlatform()];
    return linearPlatformKinds.map((p) => getDefaultLinerPlatform(p));
  }, [getSettingsValue]);

  useEffect(() => {
    if (loading || loadingRundownsByDate) return;

    const otherPlatforms = data?.getPlatforms?.filter((p) => p.mRefId !== linearPlatformId) || [];
    const linearPlatforms = getLinearPlatforms();

    const allPlatforms = [...otherPlatforms, ...linearPlatforms];

    if (rundownsByDateData?.getRundownsByPublishingDate) {
      const linerPlatformMap = new Map<string, Platform>();

      linearPlatforms.forEach((p) => {
        linerPlatformMap.set(p.mProperties.platformKind ?? 'linear', p);
      });

      const { getRundownsByPublishingDate } = rundownsByDateData;
      getRundownsByPublishingDate.forEach((rundown) => {
        const { permissions } = rundown;

        if (!isRundownEditable({ permissions, groups })) return;

        const account = getAccountFromRundown(rundown);

        const linearPlatform = linerPlatformMap.get(
          account.platformKind ?? defaultLinearPlatformKind ?? 'linear',
        );

        linearPlatform?.mProperties?.accounts?.push(account);
      });

      if (instanceType) {
        const platformsByType = getPlatform(allPlatforms, instanceType, platformKind);
        const targets =
          platformsByType?.mProperties?.accounts?.map(({ accountId, accountTitle, recurrence }) => {
            const rundown = getRundownsByPublishingDate.find((r) => r.mId === accountId);
            const mPublishingAt = rundown?.mPublishingAt;
            return {
              id: accountId,
              value: instanceType === 'linear' && accountId ? accountId : accountTitle,
              title: accountTitle,
              publishingTime: instanceType === 'linear' && mPublishingAt ? mPublishingAt : null,
              startTime: recurrence?.startTime ?? undefined,
              timeZone: recurrence?.timeZone ?? undefined,
              account: rundown ? getAccountFromRundown(rundown) : emptyAccount,
              rundownTemplateId: rundown?.mRundownTemplateId,
            } as Destination;
          }) || [];

        setDestinations(targets);
      }
    }
    setPlatforms(allPlatforms);
  }, [
    data,
    rundownsByDateData,
    loading,
    loadingRundownsByDate,
    getLinearPlatforms,
    instanceType,
    groups,
    platformKind,
  ]);

  return [
    platforms,
    error || errorRundownsByDate,
    loading || loadingRundownsByDate,
    destinations,
  ] as const;
};

export default useGetPlatforms;
