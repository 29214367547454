import styled from '@emotion/styled';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import Scrollbar from 'components/scrollbar';
import useCheckUserRight from 'hooks/useCheckUserRight';
import MetaDataView from 'screens/main/components/rightArea/sidebar/metadata/editMetadata/EditMetadata';
import { useMembersDialog } from 'store';
import { AssignedMember } from 'types';
import { MProperties } from 'types/graphqlTypes';

import AddContent from '../../../addContent';
import AddDate from '../../../addDate';
import AddDescription from '../../../addDescription';
import AddItem from '../../../addItem';
import DetailList from '../../../detailList';
import ShowItem from '../../../showItem';

const InfoWrapper = styled('div')`
  height: 100%;
`;

const MetadataWrapper = styled('div')`
  margin: 5px 15px 10px 17px;
`;

interface InfoViewProps {
  metadata?: string;
  properties: MProperties;
  description?: string;
  updateContact: (key: string, value: string | number) => void;
  updateMdf: (mdf: string) => void;
  updateDescription: (newDescription: string) => void;
  teams: AssignedMember[];
  addTeamsToContact: (members: [] | AssignedMember[]) => Promise<void>;
  departments: AssignedMember[];
  addDepartmentsToContact: (members: [] | AssignedMember[]) => Promise<void>;
  editDisabled?: boolean;
  type: 'user' | 'contact';
  errorMap: Record<string, string | undefined>;
  onErrorUpdate: ({ fieldId, error }: { fieldId: string; error: string | undefined }) => void;
}

function Info({
  updateMdf,
  updateContact,
  description = '',
  metadata = '',
  updateDescription,
  teams = [],
  addTeamsToContact,
  departments = [],
  addDepartmentsToContact,
  properties,
  editDisabled,
  type,
  errorMap,
  onErrorUpdate,
}: Readonly<InfoViewProps>) {
  const [, setGetMembers] = useMembersDialog();
  const { email, firstName, phone, dateOfBirth, readSpeed } = properties;

  const [checkUserRight] = useCheckUserRight();
  const canUseContactMetadata = checkUserRight('feature', 'contact-metadata');

  const { mdfs } = useGetMdfs({ all: true });

  const mdf = mdfs.find((m) => m.id === (type === 'user' ? 'user-mdf' : 'contact-mdf'));

  const doGetMembers = (variant: 'user' | 'team' | 'department' | 'all' | undefined) => {
    setGetMembers({
      variant,
      open: true,
      startWith: variant === 'team' ? [...teams] : [...departments],
      dialogTitle: `Add contact to ${variant}s`,
      removeTooltipText: `Remove ${firstName as string} from ${variant}`,
      subHeader: `${firstName as string} is part of ${variant}s:`,
      onOk: (newMembers) => {
        if (variant === 'team') {
          void addTeamsToContact(newMembers);
        } else {
          void addDepartmentsToContact(newMembers);
        }
      },
    });
  };

  return (
    <InfoWrapper>
      <Scrollbar top={8} bottom={8}>
        {metadata && mdf && canUseContactMetadata ? (
          <MetadataWrapper>
            <MetaDataView
              data={{ metadata }}
              onUpdate={(newValue) => {
                updateMdf(newValue);
              }}
              mdf={mdf}
              errorMap={errorMap}
              editDisabled={editDisabled}
              onErrorUpdate={onErrorUpdate}
            />
          </MetadataWrapper>
        ) : (
          <>
            {editDisabled ? (
              <ShowItem title="Description" content={description} onClick={undefined} />
            ) : (
              <AddDescription
                addItemTitle="Add Description..."
                onUpdate={updateDescription}
                helperText="Type a short Bio"
                {...{ description }}
              />
            )}
            {editDisabled ? (
              <ShowItem title="E-MAIL" content={email as string} onClick={undefined} />
            ) : (
              <AddContent
                addItemTitle="Add an Email"
                content={email as string}
                helperText="Email address"
                showItemTitle="E-MAIL"
                onUpdate={(newValue: string) => {
                  updateContact('email', newValue);
                }}
                type="email"
                inputLabel="E-MAIL"
              />
            )}
            {editDisabled ? (
              <ShowItem title="MOBILE PHONE" content={phone as string} onClick={undefined} />
            ) : (
              <AddContent
                addItemTitle="Add a Mobile Phone number"
                content={phone as string}
                showItemTitle="MOBILE PHONE"
                helperText="Type a mobile phone number here"
                onUpdate={(newValue: string) => {
                  updateContact('phone', newValue);
                }}
                type="tel"
                inputLabel="MOBILE PHONE"
              />
            )}
            <AddContent
              addItemTitle="Add Reading Speed"
              content={readSpeed as string}
              showItemTitle="READING SPEED (WORDS PER MINUTE)"
              helperText="Words per minute"
              onUpdate={(newValue: string) => {
                updateContact('readSpeed', newValue);
              }}
              inputLabel="READING SPEED"
            />
            {editDisabled ? (
              <ShowItem title="Birthday" content={dateOfBirth as string} onClick={undefined} />
            ) : (
              <AddDate
                addItemTitle="Add Birthday"
                content={dateOfBirth as string}
                showItemTitle="Birthday"
                onUpdate={(newValue: string) => {
                  updateContact('dateOfBirth', newValue);
                }}
                inputLabel="BIRTHDAY"
              />
            )}
          </>
        )}

        <DetailList
          listTitle="Teams"
          members={teams}
          avatarVariant="team"
          onItemClick={undefined}
        />
        {!editDisabled && (
          <AddItem
            title={`Add ${firstName as string} to Team(s)...`}
            onClick={() => doGetMembers('team')}
          />
        )}

        <DetailList
          listTitle="Departments"
          members={departments}
          avatarVariant="department"
          onItemClick={undefined}
        />
        {!editDisabled && (
          <AddItem
            title={`Add ${firstName as string} to Department(s)...`}
            onClick={() => doGetMembers('department')}
          />
        )}
      </Scrollbar>
    </InfoWrapper>
  );
}

export default Info;
