/* eslint-disable sort-imports */
import { useCallback, useMemo, useState } from 'react';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import { ReactComponent as Add } from 'assets/icons/systemicons/add.svg';
import { IconButton } from 'components/buttons';
import {
  Alternative,
  DefaultValue,
  FieldTypeEnum,
  MdfField,
  // MdfPermission,
} from 'types/graphqlTypes';

import { EditAlternativesDialog } from './EditAlternativesDialog';
import EditTreeChoiceDialog from './EditTreeChoiceDialog';
import { FieldModelRow } from './FieldModelRow';

export const FieldHeader = styled('div')`
  ${({ theme }) => theme.typography.dina.overline}
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 1px;
  margin-bottom: 5px;
  user-select: none;
`;

export const Root = styled('div')`
  width: 100%;
`;

const CellStyle = ({ theme }: { theme: Theme }) => css`
  padding: 8px;
  height: 40px;
  border-bottom: 1px solid ${theme.palette.dina.dividerLight};
  ${theme.typography.dina.listItemLabel};
`;

export const HeaderCell = styled(TableCell)`
  ${CellStyle};
  ${({ theme }) => theme.typography.dina.overline};
  background-color: ${({ theme }) => theme.palette.dina.backgroundResting};
`;

const ButtonWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 2px;
`;

interface Props {
  fields: MdfField[];
  onShowPermissionDialog: (fieldId: string) => void;
  onUpdateField: (field: MdfField) => void;
  onDeleteField: (fieldId: string) => void;
  onShowConfig: (field: MdfField) => void;
  onShowEditFieldDialog: (field: MdfField) => void;
  setFields: (fields: MdfField[]) => void;
  onAddFieldClick: () => void;
}

export interface Column {
  id: string;
  name: string;
}

const columns: Column[] = [
  {
    id: 'type',
    name: 'Type',
  },
  {
    id: 'defaultValue',
    name: 'Default',
  },
  {
    id: 'required',
    name: 'Required',
  },
  {
    id: 'actions',
    name: 'Actions',
  },
];

export function EditFieldModel({
  fields,
  onUpdateField,
  onDeleteField,
  onShowConfig,
  onShowEditFieldDialog,
  onShowPermissionDialog,
  onAddFieldClick,
  setFields,
}: Readonly<Props>) {
  const [showOptionsDialog, setShowOptionsDialog] = useState(false);
  const [showTreeEditDialog, setShowTreeEditDialog] = useState(false);
  const [fieldToUpdate, setFieldToUpdate] = useState<MdfField | null>(null);

  const ids = useMemo(() => fields.map((f) => f.fieldId), [fields]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const doEditAlternatives = (mdfField: MdfField) => {
    setFieldToUpdate(mdfField);
    if (mdfField.type === FieldTypeEnum.treechoice) {
      setShowTreeEditDialog(true);
    } else {
      setShowOptionsDialog(true);
    }
  };

  const updateDefaultValue = useCallback(
    (value: DefaultValue, field: MdfField) => {
      if (value !== null && field.type === FieldTypeEnum.number && typeof value === 'string') {
        onUpdateField({ ...field, defaultValue: { value: +value } });
      } else {
        onUpdateField({ ...field, defaultValue: { value } });
      }
    },
    [fields, onUpdateField],
  );

  const updateType = useCallback(
    (type: FieldTypeEnum, field: MdfField) => {
      onUpdateField({ ...field, type, defaultValue: { value: null } });
    },
    [fields, onUpdateField],
  );

  const updateAlternatives = useCallback(
    (alts: Alternative[]) => {
      if (fieldToUpdate) {
        const newField: MdfField = { ...fieldToUpdate, alternatives: alts };
        onUpdateField(newField);
        setFieldToUpdate(null);
      }
    },
    [fields, fieldToUpdate, onUpdateField, setFieldToUpdate],
  );

  const updateTreeAlternatives = (treeAlts: string[][]) => {
    if (fieldToUpdate) {
      const newField: MdfField = { ...fieldToUpdate, treeAlternatives: treeAlts };
      onUpdateField(newField);
      setFieldToUpdate(null);
    }
  };

  const updateRequired = useCallback(
    (val: boolean, field: MdfField) => {
      onUpdateField({
        ...field,
        required: val,
      });
    },
    [fields],
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    const oldIndex = fields.findIndex((item) => item.fieldId === active.id);
    const newIndex = fields.findIndex((item) => item.fieldId === over?.id);
    const updatedOrder = arrayMove(fields, oldIndex, newIndex);
    setFields(updatedOrder);
  };

  return (
    <Root>
      <Table aria-label="mdf-table">
        <TableHead key="mdf-table-head">
          <TableRow key="mdf-header">
            <HeaderCell key="fieldId">
              <ButtonWrapper>
                <IconButton
                  title="Add field"
                  size={24}
                  iconSize={20}
                  usage="text"
                  onClick={onAddFieldClick}
                >
                  <Add />
                </IconButton>
                Field id
              </ButtonWrapper>
            </HeaderCell>
            {columns.map((col) => (
              <HeaderCell key={col.id}>{col.name}</HeaderCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody key="studio-table-body">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext items={ids} strategy={verticalListSortingStrategy}>
              {fields.map((field) => (
                <FieldModelRow
                  key={field.fieldId}
                  field={field}
                  onShowEditFieldDialog={onShowEditFieldDialog}
                  onShowPermissionDialog={onShowPermissionDialog}
                  onDeleteField={onDeleteField}
                  onShowConfig={onShowConfig}
                  onEditAlternatives={doEditAlternatives}
                  onUpdateType={updateType}
                  onUpdateRequired={updateRequired}
                  onUpdateDefaultValue={updateDefaultValue}
                />
              ))}
            </SortableContext>
          </DndContext>
        </TableBody>
      </Table>
      <EditAlternativesDialog
        open={showOptionsDialog}
        setOpen={setShowOptionsDialog}
        alternatives={fieldToUpdate?.alternatives ?? []}
        updateAlternatives={updateAlternatives}
      />
      <EditTreeChoiceDialog
        open={showTreeEditDialog}
        setOpen={setShowTreeEditDialog}
        treeAlternatives={fieldToUpdate?.treeAlternatives ?? []}
        updateTreeAlternatives={updateTreeAlternatives}
      />
    </Root>
  );
}
