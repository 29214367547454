import PropTypes from 'prop-types';

import useCheckUserRight from 'hooks/useCheckUserRight';
import Scrollbar from 'components/scrollbar/scrollbar';
import ListItem from 'components/ellipsisDropdown/listItem-view';

import { useSelectedMenuSystemSettings, useSelectedCategory } from '../../../../atoms';
import SETTING_TYPES from '../../../../utils/settingTypes';
import Metadata from './metadata';
import Links from './links';
import Editor from './editor/EditorMenu';
import Instances from './instances';
import Rundown from './rundown';
import Webhooks from './webhooks';
import HtmlPanels from './htmlpanels';

import { RootWrapper, BlankDiv } from './menu-styled';

const Menu = ({ categories, fields, instanceTypes, links, panels }) => {
  const [selectedMenu, setSelectedMenu] = useSelectedMenuSystemSettings();
  const [, setCategory] = useSelectedCategory();
  const [checkUserRight] = useCheckUserRight();

  const linksPanelFeatureFlag = checkUserRight('feature', 'links-panel');
  const aiFeatureFlag = checkUserRight('feature', 'ChatGPT');
  const canShowEditorCommand = checkUserRight('feature', 'mdfBlocks');

  const settingsCategories = categories.filter((category) => category.title !== 'Rundown');

  return (
    <RootWrapper>
      <Scrollbar>
        {settingsCategories.map((category) => {
          const { title } = category;
          return (
            <ListItem
              key={title}
              text={title}
              selected={selectedMenu === title}
              onClick={() => {
                setSelectedMenu(title);
                setCategory(SETTING_TYPES.GENERAL);
              }}
              excludeDivider
              firstChild={<BlankDiv $margin="26px" />}
            />
          );
        })}
        {aiFeatureFlag && (
          <ListItem
            key="ai_config"
            text="Ai prompt config"
            selected={selectedMenu === 'Ai prompt config'}
            onClick={() => {
              setSelectedMenu('Ai prompt config');
              setCategory(SETTING_TYPES.AI_CONFIG);
            }}
            excludeDivider
            firstChild={<BlankDiv $margin="26px" />}
          />
        )}
        <Rundown />
        <Metadata fields={fields} />
        <Instances instanceTypes={instanceTypes} />
        {canShowEditorCommand && <Editor />}
        <Webhooks />
        {linksPanelFeatureFlag && <Links links={links} />}
        <HtmlPanels panels={panels} />
      </Scrollbar>
    </RootWrapper>
  );
};

Menu.propTypes = {
  categories: PropTypes.array,
  fields: PropTypes.array,
  instanceTypes: PropTypes.array,
  links: PropTypes.array,
  panels: PropTypes.array,
};

Menu.defaultProps = {
  categories: [],
  fields: [],
  instanceTypes: [],
  links: [],
  panels: [],
};

export default Menu;
