import { ReactEditor } from 'slate-react';
import { Transforms } from 'slate';
import { elementTypes } from '../constants';

const { setNodes } = Transforms;

/**
 * Removes Block (video, image, gif, section etc. ) element
 *
 * @param {Object} editor SlateJS editor instance
 * @param {Object} element element block object
 * @param {Function} update Callback to be invoked on update
 * @returns {Object} SlateJS editor instance
 */

const removeBlock = (editor, element, update) => {
  const path = ReactEditor.findPath(editor, element);
  setNodes(editor, { type: elementTypes.PARAGRAPH }, { at: path });

  update({
    type: 'block-remove',
    payload: {
      document: editor.children,
      removedElement: element,
    },
  });

  return editor;
};

export default removeBlock;
