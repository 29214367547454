/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import groupBy from 'lodash/groupBy';
import useCreateConversation from 'hooks/useCreateConversation';
import useGetUser from 'hooks/useGetUser';
import { useUsers } from 'store';
import UserContext from 'contexts/UserContext';
import GET_CONVERSATIONS_OF_USER from 'graphql/queries/getConversationsOfUser';
import LoadingIndicator from 'components/loadingIndicator';
import conversationTypes from 'utils/constants/conversationTypes';
import NewMessage from './newMessage-view';
import useConversationContext from '../../hooks/useConversationContext';
import assemblePeopleData from './utils/assemblePeopleData';
import assembleGroupData from './utils/assembleGroupData';
import assembleTeamData from './utils/assembleTeamData';
import assembleDepartmentData from './utils/assembleDepartmentData';

const NewMessageContainer = ({ onOk, onClose }) => {
  const users = useUsers();
  const { getUser } = useGetUser();
  const user = useContext(UserContext);
  const [createConversation] = useCreateConversation();
  const { teams: teamsData, departments: departmentData } = useConversationContext();

  const handleCreateConversation = async (newConversationMembers) => {
    await createConversation(newConversationMembers, onOk);
  };

  const { data, error, loading } = useQuery(GET_CONVERSATIONS_OF_USER, {
    variables: {
      input: {
        mId: user.mId,
      },
    },
  });

  if (loading) return <LoadingIndicator />;
  if (error) return <div />;

  const groupedData = groupBy(data.getConversationsOfUser, 'convoType');

  const peopleData = assemblePeopleData(users, user.mId, groupedData[conversationTypes.DIRECT]);

  const groupData = assembleGroupData(groupedData[conversationTypes.GROUP], getUser);

  const contactData = [...peopleData, ...groupData];

  const teams = assembleTeamData(teamsData);

  const departments = assembleDepartmentData(departmentData);

  const rows = [contactData, teams, departments];

  return <NewMessage onClose={onClose} rows={rows} onOk={handleCreateConversation} />;
};

NewMessageContainer.propTypes = {
  /** callback to close new message dialog */
  onClose: PropTypes.func,
  /** callback of ok button press */
  onOk: PropTypes.func,
};

NewMessageContainer.defaultProps = {
  onClose: () => {},
  onOk: () => {},
};

export default NewMessageContainer;
