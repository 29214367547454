import React from 'react';
import PropTypes from 'prop-types';
import { Typography, List } from '@material-ui/core';
import Member from 'screens/main/components/rightArea/sidebar/list-view/listItem';
import alphaSort from 'utils/alphaSort';
import useStyles from './detail-list-styles';

const DetailListView = ({ listTitle, members, onItemClick, ...rest }) => {
  const classes = useStyles();
  const sortedMembers = alphaSort(members, 'mTitle');
  const membersLength = sortedMembers.length;

  return (
    membersLength > 0 && (
      <div className={classes.root}>
        <Typography classes={{ root: classes.text }}>
          {listTitle} ({membersLength})
        </Typography>

        <List>
          {sortedMembers.map((member) => {
            const { mId, mTitle, mType, mAvatarUrl, mDescription } = member;
            return (
              <div key={mId}>
                <Member
                  id={mId}
                  name={mTitle}
                  title={mType}
                  type={mType}
                  image={mAvatarUrl}
                  description={mDescription}
                  secondaryActionItem={<div />}
                  onClick={onItemClick}
                  {...rest}
                />
              </div>
            );
          })}
        </List>
      </div>
    )
  );
};

DetailListView.propTypes = {
  /** Defines the title text for team  */
  listTitle: PropTypes.string,
  /** Defines the list of members to be shown  */
  members: PropTypes.arrayOf(
    PropTypes.shape({
      mId: PropTypes.string,
      mTitle: PropTypes.string,
      mAvatarUrl: PropTypes.string,
      mCreatedAt: PropTypes.string,
      description: PropTypes.string,
      handleDrop: PropTypes.func,
    }),
  ),

  onItemClick: PropTypes.func,
};

DetailListView.defaultProps = {
  listTitle: 'Members',
  members: [],
  onItemClick: () => {},
};

export default DetailListView;
