import { useCallback, useContext, useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { ReactComponent as Copy } from 'assets/icons/systemicons/copy.svg';
import Avatar from 'components/avatar/Avatar';
import { Button, IconButton } from 'components/buttons';
import Scrollbar from 'components/scrollbar/scrollbar';
import Text from 'components/text/Text';
import UserCtx from 'contexts/UserContext';
import memberTypes from 'graphql/memberTypes';
import GET_USER from 'graphql/queries/getUser';
import useCopyText from 'hooks/useCopyText';
import useGetMembersOf from 'hooks/useGetMembersOf';
import useImageUpload, { UploadProps } from 'hooks/useImageUpload';
import useSettingsValue from 'hooks/useSettingsValue';
import { AssignedMember } from 'types';
import { GetMemberInput, MemberType } from 'types/graphqlTypes';

import {
  useChangedProfileContent,
  useChangedProfileDepartments,
  useChangedProfilePicture,
  useChangedProfileTeams,
} from '../../../../../atomsTs';

import ProfileDetails from './ProfileDetails';
import useUpdateProfile from './useUpdateProfile';

import {
  AvatarWrapper,
  Container,
  DetailsContainer,
  Header,
  IdWrapper,
  TitleWrapper,
  TwoBlockWrapper,
} from './styled';

type GetUserReturnType = {
  getMember: AssignedMember;
};

type GetUserInputType = {
  input: GetMemberInput;
};

interface ProfileProps {
  groupPolicies: MemberType[];
}

function Profile({ groupPolicies }: Readonly<ProfileProps>) {
  const { mId, groups: userGroups } = useContext(UserCtx);
  const { copyTooltip, onCopy } = useCopyText('Copy Id to the clipboard.');
  const [getSettingsValue] = useSettingsValue();
  const [changedProfileContent, setChangedProfileContent] = useChangedProfileContent();
  const [changedProfilePicture, setChangedProfilePicture] = useChangedProfilePicture();
  const [changedProfileTeams, setChangedProfileTeams] = useChangedProfileTeams();
  const [changedProfileDepts, setChangedProfileDepts] = useChangedProfileDepartments();
  const defaultReadSpeed = getSettingsValue('rundown.defaultReadSpeed') as string;

  const { data: user } = useQuery<GetUserReturnType, GetUserInputType>(GET_USER, {
    variables: { input: { mId } },
  });
  const [teams] = useGetMembersOf(mId, memberTypes.TEAM_USER) as [
    { getMembersOf: AssignedMember[] },
  ];
  const [departments] = useGetMembersOf(mId, memberTypes.DEPARTMENT_USER) as [
    { getMembersOf: AssignedMember[] },
  ];
  const { addTeamOrDept } = useUpdateProfile();

  const groups = useMemo(
    () =>
      groupPolicies.filter(
        (groupPolicy) =>
          groupPolicy?.mRefId && userGroups && userGroups.includes(groupPolicy.mRefId),
      ),
    [groupPolicies, userGroups],
  );

  const { mTitle, mAvatarUrl, mAvatarKey, mProperties, mDescription } = user?.getMember ?? {};

  const properties = useMemo(
    () => ({
      ...(mProperties ?? {}),
      ...(changedProfileContent?.mProperties ?? {}),
    }),
    [mProperties, changedProfileContent?.mProperties],
  );

  const updateContent = useCallback(
    (key: string, value: string) => {
      setChangedProfileContent(
        (prevContent) =>
          ({
            ...(prevContent ?? {}),
            mProperties: {
              ...(prevContent?.mProperties ?? mProperties ?? {}),
              [key]: value,
            },
          } as Partial<AssignedMember>),
      );
    },
    [setChangedProfileContent, mProperties],
  );

  const updateDescription = useCallback(
    (_key: string, value: string) => {
      setChangedProfileContent((prevContent) => ({
        ...(prevContent ?? {}),
        mDescription: value,
      }));
    },
    [setChangedProfileContent],
  );

  const addTeams = useCallback(() => {
    addTeamOrDept(
      'team',
      properties?.firstName,
      teams?.getMembersOf,
      setChangedProfileTeams,
      changedProfileTeams?.new,
    );
  }, [
    addTeamOrDept,
    changedProfileTeams?.new,
    properties?.firstName,
    setChangedProfileTeams,
    teams?.getMembersOf,
  ]);

  const deleteTeams = useCallback(
    (members: AssignedMember[]) => {
      setChangedProfileTeams({ old: teams?.getMembersOf, new: members });
    },
    [setChangedProfileTeams, teams?.getMembersOf],
  );

  const addDepts = useCallback(
    () =>
      addTeamOrDept(
        'department',
        properties?.firstName,
        departments?.getMembersOf,
        setChangedProfileDepts,
        changedProfileDepts?.new,
      ),
    [
      addTeamOrDept,
      changedProfileDepts?.new,
      departments?.getMembersOf,
      properties?.firstName,
      setChangedProfileDepts,
    ],
  );

  const deleteDepts = useCallback(
    (members: AssignedMember[]) => {
      setChangedProfileDepts({ old: departments?.getMembersOf, new: members });
    },
    [departments?.getMembersOf, setChangedProfileDepts],
  );

  const onImageLoad = useCallback(
    (file: File | UploadProps[]) => {
      setChangedProfilePicture({ file: file as File, key: mAvatarKey ?? '' });
    },
    [mAvatarKey, setChangedProfilePicture],
  );
  const captureImage = useImageUpload({ onImageLoad });

  return (
    <Container>
      <Header>
        <AvatarWrapper>
          <Avatar
            size={96}
            src={
              changedProfilePicture?.file
                ? URL.createObjectURL(changedProfilePicture?.file)
                : mAvatarUrl
            }
            title={mTitle}
          />
          <Button usage="story" variant="outlined" height={32} onClick={captureImage}>
            Update
          </Button>
        </AvatarWrapper>
        <TitleWrapper>
          <Text variant="h5" color="highEmphasis">
            {mTitle}
          </Text>
          <Text variant="h7" color="mediumEmphasis">
            {properties?.jobTitle}
          </Text>
          <IdWrapper>
            <Text variant="body2" color="disabled">
              {mId}
            </Text>
            <IconButton
              usage="text"
              title={copyTooltip}
              size={24}
              iconSize={18}
              onClick={() => onCopy(mId, 'Copied!')}
            >
              <Copy />
            </IconButton>
          </IdWrapper>
        </TitleWrapper>
      </Header>
      <DetailsContainer>
        <Scrollbar top={8} bottom={8}>
          <TwoBlockWrapper>
            <ProfileDetails
              variant="text"
              title="First Name"
              textValue={properties?.firstName}
              fieldName="firstName"
              onUpdate={updateContent}
              showTopBorder={false}
            />
            <ProfileDetails
              variant="text"
              title="Surname"
              textValue={properties?.surname}
              fieldName="surname"
              onUpdate={updateContent}
              showTopBorder={false}
            />
          </TwoBlockWrapper>
          <ProfileDetails
            variant="text"
            title="Description"
            textValue={changedProfileContent?.mDescription ?? mDescription}
            fieldName="mDescription"
            onUpdate={updateDescription}
          />
          <TwoBlockWrapper>
            <ProfileDetails
              variant="text"
              title="Job Title"
              textValue={properties?.jobTitle}
              fieldName="jobTitle"
              onUpdate={updateContent}
            />
            <ProfileDetails
              variant="date"
              title="Date of birth"
              dateValue={properties?.dateOfBirth as string}
              fieldName="dateOfBirth"
              onUpdate={updateContent}
            />
          </TwoBlockWrapper>
          <TwoBlockWrapper>
            <ProfileDetails
              variant="text"
              title="Email"
              textValue={properties?.email}
              fieldName="email"
              onUpdate={updateContent}
            />
            <ProfileDetails
              variant="number"
              title="Phone"
              textValue={properties?.phone}
              fieldName="phone"
              onUpdate={updateContent}
            />
          </TwoBlockWrapper>
          <ProfileDetails
            variant="number"
            title="Read Speed (wpm)"
            textValue={properties?.readSpeed ?? defaultReadSpeed}
            fieldName="readSpeed"
            onUpdate={updateContent}
          />
          <ProfileDetails variant="chip" title="Groups" members={groups} />
          <ProfileDetails
            variant="chip"
            title="Teams"
            members={changedProfileTeams?.new ?? teams?.getMembersOf ?? []}
            avatarVariant="team"
            onDelete={deleteTeams}
            onAdd={addTeams}
          />
          <ProfileDetails
            variant="chip"
            title="Departments"
            members={changedProfileDepts?.new ?? departments?.getMembersOf ?? []}
            avatarVariant="department"
            onDelete={deleteDepts}
            onAdd={addDepts}
          />
        </Scrollbar>
      </DetailsContainer>
    </Container>
  );
}

export default Profile;
