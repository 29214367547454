/* eslint-disable sort-imports */
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { CSVLink as CsvLink } from 'react-csv';
import { capitalize, uniq, uniqBy } from 'lodash';

import { useGetOrderForms } from 'api/order_forms/useGetOrderForms';
import { ReactComponent as SidebarActive } from 'assets/icons/systemicons/sidebar_active.svg';
import { ReactComponent as SidebarInactive } from 'assets/icons/systemicons/sidebar_inactive.svg';
import AddMemberDropdown from 'components/addMember';
import { IconButton } from 'components/buttons';
import Checkbox from 'components/checkbox';
import { DateRange, useDatePicker } from 'components/mdfEditor/fields/date/DatePicker';
import { CloseIcon } from 'components/orderFormDialog/styled';
import Popper from 'components/shared/popper';
import Text from 'components/text/Text';
import Tooltip from 'components/tooltip';
import useCheckUserRight from 'hooks/useCheckUserRight';
import useGetPlatforms from 'hooks/useGetPlatforms';
import useGetStatuses from 'hooks/useGetStatuses';
import { HStack, VStack } from 'layouts/box/Box';
import { AssignedMember } from 'types';
import { Metadata } from 'types/forms/forms';
import { Alternative, Mdf, MemberType, RangeBy, SearchItemTypeEnum } from 'types/graphqlTypes';
import { OrderFormMemberType } from 'types/memberTypes/order_form';

import SearchChips from '../chips/SearchChips';
import { mTypeToLabel, rundownStatusLabels, ToolbarIcons } from '../command-constants';
import { CommandToolbarProps, CSVDetails, PopOverContents } from '../command-types';
import { generateCSVFromMembers, getDateRange, isFiltering } from '../command-utils';

import {
  ClearAll,
  ColumnHeader,
  FirstRow,
  MenuOption,
  StyledCaret,
  StyledFormControl,
  StyledRightSide,
  StyledText,
  ToolbarItem,
  Wrapper,
} from './styled';

const defaultFilterableMTypes: SearchItemTypeEnum[] = [
  SearchItemTypeEnum.story,
  SearchItemTypeEnum.rundown,
  SearchItemTypeEnum.instance,
  SearchItemTypeEnum.space,
  SearchItemTypeEnum.pitch,
  SearchItemTypeEnum.asset,
  SearchItemTypeEnum.order,
  SearchItemTypeEnum.contact,
  SearchItemTypeEnum.note,
  SearchItemTypeEnum.user,
];

export const toolbarFilterDefaults: Omit<CommandToolbarProps, 'toolbarHeight'> = {
  sortBy: 'best',
  order: 'desc',
  mTypes: [],
  rangeBy: null,
  mdfId: null,
  defaultMdfId: null, // reserved for the default built in mdf ids
  statusFilter: [],
  assignedIds: [],
  createdByIds: [],
  platformTypes: [],
  isFiltering: false,
  semanticSearch: false,
};

const rundownStatuses = ['ready', 'active', 'notReady'];

type FilterKeys = Pick<CommandToolbarProps, 'assignedIds' | 'createdByIds'>;

const getAllStatusOptions = (
  forms: OrderFormMemberType[],
  selectedMdfId: string | undefined,
): Alternative[] => {
  let opt: Alternative[] = [];

  if (selectedMdfId) {
    for (const orderForm of forms) {
      if (orderForm.mSecId === selectedMdfId) {
        const config = orderForm.configs.find((c) => c.key === 'statuses');
        return [...(config?.alternatives ?? [])];
      }
    }
  } else {
    for (const orderForm of forms) {
      const config = orderForm.configs.find((c) => c.key === 'statuses');
      opt = [...opt, ...(config?.alternatives ?? [])];
    }
  }
  return uniqBy(opt, (o) => o.value);
};

export function CommandToolbar({
  toolbarState,
  setToolbarState,
  toolbarHeight,
  setToolbarHeight,
  allMembersKeyed,
  toggleSidePanel,
  resetAll,
  clearFieldValue,
  showSidePanel,
  metadataFilter,
  items,
  mdfs,
  mdfId,
  hideDateFilter,
  hideStatusFilter,
  filterableMTypes = defaultFilterableMTypes,
  disableSidePanel = false,
}: Readonly<{
  toolbarState: CommandToolbarProps;
  setToolbarState: React.Dispatch<React.SetStateAction<CommandToolbarProps>>;
  setToolbarHeight: React.Dispatch<React.SetStateAction<number>>;
  metadataFilter: Metadata;
  toolbarHeight: number;
  showSidePanel: boolean;
  toggleSidePanel: () => void;
  clearFieldValue: (fieldId: string) => void;
  allMembersKeyed: Record<string, AssignedMember>;
  items: MemberType[];
  mdfs: Mdf[];
  mdfId: string | undefined;
  resetAll: () => void;
  hideDateFilter?: boolean;
  hideStatusFilter?: boolean;
  filterableMTypes?: SearchItemTypeEnum[];
  disableSidePanel?: boolean;
}>) {
  const [platforms] = useGetPlatforms(new Date());
  const [checkUserRight] = useCheckUserRight();
  const showSemanticSearch = checkUserRight('feature', 'semanticSearch');
  const { orderForms } = useGetOrderForms();
  const { instanceStatuses } = useGetStatuses();
  const [, openPicker] = useDatePicker();
  const [isFinding, setIsFinding] = useState(false);
  const [filterKey, setFilterKey] = useState<keyof FilterKeys>('assignedIds');
  const [sortEl, setSortEl] = useState<HTMLDivElement | null>(null);
  const [popOverContents, setPopOverContents] = useState<PopOverContents>('sort');
  const [csvData, setCsvData] = useState<CSVDetails | null>(null);

  const showTaskStatuses = toolbarState.mTypes.includes(SearchItemTypeEnum.order);

  const allTaskStatuses = useMemo(() => {
    return getAllStatusOptions(orderForms, mdfId);
  }, [orderForms, mdfId]);

  const filterableTypes = useMemo(() => {
    const fTypes = [];
    if (Array.isArray(platforms)) {
      for (const platform of platforms) {
        if (platform.mProperties.platformKind === 'audio') {
          fTypes.push('audio');
        } else {
          fTypes.push(platform.mProperties.platform);
        }
      }
    }
    return fTypes;
  }, [platforms]);

  const updateState = useCallback(
    (val: Partial<CommandToolbarProps>) => {
      setToolbarState((prevState) => {
        const newValue = {
          ...prevState,
          ...val,
        };
        return {
          ...newValue,
          isFiltering: isFiltering(newValue),
        };
      });
    },
    [setToolbarState],
  );

  const toggleMState = useCallback(
    (instanceMState: string, type: SearchItemTypeEnum) => {
      let copy = [...toolbarState.statusFilter];
      if (copy.includes(instanceMState)) {
        copy = [...toolbarState.statusFilter.filter((s) => s !== instanceMState)];
      } else {
        copy.push(instanceMState);
      }
      updateState({ statusFilter: copy, mTypes: uniq([...toolbarState.mTypes, type]) });
    },
    [updateState, toolbarState.statusFilter, toolbarState.mTypes],
  );

  const toggleMType = useCallback(
    (mType: SearchItemTypeEnum) => {
      let copy = [...toolbarState.mTypes];
      if (copy.includes(mType)) {
        copy = [...toolbarState.mTypes.filter((s) => s !== mType)];
      } else {
        copy.push(mType);
      }

      const shouldEmptyPlatform =
        !copy.includes(SearchItemTypeEnum.instance) &&
        (toolbarState.platformTypes ?? []).length > 0;

      updateState({ mTypes: copy, ...(shouldEmptyPlatform && { platformTypes: [] }) });
    },
    [updateState, toolbarState.mTypes, toolbarState.platformTypes],
  );

  const toggleSemanticSearch = useCallback(() => {
    updateState({ semanticSearch: !toolbarState.semanticSearch });
  }, [updateState, toolbarState.semanticSearch]);

  const showPopover = useCallback(
    (ev: MouseEvent, type: PopOverContents) => {
      setPopOverContents(type);
      if (sortEl) {
        setSortEl(null);
      } else if (ev.currentTarget) {
        setSortEl(ev.currentTarget as HTMLDivElement);
      }
    },
    [setSortEl, sortEl],
  );

  const selectDate = useCallback(
    (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const dateRange = getDateRange(toolbarState.rangeBy);

      openPicker({
        anchorEl: ev.currentTarget,
        startValue: dateRange
          ? { startDate: dateRange.dateRange.from, endDate: dateRange.dateRange.to }
          : undefined,
        selectRange: true,
        dateType: dateRange?.key ?? 'createdAt',
        showTypeSelect: true,
        selectDate: (val: DateRange, key?: keyof RangeBy) => {
          const prop = key ? key : 'createdAt';
          updateState({
            rangeBy: {
              [prop]: {
                from: val.startDate ?? new Date().toISOString(),
                to: val.endDate ?? new Date().toISOString(),
              },
            },
            sortBy: key,
          });
        },
      });
    },
    [updateState, openPicker, toolbarState.rangeBy],
  );

  const onSelectAssignee = useCallback(
    (newAssignee: AssignedMember[]) => {
      setIsFinding(false);
      const newId: string | undefined = newAssignee?.[0]?.mId;
      if (newId) {
        let newIds: string[] = [];
        if (toolbarState[filterKey].includes(newId)) {
          newIds = toolbarState[filterKey].filter((s) => s !== newId);
        } else {
          newIds = [...toolbarState[filterKey], newId];
        }
        updateState({ [filterKey]: newIds });
      }
    },
    [updateState, setIsFinding, toolbarState, filterKey],
  );

  const findMember = useCallback(
    (key: keyof FilterKeys) => {
      setFilterKey(key);
      setIsFinding(true);
    },
    [setFilterKey, setIsFinding],
  );

  const toggleInstanceType = useCallback(
    (type: string) => {
      let copy = [...(toolbarState.platformTypes ?? [])];
      if (copy.includes(type)) {
        copy = [...copy.filter((s) => s !== type)];
      } else {
        copy.push(type);
      }

      const shouldSetInstance =
        copy.length > 0 && !toolbarState.mTypes.includes(SearchItemTypeEnum.instance);

      updateState({
        platformTypes: copy,
        ...(shouldSetInstance && { mTypes: [SearchItemTypeEnum.instance] }),
      });
    },
    [updateState, toolbarState.platformTypes, toolbarState.mTypes],
  );

  /**
   * Anything that might affect the height of the toolbar should go into this dependency array.
   */
  useLayoutEffect(() => {
    const ele = document.getElementById('toolbar-wrapper');
    const currentHeight = ele?.getBoundingClientRect()?.height ?? 30;
    if (toolbarHeight !== currentHeight) {
      setToolbarHeight(currentHeight);
    }
  }, [
    toolbarHeight,
    toolbarState.assignedIds,
    toolbarState.createdByIds,
    toolbarState.rangeBy,
    toolbarState.statusFilter,
    metadataFilter,
    setToolbarHeight,
  ]);

  const instanceIndeterminate =
    toolbarState.mTypes.includes(SearchItemTypeEnum.instance) &&
    (toolbarState.platformTypes ?? []).length > 0;

  return (
    <Wrapper id="toolbar-wrapper" width="100%">
      <FirstRow gap="8px">
        {isFinding ? (
          <AddMemberDropdown
            autoFocus
            variant={filterKey === 'createdByIds' ? 'user' : 'all'}
            showChips={false}
            selectedMembers={[]}
            setSelectedMembers={onSelectAssignee}
            onClose={() => setIsFinding(false)}
          />
        ) : (
          <>
            <HStack justifyContent="start" gap="6px">
              <IconButton
                size={24}
                iconHeight={20}
                onFocus={(ev: React.FocusEvent<HTMLDivElement>) => {
                  if (ev.target) ev.target.blur();
                }} // Hack for stuck tooltip
                onClick={() => toggleSidePanel()}
                title="Toggle metadata sidebar"
                usage={showSidePanel ? 'story' : 'text'}
                disabled={disableSidePanel}
              >
                {showSidePanel ? <SidebarActive /> : <SidebarInactive />}
              </IconButton>
              <Tooltip title="Reset search filters">
                <ClearAll onClick={resetAll} />
              </Tooltip>
              <Tooltip title="Filter by type">
                <StyledText
                  variant="caption"
                  onClick={(ev: MouseEvent) => showPopover(ev, 'types')}
                >
                  <ToolbarIcons.Status />
                  Type
                  <StyledCaret />
                </StyledText>
              </Tooltip>
              {!hideDateFilter && (
                <Tooltip title={!toolbarState.rangeBy ? 'Search by date' : ''}>
                  <StyledText variant="caption" onClick={selectDate}>
                    <ToolbarIcons.Date />
                    Date
                    <StyledCaret />
                  </StyledText>
                </Tooltip>
              )}
              {!hideStatusFilter && (
                <Tooltip title={toolbarState.statusFilter.length > 0 ? '' : 'Filter by status'}>
                  <StyledText
                    variant="caption"
                    onClick={(ev: MouseEvent) => showPopover(ev, 'statusFilter')}
                  >
                    <ToolbarIcons.Status />
                    Status
                    <StyledCaret />
                  </StyledText>
                </Tooltip>
              )}
              <Tooltip title="Filter by assignees">
                <StyledText variant="caption" onClick={() => findMember('assignedIds')}>
                  <ToolbarIcons.Assignees />
                  Assignee
                  <StyledCaret />
                </StyledText>
              </Tooltip>
              <Tooltip title="Filter by created">
                <StyledText variant="caption" onClick={() => findMember('createdByIds')}>
                  <ToolbarIcons.Assignees />
                  Created by
                  <StyledCaret />
                </StyledText>
              </Tooltip>
            </HStack>
            <StyledRightSide>
              {showSemanticSearch && (
                <Tooltip title="Semantic search">
                  <StyledFormControl
                    control={
                      <Checkbox
                        onClick={() => toggleSemanticSearch()}
                        selected={toolbarState.semanticSearch}
                      />
                    }
                    label={''}
                  />
                </Tooltip>
              )}

              <IconButton
                onClick={(ev: MouseEvent) => showPopover(ev, 'moreOptions')}
                variant="discreet"
                usage="text"
                noBorder
                iconHeight={16}
                iconWidth={4}
                size={24}
                round
                title="More options"
              >
                <ToolbarIcons.MoreOptions />
              </IconButton>
            </StyledRightSide>
          </>
        )}
      </FirstRow>
      <SearchChips
        toolbarState={toolbarState}
        setToolbarState={setToolbarState}
        clearFieldValue={clearFieldValue}
        allMembersKeyed={allMembersKeyed}
        metadataFilter={metadataFilter}
        mdfs={mdfs}
        mdfId={mdfId}
      />
      <Popper anchorEl={sortEl} position="bottom" style={{ pointerEvents: 'auto' }}>
        {popOverContents === 'statusFilter' && !showTaskStatuses && (
          <ToolbarItem>
            <VStack alignItems="start">
              <ColumnHeader variant="overline">Instance status</ColumnHeader>
              {instanceStatuses.map((instance) => (
                <div key={instance.id}>
                  <StyledFormControl
                    control={
                      <Checkbox
                        onClick={() => toggleMState(instance.id, SearchItemTypeEnum.instance)}
                        selected={toolbarState.statusFilter.includes(instance.id)}
                      />
                    }
                    label={capitalize(instance.name)}
                  />
                </div>
              ))}
            </VStack>
            <VStack alignItems="start">
              <ColumnHeader variant="caption">Rundown status</ColumnHeader>
              {rundownStatuses.map((rundown) => (
                <div key={rundown}>
                  <StyledFormControl
                    control={
                      <Checkbox
                        onClick={() => toggleMState(rundown, SearchItemTypeEnum.rundown)}
                        selected={toolbarState.statusFilter.includes(rundown)}
                      />
                    }
                    label={rundownStatusLabels[rundown]}
                  />
                </div>
              ))}
            </VStack>
            <CloseIcon onClick={() => setSortEl(null)} />
          </ToolbarItem>
        )}

        {popOverContents === 'statusFilter' && showTaskStatuses && (
          <ToolbarItem>
            <VStack alignItems="start">
              <ColumnHeader variant="overline">Available order statuses</ColumnHeader>
              {allTaskStatuses.map((alt) => (
                <div key={alt.id}>
                  <StyledFormControl
                    control={
                      <Checkbox
                        onClick={() => toggleMState(alt.value, SearchItemTypeEnum.order)}
                        selected={toolbarState.statusFilter.includes(alt.value)}
                      />
                    }
                    label={alt.label}
                  />
                </div>
              ))}
            </VStack>
            <CloseIcon onClick={() => setSortEl(null)} />
          </ToolbarItem>
        )}
        {popOverContents === 'types' && (
          <ToolbarItem>
            <VStack alignItems="start">
              <ColumnHeader variant="overline">Types</ColumnHeader>
              <HStack alignItems="start">
                <VStack alignItems="start">
                  {filterableMTypes.map((type) => {
                    return (
                      <div key={type}>
                        <StyledFormControl
                          control={
                            <Checkbox
                              indeterminate={
                                type === SearchItemTypeEnum.instance && instanceIndeterminate
                              }
                              onClick={() => toggleMType(type)}
                              selected={toolbarState.mTypes.includes(type)}
                            />
                          }
                          label={mTypeToLabel[type]}
                        />
                      </div>
                    );
                  })}
                </VStack>
                <VStack alignItems="start">
                  {filterableTypes.map((type: string) => {
                    return (
                      <div key={type}>
                        <StyledFormControl
                          key={type}
                          control={
                            <Checkbox
                              onClick={() => toggleInstanceType(type)}
                              selected={(toolbarState.platformTypes ?? []).includes(type)}
                            />
                          }
                          label={capitalize(type)}
                        />
                      </div>
                    );
                  })}
                </VStack>
              </HStack>
            </VStack>
            <CloseIcon onClick={() => setSortEl(null)} />
          </ToolbarItem>
        )}
        {popOverContents === 'moreOptions' && (
          <ToolbarItem>
            <MenuOption gap="8px">
              <CsvLink
                asyncOnClick={true}
                data={csvData?.data ?? []}
                headers={csvData?.headers ?? []}
                filename={`Dina-export-${new Date().toISOString()}.csv`}
                onClick={(_, done) => {
                  setCsvData(generateCSVFromMembers(items, mdfs));
                  done(true);
                }}
              >
                <ToolbarIcons.Csv />
                <Text variant="listItemLabel">Export to CSV</Text>
              </CsvLink>
            </MenuOption>
            <CloseIcon onClick={() => setSortEl(null)} />
          </ToolbarItem>
        )}
      </Popper>
    </Wrapper>
  );
}
