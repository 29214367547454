import { useCallback, useEffect, useMemo, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { keyBy } from 'lodash';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import { useUpdateMdf } from 'api/mdf/useUpdateMdf';
import { ReactComponent as ArrowDown } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_down.svg';
import { ReactComponent as Edit } from 'assets/icons/systemicons/edit.svg';
import { IconButton } from 'components/buttons';
import Text from 'components/text/Text';
import { useEditorCommands } from 'store';
import { useEditMdfDialog } from 'store/mdf-atoms';
import { Mdf, MemberTypeEnum } from 'types/graphqlTypes';

import { StyledPopper, StyledTextField } from './fields/choice/styled';

import { MdfDropdownWrapper, SelectWrapper } from './styled';

export interface EditorProps {
  value: string | null;
  systemOnly?: boolean;
  onSelect?: (selected: string | null) => void;
  onSelectMdf?: (selected: Mdf | null) => void;
  style?: React.CSSProperties;
  showEditIcon?: boolean;
  error?: boolean;
  disableClearable?: boolean;
  hideLabel?: boolean;
  placeholder?: string;
}

export function MdfDropdown({
  value,
  onSelect,
  onSelectMdf,
  style,
  showEditIcon,
  error,
  disableClearable,
  placeholder,
  hideLabel,
  systemOnly,
}: Readonly<EditorProps>) {
  const [, setEditMdf] = useEditMdfDialog();
  const [selectedValue, setSelectedValue] = useState<Mdf | null>(null);
  const { mdfs, mdfsByMType } = useGetMdfs({ all: true });
  const { updateMdf } = useUpdateMdf();
  const [editorCommands] = useEditorCommands();

  const availableMdfs = useMemo(() => {
    if (systemOnly) {
      const systemMdfs = Object.values(mdfsByMType).filter((val) => val !== undefined) as Mdf[];
      const blockMdfs: Mdf[] = [];
      const keyedByMdfId = keyBy(editorCommands, (cmd) => cmd.mSecId);
      const mdfsKeyed = keyBy(mdfs, (mdf) => mdf.id);
      for (const [mdfId, command] of Object.entries(keyedByMdfId)) {
        if (command.mActive && command.mResourceType === MemberTypeEnum.Mdf) {
          blockMdfs.push(mdfsKeyed[mdfId]);
        }
      }
      return [...systemMdfs, ...blockMdfs];
    }
    return mdfs;
  }, [systemOnly, mdfs, mdfsByMType, editorCommands]);

  useEffect(() => {
    if (value !== selectedValue?.id) {
      const mdf = mdfs.find((m) => m.id === value);
      if (mdf) {
        setSelectedValue(mdf);
      } else {
        setSelectedValue(null);
      }
    }
  }, [value, mdfs, selectedValue]);

  const doEditMdf = useCallback(() => {
    setEditMdf({
      mdf: selectedValue,
      open: true,
      onOk: async (updatedMdf) => {
        await updateMdf(updatedMdf);
      },
    });
  }, [selectedValue, mdfs, setEditMdf]);

  const renderInput = (params: object) => (
    <StyledTextField
      {...params}
      variant="filled"
      error={error}
      placeholder={placeholder ?? 'Select schema'}
    />
  );

  return (
    <MdfDropdownWrapper style={style}>
      {!hideLabel && <Text variant="overline">Select a schema</Text>}
      <SelectWrapper>
        <Autocomplete
          openOnFocus
          fullWidth
          blurOnSelect
          disableClearable={disableClearable}
          noOptionsText="No schemas available"
          selectOnFocus={false}
          options={availableMdfs}
          value={selectedValue}
          getOptionSelected={(mdf: Mdf, v: Mdf) => {
            return mdf.id === v.id;
          }}
          onChange={(_ev, mdf) => {
            if (onSelect) {
              onSelect(mdf?.id ?? null);
            }
            if (onSelectMdf) {
              onSelectMdf(mdf);
            }
          }}
          renderInput={renderInput}
          PopperComponent={StyledPopper}
          getOptionLabel={(mdf) => mdf?.label}
          popupIcon={<ArrowDown />}
        />
        {showEditIcon && (
          <IconButton usage="text" size={24} onClick={doEditMdf} title="Edit schema directly">
            <Edit />
          </IconButton>
        )}
      </SelectWrapper>
    </MdfDropdownWrapper>
  );
}
