import rundownItemTypes, { primaryTypes, secondaryTypes } from 'utils/rundownItemTypes';

export const boxTypes = {
  ABSTRACT: 'abstract',
  AUTHOR: 'author',
  CHECK_IN: 'check-in',
  DESCRIPTION: 'description',
  EMBEDDED_TWEET: 'embedded-tweet',
  EMBEDDED_VIDEO: 'embedded-video',
  FACT: 'fact',
  FEED: 'feed',
  GIF: 'gif',
  HTML: 'html',
  IMAGE: 'image',
  LIVE_STREAM: 'live-stream',
  LOCATION: 'location',
  MEDIA: 'media',
  NOTIFICATION: 'notification',
  OVERLINE: 'overline',
  PHOTO_GALLERY: 'photo-gallery',
  PLACEHOLDER: 'placeholder',
  PRIVACY: 'privacy',
  QUOTE_BOX: 'quote',
  SECTION: 'section',
  SOURCE: 'source',
  TAGS: 'tags',
  TITLE: 'title',
  VIDEO: 'video',
  LANGUAGE: 'language',
  URL: 'url',
  SCRIPT_INFO: 'script-info',
  PROGRAMS: 'programs',
  MDF_BLOCK: 'mdf-block',
  ORDER_BLOCK: 'order-block',
};

const voidTypes = {
  ...boxTypes,
  ...rundownItemTypes,
  AUDIO: 'audio',
  HORIZONTAL_RULE: 'horizontal-rule',
  INFO: 'info',
  MENTION: 'mention',
  NOTES: 'notes',
  primaryTypes,
  secondaryTypes,
};

export default voidTypes;
