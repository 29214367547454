import React, { memo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSlate } from 'slate-react';
import { ReactComponent as CheckboxSelected } from 'assets/icons/systemicons/editor/CheckboxSelected.svg';
import useGetSummary from 'hooks/useGetSummary';
import { ReactComponent as Summarize } from 'assets/icons/systemicons/summarize.svg';
import TextArea from 'components/textArea';
import { ReactComponent as PlusIcon } from 'assets/icons/systemicons/add_small.svg';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import MetadataEditor from 'components/metadataEditor';
import { elementTypes } from 'components/editor/constants/types';
import { refreshSelection } from 'components/editor/utils';
import variants from 'utils/instance/variants';
import useChangeCollapse from 'components/editor/hooks/useChangeCollapse';
import updateBlock from 'components/editor/utils/updateBlock';
import SelectedElement from 'components/editor/components/selectedElement';
import stopEventPropagation from 'utils/stopEventPropagation';
import {
  ButtonWrapper,
  CheckboxWrapper,
  DescriptionIcon,
  SubDescription,
  TextAreaWrapper,
  PlusButton,
} from './styled';
import Box from '../box';
import insertTweetThread from './utils/insertTweetThread';

const menuItems = [
  {
    title: 'Summarize text',
    action: 'summarize',
    icon: <Summarize />,
  },
];

const YOUTUBE_CHAR_LIMIT = 5000;
const TWITTER_CHAR_LIMIT = 280;

const getAssistiveTextFor = (variant, text) => {
  if (variant === variants.TWITTER) return `${text.length} of ${TWITTER_CHAR_LIMIT}`;
  if (variant === variants.YOUTUBE)
    return `Max. ${YOUTUBE_CHAR_LIMIT} characters. First 150 characters are always shown.`;
  return '';
};

const splitAtIndex = (value, index) => [value.substring(0, index), value.substring(index)];

const Description = ({ attributes, children, element, direction }) => {
  const { data, type } = element;
  const { content, metadata, collapsed = false } = data || {};
  const initialValue = content || '';
  const editor = useSlate();
  const { update, variant, formsForThisPlatform, platformId } = useEditorContext();
  const [summarize] = useGetSummary();
  const [localValue, setLocalValue] = useState(initialValue);
  const isExceeded = localValue.length > TWITTER_CHAR_LIMIT;

  const onBlurComponent = useCallback(() => {
    refreshSelection(editor, element);
  }, [editor, element]);

  const [onChangeCollapse] = useChangeCollapse(element);

  const updateDescription = useCallback(
    (newData) => {
      const updatedData = {
        ...data,
        content: newData,
      };
      updateBlock(editor, element, updatedData, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, element],
  );

  const updateMetadata = useCallback(
    (newValue, metaPropName) => {
      const updatedMetadata = metadata;
      const updatedData = {
        ...data,
        metadata: {
          ...updatedMetadata,
          [metaPropName]: newValue,
        },
      };

      updateBlock(editor, element, updatedData, update);
    },
    [data, editor, element, metadata, update],
  );

  const onKeyDown = useCallback(
    (event) => {
      const { key } = event;

      if (key === 'Enter') {
        event.preventDefault();
        if (event.ctrlKey || event.metaKey || event.shiftKey) {
          const el = document.activeElement;
          const { selectionStart, selectionEnd, value } = el;
          const preSubString = value.substring(0, selectionStart);
          const postSubString = value.substring(selectionEnd, value.length);
          const newValue = `${preSubString}\n${postSubString}`;
          el.value = newValue;
          el.selectionStart = selectionStart + 1;
          el.selectionEnd = selectionStart + 1;
          setLocalValue(newValue);
        } else {
          updateDescription(localValue);
        }
      }
      if (key === 'Tab') {
        updateDescription(localValue);
      }
      if (key === 'Escape') setLocalValue(initialValue);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialValue, localValue],
  );

  const onChange = useCallback(
    (val) => {
      if (variant === variants.YOUTUBE && val.length <= YOUTUBE_CHAR_LIMIT) setLocalValue(val);
      else setLocalValue(val);
    },
    [variant],
  );

  const onBlur = useCallback(
    (event) => {
      event.preventDefault();
      updateDescription(localValue);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [localValue],
  );

  const addNewThread = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      const [lValue, rValue] = splitAtIndex(localValue, 240);
      setLocalValue(lValue);
      updateDescription(lValue);
      insertTweetThread({ editor, update, data: { ...data, content: rValue || '' } });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, localValue],
  );

  const onMenuSelect = useCallback(
    ({ action }) => {
      if (action === 'summarize') {
        summarize(localValue).then(async (summarizedValue) => {
          if (summarizedValue) {
            setLocalValue(summarizedValue);
            updateDescription(summarizedValue);
          }
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [localValue],
  );

  const isTwitterInstance = variant === variants.TWITTER;
  const blockForm = formsForThisPlatform?.[type];

  return (
    <div {...attributes} onBlur={onBlurComponent}>
      {children}
      <SelectedElement element={element}>
        <Box
          title="Description"
          boxType={type}
          platformId={platformId}
          element={element}
          menuItems={menuItems}
          onMenuSelect={onMenuSelect}
          updateCollapsed={onChangeCollapse}
          collapsed={collapsed}
          collapsedContent={localValue}
          hideEllipsisButton={variant === variants.YOUTUBE}
          iconComponent={<DescriptionIcon className="skipOverride" />}
          direction={direction}
        >
          <TextAreaWrapper isTwitter={isTwitterInstance}>
            <TextArea
              severity="regular"
              type="SoMe"
              rows={4}
              onMouseDown={stopEventPropagation}
              description={getAssistiveTextFor(variant, localValue)}
              subDescription={
                <SubDescription>
                  {isTwitterInstance && isExceeded
                    ? ` (${TWITTER_CHAR_LIMIT - localValue.length})`
                    : ''}
                </SubDescription>
              }
              value={localValue}
              onChange={onChange}
              onBlur={onBlur}
              onKeyDown={onKeyDown}
            />
          </TextAreaWrapper>
          {isTwitterInstance && (
            <ButtonWrapper>
              <CheckboxWrapper isExceeded={isExceeded}>
                <p>Move overshooting words to new thread</p>
                <CheckboxSelected />
              </CheckboxWrapper>
              <PlusButton
                disabled={!isExceeded}
                onClick={addNewThread}
                title="Create a new Thread item"
              >
                <PlusIcon />
              </PlusButton>
            </ButtonWrapper>
          )}
          {blockForm && (
            <MetadataEditor
              model={blockForm}
              setPayload={(u) => updateMetadata(u.value, u.fieldId)}
              payload={metadata}
            />
          )}
        </Box>
      </SelectedElement>
    </div>
  );
};

Description.propTypes = {
  /**
   * Attributes of SlateJS children
   */
  attributes: PropTypes.shape({}),
  /**
   * SlateJS children
   */
  children: PropTypes.node,
  /**
   * SlateJS element
   */
  element: PropTypes.shape({}),
};

Description.defaultProps = {
  attributes: {},
  children: null,
  element: {
    children: [],
    data: { content: '' },
    type: elementTypes.DESCRIPTION,
  },
};

export default memo(Description);
