/* eslint-disable import/no-extraneous-dependencies */
/**
 *  Hook for getting owners data
 */

import { useCallback, useMemo } from 'react';
import intersectionBy from 'lodash/intersectionBy';

import memberTypes from 'graphql/memberTypes';
import { useAllMembers } from 'store';
import { AssignedMember } from 'types';
import { MemberTypeEnum } from 'types/graphqlTypes';

const useGetAssignedMembers = (mAssignedMembers: AssignedMember[]) => {
  const [allMembers] = useAllMembers();
  const assignedMembers = useMemo(
    () => (Array.isArray(mAssignedMembers) ? mAssignedMembers : []),
    [mAssignedMembers],
  );

  const getAssignedMembers = useCallback(() => {
    const usersData = assignedMembers.filter(
      (m) => m.mType === (memberTypes.USER as MemberTypeEnum),
    );
    const teamsData = assignedMembers.filter(
      (m) => m.mType === (memberTypes.TEAM as MemberTypeEnum),
    );
    const departmentsData = assignedMembers.filter(
      (m) => m.mType === (memberTypes.DEPARTMENT as MemberTypeEnum),
    );
    const marketsData = assignedMembers.filter(
      (m) => m.mType === (memberTypes.MARKET as MemberTypeEnum),
    );
    const contactsData = assignedMembers.filter(
      (m) => m.mType === (memberTypes.CONTACT as MemberTypeEnum),
    );

    const assignedUsers = intersectionBy(allMembers, usersData, 'mId');
    const assignedTeams = intersectionBy(allMembers, teamsData, 'mId');
    const assignedDepartments = intersectionBy(allMembers, departmentsData, 'mId');
    const assignedMarkets = intersectionBy(allMembers, marketsData, 'mId');
    const assignedContacts = intersectionBy(allMembers, contactsData, 'mId');

    return [assignedUsers, assignedTeams, assignedDepartments, assignedMarkets, assignedContacts];
  }, [allMembers, assignedMembers]);

  return [getAssignedMembers];
};

export default useGetAssignedMembers;
