import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useStudios } from 'store';
import NOTIFY_MOSGATEWAY_UPDATE_SUBSCRIPTION from 'graphql/subscriptions/notifyMosGatewayUpdate';
import useApolloSubscription from 'hooks/useApolloSubscription';
import GET_STUDIOS from 'graphql/queries/getStudios';
import { updateStudiosCache } from './useDeleteStudio';
import useLogger from '../utils/useLogger';

const configId = 'mosgatewayClient';

const useLoadStudios = () => {
  const logger = useLogger('useLoadStudios');
  const [, setStudios] = useStudios();

  const { data } = useQuery(GET_STUDIOS, {
    variables: {
      input: {
        mId: configId,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const [subscribe, unSubscribe] = useApolloSubscription(NOTIFY_MOSGATEWAY_UPDATE_SUBSCRIPTION, {
    variables: {
      mIdSubscribed: configId,
    },
    onSubscriptionData: ({ client, subscriptionData }) => {
      const { notifyMemberUpdateSubscription: updatedStudio } = subscriptionData?.data;
      if (!updatedStudio || updatedStudio.crudAction !== 'REMOVE') return;

      updateStudiosCache(configId, client, updatedStudio, logger.log);
    },
    source: 'useLoadStudios',
  });

  useEffect(() => {
    subscribe();
    return () => {
      unSubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setStudios(data?.getMemberFromId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
};

export default useLoadStudios;
