/* eslint-disable no-console */
import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';

import { MemberType, MemberTypeEnum } from 'types/graphqlTypes';
import useLogger from 'utils/useLogger';

import { CreateBlock } from './types';
import { BLOCK_PROPS, memberToBlock } from './utils';

const CREATE_BLOCK = gql`
  mutation CreateBlock($input: CreateMemberInput) {
    createMember(input: $input) {
      ${BLOCK_PROPS}
    }
  }
`;

interface CreateBlockResult {
  createMember: MemberType;
}

const getErrorMessage = <E, I>(err: E, input: I): string =>
  `Could not create block: ${err instanceof Error ? err.message : ''} - input: ${JSON.stringify(
    input,
  )}`;

export const useCreateBlock = () => {
  const [createMutation] = useMutation<CreateBlockResult>(CREATE_BLOCK);
  const logger = useLogger('CreateBlock');
  const createBlock = useCallback(
    async (input: CreateBlock) => {
      try {
        const result = await createMutation({
          variables: {
            input: {
              ...input,
              mType: MemberTypeEnum.Block,
            },
          },
        });
        return memberToBlock(result?.data?.createMember as MemberType);
      } catch (err) {
        logger.log(getErrorMessage(err, input));
      }
    },
    [createMutation],
  );
  return { createBlock };
};
