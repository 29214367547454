import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import isNull from 'lodash/isNull';

import initialValues from 'components/editor/constants/initialValues';
import { ActionTypesEnum } from 'components/editor/constants/types/actionTypes';
import variants from 'components/editor/constants/types/editorVariants';
import useToast from 'components/toast/useToast';
import UserContext from 'contexts/UserContext';
import useCreateAsset, { AssetInput } from 'hooks/useCreateAsset';
import useDebouncedCallback from 'hooks/useDebouncedCallback';
import useGetUser from 'hooks/useGetUser';
import useTextStorage from 'hooks/useTextStorage';
import { usePolicies } from 'store';
import { Asset, DailyNote } from 'types';
import { EditorValue } from 'types/editor';
import { DailyNoteInput, MemberTypeEnum } from 'types/graphqlTypes';
import { getAssetData, getFileAssetData } from 'utils/assetData';
import checkUserRight from 'utils/checkUserRight';

import useCreateDailyNote from '../api/useCreateDailyNote';
import useUpdateDailyNote from '../api/useUpdateDailyNote';
import { useSelectedDailyNoteDate } from '../store';
import getUTCDateString from '../utils/getUTCDateString';

type UpdateInput =
  | { type: ActionTypesEnum.CHANGE; payload: EditorValue }
  | { type: ActionTypesEnum.CREATE_ASSET; payload: { asset: Asset } }
  | { type: ActionTypesEnum.ASSET_INSERT; payload: { file: File } };

const useDailyNoteEditor = (data?: DailyNote, mId = 'dailyNote') => {
  const [entity, setEntity] = useState<DailyNote | null | undefined>(data);

  const [createAssetMutation] = useCreateAsset();

  const { updateDailyNote } = useUpdateDailyNote();
  const { createDailyNote } = useCreateDailyNote();

  const { getUserTitle } = useGetUser();
  const { mId: currentUserId } = useContext(UserContext);

  const [policies] = usePolicies();
  const [selectedDate] = useSelectedDailyNoteDate();

  const [content, setContent] = useState<EditorValue | null>(
    initialValues(variants.DAILYNOTE) as EditorValue,
  );
  const [shouldResetSelection, setShouldResetSelection] = useState(false);
  const [writeLock, setWriteLock] = useState(false);
  const [readLock, setReadLock] = useState(false);
  const [lockedByUser, setLockedByUser] = useState<string>('Someone');
  const [isSavingContent, setIsSavingContent] = useState(false);
  const [locking, setLocking] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);

  const entityRef = useRef<DailyNote | null>();
  const editorValueRef = useRef<EditorValue | null>(null);
  const writeLockRef = useRef(writeLock);
  const initialContentRef = useRef<EditorValue | null>(null);
  const { toast } = useToast();

  const utcDateString = getUTCDateString(selectedDate);
  const { mRefId, mContentKey } = entity || {
    mContentKey: `dailyNote/${utcDateString}/content.data"`,
    mRefId: utcDateString,
  };
  const { data: textContent, loading, refetch } = useTextStorage(mContentKey, !mContentKey);
  const canUpdate = useMemo(() => checkUserRight(policies, 'dailyNote', 'access'), [policies]);

  const createAsset = useCallback(
    async (assetData: AssetInput) => {
      const asset = getAssetData(MemberTypeEnum.DailyNote, assetData);
      const result = await createAssetMutation(MemberTypeEnum.DailyNote, asset, false, undefined);

      return result;
    },
    [createAssetMutation],
  );

  const onAssetInsert = useCallback(
    async (file: File) => {
      const assetData = getFileAssetData(MemberTypeEnum.DailyNote, file);
      const sourceData = {
        mId: assetData.mId,
        mRefId: assetData.mRefId,
        src: '',
      };

      try {
        const result = await createAssetMutation(
          MemberTypeEnum.DailyNote,
          assetData,
          false,
          undefined,
        );
        const { createAssets: assets } = result.data as { createAssets: Asset[] };
        if (assets?.[0]) {
          sourceData.src = assets[0].mContentKey;
        }
      } catch (e) {
        toast({
          title: 'Error',
          description: `There was an error inserting the asset. ${JSON.stringify(e)}`,
          type: 'error',
        });
      }
      return sourceData;
    },
    [createAssetMutation, toast],
  );

  const onResetEditorValue = useCallback((newValue: EditorValue) => {
    if (newValue) {
      setContent({ ...newValue });
      editorValueRef.current = newValue;
      setShouldResetSelection(true);
    } else if (isNull(newValue)) {
      setContent(null);
      editorValueRef.current = null;
      setShouldResetSelection(true);
    }
  }, []);

  const setInitialValue = useCallback(
    (initialContent?: EditorValue) => {
      const initialValue = initialContent
        ? { ...initialContent }
        : (initialValues(variants.DAILYNOTE) as EditorValue);

      onResetEditorValue(initialValue);
      initialContentRef.current = initialValue;
    },
    [onResetEditorValue],
  );

  const onUpdateLock = useCallback(
    (lockedId?: string | null) => {
      if (lockedId) {
        setWriteLock(lockedId === currentUserId);
        setReadLock(lockedId !== currentUserId);
        if (lockedId !== currentUserId) {
          const newLockedByUser = getUserTitle(lockedId) ?? 'Someone';
          setLockedByUser(newLockedByUser);
        }
      } else {
        window.requestAnimationFrame(() => {
          setWriteLock(false);
          setReadLock(false);
          setLockedByUser('');
        });
      }
    },
    [currentUserId, getUserTitle],
  );

  const onForceUnlock = useCallback(async () => {
    try {
      setLocking(true);
      const input: DailyNoteInput = {
        mId: mId ?? 'dailyNote',
        mRefId: mRefId ?? utcDateString,
        locked: undefined,
      };
      const result = await updateDailyNote(input);
      setEntity(result);
      onUpdateLock(result?.locked);
      setLocking(false);
    } catch (e) {
      onUpdateLock(null);
      setLocking(false);
    }
  }, [mId, mRefId, onUpdateLock, updateDailyNote, utcDateString]);

  const onFocusEditor = useCallback(async () => {
    if (canUpdate && !writeLock && !readLock && !locking) {
      try {
        setLocking(true);
        refetch();
        const input: DailyNoteInput = {
          mId: mId ?? 'dailyNote',
          mRefId: mRefId ?? utcDateString,
          locked: currentUserId,
        };
        const result = entity ? await updateDailyNote(input) : await createDailyNote(input);
        setEntity(result);
        onUpdateLock(result?.locked);
        setLocking(false);
      } catch (error) {
        onUpdateLock(null);
        setLocking(false);
      }
    }
  }, [
    writeLock,
    readLock,
    locking,
    canUpdate,
    refetch,
    mId,
    mRefId,
    utcDateString,
    currentUserId,
    entity,
    updateDailyNote,
    createDailyNote,
    onUpdateLock,
  ]);

  const onSavePress = useCallback(
    async (shouldReleaseLock = true) => {
      if (!entity) return;
      if (!loading && writeLockRef.current) {
        setIsSavingContent(true);

        await updateDailyNote({
          mId: entity.mId,
          mRefId: entity.mRefId,
          content: JSON.stringify(editorValueRef.current),
          ...(shouldReleaseLock ? { locked: undefined } : { locked: entityRef.current?.locked }),
        });

        if (shouldReleaseLock) {
          onResetEditorValue(editorValueRef.current as EditorValue);
          initialContentRef.current = editorValueRef.current;
          onUpdateLock(null);
        }

        setIsSavingContent(false);
      }
    },
    [entity, loading, updateDailyNote, onResetEditorValue, onUpdateLock],
  );

  const onDebouncedSave = () => onSavePress(false);

  const [debouncedSave, cancelDebouncedCallback] = useDebouncedCallback(onDebouncedSave, 15000);

  const onCancelPress = useCallback(async () => {
    if (!entity) return;
    setIsCancelled(true);
    cancelDebouncedCallback();
    const initialValue = isNull(initialContentRef.current)
      ? (initialValues(variants.DAILYNOTE) as EditorValue)
      : initialContentRef.current;

    onResetEditorValue(initialValue);
    await updateDailyNote({
      mId: entity.mId,
      mRefId: entity.mRefId,
      content: JSON.stringify(initialValue),
    });

    onUpdateLock(null);
    setIsCancelled(false);
  }, [cancelDebouncedCallback, entity, onResetEditorValue, onUpdateLock, updateDailyNote]);

  const onChangeContent = useCallback(
    (newContent: EditorValue) => {
      if (writeLockRef.current) {
        editorValueRef.current = newContent;
        void debouncedSave();
      }
    },
    [debouncedSave],
  );

  const onEditorUpdate = ({ type, payload }: UpdateInput) => {
    if (type === ActionTypesEnum.CHANGE) onChangeContent(payload);
    if (type === ActionTypesEnum.CREATE_ASSET) {
      const { asset } = payload;
      return createAsset(asset);
    }
    if (type === ActionTypesEnum.ASSET_INSERT) {
      const { file } = payload;
      return onAssetInsert(file);
    }
    return null;
  };

  const beforeunloadFn = useCallback(async (e?: BeforeUnloadEvent) => {
    e?.preventDefault();
    delete e?.returnValue;

    if (
      entityRef.current &&
      writeLockRef.current &&
      entityRef.current?.locked === currentUserId &&
      editorValueRef.current
    ) {
      setIsSavingContent(true);
      cancelDebouncedCallback();

      await updateDailyNote({
        mId: entityRef.current.mId,
        mRefId: entityRef.current.mRefId,
        content: JSON.stringify(editorValueRef.current),
      });
      setIsSavingContent(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setEntity(data);
  }, [data]);

  useEffect(() => {
    entityRef.current = entity;
    onUpdateLock(entity?.locked);
  }, [entity, onUpdateLock]);

  useEffect(() => {
    writeLockRef.current = writeLock;
  }, [writeLock]);

  useLayoutEffect(() => {
    if (!mContentKey) {
      /** needed to override existing content for non existent entity */
      setInitialValue();
    } else if (textContent && entityRef.current?.mContentKey === mContentKey) {
      /** update with new content */
      setInitialValue(textContent);
    } else {
      setInitialValue();
    }
  }, [textContent, mContentKey, setInitialValue]);

  return {
    loading,
    content,
    writeLock,
    readLock,
    lockedByUser,
    locking,
    isCancelled,
    isSavingContent,
    shouldResetSelection,
    canUpdate,
    onChangeContent,
    onEditorUpdate,
    onSavePress,
    onCancelPress,
    onFocusEditor,
    beforeunloadFn,
    refetchContent: refetch,
    lockedId: entity?.locked,
    onForceUnlock,
  };
};

export default useDailyNoteEditor;
