import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  listItem: {
    display: 'flex',
    minHeight: '56px',
    borderBottom: `1px solid ${theme.palette.dina.dividerLight}`,
  },
  button: {
    '&:hover': {
      background: theme.palette.dina.hoverOverlay,
    },
    '&:focus': {
      backgroundColor: theme.palette.dina.onFocus,
    },
  },
  titleLine: {
    ...theme.typography.dina.listItemLabel,
    marginLeft: '8px',
    width: '215px',
  },
}));

export default useStyles;
