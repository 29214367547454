import { MemberTypeEnum } from 'types/graphqlTypes';

export type ConfigurableActionMTypes =
  | MemberTypeEnum.Story
  | MemberTypeEnum.Note
  | MemberTypeEnum.Rundown
  | MemberTypeEnum.Instance
  | MemberTypeEnum.Pitch
  | MemberTypeEnum.Order
  | MemberTypeEnum.Contact
  | MemberTypeEnum.User;

export const configurableActionMTypes: ConfigurableActionMTypes[] = [
  MemberTypeEnum.Story,
  MemberTypeEnum.Note,
  MemberTypeEnum.Rundown,
  MemberTypeEnum.Instance,
  MemberTypeEnum.Pitch,
  MemberTypeEnum.Order,
  MemberTypeEnum.Contact,
  MemberTypeEnum.User,
];

export const isConfigurableActionType = (
  obj: MemberTypeEnum | null | undefined,
): obj is ConfigurableActionMTypes => {
  if (!obj) return false;
  return configurableActionMTypes.includes(obj as ConfigurableActionMTypes);
};
