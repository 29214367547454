import styled from '@emotion/styled';

import Text from 'components/text/Text';

export const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  padding-left: 16px;
  gap: 10px;
`;

export const AvatarWrapper = styled('div')`
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  button {
    transition: height 100ms;
    position: absolute;
    bottom: 0;
    height: 0;
    padding: 0;
    border: none;
    border-radius: 0px;
  }
  :hover {
    button {
      height: 24px;
      padding-bottom: 4px;
    }
  }
`;

export const TypographyWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  z-index: 100;
`;

export const Title = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
