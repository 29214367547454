import React, { useMemo, memo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import Capitalize from 'utils/capitalize';
import { elementTypes } from 'components/editor/constants';
import useEditorContext from 'components/editor/hooks/useEditorContext';

import { ReactComponent as AddIcon } from 'assets/icons/systemicons/add.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/systemicons/close.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/systemicons/upload.svg';
import { RectangleButton } from 'components/buttons';
import Thumbnail from 'components/thumbnail';

import ActionButton from './components/button';

import {
  MediaWrapper,
  IconWrapper,
  PlaceholderIcon,
  ThumbnailWrapper,
  ButtonGroup,
} from './styled';
import MediaViewer from '../mediaViewer';
import variants from 'components/editor/constants/types/editorVariants';

const AddMedia = ({
  mediaSrc,
  mediaType,
  mediaWidth,
  mediaDuration,
  mediaThumbnail,
  hasPlaceholder,
  removePlaceholder,
  createPlaceholder,
  addMedia,
  removeMedia,
  readOnly,
  mId,
  mRefId,
  element,
  thumbSrc,
  withSignedUrl,
}) => {
  const [buttonType, setButtonType] = useState('default');
  const [asset, setAsset] = useState(null);
  const { variant } = useEditorContext();

  const handleMediaSelect = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      addMedia();
    },
    [addMedia],
  );

  const handleAction = useCallback(
    (event) => {
      if (buttonType === 'default') setButtonType('confirm');
      // only placeholder
      else if (hasPlaceholder) removePlaceholder(event);
      // placeholder filled with mimir asset
      else if (thumbSrc) removePlaceholder(event);
      else removeMedia(event); // DnD, Direct Upload
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [buttonType, hasPlaceholder, thumbSrc],
  );

  const handleClickAway = useCallback(() => {
    if (buttonType === 'confirm') setButtonType('default');
  }, [buttonType]);

  const uploadLabel = `Upload ${
    mediaType.charAt(0).toUpperCase() + mediaType.slice(1).toLowerCase()
  }`;

  const placeholderView = useMemo(
    () => (
      <MediaWrapper>
        <IconWrapper $mediaWidth={mediaWidth}>
          <PlaceholderIcon $active />
        </IconWrapper>
        <ButtonGroup>
          <ActionButton
            type={buttonType}
            icon={<CloseIcon />}
            onClickAway={handleClickAway}
            label="Remove Placeholder"
            onClick={handleAction}
          />
          {withSignedUrl && (
            <ActionButton icon={<UploadIcon />} label={uploadLabel} onClick={handleMediaSelect} />
          )}
        </ButtonGroup>
      </MediaWrapper>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [buttonType, handleAction, handleClickAway, handleMediaSelect],
  );

  const addView = useMemo(
    () => (
      <MediaWrapper>
        <RectangleButton
          width={mediaWidth}
          height={88}
          onClick={handleMediaSelect}
          disabled={readOnly}
        >
          <AddIcon />
        </RectangleButton>
        {variant !== variants.DAILYNOTE && (
          <ActionButton
            icon={<PlaceholderIcon />}
            label="Create Placeholder"
            onClick={createPlaceholder}
          />
        )}
      </MediaWrapper>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createPlaceholder, handleMediaSelect, readOnly],
  );

  const handleImageClick = useCallback(
    (e) => {
      e.stopPropagation();
      setAsset({
        type: elementTypes.IMAGE,
        filename: 'Image File',
        previewUri: mediaSrc,
      });
    },
    [mediaSrc],
  );

  const displayView = useMemo(
    () => (
      <MediaWrapper>
        <ThumbnailWrapper>
          <Thumbnail
            width={mediaWidth}
            height={88}
            type={mediaType}
            hideCloseButton
            duration={mediaDuration}
            videoThumbnailImage={mediaThumbnail}
            src={mediaSrc}
            mId={mId}
            mRefId={mRefId}
            element={element}
            onImageClick={handleImageClick}
            withSignedUrl={withSignedUrl}
          />
        </ThumbnailWrapper>
        <MediaViewer
          handleClose={() => setAsset(null)}
          open={!!asset}
          asset={asset}
          accessToken=""
        />
        <ActionButton
          type={buttonType}
          icon={<CloseIcon />}
          onClickAway={handleClickAway}
          label={`Remove ${Capitalize(mediaType)}`}
          onClick={handleAction}
        />
      </MediaWrapper>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      buttonType,
      element,
      handleAction,
      handleClickAway,
      mId,
      mRefId,
      mediaDuration,
      mediaSrc,
      mediaThumbnail,
      mediaType,
      withSignedUrl,
      asset,
    ],
  );

  if (hasPlaceholder) return placeholderView;
  if (mediaSrc || mediaThumbnail) return displayView;

  return addView;
};

AddMedia.propTypes = {
  /** src of media */
  mediaSrc: PropTypes.string,
  /** type of media [video, image] */
  mediaType: PropTypes.string,
  /** Boolean stops user from editing */
  readOnly: PropTypes.bool,
  /** width of the media */
  mediaWidth: PropTypes.number,
  /** Boolean that indicates a placeholder block */
  hasPlaceholder: PropTypes.bool,
  /** callback to remove placeholder */
  removePlaceholder: PropTypes.func,
  /** callback to create placeholder */
  createPlaceholder: PropTypes.func,
  /** callback to select a media file */
  addMedia: PropTypes.func,
  /** callback to remove media from block */
  removeMedia: PropTypes.func,
  /** Duration of video, shown when the type is video */
  mediaDuration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Thumbnail of video */
  mediaThumbnail: PropTypes.string,
};

AddMedia.defaultProps = {
  mediaSrc: null,
  mediaType: 'image',
  readOnly: false,
  mediaWidth: 128,
  hasPlaceholder: false,
  removePlaceholder: () => {},
  createPlaceholder: () => {},
  addMedia: () => {},
  removeMedia: () => {},
  mediaDuration: 0,
  mediaThumbnail: '',
};

export default memo(AddMedia);
