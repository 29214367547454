/* eslint-disable sort-imports */
import { useEffect, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { TableRow } from '@material-ui/core';

import { ReactComponent as DeleteIcon } from 'assets/icons/systemicons/delete.svg';
import { IconButton } from 'components/buttons';
import { Input, RowCell } from 'components/editMdfDialog/components/FieldModelRow';
import { StyledDragHandle } from 'components/mdfEditor/styled';
import { Alternative } from 'types/graphqlTypes';

interface RowProps {
  prompt: Alternative;
  onUpdateAlternative: (alt: Alternative) => void;
  onDelete: () => void;
}

export function AiConfigRow({ prompt, onUpdateAlternative, onDelete }: RowProps) {
  const [localLabel, setLocalLabel] = useState('');
  const [localValue, setLocalValue] = useState('');

  const { attributes, transform, transition, listeners, setNodeRef, setActivatorNodeRef } =
    useSortable({
      id: prompt.id,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  useEffect(() => {
    setLocalLabel(prompt.label);
    setLocalValue(prompt.value);
  }, [prompt]);

  return (
    <TableRow ref={setNodeRef} {...attributes} style={style}>
      <RowCell style={{ paddingLeft: '32px' }}>
        {
          <StyledDragHandle
            {...listeners}
            // @ts-expect-error ref incompatible from library
            ref={setActivatorNodeRef}
            style={{ top: '8px', position: 'absolute', left: '6px' }}
          />
        }
      </RowCell>
      <RowCell>
        <Input
          value={localLabel}
          onChange={(ev) => setLocalLabel(ev.target.value)}
          onBlur={() => onUpdateAlternative({ ...prompt, label: localLabel })}
        />
      </RowCell>
      <RowCell>
        <Input
          value={localValue}
          onChange={(ev) => setLocalValue(ev.target.value)}
          onBlur={() => onUpdateAlternative({ ...prompt, value: localValue })}
        />
      </RowCell>
      <RowCell>
        <IconButton
          title="Delete field"
          usage="text"
          size={24}
          iconSize={18}
          onClick={() => onDelete()}
        >
          <DeleteIcon />
        </IconButton>
      </RowCell>
    </TableRow>
  );
}
