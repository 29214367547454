import { useMutation } from '@apollo/client';
import DELETE_MEMBER from 'graphql/mutations/deleteMember';
import useLogger from 'utils/useLogger';

const useRemovePlaceholder = () => {
  const logger = useLogger('use remove placeholder');
  const [removePlaceholder] = useMutation(DELETE_MEMBER);

  const removeAssetPlaceholder = async (data) => {
    const input = {
      mId: data.mId,
      mRefId: data.mRefId,
    };

    try {
      await removePlaceholder({ variables: { input } });
      return;
    } catch (e) {
      // eslint-disable-next-line no-console
      logger.log(e);
    }
  };

  return [removeAssetPlaceholder];
};

export default useRemovePlaceholder;
