import memberTypes from 'graphql/memberTypes';
import capitalize from 'utils/capitalize';
import conversationTypes from 'utils/constants/conversationTypes';
import checkIsNotificationConversation from 'utils/messageHub/checkIsNotificationConversation';

interface SharedMember {
  mTitle?: string;
}

interface Properties {
  story?: string;
  instance?: string;
  mPublishingAt?: string;
  mTemplateId?: string;
  isTemplateInstance?: string;
  mTitle?: string;
}

interface MContent {
  mId: string;
  mType?: string;
  isTemplateInstance?: boolean;
  mTemplateId?: string;
  storyId?: string;
  mStoryId?: string;
  mPublishingAt?: string;
  sharedMember?: SharedMember;
  crudAction?: string;
  messageAssign?: string;
  properties?: Properties;
}

const getAssignmentInfo = (mContent: MContent) => {
  const { mId, mType, isTemplateInstance, mTemplateId, storyId, mStoryId, mPublishingAt } =
    mContent;
  switch (mType) {
    case memberTypes.USER_STORY:
      return { assignmentType: memberTypes.STORY, page: memberTypes.STORY, mStoryId: mId };
    case memberTypes.USER_PITCH:
      return { assignmentType: memberTypes.PITCH, page: memberTypes.PITCH, mStoryId: mId };
    case memberTypes.USER_INSTANCE:
      return {
        assignmentType: memberTypes.INSTANCE,
        page: mTemplateId || isTemplateInstance ? memberTypes.RUNDOWN : memberTypes.STORY,
        mStoryId: storyId ?? mStoryId,
        instanceId: mId,
        publishingAt: mPublishingAt,
      };
    case memberTypes.TEAM_USER:
      return { assignmentType: memberTypes.TEAM, page: memberTypes.TEAM };
    case memberTypes.DEPARTMENT_USER:
      return { assignmentType: memberTypes.DEPARTMENT, page: memberTypes.DEPARTMENT };
    case memberTypes.TEAM_STORY:
      return {
        assignmentType: memberTypes.STORY,
        page: memberTypes.STORY,
        assignedMember: `Team "${mContent?.sharedMember?.mTitle}"`,
        mStoryId: mId,
      };
    case memberTypes.DEPARTMENT_STORY:
      return {
        assignmentType: memberTypes.STORY,
        page: memberTypes.STORY,
        assignedMember: `Department "${mContent?.sharedMember?.mTitle}"`,
        mStoryId: mId,
      };
    case memberTypes.TEAM_PITCH:
      return {
        assignmentType: memberTypes.PITCH,
        page: memberTypes.STORY,
        assignedMember: `Team "${mContent?.sharedMember?.mTitle}"`,
        mStoryId: mId,
      };
    case memberTypes.DEPARTMENT_PITCH:
      return {
        assignmentType: memberTypes.PITCH,
        page: memberTypes.STORY,
        assignedMember: `Department "${mContent?.sharedMember?.mTitle}"`,
        mStoryId: mId,
      };
    case memberTypes.TEAM_INSTANCE:
      return {
        assignmentType: memberTypes.INSTANCE,
        page: mTemplateId ? memberTypes.RUNDOWN : memberTypes.STORY,
        mStoryId: isTemplateInstance ? undefined : storyId ?? mStoryId,
        instanceId: isTemplateInstance ? undefined : mId,
        assignedMember: `Team "${mContent?.sharedMember?.mTitle}"`,
      };
    case memberTypes.DEPARTMENT_INSTANCE:
      return {
        assignmentType: memberTypes.INSTANCE,
        page: mTemplateId ? memberTypes.RUNDOWN : memberTypes.STORY,
        mStoryId: isTemplateInstance ? undefined : storyId ?? mStoryId,
        instanceId: isTemplateInstance ? undefined : mId,
        publishingAt: mPublishingAt,
        assignedMember: `Department "${mContent?.sharedMember?.mTitle}"`,
      };
    default:
      return {
        assignmentType: memberTypes.STORY,
        page: memberTypes.STORY,
      };
  }
};

const createMentionContent = (entityType?: string, mTitle?: string) => {
  const text = `You were mentioned in ${entityType} chat for "${mTitle}"`;
  return JSON.stringify({
    document: [
      {
        type: 'paragraph',
        children: [
          {
            text: text || '',
          },
        ],
      },
    ],
    version: '0.1.0',
  });
};

const createAssignmentContent = (content: string, mTitle?: string, assignedBy?: string) => {
  const mContent = JSON.parse(content) as MContent;
  const { assignmentType, page, mStoryId, instanceId, assignedMember } =
    getAssignmentInfo(mContent);
  const { crudAction, mType, messageAssign } = mContent;
  const crudInsertText =
    mType === memberTypes.TEAM_USER || mType === memberTypes.DEPARTMENT_USER
      ? `added ${assignedMember ?? 'you'} to`
      : `assigned ${assignedMember ?? 'you'} to`;
  const actionText =
    crudAction === 'REMOVE' ? `removed ${assignedMember ?? 'you'} from` : crudInsertText;
  const notificationMessage = [
    {
      type: 'heading-three',
      children: [
        {
          text: `${capitalize(assignmentType)} Assignment`,
        },
      ],
    },
    {
      type: 'paragraph',
      children: [
        {
          text: `"${assignedBy ?? 'someone'}" ${actionText} ${assignmentType} "${mTitle}"`,
        },
      ],
    },
  ];

  const assignmentMessage = messageAssign
    ? [
        {
          type: 'paragraph',
          children: [
            {
              text: `${messageAssign}`,
            },
          ],
        },
      ]
    : [];

  const newContent = JSON.stringify({
    document: [...notificationMessage, ...assignmentMessage],
    version: '0.1.0',
  });

  return { mContent: newContent, mStoryId, page, instanceId };
};

const getInfoFromContent = (
  content: string,
  convoType?: string,
  mTitle?: string,
  assignedBy?: string,
) => {
  if (!checkIsNotificationConversation(convoType)) return { content };
  if (convoType === conversationTypes.ASSIGNMENT) {
    return createAssignmentContent(content, mTitle, assignedBy);
  }
  return { mContent: null };
};

const getInfoFromMessage = (mContent: string, convoType?: string) => {
  try {
    const {
      properties: {
        story: mStoryId,
        instance: instanceId,
        mPublishingAt = undefined,
        mTemplateId,
        isTemplateInstance,
        mTitle,
      } = {},
    } = JSON.parse(mContent) as MContent;

    const mType = mTemplateId ? memberTypes.RUNDOWN : memberTypes.STORY;
    const messageText =
      convoType === conversationTypes.MENTION ? createMentionContent(mType, mTitle) : mContent;

    return {
      mContent: messageText,
      mStoryId,
      instanceId,
      page: mType,
      publishingAt: mPublishingAt,
      isTemplateInstance,
    };
  } catch {
    return { mContent: null };
  }
};

export { getInfoFromContent, getInfoFromMessage };
