import { atom, useAtom, useAtomValue } from 'jotai';
import { createScope, molecule, useMolecule } from 'jotai-molecules';

import { Instance } from 'types';

export const PaneScope = createScope<`${string}:${number}` | undefined>(undefined);

const storyPaneMolecule = molecule((_getMol, getScope) => {
  const paneScope = getScope(PaneScope);
  const [, index] = (paneScope ?? ':0').split(':');

  const paneIndexAtom = atom<number>(parseInt(index));
  const creatingInstanceAtom = atom(false);
  const newlyAddedInstanceAtom = atom<Instance | null>(null);
  return {
    usePaneIndexValue: () => useAtomValue(paneIndexAtom),
    useCreatingInstance: () => useAtom(creatingInstanceAtom),
    useNewlyAddedInstance: () => useAtom(newlyAddedInstanceAtom),
  };
});

export const useStoryPaneMolecule = () => useMolecule(storyPaneMolecule);
