import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AddItem from 'screens/main/components/rightArea/sidebar/teams/listItem/addItem/addItem-view';
import { Typography, Divider, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import TextArea from 'components/textArea/TextArea';

const useStyles = makeStyles((theme) => ({
  headline: {
    ...theme.typography.dina.overline,
    width: '100%',
    paddingBottom: '4px',
  },
  description: {
    ...theme.typography.dina.rightColumnHeaderText,
    color: theme.palette.dina.highEmphasis,
    width: '100%',
  },
  divider: {
    backgroundColor: theme.palette.dina.dividerLight,
  },
  textAreaDiv: {
    width: '100%',
    paddingInline: '16px',
    paddingBottom: '16px',
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
  },
  inputHeadline: {
    ...theme.typography.dina.overline,
    width: '100%',
    padding: '16px 16px 4px 16px',
  },
  button: {
    '&:hover': {
      backgroundColor: theme.palette.dina.surfaceAppBackgroundMain,
    },
    '&:focus': {
      backgroundColor: theme.palette.dina.onFocus,
    },
  },
}));
const TeamDescription = (props) => {
  const { description, onChange } = props;
  const [showTextField, setShowTextField] = useState(false);
  const classes = useStyles();
  return (
    <div
      onBlur={(event) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
          setShowTextField(false);
        }
      }}
    >
      {!showTextField && description && (
        <div>
          <ListItem
            button
            classes={{ root: classes.listItem, button: classes.button }}
            onClick={() => setShowTextField(!showTextField)}
          >
            <Typography classes={{ root: classes.headline }}>DESCRIPTION</Typography>
            <Typography classes={{ root: classes.description }}>{description}</Typography>
          </ListItem>
          <Divider classes={{ root: classes.divider }} />
        </div>
      )}

      {!description && !showTextField && (
        <AddItem title="Add Description..." onClick={() => setShowTextField(true)} />
      )}
      {showTextField && (
        <>
          <Typography classes={{ root: classes.inputHeadline }}>DESCRIPTION</Typography>
          <div className={classes.textAreaDiv}>
            <TextArea
              type="descriptionField"
              autoFocus
              value={description === null ? '' : description}
              onChange={onChange}
              description="Short description of what the Team does"
              rows="3"
            />
          </div>
          <Divider classes={{ root: classes.divider }} />
        </>
      )}
    </div>
  );
};

TeamDescription.propTypes = {
  description: PropTypes.string,
};

TeamDescription.defaultProps = {
  description: '',
};

export default TeamDescription;
