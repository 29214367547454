import { useEffect, useState } from 'react';

import { ReactComponent as CalendarOff } from 'assets/icons/systemicons/calendar_off.svg';
import { ReactComponent as CalendarOn } from 'assets/icons/systemicons/calendar_on.svg';
import Calendar from 'components/calendar';
import Popover from 'components/dialogs/PopoverBuilder';
import Text from 'components/text/Text';
import Tooltip from 'components/tooltip/Tooltip';
import useCheckUserRight from 'hooks/useCheckUserRight';
import usePublishDateUpdate from 'hooks/usePublishDateUpdate';
import { MemberType } from 'types/graphqlTypes';
import capitalize from 'utils/capitalize';
import getRelativeDate from 'utils/getRelativeDate';

import { StyledButton } from './styled';

interface PublishingTimes {
  startDate?: string | null;
  endDate?: string | null;
}

interface Props {
  member?: Pick<MemberType, 'mId' | 'mRefId' | 'mPublishingAt' | 'mPublishingEnd'>;
  canUpdatePublishing: boolean;
}

export default function PublishButton({ member, canUpdatePublishing }: Readonly<Props>) {
  const [open, setOpen] = useState(false);
  const [publishTimes, setPublishTimes] = useState<PublishingTimes | undefined>({
    startDate: member?.mPublishingAt,
    endDate: member?.mPublishingEnd,
  });

  const [checkUserRight] = useCheckUserRight();
  const canSelectRange = checkUserRight('feature', 'story-date-range');
  const [updatePublishDate] = usePublishDateUpdate();

  const { startDate: sDate = undefined, endDate: eDate = undefined } = publishTimes || {};

  useEffect(() => {
    setPublishTimes({ startDate: member?.mPublishingAt, endDate: member?.mPublishingEnd });
  }, [member?.mPublishingAt, member?.mPublishingEnd]);

  const onClose = () => {
    setOpen(false);
  };

  const handleChange = async (newTime: Date) => {
    onClose();
    const dates = {
      startDate: newTime.toISOString(),
      endDate: null,
    };
    setPublishTimes(dates);
    await updatePublishDate(member?.mId, dates.startDate, dates.endDate);
  };

  const handleOnChangeDateRange = async ({ startDate, endDate }: PublishingTimes) => {
    onClose();
    const dates = {
      startDate,
      endDate,
    };
    setPublishTimes(dates);
    await updatePublishDate(member?.mId, dates.startDate, dates.endDate);
  };

  const handleUnschedule = async () => {
    onClose();
    setPublishTimes(undefined);
    await updatePublishDate(member?.mId);
  };

  const labelStart = sDate
    ? `${capitalize(getRelativeDate(sDate, 'DD MMM. YYYY'))}`
    : 'Schedule date...';

  const labelEnd = eDate ? `to ${capitalize(getRelativeDate(eDate, 'DD MMM. YYYY'))}` : '';

  return (
    <div style={{ justifySelf: 'start' }}>
      <Popover open={open} onOpenChange={setOpen}>
        <Tooltip
          title={
            canUpdatePublishing
              ? 'Change schedule date'
              : 'You do not have permission to change schedule date of a story'
          }
        >
          <Popover.Trigger asChild>
            <div>
              <StyledButton
                $dark
                height={32}
                variant="outlined"
                usage="outlined"
                disabled={!canUpdatePublishing}
              >
                {publishTimes?.startDate ? (
                  <CalendarOn className="skipOverride" />
                ) : (
                  <CalendarOff className="skipOverride" />
                )}
                <Text variant="listItemLabel" color="whiteHighEmphasis">
                  {labelStart}
                </Text>
                <Text variant="listItemLabel" color="whiteHighEmphasis">
                  {labelEnd}
                </Text>
              </StyledButton>
            </div>
          </Popover.Trigger>
        </Tooltip>
        {canUpdatePublishing && (
          <Popover.Content>
            <Calendar
              selectedDateRange={publishTimes}
              changeSelectedDate={handleChange}
              onUnschedule={handleUnschedule}
              changeSelectedDateRange={handleOnChangeDateRange}
              disableRangeSwitch={false}
              selectRange={canSelectRange}
              onClose={onClose}
            />
          </Popover.Content>
        )}
      </Popover>
    </div>
  );
}
