import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Divider from 'components/divider';
import Popover from 'components/popover';
import Chip from 'components/chip';
import Avatar from 'components/avatar/Avatar';
import ListItem from 'components/ellipsisDropdown/listItem-view';
import { ReactComponent as UserAdd } from 'assets/icons/systemicons/user_add.svg';
import { ReactComponent as ArrowDouble } from 'assets/icons/systemicons/arrows/arrow_double.svg';
import useStyles from './header-styles';

const paddingWidth = 40;
const userAddIconWidth = 24;
const chipWithMarginWidth = 132;
const overflowIconWidth = 48;

const Header = ({
  openNewMessage,
  mAssignedMembers,
  removeMember,
  showAddUser,
  showDeleteButton,
  selectMember,
  avatarVariant,
}) => {
  const classes = useStyles();
  const componentRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [shownMembers, setShownMembers] = useState([]);
  const [overflownMembers, setOverflownMembers] = useState([]);

  const openPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  const calculateMemberOverflow = (width) => {
    const effectiveWidth = width - paddingWidth - userAddIconWidth - overflowIconWidth;
    const noOfShownMembers = Math.floor(effectiveWidth / chipWithMarginWidth);

    setShownMembers(mAssignedMembers.slice(0, noOfShownMembers));
    setOverflownMembers(mAssignedMembers.slice(noOfShownMembers, mAssignedMembers.length));
  };

  const init = () => {
    let resizeObserver;
    const containerElement = componentRef.current;

    if (window.ResizeObserver) {
      resizeObserver = new ResizeObserver(() => {
        const { width } = containerElement.getBoundingClientRect();
        calculateMemberOverflow(width);
      });
      resizeObserver.observe(containerElement);
    } else {
      const { width } = containerElement.getBoundingClientRect();
      calculateMemberOverflow(width);
    }

    return () => {
      if (resizeObserver) resizeObserver.disconnect();
    };
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(init, [mAssignedMembers]);

  return (
    <div className={classes.headerRoot}>
      <div className={classes.component} ref={componentRef}>
        <div className={classes.chipContainer}>
          {shownMembers.map((member) => {
            if (member) {
              const { mId, mTitle, mAvatarUrl } = member;
              return (
                <Chip
                  key={mId}
                  label={mTitle}
                  avatarUrl={mAvatarUrl}
                  onDelete={() => removeMember(mId)}
                  showDeleteButton={showDeleteButton}
                  onChipClick={() => selectMember(mId)}
                  variant={avatarVariant}
                />
              );
            }
            return null;
          })}
          {overflownMembers.length > 0 && (
            <div className={classes.overflowComponent} onClick={openPopover} role="presentation">
              <div className={classes.overflowText}> {`+${overflownMembers.length}`}</div>
              <div className={classes.arrow}>
                <ArrowDouble />
              </div>
            </div>
          )}
          <Popover anchorEl={anchorEl} onClose={closePopover} noMargin>
            <div className={classes.popover}>
              {overflownMembers.map((member) => (
                <ListItem
                  key={member.mId}
                  text={member.mTitle}
                  excludeDivider
                  firstChild={
                    <div className={classes.avatar}>
                      <Avatar
                        size={24}
                        src={member.mAvatarUrl}
                        title={member.mTitle}
                        tooltipContent={member.mTitle}
                      />
                    </div>
                  }
                />
              ))}
            </div>
          </Popover>
        </div>
        {showAddUser && <UserAdd onClick={openNewMessage} className={classes.addUser} />}
      </div>
      <Divider />
    </div>
  );
};

Header.propTypes = {
  /** callback to open new message by clicking add user icon */
  openNewMessage: PropTypes.func,
  /** a list of members in a conversation */
  mAssignedMembers: PropTypes.arrayOf(
    PropTypes.shape({
      mId: PropTypes.string,
      mType: PropTypes.string,
    }),
  ),
  /** callback to remove user & initiate different conversation */
  removeMember: PropTypes.func,
  /** boolean to show add user button */
  showAddUser: PropTypes.bool,
  /** boolean to show delete button on chip */
  showDeleteButton: PropTypes.bool,
  /** callback to select a direct message with a member by clicking a chip */
  selectMember: PropTypes.func,
};

Header.defaultProps = {
  openNewMessage: () => {},
  mAssignedMembers: [],
  removeMember: () => {},
  showAddUser: true,
  showDeleteButton: true,
  selectMember: () => {},
};

export default Header;
