import { distanceInWordsToNow } from 'date-fns';
import capitalize from 'utils/capitalize';
import conversationTypes from 'utils/constants/conversationTypes';
import AvatarCell from '../components/avatarCell';

const assembleDepartmentData = (conversations = [], skipAvatarComponent = false) =>
  conversations.map((conversation) => {
    const { mId, mRefId, mTitle, mUpdatedAt, mAvatarUrl } = conversation;
    return {
      ...conversation,
      conversationMId: mId,
      conversationMRefId: mRefId,
      rowId: mId,
      type: 'Department',
      name: skipAvatarComponent ? (
        mTitle
      ) : (
        <AvatarCell mTitle={mTitle} mAvatarUrl={mAvatarUrl} avatarVariant="department" />
      ),
      chatHistory: mUpdatedAt
        ? capitalize(distanceInWordsToNow(mUpdatedAt, { addSuffix: true }))
        : '',
      convoType: conversationTypes.DEPARTMENT,
      mAssignedMembers: [conversation],
    };
  });
export default assembleDepartmentData;
