import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Storage } from '@aws-amplify/storage';

import { EditorValue } from 'types';
import { replaceTab } from 'utils/content/contentUtil';

const useTextStorage = (key: string, skip = false) => {
  const [data, setData] = useState<EditorValue | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [shouldRefetch, setShouldRefetch] = useState(false);

  const memoizedKey = useMemo(() => key ?? '', [key]);

  const isSubscribed = useRef<string | null>(null);

  const refetch = useCallback(() => {
    setShouldRefetch(true);
  }, []);

  const getContent = useCallback(async (contentKey: string) => {
    setLoading(true);
    return Storage.get(contentKey, {
      customPrefix: { public: '' },
    })
      .then((result) => fetch(result).then((response) => response.json()))
      .then((jsonResponse: string) => {
        if (isSubscribed.current === contentKey) {
          setData(replaceTab(jsonResponse as unknown as EditorValue));
        }
      })
      .catch((err: { message: string }) => {
        setError(err.message);
        setData(null);
      })
      .finally(() => {
        setLoading(false);
        setShouldRefetch(false);
      });
  }, []);

  useEffect(() => {
    if (loading && shouldRefetch) {
      setShouldRefetch(false);
    }
  }, [loading, shouldRefetch]);

  useEffect(() => {
    isSubscribed.current = memoizedKey;
    if ((shouldRefetch || !skip) && memoizedKey) {
      void getContent(memoizedKey);
    }

    return () => {
      isSubscribed.current = null;
    };
  }, [memoizedKey, skip, shouldRefetch, getContent]);

  return { error, loading, data, refetch };
};

export default useTextStorage;
