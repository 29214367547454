import { Instance } from 'types';
import { AccountType } from 'types/graphqlTypes';

import useImageUrl from './useImageUrl';

const useGetInstanceThumbnail = (instance: Instance) => {
  const { mThumbnailKey, mProperties } = instance;
  const { account = {}, platform } = mProperties ?? {};
  const { accountLogo } = account as AccountType;
  /* For linear instance rundown thumbnail is preferred */
  const thumbnailUrl = useImageUrl(
    platform === 'linear' && accountLogo ? accountLogo : (mThumbnailKey as string),
  );

  return [thumbnailUrl];
};

export default useGetInstanceThumbnail;
