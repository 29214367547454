import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { usePolicies } from 'store';
import { type ContentTab, useContentTabs, useMaxVisibleTabs, useSetCurrentTab } from 'store/tabs';
import { WithOptional } from 'types';
import { insertIntoArray, moveArrayItem } from 'utils/arrayUtils';
import checkUserRight from 'utils/checkUserRight';
import { removeStoryLayoutByStoryId } from 'utils/storyHelper/storyHelper';

type ContentKey = keyof ContentTab;

const useTabs = () => {
  const [policies] = usePolicies();
  const navigate = useNavigate();
  const { id: currentId } = useParams();
  const [contentTabs, setContentTabs] = useContentTabs();
  const setCurrentTab = useSetCurrentTab();
  const [maxVisibleTabs] = useMaxVisibleTabs();

  useEffect(() => {
    if (currentId) {
      const tab = contentTabs.find((t) => t.id === currentId);
      if (tab) setCurrentTab(tab);
    } else {
      setCurrentTab(null);
    }
  }, [currentId, contentTabs, setCurrentTab]);

  const addTab = useCallback(
    (newTab: ContentTab) => {
      const { id } = newTab;
      const tabIndex = contentTabs.findIndex((tab) => tab.id === id);

      if (tabIndex === -1) {
        if (contentTabs.length >= maxVisibleTabs) {
          setContentTabs(insertIntoArray(contentTabs, maxVisibleTabs - 1, newTab));
        } else {
          setContentTabs([...contentTabs, newTab]);
        }
      } else if (tabIndex > maxVisibleTabs - 1) {
        setContentTabs(moveArrayItem(contentTabs, tabIndex, maxVisibleTabs - 1));
      }
    },
    [contentTabs, maxVisibleTabs, setContentTabs],
  );

  const updateTab = useCallback(
    (tab: Partial<ContentTab>) => {
      const tabToUpdate = contentTabs.find((t) => t.id === tab.id);
      if (!tabToUpdate) return;

      const updated = Object.keys(tab).every(
        (key) =>
          Object.hasOwn(tabToUpdate, key) &&
          tab[key as ContentKey] === tabToUpdate[key as ContentKey],
      );
      if (updated) return;

      const updatedTab = {
        ...tabToUpdate,
        ...tab,
      };

      const canUseStoryRundownChat = checkUserRight(policies, 'chat', 'access');
      if (canUseStoryRundownChat) {
        updatedTab.notificationCount = tabToUpdate.notificationCount ?? 0;
      } else {
        delete updatedTab.notificationCount;
      }
      setContentTabs(contentTabs.map((t) => (t.id === updatedTab.id ? updatedTab : t)));
    },
    [contentTabs, policies, setContentTabs],
  );

  const closeTab = useCallback(
    (tab: WithOptional<ContentTab, 'type'>) => {
      const { id } = tab;
      const tabIndex = contentTabs.findIndex((t) => t.id === id);
      if (tabIndex === -1) return;

      if (tab.type === 'story') removeStoryLayoutByStoryId(id);

      setContentTabs(contentTabs.filter((t) => t.id !== id));

      if (currentId === id) {
        const nextTabIndex = tabIndex > 0 ? tabIndex - 1 : tabIndex + 1;
        const nextTab = contentTabs[nextTabIndex];
        navigate(nextTab ? `/${nextTab.type}/${nextTab.id}${nextTab?.search ?? ''}` : '/home');
      }
    },
    [contentTabs, currentId, navigate, setContentTabs],
  );

  const closeInactiveTabs = useCallback(() => {
    setContentTabs(contentTabs.filter((t) => t.id === currentId));
  }, [contentTabs, currentId, setContentTabs]);

  const closeActiveTab = useCallback(() => {
    const tabIndex = contentTabs.findIndex((t) => t.id === currentId);
    const nextTabIndex = tabIndex > 0 ? tabIndex - 1 : tabIndex + 1;
    const nextTab = contentTabs[nextTabIndex];

    setContentTabs(contentTabs.filter((t) => t.id !== currentId));

    navigate(nextTab ? `/${nextTab.type}/${nextTab.id}${nextTab?.search ?? ''}` : '/home');
  }, [contentTabs, currentId, navigate, setContentTabs]);

  const closeOtherTabs = useCallback(
    (tab: ContentTab) => {
      const { type, id, search = '' } = tab;
      const tabIndex = contentTabs.findIndex((t) => t.id === String(id));
      if (tabIndex === -1) return;

      setContentTabs([tab]);
      if (currentId !== id) {
        navigate(`/${type}/${id}${search}`);
      }
    },
    [contentTabs, currentId, navigate, setContentTabs],
  );

  const closeAllTabs = useCallback(() => {
    setContentTabs([]);
    if (currentId) {
      navigate('/home');
    }
  }, [currentId, navigate, setContentTabs]);

  const closeLeftTabs = useCallback(
    (tab: ContentTab) => {
      const { type, id, search = '' } = tab;
      const tabIndex = contentTabs.findIndex((t) => t.id === String(id));
      if (tabIndex === -1) return;

      setContentTabs(contentTabs.slice(tabIndex));
      navigate(`/${type}/${id}${search}`);
    },
    [contentTabs, navigate, setContentTabs],
  );

  const closeRightTabs = useCallback(
    (tab: ContentTab) => {
      const { type, id, search = '' } = tab;
      const tabIndex = contentTabs.findIndex((t) => t.id === String(id));
      if (tabIndex === -1) return;

      setContentTabs(contentTabs.slice(0, tabIndex + 1));
      navigate(`/${type}/${id}${search}`);
    },
    [contentTabs, navigate, setContentTabs],
  );

  const updateTabNotifications = useCallback(
    (tab: ContentTab) => {
      const { id } = tab;
      const tabToUpdate = contentTabs.find((t) => t.id === String(id));
      if (!tabToUpdate) return;

      const canUseStoryRundownChat = checkUserRight(policies, 'chat', 'access');
      if (!canUseStoryRundownChat) return;

      if (currentId !== id) {
        tabToUpdate.notificationCount = (tabToUpdate.notificationCount ?? 0) + 1;
        setContentTabs(contentTabs.map((t) => (t.id === tabToUpdate.id ? tabToUpdate : t)));
      }
    },
    [contentTabs, currentId, policies, setContentTabs],
  );

  const resetTabNotification = useCallback(
    (tab: ContentTab) => {
      const { id } = tab;
      const tabToUpdate = contentTabs.find((t) => t.id === String(id));
      if (!tabToUpdate) return;

      tabToUpdate.notificationCount = 0;
      setContentTabs(contentTabs.map((t) => (t.id === tabToUpdate.id ? tabToUpdate : t)));
    },
    [contentTabs, setContentTabs],
  );

  return {
    addTab,
    updateTab,
    closeTab,
    closeInactiveTabs,
    closeActiveTab,
    closeOtherTabs,
    closeAllTabs,
    closeLeftTabs,
    closeRightTabs,
    updateTabNotifications,
    resetTabNotification,
  };
};

export default useTabs;
