import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';

import Divider from 'components/divider';
import { useEditFieldValueDialog } from 'components/mdfEditor/EditFieldValueDialog';
import { ChoiceField } from 'components/mdfEditor/fields/choice/ChoiceField';
import { MdfEditor } from 'components/mdfEditor/MdfEditor';
import Scrollbar from 'components/scrollbar';
import SplitBar from 'components/split';
import Tabs from 'components/tabs/contained';
import Text from 'components/text/Text';
import Tooltip from 'components/tooltip';
import { Flex } from 'layouts/box/Box';
import { Metadata, NewFieldValue } from 'types/forms/forms';
import { FieldTypeEnum, LayoutSettings, Mdf, MdfField, ViewTypes } from 'types/graphqlTypes';

import { ConfigFieldDialog } from './components/ConfigFieldDialog';
import { EditFieldLayout } from './components/EditFieldLayout';
import { EditFieldModel } from './components/EditFieldModel';
import NewFieldDialog from './components/NewFieldDialog';
import { usePermissionDialog } from './EditPermissionDialog';
import {
  generateTooltipText,
  getDefaultLayoutSettings,
  getDefaultPayload,
  normalizeField,
} from './utils';

import {
  ContentWrapper,
  EditWrapper,
  MdfEditorWrapper,
  Payload,
  PayloadInfo,
  PayloadTitle,
  PayloadWrapper,
  RightHeader,
  RightSubWrapper,
  RightWrapper,
  TabsWrapper,
} from './styled';

interface Tab {
  label: string;
  id: ViewTypes | 'model';
}

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;

interface EditMdfProps {
  mdf: Mdf;
  onChange?: (updatedMdf: Mdf | null, originalMdf: Mdf | null) => void;
}

const tabs: Tab[] = [
  { label: 'Model', id: 'model' },
  { label: 'Layout defaults', id: 'default' },
];

export function EditMdf({ mdf, onChange }: Readonly<EditMdfProps>) {
  const [newFieldOpen, setNewFieldOpen] = useState(false);
  const [configField, setConfigField] = useState<MdfField | null>(null);
  const [, showEditFieldDialog] = useEditFieldValueDialog();
  const [, openPermissionDialog] = usePermissionDialog();
  const [selectedView, setSelectedView] = useState<ViewTypes | 'model'>('model');
  const [formPayload, setFormPayload] = useState<Metadata>(getDefaultPayload(mdf));
  const [errorMap, setErrorMap] = useState<Record<string, string | undefined>>({});

  const errorTooltip = useMemo(() => {
    const labelMap: Record<string, string> = {};
    for (const field of mdf.fields) {
      if (selectedView === 'model') {
        labelMap[field.fieldId] =
          mdf.views.default.find((s) => s.fieldId === field.fieldId)?.label ?? field.fieldId;
      } else {
        const layoutSettings = mdf.views[selectedView] ?? mdf.views.default;
        labelMap[field.fieldId] =
          layoutSettings.find((s) => s.fieldId === field.fieldId)?.label ?? field.fieldId;
      }
    }
    const errors = generateTooltipText(errorMap, labelMap);
    if (!errors.length) return undefined;
    return (
      <>
        <div>Please resolve errors:</div>
        <ul style={{ padding: '0 0 0 20px' }}>
          {errors.map((err) => (
            <li key={err}>{err}</li>
          ))}
        </ul>
      </>
    );
  }, [errorMap, selectedView]);

  const indexes = tabs.map((t) => t.id);
  const labels = tabs.map((t) => t.label);

  const setFields = useCallback(
    (newFields: MdfField[]) => {
      if (mdf && onChange) {
        onChange({ ...mdf, fields: newFields }, mdf);
      }
    },
    [mdf, onChange],
  );

  const setPayload = useCallback(
    (p: NewFieldValue) => {
      setFormPayload((prevState) => ({
        ...prevState,
        [p.fieldId]: p.value,
      }));
    },
    [setFormPayload],
  );

  const removeFromPayload = useCallback(
    (fieldId: string) => {
      const prevState = {
        ...formPayload,
      };
      delete prevState[fieldId];
      setFormPayload(prevState);
    },
    [setFormPayload, formPayload],
  );

  const updateField = useCallback(
    (field: MdfField) => {
      if (mdf && onChange) {
        const index = mdf.fields.findIndex((f) => f.fieldId === field.fieldId);
        const otherFields = mdf.fields.filter((f) => f.fieldId !== field.fieldId);
        const updatedFields = [...otherFields];
        updatedFields.splice(index, 0, field);

        onChange({ ...mdf, fields: updatedFields }, mdf);
      }
    },
    [mdf, onChange],
  );

  const updatePermissions = useCallback(
    (fieldId: string, permissions: { read: string[]; write: string[] }) => {
      if (mdf && onChange) {
        onChange(
          {
            ...mdf,
            permissions: {
              read: { ...mdf.permissions.read, [fieldId]: permissions.read },
              write: { ...mdf.permissions.write, [fieldId]: permissions.write },
            },
          },
          mdf,
        );
      }
    },
    [mdf, onChange],
  );

  const addNewField = useCallback(
    (field: MdfField) => {
      if (mdf && onChange) {
        const normalized = normalizeField(field);
        const newSettings = getDefaultLayoutSettings(field);

        onChange(
          {
            ...mdf,
            fields: [...mdf.fields, normalized],
            views: {
              ...mdf.views,
              default: [...mdf.views.default, newSettings],
            },
          },
          mdf,
        );
      }
    },
    [mdf, onChange],
  );

  const onDeleteField = useCallback(
    (fieldId: string) => {
      if (mdf && onChange) {
        const fields = mdf.fields ?? [];
        const updatedFields = fields.filter((f) => f.fieldId !== fieldId);

        onChange({ ...mdf, fields: updatedFields }, mdf);
        removeFromPayload(fieldId);
      }
    },
    [mdf, removeFromPayload, onChange],
  );

  const updateLayoutSettings = useCallback(
    (updatedSettings: LayoutSettings, view: ViewTypes) => {
      if (mdf && onChange) {
        const settings = [...mdf.views[view]];
        const index = settings.findIndex((s) => s.fieldId === updatedSettings.fieldId);
        if (index >= 0) {
          settings.splice(index, 1, updatedSettings);
        } else {
          settings.push(updatedSettings);
        }
        onChange({ ...mdf, views: { ...mdf.views, [view]: settings } }, mdf);
      }
    },
    [mdf, onChange],
  );

  const onErrorUpdate = ({ fieldId, error }: { fieldId: string; error: string | undefined }) => {
    setErrorMap((prevState) => ({
      ...prevState,
      [fieldId]: error,
    }));
  };

  const onShowFieldEditor = useCallback(
    (field: MdfField) => {
      if (mdf) {
        showEditFieldDialog({
          startValue: field.defaultValue.value,
          fieldId: field.fieldId,
          headerText: `Edit ${field.fieldId}`,
          viewType: 'default',
          mdf,
          editMode: true,
          onConfirm: (v) => updateField({ ...field, defaultValue: { value: v } }),
        });
      }
    },
    [mdf, showEditFieldDialog, updateField],
  );

  const onShowPermissionDialog = useCallback(
    (fieldId: string) => {
      openPermissionDialog({
        open: true,
        fieldId,
        read: mdf?.permissions.read[fieldId],
        write: mdf?.permissions.write[fieldId],
        setPermissions: updatePermissions,
      });
    },
    [mdf?.permissions.read, mdf?.permissions.write, openPermissionDialog, updatePermissions],
  );

  useEffect(() => {
    if (!open || mdf.id) {
      setFormPayload(getDefaultPayload(mdf));
      setErrorMap({});
    }
  }, [open, mdf.id]);

  const handleTabChange = (label: string) => {
    const selectedTab = tabs.find((t) => t.label === label) as Tab;
    setSelectedView(selectedTab.id);
  };

  return (
    <Wrapper>
      <SplitBar
        split="vertical"
        style={{
          height: '100%',
        }}
        primary="first"
        pane1Style={{
          minWidth: '60%',
          maxWidth: '80%',
        }}
        pane2Style={{
          minWidth: '20%',
          maxWidth: '100%',
        }}
      >
        <ContentWrapper>
          <TabsWrapper>
            <Flex margin="8px">
              <Tabs
                setActiveTab={handleTabChange}
                activeTab={labels[indexes.indexOf(selectedView)]}
                tabs={labels}
              />
            </Flex>
            <ChoiceField
              editorId="editMdf"
              fieldModel={{
                fieldId: 'layout-choice',
                type: FieldTypeEnum.choice,
                defaultValue: { value: null },
                alternatives: [
                  {
                    id: 'order_grid',
                    label: 'Order grid',
                    value: 'order_grid',
                  },
                  {
                    id: 'order_form',
                    label: 'Order form',
                    value: 'order_form',
                  },
                  {
                    id: 'story_create',
                    label: 'Story create',
                    value: 'story_create',
                  },
                  {
                    id: 'story_view',
                    label: 'Story view',
                    value: 'story_view',
                  },
                ],
              }}
              value={selectedView}
              setValue={(val) => setSelectedView(val ? (val as ViewTypes | 'model') : 'model')}
              fieldSettings={null}
              style={{
                width: '180px',
                marginTop: '2px',
              }}
              defaultFieldSettings={{
                fieldId: 'layout-choice',
                label: '',
                hint: '',
                visible: true,
              }}
              errorMessage={undefined}
              setError={() => {}}
              placeholder="Override layout"
            />
          </TabsWrapper>
          <Divider className="horizontal" />
          {mdf && (
            <EditWrapper>
              <Scrollbar
                valueChanged={undefined}
                closeToBottom={undefined}
                top={undefined}
                bottom={undefined}
                dark={undefined}
                showHorizontal={undefined}
              >
                <div>
                  {selectedView === 'model' && (
                    <EditFieldModel
                      fields={mdf?.fields ?? []}
                      onShowEditFieldDialog={onShowFieldEditor}
                      onShowPermissionDialog={onShowPermissionDialog}
                      onUpdateField={updateField}
                      onDeleteField={onDeleteField}
                      onShowConfig={(field) => setConfigField(field)}
                      setFields={setFields}
                      onAddFieldClick={() => setNewFieldOpen(true)}
                    />
                  )}
                  {selectedView !== 'model' && (
                    <EditFieldLayout
                      selectedView={selectedView}
                      fields={mdf.fields}
                      views={mdf.views}
                      onUpdateSettings={updateLayoutSettings}
                    />
                  )}
                </div>
              </Scrollbar>
            </EditWrapper>
          )}
        </ContentWrapper>
        {mdf ? (
          <RightWrapper>
            <RightSubWrapper>
              <RightHeader>
                <Text variant="overline" color="highEmphasis">
                  Previewing {selectedView}
                </Text>
              </RightHeader>
              <Scrollbar
                valueChanged={undefined}
                closeToBottom={undefined}
                top={undefined}
                bottom={undefined}
                dark={undefined}
                showHorizontal={undefined}
              >
                <MdfEditorWrapper>
                  <MdfEditor
                    fields={mdf.fields}
                    defaultLayoutSettings={mdf.views.default}
                    layoutSettings={
                      selectedView === 'model' ? mdf.views.default : mdf.views[selectedView]
                    }
                    updateFieldValue={setPayload}
                    metadata={formPayload}
                    errorMap={errorMap}
                    updateErrorMap={onErrorUpdate}
                    permissions={mdf.permissions}
                  />
                </MdfEditorWrapper>
              </Scrollbar>
            </RightSubWrapper>

            <PayloadWrapper>
              <PayloadTitle>
                <Text variant="overline" color="highEmphasis">
                  Payload JSON
                </Text>
                {errorTooltip && (
                  <Tooltip title={errorTooltip}>
                    <PayloadInfo className="skipOverride" />
                  </Tooltip>
                )}
              </PayloadTitle>
              <Payload>
                <code className="json">{JSON.stringify(formPayload, null, 2)}</code>
              </Payload>
            </PayloadWrapper>
          </RightWrapper>
        ) : (
          <div />
        )}
      </SplitBar>
      <NewFieldDialog
        open={newFieldOpen}
        setOpen={setNewFieldOpen}
        onNewField={addNewField}
        fields={mdf?.fields ?? []}
      />
      <ConfigFieldDialog
        open={Boolean(configField)}
        setOpen={(val: boolean) => {
          if (!val) {
            setConfigField(null);
          }
        }}
        onConfirm={(updatedField) => {
          updateField(updatedField);
          setConfigField(null);
        }}
        fieldToConfig={configField}
      />
    </Wrapper>
  );
}
