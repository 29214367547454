import styled, { StyledComponent } from '@emotion/styled';
import { capitalize } from 'lodash';

import Tooltip from 'components/tooltip';
import { ResourceType } from 'types/forms/forms';

import { CopyIcon, InstanceIcon, RemoveIcon, RundownIcon, SpacesIcon, StoryIcon } from '../styled';

interface Props {
  onClick: (type: ActionType) => void;
  resourceType: ResourceType;
  hideStuff?: boolean;
}

const StyledDiv = styled.div<{ showCopyIcon: boolean }>`
  display: flex;
  gap: 12px;
  position: relative;
  top: 7px;
  width: ${({ showCopyIcon }) => (showCopyIcon ? '104px' : '84px')};
`;

export const IconMap: Record<ResourceType, StyledComponent<React.SVGProps<SVGSVGElement>>> = {
  story: StoryIcon,
  instance: InstanceIcon,
  space: SpacesIcon,
  rundown: RundownIcon,
};

export type ActionType = 'go-to-space' | 'go-to-resource' | 'delete' | 'copy';

function OrderActions({ onClick, resourceType, hideStuff }: Readonly<Props>) {
  const ResourceIcon = IconMap[resourceType];
  const showCopyIcon = false;
  return (
    <StyledDiv showCopyIcon={showCopyIcon}>
      {!hideStuff && (
        <Tooltip title="Go to home space">
          <SpacesIcon onClick={() => onClick('go-to-space')} />
        </Tooltip>
      )}
      <Tooltip title={`Go to ${capitalize(resourceType)}`}>
        <ResourceIcon onClick={() => onClick('go-to-resource')} />
      </Tooltip>
      {showCopyIcon && (
        <Tooltip title="Copy order id">
          <CopyIcon onClick={() => onClick('copy')} />
        </Tooltip>
      )}
      {!hideStuff && (
        <Tooltip title="Delete order">
          <RemoveIcon onClick={() => onClick('delete')} />
        </Tooltip>
      )}
    </StyledDiv>
  );
}

export default OrderActions;
