const getPlatformLabel = (platformName: string, platformKind: string): string => {
  if (platformKind && platformKind !== 'tv') {
    return platformKind;
  }

  if (platformName.toLowerCase() === 'cms') return 'CMS';

  if (platformName === 'twitter') return 'X';

  return platformName;
};

export default getPlatformLabel;
