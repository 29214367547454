import { useQuery } from '@apollo/client';
import { endOfDay, startOfDay } from 'date-fns';

import GET_RUNDOWNS_BY_PUBLISHING_DATE from 'graphql/queries/getRundownByPublishingDate';
import { GetMembersByPublishingDateInput, MemberTypeEnum, Rundown } from 'types/graphqlTypes';

interface Props {
  startDate: string;
  endDate: string;
}

type ReturnType = {
  getRundownsByPublishingDate: Rundown[];
};

type InputType = {
  input: GetMembersByPublishingDateInput;
};

const useGetDailyRundowns = ({ startDate, endDate }: Props) => {
  const now = new Date().toISOString();
  const startTime = startDate || endDate || now;
  const endTime = endDate || startDate || now;

  const variables = {
    input: {
      mType: MemberTypeEnum.Rundown,
      startDate: startOfDay(startTime).toISOString(),
      endDate: endOfDay(endTime).toISOString(),
    },
  };

  const { data, error, loading } = useQuery<ReturnType, InputType>(
    GET_RUNDOWNS_BY_PUBLISHING_DATE,
    {
      variables,
      fetchPolicy: 'cache-and-network',
    },
  );

  return {
    data: data?.getRundownsByPublishingDate,
    error,
    loading,
  };
};

export default useGetDailyRundowns;
