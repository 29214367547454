import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { atom, useAtom } from 'jotai';
import { keyBy } from 'lodash';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import Dialog from 'components/dialogs/DialogBuilder';
import DraggableDialog from 'components/dialogs/Draggable';
import { generateTooltipText } from 'components/editMdfDialog/utils';
import Tooltip from 'components/tooltip';
import { Metadata, NewFieldValue } from 'types/forms/forms';
import { ViewTypes } from 'types/graphqlTypes';

import { MdfEditor } from './MdfEditor';

const HeaderWrapper = styled('div')`
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 380px;
`;

export interface EditMetadata {
  metadata: Metadata;
  mdfId: string | null;
  viewType: ViewTypes;
  headerText: string;
  confirmLabel?: string;
  onConfirm: (updatedMetadata: Metadata, mdfId: string | null) => void;
  onCancel?: () => void;
}

const editMetadataDialog = atom<EditMetadata | null>(null);

export const useEditMetadataDialog = () => useAtom(editMetadataDialog);

export function EditMetadataDialog() {
  const [dimension, setDimension] = useState({
    width: `${window.innerWidth < 300 ? window.innerWidth : '350'}`,
    height: `${window.innerHeight < 600 ? window.innerHeight : '600'}`,
  });
  const { mdfs } = useGetMdfs({ all: true });
  const [open, setOpen] = useState(false);
  const [state, setState] = useEditMetadataDialog();
  const [formPayload, setFormPayload] = useState<Metadata>({});
  const [errorMap, setErrorMap] = useState<Record<string, string | undefined>>({});
  const [mdfId, setMdfId] = useState<string | null>(null);
  const mdfMap = useMemo(() => {
    return keyBy(mdfs, (mdf) => mdf.id);
  }, [mdfs]);

  const mdf = mdfId ? mdfMap[mdfId] : null;

  const setPayload = useCallback(
    (p: NewFieldValue) => {
      setFormPayload((prevState) => ({
        ...prevState,
        [p.fieldId]: p.value,
      }));
    },
    [setFormPayload],
  );
  const onErrorUpdate = ({ fieldId, error }: { fieldId: string; error: string | undefined }) => {
    setErrorMap((prevState) => ({
      ...prevState,
      [fieldId]: error,
    }));
  };

  useEffect(() => {
    if (state === null) {
      setOpen(false);
    } else {
      setOpen(true);
      setFormPayload(state.metadata);
      setMdfId(state.mdfId);
    }
  }, [state]);

  useEffect(() => {
    setErrorMap({});
  }, [mdfId]);

  const onCancel = () => {
    if (state?.onCancel) {
      state.onCancel();
    }
    setFormPayload({});
    setErrorMap({});
    setState(null);
  };

  const onConfirm = () => {
    if (state?.onConfirm) {
      state.onConfirm(formPayload, mdfId);
    }
    setOpen(false);
    onCancel();
  };

  const errorTooltip = useMemo(() => {
    if (!state || !mdf) return undefined;
    const labelMap: Record<string, string> = {};
    for (const field of mdf.fields) {
      const layoutSettings = mdf.views[state.viewType] ?? mdf.views.default;
      labelMap[field.fieldId] =
        layoutSettings.find((s) => s.fieldId === field.fieldId)?.label ?? field.fieldId;
    }
    const errors = generateTooltipText(errorMap, labelMap);
    if (!errors.length) return undefined;
    return (
      <>
        <div>Please resolve errors:</div>
        <ul style={{ padding: '0 0 0 20px' }}>
          {errors.map((err) => (
            <li key={err}>{err}</li>
          ))}
        </ul>
      </>
    );
  }, [errorMap, state]);

  return (
    <DraggableDialog
      open={open}
      onClose={() => setOpen(false)}
      initialPosition={{
        x: 400,
        y: 100,
      }}
      minWidth={300}
      minHeight={300}
      dimension={dimension}
      setDimension={setDimension}
    >
      <Dialog.Header className="dragHandler">
        <Tooltip title={state?.headerText ?? ''}>
          <HeaderWrapper>{state?.headerText}</HeaderWrapper>
        </Tooltip>
      </Dialog.Header>
      <Dialog.Body bodyHeight="calc(100% - 84px)" overflow="auto">
        {state && (
          <>
            {mdf && (
              <MdfEditor
                fields={mdf.fields}
                autoFocus={true}
                defaultLayoutSettings={mdf.views.default}
                layoutSettings={mdf.views[state.viewType]}
                permissions={mdf.permissions}
                metadata={formPayload}
                updateFieldValue={setPayload}
                errorMap={errorMap}
                updateErrorMap={onErrorUpdate}
                fieldEditMode={false}
              />
            )}
          </>
        )}
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton onCancel={onCancel} />
        <Tooltip title={errorTooltip ?? ''}>
          <span>
            <Dialog.ConfirmButton
              label={state?.confirmLabel ?? 'Confirm'}
              onClick={onConfirm}
              disabled={Boolean(errorTooltip)}
            />
          </span>
        </Tooltip>
      </Dialog.Footer>
    </DraggableDialog>
  );
}
