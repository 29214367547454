import { useContext, useId, useMemo } from 'react';
import { Dictionary, keyBy } from 'lodash';

import UserContext from 'contexts/UserContext';
import { Metadata, NewFieldValue } from 'types/forms/forms';
import { LayoutSettings, MdfField, MdfPermission } from 'types/graphqlTypes';

import { ErrorField } from './fields/error/ErrorField';
import { FieldMap } from './fields/fields';
import TooltipWrapper from './TooltipWrapper';

import { Wrapper } from './styled';

const shouldFilterField = (
  field: MdfField,
  defaultSettings: Dictionary<LayoutSettings>,
  overrides: Dictionary<LayoutSettings>,
  forceVisible?: boolean,
  canRead?: boolean,
) => {
  if (forceVisible) return true;
  const settings = overrides[field.fieldId] ?? defaultSettings[field.fieldId];
  return (settings.visible ?? true) && canRead;
};

const hasPermission = (permissions: string[], groups: string[]): boolean => {
  if (!permissions) return true;
  if (permissions.length === 0) return false;
  if (groups.length === 0) return false;
  return groups.some((value) => permissions.includes(value));
};

export interface EditorProps {
  fields: MdfField[];
  defaultLayoutSettings: LayoutSettings[];
  layoutSettings: LayoutSettings[];
  metadata: Metadata;
  permissions: MdfPermission;
  updateFieldValue: (value: NewFieldValue) => void;
  style?: Record<string, string>;
  errorMap?: Record<string, string | undefined>;
  updateErrorMap?: (value: { fieldId: string; error: string | undefined }) => void;

  // Do not apply default value configured in model. Useful for search workflow.
  ignoreDefaultValue?: boolean;

  // Force date fields to select a range instead of single date
  forceDateRange?: boolean;

  // Will fire field updates on each change rather than on blur
  fireOnChange?: boolean;

  // Will attempt to autofocus the first field
  autoFocus?: boolean;

  // Indicates there is more vertical space to work with
  moreVerticalSpace?: boolean;
  // If provided, certain workflows are disabled, such as visibility/required.
  // Should only be used in default value editing workflow
  fieldEditMode?: boolean;

  // If provided, all fields are readonly.
  readonly?: boolean;
}

export function MdfEditor({
  fields,
  layoutSettings,
  defaultLayoutSettings,
  metadata,
  permissions,
  updateFieldValue,
  style,
  fireOnChange,
  errorMap,
  updateErrorMap,
  forceDateRange,
  moreVerticalSpace,
  autoFocus,
  fieldEditMode,
  ignoreDefaultValue,
  readonly,
}: Readonly<EditorProps>) {
  const uniqueId = useId();
  const { groups } = useContext(UserContext);
  const settingsMap = useMemo(() => {
    return keyBy(layoutSettings, (setting) => setting.fieldId);
  }, [layoutSettings]);

  const defaultSettingsMap = useMemo(() => {
    return keyBy(defaultLayoutSettings, (setting) => setting.fieldId);
  }, [defaultLayoutSettings]);

  const visibleFields = useMemo(
    () =>
      fields.filter((f) =>
        shouldFilterField(
          f,
          defaultSettingsMap,
          settingsMap,
          fieldEditMode,
          hasPermission(permissions.read[f.fieldId], groups),
        ),
      ),
    [fields, defaultSettingsMap, settingsMap, fieldEditMode, permissions.read, groups],
  );

  if (!visibleFields?.length) {
    return null;
  }

  const onErrorUpdate = (err: string | undefined, id: string) => {
    if (updateErrorMap && !fieldEditMode) {
      updateErrorMap({ fieldId: id, error: err });
    }
  };

  return (
    <Wrapper style={style}>
      {visibleFields.map((field, i) => {
        const Field = FieldMap[field.type];
        const disableEdit = !hasPermission(permissions.write[field.fieldId], groups);
        if (!Field) {
          return <ErrorField type={field?.type} style={{}} key={field.fieldId} />;
        }
        return (
          <TooltipWrapper
            key={`${uniqueId}-${field.fieldId}`}
            readonly={readonly}
            disableEdit={disableEdit}
          >
            <Field
              style={{}}
              editorId={uniqueId}
              ignoreDefaultValue={ignoreDefaultValue}
              forceDateRange={forceDateRange}
              fireOnChange={fireOnChange}
              autoFocus={autoFocus && i === 0}
              fieldModel={field}
              errorMessage={errorMap ? errorMap[field.fieldId] : undefined}
              setError={(err) => onErrorUpdate(err, field.fieldId)}
              fieldSettings={settingsMap[field.fieldId]}
              defaultFieldSettings={defaultSettingsMap[field.fieldId]}
              value={metadata[field.fieldId]}
              setValue={(val) => {
                if (!readonly && !disableEdit)
                  updateFieldValue({ value: val, fieldId: field.fieldId });
              }}
              moreVerticalSpace={moreVerticalSpace}
              disableEdit={readonly ?? disableEdit}
            />
          </TooltipWrapper>
        );
      })}
    </Wrapper>
  );
}
