import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';

import { ReactComponent as InfoIcon } from 'assets/icons/systemicons/info_off.svg';

const iconStyles = ({ type, theme }) => css`
  width: 24px;
  height: 24px;
  margin: 8px;
  path {
    fill: ${type === 'media'
      ? theme.palette.dina.blackMediumEmphasis
      : theme.palette.dina.iconActive};
  }
`;

export const InfoIconComponent = styled(InfoIcon)`
  ${iconStyles};
`;

export const ScriptContent = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  gap: 0.5rem;
`;

export const CollapsedScriptContent = styled(ScriptContent)`
  gap: 0;
  ${({ theme }) => theme.typography.dina.listItemLabel}
`;

export const ContentTop = styled('div')`
  height: 40px;
  display: flex;
  flex: 1;
  gap: 0.5rem;
  align-items: center;
`;

export const CollapsedContentTop = styled(ContentTop)`
  height: auto;
`;

export const ScriptDescriptionWrapper = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  height: 40px;
`;

export const CollapsedScriptDescription = styled(ScriptDescriptionWrapper)`
  height: auto;
`;

export const ScriptTitle = styled('div')`
  ${({ theme }) => css`
    color: ${theme.palette.dina.statusWarning};
    background: ${theme.palette.dina.inputBackground};
    border-bottom: 2px solid ${theme.palette.dina.inputBorderResting};
  `}
  height: 100%;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
`;

export const MediaInputContainer = styled('div')`
  flex: 1;
  height: 100%;
  border-radius: 2px;
  display: flex;
  align-items: center;
`;

export const TimeInputContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
`;
