import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: '40px',
  },
  btnRoot: {
    width: '100%',
    padding: '0px',
  },
  link: {
    width: '28px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  linkCounter: {
    ...theme.typography.dina.captionSmall,
  },
  selectIcon: {
    color: theme.palette.dina.iconInactive,
    height: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default useStyles;
