import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  headerWrapper: {
    height: 40,
  },
  bodyWrapper: {
    flex: 1,
  },
}));

export default useStyles;
