import { FC, ReactElement, useState } from 'react';

import { Button } from 'components/buttons';

import { ButtonGroupWrapper, Description, IconWrapper, WarningIcon, Wrapper } from './styled';

interface WarningProps {
  functionalButtonLabel?: string;
  warningText?: string;
  cancelButtonLabel?: string;
  functionalButtonCallback?: () => void;
  cancelButtonCallback?: () => void;
  IconComponent?: FC;
}

const Warning: FC<WarningProps> = ({
  functionalButtonLabel = 'Clear filters & show Instances',
  warningText = 'Warning',
  cancelButtonLabel = 'Cancel',
  functionalButtonCallback,
  cancelButtonCallback,
  IconComponent = WarningIcon,
}): ReactElement => {
  const [open, setOpen] = useState<boolean>(true);

  const handleButtonClick = (callback: (() => void) | undefined): void => {
    setOpen(false);
    callback?.();
  };

  return (
    <Wrapper $show={open}>
      <IconWrapper>
        <IconComponent className="skipOverride" />
      </IconWrapper>
      <Description>{warningText}</Description>
      <ButtonGroupWrapper>
        <Button
          variant="contained"
          usage="outlined"
          onClick={() => handleButtonClick(cancelButtonCallback)}
        >
          {cancelButtonLabel}
        </Button>
        <Button
          variant="outlined"
          usage="cta"
          onClick={() => handleButtonClick(functionalButtonCallback)}
        >
          {functionalButtonLabel}
        </Button>
      </ButtonGroupWrapper>
    </Wrapper>
  );
};

export default Warning;
