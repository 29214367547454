const conversationTypes = {
  DIRECT: 'direct',
  SPACE: 'space',
  GROUP: 'group',
  CHANNEL: 'channel',
  ALL: 'all',
  TEAM: 'team',
  DEPARTMENT: 'department',
  STORY: 'story',
  PITCH: 'pitch',
  INSTANCE: 'instance',
  RUNDOWN: 'rundown',
  NOTIFICATION: 'notification',
  ASSIGNMENT: 'assignment',
  MENTION: 'mention',
};

export default conversationTypes;
