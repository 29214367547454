import React, { memo } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useSlate } from 'slate-react';

import { markTypes } from 'components/editor/constants';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import { insertScript } from 'components/editor/components/scriptInfo/utils/onScriptKeyDown';
import { SCRIPTS } from 'components/editor/components/scriptInfo/utils/constants';

import Divider from 'components/divider';
import Button from '@material-ui/core/Button';
import MarkButton from '../markButton';
import Wrapper from '../wrapper';

const StyledDivider = styled(Divider)`
  height: 24px;
  width: 1px;
`;

const AddScriptButton = styled(Button)`
  ${({ theme }) => css`
    ${theme.typography.dina.button}
    color: ${theme.palette.dina.mediumEmphasis};
    background: ${theme.palette.dina.backgroundResting};
    border: 1px solid ${theme.palette.dina.buttonBorderOutlined};
  `};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border-radius: 6px;
`;

const { BOLD, ITALIC, UNDERLINE, STRIKE_THROUGH } = markTypes;

const ScriptToolbar = () => {
  const editor = useSlate();
  const { update } = useEditorContext();

  const onAddScript = () => {
    insertScript(editor, SCRIPTS[0], update);
  };

  return (
    <Wrapper showRightMargin>
      <MarkButton type={BOLD} />
      <MarkButton type={ITALIC} />
      <MarkButton type={UNDERLINE} />
      <MarkButton type={STRIKE_THROUGH} />
      <StyledDivider />
      <AddScriptButton variant="outlined" size="small" onClick={onAddScript}>
        [ ] Add Script Block ([)
      </AddScriptButton>
    </Wrapper>
  );
};

export default memo(ScriptToolbar);
