import variants from 'utils/instance/variants';

const validVariants = [
  variants.LINEAR,
  variants.TWITTER,
  variants.CMS,
  variants.YOUTUBE,
  variants.FACEBOOK,
  variants.INSTAGRAM,
  variants.TIKTOK,
  variants.LINKEDIN,
];

/**
 * Return variant name if specified on validVariants, otherwise returns general variant
 *
 * @param {String} variant name of the variant i.e. 'cms', 'linear' etc.
 * @returns {String} if exists in validVariant list, returns the variant or returns general
 */

const getVariant = (variant) => {
  if (validVariants.includes(variant)) return variant;
  return variants.GENERAL;
};

export default getVariant;
