import PropTypes from 'prop-types';

import { StyledIconButton } from './styled';
import { ButtonWrapper } from './ButtonWrapper';

export function IconButton({
  dark,
  ariaLabel,
  children,
  className,
  disabled,
  iconHeight,
  iconWidth,
  iconSize,
  onClick,
  selected,
  title,
  disabledTitle,
  required,
  round,
  style,
  type,
  usage,
  variant,
  noBorder,
  width,
  height,
  size,
  tabIndex,
  onFocus,
  disableEnhancedIconOpacity,
  dataTestId,
  borderRadius,
}) {
  const handleClick = (e) => {
    if (!disabled) onClick(e);
  };

  return (
    <ButtonWrapper title={title} disabledTitle={disabledTitle} disabled={disabled}>
      <StyledIconButton
        // accessability
        aria-label={ariaLabel}
        // functionality
        disabled={disabled}
        onClick={handleClick}
        type={type}
        // styling
        className={className}
        iconHeight={iconHeight ?? iconSize}
        iconWidth={iconWidth ?? iconSize}
        usage={usage}
        required={required}
        round={round}
        selected={selected}
        style={style}
        variant={variant}
        noBorder={noBorder}
        height={height ?? size}
        width={width ?? size}
        tabIndex={tabIndex}
        onFocus={onFocus}
        disableEnhancedIconOpacity={disableEnhancedIconOpacity}
        data-testid={dataTestId}
        dark={dark}
        borderRadius={borderRadius}
      >
        {children}
      </StyledIconButton>
    </ButtonWrapper>
  );
}

IconButton.propTypes = {
  /** Accessability label, most useful when there is no text (only icon etc.) */
  ariaLabel: PropTypes.string,
  /**  Children */
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string]).isRequired,
  /** custom css classes */
  className: PropTypes.string,
  /** Can't be pressed when disabled */
  disabled: PropTypes.bool,
  /** height of the icon in px */
  iconHeight: PropTypes.number,
  /** width of the icon in px */
  iconWidth: PropTypes.number,
  /** when iconHeight and iconWhdth are same we can provide iconSize instead */
  iconSize: PropTypes.number,
  /** onClick callback when button is clicked */
  onClick: PropTypes.func,
  /** Required will have a orange border */
  required: PropTypes.bool,
  /** Will have a round border when true */
  round: PropTypes.bool,
  /** Will be blue when selected */
  selected: PropTypes.bool,
  /** Title of button, will show on hover */
  title: PropTypes.string,
  /** Title of button, when the button is disabled and hovered */
  disabledTitle: PropTypes.string,
  /** Type of button, defaults to button */
  type: PropTypes.string,
  /** Color options, have an impact on the buttons color scheme  */
  usage: PropTypes.oneOf([
    'contained',
    'cta',
    'danger',
    'outlined',
    'pitch',
    'significant',
    'story',
    'text',
    'warning',
  ]),
  /** Will determine how the border looks  */
  variant: PropTypes.oneOf(['contained', 'discreet', 'outlined', 'dashed', 'warning']),
  /** Will show no border in normal and hover state */
  noBorder: PropTypes.bool,
  /** width of the button in px */
  width: PropTypes.number,
  /** height of the button in px */
  height: PropTypes.number,
  /** when height and width are same we can provide size instead  */
  size: PropTypes.number,
  /** Component style override */
  style: PropTypes.object,
  /** Tab Index of the button */
  tabIndex: PropTypes.number,
  /** if true, then don't change the fill-opacity of the svg icon */
  disableEnhancedIconOpacity: PropTypes.bool,
  /** used for test */
  dataTestId: PropTypes.string,
  /** if true force dark mode */
  dark: PropTypes.bool,
  /** */
  borderRadius: PropTypes.string,
};

IconButton.defaultProps = {
  ariaLabel: null,
  className: null,
  disabled: false,
  iconHeight: null,
  iconWidth: null,
  onClick: () => {},
  onFocus: () => {},
  required: false,
  round: false,
  selected: false,
  type: 'button',
  usage: 'cta',
  variant: 'contained',
  height: null,
  width: null,
  noBorder: false,
  style: null,
  title: undefined,
  disabledTitle: undefined,
  tabIndex: 0,
  disableEnhancedIconOpacity: false,
  dataTestId: undefined,
  dark: false,
  borderRadius: '6px',
};

export default IconButton;
