import { useMemo, useState } from 'react';

import { MdfEditor } from 'components/mdfEditor/MdfEditor';
import { Metadata, NewFieldValue } from 'types/forms/forms';
import { Mdf } from 'types/graphqlTypes';

const getParsedMD = (val: string) => {
  try {
    return JSON.parse(val ?? '{}') as Metadata;
  } catch (err) {
    return null;
  }
};

interface MetadataViewProps {
  mdf: Mdf;
  data: Record<string, string>;
  editDisabled?: boolean;
  onUpdate: (metadata: string) => void;
  errorMap: Record<string, string | undefined>;
  onErrorUpdate: ({ fieldId, error }: { fieldId: string; error: string | undefined }) => void;
}

function MetadataView({
  data,
  mdf,
  editDisabled = false,
  errorMap,
  onUpdate,
  onErrorUpdate,
}: Readonly<MetadataViewProps>) {
  const [metadata, setMetadata] = useState<Metadata>();

  const parsedMetadata = useMemo(() => {
    return getParsedMD(data.metadata) ?? {};
  }, [data.mdfId, data.metadata]);

  const updateFieldValue = (val: NewFieldValue) => {
    const newMetadata = metadata
      ? { ...metadata, [val.fieldId]: val.value }
      : { ...parsedMetadata, [val.fieldId]: val.value };
    setMetadata(newMetadata);
    onUpdate(JSON.stringify(newMetadata));
  };

  return (
    <MdfEditor
      errorMap={errorMap}
      updateErrorMap={onErrorUpdate}
      fields={mdf.fields}
      moreVerticalSpace={true}
      defaultLayoutSettings={mdf.views.default}
      layoutSettings={mdf?.views.contact_view}
      metadata={metadata ?? parsedMetadata}
      permissions={mdf.permissions}
      readonly={editDisabled}
      updateFieldValue={(fieldValue: NewFieldValue) => updateFieldValue(fieldValue)}
    />
  );
}

export default MetadataView;
