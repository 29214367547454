import { useState } from 'react';
import { useMutation } from '@apollo/client';

import GET_METADATA_FORMS from 'graphql/queries/getMetadataForms';
import UPDATE_FORM from 'graphql/mutations/updateForm';
import useLogger from 'utils/useLogger';

import { useChangedLinkFields, useLinkFields } from '../atoms';

const transformChangedMetadataFields = (changedLinkFields) =>
  changedLinkFields.map((changedField) => {
    const [id, attribute] = changedField?.key?.split(':');
    return { id, [attribute]: changedField.value };
  });

const getUpdatedLinkFields = (changedLinkFields, fields) => {
  const updatedFields = transformChangedMetadataFields(changedLinkFields);

  return fields.map((field) =>
    updatedFields.reduce((acc, cur) => {
      if (acc.id === cur.id) return { ...acc, ...cur };
      return acc;
    }, field),
  );
};

export const useUpdateLinks = () => {
  const [changedLinkFields] = useChangedLinkFields();
  const [updateForm] = useMutation(UPDATE_FORM);
  const [loading, setLoading] = useState(false);
  const [linkFields] = useLinkFields();
  const logger = useLogger('useUpdateLinks');

  const updateLinkFields = async () => {
    const updatedFields = getUpdatedLinkFields(changedLinkFields, linkFields);
    const mProperties = { __typename: 'MetadataFormType', fields: updatedFields };
    setLoading(true);

    const promise = await updateForm({
      variables: {
        input: {
          mId: 'links',
          mRefId: 'links',
          mProperties,
        },
      },
      update: (proxy, mutationResult) => {
        try {
          const { updateForm: updatedForm } = mutationResult?.data;
          proxy.writeQuery({
            query: GET_METADATA_FORMS,
            variables: {
              input: {
                mId: 'links',
                mRefId: 'links',
              },
            },

            data: { getMetadataForms: [updatedForm] },
          });
        } catch (error) {
          logger.error('useUpdateLinks error: ', error);
        }
      },
    });
    setLoading(false);
    return promise;
  };

  return [updateLinkFields, loading];
};

export default useUpdateLinks;
