// eslint-disable-next-line sort-imports
import React, { memo, SyntheticEvent, useCallback, useState } from 'react';
import Picker from '@emoji-mart/react';
import { ClickAwayListener, PopperPlacementType } from '@material-ui/core';
import { Editor } from 'slate';
import { ReactEditor, useFocused, useSlate } from 'slate-react';

import { getSelectedElement } from 'components/editor/utils';
import { useDinaTheme, useEmojiData } from 'store';
import { EmojiType } from 'types/editor';
import preventDefaultAndStopPropagation from 'utils/preventDefaultAndStopPropagation';

import { Arrow, EmojiButton, EmojiIcon, StyledPopper } from './styled';

const isSelected = (editor: Editor): boolean => !!getSelectedElement(editor);

interface EmojiPickerProps {
  pickerPlacement: PopperPlacementType;
}

function EmojiPicker({ pickerPlacement = 'bottom' }: EmojiPickerProps) {
  const [emojiData] = useEmojiData();
  const [theme] = useDinaTheme();
  const editor = useSlate();
  const isFocused = useFocused();
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLDivElement) | null>(null);

  const openPopper = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      preventDefaultAndStopPropagation(event);
      if (isSelected(editor)) setAnchorEl(event.currentTarget);
    },
    [editor],
  );

  const closePopper = useCallback(
    (event: SyntheticEvent) => {
      preventDefaultAndStopPropagation(event);
      setAnchorEl(null);
    },
    [editor],
  );

  const onEmojiSelect = useCallback(
    (emoji: EmojiType, event: React.PointerEvent) => {
      closePopper(event);
      if (emoji.native) editor.insertText(emoji.native);
      ReactEditor.focus(editor as ReactEditor);
    },
    [editor],
  );

  const onClickAway = useCallback(
    (event: React.MouseEvent<Document, MouseEvent>) => {
      if (anchorEl && !event.nativeEvent) closePopper(event as unknown as SyntheticEvent);
    },
    [anchorEl],
  );

  return (
    <>
      <ClickAwayListener onClickAway={onClickAway}>
        <EmojiButton $disabled={!isFocused} role="presentation" onMouseDown={openPopper}>
          <EmojiIcon className="skipOverride" />
          <Arrow className="skipOverride" />
        </EmojiButton>
      </ClickAwayListener>
      <StyledPopper
        id="emoji-popper"
        placement={pickerPlacement}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
      >
        <Picker
          autoFocus
          data={emojiData}
          theme={theme === 'system' ? 'auto' : theme}
          skinTonePosition="search"
          perLine={8}
          onEmojiSelect={onEmojiSelect}
        />
      </StyledPopper>
    </>
  );
}

export default memo(EmojiPicker);
