import { Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import { Asset, CustomData, CustomEditor, CustomElement } from 'types';

/**
 * Updates editor -> element's data attribute.
 *
 * @param editor SlateJS editor instance
 * @param element SlateJS element
 * @param data Data properties of the SlateJS element
 * @param newAssetData - getQuery Asset Data
 *
 *
 * @returns SlateJS editor instance
 *
 */

const syncAssetWithSlate = (
  editor: CustomEditor,
  element: CustomElement,
  data: CustomData,
  newAssetData: Asset,
) => {
  if (!newAssetData) return;

  const assetFromMetadata: Record<string, string | undefined> = {};

  newAssetData.mMetaData?.forEach(({ key, value }) => {
    if (['mediaSize', 'mediaDuration', 'provider'].includes(key)) assetFromMetadata[key] = value;
  });

  const updatedAsset =
    data?.assets?.map((asset) =>
      (asset.mAssetId && asset.mAssetId === newAssetData.mAssetId) ||
      (asset.mId === newAssetData.mId && asset.mRefId === newAssetData.mRefId)
        ? {
            ...asset,
            assetType: asset.itemType,
            itemDuration: assetFromMetadata.mediaDuration,
            mediaType: newAssetData.mediaType,
            mTitle: newAssetData.mTitle,
            thumbnailUrl: newAssetData.mThumbnailUrl,
            proxy: newAssetData.mContentUrl,
            mAssetId: newAssetData.mAssetId,
            mProvider: asset?.mProvider ?? assetFromMetadata?.provider,
          }
        : asset,
    ) ?? [];

  const updatedData = {
    ...data,
    assets: updatedAsset ?? [],
  };
  const path = ReactEditor.findPath(editor, element);
  Transforms.setNodes(editor, { data: updatedData }, { at: path });
};

export default syncAssetWithSlate;
