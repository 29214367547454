import { SearchItemTypeEnum } from 'types/graphqlTypes';

export const planningViews = {
  STATUS: 'Status',
  TIMELINE: 'Hours',
  DAY: 'Day',
  WEEK: 'Week',
  MONTH: 'Month',
} as const;

export const timeVariants = {
  DAY: 'date',
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year',
} as const;

export const sortVariants = {
  PUBLISHING_DATE: 'mPublishingAt',
  CREATED_DATE: 'mCreatedAt',
  UPDATED_DATE: 'mUpdatedAt',
} as const;

export const planningStateTypes = {
  VIEW: 'view',
  TIME: 'time',
  TAB_INDEX: 'tabIndex',
  TIME_VARIANT: 'timeVariant',
  SAVED_FILTERS: 'savedFilters',
  STATE_COLLAPSED: 'stateCollapsed',
  SHOW_SCHEDULED: 'showScheduled',
  STATES_VIEW: 'statesView',
  CURRENT_FILTER: 'currentFilter',
  SHOW_STORY: 'showStory',
  SHOW_PITCH: 'showPitch',
  SORT_CRITERIA: 'sortCriteria',
} as const;

export type PlanningView = (typeof planningViews)[keyof typeof planningViews];
export type TimeVariant = (typeof timeVariants)[keyof typeof timeVariants];

export type Tab = {
  index: number;
  label: PlanningView;
  timeVariant: TimeVariant;
  mTypes: SearchItemTypeEnum[];
  initialMTypes: SearchItemTypeEnum[];
  chunkSize?: number;
  disableMDF?: boolean;
};

export const allSupportedMTypes = [
  SearchItemTypeEnum.instance,
  SearchItemTypeEnum.story,
  SearchItemTypeEnum.pitch,
  SearchItemTypeEnum.rundown,
  SearchItemTypeEnum.draft,
];

export const defaultTabs: Tab[] = [
  {
    index: 0,
    label: planningViews.STATUS,
    timeVariant: timeVariants.DAY,
    mTypes: [SearchItemTypeEnum.instance],
    initialMTypes: [SearchItemTypeEnum.instance],
    disableMDF: true,
  },
  {
    index: 1,
    label: planningViews.TIMELINE,
    timeVariant: timeVariants.DAY,
    mTypes: [
      SearchItemTypeEnum.instance,
      SearchItemTypeEnum.story,
      SearchItemTypeEnum.pitch,
      SearchItemTypeEnum.rundown,
    ],
    initialMTypes: [
      SearchItemTypeEnum.instance,
      SearchItemTypeEnum.story,
      SearchItemTypeEnum.pitch,
      SearchItemTypeEnum.rundown,
    ],
    chunkSize: 1000,
  },
  {
    index: 2,
    label: planningViews.DAY,
    timeVariant: timeVariants.DAY,
    mTypes: allSupportedMTypes,
    initialMTypes: [SearchItemTypeEnum.story, SearchItemTypeEnum.pitch],
  },
  {
    index: 3,
    label: planningViews.WEEK,
    timeVariant: timeVariants.WEEK,
    mTypes: allSupportedMTypes,
    initialMTypes: [SearchItemTypeEnum.story, SearchItemTypeEnum.pitch],
    chunkSize: 1000,
  },
  {
    index: 4,
    label: planningViews.MONTH,
    timeVariant: timeVariants.MONTH,
    mTypes: allSupportedMTypes,
    initialMTypes: [SearchItemTypeEnum.story, SearchItemTypeEnum.pitch],
    chunkSize: 1000,
  },
];
