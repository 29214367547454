/* eslint-disable sort-imports */
import { useId } from 'react';
import { ScopeProvider } from 'jotai-molecules';

import { Box } from 'layouts/box/Box';
import {
  ContentActionProps,
  Dialog as DialogPrimitive,
  DialogContent,
  DialogFooter as DialogFooterPrimitive,
  DialogHeader as DialogHeaderPrimitive,
  DialogProps,
  DialogTitle,
} from 'lib/dialog';

import {
  DialogCancelButton,
  DialogCheckbox,
  DialogConfirmButton,
  DialogDivider,
  DialogGroup,
  DialogInputField,
  DialogLabel,
  DialogRadio,
  DialogSectionTitle,
  DialogSelect,
  DialogTable,
  DialogTwoStepButton,
} from './components/Parts';
import { StateWrapper } from './components/StateWrapper';
import { DialogDataModel, DialogScope } from './atoms';

type CommonDialogProps = {
  children: React.ReactNode;
  className?: string;
  showCloseIcon?: boolean;
  bodyHeight?: string;
  overflow?: 'hidden' | 'inherit' | 'auto' | 'scroll';
};
interface Props extends DialogProps, ContentActionProps {
  style?: React.CSSProperties;
  /** Use when controlled */
  onClose?: () => void;
  className?: string;
  initialState?: DialogDataModel;
}

function DialogBuilder({
  children,
  style,
  onClose,
  className,
  onInteractOutside,
  onPointerDownOutside,
  initialState,
  modal,
  ...rest
}: Readonly<Props>) {
  const randomId = useId();

  /** If controlled: call onClose() */
  const handleOpenChange = (state: boolean) => {
    if (!state) onClose?.();
  };

  return (
    <DialogPrimitive {...rest} modal={modal} onOpenChange={handleOpenChange} key={randomId}>
      <ScopeProvider scope={DialogScope} value={randomId}>
        <StateWrapper initialState={initialState}>
          <DialogContent
            modal={modal}
            className={className}
            style={style}
            onInteractOutside={onInteractOutside}
            onPointerDownOutside={onPointerDownOutside}
          >
            {children}
          </DialogContent>
        </StateWrapper>
      </ScopeProvider>
    </DialogPrimitive>
  );
}

const DialogHeader = ({ children, className, showCloseIcon }: Readonly<CommonDialogProps>) => {
  return (
    <DialogHeaderPrimitive showCloseIcon={showCloseIcon} className={className}>
      <DialogTitle>{children}</DialogTitle>
    </DialogHeaderPrimitive>
  );
};

const DialogBody = ({ children, className, bodyHeight, overflow }: Readonly<CommonDialogProps>) => {
  return (
    <Box
      className={className}
      padding="12px"
      minHeight="88px"
      height={bodyHeight}
      overflow={overflow ?? 'inherit'}
    >
      {children}
    </Box>
  );
};

const DialogFooter = ({ children, ...rest }: Readonly<CommonDialogProps>) => {
  return <DialogFooterPrimitive {...rest}>{children}</DialogFooterPrimitive>;
};

const DialogNamespace = Object.assign(DialogBuilder, {
  Header: DialogHeader,
  Body: DialogBody,
  Footer: DialogFooter,
  InputField: DialogInputField,
  Select: DialogSelect,
  CancelButton: DialogCancelButton,
  ConfirmButton: DialogConfirmButton,
  TwoStepButton: DialogTwoStepButton,
  SectionTitle: DialogSectionTitle,
  Divider: DialogDivider,
  Radio: DialogRadio,
  Checkbox: DialogCheckbox,
  Table: DialogTable,
  Label: DialogLabel,
  Group: DialogGroup,
});

export type { DialogDataModel };
export default DialogNamespace;
