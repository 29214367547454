import styled from '@emotion/styled';
import { css } from '@emotion/react';
import transientOptions from 'theme/helpers';

export const StyledNotificationIcon = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;

export const StyledChatEnabled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${({ theme }) => css`
    svg {
      width: 24px;
      height: 32px;
      path {
        fill: ${theme.palette.dina.whiteHighEmphasis};
        fill-opacity: ${theme.palette.mode === 'light' ? 0.65 : 0.54};
      }
    }
  `}
  &:hover {
    svg {
      path {
        fill-opacity: 1;
      }
    }
  }
`;

export const NotificationIndicatorWrapper = styled.div`
  position: absolute;
  left: 14px;
  bottom: 13px;
  pointer-events: none;
`;

export const MessagesWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.dina.surfaceCardDark};
  border-radius: 8px;
  overflow: hidden;
  display: flex;
`;

export const MessageHubInnerRootWrapper = styled('div')`
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const StyledBody = styled('div')`
  display: flex;
  flex-grow: 1;
  overflow: hidden;
`;

export const StyledNewMessageContainer = styled('div', transientOptions)`
  height: ${({ $height }) => `${$height}px`};
  width: ${({ $width }) => `calc(${$width}px - 64px)`};
`;
