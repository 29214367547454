import { lazy, Suspense, useRef } from 'react';

import LoadingIndicator from 'components/loadingIndicator';
import memberTypes from 'graphql/memberTypes';
import { BlockGrid } from 'screens/story/components/block/BlockGrid';
import { StoryMetadata } from 'screens/story/components/metadata/StoryMetadata';
import { StoryTab, storyTabs } from 'store';
import { GetOrderEnum, MemberTypeEnum } from 'types/graphqlTypes';

import { useStoryMolecule } from './store/story';
import { useStoryPaneMolecule } from './store/storyPane';
import { pitchTypesSet } from './utils/typeSets';
import Tabs from './Tabs';
import usePanes from './useStoryPanes';

import { ContentContainer, TabsContent, TabsRoot } from './styled';

const Content = lazy(() => import('./Content'));
const Notes = lazy(() => import('features/notes'));
const Instances = lazy(() => import('./Instances'));
const Assets = lazy(() => import('features/assets/Assets'));
const MapView = lazy(() => import('components/map'));
const Resources = lazy(() => import('features/resources'));
const Booking = lazy(() => import('features/schedule'));
const OrderGridFull = lazy(() => import('features/orderForm/components/OrderGridFull'));

const NotesTS = Notes as unknown as React.JSXElementConstructor<{
  storyId: string;
  hostReadSpeed: number;
  disableUpdate: boolean;
  hideCreateButton: boolean;
  ref: React.MutableRefObject<undefined>;
}>;

interface Props {
  pane: {
    tab: string;
    tId?: string;
  };
}

function StoryTabs({ pane }: Readonly<Props>) {
  const { useStory, useHostReadSpeedValue, useCanUpdateStoryValue } = useStoryMolecule();
  const { usePaneIndexValue } = useStoryPaneMolecule();
  const [story] = useStory();
  const hostReadSpeed = useHostReadSpeedValue();
  const canUpdate = useCanUpdateStoryValue();
  const paneIndex = usePaneIndexValue();
  const notesRef = useRef();
  const { updateStoryPane } = usePanes();

  if (story)
    return (
      <TabsRoot
        onValueChange={(value) => updateStoryPane(paneIndex, value as StoryTab)}
        defaultValue={storyTabs.content}
        value={pane?.tab || storyTabs.content}
      >
        <Tabs />
        <Suspense fallback={<LoadingIndicator />}>
          <ContentContainer>
            <TabsContent value={storyTabs.content}>
              <Content />
            </TabsContent>
            <TabsContent value={storyTabs.notes}>
              <NotesTS
                storyId={story?.mId}
                hostReadSpeed={hostReadSpeed}
                disableUpdate={!canUpdate}
                ref={notesRef}
                hideCreateButton
              />
            </TabsContent>
            <TabsContent value={storyTabs.instances}>
              <Instances />
            </TabsContent>
            <TabsContent value={storyTabs.assets}>
              <Assets
                memberType={story?.mType || MemberTypeEnum.Story}
                mId={story?.mId}
                canUpdate={canUpdate}
              />
            </TabsContent>
            <TabsContent value={storyTabs.bookings}>
              <Booking
                storyId={story?.mId}
                storyTitle={story?.mTitle}
                storyThumbnail={story?.mThumbnailUrl}
                isArchivedStory={story?.mState === 'archived'}
                isStoryBox={false}
              />
            </TabsContent>
            <TabsContent value={storyTabs.locations}>
              <MapView
                memberId={story.mId}
                memberType={pitchTypesSet.has(story?.mType) ? memberTypes.PITCH : memberTypes.STORY}
              />
            </TabsContent>
            <TabsContent value={storyTabs.metadata}>
              <StoryMetadata story={story} useCollapse={false} />
            </TabsContent>
            <TabsContent value={storyTabs.resources}>
              <Resources
                storyId={story?.mId}
                mAssignedMembers={story?.mAssignedMembers}
                dialogHeight="87vh"
                enableUpdate={canUpdate}
              />
            </TabsContent>
            <TabsContent value={storyTabs.blocks}>
              <BlockGrid resourceId={story?.mId} />
            </TabsContent>
            <TabsContent value={storyTabs.tasks}>
              <OrderGridFull resourceId={story?.mId} resourceType={GetOrderEnum.Resource} />
            </TabsContent>
          </ContentContainer>
        </Suspense>
      </TabsRoot>
    );
}

export default StoryTabs;
