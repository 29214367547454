/* eslint-disable sort-imports */
import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';

import { Scalars } from 'types';
import { MemberTypeEnum } from 'types/graphqlTypes';
import useLogger from 'utils/useLogger';

import { getErrorMessage } from '../commonKeys';

const TRIGGER_ACTION = gql`
  mutation TriggerAction($input: TriggerActionInput) {
    triggerAction(input: $input) {
      mId
    }
  }
`;

export type TriggerActionInput = {
  actionId: Scalars['String'];
  resourceType: MemberTypeEnum;
  resourceId: Scalars['String'];
};

interface TriggerInput {
  input: TriggerActionInput;
}

export const useTriggerAction = () => {
  const [mutation] = useMutation<null, TriggerInput>(TRIGGER_ACTION);
  const logger = useLogger('TriggerAction');
  const triggerAction = useCallback(
    async (input: TriggerActionInput) => {
      try {
        await mutation({
          variables: {
            input,
          },
        });
      } catch (err) {
        const errorMessage = getErrorMessage(err, input);
        logger.log(errorMessage);
        throw new Error(errorMessage);
      }
    },
    [mutation],
  );
  return { triggerAction };
};
