import React from 'react';
import PropTypes from 'prop-types';
import ConversationListItem from './conversationListItem';

const List = ({
  members,
  showAvatar,
  avatarVariant,
  showGroupMemberCount,
  menuItems,
  onMenuSelect,
}) => {
  const sortedMembers = [...members].sort((a, b) => (a.mUpdatedAt < b.mUpdatedAt ? 1 : -1));

  return (
    <>
      {sortedMembers.map((member) => (
        <ConversationListItem
          key={member.mId}
          {...{
            member,
            showAvatar,
            avatarVariant,
            showGroupMemberCount,
            menuItems,
            onMenuSelect,
          }}
        />
      ))}
    </>
  );
};

List.propTypes = {
  /** list of members */
  members: PropTypes.arrayOf(PropTypes.shape({})),
  /** boolean to show avatar of the member */
  showAvatar: PropTypes.bool,
  /** variant of the member */
  avatarVariant: PropTypes.string,
  /** boolean to show group member count in list item */
  showGroupMemberCount: PropTypes.bool,
  /** a list of items in the menu */
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      action: PropTypes.string,
      icon: PropTypes.element,
    }),
  ),
  /** callback to executue the actions from menu */
  onMenuSelect: PropTypes.func,
};

List.defaultProps = {
  members: [],
  showAvatar: true,
  avatarVariant: 'team',
  showGroupMemberCount: false,
  menuItems: [],
  onMenuSelect: () => {},
};

export default List;
