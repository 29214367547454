/* eslint-disable max-len */
import { RefObject, useCallback, useRef } from 'react';

/**
 * Custom React hook for handling input events, providing event handlers and a ref.
 *
 * @param onUpdate - Callback on update events.
 * @param currentValue - Current value of the input.
 * @param initialValue - Initial value of the input.
 * @param tabExplicit - Explicitly handle Tab key press events.
 * @returns [inputRef, onKeyDown, onBlur, onClear] - Array containing ref, keyboard, blur, and clear functions.
 * - On Enter, blur and update with current value.
 * - On Tab (if tabExplicit), update with current value.
 * - On Escape, blur and update with initial value.
 * - onBlur prevents default, updates if focus moved to a related target.
 * - onClear prevents default and updates with an empty string.
 */
const useInputEvents = (
  onUpdate: (value: string) => void | Promise<void>,
  currentValue: string,
  initialValue: string,
  tabExplicit: boolean = false,
): [
  RefObject<HTMLInputElement>,
  (event: React.KeyboardEvent<HTMLInputElement>) => void,
  (event: React.FocusEvent<HTMLInputElement>) => void,
  (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
] => {
  const inputRef = useRef<HTMLInputElement>(null);

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        inputRef.current?.blur();
        void onUpdate(currentValue);
        event.stopPropagation();
      }
      if (event.key === 'Tab' && tabExplicit) {
        void onUpdate(currentValue);
      }
      if (event.key === 'Escape') {
        inputRef.current?.blur();
        void onUpdate(initialValue);
      }
    },
    [currentValue, initialValue, tabExplicit, onUpdate],
  );

  const onBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      event.preventDefault();
      if (event.relatedTarget) void onUpdate(currentValue);
    },
    [currentValue, onUpdate],
  );

  const onClear = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      void onUpdate('');
    },
    [onUpdate],
  );

  return [inputRef, onKeyDown, onBlur, onClear];
};

export default useInputEvents;
