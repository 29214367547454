import React, { useState } from 'react';

import { ReactComponent as LinksIcon } from 'assets/icons/systemicons/link_off.svg';
import IframeViewer from 'components/iframeViewer';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import Popover from 'components/popover';
import Tooltip from 'components/tooltip';
import Scrollbar from 'components/scrollbar';

import { mapLinks } from './utils';
import useGetLinks from '../settings/hooks/useGetLinks';
import Link from './components/link';

import { Divider, Title, Panel, EmptyState, EmptyStateText, IconWrapper } from './styled';

const HEADLINE = 'Links';
const EMPTY_STATE_TEXT = 'Use the settings panel to add links';

function LinksPanel() {
  const [iframeUrl, setIframeUrl] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [links, loading] = useGetLinks();

  const validLinks = mapLinks(links?.mProperties);

  const openPanel = (e) => setAnchorEl(e.currentTarget);
  const closePanel = () => setAnchorEl(null);
  const updateIframeUrl = (val = null) => setIframeUrl(val);

  return (
    <>
      <Tooltip title="Links" noArrow>
        <IconWrapper onClick={openPanel}>
          <LinksIcon className="skipOverride" />
        </IconWrapper>
      </Tooltip>
      <Popover anchorEl={anchorEl} onClose={closePanel}>
        <Panel>
          <Scrollbar>
            <Title>{HEADLINE}</Title>
            <Divider />
            {loading && <LoadingIndicator />}
            {validLinks?.length ? (
              validLinks.map((link) => <Link key={link.id} link={link} onClick={updateIframeUrl} />)
            ) : (
              <EmptyState>
                <EmptyStateText>{EMPTY_STATE_TEXT}</EmptyStateText>
              </EmptyState>
            )}
          </Scrollbar>
        </Panel>
      </Popover>
      <IframeViewer
        open={typeof iframeUrl === 'string'}
        src={iframeUrl}
        closeDialog={updateIframeUrl}
        title={iframeUrl}
      />
    </>
  );
}

export default LinksPanel;
