import React, { memo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSlate } from 'slate-react';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import { ReactComponent as PublicIcon } from 'assets/icons/systemicons/world.svg';
import { ReactComponent as VisibilityIconComponent } from 'assets/icons/systemicons/editor/visibility.svg';
import { ReactComponent as UnlistedIcon } from 'assets/icons/systemicons/visibility_off.svg';
import { ReactComponent as PrivateIcon } from 'assets/icons/systemicons/padlock_on.svg';
import useChangeCollapse from 'components/editor/hooks/useChangeCollapse';
import updateBlock from 'components/editor/utils/updateBlock';
import SelectComponent from 'components/select';
import styled from '@emotion/styled';
import Box from '../box';

export const VisibilityIcon = styled(VisibilityIconComponent)`
  margin: 8px;
`;

export const ContentWrapper = styled('div')`
  margin-bottom: 16px;
`;

const Select = memo(SelectComponent);

const items = [
  {
    value: 'public',
    title: 'Public',
    icon: <PublicIcon />,
    info: 'Everyone can\nfind, see and share',
  },
  {
    value: 'unlisted',
    title: 'Unlisted',
    icon: <UnlistedIcon />,
    info: 'Only people with the link\ncan see and share',
  },
  {
    value: 'private',
    title: 'Private',
    icon: <PrivateIcon />,
    info: 'Only you or the people\nyou choose can see',
    showTopDivider: true,
  },
];

const Privacy = ({ attributes, children, element, readOnly }) => {
  const { data } = element;
  const editor = useSlate();
  const { update } = useEditorContext();
  const { content, collapsed = false } = data || {};

  const [onChangeCollapse] = useChangeCollapse(element);

  const initialValue = content || 'public';

  const [privacyValue, setPrivacyValue] = useState(initialValue);

  const onChange = useCallback(
    (newValue) => {
      setPrivacyValue(newValue);

      const updatedData = {
        ...data,
        content: newValue,
      };
      updateBlock(editor, element, updatedData, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  );

  return (
    <div {...attributes}>
      {children}
      <Box
        iconComponent={<VisibilityIcon className="skipOverride" />}
        title="Privacy"
        readOnly={readOnly}
        hideEllipsisButton
        collapsed={collapsed}
        collapsedContent={privacyValue}
        updateCollapsed={onChangeCollapse}
      >
        <ContentWrapper>
          <Select selectedValue={privacyValue} items={items} onChange={onChange} usage="editor" />
        </ContentWrapper>
      </Box>
    </div>
  );
};

Privacy.propTypes = {
  readOnly: PropTypes.bool,
};

Privacy.defaultProps = {
  readOnly: false,
};

export default memo(Privacy);
