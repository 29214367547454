import { useState, useCallback } from 'react';

import useGetStories from 'api/useGetStories';
import { ReactComponent as FiltersOff } from 'assets/icons/systemicons/filters_off.svg';
import { ReactComponent as FiltersOn } from 'assets/icons/systemicons/filters_on.svg';
import { useStoryTabs } from 'store';
import useSearchBar from 'hooks/useSearchBar';
import Deck from 'components/deck';
import { Button } from 'components/buttons';
import LoadingIndicator, { LoadingButtonIndicator } from 'components/loadingIndicator';
import ListItem from 'components/listItems/story';
import Scrollbar from 'components/scrollbar';
import Searchbar from 'components/searchbar';
import List from 'features/feedViewer/components/list';

import { useAddFeedToStory } from '../../api';

function AddDialog({ close, provider, feedId, closeTickerDialog }) {
  const [openTabs] = useStoryTabs();
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [isAddingStory, setIsAddingStory] = useState(false);
  const [userItemsOnly, setUserItemsOnly] = useState(false);
  const [addFeedToStory] = useAddFeedToStory();
  const { filterModel, updateFilterModel, currentSearch, updateCurrentSearch, searchString } =
    useSearchBar({ historyLimit: 1 });
  const { items, loading, fetchMore } = useGetStories({ userItemsOnly, searchString });

  const onSelect = (item) => {
    setSelected(item);
  };

  const fetchMoreItems = useCallback(() => {
    if (!loading) fetchMore();
  }, [loading, fetchMore]);

  const onAdd = async () => {
    if (!selected) return;

    setIsAddingStory(true);
    await addFeedToStory({ provider, feedId, storyId: selected.mId, storyType: selected.mType });
    setIsAddingStory(false);
    close();
    closeTickerDialog();
  };

  const onSwitchChange = useCallback(() => {
    setUserItemsOnly((pre) => !pre);
  }, [setUserItemsOnly]);

  const toggleFilters = useCallback(() => {
    setFiltersOpen((prev) => !prev);
  }, [setFiltersOpen]);

  const filterActive = Boolean(userItemsOnly || searchString);

  return (
    <div style={{ height: '60vh', width: '330px' }}>
      <Deck>
        <Deck.Header>
          <Deck.HeaderTitle>Add to Story or Pitch</Deck.HeaderTitle>
        </Deck.Header>
        <Deck.Toolbar>
          <Deck.ControlSwitch onChange={onSwitchChange} selected={userItemsOnly}>
            Only my items
          </Deck.ControlSwitch>
          <Deck.Button active={!!searchString} selected={filtersOpen} onClick={toggleFilters}>
            {filtersOpen ? <FiltersOn /> : <FiltersOff />}
          </Deck.Button>
        </Deck.Toolbar>
        {filtersOpen && (
          <Deck.DropdownFilters filtersOpen={filtersOpen} setFiltersOpen={setFiltersOpen}>
            <Searchbar
              chipTypes={[]}
              showSavedFiltersButton={false}
              {...{
                filterModel,
                updateFilterModel,
                currentSearch,
                updateCurrentSearch,
              }}
            />
          </Deck.DropdownFilters>
        )}
        <Deck.Body>
          {loading ? (
            <LoadingIndicator />
          ) : (
            <Scrollbar closeToBottom={fetchMoreItems}>
              {!filterActive && (
                <List>
                  <List.CollapseHeader title="Open stories & Pitches" count={openTabs.length ?? 0}>
                    <List.Body>
                      {openTabs.map((tab) => (
                        <ListItem
                          key={tab.id}
                          onClick={() => onSelect({ mId: tab.id, mType: tab.type, list: 'open' })}
                          selected={selected?.list === 'open' && selected?.mId === tab.id}
                          type={tab.type}
                        >
                          <ListItem.Thumbnail type={tab.mType} />
                          <ListItem.Text>
                            <ListItem.Title>{tab.title}</ListItem.Title>
                          </ListItem.Text>
                        </ListItem>
                      ))}
                    </List.Body>
                  </List.CollapseHeader>
                </List>
              )}
              <List>
                <List.CollapseHeader title="All" hide={filterActive}>
                  <List.Body>
                    {items.map((item) => (
                      <ListItem
                        key={item.mId}
                        onClick={() => onSelect({ ...item, list: 'all' })}
                        selected={selected?.list === 'all' && selected?.mId === item.mId}
                        type={item.mType}
                      >
                        <ListItem.Thumbnail src={item.mThumbnailUrl} type={item.mType} />
                        <ListItem.Text>
                          <ListItem.Title>{item.mTitle}</ListItem.Title>
                          <ListItem.Updated>{item.mUpdatedAt}</ListItem.Updated>
                        </ListItem.Text>
                      </ListItem>
                    ))}
                  </List.Body>
                </List.CollapseHeader>
              </List>
            </Scrollbar>
          )}
        </Deck.Body>
        <Deck.Footer>
          <Button variant="outlined" usage="outlined" onClick={close}>
            Cancel
          </Button>
          <Button variant="contained" usage="cta" disabled={!selected} onClick={onAdd}>
            {isAddingStory ? <LoadingButtonIndicator /> : 'Add to selected'}
          </Button>
        </Deck.Footer>
      </Deck>
    </div>
  );
}

export default AddDialog;
