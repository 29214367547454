import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';

import { MemberType, MemberTypeEnum } from 'types/graphqlTypes';

import { BLOCK_PROPS, memberToBlock } from './utils';

const GET_BLOCKS = gql`
  query GetBlocks($input: GetMemberInput) {
    getMembers(input: $input) {
      ${BLOCK_PROPS}
    }
  }
`;

interface GetBlocks {
  getMembers: MemberType[];
}

export const useGetBlocks = (mId?: string) => {
  const { data, error, loading, refetch } = useQuery<GetBlocks>(GET_BLOCKS, {
    variables: {
      input: {
        mId,
        mType: MemberTypeEnum.Block,
      },
    },
    fetchPolicy: 'cache-and-network',
    skip: !mId,
  });

  const blocks = useMemo(() => {
    return (data?.getMembers ?? []).map(memberToBlock);
  }, [data]);

  return { blocks, error, loading, refetch };
};
