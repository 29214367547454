import React, { useContext } from 'react';

import { ReactComponent as ArrowRight } from 'assets/icons/systemicons/arrows/disclosurearrow_right.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/systemicons/email.svg';
import { ReactComponent as ChatResting } from 'assets/icons/systemicons/HeaderNavbar/chat_resting.svg';
import Avatar from 'components/avatar/Avatar';
import { IconButton } from 'components/buttons';
import Text from 'components/text/Text';
import UserContext from 'contexts/UserContext';
import useCreateConvo from 'hooks/useCreateConvo';
import useGetUser from 'hooks/useGetUser';
import useHover from 'hooks/useHover';
import { useIsMessageHubOpen, useSelectedConversationId } from 'store';
import { AssignedMember } from 'types';
import { Metadata } from 'types/forms/forms';
import { MemberTypeEnum } from 'types/graphqlTypes';
import accessibleOnClick from 'utils/accessibleOnClick';

import { MemberProps } from '../types';

import {
  AvatarWrapper,
  ButtonListItem,
  ChatEmailIconWrapper,
  IconWrapper,
  NameText,
  TitleWrapper,
} from './styled';

const Member = ({
  id,
  name,
  title,
  type,
  image,
  imageKey,
  description,
  metadata,
  properties,
  secondaryActionItem,
  onClick,
  onSecondaryAction,
  disableInteraction = false,
}: MemberProps) => {
  const [hoverRef, isHovered] = useHover<HTMLDivElement>();
  const { getUser } = useGetUser();
  const user = useContext(UserContext);

  const { createConvo } = useCreateConvo();
  const [, setIsMessageHubOpen] = useIsMessageHubOpen();
  const [, setSelectedConvoId] = useSelectedConversationId();

  const handleClick = () => {
    if (onClick) {
      onClick({
        id,
        name,
        title,
        description,
        image,
        imageKey,
        type,
        properties,
        metadata,
      });
    }
  };

  const parsedMetadata = (metadata ? JSON.parse(metadata) : {}) as Metadata;
  const email = parsedMetadata?.email as string;
  const phone = parsedMetadata?.phone as string;
  const isExternalContact = type === MemberTypeEnum.Contact;

  const handleChat = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const member = getUser(id) as AssignedMember;
    await createConvo(member, (convo) => {
      setSelectedConvoId(convo?.mId);
      setIsMessageHubOpen(true);
    });
  };

  const handleEmail = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const emailTo = (parsedMetadata?.email ?? properties?.email) as string;
    window.location.assign(`mailto: ${emailTo}`);
  };

  const isCurrentUser = user.mId === id;

  return (
    <ButtonListItem button={!disableInteraction as true} onClick={handleClick} ref={hoverRef}>
      <AvatarWrapper>
        <Avatar size={40} variant="user" src={image} title={name} />
      </AvatarWrapper>
      <TitleWrapper>
        <NameText variant="listItemLabel" color="highEmphasis">
          {name}
        </NameText>
        <Text variant="caption">{email ?? properties?.email}</Text>
        <Text variant="caption">{phone ?? properties?.phone}</Text>
      </TitleWrapper>
      {secondaryActionItem && onSecondaryAction ? (
        <IconWrapper {...accessibleOnClick(onSecondaryAction, 'presentation')}>
          {secondaryActionItem}
        </IconWrapper>
      ) : (
        <IconWrapper {...accessibleOnClick(handleClick, 'presentation')}>
          {isHovered &&
          !isCurrentUser &&
          (type === MemberTypeEnum.User || type == MemberTypeEnum.Contact) ? (
            <ChatEmailIconWrapper style={{}}>
              {!isExternalContact && (
                <IconButton
                  onClick={handleChat}
                  variant="discreet"
                  usage="text"
                  noBorder
                  round
                  size={24}
                  iconSize={20}
                  title="Start chat"
                >
                  <ChatResting />
                </IconButton>
              )}

              <IconButton
                onClick={handleEmail}
                variant="discreet"
                usage="text"
                noBorder
                round
                size={24}
                iconSize={20}
                title="Send email"
                disabledTitle="Email is not set"
                disabled={!(email || properties?.email)}
              >
                <EmailIcon />
              </IconButton>
            </ChatEmailIconWrapper>
          ) : (
            <ArrowRight />
          )}
        </IconWrapper>
      )}
    </ButtonListItem>
  );
};

export default Member;
