/* eslint-disable sort-imports */
import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';

import { MdfDefaultIds, MdfMap } from 'types';
import { GetMdfsInput, Mdf, MdfType, MemberTypeEnum } from 'types/graphqlTypes';

import { STANDARD_MDF_KEYS } from '../commonKeys';

import { toMdfDto } from './useCreateMdf';

export const GET_MDFS = gql`
  query GetMdfs($input: GetMdfsInput) {
    getMdfs(input: $input) {
      ${STANDARD_MDF_KEYS}
    }
  }
`;

export interface GetMdfs {
  getMdfs: MdfType[];
}

interface GetInput {
  input: GetMdfsInput;
}

export type SupportedMetadataTypes =
  | MemberTypeEnum.Story
  | MemberTypeEnum.Rundown
  | MemberTypeEnum.User
  | MemberTypeEnum.Contact;

export const MTypeToMdfId: Record<SupportedMetadataTypes, string> = {
  [MemberTypeEnum.Contact]: 'contact-mdf',
  [MemberTypeEnum.Rundown]: 'rundown-mdf',
  [MemberTypeEnum.Story]: 'story-mdf',
  [MemberTypeEnum.User]: 'user-mdf',
};

export const DefaultMdfIds = ['contact-mdf', 'rundown-mdf', 'story-mdf', 'user-mdf'];

export const useGetMdfs = (props: GetMdfsInput) => {
  const { data, error, loading, refetch } = useQuery<GetMdfs, GetInput>(GET_MDFS, {
    variables: {
      input: {
        ...props,
      },
    },
    fetchPolicy: 'cache-first',
    skip: !props || isEmpty(props),
  });

  const mdfs = useMemo(() => {
    return (data?.getMdfs ?? []).map(toMdfDto);
  }, [data]);

  const mdfsSeparated = useMemo(() => {
    const defaults: Mdf[] = [];
    const custom: Mdf[] = [];
    if (!data?.getMdfs) return { defaults: [], custom: [] };
    for (const rawMdf of data.getMdfs) {
      if (MdfDefaultIds.includes(rawMdf.id)) {
        defaults.push(toMdfDto(rawMdf));
      } else {
        custom.push(toMdfDto(rawMdf));
      }
    }
    return {
      defaults,
      custom,
    };
  }, [mdfs]);

  const mdfsByMType: Record<SupportedMetadataTypes, Mdf | undefined> = useMemo(() => {
    return {
      [MemberTypeEnum.Story]: mdfs.find((mdf) => mdf.id === MdfMap[MemberTypeEnum.Story]),
      [MemberTypeEnum.User]: mdfs.find((mdf) => mdf.id === MdfMap[MemberTypeEnum.User]),
      [MemberTypeEnum.Contact]: mdfs.find((mdf) => mdf.id === MdfMap[MemberTypeEnum.Contact]),
      [MemberTypeEnum.Rundown]: mdfs.find((mdf) => mdf.id === MdfMap[MemberTypeEnum.Rundown]),
    };
  }, [mdfs]);

  return { mdfs, mdfsSeparated, mdfsByMType, error, loading, refetch };
};
