import { memo, useMemo } from 'react';
import styled from '@emotion/styled';
import { Tooltip } from '@material-ui/core';
import { capitalize, keyBy } from 'lodash';

import MemberLabel from 'components/addMember/MemberLabel';
import PlatformIcons from 'components/menu/createInstanceMenu/PlatformIcons';
import Text from 'components/text/Text';
import { getFieldIdFromKey, getFieldKey, getFieldMap } from 'features/orderForm/utils';
import useGetStatuses from 'hooks/useGetStatuses';
import { HStack } from 'layouts/box/Box';
import { FieldValue, Metadata } from 'types/forms/forms';
import { Mdf, SearchItemTypeEnum } from 'types/graphqlTypes';
import { AssignedMember } from 'types/members';
import { isoToLocaleShort } from 'utils/datetimeHelpers';

// eslint-disable-next-line sort-imports
import { mTypeToLabel, rundownStatusLabels, SearchIcons, ToolbarIcons } from '../command-constants';
import { CommandToolbarProps } from '../command-types';
import { getDateRange, getPrettyValue } from '../command-utils';

const Wrapper = styled(HStack)`
  width: 100%;
  display: flex;
  justify-content: start;
  gap: 6px;
  flex-wrap: wrap;
  padding-bottom: 3px;
`;

const Chip = styled(HStack)`
  padding: 1px 3px 1px 5px;
  height: 24px;
  border-radius: 4px;
  gap: 4px;
  align-items: center;
  justify-content: start;
  max-width: 400px;
  border: 1px solid ${({ theme }) => theme.palette.dina.buttonBorderOutlined};
`;

const ChipText = styled(Text)`
  max-width: 375px;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.palette.dina.highEmphasis};
  font-size: 10px;
  user-select: none;
`;

const Clear = styled(ToolbarIcons.Clear)`
  width: 16px;
  height: 16px;
  flex-grow: 0;
  flex-shrink: 0;
  cursor: pointer;
  &:hover path {
    fill-opacity: 1;
  }
`;

interface IChip {
  label: string;
  tooltip?: string;
  key: string;
  avatarId?: string;
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>;
}

const delimiter = '||';

const hasValidValue = (val: FieldValue) => {
  if (typeof val === 'string') return val.trim().length > 0;
  if (Array.isArray(val)) return val.length > 0;
  return true;
};

const getInstanceIcon = (platform: string | undefined) => {
  return platform
    ? PlatformIcons[platform] ?? PlatformIcons.defaultIcon
    : PlatformIcons.defaultIcon;
};

function SearchChips({
  toolbarState,
  setToolbarState,
  clearFieldValue,
  metadataFilter,
  allMembersKeyed,
  mdfs,
  mdfId,
}: Readonly<{
  toolbarState: CommandToolbarProps;
  setToolbarState: React.Dispatch<React.SetStateAction<CommandToolbarProps>>;
  clearFieldValue: (fieldId: string) => void;
  metadataFilter: Metadata;
  allMembersKeyed: Record<string, AssignedMember>;
  mdfs: Mdf[];
  mdfId: string | undefined;
}>) {
  const { instanceStatusesById } = useGetStatuses();

  const mdfMapById = useMemo(() => {
    return keyBy(mdfs, (mdf) => mdf.id);
  }, [mdfs]);
  const fieldMap = useMemo(() => {
    return getFieldMap(mdfs, 'default');
  }, [mdfs]);

  const onClear = (key: string) => {
    if (key.startsWith('assigned')) {
      const split = key.split(delimiter);
      setToolbarState((prevState) => {
        return {
          ...prevState,
          assignedIds: toolbarState.assignedIds.filter((a) => a !== split[1]),
        };
      });
    } else if (key.startsWith('createdby')) {
      const split = key.split(delimiter);
      setToolbarState((prevState) => {
        return {
          ...prevState,
          createdByIds: toolbarState.createdByIds.filter((a) => a !== split[1]),
        };
      });
    } else if (key.startsWith('dateRange')) {
      setToolbarState((prevState) => {
        return {
          ...prevState,
          rangeBy: null,
        };
      });
    } else if (key.startsWith('status')) {
      const split = key.split(delimiter);
      setToolbarState((prevState) => {
        return {
          ...prevState,
          statusFilter: toolbarState.statusFilter.filter((s) => s !== split[1]),
        };
      });
    } else if (key.startsWith('custom')) {
      const split = key.split(delimiter);
      clearFieldValue(getFieldIdFromKey(split[1]));
    } else if (key.startsWith('mType')) {
      const split = key.split(delimiter);
      setToolbarState((prevState) => {
        return {
          ...prevState,
          mTypes: toolbarState.mTypes.filter((a) => a !== (split[1] as SearchItemTypeEnum)),
        };
      });
    } else if (key.startsWith('mdfId')) {
      setToolbarState((prevState) => {
        return {
          ...prevState,
          mdfId: null,
        };
      });
    } else if (key.startsWith('platformType')) {
      const split = key.split(delimiter);
      setToolbarState((prevState) => {
        return {
          ...prevState,
          platformTypes: (toolbarState.platformTypes ?? []).filter((a) => a !== split[1]),
        };
      });
    }
  };

  const defaultChips = useMemo(() => {
    const chipsToRender: IChip[] = [];

    if (toolbarState.mTypes.length) {
      toolbarState.mTypes.forEach((type) => {
        chipsToRender.push({
          label: mTypeToLabel[type],
          key: `mType${delimiter}${type}`,
          tooltip: `Showing ${mTypeToLabel[type]}`,
          Icon: SearchIcons[type],
        });
      });
    }

    if (toolbarState.platformTypes?.length) {
      (toolbarState.platformTypes ?? []).forEach((type) => {
        chipsToRender.push({
          label: capitalize(type),
          key: `platformType${delimiter}${type}`,
          tooltip: `Showing ${capitalize(type)}`,
          Icon: getInstanceIcon(type),
        });
      });
    }

    if (toolbarState.mdfId) {
      const mdf = mdfMapById[toolbarState.mdfId];
      if (mdf) {
        const label = mdf.label;
        chipsToRender.push({
          label,
          key: `mdfId${delimiter}${toolbarState.mdfId}`,
          tooltip: `Filtering by ${label}`,
        });
      }
    }

    if (toolbarState.assignedIds.length) {
      toolbarState.assignedIds.forEach((id) => {
        const label = 'Assigned:';
        chipsToRender.push({
          label,
          key: `assigned${delimiter}${id}`,
          tooltip: `Assigned to ${label}`,
          avatarId: id,
        });
      });
    }

    if (toolbarState.createdByIds.length) {
      toolbarState.createdByIds.forEach((id) => {
        const label = 'Created by:';
        chipsToRender.push({
          label,
          key: `createdby${delimiter}${id}`,
          tooltip: `Created by ${label}`,
          avatarId: id,
        });
      });
    }

    if (toolbarState.rangeBy) {
      const dateRange = getDateRange(toolbarState.rangeBy);
      if (dateRange) {
        const label = `${dateRange.label}: ${isoToLocaleShort(
          dateRange.dateRange.from,
        )} - ${isoToLocaleShort(dateRange.dateRange.to)}`;
        chipsToRender.push({
          label,
          key: `dateRange${delimiter}${label}`,
        });
      }
    }

    if (toolbarState.statusFilter.length) {
      toolbarState.statusFilter.forEach((id) => {
        const label = instanceStatusesById[id]?.name ?? rundownStatusLabels[id] ?? id;
        chipsToRender.push({
          label,
          key: `status${delimiter}${id}`,
        });
      });
    }

    return chipsToRender;
  }, [toolbarState, instanceStatusesById]);

  const metadataChips = useMemo(() => {
    const chipsToRender: IChip[] = [];
    Object.entries(metadataFilter).forEach(([key, value]) => {
      if (hasValidValue(value)) {
        const fieldKey = getFieldKey(key, mdfId ?? '');
        const field = fieldMap[fieldKey];
        const val = getPrettyValue(value, allMembersKeyed, field);
        const fieldId = `${field?.settings.label ?? key}`;
        const label = typeof val === 'string' ? `${fieldId}: ${val}` : `${fieldId}:`;
        chipsToRender.push({
          label,
          tooltip: label,
          key: `custom||${fieldKey}`,
          avatarId: typeof val !== 'string' ? val.mId : undefined,
        });
      }
    });
    return chipsToRender;
  }, [metadataFilter, mdfId]);

  return (
    <Wrapper>
      {[...defaultChips, ...metadataChips].map((c) => (
        <Chip key={c.key}>
          <Tooltip title={c.tooltip ?? c.label}>
            <>
              {c.Icon && (
                <c.Icon className="skipOverride" style={{ width: '20px', height: '20px' }} />
              )}
              <ChipText variant="listItemLabel">{c.label}</ChipText>
              {c.avatarId && <MemberLabel variant="small" member={allMembersKeyed[c.avatarId]} />}
            </>
          </Tooltip>
          <Tooltip title="Clear">
            <Clear onClick={() => onClear(c.key)} />
          </Tooltip>
        </Chip>
      ))}
    </Wrapper>
  );
}

export default memo(SearchChips);
