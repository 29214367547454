import styled from '@emotion/styled';

export const RootWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;
  position: relative;
  background: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel2};
`;

export const MessageInputWrapper = styled('div')`
  margin: 8px;
  z-index: 1;
`;

export const MessageContainer = styled('div')`
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;
  height: 100%;
  padding-bottom: 8px;
`;
