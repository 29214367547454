import { useCallback, useEffect, useRef, useState, useMemo, memo } from 'react';
import { createPortal } from 'react-dom';
import {
  closestCenter,
  DndContext,
  getFirstCollision,
  pointerWithin,
  rectIntersection,
} from '@dnd-kit/core';
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { ReactComponent as FolderIcon } from 'assets/icons/systemicons/folder.svg';
import Scrollbar from 'components/scrollbar';
import LoadingIndicator from 'components/loadingIndicator';
import DraftDrawerContent from 'features/draft';
import List from 'features/feedViewer/components/list';
import Drawer from 'lib/drawer';
import { MemoizedListItem } from 'components/command/views/search-results/SearchItem';
import { useAllMembersKeyed } from 'store';

import DragOverlay from './components/DragOverlay';
import DroppableLane from './components/DroppableLane';
import Item from './components/Item';
import ReadOnlyLane from './components/lane/ReadOnlyLane';
import useSensors from './hooks/useSensors';
import { useKanbanMolecule } from './store/kanban';

import { RenderItem, MemberRenderItem, ItemDragWrapper } from './styled';
import { VStack } from 'layouts/box/Box';
import Text from 'components/text/Text';
import { canOpenPreview } from 'features/drawerPreview/utils';
import { useSetPreview } from 'store/preview';

function Kanban({
  loading,
  isHorizontal = false,
  customRenderItem,
  kanbanType = 'default',
  refetch = () => {},
  onContextMenu = () => {},
}) {
  const [clonedItems, setClonedItems] = useState(null);
  const [activeId, setActiveId] = useState(null);
  const [showPreview, setShowPreview] = useState(null);
  const setItemPreview = useSetPreview();
  const lastOverId = useRef(null);
  const recentlyMovedToNewContainer = useRef(false);

  const [sensors] = useSensors();
  const { useKanbanBoard, useKanbanMembers, useUpdateBoardOrder, useUpdateKanbanLaneOrder } =
    useKanbanMolecule();
  const [kanbanBoard] = useKanbanBoard();
  const updateBoardOrder = useUpdateBoardOrder();
  const updateLaneOrder = useUpdateKanbanLaneOrder();
  const [kanbanMembers] = useKanbanMembers();

  const laneOrder = useMemo(() => kanbanBoard?.mOrder ?? [], [kanbanBoard?.mOrder]);
  const kanbanLanes = useMemo(() => kanbanBoard?.lanes ?? {}, [kanbanBoard?.lanes, kanbanMembers]);
  const [allMembersKeyed] = useAllMembersKeyed();

  const generatedItems = useMemo(() => {
    if (!laneOrder) return {};
    return Object.fromEntries(
      laneOrder.map((id) => [id, kanbanLanes[id]?.mOrder?.filter(Boolean) || []]).filter(Boolean),
    );
  }, [JSON.stringify(laneOrder), JSON.stringify(kanbanLanes)]);

  // laneIds and memberIds
  const [containers, setContainers] = useState(Object.keys({ ...generatedItems }));
  const [items, setItems] = useState({ ...generatedItems });

  useEffect(() => {
    setContainers(Object.keys({ ...generatedItems }));
    setItems({ ...generatedItems });
  }, [generatedItems]);

  /** Collision detection strategy for the groups and items
   * @param args the arguments passed in by the collision detection.
   * @returns id of the closest droppable container.
   * */
  const collisionDetectionStrategy = useCallback(
    (args) => {
      if (activeId && activeId in items) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter(
            (container) => container.id in items,
          ),
        });
      }

      // Start by finding any intersecting droppable
      const pointerOver = pointerWithin(args);

      // If there are droppables intersecting with the pointer, return those
      const intersections = pointerOver?.length > 0 ? pointerOver : rectIntersection(args);
      let overId = getFirstCollision(intersections, 'id');

      if (overId) {
        if (overId in items) {
          const containerItems = items[overId];
          // If a container is matched and it contains items
          if (containerItems.length > 0) {
            // Return the closest droppable within that container
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                (container) => container.id !== overId && containerItems.includes(container.id),
              ),
            })[0]?.id;
          }
        }

        lastOverId.current = overId;

        return [{ id: overId }];
      }

      // Set the cached `lastOverId` to the id of the draggable item that was moved, otherwise
      // the previous `overId` will be returned which can cause items to incorrectly shift positions
      if (recentlyMovedToNewContainer.current) lastOverId.current = activeId;

      // If no droppable is matched, return the last match
      return lastOverId.current ? [{ id: lastOverId.current }] : [];
    },
    [activeId, items],
  );

  /** Find the container that the item belongs to
   * @param id the id of an item.
   * @returns the id of the container that the item belongs to.
   * */
  const findContainer = useCallback(
    (id) => {
      if (id in items) return id;
      return Object.keys(items).find((key) => items[key].includes(id));
    },
    [items],
  );

  /** Set the active item and clone the items.
   * @param active the item that is being dragged.
   * */
  const onDragStart = useCallback(
    ({ active }) => {
      setActiveId(active.id);
      setClonedItems(items);
    },
    [items],
  );

  /** Reset items to original state by using the cloned items */
  const onDragCancel = useCallback(() => {
    if (clonedItems) setItems(clonedItems);
    setActiveId(null);
    setClonedItems(null);
  }, [clonedItems]);

  /** Temporary update of the order in the group
   * @param active the item that is being dragged.
   * @param over the item that is currently hovered by the drag item.
   * */
  const onDragOver = useCallback(
    ({ active, over }) => {
      // Check if the active item is inside a container and the over item is not
      if (!over?.id || active.id in items) return;

      const overId = over?.id;

      const overContainer = findContainer(over.id);
      const activeContainer = findContainer(active.id);

      if (!overContainer || !activeContainer) return;

      if (activeContainer !== overContainer) {
        setItems((prevItems) => {
          const activeItems = prevItems[activeContainer];
          const overItems = prevItems[overContainer];
          const overIndex = overItems.indexOf(overId);
          const activeIndex = activeItems.indexOf(active.id);

          let newIndex;

          if (overId in items) {
            newIndex = overItems.length + 1;
          } else {
            const isBelowOverItem =
              over &&
              active.rect.current.translated &&
              active.rect.current.translated.top > over.rect.top + over.rect.height;

            const modifier = isBelowOverItem ? 1 : 0;

            newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
          }

          recentlyMovedToNewContainer.current = true;

          return {
            ...items,
            [activeContainer]: items[activeContainer].filter((item) => item !== active.id),
            [overContainer]: [
              ...items[overContainer].slice(0, newIndex),
              items[activeContainer][activeIndex],
              ...items[overContainer].slice(newIndex, items[overContainer].length),
            ],
          };
        });
      }
    },
    [findContainer, items],
  );

  /** Update the order of the items in the group
   * @param active the item that was dragged.
   * @param over the item that was dragged over.
   * */
  const onDragEnd = useCallback(
    ({ active, over }) => {
      if (active.id in items && over?.id) {
        setContainers((prevContainers) => {
          const activeIndex = prevContainers.indexOf(active.id);
          const overIndex = prevContainers.indexOf(over.id);
          const updatedContainerArray = arrayMove(prevContainers, activeIndex, overIndex);
          updateBoardOrder({ mOrder: [...updatedContainerArray] });
          return updatedContainerArray;
        });
      }

      const activeContainer = findContainer(active.id);
      if (!activeContainer) {
        setActiveId(null);
        return;
      }

      const overId = over?.id;

      if (overId == null) {
        setActiveId(null);
        return;
      }

      const overContainer = findContainer(overId);

      if (overContainer) {
        const activeIndex = items[activeContainer].indexOf(active.id);
        const overIndex = items[overContainer].indexOf(overId);

        if (activeIndex !== overIndex) {
          setItems((prevItems) => {
            const updatedItems = arrayMove(prevItems[overContainer], activeIndex, overIndex);
            return { ...prevItems, [overContainer]: updatedItems };
          });
        }

        if (activeIndex >= 0 && overIndex >= 0) {
          const updatedItems = arrayMove(items[overContainer], activeIndex, overIndex);
          updateLaneOrder({ items: { ...items, [overContainer]: updatedItems } });
        }
      }
      setActiveId(null);
    },
    [findContainer, items, updateBoardOrder],
  );

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [items]);

  const getContainer = useCallback((containerId) => kanbanLanes[containerId], [kanbanLanes]);
  const getContainerItemsIds = useCallback((containerId) => items[containerId], [items]);

  /** Render function for each group child */
  const renderItem = useCallback(
    ({ member, parentLaneId }) => {
      const isDraft = member.mType === 'draft';

      const handlePreview = () => {
        if (isDraft) {
          setShowPreview(member);
        } else if (canOpenPreview(member.mType)) {
          setItemPreview(member);
        }
      };

      return (
        <MemberRenderItem
          onClick={handlePreview}
          tabIndex={0}
          onContextMenu={(event) => onContextMenu(event, member, parentLaneId)}
        >
          <MemoizedListItem item={member} sortedBy={'updatedAt'} members={allMembersKeyed} />
        </MemberRenderItem>
      );
    },
    [onContextMenu],
  );

  /** Render function for each lane child
   * @param member rendered member.
   * */
  const getRenderFunction = useCallback(
    ({ ref, listeners, transition, transform, member, parentLaneId }) => {
      const Comp = customRenderItem ?? renderItem;
      return (
        <RenderItem
          {...listeners}
          $transform={CSS.Translate.toString(transform)}
          $transition={transition}
          ref={ref}
        >
          <Comp member={member} parentLaneId={parentLaneId} />
        </RenderItem>
      );
    },
    [customRenderItem, renderItem],
  );

  /** Drag overlay for group child
   * @param id the id of the item that is being dragged.
   * */
  const renderSortableItemDragOverlay = useCallback(
    (id) => {
      const item = kanbanMembers[id];
      return (
        <ItemDragWrapper>
          <Item member={item} renderItem={getRenderFunction} />
        </ItemDragWrapper>
      );
    },
    [getRenderFunction, kanbanMembers],
  );

  /** Group drag overlay
   * @param groupId the id of the group that is being dragged.
   */
  const renderContainerDragOverlay = useCallback(
    (groupId) => {
      const group = getContainer(groupId);
      return <ReadOnlyLane label={group?.mTitle} color={group?.color} />;
    },
    [getContainer],
  );

  const isSortingContainer = activeId ? containers.includes(activeId) : false;

  /** Select sorting strategy */
  const sortingStrategy = isHorizontal
    ? horizontalListSortingStrategy
    : verticalListSortingStrategy;

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={collisionDetectionStrategy}
        onDragStart={onDragStart}
        onDragOver={onDragOver}
        onDragEnd={onDragEnd}
        onDragCancel={onDragCancel}
      >
        <List>
          <Scrollbar>
            <List.Body horizontal={isHorizontal}>
              {loading ? (
                <LoadingIndicator />
              ) : (
                <>
                  {kanbanType !== 'rundown' && !containers?.length ? (
                    <VStack justifyContent="flex-start" padding="48px">
                      <FolderIcon style={{ height: '64px', width: '64px' }} />
                      <Text variant="h7">No groups yet</Text>
                    </VStack>
                  ) : (
                    <SortableContext items={containers} strategy={sortingStrategy}>
                      {containers?.map((containerId) => {
                        const container = getContainer(containerId);
                        const containerItemsIds = getContainerItemsIds(containerId);

                        return (
                          <DroppableLane
                            isHorizontal={isHorizontal}
                            key={containerId}
                            id={containerId}
                            parentId={kanbanBoard?.mId}
                            label={container?.mTitle}
                            color={container?.color}
                            groupMembers={kanbanMembers}
                            items={containerItemsIds}
                            isSortingContainer={isSortingContainer}
                            renderItem={getRenderFunction}
                            type={kanbanType}
                          />
                        );
                      })}
                    </SortableContext>
                  )}
                </>
              )}

              {createPortal(
                <DragOverlay
                  activeId={activeId}
                  containers={containers}
                  renderContainerDragOverlay={renderContainerDragOverlay}
                  renderSortableItemDragOverlay={renderSortableItemDragOverlay}
                />,
                document.body,
              )}
            </List.Body>
          </Scrollbar>
        </List>
      </DndContext>
      <Drawer isOpen={Boolean(showPreview)} onClose={() => setShowPreview(null)}>
        <DraftDrawerContent
          data={showPreview}
          onClose={() => setShowPreview(null)}
          refetch={refetch}
        />
      </Drawer>
    </>
  );
}

export default memo(Kanban);
