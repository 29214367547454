/* eslint-disable sort-imports */
import {
  ChangeEvent,
  FocusEventHandler,
  KeyboardEventHandler,
  MouseEvent,
  MouseEventHandler,
  useEffect,
  useState,
} from 'react';
import { ClickAwayListener, TableRow } from '@material-ui/core';

import useDeleteStudio from 'api/useDeleteStudio';
import useUpdateStudio from 'api/useUpdateStudio';
import { LoadingButtonIndicator } from 'components/loadingIndicator';
import Tooltip from 'components/tooltip';
import useCheckUserRight from 'hooks/useCheckUserRight';
import useInputEvents from 'hooks/useInputEvents';
import { MemberType } from 'types/graphqlTypes';
import { convertISODate } from 'utils/dateTime/convertDateFormat';

import { buttonStatus, ButtonStatusType } from './utils';

import { DeleteButton, Input, RowCell, StatusCell, TitleCell } from './styled';

type RequiredStudioType = Required<Pick<StudioType, 'mId' | 'mRefId'>> &
  Omit<StudioType, 'mId' | 'mRefId'>;

interface StudioType extends Omit<MemberType, 'status' | 'version'> {
  status?: string;
  version?: string;
}

interface RowProps {
  studio: StudioType;
}

function Row({ studio }: RowProps) {
  const {
    mId,
    mRefId,
    status,
    version,
    mTitle = '',
    mUpdatedAt = '',
  } = studio as RequiredStudioType;
  const [title, setTitle] = useState(mTitle);
  const [isDeleting, setIsDeleting] = useState(false);
  const [buttonState, setButtonState] = useState<ButtonStatusType>(buttonStatus.DEFAULT);
  const [updateStudio] = useUpdateStudio();
  const [deleteStudio] = useDeleteStudio();
  const [checkUserRight] = useCheckUserRight();

  const canEditStudios = checkUserRight('rundown', 'edit-studios', true);

  useEffect(() => {
    setTitle(mTitle);
  }, [mTitle]);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => setTitle(event.target.value);

  const onDelete = async (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (buttonState === buttonStatus.DEFAULT) {
      setButtonState(buttonStatus.CONFIRM);
      return;
    }

    setIsDeleting(true);
    await deleteStudio(mId, mRefId);
    setIsDeleting(false);
  };

  const onDeleteClickAway = () => {
    if (buttonState === buttonStatus.CONFIRM) setButtonState(buttonStatus.DEFAULT);
  };

  const onTitleUpdate = async (updatedValue: string) => {
    if (updatedValue === mTitle) return;
    if (updatedValue) void updateStudio(mId, mRefId, { mTitle: updatedValue });
    setTitle(mTitle);
  };

  const [inputRef, onKeyDown, onBlur] = useInputEvents(onTitleUpdate, title, mTitle);

  return (
    <TableRow tabIndex={-1}>
      <TitleCell>
        <Tooltip title={title ? 'Edit and press enter to save.' : 'Title can not be empty'} noArrow>
          <Input
            $error={!title}
            ref={inputRef}
            onKeyDown={onKeyDown as unknown as KeyboardEventHandler<HTMLInputElement>}
            onBlur={onBlur as unknown as FocusEventHandler<HTMLInputElement>}
            value={title}
            onChange={onChange}
            disabled={!canEditStudios}
          />
        </Tooltip>
      </TitleCell>
      <RowCell>{mRefId}</RowCell>
      <StatusCell $status={status}>{status === 'connected' ? 'Online' : 'Offline'}</StatusCell>
      <RowCell>{version}</RowCell>
      <RowCell>{convertISODate(mUpdatedAt, 'MMM. d, HH:mm')}</RowCell>
      <RowCell>
        {isDeleting ? (
          <LoadingButtonIndicator className="light" />
        ) : (
          <ClickAwayListener onClickAway={onDeleteClickAway}>
            <div>
              <Tooltip
                noArrow
                title={
                  buttonState === buttonStatus.DEFAULT
                    ? 'Delete studio configuration'
                    : 'Confirm Delete'
                }
              >
                <DeleteButton
                  className="skipOverride"
                  $status={buttonState}
                  $disabled={!canEditStudios}
                  onMouseDown={onDelete as MouseEventHandler<SVGSVGElement>}
                />
              </Tooltip>
            </div>
          </ClickAwayListener>
        )}
      </RowCell>
    </TableRow>
  );
}

export default Row;
