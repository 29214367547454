import React, { useState, memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSlate } from 'slate-react';
import uuidv1 from 'uuid/v1';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import TextAreaComponent from 'components/textArea';
import { removeBlock, updateBlock } from 'components/editor/utils';
import { elementTypes } from 'components/editor/constants/types';
import useChangeCollapse from 'components/editor/hooks/useChangeCollapse';
import getTweetIdFromURL from 'utils/twitter/getTweetIdFromURL';
import Tweet from './components/tweet';
import Box from '../box';
import DragAndDrop from '../dragAndDrop';
import { TweetIcon, Link, TweetWrapper } from './styled';

const TextArea = memo(TextAreaComponent);

const EmbeddedTweet = ({ attributes, children, element, direction }) => {
  const { data } = element;
  const editor = useSlate();
  const { update } = useEditorContext();

  const { tweetUrl: initialUrl, tweetId, collapsed = false } = data || {};

  const [onChangeCollapse] = useChangeCollapse(element);

  const [tweetUrl, setTweetUrl] = useState(initialUrl || '');

  const updateTweetUrl = useCallback(
    (newValue) => {
      const trimmedUrl = newValue.trim();
      const newTweetId = getTweetIdFromURL(trimmedUrl);

      const updatedData = {
        tweetUrl: trimmedUrl,
        tweetId: newTweetId,
        itemId: uuidv1(),
      };
      updateBlock(editor, element, updatedData, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  const handleChange = useCallback((newValue) => {
    setTweetUrl(newValue);
  }, []);

  const onKeyDown = useCallback(
    (event) => {
      const { key } = event;

      if (key === 'Enter') {
        event.preventDefault();
        updateTweetUrl(tweetUrl);
      }
      if (key === 'Tab') {
        updateTweetUrl(tweetUrl);
      }
      if (key === 'Escape') setTweetUrl(initialUrl);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tweetUrl, initialUrl],
  );

  const onBlur = useCallback(
    (event) => {
      event.preventDefault();
      if (event.relatedTarget) updateTweetUrl(tweetUrl);
    },
    [tweetUrl, updateTweetUrl],
  );

  const handleLinkClick = useCallback(() => {}, []);

  const onMenuSelect = useCallback(
    ({ action }) => {
      if (action === 'delete-block') removeBlock(editor, element, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const renderTweet = useMemo(
    () => (
      <Box
        iconComponent={<TweetIcon className="skipOverride" />}
        title="Embedded Tweet"
        updateCollapsed={onChangeCollapse}
        collapsed={collapsed}
        collapsedContent={tweetUrl}
        hideEllipsisButton
        onMenuSelect={onMenuSelect}
      >
        <TextArea
          disableLabel
          type="SoMe"
          rows={2}
          placeholder="Paste the Tweet URL here"
          value={tweetUrl}
          onChange={handleChange}
          description="Paste the Tweet URL here."
          subDescription={
            <Link onClick={handleLinkClick} role="presentation">
              How to get the URL
            </Link>
          }
          onBlur={onBlur}
          onKeyDown={onKeyDown}
        />
        <TweetWrapper>
          <Tweet tweetId={tweetId} />
        </TweetWrapper>
      </Box>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      collapsed,
      handleChange,
      handleLinkClick,
      onBlur,
      onKeyDown,
      onMenuSelect,
      tweetId,
      tweetUrl,
      onChangeCollapse,
    ],
  );

  return (
    <div {...attributes}>
      <DragAndDrop element={element}>
        {children}
        {renderTweet}
      </DragAndDrop>
    </div>
  );
};

EmbeddedTweet.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

EmbeddedTweet.defaultProps = {
  attributes: {},
  children: null,
  element: {
    children: [],
    data: {},
    type: elementTypes.EMBEDDED_TWEET,
  },
};

export default memo(EmbeddedTweet);
