import memberTypes from 'graphql/memberTypes';

export const storyTypesSet = new Set([
  memberTypes.STORY,
  memberTypes.PITCH,
  memberTypes.RESTRICTED_PITCH,
  memberTypes.RESTRICTED_STORY,
  memberTypes.ARCHIVED_RESTRICTED_STORY,
  memberTypes.ARCHIVED_RESTRICTED_PITCH,
  memberTypes.ARCHIVED_PITCH,
  memberTypes.ARCHIVED_RESTRICTED_STORY,
]);

export const pitchTypesSet = new Set([
  memberTypes.PITCH,
  memberTypes.ARCHIVED_PITCH,
  memberTypes.RESTRICTED_PITCH,
  memberTypes.ARCHIVED_RESTRICTED_PITCH,
]);
