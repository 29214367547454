import styled from '@emotion/styled';

import { TabsList, TabsRoot, TabsTrigger } from 'lib/tabs';
import transientOptions from 'theme/helpers';

export interface RootProps {
  fullWidth?: boolean;
}
interface ListProps {
  $width: number;
  fullWidth?: boolean;
}

export const Root = styled(TabsRoot)<RootProps>`
  height: 32px;
  padding: 1px;
  border: 1px solid ${({ theme }) => theme.palette.dina.buttonBorderOutlined};
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  ${({ fullWidth }) => fullWidth && 'width: 100%'};
`;

export const List = styled(TabsList, transientOptions)<ListProps>`
  display: flex;
  width: ${({ $width, fullWidth }) => (fullWidth ? '100%' : $width + 'px')};
`;

export const Trigger = styled(TabsTrigger)`
  all: unset;

  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 28px;
  padding-inline: 8px;
  border-radius: 6px;
  white-space: nowrap;
  ${({ theme }) => theme.typography.dina.listItemLabel};
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};

  user-select: none;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
    background: ${({ theme }) => theme.palette.dina.backgroundHover};
  }
  &[data-state='active'] {
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
    background: ${({ theme }) => theme.palette.dina.onFocus};
  }
`;
