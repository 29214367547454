import { useCallback } from 'react';

import { useRightHidden, useRightSelection, useContactsTab } from 'store/sidebar';

import { RightContainer, StyledDrawer as Drawer } from './rightArea-styled';
import Sidebar from './sidebar';
import VerticalNavbar, { navbarPosition } from '../verticalNavbar/index';

const RightArea = () => {
  const [rightHidden, setRightHidden] = useRightHidden();
  const [rightSelection, setRightSelection] = useRightSelection();
  const [contactsTab, setContactsTab] = useContactsTab();

  const toggleRightSidebar = () => {
    setRightHidden(!rightHidden);
  };

  const handleContactTabChange = useCallback(
    (val) => {
      if (val === undefined) return;
      setContactsTab(val);
    },
    [setContactsTab],
  );

  const handleSelectionChanged = useCallback(
    (val) => {
      setRightSelection(val);
    },
    [setRightSelection],
  );

  return (
    <RightContainer>
      <VerticalNavbar
        selectionType={rightSelection}
        toggleSidebar={toggleRightSidebar}
        selectionChanged={handleSelectionChanged}
        hidden={rightHidden}
        position={navbarPosition.RIGHT}
      />
      <Drawer variant="persistent" anchor="right" open={!rightHidden && Boolean(rightSelection)}>
        <Sidebar
          selectionType={rightSelection}
          contactTab={contactsTab}
          handleContactTabChange={handleContactTabChange}
        />
      </Drawer>
    </RightContainer>
  );
};

export default RightArea;
