import styled from '@emotion/styled';
import Typography from '@material-ui/core/Typography';

export const ScriptWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 50vw;
  height: 100%;
  max-width: 1024px;
  background: ${({ theme }) => theme.palette.dina.surfaceCard};
  border-radius: 6px;
`;

export const ScriptHeaderWrapper = styled('div')`
  display: flex;
  height: 40px;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
  justify-content: center;
  position: relative;
`;

export const StyledScriptTitle = styled(Typography)`
  ${({ theme }) => theme.typography.dina.h7};
`;

export const ScriptFooter = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.3rem;
  background: ${({ theme }) => theme.palette.dina.backgroundResting};
`;

export const SummaryWrapper = styled('div')`
  display: flex;
`;

export const ButtonWrapper = styled('div')`
  display: flex;
  gap: 8px;
`;
