import memberTypes from 'graphql/memberTypes';
import { CrudActionEnum } from 'types/graphqlTypes';
import { Message, NotifyUser } from 'types/messageHub';

type MessageFunctionArrayItem = [string | undefined, (item: NotifyUser) => Message];

const ADDED = 'Added';
const REMOVED = 'Removed';
const FROM = 'From';
const TO = 'to';

const { STORY, TEAM, DEPARTMENT } = memberTypes;

const sharePitchWithUser = (actionName?: CrudActionEnum) =>
  `${actionName === CrudActionEnum.Insert ? 'assigned you to' : 'removed you from'} pitch: `;

const shareStoryWithUser = (actionName?: CrudActionEnum) =>
  `${actionName === CrudActionEnum.Insert ? 'assigned you to' : 'removed you from'} story: `;

const shareStoryWithTeam = (teamName?: string, actionName?: CrudActionEnum) =>
  `${
    actionName === CrudActionEnum.Insert
      ? `assigned team "${teamName}" to`
      : `removed team "${teamName}" from`
  } story: `;

const shareStoryWithdepartment = (departmentName?: string, actionName?: CrudActionEnum) =>
  `${
    actionName === CrudActionEnum.Insert
      ? `assigned department "${departmentName}"  to`
      : `removed department "${departmentName}"  from`
  } story: `;

const addOrRemoveDepartmentToTeam = (
  departmentName?: string,
  teamName?: string,
  actionName?: CrudActionEnum,
) =>
  `${
    actionName === CrudActionEnum.Insert ? ADDED : REMOVED
  } ${DEPARTMENT}\r\n"${departmentName}"\r\n${
    actionName === CrudActionEnum.Insert ? TO : FROM
  }  ${TEAM}:\r\n"${teamName}"`;

const instanceAddedToStory = (instanceName?: string, storyName?: string) =>
  `${ADDED} a "${instanceName?.toUpperCase()}" instance to ${STORY}:\r\n${storyName}`;

const userAddOrRemoveFromInstance = (actionName?: CrudActionEnum) =>
  `${actionName === CrudActionEnum.Insert ? 'assigned you to' : 'removed you from'} instance: `;

const addRemoveUserFromConversation = (
  userTitle?: string,
  conversationId?: string,
  actionName?: CrudActionEnum,
) =>
  `${userTitle !== 'You' ? `${userTitle} is` : 'You have been '}\r\n${
    actionName === CrudActionEnum.Insert ? 'added to conversation' : 'removed from conversation'
  }\r\n"${conversationId}"`;

const addOrRemoveTeamFromInstance = (teamName?: string, actionName?: CrudActionEnum) =>
  `${
    actionName === CrudActionEnum.Insert
      ? `assigned team "${teamName}" to`
      : `removed team "${teamName}" from`
  } instance: `;

const addOrRemoveDepartemntFromInstance = (departmentName?: string, actionName?: CrudActionEnum) =>
  `${
    actionName === CrudActionEnum.Insert
      ? `assigned department "${departmentName}" to`
      : `removed department "${departmentName}" from`
  } instance: `;

const addOrRemoveTeamFromPitch = (teamName?: string, actionName?: CrudActionEnum) =>
  `${
    actionName === CrudActionEnum.Insert
      ? `assigned team "${teamName}" to`
      : `removed team "${teamName}" from`
  } pitch: `;

const addOrRemoveDepartemntFromPitch = (departmentName?: string, actionName?: CrudActionEnum) =>
  `${
    actionName === CrudActionEnum.Insert
      ? `assigned department "${departmentName}"  to`
      : `removed department "${departmentName}"  from`
  } pitch: `;

const getUserTitle = (user: Record<string, string>, currentUser?: string) =>
  currentUser !== user?.mId ? user?.mTitle : 'You';

// message types
const sharePitchWithUserMessage = (pitch: NotifyUser): Message => {
  const { crudAction } = pitch.message;
  const message = sharePitchWithUser(crudAction);
  return {
    message,
    messageType: 'share',
    messageTypeString:
      crudAction === CrudActionEnum.Insert ? 'Shared pitch' : 'Removed user from pitch',
    mId: pitch.mId,
    mType: pitch.mType,
    itemTitle: pitch.mTitle,
    messageAssign: pitch.messageAssign ?? '',
  };
};

const addOrRemoveUserFromConversationMessage = (conversation: NotifyUser): Message => {
  const { user, loggedInUserId, crudAction } = conversation.message;
  const message = addRemoveUserFromConversation(
    getUserTitle(user, loggedInUserId),
    conversation.mTitle ?? conversation.mId,
    crudAction,
  );
  return {
    message,
    messageType: 'share',
    messageTypeString:
      crudAction === CrudActionEnum.Insert
        ? 'Added user to conversation'
        : 'Removed user from conversation',
    mId: conversation.mId,
    mType: conversation.mType,
    itemTitle: conversation.mTitle,
  };
};

const newMessageReceivedNotifictaion = (chatMessage: NotifyUser): Message => {
  const message = 'New message Received';
  return {
    message,
    messageType: 'New Message',
    messageTypeString: '',
    mId: chatMessage.mId, // Id of conversation
    mType: chatMessage.mType,
    itemTitle: chatMessage.mTitle,
  };
};

const shareStoryWithUserMessage = (story: NotifyUser): Message => {
  const { crudAction } = story.message;
  const message = shareStoryWithUser(crudAction);
  return {
    message,
    messageType: 'share',
    messageTypeString:
      crudAction === CrudActionEnum.Insert ? 'Shared story' : 'Removed user from story',
    mId: story.mId,
    mType: story.mType,
    itemTitle: story.mTitle,
    messageAssign: story.messageAssign ?? '',
  };
};

const shareStoryWithTeamMessage = (item: NotifyUser): Message => {
  const { crudAction, sharedMember } = item.message;
  const message = shareStoryWithTeam(sharedMember?.mTitle, crudAction);
  return {
    message,
    messageType: 'share',
    messageTypeString:
      crudAction === CrudActionEnum.Insert ? 'Shared story with team' : 'Removed story from team',
    mId: item.mId,
    mType: item.mType,
    itemTitle: item.mTitle,
    messageAssign: item.messageAssign ?? '',
  };
};

const shareStoryWithDepartmentMessage = (item: NotifyUser): Message => {
  const { crudAction, sharedMember } = item.message;
  const message = shareStoryWithdepartment(sharedMember.mTitle, crudAction);
  return {
    message,
    messageType: 'share',
    messageTypeString:
      item?.crudAction === CrudActionEnum.Insert
        ? 'Shared story with department'
        : 'Removed story from department',
    mId: item.mId,
    mType: item.mType,
    itemTitle: item.mTitle,
    messageAssign: item.messageAssign ?? '',
  };
};

const addOrRemoveUserToTeamMessage = (item: NotifyUser): Message => {
  const { mId, mType, mTitle: itemTitle, message: data } = item;
  return {
    mId,
    mType,
    itemTitle,
    message: `${
      data?.crudAction === CrudActionEnum.Insert ? 'added you to team: ' : 'removed you from team: '
    } `,
    messageType: 'share',
    messageTypeString:
      data.crudAction === CrudActionEnum.Insert ? 'Added user to team' : 'Removed user from team',
  };
};

const addOrRemoveUserToDepartmentMessage = (item: NotifyUser): Message => {
  const { mId, mType, mTitle: itemTitle, message: data } = item;
  return {
    mId,
    mType,
    itemTitle,
    message: `${
      data?.crudAction === CrudActionEnum.Insert
        ? 'added you to department: '
        : 'removed you from department: '
    } `,
    messageType: 'share',
    messageTypeString:
      data?.crudAction === CrudActionEnum.Insert
        ? 'Added user to department'
        : 'Removed user from department',
  };
};

const addOrRemoveDepartmentToTeamMessage = (item: NotifyUser): Message => {
  const message = addOrRemoveDepartmentToTeam(
    item?.department?.mTitle,
    item?.team?.mTitle,
    item.crudAction,
  );
  return {
    message,
    messageType: 'share',
    messageTypeString:
      item.crudAction === CrudActionEnum.Insert
        ? 'Added department to team:'
        : 'Removed department from team:',
  };
};

const instanceAddedToStoryMessage = (item: NotifyUser): Message => {
  const message = instanceAddedToStory(
    item?.instance?.mProperties?.platform as string,
    item?.story?.mTitle as string,
  );
  return {
    message,
    messageType: `${item?.instance?.mProperties?.platform as string}`,
    messageTypeString:
      item.crudAction === CrudActionEnum.Insert
        ? 'Added an instance to story:'
        : 'Removed instance from story:',
  };
};

const addOrRemoveUserFromInstance = (instance: NotifyUser): Message => {
  const { crudAction } = instance.message;
  const message = userAddOrRemoveFromInstance(crudAction);

  return {
    message,
    messageType: 'share',
    messageTypeString:
      crudAction === CrudActionEnum.Insert
        ? 'Assigned user to instance:'
        : 'Removed user from instance:',
    mId: instance.mStoryId,
    mType: instance.mType,
    itemTitle: instance.mTitle,
    mRefId: instance.mRefId,
    mTemplateId: instance.mTemplateId,
    isTemplateInstance: instance.isTemplateInstance,
    mPublishingAt: instance.mPublishingAt,
    messageAssign: instance.messageAssign ?? '',
  };
};

const addOrRemoveInstanceFromTeamMessage = (item: NotifyUser): Message => {
  const { crudAction, sharedMember } = item.message;
  const message = addOrRemoveTeamFromInstance(sharedMember.mTitle, crudAction);
  return {
    message,
    messageType: 'share',
    messageTypeString:
      crudAction === CrudActionEnum.Insert
        ? 'Assigned instance to team'
        : 'Removed instance from team',
    mId: item?.mId,
    mRefId: item?.mRefId,
    mType: item?.mType,
    itemTitle: item?.mTitle,
    messageAssign: item?.messageAssign ?? '',
  };
};

const addOrRemoveInstanceFromDepartmentMessage = (item: NotifyUser): Message => {
  const { crudAction, sharedMember } = item.message;
  const message = addOrRemoveDepartemntFromInstance(sharedMember.mTitle, crudAction);
  return {
    message,
    messageType: 'share',
    messageTypeString:
      crudAction === CrudActionEnum.Insert
        ? 'Assigned instance to department'
        : 'Removed instance from department',
    mId: item.mId,
    mRefId: item.mRefId,
    mType: item.mType,
    itemTitle: item.mTitle,
    messageAssign: item.messageAssign ?? '',
  };
};

const addOrRemovePitchFromTeamMessage = (item: NotifyUser): Message => {
  const { crudAction, sharedMember } = item.message;
  const message = addOrRemoveTeamFromPitch(sharedMember.mTitle, crudAction);
  return {
    message,
    messageType: 'share',
    messageTypeString:
      crudAction === CrudActionEnum.Insert ? 'Assigned pitch to team' : 'Removed pitch from team',
    mId: item.mId,
    mRefId: item.mRefId,
    mType: item.mType,
    itemTitle: item.mTitle,
    messageAssign: item.messageAssign ?? '',
  };
};

const addOrRemovePitchFromDepartmentMessage = (item: NotifyUser): Message => {
  const { crudAction, sharedMember } = item.message;
  const message = addOrRemoveDepartemntFromPitch(sharedMember.mTitle, crudAction);
  return {
    message,
    messageType: 'share',
    messageTypeString:
      crudAction === CrudActionEnum.Insert
        ? 'Assigned pitch to department'
        : 'Removed pitch from department',
    mId: item.mId,
    mRefId: item.mRefId,
    mType: item.mType,
    itemTitle: item.mTitle,
    messageAssign: item.messageAssign ?? '',
  };
};

const messageFunctionsArray: MessageFunctionArrayItem[] = [
  ['usr_str', shareStoryWithUserMessage],
  ['tea_str', shareStoryWithTeamMessage],
  ['dep_str', shareStoryWithDepartmentMessage],
  ['tea_usr', addOrRemoveUserToTeamMessage],
  ['dep_usr', addOrRemoveUserToDepartmentMessage],
  ['dep_tea', addOrRemoveDepartmentToTeamMessage],
  ['str_ins', instanceAddedToStoryMessage],
  ['usr_ins', addOrRemoveUserFromInstance],
  ['usr_pitch', sharePitchWithUserMessage],
  ['message', newMessageReceivedNotifictaion],
  ['con_usr', addOrRemoveUserFromConversationMessage],
  ['tea_ins', addOrRemoveInstanceFromTeamMessage],
  ['dep_ins', addOrRemoveInstanceFromDepartmentMessage],
  ['tea_pitch', addOrRemovePitchFromTeamMessage],
  ['dep_pitch', addOrRemovePitchFromDepartmentMessage],
];
const messageFunctionsMap = new Map(messageFunctionsArray);

const getMessage = (notifyUser: NotifyUser) => {
  const mType = notifyUser?.message?.mType;
  const messageFunction = messageFunctionsMap.get(mType);
  if (!messageFunction) return undefined;
  return messageFunction(notifyUser);
};

export default getMessage;
