import styled from '@emotion/styled';

export const Title = styled('a')`
  ${({ theme }) => theme.typography.dina.listItemLabel};
  text-decoration: none;
  font-weight: 500;
  letter-spacing: 0.25px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  align-self: center;
  cursor: pointer;
  :hover {
    filter: ${({ theme }) => theme.palette.dina.onHover};
    text-decoration: ${(props) => (props.disableEdit ? 'none' : 'underline')};
    cursor: ${(props) => (props.disableEdit ? 'default' : 'pointer')};
  }
`;

export const TitleWrapper = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
`;
