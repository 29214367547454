import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import { CircularProgress, Typography } from '@material-ui/core';
import Button from 'components/buttons/button';

export const LockedWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const LockedIcon = styled('div')`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LockedText = styled(Typography)`
  ${({ theme }) => theme.typography.dina.button};
  font-weight: 500;
`;

export const ActionButtonsWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 1rem;
`;

export const CancelButton = styled(Button)`
  width: 120px;
  height: 24px;
  border-radius: 4px;
`;

export const SaveButton = styled(Button)`
  ${({ theme }) => css`
    ${theme.typography.dina.button};
    background-color: ${theme.palette.dina.whiteHighEmphasis};
    color: ${theme.palette.dina.blackHighEmphasis};
    font-weight: 500;
    width: 120px;
    height: 24px;
    border-radius: 4px;

    :hover {
      background-color: ${theme.palette.dina.whiteHighEmphasis};
      color: ${theme.palette.dina.blackHighEmphasis};
    }
  `};
`;

export const Container = styled('div')`
  position: relative;
  width: 100%;
  height: ${({ collapsed }) => (collapsed ? '0px' : '40px')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  padding: 0 16px;
  gap: 8px;
  background-color: ${({ readLock, writeLock, theme }) => {
    if (writeLock) return theme.palette.dina.statusApproved;
    if (readLock) return theme.palette.dina.statusWarning;
    return 'transparent';
  }};
  ${LockedIcon} {
    path {
      fill: ${({ readLock, writeLock, theme }) => {
        if (writeLock) return theme.palette.dina.whiteHighEmphasis;
        if (readLock) return theme.palette.dina.blackHighEmphasis;
        return 'inherit';
      }};
      fill-opacity: 1;
    }
  }
  ${LockedText} {
    color: ${({ readLock, writeLock, theme }) => {
      if (writeLock) return theme.palette.dina.whiteHighEmphasis;
      if (readLock) return theme.palette.dina.blackHighEmphasis;
      return 'inherit';
    }};
  }
`;

export const MessageContainer = styled('div')`
  ${({ theme }) => css`
    ${theme.typography.dina.button};
    background-color: ${theme.palette.dina.blackInactive};
  `};
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const Progress = styled(CircularProgress)`
  color: ${({ theme }) => theme.palette.dina.whiteHighEmphasis};
  animation-duration: 1s;
`;

export const ProgressText = styled(Typography)`
  ${({ theme }) => css`
    ${theme.typography.dina.button};
    color: ${theme.palette.dina.whiteHighEmphasis};
  `};
`;
