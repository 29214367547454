import styled from '@emotion/styled';

import { ReactComponent as SidebarActive } from 'assets/icons/systemicons/side_preview.svg';
import Text from 'components/text/Text';
import { HStack } from 'layouts/box/Box';

export const OpenText = styled(Text)`
  position: relative;
  top: -1px;
`;

export const Wrapper = styled('div')`
  position: relative;
  .sidebar-preview {
    display: none;
  }

  &:hover .sidebar-preview {
    display: block;
  }
`;

export const StyledPreviewButton = styled(HStack)`
  align-content: center;
  align-items: center;
  width: 68px;
  height: 24px;
  position: absolute;
  cursor: pointer;
  right: 5px;
  top: 6px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: ${({ theme }) => theme.palette.dina.surfaceCard};
  &:hover {
    border-color: ${({ theme }) => theme.palette.dina.highEmphasis};
    * {
      color: white;
      fill-opacity: 1;
    }
  }

  svg {
    position: relative;
    top: 6px;
  }
`;

export interface OpenPreviewProps {
  onPreviewClick: () => void;
  children: React.ReactElement;
}

function OpenPreviewButton({ onPreviewClick, children }: Readonly<OpenPreviewProps>) {
  return (
    <Wrapper>
      {children}
      <StyledPreviewButton
        className="sidebar-preview"
        gap="3px"
        alignItems="center"
        onClick={() => onPreviewClick()}
      >
        <SidebarActive />
        <OpenText variant="caption">Open</OpenText>
      </StyledPreviewButton>
    </Wrapper>
  );
}

export default OpenPreviewButton;
