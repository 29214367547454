import { FieldValue } from 'types/forms/forms';

export const isSingleArray = (val: FieldValue | null): val is Array<string> => {
  if (Array.isArray(val)) {
    if (!val.length) return true;
    return typeof val[0] === 'string';
  }
  return false;
};

export const isPrefixArray = (arr1: string[], arr2: string[]): boolean =>
  arr1.length >= arr2.length && arr2.every((element, index) => element === arr1[index]);
