import { atom, useAtom } from 'jotai';
import { createScope, molecule, useMolecule } from 'jotai-molecules';

import { CustomElement } from 'types/editor';

export const EditorScope = createScope(undefined);

const editorMolecule = molecule((_, getScope) => {
  getScope(EditorScope);

  const selectedBlockAtom = atom<CustomElement | undefined>(undefined);
  const showSidePanelAtom = atom<boolean>(false);

  return {
    useSelectedBlock: () => useAtom(selectedBlockAtom),
    useShowSidePanel: () => useAtom(showSidePanelAtom),
  };
});

export const useEditorMolecule = () => useMolecule(editorMolecule);
