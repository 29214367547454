import { useCallback } from 'react';
import { useMutation } from '@apollo/client';

import memberTypes from 'graphql/memberTypes';
import UPDATE_USER_GROUPS from 'graphql/mutations/updateUser';
import GET_COGNITO_USERS from 'graphql/queries/getAllDbUsers';
import { useMembers } from 'store';
import { MemberType, UpdateUserType } from 'types/graphqlTypes';
import useLogger from 'utils/useLogger';

interface QueryUsersList {
  getUsers: MemberType[];
}

interface UserGroupUpdateType {
  updateUser: MemberType;
}

const useUpdateUser = () => {
  const logger = useLogger('updateUser');
  const [updateUser] = useMutation<UserGroupUpdateType>(UPDATE_USER_GROUPS);
  const [members, setMembers] = useMembers();

  const handleUserUpdate = useCallback(
    async (params: UpdateUserType) => {
      const {
        mId,
        mRefId,
        username,
        groups,
        newGroups,
        removedGroups,
        notListed,
        setErrorText,
        setOpenDialog,
      } = params;
      const variables = {
        input: {
          mId,
          mRefId,
          username,
          groups,
          newGroups,
          removedGroups,
          notListed,
        },
      };
      await updateUser({
        variables,
        onError: (err) => {
          logger.log(err);
          if (setErrorText) setErrorText('Something went wrong. Please try again');
        },
        update: (client, result) => {
          const updatedUser = result?.data?.updateUser;

          if (!updatedUser?.mTitle && updatedUser?.mProperties?.username) {
            updatedUser.mTitle = updatedUser?.mProperties?.username as string;
          }
          try {
            const usersList = client.readQuery({
              query: GET_COGNITO_USERS,
            }) as QueryUsersList;
            if (!Array.isArray(usersList?.getUsers) || !updatedUser) return;

            if (typeof notListed === 'boolean') {
              setMembers({
                ...members,
                [memberTypes.USER]: members[memberTypes.USER]?.map((member) => {
                  if (member?.mId === updatedUser?.mId) {
                    return {
                      ...member,
                      mProperties: {
                        ...member.mProperties,
                        notListed: notListed,
                      },
                    };
                  }
                  return member;
                }),
              });
            }

            const updatedUsersList = usersList.getUsers.map((user) => {
              const { mMetaData, mProperties } = user;
              if (user?.mId === mId && mMetaData && mProperties) {
                const updatedMetadata = groups?.length
                  ? user?.mMetaData?.map((item) =>
                      item.key === 'groups' ? { ...item, value: groups.join(',') } : item,
                    )
                  : mMetaData;

                return {
                  ...user,
                  mMetaData: updatedMetadata,
                  mProperties: {
                    ...mProperties,
                    notListed: typeof notListed === 'boolean' ? notListed : mProperties?.notListed,
                  },
                };
              }
              return user;
            });
            client.writeQuery({
              query: GET_COGNITO_USERS,
              data: {
                getUsers: updatedUsersList,
              },
            });
            setOpenDialog(false);
          } catch (err) {
            logger.log(err);
          }
        },
      });
    },
    [logger, members, setMembers, updateUser],
  );
  return handleUserUpdate;
};

export default useUpdateUser;
