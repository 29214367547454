import { useMemo, useState } from 'react';
import { useTheme } from '@emotion/react';
import { IconButton } from '@material-ui/core';

import ICONS from 'assets/icons/icons';
import { Button } from 'components/buttons';
import DraggableDialog from 'components/dialogs/Draggable';
import Icon from 'components/icon';
import { LazyPlayer } from 'components/reactPlayer';
import { RenditionType } from 'types/graphqlTypes';

import Metadata from './metadata';

import {
  AnimatedClose,
  AnimatedInfo,
  BgImage,
  CloseIcon,
  Content,
  Filename,
  Footer,
  Header,
  HeaderText,
  HeaderTitle,
  Image,
  InfoContainer,
  InfoIconWrapper,
  InfoOverlay,
  Wrapper,
} from './styled';

type PropType = {
  handleClose: () => void;
  open: boolean;
  asset: RenditionType;
  accessToken: string;
};

const MediaViewer = ({ handleClose, open, asset, accessToken }: PropType) => {
  const theme = useTheme();
  const { type, uri, href, previewUri, videoPreviewUri, filename } = asset || {};
  const [dimension, setDimension] = useState({ width: 'fit-content', height: 'fit-content' });
  const [isHoveringInfoIcon, setIsHoveringInfoIcon] = useState(false);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const toggleOpen = () => setIsInfoOpen(!isInfoOpen);

  const url = useMemo(() => {
    if (type && type?.toLowerCase() === 'video' && uri)
      return `${videoPreviewUri ?? uri}${accessToken}`;
    if (type && type?.toLowerCase() === 'image' && href)
      return `${previewUri ?? href}${accessToken}`;
    return '';
  }, [type, uri, href, videoPreviewUri, previewUri]);

  const closeModal = () => {
    setIsInfoOpen(false);
    handleClose();
  };

  return (
    <DraggableDialog
      open={open}
      onClose={handleClose}
      minWidth={640}
      minHeight={410}
      dimension={dimension}
      setDimension={setDimension}
      style={{ zIndex: 1400 }}
    >
      <Wrapper>
        <Header className="dragHandler">
          <HeaderText>
            <HeaderTitle>Preview</HeaderTitle>
            <Filename>{filename}</Filename>
          </HeaderText>
          <AnimatedClose>
            <IconButton size="small" onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </AnimatedClose>
        </Header>
        <Content>
          {type?.toLowerCase() === 'video' && (
            <LazyPlayer height="100%" width="100%" url={url} controls playerRef={undefined} />
          )}
          {type?.toLowerCase() === 'image' && (
            <BgImage $image={url}>
              <Image src={url} alt="background" />
            </BgImage>
          )}

          <InfoContainer>
            {isInfoOpen && (
              <InfoOverlay open={isInfoOpen}>
                <Metadata asset={asset} />
              </InfoOverlay>
            )}
            <InfoIconWrapper isOpen={isInfoOpen}>
              <AnimatedInfo
                onMouseEnter={() => setIsHoveringInfoIcon(true)}
                onMouseLeave={() => setIsHoveringInfoIcon(false)}
                onClick={toggleOpen}
              >
                {isInfoOpen ? (
                  <Icon
                    hovered={isHoveringInfoIcon}
                    hoverColor={theme.palette.dina.highEmphasis}
                    icon={ICONS.ARROW_RIGHT}
                    width={24}
                    height={24}
                  />
                ) : (
                  <Icon
                    hovered={isHoveringInfoIcon}
                    hoverColor={theme.palette.dina.highEmphasis}
                    icon={ICONS.INFO}
                    width={24}
                    height={24}
                  />
                )}
              </AnimatedInfo>
            </InfoIconWrapper>
          </InfoContainer>
        </Content>
        <Footer>
          <Button
            variant="outlined"
            usage="outlined"
            autoWidth
            height={32}
            width={100}
            onClick={closeModal}
          >
            Close
          </Button>
        </Footer>
      </Wrapper>
    </DraggableDialog>
  );
};

export default MediaViewer;
