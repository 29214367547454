import { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { elementTypes } from 'components/editor/constants';
import { useSlate, ReactEditor } from 'slate-react';
import useCheckUserRight from 'hooks/useCheckUserRight';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import tooltipEnum from 'components/editor/constants/tooltipEnum';
import isSelectionInsideSectionDivider from 'components/editor/components/sectionDivider/utils/isSelectionInsideSectionDivider';
import wrapSection from 'components/editor/components/sectionDivider/utils/wrapSection';
import { getMetaDataValueFromConfig } from 'components/editor/utils/getMetaDataValueFromConfig';
import iconComponents from './constants/iconComponents';
import { Wrapper } from './styled';
import insertBlock from '../iconButton/utils/insertBlock';

const getTitle = (editor) =>
  editor?.children?.find((child) => child.type === elementTypes.TITLE)?.data?.content || '';

const BlockButton = ({ type, data, tooltipType }) => {
  const editor = useSlate();
  const { update, config } = useEditorContext();
  const IconButton = iconComponents[type];

  const [checkUserRight] = useCheckUserRight();
  const canUseSectionDivider = checkUserRight('feature', elementTypes.SECTION_DIVIDER);

  const disabled = useMemo(
    () =>
      type === elementTypes.SECTION_DIVIDER &&
      canUseSectionDivider &&
      isSelectionInsideSectionDivider(editor),
    [canUseSectionDivider, type, editor.selection],
  );

  const insertItems = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();

    switch (type) {
      case elementTypes.CONTENT:
        insertBlock({ editor, update, type: elementTypes.PARAGRAPH });
        break;
      case elementTypes.TAGS:
        insertBlock({ editor, update, type, data: { content: [], enableAutocomplete: true } });
        break;
      case elementTypes.AUTHOR:
      case elementTypes.SOURCE:
      case elementTypes.SECTION:
        insertBlock({ editor, update, type, data: { content: [] } });
        break;
      case elementTypes.LIVE_STREAM:
        insertBlock({ editor, update, type, data: { dvrHours: 48, mTitle: getTitle(editor) } });
        break;
      case elementTypes.LANGUAGE:
        insertBlock({ editor, update, type, data: { content: { value: '', title: '' } } });
        break;
      case elementTypes.PROGRAMS:
        insertBlock({
          editor,
          update,
          type,
          data: {
            mTitle: getMetaDataValueFromConfig(config, type, 'mTitle'),
            fieldId: getMetaDataValueFromConfig(config, type, 'fieldId'),
          },
        });
        break;
      case elementTypes.SECTION_DIVIDER:
        wrapSection({
          editor,
          update,
          type,
          data: { mTitle: getMetaDataValueFromConfig(config, type, 'defaultTitle') },
        });
        break;
      default:
        insertBlock({ editor, update, type, data });
        break;
    }

    // ensures new block gets focus after insert
    ReactEditor.focus(editor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderIcon = useMemo(
    () => (
      <>
        {IconButton && (
          <Wrapper $disabled={disabled}>
            <IconButton onClick={insertItems} title={tooltipEnum[tooltipType || type]} />
          </Wrapper>
        )}
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [insertItems, disabled],
  );

  return renderIcon;
};

BlockButton.propTypes = {
  /**
   * type of the block that the button will insert
   */
  type: PropTypes.string,
  /** default data passed from toolbar */
  data: PropTypes.shape({}),
  /** special type for tooltip */
  tooltipType: PropTypes.string,
};

BlockButton.defaultProps = {
  type: elementTypes.IMAGE,
  data: {},
  tooltipType: undefined,
};

export default memo(BlockButton);
