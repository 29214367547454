import { css } from '@emotion/react';
import styled from '@emotion/styled';

import transientOptions from 'theme/helpers';

const Arrow = styled('div')`
  margin: 6px 0px 6px 8px;
  user-select: none;
`;

const DropdownWrapper = styled.div`
  position: static;
  height: 32px;
  width: 48px;
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SelectWrapper = styled.div`
  cursor: pointer;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
`;

export const GraphicsSubtitle = styled('p')`
  margin: 0;
  ${({ theme }) => theme.typography.dina.caption};
  color: ${({ theme }) => theme.palette.dina.timelineGraphic};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  line-height: 114%;
  user-select: none;
`;

export const Circle = styled('span')`
  margin-right: 2px;
  height: 12px;
  min-width: 12px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.dina.timelineGraphic};
`;

const SubTitle = styled('p', transientOptions)<{ $hasGraphics?: boolean }>`
  margin: 0;
  ${({ theme }) => theme.typography.dina.caption};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  line-height: 114%;
  user-select: none;
  display: flex;
  align-items: center;
  ${({ $hasGraphics, theme }) =>
    $hasGraphics &&
    css`
      color: ${theme.palette.dina.timelineGraphic};
    `}
`;

export const PreLine = styled('div')`
  white-space: pre-line;
`;

const Title = styled('div')`
  ${({ theme }) => css`
    ${theme.typography.dina.caption};
    color: ${theme.palette.dina.highEmphasis};
  `}
  text-overflow: ellipsis;
  width: 100%;
  height: auto;
  line-height: 13px;
  max-height: 40px;
  user-select: none;
`;

const TitlesWrapper = styled.div`
  width: calc(100% - 32px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
  margin-right: 6px;
`;

export { Arrow, DropdownWrapper, SubTitle, Title, TitlesWrapper, Wrapper, SelectWrapper };
