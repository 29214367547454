import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Destination } from './destination-indicator-styled';

const DestinationIndicator = ({ destination, onClick, canOpenRundown, onOpenRundown }) => {
  const handleOpenRundown = (event) => {
    if (canOpenRundown) {
      event.stopPropagation();
      event.preventDefault();
      onOpenRundown();
    }
  };

  return (
    <Wrapper role="presentation" onClick={onClick}>
      <Destination role="presentation" $canOpenRundown={canOpenRundown} onClick={handleOpenRundown}>
        {destination}
      </Destination>
    </Wrapper>
  );
};

DestinationIndicator.propTypes = {
  /** Current destination of the instance */
  destination: PropTypes.string,
  /** Callback to be invoked when indicator is clicked */
  onClick: PropTypes.func,
};

DestinationIndicator.defaultProps = {
  destination: '',
  onClick: (event) => {},
};

export default DestinationIndicator;
