import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { List as MuiList, MenuItem as MuiMenuItem, Paper } from '@material-ui/core';

export const ListWrapper = styled(Paper)`
  z-index: 99999;
  position: absolute;
  ${({ top, left, theme }) => css`
    top: ${top}px;
    left: ${left}px;
    background-color: ${theme.palette.dina.surfaceCardDark};
    box-shadow: ${theme.palette.mode === 'light'
      ? `0px 0px 2px rgba(0, 0, 0, 0.15), 0px 0px 4px rgba(0, 0, 0, 0.15),
      0px 12px 24px rgba(0, 0, 0, 0.15)`
      : `0px 0px 2px rgba(0, 0, 0, 0.25), 0px 0px 4px rgba(0, 0, 0, 0.25),
      0px 12px 24px rgba(0, 0, 0, 0.25)`};
  `};
  border-radius: 8px;
  width: 320px;
`;

export const List = styled(MuiList)`
  border-radius: 4px;
`;

export const MenuItem = styled(MuiMenuItem)`
  color: ${({ theme }) => theme.palette.dina.highEmphasis};
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  pointer-events: none;
`;
