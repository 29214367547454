import { ReactEditor } from 'slate-react';
import { Transforms, BaseElement } from 'slate';
import isList from '../components/list/utils/isList';
import isSection from '../components/sectionDivider/utils/isSection';

/**
 * Selects a given SlateJS element
 *
 * @param {Object} editor SlateJS editor instance
 * @param {BaseElement} element SlateJS element
 * @returns {Object} SlateJS editor instance
 */

const selectElement = (editor, element) => {
  if (!element) return;
  if (isSection(element) || isList(element)) {
    return selectElement(editor, element?.children?.[0]);
  }
  try {
    const target = ReactEditor.findPath(editor, element);
    const path = [...target, 0];

    Transforms.select(editor, {
      anchor: {
        offset: 0,
        path,
      },
      focus: {
        offset: 0,
        path,
      },
    });
  } catch (error) {
    // console.log(error);
  }

  return editor;
};

export default selectElement;
