import { useCallback } from 'react';

import useDinaNavigate from 'hooks/useDinaNavigate';
import useGetStoryIdFromInstanceId from 'hooks/useGetStoryIdFromInstanceId';
import { ResourceType } from 'types/forms/forms';
import { MemberTypeEnum } from 'types/graphqlTypes';

export const isNavigatable = (
  mResourceType: unknown,
): mResourceType is 'story' | 'space' | 'instance' | 'rundown' => {
  if (typeof mResourceType !== 'string') return false;
  return ['story', 'space', 'instance', 'rundown'].includes(mResourceType);
};

const useGoToOrderResource = () => {
  const { getStoryId } = useGetStoryIdFromInstanceId();
  const { navigateTo } = useDinaNavigate();

  const goToResource = useCallback(
    async (resourceId: string, resourceType: ResourceType) => {
      return new Promise((resolve, reject) => {
        if (resourceType === MemberTypeEnum.Instance) {
          getStoryId(resourceId)
            .then((storyId) => {
              navigateTo('story', storyId, {
                tab: 'instances',
                entityId: resourceId,
              });
              resolve(true);
            })
            .catch(() => reject(new Error('Expected a story returned')));
        } else if (isNavigatable(resourceType)) {
          if (resourceId === 'home') navigateTo('home');
          else navigateTo(resourceType, resourceId);
          resolve(true);
        }
        resolve(true);
      });
    },
    [getStoryId, navigateTo],
  );

  return { goToResource };
};

export default useGoToOrderResource;
