import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Typography, FormControlLabel as MuiFormControlLabel } from '@material-ui/core';

export const DateTime = styled('div')`
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export const DatePicker = styled('div')`
  width: 194px;
`;

export const TimePicker = styled('div')`
  width: 134px;
`;

export const ResetTextButton = styled(Typography)`
  ${({ theme }) => css`
    ${theme.typography.dina.captionRegular};
    color: ${theme.palette.dina.disabled};
  `}
  text-decoration-line: underline;
  cursor: pointer;
  width: max-content;
  margin-left: auto;
  :hover {
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
  :disabled {
    opacity: 0.35;
  }
`;

export const FormControlLabel = styled(MuiFormControlLabel)`
  margin: 0;
  height: 40px;
  & .MuiFormControlLabel-label {
    ${({ theme }) => theme.typography.dina.listItemLabel};
    margin-left: 4px;
  }
`;
