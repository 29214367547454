import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import { Popover as PopoverComponent } from '@material-ui/core';

import { ReactComponent as ColorIcon } from 'assets/icons/systemicons/editor/color.svg';
import transientOptions from 'theme/helpers';

export const Color = styled(ColorIcon, transientOptions)`
  cursor: pointer;
  &:hover {
    ${({ theme }) => css`
      filter: ${theme.palette.dina.onHover};
      -webkit-filter: ${theme.palette.dina.onHover};
    `}
  }
  & .colorBox {
    fill: ${(props) => props.$markColor};
  }
`;

export const Popover = styled(PopoverComponent)`
  .MuiPopover-paper {
    background: transparent;
    margin-top: 4px;
  }
`;

export const Reset = styled('div')`
  position: absolute;
  width: 25px;
  height: 25px;
  left: 6px;
  top: 6px;
  font-size: 0;
  z-index: 2;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.palette.dina.mediumEmphasis};
  &:hover {
    ${({ theme }) => css`
      outline: 2px solid ${theme.palette.dina.highEmphasis};
      box-shadow: ${theme.palette.mode === 'light'
        ? '0 0 5px 2px rgba(0, 0, 0, 0.15)'
        : '0 0 5px 2px rgba(0, 0, 0, 0.25)'};
    `}
  }
`;
