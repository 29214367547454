import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ResizableDialog from 'components/resizableDialog';
import { ReactComponent as CalendarOff } from 'assets/icons/systemicons/calendar_small_off.svg';
import { ReactComponent as VisibilityIcon } from 'assets/icons/systemicons/editor/visibility.svg';
import useCheckUserRight from 'hooks/useCheckUserRight';
import Preview from '../../../preview';
import EditButton from '../../../cmsEditing/components/editButton';
import DatetimeIndicator from '../datetimeIndicator';
import useStyles from './schedulingButtons-styles';
import useGetRundown from 'hooks/useGetRundown';
import UserContext from 'contexts/UserContext';
import isRundownEditable from 'utils/rundown/isRundownEditable';

const SchedulingButtons = ({
  datetime,
  embeddedEndpoint,
  isCMS,
  openPublishingSettings,
  previewEndpoint,
  id,
  title,
  onCmsEditingClick,
  isCmsBlock,
  isFailedState,
  isDisabled,
  destinationId,
  isLinear,
}) => {
  const [checkUserRight] = useCheckUserRight();
  const canShowNewDesign = checkUserRight('feature', 'new-some-design');
  const canShowCmsIframe = checkUserRight('feature', 'cms-iframe-design');

  const showPreviewButtons = (canShowNewDesign || canShowCmsIframe) && isCMS && !isCmsBlock;

  const [getRundown] = useGetRundown('cache-first');
  const userContext = useContext(UserContext);
  const classes = useStyles({ isDisabled });

  const [relatedRundown, setRelatedRundown] = useState(null);
  const [openPreview, setOpenPreview] = useState(false);

  const openPreviewView = () => setOpenPreview(true);
  const closePreviewView = () => setOpenPreview(false);

  const hasPermissionForRundown = useMemo(() => {
    if (!isLinear || !destinationId || !relatedRundown) return true;

    /** doesn't have read access to the rundown */
    if ('__typename' in relatedRundown && relatedRundown.__typename === 'RestrictedErrorType') {
      return false;
    }

    return isRundownEditable({
      permissions: relatedRundown?.permissions,
      groups: userContext?.groups,
    });
  }, [isLinear, destinationId, relatedRundown]);

  const displayPreview = !!previewEndpoint && isCMS;
  const displayEdit = !!embeddedEndpoint && showPreviewButtons;

  const getAndSetRundown = useCallback(async () => {
    if (destinationId) {
      const data = await getRundown(destinationId, destinationId);
      setRelatedRundown(data);
    }
  }, [destinationId]);

  const handleOpenPublishingSettings = (event) => {
    if (!isDisabled && hasPermissionForRundown) {
      openPublishingSettings(event);
    }
  };

  useEffect(() => {
    if (isLinear) {
      void getAndSetRundown();
    }
  }, [isLinear, getAndSetRundown]);

  return (
    <>
      <div className={classes.buttonWrapper}>
        {displayEdit && <EditButton onClick={onCmsEditingClick} />}
        {displayPreview && (
          <div role="presentation" onClick={openPreviewView} className={classes.scheduleButton}>
            <VisibilityIcon className={classes.calendarIcon} />
            <div className={classes.schedule}>Preview</div>
          </div>
        )}
        {displayPreview && (
          <ResizableDialog open={openPreview}>
            <Preview id={id} title={title} closeDialog={closePreviewView} />
          </ResizableDialog>
        )}
      </div>
      {datetime ? (
        <DatetimeIndicator
          {...{ datetime, isFailedState }}
          isDisabled={isDisabled || !hasPermissionForRundown}
          format="MMM D / HH:mm"
          onClick={handleOpenPublishingSettings}
        />
      ) : (
        <div
          role="presentation"
          onClick={handleOpenPublishingSettings}
          className={`${classes.scheduleButtonDateTime}`}
        >
          <CalendarOff className={classes.calendarIcon} />
          <div className={classes.schedule}>Schedule...</div>
        </div>
      )}
    </>
  );
};

export default SchedulingButtons;
