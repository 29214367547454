import React, { memo, useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { useSlate, ReactEditor } from 'slate-react';
import { Editor, Range } from 'slate';

import useEditorContext from 'components/editor/hooks/useEditorContext';
import variants from 'components/editor/constants/types/editorVariants';

import Portal from 'components/portal';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Divider from 'components/divider';

import onScriptKeyDown from '../../utils/onScriptKeyDown';
import { SCRIPTS } from '../../utils/constants';

import { ListWrapper, List, MenuItem } from './styled';

const ScriptDropdown = () => {
  const ref = useRef();
  const editor = useSlate();
  const { update: handleUpdate, variant } = useEditorContext();
  const [target, setTarget] = useState(null);
  const [index, setIndex] = useState(0);
  const [search, setSearch] = useState('');
  const [top, setTop] = useState('200vh');
  const [left, setLeft] = useState('200vw');

  const isScriptEditor = useMemo(() => variant === variants.SCRIPT, [variant]);

  const scripts = useMemo(
    () =>
      SCRIPTS.filter((script) =>
        search ? script.toLowerCase().startsWith(search.toLowerCase()) : script,
      ).slice(0, 10),
    [search],
  );

  useEffect(() => {
    const { selection } = editor;
    if (selection && Range.isCollapsed(selection) && isScriptEditor) {
      const [start] = Range.edges(selection);
      const wordBefore = Editor.before(editor, start, { unit: 'word' });
      let beforeRange = wordBefore && Editor.range(editor, wordBefore, start);
      let beforeText = beforeRange && Editor.string(editor, beforeRange);
      let beforeMatch = beforeText && beforeText.match(/^\[/);

      if (!beforeMatch) {
        const before = wordBefore && Editor.before(editor, wordBefore);
        beforeRange = before && Editor.range(editor, before, start);
        beforeText = beforeRange && Editor.string(editor, beforeRange);
        beforeMatch = beforeText && beforeText.match(/^\[/);
      }

      const after = Editor.after(editor, start);
      const afterRange = Editor.range(editor, start, after);
      const afterText = Editor.string(editor, afterRange);
      const afterMatch = afterText.match(/^(\s|$)/);

      if (beforeMatch && afterMatch) {
        setTarget(beforeRange);
        setSearch(beforeMatch.input ? beforeMatch.input.slice(1) : '');
        setIndex(0);
        return;
      }
    }

    setTarget(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor.selection]);

  const handleKeyDown = useCallback(
    (event) =>
      onScriptKeyDown(editor, event, target, index, scripts, setIndex, setTarget, handleUpdate),

    [editor, handleUpdate, index, scripts, setIndex, setTarget, target],
  );

  useEffect(() => {
    isScriptEditor && window.addEventListener('keydown', handleKeyDown, true);
    return () => {
      window.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [handleKeyDown, isScriptEditor]);

  useEffect(() => {
    if (target && scripts.length > 0) {
      const domRange = ReactEditor.toDOMRange(editor, target);
      const rect = domRange.getBoundingClientRect();
      setTop(`${rect.top + window.pageYOffset + 24}px`);
      setLeft(`${rect.left + window.pageXOffset}px`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scripts.length, target]);

  return isScriptEditor && target && scripts.length ? (
    <ClickAwayListener
      onClickAway={() => {
        setTarget(null);
      }}
    >
      <Portal>
        <ListWrapper
          ref={ref}
          top={top}
          left={left}
          onMouseDown={(e) => {
            // prevent toolbar from taking focus away from editor
            e.preventDefault();
          }}
        >
          <List>
            {scripts.map((char, i) => (
              <MenuItem
                key={char}
                selected={i === index}
                dense
                onClick={(event) => event.preventDefault()}
              >
                <span>{char}</span>
                <span>[{char}]</span>
              </MenuItem>
            ))}
            <Divider />
          </List>
        </ListWrapper>
      </Portal>
    </ClickAwayListener>
  ) : null;
};

export default memo(ScriptDropdown);
