import styled from '@emotion/styled';

import { ReactComponent as ChatEnabled } from 'assets/icons/systemicons/HeaderNavbar/chat_enabled.svg';
import fallbackImage from 'assets/images/default/defaultThumbnail.png';
import Avatar from 'components/avatar/Avatar';
import memberTypes from 'graphql/memberTypes';

const ChatIcon = styled(ChatEnabled)`
  width: 40px;
  height: 40px;
  & path {
    fill-opacity: 1;
  }
`;

const Image = styled('img')`
  width: 40px;
  height: 40px;
  border-radius: 2px;
  object-fit: cover;
`;

const Icon = ({
  variant = memberTypes.MESSAGE,
  mAvatarUrl,
}: {
  variant: string;
  mAvatarUrl: string;
}) => {
  if (variant === memberTypes.MESSAGE || variant === memberTypes.CONVERSATION) {
    if (mAvatarUrl) return <Avatar src={mAvatarUrl} size={40} />;
    return <ChatIcon />;
  }
  return <Image src={mAvatarUrl || fallbackImage} alt="" />;
};

export default Icon;
