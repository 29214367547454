import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { ReactComponent as Settings } from 'assets/icons/systemicons/columns.svg';
import { ReactComponent as Instance } from 'assets/icons/systemicons/ContainerLeft_Navbar/instances_off.svg';
import { ReactComponent as Story } from 'assets/icons/systemicons/ContainerLeft_Navbar/Left_AllStories_off.svg';
import { ReactComponent as Rundown } from 'assets/icons/systemicons/ContainerLeft_Navbar/rundowns_off.svg';
import { ReactComponent as Copy } from 'assets/icons/systemicons/copy.svg';
import { ReactComponent as CopyApproved } from 'assets/icons/systemicons/copyApproved.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/systemicons/delete_small.svg';
import { ReactComponent as Space } from 'assets/icons/systemicons/spaces_off.svg';
import { Button } from 'components/buttons';
import transientOptions from 'theme/helpers';
interface GridContainerProps {
  maxHeight: number | undefined;
}

interface StyledButtonProps {
  $dark?: boolean;
}

interface TaskProps {
  $width: number;
}

export const HeaderSection = styled.div`
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
`;

export const HeaderWrapper = styled.div`
  min-width: 100px;
`;

export const StyledPlus = styled.div`
  font-size: 22px;
  display: flex;
  padding: 6px;
  height: 100%;
  &:hover {
    background: ${({ theme }) => theme.palette.dina.hoverOverlay};
  }
`;

export const TaskWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 4px;
  justify-content: center;
  border-right: 1px solid ${({ theme }) => theme.palette.dina.buttonBorderOutlined};
  &:hover {
    background: ${({ theme }) => theme.palette.dina.hoverOverlay};
  }
`;

export const TaskText = styled.div`
  font-size: 12px;
  white-space: pre;
  padding: 1px;
  text-align: left;
  ${({ theme }) => css`
    color: ${theme.palette.dina.mediumEmphasis};
  `}
`;

export const TaskProgress = styled.div<TaskProps>`
  height: 100%;
  position: absolute;
  border-radius: 2px;
  background: orange;
  ${({ $width }) => css`
    width: ${$width}%;
    background: ${$width === 100 ? 'rgb(0, 138, 0)' : 'orange'};
  `}
`;

export const TaskProgressWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background: ${({ theme }) => theme.palette.dina.watermark};
`;

export const StyledButton = styled(Button, transientOptions)<StyledButtonProps>`
  padding: 0;
  gap: 0;
  ${({ $dark, theme }) =>
    $dark
      ? css`
          border-color: ${theme.palette.dina.whiteButtonBorderOutlined};
          ${StyledPlus} {
            color: ${theme.palette.dina.whiteHighEmphasis};
            :hover {
              background: ${theme.palette.dina.whiteHoverOverlay};
            }
          }
          ${TaskWrapper} {
            border-color: ${theme.palette.dina.whiteButtonBorderOutlined};
            :hover {
              background: ${theme.palette.dina.whiteHoverOverlay};
            }
          }
          ${TaskProgressWrapper} {
            background: ${theme.palette.dina.whiteWatermark};
          }
          ${TaskText} {
            color: ${theme.palette.dina.whiteHighEmphasis};
          }
        `
      : ''}
`;

const IconStyles = `
  cursor: pointer;
  width: 20px;
  height: 20px;
  &:hover path {
    fill-opacity: 1;
  }
`;

export const ConfigIconOff = styled(Settings)`
  ${IconStyles}
`;

export const ConfigIconOn = styled(Settings)`
  ${IconStyles}
  ${({ theme }) => css`
    path {
      fill: ${theme.palette.dina.warningBorder} !important;
      fill-opacity: 1 !important;
    }
    &:hover path {
      fill: ${theme.palette.dina.warningBorder};
      fill-opacity: 1;
    }
  `}
`;

export const SpacesIcon = styled(Space)`
  ${IconStyles}
`;

export const RemoveIcon = styled(DeleteIcon)`
  ${IconStyles}
  width: 24px;
  height: 24px;
  position: relative;
  top: -3px;
  left: -2px;
`;

export const CopyDoneIcon = styled(CopyApproved)`
  ${IconStyles}
`;

export const StoryIcon = styled(Story)`
  ${IconStyles}
`;

export const CopyIcon = styled(Copy)`
  ${IconStyles}
`;

export const InstanceIcon = styled(Instance)`
  ${IconStyles}
`;

export const RundownIcon = styled(Rundown)`
  ${IconStyles}
`;

export const InlineTagWrapper = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Switches = styled.div`
  display: flex;
  align-items: center;
`;

export const SwitchLabel = styled.div`
  ${({ theme }) => theme.typography.dina.listItemLabel};
  cursor: pointer;
  font-size: 12px;
  margin-left: -4px;
  ${({ theme }) => css`
    color: ${theme.palette.dina.mediumEmphasis};
    &:hover {
      color: ${theme.palette.dina.highEmphasis};
    }
  `}
`;

export const OrderGridWrapper = styled.div<GridContainerProps>`
  flex: 1;
  width: 100%;
  overflow: auto;
  .rdg {
    ${({ theme }) => css`
      --rdg-header-background-color: ${theme.palette.dina.surfaceAppBackgroundNavLevel1};
      --rdg-row-hover-background-color: none;
      --rdg-color: ${theme.palette.dina.mediumEmphasis};
      --rdg-background-color: ${theme.palette.dina.surfaceCard};
      --rdg-border-color: ${theme.palette.dina.borderResting};
    `}
    div[role='columnheader'] {
      ${({ theme }) => theme.typography.dina.overline};
      display: flex;
      flex-direction: column;
      div,
      span {
        margin: auto;
      }
    }
    block-size: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : '250px')};
  }
  .rdg-row.isDragging .rdg-cell {
    opacity: 0.4;
  }

  .rdg-row.isOver .rdg-cell {
    border-bottom: 2px solid white;
  }

  .rdg-row:nth-of-type(odd) .rdg-cell:not(.error) {
    background: rgba(237, 237, 255, 0.05);
    &.disabled {
      background: rgba(0, 0, 0, 0.2) !important;
      &:hover {
        background: rgb(237 237 255 / 10%);
      }
    }
    &:hover {
      background: rgb(237 237 255 / 10%);
    }
  }
  .rdg-row:nth-of-type(even) .rdg-cell:hover {
    background: rgb(237 237 255 / 10%);
  }
  .rdg-cell.disable {
    cursor: not-allowed;
  }
  .rdg-cell.error {
    background: rgba(255, 0, 0, 0.2);
    cursor: not-allowed;
  }
`;

export const Wrapper = styled('div')`
  display: flex;
  gap: 8px;
  padding: 8px;
`;

export const Panel = styled('div')`
  flex: 1;
  width: 150px;
  max-height: 60vh;
  overflow: auto;
`;

export const Group = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  margin: 8px 0 4px 0;
  background-color: ${({ theme }) => theme.palette.dina.blackHoverOverlay};
  user-select: none;
`;

export const EmptyRows = styled('div')`
  text-align: center;
  grid-column: 1/-1;
  margin-top: 20px;
`;

export const EmptyOptions = styled('div')`
  display: flex;
  justify-content: center;
  user-select: none;
`;

export const OrderGridFullWrapper = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
