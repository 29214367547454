import React, { memo } from 'react';
import { markTypes, elementTypes } from 'components/editor/constants';
import useCheckUserRight from 'hooks/useCheckUserRight';
import MarkButton from '../markButton';
import ColorButton from '../colorButton';
import AutomationButton from '../automationButton';
import { OuterWrapper, InnerWrapper, Container, Empty } from './styled';

const { BOLD, ITALIC, UNDERLINE } = markTypes;

const {
  CAMERA,
  PACKAGE,
  VOICE_OVER,
  FULLSCREEN_GRAPHICS,
  LIVE,
  DVE,
  JINGLE,
  BREAK,
  TELEPHONE,
  ADLIB,
  AUDIO,
  ACCESSORY,
  OVERLAY_GRAPHICS,
} = elementTypes;

const Wrapper = ({ children }) => (
  <OuterWrapper>
    <InnerWrapper>{children}</InnerWrapper>
  </OuterWrapper>
);

const LinearToolbar = ({ platformKind }) => {
  const [checkUserRight] = useCheckUserRight();
  const canShowStarCG = checkUserRight('feature', 'starcg');

  if (platformKind === 'audio')
    return (
      <Container>
        <Wrapper>
          <MarkButton type={BOLD} />
          <MarkButton type={ITALIC} />
          <MarkButton type={UNDERLINE} />
          <ColorButton />
        </Wrapper>
      </Container>
    );

  return (
    <Container>
      <Wrapper>
        <MarkButton type={BOLD} />
        <MarkButton type={ITALIC} />
        <MarkButton type={UNDERLINE} />
        <ColorButton />
      </Wrapper>
      <Empty />
      <Wrapper>
        <AutomationButton type={CAMERA} />
        <AutomationButton type={PACKAGE} />
        <AutomationButton type={VOICE_OVER} />
        <AutomationButton type={FULLSCREEN_GRAPHICS} />
        <AutomationButton type={LIVE} />
        <AutomationButton type={DVE} />
      </Wrapper>
      <Wrapper>
        <AutomationButton type={JINGLE} />
        <AutomationButton type={BREAK} />
        <AutomationButton type={TELEPHONE} />
        <AutomationButton type={ADLIB} />
      </Wrapper>
      <Wrapper>
        {canShowStarCG && <AutomationButton type={OVERLAY_GRAPHICS} />}
        <AutomationButton type={AUDIO} />
        <AutomationButton type={ACCESSORY} />
      </Wrapper>
    </Container>
  );
};

export default memo(LinearToolbar);
