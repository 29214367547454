import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import TextArea from 'components/textArea/TextArea';
import ShowItem from '../showItem';
import AddItem from '../addItem';
import useStyles from './add-description-styles';

const AddDescriptionView = ({ addItemTitle, description, helperText, onUpdate }) => {
  const classes = useStyles();
  const [showTextField, setShowTextField] = useState(false);
  const [descriptionValue, setDescriptionValue] = useState(description || '');

  const onChange = (newValue) => {
    setDescriptionValue(newValue);
    onUpdate(newValue);
  };

  return (
    <div
      onBlur={(event) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
          setShowTextField(false);
        }
      }}
    >
      {!showTextField && descriptionValue && (
        <ShowItem
          title="Description"
          content={descriptionValue}
          onClick={() => setShowTextField(true)}
        />
      )}

      {!showTextField && !descriptionValue && (
        <AddItem title={addItemTitle} onClick={() => setShowTextField(true)} />
      )}

      {showTextField && (
        <div className={classes.wrapper}>
          <Typography classes={{ root: classes.inputHeadline }}>DESCRIPTION</Typography>

          <div className={classes.textAreaDiv}>
            <TextArea
              type="descriptionField"
              autoFocus
              value={descriptionValue}
              description={helperText}
              rows="3"
              {...{ onChange }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

AddDescriptionView.propTypes = {
  /** Defines the title text for AddItem component  */
  addItemTitle: PropTypes.string,
  /** Defines the description text  */
  description: PropTypes.string,
  /** Defines the description text for TextArea component */
  helperText: PropTypes.string,
  /** Callback to be invoked on text input change */
  onUpdate: PropTypes.func,
};

AddDescriptionView.defaultProps = {
  addItemTitle: '',
  description: '',
  helperText: '',
  onUpdate: () => {},
};

export default AddDescriptionView;
