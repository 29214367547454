import styled from '@emotion/styled';

import { ReactComponent as Add } from 'assets/icons/systemicons/add.svg';
import { ReactComponent as Close } from 'assets/icons/systemicons/close.svg';

export const GridWrapper = styled.div`
  height: 70vh;
`;

export const CloseIcon = styled(Close)`
  cursor: pointer;
  &:hover path {
    fill-opacity: 1;
  }
`;

export const AddIcon = styled(Add)`
  width: 18px;
  height: 18px;
  margin-left: 0 !important;
  path {
    fill-opacity: 1;
  }
`;

export const HeaderButtons = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const TitleWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
`;

export const SelectAssigneeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const ContentWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
