import { useContext, useState } from 'react';
import UserCtx from 'contexts/UserContext';
import { useApolloClient } from '@apollo/client';

import { useDinaTheme, useChangedTheme } from 'store';
import useUpdateSettings from 'hooks/useUpdateSettings';
import GET_SETTINGS from 'graphql/queries/getSettings';

import { useChangedUserSettings } from '../atoms';

const useUpdateUserSettings = () => {
  const [changedUserSettings] = useChangedUserSettings();
  const [, setDinaTheme] = useDinaTheme();
  const [changedTheme] = useChangedTheme();
  const user = useContext(UserCtx);
  const { mId: userId } = user;
  const [loading, setLoading] = useState(false);
  const client = useApolloClient();

  const updateSettings = useUpdateSettings();

  const updateUserSettings = async () => {
    const mMetaData = changedUserSettings.map(({ originalValue, ...rest }) => ({ ...rest }));

    setLoading(true);

    await updateSettings({ mId: 'settings', mRefId: userId, mMetaData });

    await client.query({
      query: GET_SETTINGS,
      variables: {
        generalSettingsInput: { mId: 'settings', mRefId: 'general' },
        userSettingsInput: { mId: 'settings', mRefId: userId },
      },
      fetchPolicy: 'network-only',
    });

    if (changedTheme) setDinaTheme(changedTheme);
    setLoading(false);
  };
  return [updateUserSettings, loading];
};

export default useUpdateUserSettings;
