/**
 *  Hook for bookmarking a story
 *
 */

import { useMutation } from '@apollo/client';

import updateBookmarks from 'graphql/mutations/updateBookmarks';
import { useUserConfig } from 'store';
import { UserKeyedBookmarkType, useUserBookmarks } from 'store/bookmarks';
import { UserBookmark } from 'types';

const getNewBookmarks = (
  bookmarks: UserKeyedBookmarkType,
  bookmark: UserBookmark,
  isBookmarked: boolean,
) => {
  if (!isBookmarked)
    return {
      ...bookmarks,
      [bookmark.bookmarkedId]: bookmark,
    };

  const newBookmarks = { ...bookmarks };
  delete newBookmarks[bookmark.bookmarkedId];
  return newBookmarks;
};

const useToggleBookmark = () => {
  const [mutate] = useMutation(updateBookmarks);
  const [userConfig] = useUserConfig();
  const { mId } = userConfig;
  const [bookmarks, setBookmarks] = useUserBookmarks();

  const toggleBookmark = async (newBookMark: UserBookmark) => {
    const isBookmarked = Boolean(bookmarks[newBookMark.bookmarkedId]);

    const newBookmarks = getNewBookmarks(bookmarks, newBookMark, isBookmarked);
    setBookmarks(newBookmarks);
    await mutate({
      variables: {
        input: {
          mId,
          mBookmarks: Object.values(newBookmarks),
        },
      },
    });
  };

  return toggleBookmark;
};

export default useToggleBookmark;
