import { useEffect, useState } from 'react';

import AddMemberDropdown from 'components/addMember';
import MemberLabel from 'components/addMember/MemberLabel';
import { SelectAssigneeWrapper } from 'components/orderFormDialog/styled';
import Text from 'components/text/Text';
import Tooltip from 'components/tooltip';
import { useAllMembersKeyed } from 'store';
import { FieldValue } from 'types/forms/forms';
import { DefaultValueWrapper } from 'types/graphqlTypes';
import { AssignedMember } from 'types/members';

import { FieldHeader } from '../../styled';
import { FieldProps } from '../fields';

export const getTextValue = (
  value: FieldValue,
  defaultValue: DefaultValueWrapper,
  ignoreDefaultValue: boolean,
): string => {
  // Should not happen - lets normalize.
  if (Array.isArray(value)) {
    return '';
  }

  if (typeof value === 'string') {
    return value;
  }

  if (!ignoreDefaultValue && typeof defaultValue.value === 'string') {
    return defaultValue.value;
  }
  return '';
};

const normalizeFilter = (val: string[] | undefined) => {
  if (!val || (Array.isArray(val) && !val.length)) return undefined;
  return val;
};

export function UserField({
  fieldModel,
  fieldSettings,
  defaultFieldSettings,
  value,
  setValue,
  errorMessage,
  setError,
  style,
  ignoreDefaultValue,
}: FieldProps) {
  const [allMembersKeyed] = useAllMembersKeyed();
  const [assignee, setAssignee] = useState<AssignedMember | null>(null);
  const [isFindingAssignee, setIsFindingAssignee] = useState(false);
  const { defaultValue, required, filter } = fieldModel;
  const { hint, label } = fieldSettings ?? defaultFieldSettings;

  const normalizedFilter = normalizeFilter(filter);

  const onSelectAssignee = (newAssignee: AssignedMember[]) => {
    setValue(newAssignee[0]?.mId ?? '');
  };

  useEffect(() => {
    const val = getTextValue(value, defaultValue, ignoreDefaultValue ?? false);
    if (val) {
      const member = allMembersKeyed[val] as unknown as AssignedMember | undefined;
      setAssignee(member ?? null);
    } else {
      setAssignee(null);
    }
  }, [value, defaultValue, allMembersKeyed, ignoreDefaultValue]);

  useEffect(() => {
    if (required && !assignee) {
      setError('Required value');
    } else if (required && assignee && errorMessage) {
      setError(undefined);
    } else if (!required && errorMessage) {
      setError(undefined);
    }
  }, [assignee, errorMessage, required]);

  const onRemoveClick = (ev: React.MouseEvent<Element, MouseEvent>) => {
    ev.stopPropagation();
    setAssignee(null);
    setValue('');
  };

  const onClose = () => {
    setIsFindingAssignee(false);
  };

  return (
    <Tooltip title={hint ?? ''}>
      <SelectAssigneeWrapper
        style={{ marginBottom: '4px', gap: label ? '5px' : '0px', ...(style || {}) }}
      >
        <FieldHeader>{label}</FieldHeader>
        {isFindingAssignee ? (
          <AddMemberDropdown
            variants={normalizedFilter}
            variant="all" // fallback to all in case filter is undefined
            singleChoice
            autoFocus
            disableClearable={true}
            selectedMembers={assignee ? [assignee] : []}
            setSelectedMembers={onSelectAssignee}
            placeholderText="Find member"
            noOptionsText="No matches found"
            onClose={onClose}
            injectedMembers={undefined}
          />
        ) : (
          <>
            <MemberLabel
              variant="form"
              member={assignee}
              onClick={() => setIsFindingAssignee(true)}
              onRemoveClick={onRemoveClick}
            />
            {errorMessage && (
              <Text variant="caption" color="statusWarning">
                {errorMessage}
              </Text>
            )}
          </>
        )}
      </SelectAssigneeWrapper>
    </Tooltip>
  );
}
