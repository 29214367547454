/* eslint-disable sort-imports */
import { useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { TableRow } from '@material-ui/core';

import { ReactComponent as DeleteIcon } from 'assets/icons/systemicons/delete.svg';
import { ReactComponent as ResetNumberingIcon } from 'assets/icons/systemicons/rundown/gridIcons/reset.svg';
import { RowCell } from 'components/editMdfDialog/components/FieldModelRow';
import { Input } from 'components/input/styled';
import Tooltip from 'components/tooltip';
import LWCheckbox from 'features/orderForm/components/LWCheckbox';
import LWSelect from 'features/orderForm/components/LWSelect';
import { HStack } from 'layouts/box/Box';
import { Alternative, AuthType, IntegrationType } from 'types/graphqlTypes';
import { isoToLocaleShort } from 'utils/datetimeHelpers';

const DeleteButton = styled(DeleteIcon)`
  cursor: pointer;
  height: 18px;
  width: 18px;
  &:hover {
    path {
      fill-opacity: 1;
      stroke-opacity: 1;
    }
  }
`;

const ResetButton = styled(ResetNumberingIcon)`
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-right: 6px;
  &:hover {
    path {
      fill-opacity: 1;
      stroke-opacity: 1;
    }
  }
`;

const StyledRowCell = styled(RowCell)`
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
`;

const StyledTableRow = styled(TableRow)<{ $selected: boolean }>`
  ${({ $selected, theme }) =>
    $selected &&
    css`
      td {
        background: ${theme.palette.dina.selectedNotActive};
        color: ${theme.palette.dina.highEmphasis};
      }
    `}
`;

const authOptions: Alternative[] = [
  {
    id: AuthType.None,
    label: 'None',
    value: AuthType.None,
  },
  {
    id: AuthType.Apikey,
    label: 'API key',
    value: AuthType.Apikey,
  },
];

interface RowProps {
  plugin: IntegrationType;
  isSelected: boolean;
  hasChanges: boolean;
  onSelect: (action: IntegrationType) => void;
  onDeletePlugin: (id: string) => void;
  onUpdatePlugin: (updates: Partial<IntegrationType>) => void;
  onResetPlugin: (id: string) => void;
}

export function EditPluginRow({
  plugin,
  hasChanges,
  onDeletePlugin,
  onUpdatePlugin,
  onResetPlugin,
  isSelected,
  onSelect,
}: Readonly<RowProps>) {
  const { mTitle, mDescription, auth, iconUrl, mUpdatedAt, endpoint, mActive, externalId } = plugin;
  const [title, setTitle] = useState(mTitle);
  const [localEndpoint, setLocalEndpoint] = useState(endpoint ?? '');
  const [localIconUrl, setLocalIconUrl] = useState(iconUrl ?? '');
  const [description, setDescription] = useState(mDescription ?? '');

  return (
    <StyledTableRow $selected={isSelected}>
      <StyledRowCell
        style={hasChanges ? { color: 'orange' } : undefined}
        onClick={() => onSelect(plugin)}
      >
        <Tooltip title="Test action">
          <span>
            {externalId}
            {hasChanges ? '*' : ''}
          </span>
        </Tooltip>
      </StyledRowCell>
      <RowCell>
        <Input
          value={title}
          onChange={(ev) => setTitle(ev.target.value)}
          onBlur={() => onUpdatePlugin({ mTitle: title })}
          placeholder="Type menu label here.."
        />
      </RowCell>
      <RowCell>
        <Input
          value={description}
          onChange={(ev) => setDescription(ev.target.value)}
          onBlur={() =>
            onUpdatePlugin({ mDescription: description.length === 0 ? null : description })
          }
          placeholder="Optional description"
        />
      </RowCell>
      <RowCell>
        <Input
          value={localIconUrl}
          onChange={(ev) => setLocalIconUrl(ev.target.value)}
          onBlur={() =>
            onUpdatePlugin({ iconUrl: localIconUrl.length === 0 ? null : localIconUrl })
          }
          placeholder="https://url-to-your-icon.com"
        />
      </RowCell>
      <RowCell>
        <LWSelect
          value={auth?.type ?? AuthType.None}
          options={authOptions}
          setValue={(val) =>
            onUpdatePlugin({ auth: { type: val as AuthType, __typename: 'Auth' } })
          }
        />
      </RowCell>
      <RowCell>
        <Input
          value={localEndpoint}
          onChange={(ev) => setLocalEndpoint(ev.target.value)}
          onBlur={() =>
            onUpdatePlugin({ endpoint: localEndpoint.length === 0 ? null : localEndpoint })
          }
          placeholder="REST endpoint"
        />
      </RowCell>
      <RowCell>
        <LWCheckbox selected={mActive} setValue={() => onUpdatePlugin({ mActive: !mActive })} />
      </RowCell>
      <RowCell>{isoToLocaleShort(mUpdatedAt)}</RowCell>
      <RowCell>
        <HStack justifyContent="start">
          <Tooltip noArrow title="Discard changes">
            <ResetButton onMouseDown={() => onResetPlugin(plugin.mRefId)} />
          </Tooltip>
          <Tooltip noArrow title="Delete field">
            <DeleteButton onMouseDown={() => onDeletePlugin(plugin.mRefId)} />
          </Tooltip>
        </HStack>
      </RowCell>
    </StyledTableRow>
  );
}
