import useShareStory from 'hooks/useShareStory';
import Scrollbar from 'screens/main/components/scrollbar';
import List from '../../list-view';
import { useMembers } from 'store';

const ContactListContainer = ({ type, ...rest }) => {
  const [members] = useMembers();

  const [assignMemberToStory] = useShareStory();
  if (members) {
    const addUserToStory = async (userId, storyId) => {
      assignMemberToStory(storyId, userId, type);
    };

    const contacts = members[type].filter((contact) => !contact?.mProperties?.notListed);

    return (
      <Scrollbar>
        <List
          type={type}
          members={contacts}
          dropHandler={(userId, storyId) => addUserToStory(userId, storyId)}
          {...rest}
        />
      </Scrollbar>
    );
  }

  return null;
};

export default ContactListContainer;
