import { memo, useCallback, useEffect, useMemo } from 'react';
import { capitalize } from 'lodash';
import { ReactEditor, RenderElementProps, useReadOnly, useSelected, useSlate } from 'slate-react';

import { ReactComponent as TaskIcon } from 'assets/icons/systemicons/task_small.svg';
import Avatar, { AvatarVariant } from 'components/avatar/Avatar';
import { elementTypes } from 'components/editor/constants';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import { getStatusOptions } from 'components/editor/Sidepanel';
import { useEditorMolecule } from 'components/editor/store';
import Text from 'components/text/Text';
import Tooltip from 'components/tooltip/Tooltip';
import useCheckUserRight from 'hooks/useCheckUserRight';
import { useGetOrder } from 'screens/space/api/useGetOrdersAndForms';
import { useAllMembers } from 'store';
import { GetOrderEnum } from 'types/graphqlTypes';
import accessibleOnClick from 'utils/accessibleOnClick';
import preventDefaultAndPropagation from 'utils/preventDefaultAndStopPropagation';

import DragAndDrop from '../dragAndDrop';

import {
  AvatarWrapper,
  FocusWrapper,
  IconWrapper,
  LabelWrapper,
  OwnerIcon,
  OwnerWrapper,
  SelectWrapper,
  StatusWrapper,
} from './styled';

interface MemberAvatarProps {
  mOwner?: string;
  mAssignee?: string;
}
function MemberAvatar({ mOwner, mAssignee }: Readonly<MemberAvatarProps>) {
  const [members] = useAllMembers();

  const owner = members.find((m) => m.mId === mOwner);
  const assignee = members.find((m) => m.mId === mAssignee);

  return (
    <AvatarWrapper>
      {owner && (
        <OwnerWrapper>
          <Avatar
            className="avatar"
            variant={owner.mType as AvatarVariant}
            src={owner.mAvatarUrl}
            size={20}
            title={owner.mTitle}
          />
          <OwnerIcon className="skipOverride" />
        </OwnerWrapper>
      )}
      {assignee && (
        <Avatar
          className="avatar"
          variant={assignee.mType as AvatarVariant}
          src={assignee.mAvatarUrl}
          size={20}
          title={assignee.mTitle}
        />
      )}
    </AvatarWrapper>
  );
}

function OrderBlock({
  attributes = { 'data-slate-node': 'element', ref: null },
  children = null,
  element = {
    type: elementTypes.PARAGRAPH,
    data: {},
    children: [],
  },
}: Readonly<RenderElementProps>) {
  const [checkUserRight] = useCheckUserRight();
  const canShowMdfBlocks = checkUserRight('feature', 'mdfBlocks');
  const isSelected = useSelected();
  const isReadonly = useReadOnly();

  const editor = useSlate();
  const { useSelectedBlock, useShowSidePanel } = useEditorMolecule();
  const { resourceDetails } = useEditorContext();

  const [selectedBlock, setSelectedBlock] = useSelectedBlock();
  const [, setShowSidePanel] = useShowSidePanel();

  const { mId, taskType, mStatus } = element.data ?? {};
  const { order } = useGetOrder(mId, GetOrderEnum.Resource);

  const isOrderSelected = selectedBlock?.data?.mId === element.data?.mId;

  useEffect(() => {
    if (isSelected) setSelectedBlock(element);
  }, [isSelected, isReadonly]);

  const selectOrderBlock = useCallback(
    (event: React.MouseEvent) => {
      if (isReadonly) preventDefaultAndPropagation(event);
      ReactEditor.focus(editor);
      if (canShowMdfBlocks) setShowSidePanel(true);
      setSelectedBlock(element);
    },
    [isReadonly],
  );

  const status = useMemo(() => {
    const { mFormId } = order ?? {};
    const { orderFormMap } = resourceDetails ?? {};

    const statusOptions = getStatusOptions(mFormId, orderFormMap);

    return statusOptions?.find((stsOption) => stsOption.value === order?.mStatus);
  }, [order]);

  return (
    <div {...attributes}>
      <DragAndDrop element={element} enableReadOnly={false}>
        {children}
        <FocusWrapper contentEditable={false}>
          <Tooltip title="View details">
            <SelectWrapper
              $selected={isReadonly ? isOrderSelected : isSelected}
              {...accessibleOnClick(selectOrderBlock, 'presentation')}
            >
              <IconWrapper>
                <TaskIcon className="skipOverride" />
              </IconWrapper>
              <LabelWrapper>
                <Text as="p" variant="caption" color="mediumEmphasis">
                  {taskType}
                </Text>
              </LabelWrapper>
              <MemberAvatar mOwner={order?.mOwner} mAssignee={order?.mAssignee} />
              <StatusWrapper>
                <Text variant="button">{capitalize(status?.label ?? mStatus)}</Text>
              </StatusWrapper>
            </SelectWrapper>
          </Tooltip>
        </FocusWrapper>
      </DragAndDrop>
    </div>
  );
}

export default memo(OrderBlock);
