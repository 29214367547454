import LoadingIndicator from 'components/loadingIndicator';
import useDebouncedLoading from 'hooks/useDebouncedLoading';

interface Props {
  isLoading?: boolean;
  debounceTime?: number;
}

const DebouncedLoadingIndicator = ({ isLoading = true, debounceTime = 500 }: Props) => {
  const showLoading = useDebouncedLoading(isLoading, debounceTime);

  return showLoading ? <LoadingIndicator /> : null;
};

export default DebouncedLoadingIndicator;
