import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  seenInfo: {
    display: 'flex',
    '& > *': {
      marginLeft: '4px',
    },
  },
}));

export default useStyles;
