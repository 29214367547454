import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { Storage } from '@aws-amplify/storage';

import { EditorValue } from 'types/editor';
import { replaceTab } from 'utils/content/contentUtil';
import useLogger from 'utils/useLogger';

import { createImageUrl, useIsProdValid } from './useImageUrl';

type SetLoading = Dispatch<SetStateAction<boolean>>;
type SetData = Dispatch<SetStateAction<EditorValue | null>>;

/**
 * Fetches and processes content based on a provided key.
 *
 * @param contentKey - The key to identify the content to retrieve.
 * @param isProdValid - A flag indicating whether to use a production-valid URL.
 * @param errorLogger - A function to log errors during content retrieval.
 * @param setData - A function to set the retrieved and processed content data (optional).
 * @param setLoading - A function to control a loading indicator (optional).
 * @returns A Promise that resolves to the normalized content data or null on error.
 */
export const getContent = async (
  contentKey: string,
  isProdValid: boolean,
  errorLogger: (error: Error) => void,
  setData: SetData | undefined,
  setLoading: SetLoading | undefined,
) => {
  if (setLoading) setLoading(true);

  try {
    const validUrl = isProdValid
      ? createImageUrl(contentKey)
      : await Storage.get(contentKey, {
          customPrefix: { public: '' },
        });

    const response = await fetch(validUrl);
    const jsonResponse = (await response.json()) as EditorValue;

    const normalizedData = replaceTab(jsonResponse);
    if (setData && setLoading) {
      setData(normalizedData);
      setLoading(false);
    }
    return normalizedData;
  } catch (err: unknown) {
    if (err instanceof Error) errorLogger(err);

    if (setData && setLoading) {
      setData(null);
      setLoading(false);
    }
    return null;
  }
};

/**
 * A custom hook that fetches and manages content using `getContent`.
 *
 * @param key - The key to identify the content to retrieve (optional).
 * @param skip - A flag to skip fetching content initially (default: false).
 * @returns An object containing `data`, `loading`, and `refetch` functions.
 *   - `data`: The retrieved and processed content data (initially null).
 *   - `loading`: A flag indicating whether content is being fetched.
 *   - `refetch`: A function to manually trigger content refetching.
 */
const useContentResolver = (key: string | undefined, skip = false) => {
  const isProdValid = useIsProdValid();
  const logger = useLogger('useContentResolver');

  const [data, setData] = useState<EditorValue | null>(null);
  const [loading, setLoading] = useState(false);
  const [shouldRefetch, setShouldRefetch] = useState(false);

  const isSubscribed = useRef<boolean | null>(null);

  const refetch = useCallback(() => setShouldRefetch((prev) => !prev), []);

  useEffect(() => {
    isSubscribed.current = true;

    if (!skip && key && isSubscribed.current)
      getContent(key, isProdValid, logger.log, setData, setLoading).then(
        () => {},
        () => {},
      );

    return () => {
      isSubscribed.current = false;
    };
  }, [key, skip, shouldRefetch]);

  return { data, loading, refetch };
};

export default useContentResolver;
