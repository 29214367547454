import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import Drawer from 'lib/drawer';
import { usePreviewValue, useSetPreview } from 'store/preview';
import { MemberType, MemberTypeEnum } from 'types/graphqlTypes';

import BlockPreview from './components/BlockPreview';
import Instance from './components/Instance';
import OrderPreview from './components/OrderPreview';
import Story from './components/Story';
interface Props {
  member: MemberType | null;
  onClose: () => void;
}

const drawerComponents: { [key in MemberTypeEnum]?: React.FC<Props> } = {
  [MemberTypeEnum.Order]: OrderPreview,
  [MemberTypeEnum.Block]: BlockPreview,
  [MemberTypeEnum.Story]: Story,
  [MemberTypeEnum.ResStory]: Story,
  [MemberTypeEnum.Pitch]: Story,
  [MemberTypeEnum.ResPitch]: Story,
  [MemberTypeEnum.Instance]: Instance,
};

export default function DrawerPreview() {
  const member = usePreviewValue();
  const setPreview = useSetPreview();
  const [, setSearchParams] = useSearchParams();

  const onClose = useCallback(() => {
    setSearchParams((prev) => {
      prev.delete('previewPanes');
      return prev;
    });
    setPreview(null);
  }, [setPreview, setSearchParams]);

  const ComponentToRender = useMemo(
    () =>
      member?.mType && drawerComponents[member?.mType] ? drawerComponents[member?.mType] : null,
    [member?.mType],
  );

  return (
    <Drawer isOpen={Boolean(ComponentToRender)} onClose={onClose}>
      <>{ComponentToRender && <ComponentToRender member={member} onClose={onClose} />}</>
    </Drawer>
  );
}
