import { Transforms, Element, Node } from 'slate';
import insertParagraph from 'components/editor/components/paragraph/utils/insertParagraph';
import { elementTypes } from 'components/editor/constants/types';
import isList from 'components/editor/components/list/utils/isList';
import isQuote from 'components/editor/components/quote/utils/isQuote';
import isSection from '../components/sectionDivider/utils/isSection';
import isHeading from '../components/heading/utils/isHeading';
import unwrapSection from '../components/sectionDivider/utils/unwrapSection';
import { isEmptyParagraph } from '../components/paragraph/utils/isParagraph';

const { LIST_ITEM, UNORDERED_LIST, ORDERED_LIST, SECTION_DIVIDER } = elementTypes;
const { select, setNodes, removeNodes, insertNodes } = Transforms;
const { isElement } = Element;

/**
 * Wraps editor with overridden normalization plugin functionalities
 *
 * @param {Object} editor SlateJS editor instance
 * @returns {Object} SlateJS editor instance
 */
const withNormalization = (editor) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = (entry) => {
    const [node, path] = entry;
    const isRoot = path.length === 0;

    try {
      if (isRoot) {
        const { children } = node;
        const numberOfChildren = children.length;
        const lastChild = children[numberOfChildren - 1];

        if (!lastChild) return insertParagraph(editor, { mode: 'highest', at: [0] });

        if (
          lastChild &&
          (editor.isVoid(lastChild) ||
            isSection(lastChild) ||
            isList(lastChild) ||
            isHeading(lastChild) ||
            isQuote(lastChild) ||
            !isEmptyParagraph(lastChild))
        ) {
          const focus = editor.selection?.focus;

          // eslint-disable-next-line consistent-return
          if (!focus) return;

          insertParagraph(editor, { mode: 'highest', at: [numberOfChildren] });

          return select(editor, focus);
        }
      }
      if (!isElement(node)) return;

      if (editor.isVoid(node) && node.children[0].text !== '') {
        removeNodes(editor, { at: path });
        return insertNodes(editor, { ...node, children: [{ text: '' }] });
      }

      if (isList(node)) {
        for (const [child, childPath] of Node.children(editor, path)) {
          if (
            child.type !== LIST_ITEM ||
            child.type !== UNORDERED_LIST ||
            child.type !== ORDERED_LIST
          )
            return setNodes(editor, { type: LIST_ITEM }, { at: childPath });
        }
      }

      if (isSection(node)) {
        for (const [child, childPath] of Node.children(editor, path)) {
          if (child.type === SECTION_DIVIDER) {
            unwrapSection(editor, { at: childPath });
          }
        }
      }
    } catch (error) {
      // console.log(error);
    }

    return normalizeNode(entry);
  };

  return editor;
};

export default withNormalization;
