import { Theme, ThemeProvider } from '@emotion/react';

import DarkColors from 'theme/colors-dark';

const adjustedTheme = (ancestorTheme: Partial<Theme>) => ({
  ...ancestorTheme,
  palette: { ...ancestorTheme.palette, dina: DarkColors },
});

export interface Props {
  children: React.ReactNode;
}

export default function PersistentTheme({ children }: Readonly<Props>) {
  return <ThemeProvider theme={adjustedTheme as Partial<Theme>}>{children}</ThemeProvider>;
}
