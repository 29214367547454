import { endOfDay, startOfDay } from 'date-fns';

import useCheckUserRight from 'hooks/useCheckUserRight';
import { useSidebarDatePickerAtom } from 'store/sidebar';

import useSubscribeRundownsUpdate from '../hooks/useSubscribeRundownsUpdate';

import RundownList from './list-view';

interface Props {
  setPreviewRundown: (rundownId: string) => void;
  showMasterRundown: boolean;
  setShowMasterRundown: (showMasterRundown: boolean) => void;
}

const sanitizeSelectedDates = (selectedDates: { startDate: Date | null; endDate: Date | null }) => {
  const { startDate, endDate } = selectedDates;
  if (startDate && endDate) return selectedDates;
  return {
    startDate: startOfDay(startDate ?? endDate ?? new Date()),
    endDate: endOfDay(endDate ?? startDate ?? new Date()),
  };
};

const SidebarContainer = ({
  setPreviewRundown,
  showMasterRundown,
  setShowMasterRundown,
}: Props) => {
  const [selectedDates, setSelectedDates] = useSidebarDatePickerAtom();
  const [checkUserRight] = useCheckUserRight();
  const hideMasterRundowns = !checkUserRight('rundown', 'view-master');
  const canCreateNewRundown = checkUserRight('rundown', 'create');

  useSubscribeRundownsUpdate();

  return (
    <RundownList
      onDateChanged={setSelectedDates}
      selectedDates={sanitizeSelectedDates(selectedDates)}
      hideMasterRundowns={hideMasterRundowns}
      canCreateNewRundown={canCreateNewRundown}
      setPreviewRundown={setPreviewRundown}
      showMasterRundown={showMasterRundown}
      setShowMasterRundown={setShowMasterRundown}
    />
  );
};

export default SidebarContainer;
