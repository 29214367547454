import React from 'react';
import PropTypes from 'prop-types';

function ErrorField({ type, style }) {
  return <div style={style}>Could not resolve type: {type}</div>;
}

ErrorField.propTypes = {
  type: PropTypes.string,
};

ErrorField.defaultProps = {
  type: null,
};

export default ErrorField;
