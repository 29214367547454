import React from 'react';
import URLS from 'utils/constants/urls';
import { ReactComponent as DinaIcon } from 'assets/images/DinaIcon.svg';
import { Root, Anchor, Text, IconWrapper } from './footer-styles';

const Footer = (props) => {
  const versionNumber = process.env.REACT_APP_VERSION || 'N.NN.N';
  const versionDateUtc = process.env.REACT_APP_VERSION_DATE || new Date().toString();
  const versionDate = new Date(versionDateUtc).toLocaleDateString();
  const { CHANGELOG_URL } = URLS;

  return (
    <Root>
      <div>
        <Text>Dina by Dina Media Tech AS</Text>
        <Anchor href={CHANGELOG_URL} target='_blank' rel='noopener noreferrer'>
          <Text>
            Version {versionNumber}, {versionDate}
          </Text>
        </Anchor>
        <Text>Copyright © Dina Media Tech AS</Text>
        <Text>All rights reserved</Text>
      </div>
      <IconWrapper>
        <DinaIcon />
      </IconWrapper>
    </Root>
  );
};

export default Footer;
