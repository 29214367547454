import styled from '@emotion/styled';

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  height: 87vh;
  background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel2};
`;

export const Body = styled('div')`
  overflow: auto;
`;
