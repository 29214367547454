import DepartmentFallback from 'assets/images/avatar/department.png';
import TeamFallback from 'assets/images/avatar/team.png';
import UserFallback from 'assets/images/avatar/user.png';
import ImageComponent from 'components/image/Image';
import Text from 'components/text/Text';
import Tooltip from 'components/tooltip/Tooltip';
import getInitials from 'utils/getInitials';

import { DEFAULT_SIZE, Fallback, Image, Root, type RootProps } from './styled';

export type AvatarVariant = 'user' | 'team' | 'department' | 'contact';

interface TooltipWrapperProps {
  tooltipContent?: string;
  children: React.ReactElement;
}

export interface AvatarProps extends RootProps, Pick<TooltipWrapperProps, 'tooltipContent'> {
  variant?: AvatarVariant;
  src?: string;
  title?: string;
  className?: string;
  onClick?: () => void;
}

const getFallbackImg = (variant?: AvatarVariant) => {
  switch (variant) {
    case 'team':
      return TeamFallback;
    case 'department':
      return DepartmentFallback;
    case 'user':
    default:
      return UserFallback;
  }
};

function TooltipWrapper({ children, tooltipContent }: TooltipWrapperProps) {
  return tooltipContent ? <Tooltip title={tooltipContent}>{children}</Tooltip> : children;
}

function Avatar({
  variant,
  className,
  size = DEFAULT_SIZE,
  borderRadius,
  borderWidth,
  borderColor,
  tooltipContent,
  src,
  title,
  onClick,
}: Readonly<AvatarProps>) {
  return (
    <TooltipWrapper tooltipContent={tooltipContent}>
      <Root
        className={className}
        size={size}
        borderRadius={borderRadius}
        borderWidth={borderWidth}
        borderColor={borderColor}
        onClick={onClick}
      >
        <Image src={src} alt={title} />
        <Fallback>
          {title ? (
            <Text variant="button" color="whiteHighEmphasis" style={{ fontSize: size / 2.4 }}>
              {getInitials(title, 2)}
            </Text>
          ) : (
            <ImageComponent src={getFallbackImg(variant)} alt={variant ?? 'fallback'} />
          )}
        </Fallback>
      </Root>
    </TooltipWrapper>
  );
}

export default Avatar;
