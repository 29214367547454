import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ListItem as ListItemComponent } from '@material-ui/core';
import PopperComponent from 'components/shared/popper';
import transientOptions from 'theme/helpers';

export const InstancePreviewWrapper = styled('div')`
  width: 527px;
`;

export const ListItem = styled(ListItemComponent, transientOptions)`
  &.MuiListItem-root {
    ${({ theme }) => css`
      background-color: ${theme.palette.dina.surfaceAppBackgroundNavLevel2};
      border-bottom: 1px solid ${theme.palette.dina.dividerLight};
    `}
    padding: 0 8px 0 0;
    transform: translate3d(0, 0, 0);
    flex-direction: column;

    :last-child {
      border-bottom: none;
    }
    :hover {
      background-color: ${({ theme }) => theme.palette.dina.storyCardHover};
    }
  }
  &.Mui-selected,
  &.Mui-selected:hover,
  &.Mui-selected:focus {
    background-color: ${({ $isInstanceItemVariant, theme }) =>
      theme.palette.dina[$isInstanceItemVariant ? 'onFocusOpacity' : 'onFocus']};
  }
`;

export const Popper = styled(PopperComponent)`
  background-color: transparent;
`;
