import { useCallback, useEffect, useMemo, useState } from 'react';

import { ReactComponent as Close } from 'assets/icons/systemicons/close.svg';
import { IconButton } from 'components/buttons';
import Text from 'components/text/Text';
import Tooltip from 'components/tooltip';
import { useTreeChoiceDialog } from 'components/treeChoiceDialog/TreeChoiceDialog';
import { HStack, VStack } from 'layouts/box/Box';

import { FieldHeader } from '../../styled';
import { FieldProps } from '../fields';
import { TextWrapper } from '../text/styled';
import { isSingleArray } from '../utils';

import TreeChoiceDefault from './TreeChoiceDefault';

export function TreeChoiceField({
  fieldModel,
  value,
  setValue,
  fieldSettings,
  defaultFieldSettings,
  style,
  errorMessage,
  setError,
  ignoreDefaultValue,
  disableEdit,
}: Readonly<FieldProps>) {
  const [, openTreeChoiceDialog] = useTreeChoiceDialog();
  const { fieldId, required } = fieldModel;
  const { label, hint } = fieldSettings ?? defaultFieldSettings;
  const [hasMadeChange, setHasMadeChange] = useState(false);

  const defaultValue = useMemo(() => {
    return Array.isArray(fieldModel.defaultValue.value)
      ? (fieldModel.defaultValue.value as string[])
      : [];
  }, [fieldModel]);

  const selectedValue = useMemo(() => {
    if (isSingleArray(value)) {
      return value;
    }
    if (!ignoreDefaultValue && isSingleArray(defaultValue) && !hasMadeChange) {
      return defaultValue;
    }
    return [];
  }, [value, defaultValue, hasMadeChange, ignoreDefaultValue]);

  useEffect(() => {
    if (required && !selectedValue.length) {
      setError('Required value');
    } else if (required && selectedValue.length && errorMessage) {
      setError(undefined);
    } else if (!required && errorMessage) {
      setError(undefined);
    }
  }, [selectedValue, errorMessage, required]);

  const setSelected = useCallback(
    (val: string[]) => {
      setValue(val);
      setHasMadeChange(true);
    },
    [setValue, setHasMadeChange],
  );

  const openTreeChoice = useCallback(
    () =>
      openTreeChoiceDialog({
        open: true,
        fieldId,
        selected: selectedValue,
        setSelected,
        treeAlternatives: fieldModel.treeAlternatives,
      }),
    [fieldId, selectedValue, setSelected, fieldModel.treeAlternatives],
  );

  return (
    <Tooltip title={hint ?? ''}>
      <TextWrapper key={fieldId} style={style}>
        <FieldHeader>{label}</FieldHeader>
        <VStack gap="2px" alignItems="start">
          <HStack justifyContent="space-between" width="100%">
            <TreeChoiceDefault onClick={openTreeChoice} choice={selectedValue} />
            {!disableEdit && selectedValue?.length > 0 && (
              <IconButton title="Clear value" usage="text" size={24} onClick={() => setValue([])}>
                <Close />
              </IconButton>
            )}
          </HStack>

          {errorMessage && (
            <Text variant="caption" color="statusWarning">
              {errorMessage}
            </Text>
          )}
        </VStack>
      </TextWrapper>
    </Tooltip>
  );
}
