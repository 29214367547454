import conversationTypes from 'utils/constants/conversationTypes';
import allImage from 'assets/icons/systemicons/all.svg';
import notificationImage from 'assets/icons/systemicons/notifications_on.svg';

const getFilteredMAssignedMembers = (mAssignedMembers, userId) => {
  if (!Array.isArray(mAssignedMembers)) return [];
  const filteredMAssignedMembers = mAssignedMembers.filter(({ mId }) => mId !== userId);
  return filteredMAssignedMembers;
};

const getGroupConversationName = (mAssignedMembers, userId, getUser) => {
  const filteredMAssignedMembers = getFilteredMAssignedMembers(mAssignedMembers, userId);
  const memberNames = filteredMAssignedMembers.map(({ mId }) => {
    const { mTitle } = getUser(mId) || {};
    return mTitle;
  });
  return memberNames.join(', ');
};

const getConversationNameAndAvatar = (mAssignedMembers, userId, getUser) => {
  const filteredMAssignedMembers = getFilteredMAssignedMembers(mAssignedMembers, userId);
  if (filteredMAssignedMembers.length !== 1) return {};
  const user = getUser(filteredMAssignedMembers[0].mId);
  const { mTitle, mAvatarUrl } = user || {};
  return { name: mTitle, mAvatarUrl };
};

const getAvatarUrl = (item, members) => {
  const member = members.find((t) => t.mId === item.mId);
  const { mAvatarUrl } = member ?? {};
  return mAvatarUrl;
};

const getConversationInformation = (member, userId, getUser, teams = [], departments = []) => {
  const conversation = { ...member };

  switch (member.convoType) {
    case conversationTypes.GROUP:
      conversation.name = getGroupConversationName(member.mAssignedMembers, userId, getUser);
      break;
    case conversationTypes.DIRECT: {
      const { name, mAvatarUrl } = getConversationNameAndAvatar(
        member.mAssignedMembers,
        userId,
        getUser,
      );
      conversation.name = name;
      conversation.mAvatarUrl = mAvatarUrl;
      break;
    }
    case conversationTypes.ALL: {
      conversation.name = 'Everyone';
      conversation.mAvatarUrl = allImage;
      break;
    }
    case conversationTypes.NOTIFICATION: {
      conversation.name = 'Dina Notifications';
      conversation.mAvatarUrl = notificationImage;
      break;
    }
    case conversationTypes.TEAM: {
      const mAvatarUrl = getAvatarUrl(member, teams);
      conversation.mAvatarUrl = mAvatarUrl;
      break;
    }
    case conversationTypes.DEPARTMENT: {
      const mAvatarUrl = getAvatarUrl(member, departments);
      conversation.mAvatarUrl = mAvatarUrl;
      break;
    }
    default:
      break;
  }

  return conversation;
};

export default getConversationInformation;
