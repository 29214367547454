import { css } from '@emotion/react';
import styled from '@emotion/styled';

const variantOptions = (colors) => ({
  discreet: {
    border: '1px solid transparent',
  },
  outlined: {
    border: `1px solid ${colors.buttonBorderOutlined}`,
  },
  contained: {
    border: `none`,
  },
  warning: {
    border: `0.5px solid ${colors.statusWarning}`,
  },
  dashed: {
    border: `1px dashed ${colors.buttonBorderOutlined}`,
  },
});

const usageOptions = (colors, dark) => ({
  text: {
    color: colors.mediumEmphasis,
    backgroundColor: 'transparent',
    iconColor: dark ? colors.whiteMediumEmphasis : colors.iconInactive,
  },
  outlined: {
    color: colors.mediumEmphasis,
    backgroundColor: `${colors.blackHoverOverlay}`,
    hover: `${colors.outlineButtonOnHover}`,
    iconColor: colors.iconInactive,
  },
  cta: {
    color: colors.whiteHighEmphasis,
    backgroundColor: `${colors.buttonBackgroundHighEmphasis}`,
    hover: `${colors.buttonBackgroundHighEmphasisHover}`,
    iconColor: colors.iconSelectedWhite,
  },
  significant: {
    color: colors.whiteHighEmphasis,
    backgroundColor: `${colors.buttonBackgroundSignificantAction}`,
    hover: `${colors.buttonBackgroundSignificantActionHover}`,
    iconColor: colors.iconSelectedWhite,
  },
  warning: {
    color: colors.highEmphasis,
    backgroundColor: `${colors.warningBackground}`,
    hover: `${colors.hoverOverlay}`,
    iconColor: colors.highEmphasis,
  },
  danger: {
    color: colors.whiteHighEmphasis,
    backgroundColor: `${colors.buttonBackgroundDangerousAction}`,
    hover: `${colors.buttonBackgroundDangerousActionHover}`,
    iconColor: colors.iconSelectedWhite,
  },
  story: {
    color: colors.whiteHighEmphasis,
    backgroundColor: `${colors.statusStory}`,
    hover: `${colors.buttonBackgroundStoryHover}`,
    iconColor: colors.iconSelectedWhite,
  },
  pitch: {
    color: colors.blackHighEmphasis,
    backgroundColor: `${colors.statusPitch}`,
    hover: `${colors.buttonBackgroundPitchHover}`,
    iconColor: colors.blackHighEmphasis,
  },
});

export const StyledIconButton = styled('button')`
  height: 40px;
  width: 40px;
  background-color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-out;
  user-select: none;
  cursor: pointer;
  position: relative;

  :focus-visible {
    outline: 3px solid ${({ theme }) => theme.palette.dina.onFocus};
  }

  svg {
    min-width: ${({ iconWidth }) => iconWidth || '24'}px;
    min-height: ${({ iconHeight }) => iconHeight || '24'}px;
    max-width: ${({ iconWidth }) => iconWidth || '24'}px;
    max-height: ${({ iconHeight }) => iconHeight || '24'}px;
    path {
      ${({ disableEnhancedIconOpacity }) =>
        !disableEnhancedIconOpacity &&
        css`
          fill-opacity: 1;
        `}
    }
  }

  :hover {
    transform: scale(1, 1);

    svg {
      path {
        fill: ${({ dark, theme }) =>
          theme.palette.dina[dark ? 'whiteHighEmphasis' : 'highEmphasis']};
        fill-opacity: 1;
      }
    }
  }

  ${({ variant, theme }) => {
    const option = variantOptions(theme.palette.dina)[variant];
    return (
      option &&
      css`
        border: ${option.border};
      `
    );
  }};

  ${({ usage, dark, theme }) => {
    const option = usageOptions(theme.palette.dina, dark)[usage];
    return (
      option &&
      css`
        background-color: ${option.backgroundColor};
        color: ${option.color};
        svg {
          path {
            fill: ${option.iconColor};
          }
        }
        :hover {
          background-color: ${option.hover};
          svg {
            path {
              fill: ${option.iconColor};
            }
          }
        }
        :active {
          background-color: ${option.backgroundColor};
        }
      `
    );
  }};

  ${({ selected, theme }) =>
    selected &&
    css`
      background-color: ${theme.palette.dina.onSelected};
      color: ${theme.palette.dina.whiteHighEmphasis};
      border: none;
      :hover {
        border: none;
        background-color: ${theme.palette.dina.onSelected};
        filter: brightness(0.92);
        color: ${theme.palette.dina.whiteHighEmphasis};
        svg {
          path {
            fill: ${theme.palette.dina.whiteHighEmphasis};
          }
        }
      }
      svg {
        path {
          fill: ${theme.palette.dina.whiteHighEmphasis};
        }
      }
    `}

  ${({ required, theme }) =>
    required &&
    css`
      border: 1px solid ${theme.palette.dina.statusWarning};
    `}

  ${({ width }) =>
    width &&
    css`
      max-width: ${width}px;
      padding: 0;
    `}

  ${({ height }) =>
    height &&
    css`
      max-height: ${height}px;
      padding: 0;
    `}

  ${({ noBorder }) =>
    noBorder &&
    css`
      border: none;
      :hover {
        border: none;
      }
    `}

  ${({ round, borderRadius }) =>
    css`
      border-radius: ${round ? '20px' : borderRadius};
    `}


  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.54;
      pointer-events: none;
    `}
`;
