import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import { Typography } from '@material-ui/core';

import { ReactComponent as ArrowUpIcon } from 'assets/icons/systemicons/arrows/disclosurearrow_up.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/systemicons/arrows/disclosurearrow_down.svg';
import MenuComponent from '../menu';

const iconStyles = ({ type, theme }) => css`
  width: 24px;
  height: 24px;
  margin: 8px;
  path {
    fill: ${type === 'media' || type === 'error' || type === 'iscoverphoto' || type === 'feed'
      ? theme.palette.dina.blackHighEmphasis
      : theme.palette.dina.whiteHighEmphasis};
  }
`;

export const ArrowUp = styled(ArrowUpIcon)`
  ${({ type, theme }) => iconStyles({ type, theme })}
`;

export const ArrowDown = styled(ArrowDownIcon)`
  ${({ type, theme }) => iconStyles({ type, theme })}
`;

export const BoxWrapper = styled('div')`
  padding: 8px;
  pointer-events: ${(props) => (props.readOnly ? 'none' : 'all')};
`;

export const Menu = styled(MenuComponent)`
  margin-right: -10px;
  opacity: 0;
`;

export const BoxIconWrapper = styled('div')`
  min-width: 40px;
  max-width: 40px;
  background-color: ${({ type, theme }) => {
    if (type === 'media') return theme.palette.dina.timelineVideoClip;
    if (type === 'error') return theme.palette.dina.statusError;
    if (type === 'iscoverphoto') return theme.palette.dina.statusApproved;
    if (type === 'feed') return theme.palette.dina.fabBackground;
    return theme.palette.dina.buttonBackgroundHighEmphasis;
  }};
  border-radius: 4px 0 0 4px;
  margin: 0;
  position: relative;
  path {
    fill: ${({ type, theme }) => {
      if (type === 'media' || type === 'error' || type === 'iscoverphoto' || type === 'feed')
        return theme.palette.dina.blackHighEmphasis;
      return theme.palette.dina.whiteHighEmphasis;
    }};
    fill-opacity: 1;
  }
  :hover {
    ${ArrowUp} {
      path {
        fill: ${({ type, theme }) => {
          if (type === 'media' || type === 'error' || type === 'iscoverphoto' || type === 'feed')
            return theme.palette.dina.blackHighEmphasis;
          return theme.palette.dina.whiteHighEmphasis;
        }};
        fill-opacity: 1;
      }
    }
    ${ArrowDown} {
      path {
        fill: ${({ type, theme }) => {
          if (type === 'media' || type === 'error' || type === 'iscoverphoto' || type === 'feed')
            return theme.palette.dina.blackHighEmphasis;
          return theme.palette.dina.whiteHighEmphasis;
        }};
        fill-opacity: 1;
      }
    }
  }
`;

export const ArrowIconWrapper = styled('div')`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: ${({ type, theme }) => {
    if (type === 'media') return theme.palette.dina.timelineVideoClip;
    if (type === 'error') return theme.palette.dina.statusError;
    if (type === 'iscoverphoto') return theme.palette.dina.statusApproved;
    if (type === 'feed') return theme.palette.dina.fabBackground;
    return theme.palette.dina.buttonBackgroundHighEmphasis;
  }};
`;

export const ContentWrapper = styled('div')`
  display: flex;
  height: ${(props) => props.height || 'auto'};
  border-radius: 4px;
  box-shadow: ${({ showHighlight, theme }) =>
    showHighlight
      ? `0 0 0 2px ${theme.palette.dina.statusOnFocused}`
      : `0 0 0 1px ${theme.palette.dina.borderResting}`};
  background: ${({ showHighlight, theme }) =>
    showHighlight ? theme.palette.dina.backgroundSelected : theme.palette.dina.blackHoverOverlay};

  :hover {
    box-shadow: ${({ showHighlight, theme }) =>
      showHighlight
        ? `0 0 0 2px ${theme.palette.dina.statusOnFocused}`
        : `0 0 0 1px ${theme.palette.dina.blockBorderHover}`};
    background: ${({ theme }) => theme.palette.dina.outlineButtonOnHover};
    ${Menu} {
      opacity: 1;
    }
    ${ArrowIconWrapper} {
      opacity: 1;
    }
  }
  position: relative;
`;

export const MainContentWrapper = styled('div')`
  width: 100%;
  padding: 8px 16px;
  overflow: ${(props) => (props.height ? 'hidden' : 'initial')};
`;

export const EllipsisWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  overflow: ${(props) => (props.collapsed ? 'visible' : 'hidden')};
  align-items: center;
  height: ${({ subtitle, collapsed }) => {
    if (subtitle && !collapsed) return '56px';
    if (collapsed) return '23px';
    return '28px';
  }};
`;

export const TitleWrapper = styled('div')`
  width: calc(100% - 32px);
`;

export const Title = styled('p')`
  ${({ theme }) => theme.typography.dina.overline};
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 1px;
  margin: 0;
  user-select: none;
`;

export const Subtitle = styled('p')`
  ${({ theme }) => theme.typography.dina.listItemLabelMedium};
  letter-spacing: 0.25px;
  margin: 0;
  margin-top: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
`;

export const SubSubTitle = styled('span')`
  ${({ theme }) => theme.typography.dina.captionItalic};
  text-transform: capitalize;
  letter-spacing: 0.25px;
  margin: 0;
  margin-top: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
`;

export const CollapsedContent = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'collapsed',
})`
  ${({ collapsed, theme }) =>
    collapsed ? theme.typography.dina.caption : theme.typography.dina.captionItalic}
  width: calc(100% - 10px);
  margin-bottom: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select: none;
`;

export const TopRightContent = styled(Typography)`
  ${({ theme }) => theme.typography.dina.caption};
`;

export const TopRightContentWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: ${(props) => (props.collapsed ? '18px' : '0px')};
`;
