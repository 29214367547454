import gql from 'graphql-tag';

export default gql`
  query GetUser($input: GetMemberInput) {
    getMember(input: $input) {
      mId
      mRefId
      mTitle
      mType
      mAvatarUrl @client(always: true)
      mBookmarks {
        bookmarkedId
        bookmarkedType
      }
      mAvatarKey
      mDescription
      mProperties {
        ... on ContactType {
          firstName
          surname
          email
          phone
          dateOfBirth
          jobTitle
          readSpeed
        }
      }
      mMetaData {
        key
        value
      }
    }
  }
`;
