import { atom, useAtom } from 'jotai';

import { Alternative, IntegrationType, Mdf, RundownPermissionsType } from 'types/graphqlTypes';
import { PermissionType } from 'types/groupPermissions/permissions';
import { AssignedMember, PanelType } from 'types/members';
import { EditorCommandConfigType } from 'types/memberTypes/editorCommands';

/** Changed user profile settings */
const changedProfileContent = atom<Partial<AssignedMember> | null>(null);
export const useChangedProfileContent = () => useAtom(changedProfileContent);

export interface ChangedProfilePictureProps {
  file: File;
  key: string;
}

const changedProfilePicture = atom<ChangedProfilePictureProps | null>(null);
export const useChangedProfilePicture = () => useAtom(changedProfilePicture);

export interface ChangedTeamsOrDeptsTypes {
  old: AssignedMember[];
  new: AssignedMember[];
}
const changedProfileTeams = atom<ChangedTeamsOrDeptsTypes | null>(null);
export const useChangedProfileTeams = () => useAtom(changedProfileTeams);

const changedProfileDepartments = atom<ChangedTeamsOrDeptsTypes | null>(null);
export const useChangedProfileDepartments = () => useAtom(changedProfileDepartments);

/** Changed rows */
const changedRowsAtom = atom<PermissionType[]>([]);
export const useChangedRows = () => useAtom(changedRowsAtom);

/** Selected item from left menu */
const selectedLeftMenuAtom = atom<string | null>(null);
export const useSelectedLeftMenu = () => useAtom(selectedLeftMenuAtom);

/** Changed mdfs in system settings */
const changedMdfsAtom = atom<Record<string, Mdf>>({});
export const useChangedMdfs = () => useAtom(changedMdfsAtom);

/** Changed actions in system settings */
const changedActionsAtom = atom<Record<string, IntegrationType>>({});
export const useChangedActions = () => useAtom(changedActionsAtom);

/** Changed actions in system settings */
const changedPluginsAtom = atom<Record<string, IntegrationType>>({});
export const useChangedPlugins = () => useAtom(changedPluginsAtom);

/** Changed ai prompt in system settings */
const changedPrompts = atom<Alternative[] | null>(null);
export const useChangedPrompts = () => useAtom(changedPrompts);

/** TODO: To be merged with atoms when converted to typescript */
/** HtmlPanel  */
const panelsAtom = atom<PanelType[]>([]);
export const usePanels = () => useAtom(panelsAtom);

/** Changed panel  */
const changedPanelsAtom = atom<PanelType[]>([]);
export const useChangedPanels = () => useAtom(changedPanelsAtom);

/** New Panel Id  */
const newPanelIdAtom = atom('');
export const useNewPanelId = () => useAtom(newPanelIdAtom);

/** Changed user settings */
const selectedOrganizationAtom = atom<string | null>(null);
export const useSelectedOrganization = () => useAtom(selectedOrganizationAtom);

/* changed webhooks urls in system settings */
export interface ChangedWebhookType {
  key: string;
  value: string;
  originalValue: string;
  severity: string;
}
const changedWebhooksAtom = atom<ChangedWebhookType[]>([]);
export const useChangedWebhooks = () => useAtom(changedWebhooksAtom);

/** Control settings panel open/close */
const settingsOpen = atom(false);
export const useSettingsOpen = () => useAtom(settingsOpen);

const changedRundownPermissions = atom<
  {
    mId: string;
    permissions: RundownPermissionsType;
  }[]
>([]);
export const useChangedRundownPermissions = () => useAtom(changedRundownPermissions);

/* changed editor commands in system settings */

export type ChangedEditorCommandType = Partial<Omit<EditorCommandConfigType, 'mId' | 'mType'>>;

const changedEditorCommands = atom<ChangedEditorCommandType[]>([]);
export const useChangedEditorCommands = () => useAtom(changedEditorCommands);
