import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import Scrollbar from 'components/scrollbar';
import { ReactComponent as PlusIcon } from 'assets/icons/systemicons/add_small.svg';
import List from '../list';
import useStyles from './group-styles';

const Group = ({
  groupTitle,
  showAddButton,
  onAddButtonClicked,
  members,
  showAvatar,
  avatarVariant,
  showGroupMemberCount,
  height,
  toggleOpen,
  menuItems,
  onMenuSelect,
}) => {
  const classes = useStyles({ height });

  const handleAddButtonClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onAddButtonClicked();
  };

  return (
    <div className={classes.root}>
      <div className={classes.groupHeader} role="presentation" onClick={toggleOpen}>
        <div>{groupTitle}</div>
        {showAddButton && (
          <IconButton onClick={handleAddButtonClick} size="small">
            <PlusIcon />
          </IconButton>
        )}
      </div>
      <div className={classes.list}>
        <Scrollbar>
          <div className={classes.container}>
            <List
              {...{ members, showAvatar, avatarVariant, showGroupMemberCount }}
              menuItems={menuItems}
              onMenuSelect={onMenuSelect}
            />
          </div>
        </Scrollbar>
      </div>
    </div>
  );
};

Group.propTypes = {
  /** title of the group */
  groupTitle: PropTypes.string,
  /** boolean to show add button on group */
  showAddButton: PropTypes.bool,
  /** add button click callback */
  onAddButtonClicked: PropTypes.func,
  /** a list of members */
  members: PropTypes.arrayOf(
    PropTypes.shape({
      mId: PropTypes.string,
      mTitle: PropTypes.string,
      mAvatarUrl: PropTypes.string,
      mAssignedMembers: PropTypes.arrayOf(
        PropTypes.shape({
          mId: PropTypes.string,
          mType: PropTypes.string,
        }),
      ),
      notificationCount: PropTypes.number,
    }),
  ),
  /** boolean to show avatar of the member */
  showAvatar: PropTypes.bool,
  /** variant of the member */
  avatarVariant: PropTypes.string,
  /** boolean to show group member count in list item */
  showGroupMemberCount: PropTypes.bool,
  /** height of the conversation list  */
  height: PropTypes.number,
  /** callback to toggle list open */
  toggleOpen: PropTypes.func,
  /** a list of items in the menu */
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      action: PropTypes.string,
      icon: PropTypes.element,
    }),
  ),
  /** callback to executue the actions from menu */
  onMenuSelect: PropTypes.func,
};

Group.defaultProps = {
  groupTitle: '',
  showAddButton: true,
  onAddButtonClicked: () => {},
  members: [],
  showAvatar: true,
  avatarVariant: 'team',
  showGroupMemberCount: false,
  height: 0,
  toggleOpen: () => {},
  menuItems: [],
  onMenuSelect: () => {},
};

export default Group;
