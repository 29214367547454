import { memo, useCallback, useMemo } from 'react';
import { useSlate } from 'slate-react';

import DropZone from 'components/editor/components/dropZone';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import useToast from 'components/toast/useToast';
import { CustomElement, isString } from 'types';
import dndTypes from 'utils/dndTypes';
import { DroppedAsset } from 'utils/normalizeAssetData';
import { allowsVideo, mediaTypes } from 'utils/rundownItemTypes';

import addMedia from './utils/addClip';
import addGraphics from './utils/addGraphics';

const { ASSET } = dndTypes;

const getMediaType = (type: string, payload: DroppedAsset | string) => {
  if (type !== mediaTypes.CLIP) return type;
  if (isString(payload)) return type;

  const { itemType } = payload;
  return itemType === 'image' ? mediaTypes.IMAGE : mediaTypes.CLIP;
};

interface MediaDropZoneProps {
  children: React.ReactNode;
  element: CustomElement;
  canDropVideo: boolean;
  canDropImage: boolean;
  canDropGraphics: boolean;
}

function MediaDropZone({
  children,
  element,
  canDropVideo,
  canDropImage,
  canDropGraphics,
}: Readonly<MediaDropZoneProps>) {
  const { errorToast } = useToast();
  const editor = useSlate();
  const { update } = useEditorContext();

  const onDrop = useCallback(
    async ({ type, payload }: { type: string; payload: DroppedAsset | string }) => {
      const mediaType = getMediaType(type, payload);
      if (isString(payload)) {
        if (mediaType === mediaTypes.GRAPHICS) {
          await addGraphics(editor, element, payload, canDropGraphics, update, errorToast);
        }
        return;
      }

      if (mediaType === mediaTypes.CLIP) {
        if (canDropVideo || canDropImage || allowsVideo(element.type))
          await addMedia(editor, element, payload, update, errorToast);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  /**
   * Returns a list of mediaType allowed to drop on the automation item
   * Note that graphics is always allowed.
   *
   * If dropping graphics on automation items not expecting graphics then
   * the graphics will be inserted as a secondary graphics following the
   * automation item. I.e. like adding a lower-third.
   */
  const mediaDropZoneAcceptedTypes = useMemo(() => {
    const accept = [ASSET, mediaTypes.GRAPHICS];
    if (canDropImage || canDropVideo || allowsVideo(element.type)) {
      accept.push(mediaTypes.CLIP);
    }
    return accept;
  }, [element.type, canDropVideo, canDropImage]);

  return (
    <DropZone accept={mediaDropZoneAcceptedTypes} {...{ onDrop, element }}>
      {children}
    </DropZone>
  );
}

export default memo(MediaDropZone);
