import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'components/avatar/Avatar';
import { ReactComponent as RightPointer } from 'assets/icons/systemicons/speechbubble_right_pointer.svg';
import { ReactComponent as LeftPointer } from 'assets/icons/systemicons/speechbubble_left_pointer.svg';
import Editor from 'components/editor';
import useStyles from './messageRow-styles';

const MessageRow = ({ mContent, mAvatarUrl, mTitle, isSelfMessage, showEditButton }) => {
  const classes = useStyles({ isSelfMessage });
  const MessagePointer = () => {
    if (isSelfMessage) return <RightPointer className={classes.rightPointer} />;
    return <LeftPointer className={classes.leftPointer} />;
  };
  return (
    <div className={classes.messageRow}>
      {!isSelfMessage && (
        <Avatar tooltipContent={mTitle} variant="user" size={28} src={mAvatarUrl} title={mTitle} />
      )}
      <MessagePointer />
      <div className={classes.messageContainer}>
        <Editor
          showHoveringTooltip={false}
          suppressChangeEvent={true}
          isFixedHeightEditor={false}
          background="transparent"
          renderToolbar={() => null}
          height="100%"
          readOnly={showEditButton}
          padding={0}
          value={JSON.parse(mContent)}
          placeholder=""
        />
      </div>
    </div>
  );
};

MessageRow.propTypes = {
  /** content of message */
  mContent: PropTypes.string,
  /** avatar image of sender */
  mAvatarUrl: PropTypes.string,
  /** name of sender */
  mTitle: PropTypes.string,
  /** boolean to indicate self message */
  isSelfMessage: PropTypes.bool,
};

MessageRow.defaultProps = {
  mContent: '',
  mAvatarUrl: '',
  mTitle: '',
  isSelfMessage: false,
};

export default MessageRow;
