/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { Button, IconButton } from 'components/buttons';
import Switch from 'components/switch';
import useDebounce from 'hooks/use-debounce';
import Scrollbar from 'components/scrollbar';

import {
  Body,
  Filters,
  Footer,
  Header,
  HeaderTitle,
  Input,
  Root,
  SwitchControl,
  SwitchLabel,
  Toolbar,
} from './styled';

function Deck({ children, dark = false, stretch = true, minWidth = 'auto', maxWidth = '100%' }) {
  return (
    <Root $dark={dark} $stretch={stretch} $minWidth={minWidth} $maxWidth={maxWidth}>
      {children}
    </Root>
  );
}

Deck.Header = function DeckHeader({ children }) {
  return <Header>{children}</Header>;
};

Deck.HeaderTitle = function DeckHeader({ children }) {
  return <HeaderTitle>{children}</HeaderTitle>;
};

Deck.HeaderInput = function DeckHeaderInput({ value, onChange, autoFocus = false }) {
  const [val, setVal] = useState(value);
  const debouncedInput = useDebounce(val);
  const ref = useRef(null);

  useEffect(() => {
    onChange(debouncedInput);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInput]);

  const handleChange = (e) => {
    setVal(e.target.value);
  };

  const handleFocus = (e) => {
    e.target.select();
  };

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      onChange(val);
      ref.current.blur();
    }
  };

  return (
    <Input
      ref={ref}
      name="header-input"
      value={val}
      autoFocus={autoFocus}
      onChange={handleChange}
      onKeyUp={handleKeyUp}
      onFocus={handleFocus}
    />
  );
};

Deck.Toolbar = function DeckToolbar({ children }) {
  return <Toolbar role="menubar">{children}</Toolbar>;
};

Deck.Button = function DeckButton({
  children,
  onClick,
  label = '',
  selected = false,
  active = false,
  title = '',
  style = {},
}) {
  const variant = active ? 'warning' : 'discreet';
  const usage = active ? 'warning' : 'text';

  return (
    <IconButton
      variant={selected ? 'contained' : variant}
      usage={selected ? 'cta' : usage}
      aria-label={label}
      onClick={onClick}
      height={32}
      width={32}
      title={title}
      style={style}
    >
      {children}
    </IconButton>
  );
};

Deck.ControlSwitch = function DeckControlSwitch({ onChange, selected, children }) {
  return (
    <SwitchControl>
      <Switch aria-label="Show my items only" onChange={onChange} selected={selected} />
      <SwitchLabel>{children}</SwitchLabel>
    </SwitchControl>
  );
};

Deck.DropdownFilters = function DeckDropdownFilter({ children, filtersOpen, setFiltersOpen }) {
  const toggleShowFilters = () => {
    setFiltersOpen((prev) => !prev);
  };

  return (
    <Filters>
      <Scrollbar>{filtersOpen && <>{children}</>}</Scrollbar>
      <Button onClick={toggleShowFilters}>Close</Button>
    </Filters>
  );
};

Deck.Body = function DeckBody({ children, paddingX = 0, paddingY = 0 }) {
  return (
    <Body $paddingX={paddingX} $paddingY={paddingY}>
      {children}
    </Body>
  );
};

Deck.Footer = function DeckFooter({ children }) {
  return <Footer>{children}</Footer>;
};

Deck.propTypes = {
  /** Children */
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
};

Deck.defaultProps = {};

export default Deck;
