const actionTypes = {
  ASSET_INSERT: 'asset-insert',
  ASSET_REMOVE: 'asset-remove',
  ASSET_UPDATE: 'asset-update',
  AUTOMATION_INSERT: 'automation-insert',
  AUTOMATION_REMOVE: 'automation-remove',
  AUTOMATION_UPDATE: 'automation-update',
  CHANGE: 'change',
  CREATE_ASSET: 'create-asset',
  CREATE_PLACEHOLDER: 'create-placeholder',
  REMOVE_PLACEHOLDER: 'remove-placeholder',
  BLOCK_UPDATE: 'block-update',
  COMMIT_UPDATE: 'commit-update',
};

export enum ActionTypesEnum {
  ASSET_INSERT = 'asset-insert',
  ASSET_REMOVE = 'asset-remove',
  ASSET_UPDATE = 'asset-update',
  AUTOMATION_INSERT = 'automation-insert',
  AUTOMATION_REMOVE = 'automation-remove',
  AUTOMATION_UPDATE = 'automation-update',
  CHANGE = 'change',
  CREATE_ASSET = 'create-asset',
  CREATE_PLACEHOLDER = 'create-placeholder',
  REMOVE_PLACEHOLDER = 'remove-placeholder',
  BLOCK_UPDATE = 'block-update',
  COMMIT_UPDATE = 'commit-update',
}

export default actionTypes;
