import React, { useEffect, useState, memo, useCallback } from 'react';
import { Transforms } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';
import PropTypes from 'prop-types';

import useInputEvents from 'hooks/useInputEvents';
import TextAreaComponent from 'components/textArea';

const TextArea = memo(TextAreaComponent);

const FileName = ({ readOnly, fileName: initialFileName, updateFileName, tabIndex }) => {
  const editor = useSlate();
  const [fileName, setFileName] = useState(initialFileName);

  const onUpdateFileName = useCallback(
    (newFileName) => {
      if (newFileName === initialFileName) setFileName(initialFileName);
      else updateFileName(newFileName.trim());
    },
    [initialFileName, updateFileName],
  );

  const [fileNameInputInputRef, handleFileNameKeydown, handleFileNameBlur] = useInputEvents(
    onUpdateFileName,
    fileName,
    initialFileName,
  );

  const handleFileNameChange = useCallback((newName) => {
    setFileName(newName);
  }, []);

  const handleEnterPress = useCallback(
    (event) => {
      if (
        fileNameInputInputRef.current === document.activeElement &&
        event.shiftKey &&
        event.keyCode === 9
      ) {
        Transforms.move(editor, { reverse: true });
        ReactEditor.focus(editor);
      }
    },
    [fileNameInputInputRef, editor],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleEnterPress, true);

    return () => window.removeEventListener('keydown', handleEnterPress, true);
  }, [handleEnterPress]);

  return (
    <TextArea
      severity="regular"
      type="SoMe"
      disableLabel
      rows={1}
      value={fileName}
      onChange={handleFileNameChange}
      placeholder="Add File Name"
      inputRef={fileNameInputInputRef}
      onKeyDown={handleFileNameKeydown}
      onBlur={handleFileNameBlur}
      disabled={readOnly}
      multiline={false}
      inputProps={{ tabIndex }}
    />
  );
};

FileName.propTypes = {
  readOnly: PropTypes.bool,
  fileName: PropTypes.string,
  updateFileName: PropTypes.func,
  tabIndex: PropTypes.oneOf([0, -1]),
};

FileName.defaultProps = {
  readOnly: false,
  fileName: '',
  updateFileName: () => {},
  tabIndex: 0,
};

export default memo(FileName);
