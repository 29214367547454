import { useEffect } from 'react';

import DebouncedLoadingIndicator from 'components/debouncedLoadingIndicator/DebouncedLoadingIndicator';
import Editor from 'components/editor';
import Scrollbar from 'components/scrollbar/scrollbar';
import useContentResolver from 'hooks/useContentResolver';

import { useVersionHistoryMolecule } from '../../../atoms';

import {
  ContentWrapper,
  DateLabel,
  Divider,
  EditorContent,
  Fallback,
  Header,
  Wrapper,
} from './styled';

function Content() {
  const { useSelectedContent, useCurrentAudit } = useVersionHistoryMolecule();
  const [, setSelectedContent] = useSelectedContent();
  const [currentAudit] = useCurrentAudit();
  const { contentKey, timestamp } = currentAudit || {};
  const { data, loading } = useContentResolver(contentKey);

  useEffect(() => {
    if (data) setSelectedContent(data);
  }, [data]);

  return (
    <Wrapper>
      <Header>
        <DateLabel>{timestamp}</DateLabel>
      </Header>
      <Divider />
      <ContentWrapper>
        <EditorContent>
          {loading && <DebouncedLoadingIndicator />}
          {contentKey && data ? (
            <Scrollbar top={5} bottom={5}>
              <Editor
                value={data}
                readOnly
                renderToolbar={() => {}}
                height="100%"
                direction={undefined}
                hostReadSpeed={undefined}
                thumbnail={undefined}
                isAllowed={undefined}
                setEditor={undefined}
                platformStructure={undefined}
                platformId={undefined}
                isCmsBlock={undefined}
                editorFontSize={undefined}
                getPlaceholderConfigs={undefined}
                withSignedUrl={undefined}
                platformKind={undefined}
              />
            </Scrollbar>
          ) : (
            <Fallback>No version to show</Fallback>
          )}
        </EditorContent>
      </ContentWrapper>
    </Wrapper>
  );
}

export default Content;
