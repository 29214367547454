/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useReducer, useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import Header from 'components/shared/header';
import Footer from 'components/shared/footer';
import Select from 'components/select';
import Divider from 'components/divider';
import TransitionSelect from '../transitionSelect';
import List from './list';
import Table from './table';
import reducer, { actionTypes } from './utils/reducer';
import {
  RootWrapper,
  LeftWrapper,
  ContentWrapper,
  RightWrapper,
  CenterContent,
  FieldLabel,
  TransitionSelectWrapper,
} from './details-view-styled';

const FilterBySpec = (specArray, metadataArray) =>
  specArray.map((field) => {
    const target = metadataArray.find((tl) => tl.name === field.name);
    return target ? { ...field, ...target } : field;
  });

const DetailsView = ({
  initialVariant,
  initialTransition,
  metaData,
  transitionData,
  onOk,
  onClose,
  items,
  spec,
  resources,
  selectTemplateSet,
  templateSetIndex,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    transitionData,
    metaData,
  });

  const initialIndex = useMemo(
    () => spec.findIndex((template) => template.variant === initialVariant),
    [initialVariant, spec],
  );

  const [selectedListIndex, setSelectedListIndex] = useState(initialIndex >= 0 ? initialIndex : 0);
  const [selectedOption, setSelectedOption] = useState(initialTransition || transitionData[0]);

  const onTransitionUpdate = useCallback((transitionObj) => {
    dispatch({
      payload: transitionObj,
      type: actionTypes.UPDATE_TRANSITION,
    });
    setSelectedOption(transitionObj);
  }, []);

  const onFieldUpdate = useCallback((payload) => {
    dispatch({
      payload,
      type: actionTypes.UPDATE_META,
    });
  }, []);

  const handleSelectionChange = useCallback((val) => setSelectedListIndex(val), []);

  const handleChange = useCallback((val) => {
    selectTemplateSet(val);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedList = spec[selectedListIndex] || {};
  const { variant, fields = [], name, description } = selectedList;

  const fieldsBySpec = useMemo(
    () => FilterBySpec(fields, state.metaData),
    [fields, state.metaData],
  );

  return (
    <div>
      <RootWrapper>
        <LeftWrapper>
          <Header label="Variants" />
          <ContentWrapper>
            <Select
              hideLabel
              items={items}
              onChange={handleChange}
              usage="details"
              selectedValue={templateSetIndex}
            />
            <List
              data={spec}
              onSelectionChange={handleSelectionChange}
              selectedIndex={selectedListIndex}
            />
          </ContentWrapper>
        </LeftWrapper>
        <Divider orientation="vertical" />
        <RightWrapper>
          <Header label="Details" onClose={onClose} />
          <ContentWrapper>
            <CenterContent>
              {transitionData.length !== 0 && (
                <>
                  <FieldLabel>In Transition</FieldLabel>
                  <TransitionSelectWrapper>
                    <TransitionSelect
                      update={onTransitionUpdate}
                      value={selectedOption}
                      arrowPosition="end"
                      anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                      }}
                    />
                  </TransitionSelectWrapper>
                </>
              )}
              {fieldsBySpec.length !== 0 && (
                <>
                  <FieldLabel>Content</FieldLabel>
                  <Table
                    fields={fieldsBySpec}
                    onFieldUpdate={onFieldUpdate}
                    resources={resources}
                  />
                </>
              )}
            </CenterContent>
          </ContentWrapper>
        </RightWrapper>
      </RootWrapper>
      <Footer
        onOk={() => {
          onOk(variant, fieldsBySpec, selectedOption, name, description);
        }}
        onCancel={onClose}
      />
    </div>
  );
};

DetailsView.propTypes = {
  /** Initial Variant to be selected on the list */
  initialVariant: PropTypes.string,
  /** Initial transition value to show on the In transition */
  initialTransition: PropTypes.shape({
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  /** metaData that comes from the primary items */
  metaData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  /** transition data to show on the options of transition selection  */
  transitionData: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  /** onOk Callback function */
  onOk: PropTypes.func,
  /** on Close Callback Function */
  onClose: PropTypes.func,
  spec: PropTypes.arrayOf(PropTypes.shape({})),
};

DetailsView.defaultProps = {
  initialVariant: 'VARIANT',
  initialTransition: null,
  metaData: [],
  transitionData: [],
  onOk: () => {},
  onClose: () => {},
  spec: [],
};

export default memo(DetailsView);
