import { useQuery } from '@apollo/client';

import GET_RUNDOWN from 'graphql/queries/getRundown';
import { QueryGetRundownArgs, Rundown } from 'types/graphqlTypes';

type GetRundownReturnType = {
  getRundown: Rundown;
};

type GetRundownInput = {
  mId: string;
};

const useGetRundown = ({ mId }: GetRundownInput) => {
  const { data, error, loading, refetch } = useQuery<GetRundownReturnType, QueryGetRundownArgs>(
    GET_RUNDOWN,
    {
      variables: {
        input: {
          mId: mId,
          mRefId: mId,
        },
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-and-network',
    },
  );

  return { data, error, loading, refetch };
};

export default useGetRundown;
