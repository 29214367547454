import gql from 'graphql-tag';

export default gql`
  query GetTeamMembers($input: GetMemberInput) {
    getMembers(input: $input) {
      mId
      mRefId
      mTitle
      mAvatarUrl
      mType
      mDescription
    }
  }
`;
