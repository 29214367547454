import React, { memo } from 'react';
import MuiDrawer from '@material-ui/core/Drawer';

interface DrawerProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

function Drawer({ children, isOpen, onClose }: DrawerProps) {
  return (
    <MuiDrawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      disableEnforceFocus
      PaperProps={{ square: false, style: { borderRadius: '8px' } }}
    >
      {children}
    </MuiDrawer>
  );
}
export default memo(Drawer);
