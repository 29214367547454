import { Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import insertSecondaryAutomationElement from 'components/editor/components/secondaryAutomation/utils/insertSecondaryAutomationElement';
import { actionTypes } from 'components/editor/constants/types';
import { Update } from 'components/editor/EditorContext';
import getMosAsset from 'components/editor/utils/getMosAsset';
import notifyChange from 'components/editor/utils/notifyChange';
import { CustomEditor, CustomElement } from 'types';
import findAndReplace from 'utils/findAndReplace';
import { allowsGraphics, mediaTypes, RundownItemTypes } from 'utils/rundownItemTypes';

import { CreateAssetType } from './addClip';

/**
 * Adds graphics media to given element
 *
 * @param editor SlateJS editor instance
 * @param element SlateJS element to be updated
 * @param payload Data payload of dropped item
 * @param canDropGraphics True if the automation item allows graphics to be added
 * @param update Callback to be invoked on update
 * @returns SlateJS editor instance
 */

const addGraphics = async (
  editor: CustomEditor,
  element: CustomElement,
  payload: string,
  canDropGraphics: boolean,
  update: Update,
  errorToast: (err: unknown) => void = () => {},
) => {
  const mosAsset = getMosAsset(payload, mediaTypes.GRAPHICS);
  if (!mosAsset) return;

  const { type } = element;
  const isPrimary = canDropGraphics || allowsGraphics(type);

  mosAsset.mediaType = isPrimary ? mediaTypes.GRAPHICS : mediaTypes.SECONDARY_GRAPHICS;

  try {
    const result = (await update({
      type: actionTypes.CREATE_ASSET,
      payload: { document: editor.children, asset: mosAsset },
    })) as unknown as CreateAssetType;

    const asset = result?.data?.createAssets?.[0];
    if (!asset) return;

    const path = ReactEditor.findPath(editor, element);

    if (isPrimary) {
      const { data } = element;
      const { assets: previousAssets = [], ...rest } = data ?? {};

      const newAsset = {
        ...mosAsset,
        mId: asset.mId,
        mRefId: asset.mRefId,
        mAssetId: asset.mAssetId,
        mosobj: payload,
      };

      const updatedData = {
        ...rest,
        assets: findAndReplace(previousAssets, newAsset, 'assetType'),
      };

      Transforms.setNodes(editor, { data: updatedData }, { at: path });

      await update({
        type: actionTypes.AUTOMATION_UPDATE,
        payload: { document: editor.children, updatedData },
      });
    } else {
      const [index] = path;

      insertSecondaryAutomationElement(
        editor,
        RundownItemTypes.OVERLAY_GRAPHICS,
        {
          templateVariant: asset.mTitle,
          mId: asset.mId,
          mRefId: asset.mRefId,
          mAssetId: asset.mAssetId,
          provider: mosAsset.provider,
          mosobj: payload,
        },
        true,
        index,
      );
    }

    notifyChange(editor, update);
    ReactEditor.focus(editor);
  } catch (e) {
    errorToast(e);
  }
};

export default addGraphics;
