import { useCallback } from 'react';
import { isObject } from 'lodash';

import useToast from 'components/toast/useToast';
import useGoToOrderResource, {
  isNavigatable,
} from 'features/orderForm/components/useGoToOrderResource';
import useCreateConvo from 'hooks/useCreateConvo';
import useDinaNavigate from 'hooks/useDinaNavigate';
import useOpenMimirItem from 'hooks/useOpenMimirItem';
import { isContentType, useIsMessageHubOpen, useSelectedConversationId } from 'store';
import { AssignedMember } from 'types';
import { AccountType, MemberType, MemberTypeEnum } from 'types/graphqlTypes';

const canChatWith = [MemberTypeEnum.Team, MemberTypeEnum.Department, MemberTypeEnum.User];

interface AccountId {
  account: AccountType;
}

const isAccountType = (obj: unknown): obj is AccountId => {
  if (!isObject(obj)) return false;
  const maybeAccountId = obj as Partial<AccountId>;
  return maybeAccountId.account !== undefined;
};

const getAccountId = (obj: unknown): string | undefined => {
  if (isAccountType(obj)) {
    return obj.account.accountId ?? undefined;
  }
};

const useOpenMember = () => {
  const { navigateTo } = useDinaNavigate();
  const { errorToast } = useToast();
  const { goToResource } = useGoToOrderResource();
  const { createConvo } = useCreateConvo();
  const [, setIsMessageHubOpen] = useIsMessageHubOpen();
  const [, setSelectedConvoId] = useSelectedConversationId();
  const openAssetInMimir = useOpenMimirItem();

  const openItem = useCallback(
    (val: MemberType) => {
      if (!val.mId || !val.mType) return;
      const { mId, mType, mSecId, mRefId, mProperties } = val;
      const accountId = getAccountId(mProperties);

      if (isContentType(mType)) {
        navigateTo(mType, mId);
      } else if (mProperties?.platform === 'linear' && accountId) {
        if (accountId) navigateTo('rundown', accountId);
      } else if (mType === MemberTypeEnum.Instance && mSecId) {
        navigateTo('story', mSecId, {
          tab: 'instances',
          entityId: mId,
        });
      } else if (mType === MemberTypeEnum.Note) {
        navigateTo('story', mId, {
          tab: 'notes',
          entityId: mRefId,
        });
      } else if (mType === MemberTypeEnum.Order) {
        if (isNavigatable(val.mResourceType))
          goToResource(val.mId, val.mResourceType).catch(errorToast);
      } else if (mType === MemberTypeEnum.Block) {
        navigateTo('story', mId, {
          tab: 'blocks',
        });
      } else if (mType === MemberTypeEnum.Asset && val.mRefId) {
        openAssetInMimir(val.mRefId);
      } else if (canChatWith.includes(val.mType)) {
        createConvo(val as unknown as AssignedMember, (convo) => {
          setSelectedConvoId(convo?.mId);
          setIsMessageHubOpen(true);
        }).catch(errorToast);
      }
    },
    [navigateTo, createConvo, errorToast, setSelectedConvoId, setIsMessageHubOpen],
  );

  return { openItem };
};

export default useOpenMember;
