import React from 'react';
import Scrollbar from 'components/scrollbar/scrollbar';
import ListItem from 'components/ellipsisDropdown/listItem-view';

import { useSelectedUserSettingAtom } from '../../../../atoms';
import USER_SETTING_TYPES from '../../../../utils/userSettingTypes';

import { RootWrapper } from './menu-styled';

const Menu = () => {
  const [selectedUserSetting, setSelectedUserSetting] = useSelectedUserSettingAtom();

  const toggleLeftMenu = (leftMenuItem) => {
    setSelectedUserSetting(leftMenuItem);
  };
  const toggleLeftMenuProfile = () => {
    toggleLeftMenu(USER_SETTING_TYPES.PROFILE);
  };

  const toggleLeftMenuUserTour = () => {
    toggleLeftMenu(USER_SETTING_TYPES.USER_TOUR);
  };

  const toggleLeftMenuUserTheme = () => {
    toggleLeftMenu(USER_SETTING_TYPES.THEME);
  };

  const toggleLeftMenuNotification = () => {
    toggleLeftMenu(USER_SETTING_TYPES.NOTIFICATION);
  };

  return (
    <RootWrapper>
      <Scrollbar>
        <ListItem
          key={USER_SETTING_TYPES.PROFILE}
          text="Profile"
          selected={selectedUserSetting === USER_SETTING_TYPES.PROFILE}
          onClick={toggleLeftMenuProfile}
          excludeDivider
        />
        <ListItem
          key={USER_SETTING_TYPES.USER_TOUR}
          text="User Interface Tours"
          selected={selectedUserSetting === USER_SETTING_TYPES.USER_TOUR}
          onClick={toggleLeftMenuUserTour}
          excludeDivider
        />
        <ListItem
          key={USER_SETTING_TYPES.THEME}
          text="User Interface Theme"
          selected={selectedUserSetting === USER_SETTING_TYPES.THEME}
          onClick={toggleLeftMenuUserTheme}
          excludeDivider
        />
        <ListItem
          key={USER_SETTING_TYPES.NOTIFICATION}
          text="Notifications"
          selected={selectedUserSetting === USER_SETTING_TYPES.NOTIFICATION}
          onClick={toggleLeftMenuNotification}
          excludeDivider
        />
      </Scrollbar>
    </RootWrapper>
  );
};

export default Menu;
