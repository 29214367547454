import { atom, useAtom } from 'jotai';
import { createScope, molecule, useMolecule } from 'jotai-molecules';

export const AssetsScope = createScope(undefined);

const assetsMolecule = molecule((_, getScope) => {
  getScope(AssetsScope);

  const selectedGraphicsAssetAtom = atom({});
  const useSelectedGraphicsAssetAtom = () => useAtom(selectedGraphicsAssetAtom);

  const selectedVideoAssetAtom = atom({});
  const useSelectedVideoAssetAtom = () => useAtom(selectedVideoAssetAtom);

  const assetsTabIndexAtom = atom(0);
  const useAssetsTabIndex = () => useAtom(assetsTabIndexAtom);

  const showGraphicsEditor = atom(false);
  const useShowGraphicsEditor = () => useAtom(showGraphicsEditor);

  return {
    useSelectedGraphicsAssetAtom,
    useSelectedVideoAssetAtom,
    useAssetsTabIndex,
    useShowGraphicsEditor,
  };
});
export const useAssetsMolecule = () => useMolecule(assetsMolecule);
