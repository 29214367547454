import { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

const MIMIR_SEARCH_REQUEST = gql`
  mutation MimirSearchRequest($input: ServiceRequestInput) {
    serviceRequest(input: $input) {
      body
      ok
      provider
      status
      statusText
    }
  }
`;

const itemsPerPage = 25;

export interface MimirItem {
  id: string;
}

interface ServiceRequest {
  serviceRequest: {
    body: string;
  };
}

interface MimirSearchResult {
  count: number;
  from: number;
  total: number;
  totalAcrossPages: number;
  _embedded: {
    collection: MimirItem[];
  };
}

const useMimirSearch = () => {
  const [doSearch] = useMutation<ServiceRequest>(MIMIR_SEARCH_REQUEST);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [noMoreResults, setNoMoreResults] = useState(false);

  let from = 0;
  let searchString = ''; // used for remembering the search string when paging
  let totalAcrossPages: number | undefined; // used for detecting whether we can fetch more

  const search = useCallback(
    async (newSearchString: string, reset = true, useSemanticSearch = false) => {
      setLoading(true);
      if (reset) {
        from = 0;
        totalAcrossPages = undefined;
        setTotal(0);
        setNoMoreResults(false);
      }
      searchString = newSearchString;
      const params = {
        from,
        searchString,
        itemsPerPage,
        isFuzzy: false,
        includeTypeCounts: false,
        includeDateCounts: false,
        includeFolders: false,
        ...(useSemanticSearch && { useCustomSearchHook: true }),
      };
      let mimirItems: MimirItem[] = [];
      const variables = {
        input: {
          provider: 'Mimir',
          service: 'search',
          request: {
            body: '{}',
            headers: '{}',
            method: 'GET',
            params: JSON.stringify(params),
            url: '',
          },
        },
      };
      try {
        await doSearch({
          variables,
          update: (_, mutationResult) => {
            const result: MimirSearchResult = JSON.parse(
              mutationResult?.data?.serviceRequest?.body ?? '{}',
            ) as MimirSearchResult;
            totalAcrossPages = result.totalAcrossPages;
            setTotal(totalAcrossPages);
            mimirItems = [...(result?._embedded?.collection ?? [])];
          },
        });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Could not search in Mimir', err);
      }
      from += itemsPerPage + 1;
      setLoading(false);
      return mimirItems;
    },
    [doSearch, setLoading],
  );

  const fetchMore = useCallback(async () => {
    if (from <= Number(totalAcrossPages)) {
      const nextBatch = await search(searchString, false);
      return nextBatch;
    }
    setNoMoreResults(true);
    return null;
  }, [setLoading]);

  return { search, loading, total, noMoreResults, fetchMore };
};

export default useMimirSearch;
