import { memo, useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { format } from 'date-fns';

import { ReactComponent as AddIcon } from 'assets/icons/systemicons/add_small.svg';
import { AvatarVariant } from 'components/avatar/Avatar';
import { Button } from 'components/buttons';
import Chip from 'components/chip';
import InputField from 'components/input/Input';
import { DateRange, useDatePicker } from 'components/mdfEditor/fields/date/DatePicker';
import Text from 'components/text/Text';
import transientOptions from 'theme/helpers';
import { AssignedMember } from 'types';
import { MemberType } from 'types/graphqlTypes';

interface ContentWrapperProps {
  $showTopBorder?: boolean;
}
export const ContentWrapper = styled('div', transientOptions)<ContentWrapperProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px 12px 12px;
  border-top: 1px solid
    ${({ $showTopBorder = true, theme }) =>
      !$showTopBorder ? theme.palette.dina.dividerLight : 'transparent'};
`;

export const ChipContainer = styled('div')`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
`;

interface TextProps {
  showTopBorder?: boolean;
  title: string;
  variant: 'text' | 'number';
  textValue: string | number | undefined;
  fieldName: string;
  onUpdate: (fieldName: string, newValue: string) => void;
}

function TextComponent({
  textValue,
  fieldName,
  onUpdate,
  variant,
}: Readonly<Omit<TextProps, 'title'>>) {
  const [isEditing, setIsEditing] = useState(false);

  const isNotEmpty = textValue && textValue !== '';

  const handleUpdate = useCallback(
    (newValue: string) => {
      setIsEditing(false);
      onUpdate(fieldName, newValue);
    },
    [fieldName, onUpdate],
  );

  return isEditing ? (
    <InputField
      type={variant}
      autoFocus={true}
      placeholder="Enter your value..."
      initialValue={textValue as string}
      updateValue={handleUpdate}
      onBlur={() => {
        setIsEditing(false);
      }}
    />
  ) : (
    <Text
      variant={isNotEmpty ? 'body2' : 'body2Italic'}
      color={isNotEmpty ? 'highEmphasis' : 'mediumEmphasis'}
      onClick={() => setIsEditing(true)}
      style={{ cursor: 'pointer' }}
    >
      {isNotEmpty ? textValue : 'Not set'}
    </Text>
  );
}

interface DateProps extends Omit<TextProps, 'variant' | 'textValue'> {
  variant: 'date';
  dateValue: string;
}

function DateComponent({
  dateValue,
  fieldName,
  onUpdate,
}: Readonly<Omit<DateProps, 'title' | 'variant'>>) {
  const [, openPicker] = useDatePicker();
  const isNotEmpty = dateValue && dateValue !== '';
  const showDatePicker = useCallback(
    (ev: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      openPicker({
        anchorEl: ev.currentTarget,
        startValue: dateValue || undefined,
        hideUnscheduleButton: true,
        selectRange: false,
        showTimePicker: false,
        selectDate: (newValue: DateRange) => {
          onUpdate(fieldName, newValue.startDate);
        },
      });
    },
    [dateValue, fieldName, onUpdate, openPicker],
  );
  return (
    <Text
      variant={isNotEmpty ? 'body2' : 'body2Italic'}
      color={isNotEmpty ? 'highEmphasis' : 'mediumEmphasis'}
      onClick={showDatePicker}
      style={{ cursor: 'pointer' }}
    >
      {isNotEmpty ? format(dateValue, 'ddd, Do MMM YYYY') : 'Not set'}
    </Text>
  );
}

interface ChipProps {
  showTopBorder?: boolean;
  title: string;
  variant: 'chip';
  members: MemberType[] | AssignedMember[];
  avatarVariant?: AvatarVariant;
  onDelete?: (members: AssignedMember[]) => void;
  onAdd?: () => void;
}

function ProfileDetails(props: TextProps | DateProps | ChipProps) {
  return (
    <ContentWrapper $showTopBorder={props?.showTopBorder}>
      <Text variant="overline" color="mediumEmphasis">
        {props.title}
      </Text>
      {(props.variant === 'text' || props.variant === 'number') && (
        <TextComponent
          variant={props.variant}
          textValue={props.textValue}
          fieldName={props.fieldName}
          onUpdate={props.onUpdate}
        />
      )}
      {props.variant === 'date' && (
        <DateComponent
          dateValue={props.dateValue}
          fieldName={props.fieldName}
          onUpdate={props.onUpdate}
        />
      )}
      {props.variant === 'chip' && (
        <ChipContainer>
          {props.members?.map((member) => (
            <Chip
              key={`${member?.mId as string}`}
              label={member?.mTitle as string}
              avatarUrl={member?.mAvatarUrl as string}
              showAvatar={!!props.avatarVariant}
              width="auto"
              height={32}
              labelType="listItemLabel"
              disabled={!props?.onDelete}
              onDelete={
                props?.onDelete
                  ? () => {
                      props.onDelete?.(
                        (props.members as AssignedMember[]).filter((m) => m.mId !== member.mId),
                      );
                    }
                  : undefined
              }
            />
          ))}
          {props.onAdd && (
            <Button
              usage="outlined"
              variant="outlined"
              height={32}
              width={64}
              onClick={props.onAdd}
            >
              <AddIcon />
              Add
            </Button>
          )}
        </ChipContainer>
      )}
    </ContentWrapper>
  );
}

export default memo(ProfileDetails);
