import { useEffect } from 'react';
import { ApolloClient } from '@apollo/client';
import startOfDay from 'date-fns/start_of_day';

import GET_MEMBER from 'graphql/queries/getMember';
import NOTIFY_MEMBER_UPDATE_SUBSCRIPTION from 'graphql/subscriptions/notifyMemberUpdate';
import useApolloSubscription from 'hooks/useApolloSubscription';
import { DailyNote } from 'types';

import updateDailyNoteCache from '../api/updateDailyNoteCache';
import { useHasUpdatedSubscription, useSelectedDailyNoteDate } from '../store';
import getUTCDateString from '../utils/getUTCDateString';

const useDailyNoteSubscription = () => {
  const [selectedDate] = useSelectedDailyNoteDate();
  const [, setHasUpdatedSubscription] = useHasUpdatedSubscription();

  const selectedMrefId = getUTCDateString(startOfDay(new Date(selectedDate)));

  const [subscribe, unSubscribe] = useApolloSubscription(NOTIFY_MEMBER_UPDATE_SUBSCRIPTION, {
    variables: {
      mIdSubscribed: 'dailyNote',
    },
    onSubscriptionData: ({
      client,
      subscriptionData,
    }: {
      client: ApolloClient<object>;
      subscriptionData: { data: { notifyMemberUpdateSubscription: DailyNote } };
    }) => {
      const updatedData = subscriptionData.data.notifyMemberUpdateSubscription;

      const cachedData = client.readQuery({
        query: GET_MEMBER,
        variables: {
          input: {
            mId: updatedData.mId,
            mRefId: updatedData.mRefId,
          },
        },
      }) as { getMember: DailyNote | null };

      updateDailyNoteCache(client, updatedData);

      // don't show badge when locked
      if (!cachedData?.getMember?.locked && updatedData.locked) return;

      if (updatedData.mRefId === selectedMrefId) {
        setHasUpdatedSubscription(true);
      }
    },
    source: 'dailyNote',
  });

  useEffect(() => {
    subscribe();
    return () => {
      unSubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useDailyNoteSubscription;
