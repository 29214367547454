import React, { memo, useState, useCallback, useMemo } from 'react';
import { useSlate } from 'slate-react';
import PropTypes from 'prop-types';
import { elementTypes } from 'components/editor/constants/types';
import { ReactComponent as OverlineIconComponent } from 'assets/icons/systemicons/editor/trumpet.svg';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import useInputEvents from 'hooks/useInputEvents';
import updateBlock from 'components/editor/utils/updateBlock';
import useChangeCollapse from 'components/editor/hooks/useChangeCollapse';
import styled from '@emotion/styled';
import InputBlock from '../inputBlock';
import DragAndDrop from '../dragAndDrop';

const OverlineIcon = styled(OverlineIconComponent)`
  width: 24px;
  height: 24px;
  margin: 6px;
`;

const Overline = ({ attributes, children, element, direction }) => {
  const { data } = element;
  const { collapsed } = data;
  const editor = useSlate();
  const { update } = useEditorContext();
  const initialValue = data.content || '';
  const [value, setValue] = useState(initialValue);

  const [onChangeCollapse] = useChangeCollapse(element);

  const updateTitle = useCallback(
    (newTitle) => {
      const updatedData = {
        ...data,
        content: newTitle,
      };
      updateBlock(editor, element, updatedData, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  const onUpdate = useCallback(
    (newValue) => {
      if (newValue === initialValue) setValue(initialValue);
      else updateTitle(newValue);
    },
    [initialValue, updateTitle],
  );

  const [inputRef, handleKeydown, handleBlur] = useInputEvents(onUpdate, value, initialValue);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = useCallback(setValue, []);

  const renderContent = useMemo(
    () => (
      <InputBlock
        label="Overline"
        icon={<OverlineIcon className="skipOverride" />}
        value={value}
        onChange={handleChange}
        inputRef={inputRef}
        onKeyDown={handleKeydown}
        onBlur={handleBlur}
        collapsed={collapsed}
        collapsedContent={value}
        updateCollapsed={onChangeCollapse}
        direction={direction}
        hideEllipsisButton
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [collapsed, handleBlur, handleChange, handleKeydown, inputRef, onChangeCollapse, value],
  );

  return (
    <div {...attributes}>
      <DragAndDrop element={element}>
        {children}
        {renderContent}
      </DragAndDrop>
    </div>
  );
};

Overline.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
  /** whether title block is readonly on not */
  readOnly: PropTypes.bool,
};

Overline.defaultProps = {
  attributes: {},
  children: null,
  element: {
    children: [],
    data: {},
    type: elementTypes.OVERLINE,
  },
  readOnly: false,
};

export default memo(Overline);
