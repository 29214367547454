import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import GET_TEAM_MEMBERS from 'graphql/queries/getTeamMembers';
import GET_MEMBERS_OF from 'graphql/queries/getMembersOf';
import memberTypes from 'graphql/memberTypes';
import { getMembersQuery, getMembersOfQuery } from 'graphql/queryVariables';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import SummaryView from '../summary-view';

const TeamContainer = (props) => {
  const { item, ...rest } = props;
  const { id, description } = item;
  const [listDetails, setListDetails] = useState([]);

  const {
    data: peopleData,
    loading: peopleLoading,
    error: peopleError,
  } = useQuery(GET_TEAM_MEMBERS, {
    variables: getMembersQuery(id, memberTypes.TEAM_USER),
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: departmentData,
    loading: departmentLoading,
    error: departmentError,
  } = useQuery(GET_MEMBERS_OF, {
    variables: getMembersOfQuery(id, memberTypes.DEPARTMENT_TEAM),
    fetchPolicy: 'cache-and-network',
  });

  if (departmentLoading || peopleLoading) return <LoadingIndicator />;
  if (departmentError) return <div> No department Data. </div>;
  if (peopleError) return <div> No people data </div>;

  const { getMembersOf: getPeople } = peopleData;
  const { getMembersOf: getDepartments } = departmentData;

  if (listDetails.length < 1 && getPeople && getDepartments) {
    setListDetails([
      {
        listTitle: 'People',
        avatarVariant: 'user',
        members: getPeople,
      },
      {
        listTitle: 'Departments',
        avatarVariant: 'department',
        members: getDepartments,
      },
    ]);
  }

  return (
    <SummaryView
      teamId={item.id}
      title={item.name}
      description={description}
      avatarUrl={item.image}
      listDetails={listDetails}
      {...rest}
    />
  );
};

export default TeamContainer;
