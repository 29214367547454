import React from 'react';

import { FieldValue } from 'types/forms/forms';
import { FieldTypeEnum, LayoutSettings, MdfField } from 'types/graphqlTypes';

import { CheckboxField } from './checkbox/CheckboxField';
import { ChoiceField } from './choice/ChoiceField';
import { DateField } from './date/DateField';
import { LinkField } from './link/LinkField';
import { MultipleChoiceField } from './multiplechoice/MultipleChoiceField';
import { TextField } from './text/TextField';
import { TreeChoiceField } from './treechoice/TreeChoiceField';
import { UserField } from './user/UserField';

export interface FieldProps {
  fieldModel: MdfField;
  fieldSettings: LayoutSettings | null;
  defaultFieldSettings: LayoutSettings;

  value: FieldValue;
  setValue: (val: FieldValue) => void;
  style: React.CSSProperties;
  errorMessage: string | undefined;
  autoFocus?: boolean;
  setError: (err: string | undefined) => void;
  ignoreDefaultValue?: boolean;

  // Every unique instance of the MdfEditor will have this unique id
  editorId: string;

  // Will enforce date field to select a range. Note
  // that this will change the returned value to type DateRange
  // instead of string.
  forceDateRange?: boolean;

  // By default the editor will send new values when the user blurs
  // text fields, setting this to true will fire field changes on every
  // keystroke.
  fireOnChange?: boolean;
  // Indicates there is more vertical space to work with
  moreVerticalSpace?: boolean;
  // Override default placeholder text
  placeholder?: string;

  // If true disable editing/clearing value
  disableEdit?: boolean;
}

export const UserFriendlyLabel: Record<FieldTypeEnum, string> = {
  choice: 'Choice',
  multiplechoice: 'Multiple choice',
  checkbox: 'Checkbox',
  number: 'Number',
  link: 'Link',
  user: 'User/Team/Dept/Contact',
  text: 'Text',
  date: 'Date',
  treechoice: 'Tree choice',
};

export const FieldMap: Record<FieldTypeEnum, React.FC<FieldProps>> = {
  choice: ChoiceField,
  multiplechoice: MultipleChoiceField,
  treechoice: TreeChoiceField,
  number: TextField,
  link: LinkField,
  user: UserField,
  checkbox: CheckboxField,
  date: DateField,
  text: TextField,
};
