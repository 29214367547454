import React from 'react';
import PropTypes from 'prop-types';

import AppBar from 'screens/main/components/rightArea/sidebar/appBar';
import AvatarHeader from 'screens/main/components/rightArea/sidebar/avatarHeader';
import ShowItem from 'screens/main/components/rightArea/sidebar/showItem';
import DetailList from 'screens/main/components/rightArea/sidebar/detailList';

import { Wrapper, Body } from './summary-view-styled';

const SummaryView = ({
  title,
  description,
  email,
  phone,
  listDetails,
  onClose,
  type,
  subtitle,
  additionalInfo,
  variant,
  ...rest
}) => (
  <Wrapper>
    <AppBar>
      <AvatarHeader
        {...rest}
        variant={variant}
        type={type}
        isSummary
        disableUpload
        title={title}
        subtitle={subtitle}
        onClose={onClose}
      />
    </AppBar>
    <Body>
      <ShowItem title="Description" content={description || 'No description'} disableInteraction />
      {variant === 'People' && (
        <>
          <ShowItem title="E-MAIL" content={email || ''} disableInteraction />
          <ShowItem title="Mobile Phone" content={phone || ''} disableInteraction />
        </>
      )}
      {listDetails.map(({ listTitle, avatarVariant, members }) => (
        <DetailList
          key={avatarVariant}
          listTitle={listTitle}
          avatarVariant={avatarVariant}
          members={members}
          disableInteraction
        />
      ))}
    </Body>
  </Wrapper>
);

SummaryView.propTypes = {
  /** Team title  */
  title: PropTypes.string,
  /** Team Description  */
  description: PropTypes.string,
  /** list details */
  listDetails: PropTypes.arrayOf(
    PropTypes.shape({
      listTitle: PropTypes.string,
      avatarVariant: PropTypes.string,
      members: PropTypes.arrayOf([PropTypes.string, PropTypes.shape({})]),
    }),
  ),
};

SummaryView.defaultProps = {
  title: '',
  description: '',
  listDetails: [],
};

export default SummaryView;
