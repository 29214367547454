import React, { memo } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const LeafWrapper = styled('span')`
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
  text-decoration: ${({ underline, strikeThrough }) => {
    if (underline) return 'underline';
    if (strikeThrough) return 'line-through';
    return 'none';
  }};
  text-shadow: ${({ color }) => {
    if (color?.includes('ffffff')) return '1px 1px #000';
    return 'none';
  }};
  color: ${({ color }) => color || 'inherit'};
`;

function Leaf({ attributes, children, leaf }) {
  return (
    <LeafWrapper {...leaf} {...attributes}>
      {children}
    </LeafWrapper>
  );
}

Leaf.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
};

Leaf.defaultProps = {
  attributes: {},
  children: null,
};

export default memo(Leaf);
