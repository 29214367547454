import React, { useState, useCallback, memo, useMemo } from 'react';
import { useTheme } from '@emotion/react';
import { Editor } from 'slate';
import { useSlate, useFocused } from 'slate-react';

import Tooltip from 'components/tooltip';
import { getMark } from 'components/editor/utils';
import { GithubPicker } from 'components/reactColor';
import tooltipEnum from 'components/editor/constants/tooltipEnum';
import { markTypes, textColors } from 'components/editor/constants';

import { Color, Popover, Reset } from './styled';

const type = markTypes.COLOR;

const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};

const transformOrigin = {
  vertical: 'top',
  horizontal: 'center',
};

const ColorButton = () => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const editor = useSlate();
  const isFocused = useFocused();
  const markColor = getMark(editor, type) || 'transparent';

  const openPopover = useCallback(({ currentTarget }) => setAnchorEl(currentTarget), []);

  const closePopover = useCallback(() => setAnchorEl(null), []);

  const handleOpenPopover = useCallback(
    (event) => {
      event.preventDefault();

      if (isFocused) openPopover(event);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFocused],
  );

  const handleResetColor = useCallback(() => {
    closePopover();
    try {
      Editor.removeMark(editor, type);
    } catch (error) {
      // console.log(error);
    }
  }, [closePopover, editor]);

  const handleColorChange = useCallback(
    ({ hex }) => {
      closePopover();
      try {
        Editor.addMark(editor, type, hex);
      } catch (error) {
        // console.log(error);
      }
    },
    [closePopover, editor],
  );

  const renderColor = useMemo(
    () => (
      <Tooltip title={tooltipEnum.COLOR} noArrow>
        <Color onMouseDown={handleOpenPopover} $markColor={markColor} />
      </Tooltip>
    ),
    [handleOpenPopover, markColor],
  );

  return (
    <>
      {renderColor}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <Reset title="reset" onClick={handleResetColor} />
        <GithubPicker
          width={188}
          triangle="hide"
          styles={{
            default: {
              card: {
                background: theme.palette.dina.surfaceCard,
                paddingLeft: '31px',
              },
            },
          }}
          color={markColor}
          colors={textColors}
          onChangeComplete={handleColorChange}
        />
      </Popover>
    </>
  );
};

export default memo(ColorButton);
