import React, { useState } from 'react';

import SplitBar from 'components/split';

import { EditActions } from './EditActions';
import { SearchPlugins } from './SearchPlugins';

import { LeftListItem, Wrapper } from './styled';

interface Props {
  style?: React.CSSProperties;
}

type Integration = 'customActions' | 'searchPlugins';
interface IntegrationMenuItem {
  component: React.FC<Props>;
  label: string;
}

const MenuItems: Record<Integration, IntegrationMenuItem> = {
  customActions: {
    component: EditActions,
    label: 'Custom actions',
  },
  searchPlugins: {
    component: SearchPlugins,
    label: 'Search plugins',
  },
};

export const Integrations: React.FC = (): JSX.Element => {
  const [selectedMenu, setSelectedMenu] = useState<Integration>('customActions');
  const IntegrationPage = MenuItems[selectedMenu].component;
  return (
    <Wrapper>
      <SplitBar
        split={undefined}
        style={{
          height: '100%',
        }}
        primary="first"
        pane1Style={{
          minWidth: '180px',
          maxWidth: '300px',
        }}
        pane2Style={{
          minWidth: '200px',
        }}
      >
        <div>
          {Object.entries(MenuItems).map(([k, v]) => (
            <LeftListItem
              key={k}
              $selected={(k as Integration) === selectedMenu}
              onClick={() => setSelectedMenu(k as Integration)}
            >
              {v.label}
            </LeftListItem>
          ))}
        </div>
        <div style={{ width: '100%', height: '100%' }}>
          <IntegrationPage />
        </div>
      </SplitBar>
    </Wrapper>
  );
};
