import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { CircularProgress, Typography } from '@material-ui/core';

import Button from 'components/buttons/button';

export const ActionButtonsWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 0.5rem;
`;

export const CancelButton = styled(Button)`
  width: 104px;
  border-radius: 4px;
`;

export const SaveButton = styled(Button)`
  ${({ theme }) => css`
    background-color: ${theme.palette.dina.whiteHighEmphasis};
    color: ${theme.palette.dina.blackHighEmphasis};
    font-weight: 500;
    width: 104px;

    :hover {
      background-color: ${theme.palette.dina.whiteHighEmphasis};
      color: ${theme.palette.dina.blackHighEmphasis};
    }
  `};
`;

export const ForceUnlockButton = styled(Button)<{ $waitForConfirmation: boolean }>`
  border: 1px solid
    ${({ theme, $waitForConfirmation }) =>
      $waitForConfirmation
        ? theme.palette.dina.buttonBorderOutlined
        : theme.palette.dina.buttonBorderOutlinedBlack};
  color: ${({ theme, $waitForConfirmation }) =>
    $waitForConfirmation
      ? theme.palette.dina.whiteHighEmphasis
      : theme.palette.dina.blackHighEmphasis};
  font-weight: 500;
`;

export const Container = styled('div')<{
  readLock?: boolean;
  writeLock?: boolean;
}>`
  position: relative;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  padding: 0 0.5rem;
  gap: 8px;
  background-color: ${({ readLock, writeLock, theme }) => {
    if (writeLock) return theme.palette.dina.statusApproved;
    if (readLock) return theme.palette.dina.statusWarning;
    return 'transparent';
  }};
`;

export const MessageContainer = styled('div')`
  ${({ theme }) => css`
    ${theme.typography.dina.button};
    background-color: ${theme.palette.dina.blackInactive};
  `};
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const Progress = styled(CircularProgress)`
  color: ${({ theme }) => theme.palette.dina.whiteHighEmphasis};
  animation-duration: 1s;
`;

export const ProgressText = styled(Typography)`
  ${({ theme }) => css`
    ${theme.typography.dina.button};
    color: ${theme.palette.dina.whiteHighEmphasis};
  `};
`;
