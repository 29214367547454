import { useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import { format, differenceInMilliseconds } from 'date-fns';

import useLogger from 'utils/useLogger';

const ErrorEvents = {
  GraphQL: 'graphql',
  Offline: 'offline',
};

const isIgnoreErrors = (errors) => {
  const errorMessage = errors?.errors?.[0]?.message;

  // Can happen when the pc goes to sleep
  if (errorMessage?.includes('Connection closed')) return !navigator.onLine;
  // Feed error when the user is not authorized
  if (errorMessage?.includes('invalid filter value for operator `containsAny`')) return true;

  return false;
};

const useApolloSubscription = (query, options) => {
  const logger = useLogger('useApolloSubscription');

  const client = useApolloClient();

  const DefaultRefreshTimeout = 200; // Time to wait before triggering a refresh, in msec
  const MinimumTimeBetweenRefresh = 1000; // Minimum interval between two refresh request.
  const MaximumTimeBetweenRefresh = 10000; // Maximum interval between two refresh attempts.

  let subscription;
  let subscriptionCount = 0;
  let refreshTimeout;
  let lastRefreshTime;
  let lastSubscribeTime;
  let lastOfflineTime;
  const defaultErrorHandler = (payload) => {
    const { event, name, variables, error = '' } = payload;
    logger.log(`onError: ${event} ${name} ${JSON.stringify(variables)} ${JSON.stringify(error)}`);
  };

  const name = query.definitions?.[0]?.name?.value;
  const { variables, onSubscriptionData, onError = defaultErrorHandler, skip, source } = options;

  const getAuditPayload = (when = new Date()) => {
    const payload = {
      name,
      variables: variables ?? {},
      count: subscriptionCount,
      timestamp: format(when, 'HH:mm:ss.SSS'),
    };
    if (lastSubscribeTime) payload.subscribed = format(new Date(lastSubscribeTime), 'HH:mm:ss.SSS');
    if (lastRefreshTime) payload.refreshed = format(new Date(lastRefreshTime), 'HH:mm:ss.SSS');
    if (lastOfflineTime) payload.offline = format(new Date(lastOfflineTime), 'HH:mm:ss.SSS');
    if (source) payload.source = source;
    return payload;
  };

  const subscribe = () => {
    if (subscription) unsubscribe();
    if (skip) return;
    subscriptionCount += 1;
    lastSubscribeTime = new Date();

    lastOfflineTime = undefined;

    const querySubscription = client.subscribe({
      query,
      variables: { ...variables },
    });

    subscription = querySubscription.subscribe(
      (data) => {
        onSubscriptionData && onSubscriptionData({ client, subscriptionData: data });
      },
      (errors) => {
        const payload = getAuditPayload();
        if (isIgnoreErrors(errors)) {
          logger.info(JSON.stringify(payload), errors);
        } else {
          refreshSubscription();
          logger.error(JSON.stringify(payload), errors);
        }

        onError && onError({ event: ErrorEvents.GraphQL, client, ...payload, errors });
      },
    );
  };

  const unsubscribe = () => {
    if (subscription) {
      subscription.unsubscribe();
      subscription = undefined;
    }
    if (refreshTimeout) {
      clearTimeout(refreshTimeout);
      refreshTimeout = undefined;
    }
  };

  const refreshSubscription = (defaultTimeout = DefaultRefreshTimeout) => {
    if (refreshTimeout) {
      clearTimeout(refreshTimeout);
      refreshTimeout = undefined;
    }

    let timeout = defaultTimeout;
    const tnow = new Date();
    if (
      lastRefreshTime &&
      differenceInMilliseconds(tnow, lastRefreshTime) < MinimumTimeBetweenRefresh
    ) {
      timeout = MaximumTimeBetweenRefresh; // Will poll with low frequency for recurring errors
    }

    lastRefreshTime = new Date();
    refreshTimeout = setTimeout(() => subscribe(), timeout);
  };

  const updateConnectionStatus = (event) => {
    if (event.type === 'online') {
      logger.log('Online: reconnecting');
      refreshSubscription();
    } else {
      unsubscribe();
      const payload = getAuditPayload();
      onError && onError({ event: ErrorEvents.Offline, client, ...payload });
      lastOfflineTime = new Date();
    }
  };

  useEffect(() => {
    window.addEventListener('online', updateConnectionStatus);
    window.addEventListener('offline', updateConnectionStatus);
    return () => {
      window.removeEventListener('online', updateConnectionStatus);
      window.removeEventListener('offline', updateConnectionStatus);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [subscribe, unsubscribe];
};

export default useApolloSubscription;
