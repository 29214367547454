import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  menu: {
    background: theme.palette.dina.surfaceCard,
    minWidth: 280,
    borderRadius: '8px',
    backdropFilter: 'blur(30px)',
    maxHeight: '90vh',
    overflow: 'auto',
  },
  listSubheader: {
    ...theme.typography.dina.overline,
    margin: '6px 0px',
  },
  menuItem: {
    marginLeft: 0,
  },
  divider: {
    margin: '8px 0 7px 16px',
  },
}));

export default useStyles;
