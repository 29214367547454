import React, { useCallback, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSlate } from 'slate-react';
import { elementTypes } from 'components/editor/constants';
import { mediaTypes } from 'utils/rundownItemTypes';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import DropZone from 'components/editor/components/dropZone';
import addMos from './utils/addMos';

const { MOS } = mediaTypes;
const MosDropZone = ({ children, element }) => {
  const editor = useSlate();
  const { update } = useEditorContext();

  const onDrop = useCallback(
    ({ type, payload, iconUrl }) => {
      if (type === MOS) addMos(editor, element, payload, iconUrl, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  const mediaDropZoneAcceptedTypes = useMemo(() => {
    const accept = [mediaTypes.MOS];

    if (element.type === elementTypes.MOS) {
      accept.push(elementTypes.MOS);
    }

    return accept;
  }, [element.type]);

  return (
    <DropZone accept={mediaDropZoneAcceptedTypes} {...{ onDrop, element }}>
      {children}
    </DropZone>
  );
};

MosDropZone.propTypes = {
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

MosDropZone.defaultProps = {
  children: null,
  element: {
    children: [],
  },
};

export default memo(MosDropZone);
