import React, { memo, useCallback } from 'react';
import { Editor } from 'slate';
import { RenderElementProps, RenderLeafProps, RenderPlaceholderProps } from 'slate-react';

import SplitBar from 'components/split';
import { EditorFontSize } from 'types/editor';
import preventDefaultEvent from 'utils/preventDefaultEvent';
import stopEventPropagation from 'utils/stopEventPropagation';

import CustomPlaceholder from './components/customPlaceholder';
import Leaf from './components/leaf';
import elementComponents from './constants/elementComponents';
import variants from './constants/types/editorVariants';
import SidePanel from './Sidepanel';
import { useEditorMolecule } from './store';
import { onPaste } from './utils';

import { Editable, EditableWrapper } from './styled';

interface EditorSidePanelProps {
  children: React.ReactNode;
  height: string;
  sidePanelComponent: React.JSX.Element;
}

function EditorWithSidePanel({ children, height, sidePanelComponent }: EditorSidePanelProps) {
  const { useShowSidePanel } = useEditorMolecule();
  const [showSidePanel] = useShowSidePanel();
  return showSidePanel ? (
    <SplitBar
      style={{ height }}
      split="vertical"
      primary="second"
      pane1Style={{
        minWidth: '40%',
        maxWidth: '70%',
      }}
      pane2Style={{
        minWidth: '30%',
        maxWidth: '60%',
      }}
    >
      {children}
      {sidePanelComponent}
    </SplitBar>
  ) : (
    children
  );
}

interface CustomEditableProps {
  variant: string;
  height: string;
  padding: number;
  editorFontSize: EditorFontSize;
  onFocus: React.FocusEventHandler<HTMLDivElement>;
  onBlur: React.FocusEventHandler<HTMLDivElement>;
  onKeyDown: React.KeyboardEventHandler<HTMLDivElement>;
  placeholder?: string;
  readOnly?: boolean;
  editor: Editor;
  direction: string;
}

function CustomEditable({
  height,
  padding,
  editorFontSize,
  variant,
  onFocus,
  onBlur,
  onKeyDown,
  placeholder,
  readOnly,
  direction,
  editor,
}: Readonly<CustomEditableProps>) {
  const renderElement = useCallback(
    (elementProps: RenderElementProps) => {
      const ElementComponent = elementComponents(elementProps, variant);

      return <ElementComponent readOnly={undefined} direction={direction} {...elementProps} />;
    },
    [variant, direction],
  );

  const renderLeaf = useCallback((leafProps: RenderLeafProps) => <Leaf {...leafProps} />, []);

  const renderPlaceholder = useCallback(
    (placeholderProps: RenderPlaceholderProps) => <CustomPlaceholder {...placeholderProps} />,
    [],
  );

  const handlePaste: React.ClipboardEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      onPaste(event, editor);
    },
    [editor],
  );

  /** stop event bubble for onkeyup: for rundown grid */
  const onKeyUp: React.KeyboardEventHandler<HTMLDivElement> = useCallback((event) => {
    preventDefaultEvent(event);
    stopEventPropagation(event);
  }, []);

  return (
    <EditorWithSidePanel height={height} sidePanelComponent={<SidePanel />}>
      <EditableWrapper
        padding={padding}
        fontSize={editorFontSize}
        messageVariant={variant === variants.MESSAGE}
        onMouseDown={preventDefaultEvent}
      >
        <Editable
          autoFocus
          onMouseDown={stopEventPropagation}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          placeholder={placeholder}
          readOnly={readOnly}
          renderPlaceholder={renderPlaceholder}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          onDrop={() => true}
          onPaste={handlePaste}
        />
      </EditableWrapper>
    </EditorWithSidePanel>
  );
}

export default memo(CustomEditable);
