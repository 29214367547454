import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Popper } from '@material-ui/core';

import { ReactComponent as ArrowIcon } from 'assets/icons/systemicons/arrows/arrow_double_unboxed.svg';
import { ReactComponent as EmojiSvg } from 'assets/icons/systemicons/editor/emoji_small.svg';
import transientOptions from 'theme/helpers';

interface EmojiButtonProps {
  $disabled: boolean;
}
export const EmojiButton = styled('div', transientOptions)<EmojiButtonProps>`
  display: flex;
  align-items: center;
  ${({ $disabled }) =>
    $disabled &&
    css`
      user-select: none;
    `};
  :hover {
    svg > path {
      fill-opacity: 1;
    }
  }
`;

export const Arrow = styled(ArrowIcon)`
  margin-left: 4px;
  path {
    fill: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
`;

export const EmojiIcon = styled(EmojiSvg)`
  path {
    fill: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
`;

export const StyledPopper = styled(Popper)`
  z-index: 1300;
  pointer-events: auto;
`;
