import React, { useContext, useRef, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import LoadingIndicator from 'components/loadingIndicator';
import UserContext from 'contexts/UserContext';
import GET_MESSAGES_OF_CONVERSATION from 'graphql/queries/getMessagesOfConversation';
import useUpdateConvoReadAt from 'hooks/useUpdateConvoReadAt';
import useLogger from 'utils/useLogger';
import Messages from './messages-view';
import useConversationContext from '../../../hooks/useConversationContext';

const MessagesContainer = ({ handleSendMessage }) => {
  const logger = useLogger('Messages Container');
  const { currentConversation, onClose } = useConversationContext();
  const { mId } = currentConversation;
  const user = useContext(UserContext);
  const lastMessageRef = useRef(null);
  const [updateConvoReadAt] = useUpdateConvoReadAt();

  const handleDelete = async (mRefId) => {
    try {
      await handleSendMessage('', mRefId, 'DELETE');
    } catch (e) {
      // console.log(e);
    }
  };

  const handleUpdate = async (mRefId, mContent) => {
    try {
      await handleSendMessage(mContent, mRefId, 'UPDATE');
    } catch (e) {
      // console.log(e);
    }
  };

  useEffect(() => {
    lastMessageRef.current = null;
    updateConvoReadAt(mId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mId]);

  const variables = {
    input: { mId },
    limit: 25,
  };

  const { data, loading, error, fetchMore } = useQuery(GET_MESSAGES_OF_CONVERSATION, {
    variables,
    skip: !mId,
    fetchPolicy: 'cache-and-network',
  });

  if (!mId) return <div />;
  if (loading) return <LoadingIndicator />;
  if (error) {
    logger.error(error);
    return <div />;
  }

  const messages = data?.getMessagesOfConversation?.items || [];
  const hasMore = Boolean(data?.getMessagesOfConversation?.nextToken);

  const sortedMessages = [...messages].sort((a, b) => (a.mCreatedAt > b.mCreatedAt ? -1 : 1));

  const handleLoadMore = async () => {
    if (hasMore) {
      const [lastMessage] = sortedMessages.slice(-1);
      lastMessageRef.current = lastMessage.mRefId;
      await fetchMore({
        variables: {
          ...variables,
          nextToken: data.getMessagesOfConversation.nextToken,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => ({
          getMessagesOfConversation: {
            items: [
              ...previousResult.getMessagesOfConversation.items,
              ...fetchMoreResult.getMessagesOfConversation.items,
            ],
            nextToken: fetchMoreResult.getMessagesOfConversation.nextToken,
            __typename: 'PaginatedMessageType',
          },
        }),
      });
    }
  };

  return (
    <Messages
      messages={sortedMessages}
      userId={user.mId}
      hasMore={hasMore}
      onLoadMore={handleLoadMore}
      lastMessageRef={lastMessageRef}
      onDeleteMessage={handleDelete}
      onUpdateMessage={handleUpdate}
      onClose={onClose}
    />
  );
};

MessagesContainer.propTypes = {};

MessagesContainer.defaultProps = {};

export default MessagesContainer;
