import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { ReactComponent as OwnerIconSvg } from 'assets/icons/systemicons/owner_indicator.svg';
import transientOptions from 'theme/helpers';

export const FocusWrapper = styled('div', transientOptions)`
  padding: 8px;
`;

export const SelectWrapper = styled('div', transientOptions)<{ $selected: boolean }>`
  border-radius: 4px;
  height: 40px;
  display: flex;
  align-items: center;
  max-width: 600px;
  cursor: pointer;
  box-shadow: ${({ $selected, theme }) =>
    $selected
      ? `0 0 0 2px ${theme.palette.dina.statusOnFocused}`
      : `0 0 0 2px ${theme.palette.dina.borderResting}`};
  background: ${({ $selected, theme }) =>
    $selected ? theme.palette.dina.backgroundSelected : theme.palette.dina.blackHoverOverlay};
  :hover {
    box-shadow: ${({ $selected, theme }) =>
      $selected
        ? `0 0 0 2px ${theme.palette.dina.statusOnFocused}`
        : `0 0 0 2px ${theme.palette.dina.blockBorderHover}`};
    background: ${({ theme }) => theme.palette.dina.tableAlternatingRowOverlay};
  }
`;

export const IconWrapper = styled('div')`
  width: 32px;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.dina.buttonBackgroundHighEmphasis};
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    path: {
      fill-opacity: 1;
    }
  }
`;

export const LabelWrapper = styled('div')`
  flex: 1;
  padding-left: 8px;
  p:first-child {
    margin-top: 4px;
    margin-bottom: 2px;
  }
`;

export const AvatarWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 2px;
`;

export const OwnerWrapper = styled('div')`
  position: relative;
`;

export const OwnerIcon = styled(OwnerIconSvg)`
  position: absolute;
  top: -2px;
  right: -2px;
`;

export const StatusWrapper = styled('div')`
  ${({ theme }) => css`
    border: 1px solid ${theme.palette.dina.dividerLight};
    background-color: ${theme.palette.dina.blackHoverOverlay};
  `}
  height: 24px;
  padding: 0px 10px;
  border-radius: 6px;
  margin-left: 8px;
  margin-right: 16px;
`;
