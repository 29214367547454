/* eslint-disable react/prop-types */
import { useCallback } from 'react';
import { Item, ItemParams, PredicateParams } from 'react-contexify';
import styled from '@emotion/styled';

import { ReactComponent as AddStory } from 'assets/icons/search/add_story.svg';
import { ReactComponent as Open } from 'assets/icons/systemicons/open.svg';
import { useCreateMemberDialog } from 'components/createNewV3/CreateNewDialog';
import { Menu } from 'features/contextMenu/styled';
import { SearchResultItem } from 'features/searchPlugin/types';
import { HStack } from 'layouts/box/Box';

const MenuText = styled('div')`
  position: relative;
  top: -1px;
`;

const IconWrapper = styled('div')`
  width: 24px;
  padding: 0 2px;
  flex-grow: 0;
  flex-shrink: 0;

  svg {
    width: 20px;
    height: 20px;
  }
`;

interface MemberMenuProps {
  item: SearchResultItem;
}

const shouldOpenInTabBeHidden = (data: PredicateParams<MemberMenuProps, MemberMenuProps>) => {
  return data.props?.item?.externalUrl === undefined;
};

const allowCreateStoryFromItem = (data: PredicateParams<MemberMenuProps, MemberMenuProps>) => {
  return data.props?.item?.allowCreateStory === false;
};

interface PluginContextMenuProps {
  item?: SearchResultItem;
  type: 'open' | 'create';
}

export function PluginSearchContextMenu() {
  const [, showCreateMemberDialog] = useCreateMemberDialog();

  const doCreateStory = useCallback(
    (item: SearchResultItem) => {
      showCreateMemberDialog({
        mTitle: item.title,
        forceShow: true,
        anchorEl: null,
        ...(item.metadata && {
          preselectedMetadata: {
            metadata: item.metadata,
            mdfId: 'story-mdf',
          },
        }),
      });
    },
    [showCreateMemberDialog],
  );

  const handleClickEvent = ({ props }: ItemParams<PluginContextMenuProps>) => {
    if (props?.item) {
      if (props.type === 'create') {
        doCreateStory(props.item);
      } else if (props.item.externalUrl) {
        window.open(props.item.externalUrl, '_newtab');
      }
    }
  };

  return (
    <Menu id="pluginMenu" style={{ zIndex: 2400 }}>
      <Item
        id="open"
        key="open"
        hidden={shouldOpenInTabBeHidden}
        onClick={({ props, event, triggerEvent }: ItemParams<PluginContextMenuProps>) =>
          handleClickEvent({ props: { ...props, type: 'open' }, event, triggerEvent })
        }
      >
        <HStack justifyContent="start" gap="6px" alignItems="center">
          <IconWrapper>
            <Open />
          </IconWrapper>
          <div>Open in new tab</div>
        </HStack>
      </Item>
      <Item
        id="create_story"
        key="create_story"
        hidden={allowCreateStoryFromItem}
        onClick={({ props, event, triggerEvent }: ItemParams<PluginContextMenuProps>) =>
          handleClickEvent({ props: { ...props, type: 'create' }, event, triggerEvent })
        }
      >
        <HStack justifyContent="start" gap="6px" alignItems="center" maxWidth="300px">
          <IconWrapper>
            <AddStory className="skipOverride" />
          </IconWrapper>
          <MenuText>Create story</MenuText>
        </HStack>
      </Item>
    </Menu>
  );
}
