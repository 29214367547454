import { css } from '@emotion/react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Assignees from 'components/assignees/Assignees';
import { useMembersDialog } from 'store';

const Container = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ShareButton = styled('div')`
  ${({ size }) => css`
    width: ${size}px;
    height: ${size}px;
  `}
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover svg path {
    fill-opacity: 1;
  }
`;

const AssigneesView = ({ assignees, onAssigneeUpdate }) => {
  const [, setGetMembers] = useMembersDialog();
  const openDialog = () => {
    setGetMembers({
      open: true,
      variant: 'all',
      removeTooltipText: 'Unassign',
      dialogTitle: 'Assign to instance',
      subHeader: 'Assigned To:',
      startWith: [...assignees],
      showMessage: true,
      onOk: (newMembers, messageAssign) => {
        if (newMembers) {
          onAssigneeUpdate(newMembers, messageAssign);
        }
      },
    });
  };

  return (
    <Container>
      <Assignees members={assignees} size={24} maxAvatarToShow={3} onClick={openDialog} />
    </Container>
  );
};

AssigneesView.propTypes = {
  /** List of users assigned to current instance */
  assignees: PropTypes.arrayOf(
    PropTypes.shape({
      mAvatarUrl: PropTypes.string,
    }),
  ),
  /** Callback to be invoked user assignees are updated  */
  onAssigneeUpdate: PropTypes.func,
};

AssigneesView.defaultProps = {
  assignees: [],
  onAssigneeUpdate: (updatedAssignees) => {},
};

export default AssigneesView;
