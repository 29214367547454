import PropTypes from 'prop-types';

import { UNTITLED_STORY } from 'utils/constants';

import { StyledTooltipDescription, StyledTooltipTitleWrapper } from './styled';

const TabToolTip = ({ title }: { title: string }) => (
  <StyledTooltipTitleWrapper>
    <StyledTooltipDescription>{title ?? UNTITLED_STORY}</StyledTooltipDescription>
  </StyledTooltipTitleWrapper>
);

TabToolTip.propTypes = {
  /** title of the tab that should be shown in the tooltip */
  title: PropTypes.string,
};

TabToolTip.defaultProps = {
  title: UNTITLED_STORY,
};

export default TabToolTip;
