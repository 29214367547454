import { useState } from 'react';
import { ApolloCache, FetchResult, useMutation } from '@apollo/client';

import { blockFormSplit } from 'graphql/global-functions/utils/saveMetaFormOnContext';
import memberTypes from 'graphql/memberTypes';
import UPDATE_FORM from 'graphql/mutations/updateForm';
import { getMembersQuery } from 'graphql/queryVariables';
import { GET_FORM_FROM_MEMBER } from 'hooks/useGetFormFromMember';
import { BlockForms, useBlockForms } from 'store';
import { Form } from 'types/forms/forms';

const useUpdateForm = (
  updateCacheFn?: (
    proxy: ApolloCache<Record<string, string>>,
    mutationResult: Omit<FetchResult<{ updateForm: Form }>, 'context'>,
  ) => void,
) => {
  const [updateForm] = useMutation<{ updateForm: Form }>(UPDATE_FORM);
  const [loading, setLoading] = useState(false);
  const saveForm = async (form: Form) => {
    setLoading(true);
    const promise = await updateForm({
      variables: {
        input: {
          mId: form.mId,
          mTitle: form.mTitle,
          mRefId: form.mRefId,
          mRefTitle: form.mRefTitle,
          mType: form.mType,
          mProperties: form.mProperties,
        },
      },
      update(proxy: ApolloCache<Record<string, string>>, mutationResult) {
        if (updateCacheFn) {
          updateCacheFn(proxy, mutationResult);
        }
      },
    });
    setLoading(false);
    return promise;
  };

  return [saveForm, loading] as const;
};

export const useUpdateMemberForm = () => {
  const updateMemberFormCache = (
    proxy: ApolloCache<Record<string, string>>,
    mutationResult: Omit<FetchResult<{ updateForm: Form }>, 'context'>,
  ) => {
    if (!mutationResult.data?.updateForm) return;

    const { updateForm } = mutationResult.data;

    const cachedForms = proxy.readQuery<{ getMembers: Form[] }>({
      query: GET_FORM_FROM_MEMBER,
      variables: getMembersQuery(updateForm.mId, memberTypes.FORM),
    });

    const allForms = [
      ...(cachedForms?.getMembers.filter((m) => m.mRefId !== updateForm.mRefId) ?? []),
      updateForm,
    ];

    proxy.writeQuery({
      query: GET_FORM_FROM_MEMBER,
      variables: getMembersQuery(updateForm.mId, memberTypes.FORM),
      data: {
        getMembers: allForms,
      },
    });
  };
  return useUpdateForm(updateMemberFormCache);
};

export const useUpdateBlockForm = () => {
  const [saveForm, loading] = useUpdateForm();
  const [, setBlockForms] = useBlockForms();

  const saveBlockForm = async (form: Form) => {
    await saveForm(form);
    const { platform, blockType } = blockFormSplit(form.mRefId) as Record<string, string>;

    setBlockForms((prevState) => ({
      ...prevState,
      [platform]: {
        ...prevState[platform],
        [blockType]: form,
      } as BlockForms,
    }));
  };

  return [saveBlockForm, loading];
};
