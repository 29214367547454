import React from 'react';
import AddItem from 'screens/main/components/rightArea/sidebar/teams/listItem/addItem/addItem-view';
import { useMembersDialog } from 'store';
import DetailList from '../../../../detailList';

const Departments = ({ teamDepartments, addDepartment, teamTitle, containerRef, ...rest }) => {
  const teamDepartmentsExist = teamDepartments.length > 0;
  const [, setGetMembers] = useMembersDialog();

  const doGetDepartments = () => {
    setGetMembers({
      variant: 'department',
      open: true,
      startWith: [...teamDepartments],
      dialogTitle: `Add ${teamTitle} to departments`,
      removeTooltipText: `Remove ${teamTitle} from department`,
      subHeader: `${teamTitle} is part of departments:`,
      onOk: (newMembers) => {
        addDepartment(newMembers);
      },
    });
  };

  return (
    <div>
      {teamDepartmentsExist && (
        <DetailList
          listTitle="DEPARTMENTS"
          members={teamDepartments}
          avatarVariant="department"
          {...rest}
        />
      )}

      <AddItem title="Departments..." onClick={doGetDepartments} />
    </div>
  );
};

export default Departments;
