import { useState, useEffect, forwardRef } from 'react';
import { useDrag } from 'react-dnd';
import DndTypes from 'utils/dndTypes';
import InstancePreview from 'screens/main/components/leftArea/sidebar/rundown/instancePreview';
import useGetAssignedMembers from 'hooks/useGetAssignedMembers';
import useDinaNavigate from 'hooks/useDinaNavigate';
import useSelectRundownInstance from 'hooks/useSelectRundownInstance';
import useMouseClickEvents from 'hooks/useMouseClickEvents';
import useCheckUserRight from 'hooks/useCheckUserRight';
import { CustomFields } from 'utils/metadata';
import publishingPoints from 'components/instanceCard/utils/publishingPoints';
import KanbanCard from 'components/kanbanCard';
import getIdentifier from 'utils/instance/getAccountIdentifier';
import { defaultLinearPlatformKind } from 'utils/instance/platform';

import { InstancePreviewWrapper, ListItem, Popper } from './listItem-styled';

export const InstanceItemVariant = {
  INSTANCE_LIST_ITEM: 'instance_list_item',
  CREATE_LINK_LIST_ITEM: 'create_link_list_item',
};

const getMetaDataValue = (mMetaData, key) => {
  const foundMetaData = (mMetaData || []).find((metaData) => metaData.key === key);
  return foundMetaData?.value;
};

const InstanceItem = (props, ref) => {
  const {
    instance,
    isInstanceItemSelected,
    setOpenPreviewIndex,
    setCurrentFocusedIndex,
    index,
    onSelectInstance,
    variant,
    disabled,
  } = props;
  const {
    mId,
    mTitle: title,
    items,
    mProperties,
    mUpdatedAt,
    mState,
    mPublishingAt,
    mRelatedMembers,
    mAssignedMembers,
    mStoryId,
    isTemplateInstance,
    mTemplateId,
    mMetaData,
    mContentKey,
  } = instance;

  const blockTitle = getMetaDataValue(mMetaData, 'block_title');

  const { platform: instanceType, account = {}, platformKind } = mProperties ?? {};
  const storyTitle = getMetaDataValue(mMetaData, CustomFields.STORY_TITLE);

  const { accountTitle, accountId } = account;
  const accountIdentifier = getIdentifier(instanceType, accountTitle);
  const [checkUserRight] = useCheckUserRight();
  const hasPermissionForPlatform = checkUserRight('platform', accountIdentifier);
  const canScheduleInstance = checkUserRight('instance', 'schedule') && hasPermissionForPlatform;

  const isInstanceItemVariant = variant === InstanceItemVariant.INSTANCE_LIST_ITEM;
  const [getAssignedMembers] = useGetAssignedMembers(mAssignedMembers || []);

  const { navigateTo } = useDinaNavigate();
  const { selectRundownInstance } = useSelectRundownInstance();

  const isOpenStoryDisabled = Boolean(isTemplateInstance || mTemplateId);

  const handleOpenStory = (event) => {
    handleClose(event);
    if (isOpenStoryDisabled) {
      handleOpenRundown();
      return;
    }
    navigateTo('story', mStoryId, {
      tab: 'instances',
      entityId: mId,
    });
  };

  const [assignedUsers, assignedTeams, assignedDepartments] = getAssignedMembers();
  const assignedMembers = [...assignedUsers, ...assignedTeams, ...assignedDepartments];

  const destination = account?.accountTitle;

  const handleOpenRundown = () => {
    if (instanceType === publishingPoints.LINEAR && destination !== 'Unassigned' && accountId) {
      navigateTo(isTemplateInstance ? 'rundowntemplate' : 'rundown', accountId);
      selectRundownInstance([mId]);
    }
  };

  const payload = {
    id: mId,
    platform: instanceType,
    publishingAt: mPublishingAt,
    statusId: mState,
    accountId: account.accountId,
    accountRefId: account.accountRefId,
    platformKind: platformKind || defaultLinearPlatformKind,
    ...instance,
  };

  const [, dragRef] = useDrag({
    canDrag: () => canScheduleInstance,
    type: DndTypes.INSTANCE_LIST_ITEM,
    item: { type: DndTypes.INSTANCE_LIST_ITEM, payload },
  });
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
    setCurrentFocusedIndex(null);
    setOpenPreviewIndex(null);
  };

  const handleClick = () => {
    const { current } = ref;
    setAnchorEl(current);
    setCurrentFocusedIndex(index);
    setOpenPreviewIndex(index);
  };

  useEffect(() => {
    if (isInstanceItemSelected && isInstanceItemVariant) {
      const { current } = ref;
      setAnchorEl(current);
      setCurrentFocusedIndex(index);
      setOpenPreviewIndex(index);
    } else {
      setAnchorEl(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInstanceItemSelected]);

  const handleClickInstance = (event) => {
    if (isInstanceItemVariant) handleClick();
    else onSelectInstance(event, index, mId);
  };

  const [onClick, onDoubleClick] = useMouseClickEvents(handleClickInstance, handleOpenStory);

  return (
    <ListItem
      button
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      ref={isInstanceItemVariant ? dragRef : null}
      disabled={disabled}
      selected={isInstanceItemSelected && !disabled}
      $isInstanceItemVariant={isInstanceItemVariant}
    >
      <KanbanCard
        destination={destination}
        mRelatedMembers={mRelatedMembers}
        updatedAt={mUpdatedAt}
        storyTitle={storyTitle}
        blockTitle={blockTitle}
        title={title}
        mPublishingAt={mPublishingAt}
        items={items}
        mState={mState}
        assignedUsers={assignedMembers}
        platform={instanceType}
        platformKind={platformKind}
        canOpenDestination={
          instanceType === publishingPoints.LINEAR && destination !== 'Unassigned'
        }
        openDestination={handleOpenRundown}
      />
      <Popper anchorEl={anchorEl} position="right-start">
        <InstancePreviewWrapper
          onClick={(e) => {
            e.stopPropagation();
            // e.preventDefault();
          }}
          onDoubleClick={(e) => {
            e.stopPropagation();
          }}
        >
          <InstancePreview
            onClose={handleClose}
            onOpenStory={handleOpenStory}
            onOpenRundown={handleOpenRundown}
            id={mId}
            title={title}
            mContentKey={mContentKey}
            assignees={assignedMembers}
            state={mState}
            metaData={mMetaData}
            destination={destination}
            platform={instanceType}
            publishingAt={mPublishingAt}
            isOpenStoryDisabled={isOpenStoryDisabled}
            instance={instance}
            isLinkingDisabled
          />
        </InstancePreviewWrapper>
      </Popper>
    </ListItem>
  );
};

const forwardedInstanceItem = forwardRef(InstanceItem);
export default forwardedInstanceItem;
