import styled from '@emotion/styled';

import Divider from 'components/divider';

export const UserContentWrapper = styled.div`
  margin-left: auto;
  padding: 8px;
  display: flex;
  gap: 1rem;
`;

export const StyledTabDivider = styled(Divider)`
  margin: 4px;
`;

export const OrdersButtonWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
