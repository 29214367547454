import React, { useState, useEffect, useCallback, memo } from 'react';
import PropTypes from 'prop-types';

import { searchbarActions } from 'utils/constants/searchbar';
import stopEventPropagation from 'utils/stopEventPropagation';
import transitionTypeEnum from 'utils/constants/transitionTypeEnum';
import useCheckUserRight from 'hooks/useCheckUserRight';
import useSearchBar from 'hooks/useSearchBar';
import useDebounce from 'hooks/use-debounce';
import useGetPlatforms from 'hooks/useGetPlatforms';
import { ReactComponent as ContentIcon } from 'assets/icons/systemicons/editor/contents.svg';
import { ReactComponent as LinkIcon } from 'assets/icons/systemicons/editor/link.svg';
import { Button } from 'components/buttons';
import Searchbar from 'components/searchbar';
import Divider from 'components/divider';
import InputField from 'components/inputField/InputField';

import actionTypes from '../../constants/actionTypes';
import InstanceList from '../instanceList';

import {
  InputWrapper,
  MaterialDialogWrapper,
  Heading,
  StyledInputFieldWrapper,
  IconWrapper,
  StyledDialogActions,
} from './styled';

const { CANCEL, CONFIRM } = actionTypes;

const DialogContent = ({ initialLink, initialLinkText, update }) => {
  const [filters] = useState([]);
  const [shouldSearch, setShouldSearch] = useState(true);
  const [href, setHref] = useState(initialLink);
  const [linkText, setLinkText] = useState(initialLinkText);
  const cancel = useCallback(() => update({ type: CANCEL }), [update]);
  const [checkUserRight] = useCheckUserRight();
  const showInstanceListToLink = checkUserRight('feature', 'instance-link');
  const [platformsData, setPlatformsData] = useState();

  const [data, error, pLoading] = useGetPlatforms(new Date());

  useEffect(() => {
    if (!pLoading && !error) setPlatformsData(data);
  }, [data, pLoading, error]);

  const {
    filterModel,
    updateFilterModel,
    history,
    updateHistory,
    currentSearch,
    updateCurrentSearch,
    searchString,
    instanceAssignedMembers: assignedMembers,
    platformsFilter,
  } = useSearchBar({ historyLimit: 5 });

  const confirm = useCallback(
    () => update({ type: CONFIRM, payload: { href, linkText } }),
    [linkText, update, href],
  );

  const onUpdateLinkInput = useCallback(
    (newValue) => {
      if (!shouldSearch) setShouldSearch(true);
      setHref(newValue);
    },
    [setHref, setShouldSearch, shouldSearch],
  );

  const handleEnterEscape = useCallback(
    (event) => {
      const { key } = event;
      const isEnter = key === 'Enter';
      const isEscape = key === 'Escape';

      if (isEnter || isEscape) event.preventDefault();

      if (isEnter) confirm();

      if (isEscape) cancel();
    },
    [cancel, confirm],
  );

  const onSelectInstance = useCallback(
    (instanceId) => {
      setShouldSearch(false);
      setHref(`dina://${instanceId}`);
    },
    [setHref],
  );

  const debouncedSearchTerm = useDebounce(href, 250);
  // Effect for API call
  useEffect(
    () => {
      if (shouldSearch) {
        updateFilterModel({ type: searchbarActions.UPDATE_FREE_TEXT, value: debouncedSearchTerm });
      }
    },
    [debouncedSearchTerm, updateFilterModel, shouldSearch],
    // Only call effect if debounced search term changes
  );

  const dialogTitle = showInstanceListToLink
    ? 'Paste URL or Link to another instance'
    : 'Paste URL';

  return (
    <div onKeyDown={handleEnterEscape} onMouseDown={stopEventPropagation} role="presentation">
      <MaterialDialogWrapper>
        <Heading>{dialogTitle}</Heading>
        <InputWrapper>
          <IconWrapper>
            <ContentIcon />
          </IconWrapper>
          <StyledInputFieldWrapper>
            <InputField
              fullWidth
              placeholder="Text to Display"
              disableLabel
              value={linkText}
              onChange={setLinkText}
              usage="editor"
            />
          </StyledInputFieldWrapper>
        </InputWrapper>
        <InputWrapper>
          <IconWrapper>
            <LinkIcon />
          </IconWrapper>
          <StyledInputFieldWrapper>
            <InputField
              fullWidth
              placeholder={
                showInstanceListToLink === true ? 'Paste or search for a link...' : 'Paste a link'
              }
              disableLabel
              value={href}
              onChange={onUpdateLinkInput}
              usage="editor"
            />
          </StyledInputFieldWrapper>
        </InputWrapper>
        {showInstanceListToLink && (
          <Searchbar
            chipTypes={['platform', 'user']}
            savedFilters={filters}
            hideSavedFilters
            platforms={platformsData}
            filterModel={filterModel}
            updateFilterModel={updateFilterModel}
            history={history}
            updateHistory={updateHistory}
            currentSearch={currentSearch}
            updateCurrentSearch={updateCurrentSearch}
            showFreeTextInput={false}
            showSavedFiltersButton={false}
            transitionType={transitionTypeEnum.FADE}
          />
        )}
        {showInstanceListToLink && (
          <InstanceList
            onSelectInstance={onSelectInstance}
            searchString={searchString}
            platformsFilter={platformsFilter}
            assignedMembers={assignedMembers}
          />
        )}
      </MaterialDialogWrapper>
      <Divider />
      <StyledDialogActions>
        <Button variant="outlined" usage="outlined" onClick={cancel}>
          Cancel
        </Button>
        <Button variant="outlined" usage="cta" onClick={confirm} disabled={href.length === 0}>
          Create Link
        </Button>
      </StyledDialogActions>
    </div>
  );
};

DialogContent.propTypes = {
  /** Specifies existent url for the link */
  initialLink: PropTypes.string,
  /** Specifies existing text for the link */
  initialLinkText: PropTypes.string,
  /** Callback to be invoked on change, with update type and relevant payload passed in */
  update: PropTypes.func,
};

DialogContent.defaultProps = {
  initialLink: '',
  initialLinkText: '',
  update: ({ type, payload }) => {},
};

export default memo(DialogContent);
