import React from 'react';
import { create } from 'jss';
import JssProvider from 'react-jss/lib/JssProvider';
import { CssBaseline } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ThemeProvider, createGenerateClassName, jssPreset } from '@material-ui/styles';
import { ThemeProvider as EmotionThemeProvider, Global, css } from '@emotion/react';
import { useDinaTheme, useChangedTheme } from 'store';

import colorDark from './colors-dark';
import colorLight from './colors-light';
import getTypography from './typography';

export default function Theme({ children }) {
  const [userTheme] = useDinaTheme();
  const [changedTheme] = useChangedTheme();
  const generateClassName = createGenerateClassName();
  const jss = create({
    ...jssPreset(),
    insertionPoint: document.getElementById('jss-insertion-point'),
  });

  const themeToUse = changedTheme || userTheme || 'dark';
  const systemTheme = useMediaQuery('(prefers-color-scheme: light)') ? 'light' : 'dark';
  const themePreference = themeToUse === 'system' ? systemTheme : changedTheme || themeToUse;
  const prefersLightTheme = themePreference === 'light';

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: themePreference,
          type: themePreference,
          dina: {
            ...(prefersLightTheme ? colorLight : colorDark),
          },
          background: prefersLightTheme
            ? { default: '#E5E7E9', paper: '#E5E7E9' }
            : { default: '#222a35', paper: '#222a35' },
        },
        typography: {
          useNextVariants: true,
          dina: {
            ...getTypography(themePreference === 'light' ? colorLight : colorDark),
          },
        },
      }),
    [themePreference, prefersLightTheme],
  );

  return (
    <JssProvider jss={jss} generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>
        <EmotionThemeProvider theme={theme}>
          <CssBaseline />
          <Global
            styles={css`
              svg:not(.skipOverride) {
                path {
                  fill: ${theme.palette.dina.highEmphasis};
                  fill-opacity: 0.54;
                }
              }
              /* path {
                fill: ${theme.palette.dina.highEmphasis};
                fill-opacity: 0.54;
              } */

              /* width */
              ::-webkit-scrollbar {
                width: 16px;
                height: 16px;
                background: rgba(255, 255, 255, 0);
                position: absolute;
              }

              /* Track */
              ::-webkit-scrollbar-track {
                box-shadow: inset 0 0 16px 16px rgba(255, 255, 255, 0);
                border: solid 6px transparent;
              }

              /* Handle */
              ::-webkit-scrollbar-thumb {
                box-shadow: inset 0 0 16px 16px
                  ${prefersLightTheme ? 'rgba(0, 0, 0, 0.25)' : 'rgba(255, 255, 255, 0.25)'};
                border: solid 6px transparent;
                border-radius: 8px;
              }

              /** hover effect on thumb */
              ::-webkit-scrollbar-thumb:hover {
                box-shadow: inset 0 0 16px 16px
                  ${prefersLightTheme ? 'rgba(0, 0, 0, 0.35)' : 'rgba(255, 255, 255, 0.35)'};
              }

              /** active effect on thumb */
              ::-webkit-scrollbar-thumb:active {
                box-shadow: inset 0 0 16px 16px
                  ${prefersLightTheme ? 'rgba(0, 0, 0, 0.54)' : 'rgba(255, 255, 255, 0.54)'};
              }

              /* Remove blue outline on Dina login page */
              #root {
                outline: none;
              }
            `}
          />
          {children}
        </EmotionThemeProvider>
      </ThemeProvider>
    </JssProvider>
  );
}
