import useCheckUserRight from 'hooks/useCheckUserRight';

import { useSelectedCategory, useSelectedMenuSystemSettings } from '../../../../../atoms';
import { CATEGORY_TYPES } from '../../../../../utils/settingTypes';
import List from '../../../../collapseList';

const webhooks = {
  ORDER: 'order',
  STORY_CONTENT: 'story-content',
  NOTE_CONTENT: 'note-content',
};

function WebhooksMenu() {
  const [checkUserRight] = useCheckUserRight();
  const [category, setCategory] = useSelectedCategory();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [selectedMenu, setSelectedMenu] = useSelectedMenuSystemSettings();
  const canShowOrderWebhooks = checkUserRight('feature', 'ordermanagement');

  const onMenuClick = (
    event: React.MouseEvent<HTMLLIElement>,
    menuType: string,
    categoryType: string,
  ) => {
    event.preventDefault();
    setSelectedMenu(menuType);
    setCategory(categoryType);
  };

  return (
    <List>
      <List.Header
        selected={category === CATEGORY_TYPES.WEBHOOKS}
        hideCreateButton
        itemCount={undefined}
        createButtonTooltip={undefined}
        createButtonDisabled={undefined}
        onCreateClick={undefined}
      >
        Webhooks
      </List.Header>
      <List.Body>
        {canShowOrderWebhooks && (
          <List.Item
            id={webhooks.ORDER}
            key={webhooks.ORDER}
            selected={selectedMenu === webhooks.ORDER}
            onClick={(event: React.MouseEvent<HTMLLIElement>) =>
              onMenuClick(event, webhooks.ORDER, CATEGORY_TYPES.WEBHOOKS)
            }
            italic={undefined}
            className={undefined}
            itemCount={undefined}
          >
            Order
          </List.Item>
        )}
        <List.Item
          id={webhooks.STORY_CONTENT}
          key={webhooks.STORY_CONTENT}
          selected={selectedMenu === webhooks.STORY_CONTENT}
          onClick={(event: React.MouseEvent<HTMLLIElement>) =>
            onMenuClick(event, webhooks.STORY_CONTENT, CATEGORY_TYPES.WEBHOOKS)
          }
          italic={undefined}
          className={undefined}
          itemCount={undefined}
        >
          Story Content
        </List.Item>
        <List.Item
          id={webhooks.NOTE_CONTENT}
          key={webhooks.NOTE_CONTENT}
          selected={selectedMenu === webhooks.NOTE_CONTENT}
          onClick={(event: React.MouseEvent<HTMLLIElement>) =>
            onMenuClick(event, webhooks.NOTE_CONTENT, CATEGORY_TYPES.WEBHOOKS)
          }
          italic={undefined}
          className={undefined}
          itemCount={undefined}
        >
          Note Content
        </List.Item>
      </List.Body>
    </List>
  );
}

export default WebhooksMenu;
