import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(({ palette, typography }) => ({
  wrapper: {
    height: '24px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingInline: '12px 20px',
    backgroundColor: palette.dina.surfaceAppBackgroundNavLevel1,
  },
  title: {
    ...typography.dina.label,
    color: palette.dina.highEmphasis,
  },
  numberOfItems: {
    ...typography.dina.caption,
    color: palette.dina.mediumEmphasis,
  },
}));

const ListHeader = ({ title, numberOfItems }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.numberOfItems}>{numberOfItems}</Typography>
    </div>
  );
};

export default ListHeader;
