import { useState } from 'react';
import PropTypes from 'prop-types';
import Text from 'components/text/Text';

import useImageUpload from 'hooks/useImageUpload';

import Avatar from 'components/avatar/Avatar';
import InputField from 'components/inputField';

import { Wrapper, AvatarWrapper, TypographyWrapper, Title } from './styled';
import { Button } from 'components/buttons';

const AvatarRowView = ({
  id,
  title: initialTitle,
  subtitle,
  additionalInfo,
  avatarUrl,
  variant,
  onImageUpload,
  disableUpload,
  updateDepartmentTitle,
}) => {
  const [uploadedAvatar, setUploadedAvatar] = useState(null);
  const [showInputField, setShowInputField] = useState(false);
  const [title, setTitle] = useState(initialTitle);

  const onImageLoad = (imageFile) => {
    const imageSrc = URL.createObjectURL(imageFile);

    setUploadedAvatar(imageSrc);
    onImageUpload(imageFile, imageSrc);
  };

  const captureImage = useImageUpload({ onImageLoad });
  const avatarImage = uploadedAvatar || avatarUrl;

  const onTitleClick = () => {
    setShowInputField(true);
  };

  const onTitleChange = (value) => {
    setTitle(value);
  };

  const handleEnterKey = (e) => {
    if (e.keyCode === 13) {
      updateDepartmentTitle(id, title);
      setShowInputField(false);
    }
    if (e.keyCode === 27) {
      setTitle(initialTitle);
      setShowInputField(false);
    }
  };

  return (
    <Wrapper>
      <AvatarWrapper>
        <Avatar size={64} variant={variant} src={avatarImage} title={title} />
        {!disableUpload && (
          <Button variant="outlined" height={24} onClick={captureImage}>
            Update
          </Button>
        )}
      </AvatarWrapper>

      <TypographyWrapper>
        {showInputField && variant === 'Department' ? (
          <InputField value={title} autoFocus onChange={onTitleChange} onKeyDown={handleEnterKey} />
        ) : (
          <Title variant="avatarHeaderTitle" onClick={onTitleClick}>
            {title}
          </Title>
        )}
        <Text variant="avatarHeaderSubtitle">{subtitle}</Text>
        <Text variant="captionMedium">{additionalInfo}</Text>
      </TypographyWrapper>
    </Wrapper>
  );
};

AvatarRowView.propTypes = {
  /** Defines the primary header text */
  title: PropTypes.string,
  /** Defines the secondary header text */
  subtitle: PropTypes.string,
  /** If true, disables the action menu for image upload */
  disableUpload: PropTypes.bool,
  /** Defines additional header components to render */
  additionalInfo: PropTypes.node,
  id: PropTypes.string,
  avatarUrl: PropTypes.string,
  variant: PropTypes.string,
  onImageUpload: PropTypes.func,
  updateDepartmentTitle: PropTypes.func,
};

AvatarRowView.defaultProps = {
  id: 'default',
  avatarUrl: '',
  variant: '',
  title: 'The Title',
  subtitle: 'Subtitle',
  disableUpload: false,
  additionalInfo: null,
  onImageUpload: () => {},
  updateDepartmentTitle: () => {},
};

export default AvatarRowView;
