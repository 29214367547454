import { useRef } from 'react';
import PropTypes from 'prop-types';

import useLoadInstances from 'screens/rundown/api/useLoadInstances';
import { Box } from 'layouts/box/Box';
import useGetRundown from 'screens/rundown/api/useGetRundown';

import DetailsView from './details-view';

const DetailsContainer = ({ previewRundown, setPreviewRundown }) => {
  const { id, title } = previewRundown;
  const ref = useRef(null);

  const { data, error, loading } = useGetRundown({ mId: id });
  const { loadInstances } = useLoadInstances({ rundownId: id });

  const { mRefId, mOrder, mPreorder } = data?.getRundown ?? {};

  const handleLoadingInstances = async (order) => {
    await loadInstances(order);
  };

  if (mRefId !== ref.current) {
    ref.current = mRefId;
    handleLoadingInstances([...(mOrder ?? []), ...(mPreorder ?? [])]);
  }

  if (error) return <div>{error.message}</div>;

  return (
    <Box height="100%">
      <DetailsView
        setPreviewRundown={setPreviewRundown}
        loading={loading}
        title={title}
        data={data}
        publishingtime={data?.getRundown?.mPublishingAt}
      />
    </Box>
  );
};

DetailsContainer.propTypes = {
  previewRundown: PropTypes.objectOf(PropTypes.string),
  setPreviewRundown: PropTypes.func,
};

DetailsContainer.defaultProps = {
  previewRundown: null,
  setPreviewRundown: () => {},
};

export default DetailsContainer;
