import { useContext, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import groupBy from 'lodash/groupBy';
import { useSelectedConversationId } from 'store';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import UserContext from 'contexts/UserContext';
import { NotificationContext } from 'contexts/NotificationContext';
import GET_CONVERSATIONS_OF_USER from 'graphql/queries/getConversationsOfUser';
import LEAVE_CONVERSATION from 'graphql/mutations/leaveConversation';
import memberTypes from 'graphql/memberTypes';
import conversationTypes from 'utils/constants/conversationTypes';
import notificationImage from 'assets/icons/systemicons/notifications_on.svg';
import useLogger from 'utils/useLogger';
import useConversationContext from 'screens/main/components/header/navbar/messageHub/hooks/useConversationContext';
import LeftColumn from './leftColumn-view';
import assembleTeamData from '../newMessage/utils/assembleTeamData';
import { WarningDialog } from 'components/dialogs/CommonDialogs';
import assembleDepartmentData from '../newMessage/utils/assembleDepartmentData';

const LeftColumnContainer = ({ onAddNewConversation, ...rest }) => {
  const logger = useLogger('Conversation list: leftColumn');
  const user = useContext(UserContext);
  const { newConversation, setNewConversation } = useContext(NotificationContext);
  const { mId } = user;

  const [selectedConversationId, setSelectedConversationId] = useSelectedConversationId();

  const { onConversationSelect, currentConversation } = useConversationContext();

  const [dialog, setDialog] = useState(null);
  const [dialogId, setDialogId] = useState(null);

  const closeDialog = () => setDialog(null);

  const [leaveConversation] = useMutation(LEAVE_CONVERSATION);

  const { data, loading, error, refetch } = useQuery(GET_CONVERSATIONS_OF_USER, {
    variables: {
      input: {
        mId,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (newConversation) {
      refetch();
      setNewConversation(false);
    }
  }, [newConversation]);

  const handleLeaveConversation = useCallback(
    (id) => {
      if (currentConversation.mId === id) {
        setSelectedConversationId(null);
        onConversationSelect({});
      }

      leaveConversation({
        variables: {
          input: {
            mId: id,
            mRefId: mId,
          },
        },
        update: (proxy) => {
          const conversations = proxy.readQuery({
            query: GET_CONVERSATIONS_OF_USER,
            variables: {
              input: {
                mId,
              },
            },
          });
          const newConversations = conversations.getConversationsOfUser.filter(
            ({ mId: _mId }) => _mId !== id,
          );

          proxy.writeQuery({
            query: GET_CONVERSATIONS_OF_USER,
            variables: {
              input: {
                mId,
              },
            },
            data: {
              getConversationsOfUser: newConversations,
            },
          });
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentConversation.mId, selectedConversationId],
  );

  const confirmDialog = () => {
    closeDialog();
    handleLeaveConversation(dialogId);
  };

  const onMenuSelect = (action, id) => {
    if (action === 'leave-conversation') {
      setDialog(action);
      setDialogId(id);
    }
  };

  if (loading) return <LoadingIndicator />;
  if (error) {
    logger.error(error);
    return <div>{error.message}</div>;
  }

  const getConversationsOfUser = data?.getConversationsOfUser || [];

  const teamData = getConversationsOfUser
    .filter((d) => d.mType === memberTypes.TEAM)
    .filter(Boolean);
  const departmentData = getConversationsOfUser
    .filter((d) => d.mType === memberTypes.DEPARTMENT)
    .filter(Boolean);

  const groupedData = groupBy(data.getConversationsOfUser, 'convoType');
  const people = groupedData[conversationTypes.DIRECT];

  const groups = groupedData[conversationTypes.GROUP];
  const teams = assembleTeamData(teamData, true);
  const departments = assembleDepartmentData(departmentData, true);

  return (
    <>
      <LeftColumn
        {...rest}
        people={people}
        groups={groups}
        teams={teams}
        departments={departments}
        onAddButtonClicked={onAddNewConversation}
        notificationsConvoList={[
          {
            mId: `${mId}_notifications`,
            mTitle: 'Dina Notifications',
            mAvatarUrl: notificationImage,
            convoType: conversationTypes.NOTIFICATION,
          },
        ]}
        onMenuSelect={onMenuSelect}
      />
      <WarningDialog
        open={dialog === 'leave-conversation'}
        onClose={closeDialog}
        onClick={confirmDialog}
        title="Leave the Conversation?"
        message="When you leave a Conversation, you will no longer receive any notifications from it. Messages you have posted in this Conversation will remain visible for remaining participants."
        confirmLabel="Leave"
      />
    </>
  );
};

LeftColumnContainer.propTypes = {
  /** callback to open new conversation list */
  onAddNewConversation: PropTypes.func,
};

LeftColumnContainer.defaultProps = {
  onAddNewConversation: () => {},
};

export default LeftColumnContainer;
