import { ColorVariants } from './colors';
import {
  ConfigType,
  MemberType,
  MemberTypeEnum,
  // eslint-disable-next-line sort-imports
  MProperties,
  ProviderInput,
  Recurrence,
} from './graphqlTypes';
import { WithOptional, WithRequired } from './utils';

export const DEFAULT_STORY_MDF_ID = 'story-mdf';
export const DEFAULT_RUNDOWN_MDF_ID = 'rundown-mdf';
export const DEFAULT_INTERNAL_CONTACT_MDF_ID = 'user-mdf';
export const DEFAULT_EXTERNAL_CONTACT_MDF_ID = 'contact-mdf';

export const MdfDefaultIds = [
  DEFAULT_STORY_MDF_ID,
  DEFAULT_RUNDOWN_MDF_ID,
  DEFAULT_INTERNAL_CONTACT_MDF_ID,
  DEFAULT_EXTERNAL_CONTACT_MDF_ID,
];

export const MdfMap = {
  [MemberTypeEnum.Story]: DEFAULT_STORY_MDF_ID,
  [MemberTypeEnum.Contact]: DEFAULT_EXTERNAL_CONTACT_MDF_ID,
  [MemberTypeEnum.User]: DEFAULT_INTERNAL_CONTACT_MDF_ID,
  [MemberTypeEnum.Rundown]: DEFAULT_RUNDOWN_MDF_ID,
};

export type AssignedMember = Pick<
  WithRequired<MemberType, 'mId' | 'mRefId' | 'mType' | 'mTitle' | 'mProperties'>,
  | 'mId'
  | 'mRefId'
  | 'mType'
  | 'mTitle'
  | 'mAvatarUrl'
  | 'mDescription'
  | 'mProperties'
  | 'mAvatarKey'
> & {
  mProperties: UserMProperties;
  avatarUrl?: string;
  isCreator?: boolean;
};

export interface MId {
  mId: string;
}

export interface MIdRefId {
  mId: string;
  mRefId: string;
}

type FeaturePermission = {
  name: string;
  groups: string[];
};
export interface MemberPermissions {
  read: [FeaturePermission];
  write: [FeaturePermission];
}

export const isString = (payload: unknown): payload is string => {
  return typeof payload === 'string';
};

export const isObject = (obj: unknown): obj is object => {
  return typeof obj === 'object';
};

export const hasMID = (obj: unknown): obj is MId => {
  return isObject(obj) && typeof (obj as Partial<MId>).mId == 'string';
};

export const isAssignedMember = (obj: unknown): obj is AssignedMember => {
  if (!isObject(obj)) return false;
  const probablyMember = obj as Partial<AssignedMember>;
  const { mType, mTitle, mId } = probablyMember;
  return (
    mType === ('user' as MemberTypeEnum) && typeof mTitle === 'string' && typeof mId === 'string'
  );
};
export type Story = Pick<
  WithRequired<
    MemberType,
    | 'mId'
    | 'mRefId'
    | 'mType'
    | 'mTitle'
    | 'mContentKey'
    | 'mAssignedMembers'
    | 'mSyncProviders'
    | 'mSyncActive'
    | 'mAvatarKey'
    | 'mThumbnailKey'
    | 'mUpdatedAt'
    | 'mCreatedAt'
    | 'metadata'
  >,
  | 'mId'
  | 'mRefId'
  | 'mType'
  | 'mTitle'
  | 'mDescription'
  | 'mContentKey'
  | 'mContentUrl'
  | 'mSyncProviders'
  | 'mSyncActive'
  | 'mAvatarKey'
  | 'mThumbnailKey'
  | 'mUpdatedAt'
  | 'mCreatedAt'
  | 'mThumbnailUrl'
  | 'mAvatarUrl'
  | 'mPublishingAt'
  | 'mPublishingEnd'
  | 'mMetaData'
  | 'mState'
  | 'metadata'
  | 'ttl'
  | 'locked'
> & {
  __typename?: 'RestrictedErrorType' | 'MemberType';
  mdfId?: string;
  mAssignedMembers: AssignedMember[];
};

export type Instance = Pick<
  WithRequired<
    MemberType,
    | 'mId'
    | 'mRefId'
    | 'mType'
    | 'mTitle'
    | 'mMetaData'
    | 'mProperties'
    | 'locked'
    | 'mCreatedById'
    | 'mCreatedAt'
    | 'mUpdatedAt'
    | 'mAssignedMembers'
    | 'mState'
    | 'mStoryId'
    | 'mUpdatedById'
  >,
  | '__typename'
  | 'mId'
  | 'mRefId'
  | 'mType'
  | 'mTitle'
  | 'mMetaData'
  | 'mProperties'
  | 'locked'
  | 'mCreatedById'
  | 'mCreatedAt'
  | 'mUpdatedAt'
  | 'mAssignedMembers'
  | 'items'
  | 'mState'
  | 'mStoryId'
  | 'mUpdatedById'
  | 'mContentKey'
  | 'mContentUrl'
  | 'mDefaultContentKey'
  | 'mPublishingAt'
  | 'mPublishingEnd'
  | 'mThumbnailUrl'
  | 'mThumbnailKey'
  | 'mDescription'
  | 'mRelatedMembers'
  | 'isRestricted'
  | 'mAvatarKey'
  | 'mRundownTemplateId'
  | 'mTemplateId'
  | 'isTemplateInstance'
> & {
  mProperties: {
    platform: string;
    account?: PlatformAccountType;
    id?: string;
    platformKind?: string;
    platformIcon?: string;
    provider?: {
      embeddedEndpoint: string;
      message: string;
      previewEndpoint: string;
      requestId: string;
      state: string;
      updateEndpoint: string;
    };
    platformStructure?: {
      id: string;
      name: string;
      variant: string;
      sections: {
        blocks: string[];
        id: string;
        name: string;
      }[];
      config: {
        block: string;
        mMetaData: {
          key: string;
          value: string;
        }[];
      };
      allowVideoInPhotogallery: boolean;
    };
  };
};

export type Note = Pick<
  WithRequired<
    MemberType,
    | 'mId'
    | 'mRefId'
    | 'mType'
    | 'mTitle'
    | 'mContentKey'
    | 'mDefaultContentKey'
    | 'mCreatedAt'
    | 'mUpdatedAt'
  >,
  | 'mId'
  | 'mRefId'
  | 'mType'
  | 'mTitle'
  | 'mContentKey'
  | 'mDefaultContentKey'
  | 'mCreatedAt'
  | 'mUpdatedAt'
  | 'mProperties'
  | 'mDescription'
  | 'locked'
>;

export type Asset = Pick<
  WithRequired<
    MemberType,
    'mId' | 'mRefId' | 'itemType' | 'mAssetId' | 'mContentKey' | 'mMetaData' | 'mType' | 'mediaType'
  >,
  | 'mId'
  | 'mRefId'
  | 'itemType'
  | 'mAssetId'
  | 'mContentKey'
  | 'mContentUrl'
  | 'mMetaData'
  | 'mThumbnailUrl'
  | 'mType'
  | 'mediaType'
  | 'mUpdatedAt'
  | 'mTitle'
>;

export type PanelType = Pick<MemberType, 'mTitle' | 'mId' | 'mRefId' | 'configs'> & {
  mTitle: string;
  mId: string;
  mRefId: string;
  configs: ConfigType[];
};
export type OtherPlatform = Pick<
  WithRequired<MemberType, 'mRefId' | 'mTitle' | 'mProperties'>,
  'mRefId' | 'mTitle' | 'mProperties'
> & {
  mProperties: MProperties & {
    platform: string;
    accounts?: PlatformAccountType[];
    platformKind?: string;
    provider?: ProviderInput;
    platformStructure?: {
      id: string;
      name: string;
      variant: string;
      sections: {
        blocks: string[];
        id: string;
        name: string;
      }[];
      config: {
        block: string;
        mMetaData: {
          key: string;
          value: string;
        }[];
      };
      allowVideoInPhotogallery: boolean;
    };
  };
};

export type LinearPlatform = WithOptional<OtherPlatform, 'mRefId'> & { id: string };

export type Platform = OtherPlatform | LinearPlatform;

export interface PlatformAccountType {
  accountUrl: string;
  accountLogo?: string;
  accountTitle: string;
  accountId: string | null;
  recurrence?: Recurrence;
  isUnassigned?: boolean;
  rundownTemplateId?: string;
  orderType?: string;
}

export type UserMProperties = {
  firstName: string;
  surname: string;
  email: string;
  phone?: string;
  dateOfBirth?: string;
  jobTitle?: string;
  readSpeed?: number;
  notListed?: boolean;
};

export type UserBookmark = {
  bookmarkedId: string;
  bookmarkedType: string;
};

export type User = Pick<
  WithRequired<MemberType, 'mId' | 'mRefId' | 'mType' | 'mTitle'>,
  | 'mId'
  | 'mRefId'
  | 'mType'
  | 'mTitle'
  | 'mAvatarKey'
  | 'mAvatarUrl'
  | 'mMetaData'
  | 'mBookmarks'
  | 'mDescription'
  | 'mProperties'
> & {
  mProperties: UserMProperties;
  groups?: string[];
  avatarUrl?: string;
  attributes?: User;
};

export type KanbanBoardStateType = {
  id: string;
  name: string;
  description: string;
  backgroundColor: keyof ColorVariants;
  icon: string;
};

export type DailyNote = Pick<
  WithRequired<MemberType, 'mId' | 'mRefId' | 'mType' | 'mContentKey'>,
  'mId' | 'mRefId' | 'mType' | 'mContentKey' | 'locked' | 'mUpdatedAt' | 'mCreatedAt'
>;
export interface Version {
  id?: string;
  title?: string;
  type?: string;
  eventId: string;
  timestamp?: string;
  updatedBy?: string;
  updatedById?: string;
  contentKey?: string;
  actionId?: 'version' | 'stateChanged' | 'created' | 'assigned';
  labels?: (string | undefined)[];
  oldState?: string;
  newState?: string;
}
