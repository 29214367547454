import React, { useContext, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Divider from 'components/divider';
import MessageInput from 'components/messageInput';
import { useQuery } from '@apollo/client';
import LoadingIndicator from 'components/loadingIndicator';
import UserContext from 'contexts/UserContext';
import { useCurrentTabValue, useUsers } from 'store';
import useTabs from 'hooks/useTabs';
import useCreateMessage from 'hooks/useCreateMessage';
import useUpdateConvoReadAt from 'hooks/useUpdateConvoReadAt';
import GET_MESSAGES_OF_CONVERSATION from 'graphql/queries/getMessagesOfConversation';
import conversationTypes from 'utils/constants/conversationTypes';
import useLogger from 'utils/useLogger';
import Messages from '../../../../header/navbar/messageHub/components/messageContents/messages/messages-view';
import {
  RootWrapper,
  MessageContainer,
  MessageInputWrapper,
} from '../../../../header/navbar/messageHub/components/messageContents/messageContents-styled';

const supportedConversationTypes = [
  conversationTypes.STORY,
  conversationTypes.PITCH,
  conversationTypes.RUNDOWN,
  conversationTypes.SPACE,
  conversationTypes.TEAM,
  conversationTypes.DEPARTMENT,
];

const MessagesContainer = ({ mId, convoType }) => {
  const logger = useLogger('Story/Rundown Chat');
  const user = useContext(UserContext);
  const users = useUsers();
  const lastMessageRef = useRef(null);
  const [createMessage] = useCreateMessage();
  const [updateConvoReadAt] = useUpdateConvoReadAt();
  const currentTab = useCurrentTabValue();
  const { resetTabNotification } = useTabs();

  const handleDeleteMessage = async (mRefId) => {
    try {
      await createMessage(mId, '', convoType, mRefId, 'DELETE');
    } catch (e) {
      // console.log(e);
    }
  };

  const handleCreateMessage = async (newMessage) => {
    try {
      await createMessage(mId, JSON.stringify(newMessage), convoType);
    } catch (e) {
      // console.log(e);
    }
  };

  const handleUpdateMessage = async (mRefId, mContent) => {
    try {
      createMessage(mId, JSON.stringify(mContent), convoType, mRefId, 'UPDATE');
    } catch (e) {
      // console.log(e);
    }
  };

  const handleResetNotification = () => {
    if (currentTab) resetTabNotification(currentTab);
  };

  useEffect(() => {
    lastMessageRef.current = null;
    updateConvoReadAt(mId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mId]);

  const variables = {
    input: { mId },
    limit: 25,
  };

  const { data, loading, error, fetchMore } = useQuery(GET_MESSAGES_OF_CONVERSATION, {
    variables,
    skip: !mId,
    fetchPolicy: 'cache-and-network',
  });

  if (!mId || !supportedConversationTypes.includes(convoType)) return <div />;
  if (loading) return <LoadingIndicator />;
  if (error) {
    logger.error(error);
    return <div />;
  }

  const messages = data?.getMessagesOfConversation?.items || [];
  const hasMore = Boolean(data?.getMessagesOfConversation?.nextToken);

  const sortedMessages = [...messages].sort((a, b) => (a.mCreatedAt > b.mCreatedAt ? -1 : 1));

  const handleLoadMore = async () => {
    if (hasMore) {
      const [lastMessage] = sortedMessages.slice(-1);
      lastMessageRef.current = lastMessage.mRefId;
      await fetchMore({
        variables: {
          ...variables,
          nextToken: data.getMessagesOfConversation.nextToken,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => ({
          getMessagesOfConversation: {
            items: [
              ...previousResult.getMessagesOfConversation.items,
              ...fetchMoreResult.getMessagesOfConversation.items,
            ],
            nextToken: fetchMoreResult.getMessagesOfConversation.nextToken,
            __typename: 'PaginatedMessageType',
          },
        }),
      });
    }
  };

  const suggestedUsers = users.filter((usr) => usr.mId !== user.mId);

  return (
    <RootWrapper onClick={handleResetNotification}>
      <MessageContainer>
        <Messages
          messages={sortedMessages}
          userId={user.mId}
          hasMore={hasMore}
          onLoadMore={handleLoadMore}
          lastMessageRef={lastMessageRef}
          onDeleteMessage={handleDeleteMessage}
          onUpdateMessage={handleUpdateMessage}
          suggestedUsers={suggestedUsers}
        />
      </MessageContainer>
      <Divider />
      <MessageInputWrapper>
        <MessageInput onSend={handleCreateMessage} users={suggestedUsers} />
      </MessageInputWrapper>
    </RootWrapper>
  );
};

MessagesContainer.propTypes = {
  /** mId of the story/pitch/rundown */
  mId: PropTypes.string,
  /** supported conversation types (i.e. 'story', 'pitch', 'rundown', 'space') */
  convoType: PropTypes.string,
};

MessagesContainer.defaultProps = {
  mId: '',
  convoType: '',
};

export default MessagesContainer;
