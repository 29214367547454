import styled from '@emotion/styled';

export const Wrapper = styled('div')`
  width: 100%;
  height: 100%;
`;

export const HooksWrapper = styled('div')``;

export const PayloadWrapper = styled('div')`
  height: 100%;
  padding: 8px;
`;

export const Label = styled('p')`
  ${({ theme }) => theme.typography.dina.overline};
  margin: 0;
`;

export const Viewer = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
`;

export const Formatted = styled('pre')`
  width: 100%;
  overflow: auto;
  background-color: ${({ theme }) => theme.palette.dina.outlineButtonOnHover};
  border-radius: 4px;
  margin: 8px 0px;
`;

export const FallbackWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FallbackText = styled('p')`
  ${({ theme }) => theme.typography.dina.h7};
`;
