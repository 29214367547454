import { useState, useEffect, useCallback, useMemo, useRef, useContext } from 'react';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import differenceBy from 'lodash/differenceBy';
import isNull from 'lodash/isNull';

import {
  isLinearInstance,
  isCmsInstance,
} from 'screens/story/components/instance/utils/checkInstancePlatform';
import {
  durationTypes,
  getDuration,
  getTime,
  getSeconds,
  getDurationKey,
  filterEditorMeta,
  isBeforeToday,
} from 'screens/rundown/components/editor/utils';
import respectHostReadSpeed from 'screens/rundown/utils/respectHostReadSpeed';
import UPDATE_INSTANCE from 'graphql/mutations/updateStoryInstance';
import GET_SUMMARY from 'graphql/queries/getSummary';
import {
  isApproved,
  inReviewState,
  restrictedPlatformProps,
} from 'utils/instance/restrictedPlatformPropsHelper';
import mDefaultProperties from 'utils/constants/mDefaultProperties';
import { uploadToS3 } from 'utils/s3Utils';
import { getUserLockToken, getUserIdFromLockedId } from 'utils/lock/lockToken';
import UserContext from 'contexts/UserContext';
import ConfigContext from 'contexts/configContext';
import { merge, filter, IgnoredTemplateKeys } from 'utils/metadata';
import { isOlderSlateValue, migrateValue, serializeToText, actionTypes } from 'components/editor';
import { elementTypes, initialValues } from 'components/editor/constants';
import variants from 'utils/instance/variants';
import { getFileAssetData, getAssetData } from 'utils/assetData';
import getVariant from 'components/instanceCard/utils/getVariant';
import getHostReadSpeed from 'components/instanceCard/utils/getHostReadSpeed';
import getEmptyMetadataForForm from 'utils/getEmptyMetadata';
import countTwitterThread from 'utils/instance/countTwitterThread';
import memberTypes from 'graphql/memberTypes';
import GET_STORY from 'graphql/queries/getStory';
import GET_RUNDOWN from 'graphql/queries/getRundown';
import { getMemberQuery } from 'graphql/queryVariables';
import { UNTITLED_STORY } from 'utils/constants';
import getIdentifier from 'utils/instance/getAccountIdentifier';
import useLogger from 'utils/useLogger';
import useAssignInstance from 'hooks/useAssignInstance';
import useCheckUserRight from 'hooks/useCheckUserRight';
import useLockInstance from 'hooks/useLockInstanceForRundown';
import useUpdateInstance from 'hooks/useUpdateInstance';
import useGetAssignedMembers from 'hooks/useGetAssignedMembers';
import useSettingsValue from 'hooks/useSettingsValue';
import useDidMount from 'hooks/useDidMount';
import useCheckPublishingPermission from 'hooks/useCheckPublishingPermission';
import usePostEvent from 'hooks/usePostEvent';
import useGetUser from 'hooks/useGetUser';
import useContentResolver, { getContent } from './useContentResolver';
import useUpdateDurationMeta from 'hooks/useUpdateDurationMeta';
import useUpdateSettings from 'hooks/useUpdateSettings';
import useDebouncedCallback from 'hooks/useDebouncedCallback';
import useUpdateRelatedMembers from 'hooks/useUpdateRelatedMembers';
import useScheduleLinearInstance from 'hooks/useScheduleLinearInstance';
import useCreateAsset from 'hooks/useCreateAsset';
import useUploadAsset from 'hooks/useUploadAsset';
import useUpdateAsset from 'hooks/useUpdateAsset';
import { useIsProdValid } from 'hooks/useImageUrl';
import useCreatePlaceholder from 'hooks/useCreatePlaceholder';
import useRemovePlaceholder from 'hooks/useRemovePlaceholder';
import useUpdateTwitterThreadCount from 'hooks/useUpdateTwitterThreadCount';
import useGetPlatforms from 'hooks/useGetPlatforms';
import useDuplicateInstance from 'hooks/useDuplicateInstance';
import useGetContentTemplateFolders from 'hooks/useGetContentTemplateFolders';
import useSaveTemplate from './useSaveTemplate';
import useDeleteTemplate from './useDeleteTemplate';
import useArchiveMember from './useArchiveMember';
import useCreateFolder from './useCreateFolder';
import useDeleteFolder from './useDeleteFolder';
import useDinaNavigate from './useDinaNavigate';
import useGetInstanceThumbnail from './useGetInstanceThumbnail';

const isLinearPlatform = (platformType) => platformType === 'linear';

const getPermissionForSpecificPlatformAccount = (checkUserRight, platformType, accountTitle) => {
  if (isLinearPlatform(platformType)) return true;
  if (!accountTitle) return true;
  const accountIdentifier = getIdentifier(platformType, accountTitle);
  return checkUserRight('platform', accountIdentifier);
};

const getPermissions = (checkUserRight, platformType, accountTitle) => {
  const hasPermissionForPlatformAccount = getPermissionForSpecificPlatformAccount(
    checkUserRight,
    platformType,
    accountTitle,
  );
  const canUpdateInstance = checkUserRight('instance', 'update') && hasPermissionForPlatformAccount;
  const canCreateNewTemplate =
    checkUserRight('instance', 'create-template') && hasPermissionForPlatformAccount;
  const canDeleteTemplate =
    checkUserRight('instance', 'delete-template') && hasPermissionForPlatformAccount;
  const canSetDefaultTemplate =
    checkUserRight('instance', 'set-default-template') && hasPermissionForPlatformAccount;
  const canReorderTemplates =
    checkUserRight('instance', 'allow-reordering-templates') && hasPermissionForPlatformAccount;
  const canDeleteTemplateFolder =
    checkUserRight('instance', 'delete-template-folder') && hasPermissionForPlatformAccount;
  const canPinTemplateFolder =
    checkUserRight('instance', 'allow-pinning-template-folder') && hasPermissionForPlatformAccount;
  const canCreateInstance = checkUserRight('instance', 'create') && hasPermissionForPlatformAccount;
  const canScheduleInstance =
    checkUserRight('instance', 'schedule') && hasPermissionForPlatformAccount;
  const canShowCmsIframe =
    checkUserRight('feature', 'cms-iframe-design') && hasPermissionForPlatformAccount;
  const canUploadMediaBySignedURL =
    checkUserRight('feature', 'upload-media') && hasPermissionForPlatformAccount;
  const canEditReadyInstance = checkUserRight('rundown', 'edit-ready-instances');
  const canShowNewDesign = checkUserRight('feature', 'new-some-design');
  const canPreviewInstance = checkUserRight('feature', 'preview-instance');

  return {
    canUpdateInstance,
    canCreateInstance,
    canEditReadyInstance,
    canUploadMediaBySignedURL,
    canCreateNewTemplate,
    canReorderTemplates,
    canSetDefaultTemplate,
    canDeleteTemplate,
    canDeleteTemplateFolder,
    canPinTemplateFolder,
    canShowNewDesign,
    canShowCmsIframe,
    canScheduleInstance,
    canPreviewInstance,
  };
};

const useInstance = (instance = {}) => {
  const {
    mId,
    mPublishingAt,
    mPublishingEnd,
    mContentKey,
    mDefaultContentKey,
    locked: lockedBy,
    mMetaData,
    isTemplateInstance,
    mProperties,
    mAssignedMembers,
    mDescription,
    mRefId,
    mTitle,
    mState,
    mRelatedMembers,
    mStoryId,
    platformKind,
  } = instance;
  const { metadataForms } = useContext(ConfigContext);
  const { account: platformAccount = {}, platform: platformType, provider } = mProperties || {};
  const { accountTitle, accountId } = platformAccount;
  const user = useContext(UserContext);
  const isProdValid = useIsProdValid();
  const logger = useLogger();

  const [thumbnailUrl] = useGetInstanceThumbnail(instance);

  const updateSettings = useUpdateSettings();

  const {
    data: storyData,
    loading: storyLoading,
    refetch: storyRefetch,
  } = useQuery(GET_STORY, {
    variables: getMemberQuery(mStoryId),
    fetchPolicy: 'cache-first',
    skip: mStoryId === accountId, // only make getStory request if it is a story instance
  });

  const storyPublishingAt = storyData?.getStory?.mPublishingAt;
  const storyTitle = storyData?.mTitle === UNTITLED_STORY ? '' : storyData?.mTitle;
  const storyDateRef = useRef(storyPublishingAt || new Date().toISOString());

  const [checkUserRight] = useCheckUserRight();
  const {
    canUpdateInstance,
    canCreateInstance,
    canEditReadyInstance,
    canUploadMediaBySignedURL,
    canCreateNewTemplate,
    canDeleteTemplate,
    canDeleteTemplateFolder,
    canPinTemplateFolder,
    canReorderTemplates,
    canSetDefaultTemplate,
    canShowNewDesign,
    canShowCmsIframe,
    canScheduleInstance,
    canPreviewInstance,
  } = getPermissions(checkUserRight, platformType, accountTitle);

  const { accountId: platformAccountId } = platformAccount;
  const destination = useMemo(
    () => ({
      id: platformAccountId,
      title: accountTitle,
      value: isLinearPlatform(platformType) && platformAccountId ? platformAccountId : accountTitle,
    }),
    [accountTitle, platformAccountId, platformType],
  );
  const blankMetaData = getEmptyMetadataForForm(metadataForms[0]);
  const form = metadataForms[0];
  const defaultMetadata =
    mMetaData ||
    blankMetaData.map(({ key, value }) => ({
      key,
      value,
      __typename: 'mMetaDataField',
    }));

  if (defaultMetadata && form && defaultMetadata.length !== form.fields.length) {
    form.fields ||
      [].forEach((md) => {
        if (!defaultMetadata.find((smd) => smd.key === md.id)) {
          const defaultMeta = { key: md.id, value: md.value, __typename: 'mMetaDataField' };
          defaultMetadata.push(defaultMeta);
        }
      });
  }

  const [currentDestination, setCurrentDestination] = useState(destination);
  const [disableEdit, setDisableEdit] = useState(!canUpdateInstance);
  const [isSavingContent, setIsSavingContent] = useState(false);
  const [locking, setLocking] = useState(false);
  const [readLock, setReadLock] = useState(false);
  const [writeLock, setWriteLock] = useState(false);
  const [lockedByUser, setLockedByUser] = useState('Someone');
  const [lockedByCurrentUser, setLockedByCurrentUser] = useState(false);
  const [skipDownload, setSkipDownload] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [editorData, setEditorData] = useState(null);
  const [showMetadata, setShowMetadata] = useState(false);
  const [templateContentKey, setTemplateContentKey] = useState();
  const [, setTargetRundownTitle] = useState('');
  const [, setPublishingSettingsAnchorEl] = useState(null);
  const [isCancelled, setIsCancelled] = useState(false);

  const instanceRef = useRef(instance);
  const mContentKeyRef = useRef(mContentKey);
  const writeLockRef = useRef(writeLock);
  const initialContentRef = useRef(null);

  const [updateStoryInstance] = useMutation(UPDATE_INSTANCE);

  const {
    data: s3Data,
    loading: contentLoading,
    refetch,
  } = useContentResolver(mContentKey, skipDownload, mDefaultContentKey);

  const {
    loading: templateDataLoading,
    data: templateData,
    refetch: refetchTemplate,
  } = useContentResolver(templateContentKey);

  const editorValueRef = useRef(s3Data);

  const client = useApolloClient();
  const [updateInstance] = useUpdateInstance();
  const [lockInstance] = useLockInstance();
  const [addMembersToInstance] = useAssignInstance();
  const [getSettingsValue] = useSettingsValue();
  const [getAssignedMembers] = useGetAssignedMembers(mAssignedMembers || []);
  const [restrictedPlatform] = useCheckPublishingPermission();
  const [postEvent] = usePostEvent();
  const { getUserTitle } = useGetUser();
  const [handleDurationFieldsChange] = useUpdateDurationMeta(blankMetaData, () => {});
  const [updateRelatedMembersMutation, updatingRelatedMembers] = useUpdateRelatedMembers();
  const [scheduleLinearInstance] = useScheduleLinearInstance(() => {}, setCurrentDestination);
  const [createStoryAsset] = useCreateAsset();
  const [getUploadUrl] = useUploadAsset();
  const [updateStoryAsset] = useUpdateAsset();
  const [createPlaceholder] = useCreatePlaceholder();
  const [removePlaceholder] = useRemovePlaceholder();
  const [saveTemplate] = useSaveTemplate(editorValueRef);
  const [deleteTemplate] = useDeleteTemplate();
  const [archiveMember] = useArchiveMember();
  const [createFolder] = useCreateFolder();
  const [deleteFolder] = useDeleteFolder();
  const [platforms, , platformsLoading] = useGetPlatforms(storyDateRef.current);
  const { navigateTo } = useDinaNavigate();
  const [createDuplicateInstance] = useDuplicateInstance();

  const platform = useMemo(
    () =>
      platforms.find((p) => p.mProperties.platform === mProperties.platform) ||
      mDefaultProperties?.mProperties?.platform,
    [mProperties.platform, platforms],
  );

  const editorValue = useMemo(
    () => (editorData && isOlderSlateValue(editorData) ? migrateValue(editorData) : editorData),
    [editorData],
  );

  const didMount = useDidMount();

  const [assignedUsers, assignedTeams, assignedDepartments] = useMemo(
    () => getAssignedMembers(),
    [getAssignedMembers],
  );

  const assignedMembers = useMemo(
    () => [...assignedUsers, ...assignedTeams, ...assignedDepartments],
    [assignedUsers, assignedTeams, assignedDepartments],
  );

  const { mId: userId } = user;
  const variant = getVariant(platformType);
  const defaultReadSpeed = getSettingsValue('rundown.defaultReadSpeed');
  const hostReadSpeed = getHostReadSpeed(defaultMetadata, defaultReadSpeed);
  const [metadata, setMetadata] = useState(respectHostReadSpeed(defaultMetadata, hostReadSpeed));
  const autoClipDurationSettingsValue =
    getSettingsValue('rundown.enableAutoClipDuration') === 'true';
  const canUpdateScriptDurationSettingsValue =
    getSettingsValue('rundown.instance.updateScriptDurationField') === 'true';
  const clipDuration = getDuration(metadata, durationTypes.CLIP_DURATION);
  const scriptDuration = getDuration(metadata, durationTypes.SPEAK_DURATION);
  const totalDuration = getDuration(metadata, durationTypes.TOTAL_DURATION);
  const twitterThreadCount = countTwitterThread(metadata, editorValueRef.current, blankMetaData);
  const document = useMemo(
    () => (editorValueRef.current ? editorValueRef.current.document : []),
    [],
  );

  const placeholderFormat = {
    defaultFormat: getSettingsValue(`mam.placeholderName.${platformType}`, 'mam.placeholderName'),
    defaultHint: getSettingsValue('mam.placeholderName.defaultHint'),
    maxLength: getSettingsValue('mam.placeholderName.maxLength'),
    maxLengthMessage: getSettingsValue('mam.placeholderName.maxLengthMessage'),
    characters: getSettingsValue('mam.placeholderName.characters'),
    charactersMessage: getSettingsValue('mam.placeholderName.charactersMessage'),
    conjunctiveCharacter: getSettingsValue('mam.placeholderName.conjunctiveCharacter'),
    hasDuplicateMessage: getSettingsValue('mam.placeholderName.hasDuplicateMessage'),
  };

  const updateEditStatus = useCallback(() => {
    if (!isLinearInstance(instance) || canEditReadyInstance) return;
    setDisableEdit(platformAccount?.orderType === 'ready');
  }, [canEditReadyInstance, instance, platformAccount?.orderType]);

  const assignMembers = useCallback(
    async (members) => {
      const existingIds = mAssignedMembers || [];
      const updatedIds = members.map(({ mId: id, mType }) => ({ mId: id, mType }));
      const removedIds = differenceBy(existingIds, updatedIds, 'mId');
      const addedIds = differenceBy(updatedIds, existingIds, 'mId');

      addMembersToInstance(mId, addedIds, removedIds, updatedIds);
    },
    [addMembersToInstance, mAssignedMembers, mId],
  );

  const onCreateDuplicate = useCallback(async (rundownId) => {
    if (!rundownId) return createDuplicateInstance(mId, null, {});
    try {
      const { data: rundownData } = await client.query({
        query: GET_RUNDOWN,
        variables: {
          input: {
            mId: rundownId,
            mRefId: rundownId,
          },
        },
        fetchPolicy: 'network-only',
      });
      createDuplicateInstance(mId, rundownId, rundownData?.getRundown);
    } catch (e) {}
  }, []);

  const assignMemberToInstance = useCallback(
    async (members) => {
      const assignee = mAssignedMembers || [];
      const addedIds = members.map(({ mId: id, mType }) => ({ mId: id, mType }));
      const updatedAssignees = [...assignee, ...addedIds];

      addMembersToInstance(mId, addedIds, [], updatedAssignees);
    },
    [addMembersToInstance, mAssignedMembers, mId],
  );

  const updateMetadataState = useCallback(
    (newMetaFields) => {
      const updatedMetadata = metadata.map((meta) => {
        const nMeta = newMetaFields.find((field) => field.key === meta.key);
        return nMeta || meta;
      });
      setMetadata(updatedMetadata);
    },
    [metadata, setMetadata],
  );

  const handleInstanceUpdate = useCallback(
    async (params) => {
      const { instance: inst, metadata: paramMetadata, audit } = params;
      if (!inst) return;
      cancelDebouncedSave();
      await updateInstance({
        ...params,
        metadata: respectHostReadSpeed(
          paramMetadata,
          hostReadSpeed,
          canUpdateScriptDurationSettingsValue,
        ),
        instanceId: mId,
      });
      const isCancelledEvent = audit?.source?.includes('cancelled');
      if (params?.unlock && !isCancelledEvent && canPreviewInstance && isCmsInstance(inst))
        await postEvent(inst.mId, 'publish', 'preview', inst.mType);
    },
    // eslint-disable-next-line no-use-before-define
    [canPreviewInstance, hostReadSpeed, mId, postEvent, updateInstance],
  );

  const saveInstanceWithContent = useCallback(
    async (params) => {
      const { content, instance: inst } = params;
      const isLinear = isLinearInstance(inst);

      const updatedMetadata = merge(
        isLinear
          ? handleDurationFieldsChange(
              content,
              hostReadSpeed,
              inst.mMetaData,
              canUpdateScriptDurationSettingsValue,
            )
          : inst.mMetaData,
        params.metadata,
      );

      const items = isLinear ? filterEditorMeta(content?.document) : [];
      await handleInstanceUpdate({ ...params, metadata: updatedMetadata, items });
    },
    [
      handleDurationFieldsChange,
      handleInstanceUpdate,
      hostReadSpeed,
      canUpdateScriptDurationSettingsValue,
    ],
  );

  const saveAll = useCallback(
    async (params) => {
      const { instance: inst, version, unlock } = params;
      if (contentLoading || !inst || !(writeLockRef.current || version)) return;
      if (unlock) {
        writeLockRef.current = false;
      }

      setIsSavingContent(true);
      await saveInstanceWithContent(params);
      setIsSavingContent(false);
      setHasChanges(false);
    },
    [contentLoading, saveInstanceWithContent],
  );

  const [debouncedSaveAll, cancelDebouncedSave] = useDebouncedCallback(saveAll, 15000);

  const onMetadataChanged = useCallback(
    async (newMetadata, newInstance) => {
      updateMetadataState(newMetadata);
      const params = {
        metadata: newMetadata,
        instance: newInstance,
        audit: { source: 'useInstance:onMetadataChanged' },
      };
      await handleInstanceUpdate(params);
    },
    [handleInstanceUpdate, updateMetadataState],
  );

  const findKey = (str) => blankMetaData.find((item) => getDurationKey(item) === str).key;

  const handleClipDurationChange = useCallback(
    async (newClipDuration, manual = !autoClipDurationSettingsValue) => {
      // eslint-disable-next-line no-param-reassign
      const newTotalDuration = getTime(getSeconds(scriptDuration) + getSeconds(newClipDuration));
      const updatedDurations = [
        { key: findKey(durationTypes.TOTAL_DURATION), value: newTotalDuration },
        { key: findKey(durationTypes.CLIP_DURATION), value: newClipDuration, manual },
      ];

      await onMetadataChanged(updatedDurations, instance);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [metadata, onMetadataChanged, instance],
  );

  const handleScriptDurationChange = useCallback(
    async (scriptDuration, manual = !canUpdateScriptDurationSettingsValue) => {
      const scriptAutoValue = respectHostReadSpeed(
        metadata,
        hostReadSpeed,
        canUpdateScriptDurationSettingsValue,
      )?.find(({ key }) => key.includes(durationTypes.SPEAK_DURATION))?.autoValue;

      const clipDuration = getDuration(metadata, durationTypes.CLIP_DURATION);
      const newTotalDuration = getTime(getSeconds(scriptDuration) + getSeconds(clipDuration));
      const updatedDurations = [
        { key: findKey(durationTypes.TOTAL_DURATION), value: newTotalDuration },
        {
          key: findKey(durationTypes.SPEAK_DURATION),
          value: scriptDuration,
          autoValue: scriptAutoValue || '00:00',
          manual,
        },
      ];

      await onMetadataChanged(updatedDurations, instance);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [canUpdateScriptDurationSettingsValue, hostReadSpeed, metadata, onMetadataChanged, instance],
  );

  const update = useCallback(
    async (input) => {
      try {
        cancelDebouncedSave();
        setIsSavingContent(true);
        await updateStoryInstance({
          variables: {
            input,
          },
        });
      } catch (err) {
      } finally {
        setIsSavingContent(false);
      }
    },
    // eslint-disable-next-line no-use-before-define
    [debouncedSaveAll, updateStoryInstance],
  );

  const saveInstance = useCallback(
    (publishTime, newExpiryTime) => {
      const updateInputs = { mId };
      if (publishTime !== mPublishingAt) {
        updateInputs.mPublishingAt = publishTime;
      }

      if (newExpiryTime !== mPublishingEnd) {
        updateInputs.mPublishingEnd = newExpiryTime;
      }
      if (restrictedPlatform(platformType) && publishTime && !isApproved(mState)) {
        updateInputs.mState = inReviewState;
        updateInputs.mProperties = restrictedPlatformProps(platformType);
      }
      update(updateInputs);
    },
    [mId, mPublishingAt, mPublishingEnd, platformType, restrictedPlatform, update],
  );

  const changeTitle = useCallback(
    async (title) => {
      const input = {
        mId,
        mTitle: title,
      };
      update(input);
    },
    [mId, update],
  );

  const changeDescription = useCallback(
    async (description) => {
      const input = {
        mId,
        mDescription: description,
      };
      update(input);
    },
    [mId, update],
  );

  const changeStatus = useCallback(
    async (status) => {
      const input = {
        mId,
        mState: status,
      };
      update(input);
    },
    [mId, update],
  );

  const changeDestination = useCallback(
    async (account) => {
      const input = {
        mId,
        mProperties: {
          __typename: 'PlatformType',
          platform: platformType,
          account: {
            accountId: account?.accountId,
            accountUrl: account?.accountUrl,
            accountLogo: account?.accountLogo,
            accountTitle: account?.accountTitle,
          },
        },
      };

      update(input);
    },
    [mId, platformType, update],
  );

  const savePublishSettings = useCallback(
    (publishTime, account, newExpiryTime) => {
      const input = {
        mId,
        mProperties: {
          __typename: 'PlatformType',
          platform: platformType,
          account: {
            accountId: account?.accountId,
            accountUrl: account?.accountUrl,
            accountLogo: account?.accountLogo,
            accountTitle: account?.accountTitle,
          },
        },
      };

      if (publishTime !== mPublishingAt) {
        input.mPublishingAt = publishTime;
      }

      if (newExpiryTime !== mPublishingEnd) {
        input.mPublishingEnd = newExpiryTime;
      }
      update(input);
    },
    [mId, platformType, mPublishingAt, mPublishingEnd, update],
  );

  const onInstanceChanged = useCallback(
    (instanceChangeObject) => {
      const { changeType } = instanceChangeObject;

      switch (changeType) {
        case 'publishingSchedule': {
          const publishTime = instanceChangeObject.schedule
            ? instanceChangeObject.schedule.publishTime
            : null;
          return saveInstance(publishTime, mPublishingEnd);
        }

        case 'expirySchedule': {
          const newExpiryTime = instanceChangeObject.schedule
            ? instanceChangeObject.schedule.expiryTime
            : null;
          return saveInstance(mPublishingAt, newExpiryTime);
        }

        case 'schedule-and-expiry': {
          const publishTime = instanceChangeObject.schedule
            ? instanceChangeObject.schedule.publishTime
            : null;
          const newExpiryTime = instanceChangeObject.schedule
            ? instanceChangeObject.schedule.expiryTime
            : null;
          return saveInstance(publishTime, newExpiryTime);
        }

        case 'destination': {
          const account = platform?.mProperties?.accounts?.find(
            (a) => a.accountTitle === instanceChangeObject.accountUrl,
          );

          return changeDestination(account);
        }

        case 'schedule-and-destination': {
          const publishTime = instanceChangeObject.publishingSettings.publishTime || null;
          const account = platform?.mProperties?.accounts?.find(
            (a) => a.accountTitle === instanceChangeObject.publishingSettings.accountUrl,
          );

          return savePublishSettings(publishTime, account, mPublishingEnd);
        }

        case 'expiry-and-destination': {
          const newExpiryTime = instanceChangeObject.publishingSettings.expiryTime || null;
          const account = platform?.mProperties?.accounts?.find(
            (a) => a.accountTitle === instanceChangeObject.publishingSettings.accountUrl,
          );

          return savePublishSettings(mPublishingAt, account, newExpiryTime);
        }

        case 'schedule-and-destination-and-expiry': {
          const publishTime = instanceChangeObject.publishingSettings.publishTime || null;
          const newExpiryTime = instanceChangeObject.publishingSettings.expiryTime || null;
          const account = platform?.mProperties?.accounts?.find(
            (a) => a.accountTitle === instanceChangeObject.publishingSettings.accountUrl,
          );

          return savePublishSettings(publishTime, account, newExpiryTime);
        }

        case 'content': {
          const key = mContentKeyRef.current;
          setIsSavingContent(true);
          const file = new window.File(
            [JSON.stringify(instanceChangeObject.content)],
            'content.data',
            {
              type: 'text/plain',
            },
          );

          try {
            return uploadToS3(key, file);
          } catch (err) {
            return err;
          } finally {
            setIsSavingContent(false);
          }
        }

        case 'title': {
          return changeTitle(instanceChangeObject.title);
        }

        case 'status': {
          return changeStatus(instanceChangeObject.status);
        }

        case 'description': {
          return changeDescription(instanceChangeObject.description);
        }

        default:
          return null;
      }
    },
    [
      changeDescription,
      changeDestination,
      changeStatus,
      changeTitle,
      mPublishingAt,
      mPublishingEnd,
      platform?.mProperties?.accounts,
      saveInstance,
      savePublishSettings,
    ],
  );

  const onStatusChange = useCallback(
    (value) => {
      onInstanceChanged({ changeType: 'status', status: value });
    },
    [onInstanceChanged],
  );

  const onTitleUpdate = useCallback(
    (value) => {
      onInstanceChanged({ changeType: 'title', title: value });
    },
    [onInstanceChanged],
  );

  const updateLockedByUser = useCallback(
    (lockedId) => {
      const newUserId = getUserIdFromLockedId(lockedId);
      const newLockedByUser = getUserTitle(newUserId);
      setLockedByUser(newLockedByUser);
    },
    [getUserTitle],
  );

  const updateLock = useCallback(
    (lockedId) => {
      if (lockedId) {
        const isLockedByCurrentUser = lockedId === getUserLockToken(userId);
        if (isLockedByCurrentUser) {
          setWriteLock(true);
          setReadLock(false);
          setLockedByCurrentUser(true);
          writeLockRef.current = true;
        } else {
          setWriteLock(false);
          setReadLock(true);
          setLockedByCurrentUser(false);
          updateLockedByUser(lockedId);
          writeLockRef.current = false;
        }
      } else {
        window.requestAnimationFrame(() => {
          setWriteLock(false);
          setReadLock(false);
          setLockedByCurrentUser(false);
          writeLockRef.current = false;
        });
      }
    },
    [updateLockedByUser, userId],
  );

  const resetEditorValue = useCallback(
    (newValue) => {
      if (newValue) {
        const value = isOlderSlateValue(newValue) ? migrateValue(newValue) : newValue;

        setEditorData({ ...value });
        editorValueRef.current = value;
      } else if (isNull(newValue)) {
        setEditorData(null);
        editorValueRef.current = null;
      }
    },
    [editorData],
  );

  const releaseWriteLock = useCallback(async () => {
    if (lockedBy !== getUserLockToken(userId)) return;
    cancelDebouncedSave();
    const params = {
      instance: { ...instance },
      unlock: true,
      audit: {
        source: 'useInstance:releaseWriteLock',
      },
    };

    if (editorValueRef.current) params.content = editorValueRef.current;
    await saveAll(params);
    updateLock();

    if (editorValueRef.current) resetEditorValue(editorValueRef.current);
  }, [lockedBy, userId, debouncedSaveAll, instance, saveAll, updateLock, resetEditorValue]);

  const onForceUnlock = useCallback(() => {
    if (lockedBy === getUserLockToken(userId)) {
      releaseWriteLock();
      return;
    }

    const params = {
      instance: { mId, mRefId },
      unlock: true,
      audit: {
        source: 'useInstance:forceUnlock',
      },
    };

    updateInstance({
      ...params,
      instanceId: mId,
    });
  }, [lockedBy, mId, mRefId, releaseWriteLock, updateInstance, userId]);

  const handleLockInstance = useCallback(async () => {
    if (!writeLock && !readLock) {
      setLocking(true);
      updateLock(lockedBy || getUserLockToken(userId));
      const result = await lockInstance(mId, userId);
      if (result?.data?.lockMember) {
        const { locked, mContentKey: latestContentKey } = result.data.lockMember;

        const content =
          mContentKey !== latestContentKey
            ? await getContent(latestContentKey, isProdValid, logger.log)
            : null;

        if (content) resetEditorValue(content);

        updateLock(locked);
        setLocking(false);
        return locked;
      }
      setLocking(false);
      updateLock();
    }
    return undefined;
  }, [
    writeLock,
    readLock,
    updateLock,
    lockedBy,
    userId,
    lockInstance,
    mId,
    mContentKey,
    resetEditorValue,
  ]);

  const onRestoreVersion = useCallback(
    async (rescont) => {
      const content = isOlderSlateValue(rescont) ? migrateValue(rescont) : rescont;
      resetEditorValue(content);
      const params = {
        content,
        instance,
        unlock: true,
        version: 'restored',
        audit: { source: 'useInstance:onRestoreVersion' },
      };
      await saveAll(params);
      updateLock();
    },
    [resetEditorValue, instance, saveAll, updateLock],
  );

  const onChange = useCallback(
    (value, updatedInstance, createVersion = false) => {
      if (writeLockRef.current) {
        setHasChanges(true);
        editorValueRef.current = value;
        const params = {
          content: value,
          instance: updatedInstance,
          autosave: !createVersion,
          audit: { source: 'useInstance:onChange' },
        };

        if (createVersion) params.version = 'asset';
        createVersion ? saveAll(params) : debouncedSaveAll(params);
      }
    },
    [debouncedSaveAll, saveAll],
  );

  const summarize = useCallback(async () => {
    try {
      const { data: summarizedData } = await client.query({
        query: GET_SUMMARY,
        variables: {
          input: {
            mContent: serializeToText(document),
            mSummarizeLines: 1,
          },
        },
        fetchpolicy: 'network-only',
      });

      if (summarizedData && summarizedData.getSummary) {
        const summarizedValue = {
          ...editorValueRef.current,
          document: [
            {
              type: elementTypes.PARAGRAPH,
              children: [{ text: summarizedData.getSummary.mSummarize }],
            },
          ],
        };

        resetEditorValue(summarizedValue);
      }
    } catch (e) {}
  }, [client, document, resetEditorValue]);

  const onTemplateInsert = useCallback(
    (loading, data) => {
      if (loading || !data) return;

      const content = isOlderSlateValue(data) ? migrateValue(data) : data;

      const inst = { ...instance };
      if (content.metadata) inst.mMetaData = content.metadata;

      const params = {
        content,
        instance: inst,
        version: 'template',
        metadata: filter(content.metadata, { excludeKeys: IgnoredTemplateKeys, ignoreNull: true }),
        audit: { source: 'useInstance:onTemplateInsert' },
      };
      cancelDebouncedSave();
      saveAll(params);
      resetEditorValue(content);
      setTemplateContentKey(null);
    },
    [instance, debouncedSaveAll, saveAll, resetEditorValue],
  );

  const onSelectTemplate = useCallback(
    ({ mContentKey: storageKey }) => {
      if (templateContentKey === storageKey) refetchTemplate();
      else {
        setTemplateContentKey(storageKey);
      }
    },
    [refetchTemplate, templateContentKey],
  );

  const onMoveRundownInstance = useCallback(
    (publishingTime, sourceRundown, targetRundown) => {
      scheduleLinearInstance(instance, sourceRundown, targetRundown, currentDestination);
    },
    [currentDestination, instance, scheduleLinearInstance],
  );

  // NOTE: We do not create new rundown from publish settings view.
  // Consider removing this block and related functions after consulting.
  const handleInstanceRundownUpdate = useCallback(
    ({ selectedDestination, publishingTime }) => {
      setTargetRundownTitle(selectedDestination.title);

      const newCurrentDestination = {
        id: selectedDestination.id,
        value: selectedDestination.value,
        title: selectedDestination.title,
        publishingTime,
        startTime: selectedDestination.startTime,
        timeZone: selectedDestination.timeZone,
      };

      setCurrentDestination(newCurrentDestination);

      const targetRundown = {
        mId: selectedDestination.id,
        mRefId: selectedDestination.id,
        mTitle: selectedDestination.title,
        mPublishingAt: selectedDestination.publishingTime,
      };

      const sourceRundown = {
        mId: platformAccount?.accountId,
        mRefId: platformAccount?.accountId,
      };

      if (mId !== '-' && (!publishingTime || !isBeforeToday(publishingTime))) {
        onMoveRundownInstance(publishingTime, sourceRundown, targetRundown);
      }

      setPublishingSettingsAnchorEl(null);
    },
    [mId, platformAccount.accountId, onMoveRundownInstance],
  );

  const onPublishSettingsChange = useCallback(
    ({ selectedDestination, publishingTime, expiryTime: newExpiryTime }) => {
      const isDestinationChanged = currentDestination.value !== selectedDestination.value;
      const isPublishingTimeChanged = publishingTime !== mPublishingAt;
      const isExpiryTimeChanged = mPublishingEnd !== newExpiryTime;

      setCurrentDestination(selectedDestination);

      if (isDestinationChanged && isPublishingTimeChanged && isExpiryTimeChanged) {
        onInstanceChanged({
          changeType: 'schedule-and-destination-and-expiry',
          publishingSettings: {
            publishTime: publishingTime,
            accountUrl: selectedDestination.value,
            expiryTime: newExpiryTime,
          },
        });
      } else if (isDestinationChanged && isPublishingTimeChanged) {
        onInstanceChanged({
          changeType: 'schedule-and-destination',
          publishingSettings: {
            publishTime: publishingTime,
            accountUrl: selectedDestination.value,
          },
        });
      } else if (isDestinationChanged && isExpiryTimeChanged) {
        onInstanceChanged({
          changeType: 'expiry-and-destination',
          publishingSettings: {
            expiryTime: newExpiryTime,
            accountUrl: selectedDestination.value,
          },
        });
      } else if (isPublishingTimeChanged && isExpiryTimeChanged) {
        onInstanceChanged({
          changeType: 'schedule-and-expiry',
          schedule: {
            publishTime: publishingTime,
            expiryTime: newExpiryTime,
          },
        });
      } else if (isPublishingTimeChanged) {
        onInstanceChanged({
          changeType: 'publishingSchedule',
          schedule: {
            publishTime: publishingTime,
          },
        });
      } else if (isExpiryTimeChanged) {
        onInstanceChanged({
          changeType: 'expirySchedule',
          schedule: {
            expiryTime: newExpiryTime,
          },
        });
      } else if (isDestinationChanged) {
        onInstanceChanged({
          changeType: 'destination',
          accountUrl: selectedDestination.value,
        });
      }
    },
    [currentDestination.value, mPublishingAt, mPublishingEnd, onInstanceChanged],
  );

  const handlePublishSettingChange = useCallback(
    (publishSetting) => {
      if (platformType === variants.LINEAR) handleInstanceRundownUpdate(publishSetting);
      else onPublishSettingsChange(publishSetting);
    },
    [handleInstanceRundownUpdate, platformType, onPublishSettingsChange],
  );

  const createAssetWithoutUrl = useCallback(
    async (file, uploadProgressCallback) => {
      const assetData = getFileAssetData(mStoryId, file);
      const sourceData = {
        mId: assetData.mId,
        mRefId: assetData.mRefId,
      };

      try {
        const result = await createStoryAsset(mStoryId, assetData, null, uploadProgressCallback);
        const { createAssets: assets } = result.data;
        if (assets && assets[0]) {
          sourceData.src = assets[0].mContentKey;
        }
      } catch (e) {}
      return sourceData;
    },
    [createStoryAsset, mStoryId],
  );

  const getAssetUploadUrl = useCallback(
    // eslint-disable-next-line consistent-return
    async (file, data) => {
      const { name, type } = file;
      const { mTitle: payloadAssetTitle, mRefId: assetId } = data || {};

      return getUploadUrl({
        mId: mStoryId,
        mRefId: assetId || undefined,
        mTitle: payloadAssetTitle || name,
        itemType: type === 'application/mxf' ? 'video' : type.split('/')[0],
        filename: name,
        fileType: type,
      });
    },
    [getUploadUrl, mStoryId],
  );

  const onAssetInsert = useCallback(
    async (file, data, bypassUploadApi, uploadProgressCallback) => {
      if (!bypassUploadApi && canUploadMediaBySignedURL) return getAssetUploadUrl(file, data);
      return createAssetWithoutUrl(file, uploadProgressCallback);
    },
    [canUploadMediaBySignedURL, getAssetUploadUrl, createAssetWithoutUrl],
  );

  const createAsset = useCallback(
    async (storyId, assetData) => {
      const asset = getAssetData(storyId, assetData);
      const result = await createStoryAsset(storyId, asset, true);
      return result;
    },
    [createStoryAsset],
  );

  const updateAsset = useCallback(
    async (storyId, assetData) => {
      const result = await updateStoryAsset(storyId, assetData);
      return result;
    },
    [updateStoryAsset],
  );

  const onDeleteTemplate = useCallback(
    async (templateId, templateRefId) => {
      await deleteTemplate(templateId, templateRefId);
    },
    [deleteTemplate],
  );

  const handleCreatePlaceholder = useCallback(
    async (title, itemType) => {
      const { mId: id, mRefId: assetId } = await createPlaceholder(mId, {
        mTitle: title,
        itemType,
        mProperties,
      });

      return { title, mId: id, mRefId: assetId };
    },
    [createPlaceholder, mId, mProperties],
  );

  const [handleTwitterThreadChange] = useUpdateTwitterThreadCount(
    blankMetaData,
    updateMetadataState,
  );

  const handleEditorUpdate = useCallback(
    async ({ type, payload }) => {
      const {
        CHANGE,
        ASSET_INSERT,
        CREATE_ASSET,
        CREATE_PLACEHOLDER,
        REMOVE_PLACEHOLDER,
        ASSET_UPDATE,
        COMMIT_UPDATE,
      } = actionTypes;
      if (type === ASSET_INSERT) {
        const { file, data, bypassUploadApi, uploadProgressCallback } = payload;
        return onAssetInsert(file, data, bypassUploadApi, uploadProgressCallback);
      }

      if (type === CREATE_ASSET) {
        const { asset } = payload;
        return createAsset(mStoryId, asset);
      }

      if (type === ASSET_UPDATE) {
        const { asset } = payload;
        return updateAsset(mStoryId, asset);
      }

      if (type === CREATE_PLACEHOLDER) {
        const { title, itemType } = payload;
        return handleCreatePlaceholder(title, itemType);
      }

      if (type === REMOVE_PLACEHOLDER) {
        const { placeholder } = payload;
        removePlaceholder(placeholder);
      }

      if (type === CHANGE || type === COMMIT_UPDATE) {
        let newMetaData = [];
        if (variant === variants.TWITTER) {
          newMetaData = handleTwitterThreadChange(payload, metadata);
        } else if (variant === variants.LINEAR) {
          newMetaData = instanceRef.current.mMetaData || defaultMetadata;
        }

        editorValueRef.current = payload;
        onChange(
          payload,
          { ...instanceRef.current, mMetaData: newMetaData },
          type === COMMIT_UPDATE,
        );
      }

      return null;
    },
    [
      onAssetInsert,
      createAsset,
      mStoryId,
      updateAsset,
      handleCreatePlaceholder,
      removePlaceholder,
      variant,
      onChange,
      handleTwitterThreadChange,
      metadata,
      defaultMetadata,
    ],
  );

  const onDescriptionChange = useCallback(
    (value) => {
      onInstanceChanged({ changeType: 'description', description: value });
    },
    [onInstanceChanged],
  );

  const onDone = useCallback(() => {
    releaseWriteLock();
  }, [releaseWriteLock]);

  const getPlatformInitialValue = useCallback(() => {
    const platformStructure = platform?.mProperties?.platformStructure;
    const isCmsBlock =
      platformStructure && variant === variants.CMS && platformStructure.variant === 'blocks';
    const isAllowed = canShowNewDesign || (variant === variants.CMS && canShowCmsIframe);

    return initialValues(variant, isAllowed, isCmsBlock);
  }, [canShowCmsIframe, canShowNewDesign, platform?.mProperties?.platformStructure, variant]);

  const handleCancel = useCallback(async () => {
    setIsCancelled(true);

    const initialValue = isNull(initialContentRef.current)
      ? getPlatformInitialValue()
      : initialContentRef.current;

    const params = {
      instance: { ...instance },
      unlock: true,
      audit: {
        source: 'instance-container:cancelled',
      },
      content: initialValue,
    };

    cancelDebouncedSave();
    await saveAll(params);
    resetEditorValue(initialValue);
    updateLock();
    setIsCancelled(false);
  }, [getPlatformInitialValue, instance, saveAll, debouncedSaveAll, resetEditorValue, updateLock]);

  const onSaveTemplate = useCallback(
    async (folderId, templateTitle, overwriteData) => {
      const newMProperties = {
        __typename: 'PlatformType',
        platform: platformType,
      };
      if (platformKind) newMProperties.platformKind = platformKind;
      await saveTemplate(folderId, templateTitle, overwriteData, metadata, newMProperties);
    },
    [metadata, platformType, saveTemplate],
  );

  const onSetDefaultTemplate = useCallback(
    (settingsValues) => {
      updateSettings({ mId: 'settings', mRefId: 'general', mMetaData: settingsValues });
    },
    [updateSettings],
  );

  const onDeleteInstance = useCallback(async () => {
    await archiveMember(
      mId,
      memberTypes.instanceMemberTypes,
      platformType === 'linear' ? currentDestination.id : null,
      mStoryId,
    );
    storyRefetch();
  }, [archiveMember, currentDestination.id, mId, platformType, storyRefetch]);

  const onCreateFolder = useCallback(
    async (title, parentId) => {
      const updatedMProperties = {
        __typename: 'PlatformType',
        platform: platformType,
        pinned: false,
      };
      if (platformKind) updatedMProperties.platformKind = platformKind;
      await createFolder(title, parentId, updatedMProperties);
    },
    [createFolder, platformType, platformKind],
  );

  const onDeleteFolder = useCallback(
    async (folderId, folderRefId) => {
      await deleteFolder(folderId, folderRefId);
    },
    [deleteFolder],
  );

  const folders = useGetContentTemplateFolders(platformType, platformKind);

  const checkVersionRestorability = useCallback(
    async (content) => {
      if (lockedByCurrentUser) return true;
      const lockedId = await handleLockInstance();
      return lockedId === getUserLockToken(userId);
    },
    [handleLockInstance, lockedByCurrentUser, userId],
  );

  const handleOpenRundown = useCallback(() => {
    if (isLinearInstance(instance)) {
      navigateTo(isTemplateInstance ? 'rundowntemplate' : 'rundown', platformAccountId);
    }
  }, [instance, navigateTo, platformAccountId, isTemplateInstance]);

  const placeHolderFormatValues = useMemo(
    () => ({
      ...instance,
      rundown: { mTitle: currentDestination.title },
      story: { mTitle: storyTitle, mPublishingAt: storyPublishingAt },
    }),
    [currentDestination.title, instance, storyPublishingAt, storyTitle],
  );

  useEffect(() => {
    instanceRef.current = instance;
    setCurrentDestination((prev) => {
      if (prev?.id !== destination?.id || prev?.value !== destination?.value) return destination;
      return prev;
    });
    if (canUpdateInstance) updateEditStatus();
  }, [canUpdateInstance, destination, instance, updateEditStatus]);

  useEffect(() => {
    if (didMount)
      setMetadata(
        respectHostReadSpeed(mMetaData, hostReadSpeed, canUpdateScriptDurationSettingsValue),
      );
  }, [didMount, hostReadSpeed, mMetaData]);

  useEffect(() => {
    if (didMount && s3Data) {
      resetEditorValue(s3Data);
      initialContentRef.current = s3Data;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [didMount, s3Data]);

  useEffect(() => {
    if (didMount) {
      updateLock(lockedBy);
      if (!lockedBy) refetch();
    }
  }, [lockedBy, didMount, updateLock, refetch]);

  useEffect(() => {
    const unlockBeforeUnload = async (e) => {
      if (e) {
        e.preventDefault();
        // eslint-disable-next-line dot-notation
        delete e.returnValue;
      }

      if (instanceRef.current?.locked !== getUserLockToken(userId)) {
        return;
      }

      cancelDebouncedSave();

      const params = {
        instance: { ...instanceRef.current },
        unlock: true,
        audit: { source: 'useInstance:unlockBeforeUnload' },
      };
      if (editorValueRef.current) params.content = editorValueRef.current;
      await saveInstanceWithContent(params);
    };

    window.addEventListener('beforeunload', async (e) => {
      await unlockBeforeUnload(e);
    });

    return () => {
      unlockBeforeUnload();
      window.removeEventListener('beforeunload', async (e) => {
        await unlockBeforeUnload(e);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => onTemplateInsert(templateDataLoading, templateData),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [templateDataLoading, templateData],
  );

  return {
    canUpdateInstance,
    canCreateInstance,
    canEditReadyInstance,
    canUploadMediaBySignedURL,
    canCreateNewTemplate,
    canDeleteTemplate,
    canDeleteTemplateFolder,
    canPinTemplateFolder,
    canReorderTemplates,
    canSetDefaultTemplate,
    canShowNewDesign,
    canShowCmsIframe,
    canScheduleInstance,
    lockInstance,
    assignedMembers,
    assignMembers,
    assignees: assignedMembers,
    onAssigneeUpdate: assignMembers,
    assignMemberToInstance,
    disableEdit,
    currentDestination,
    provider,
    autoClipDurationSettingsValue,
    clipDuration,
    scriptDuration,
    totalDuration,
    hostReadSpeed,
    onClipDurationChange: handleClipDurationChange,
    onScriptDurationChange: handleScriptDurationChange,
    canUpdateScriptDurationSettingsValue,
    handleUpdateMetadata: onMetadataChanged,
    isSavingContent,
    onInstanceChanged,
    onStatusChange,
    onTitleUpdate,
    onCreateDuplicate,
    publishingDate: mPublishingAt,
    schedule: mPublishingAt,
    loading: contentLoading || platformsLoading || storyLoading || locking,
    onLockInstance: handleLockInstance,
    onUnlockInstance: releaseWriteLock,
    editorValue,
    hasChanges,
    lockedByUser,
    lockedByCurrentUser,
    readLock,
    writeLock,
    setSkipDownload,
    onChange,
    onSelectTemplate,
    showMetadata,
    setShowMetadata,
    summarize,
    onRestoreVersion,
    updatingRelatedMembers,
    updateRelatedMembersMutation,
    onPublishSettingsChange: handlePublishSettingChange,
    onEditorUpdate: handleEditorUpdate,
    onAssetInsert,
    onDone,
    onCancel: handleCancel,
    isCancelled,
    onDescriptionChange,
    description: mDescription,
    onSaveTemplate,
    placeHolderFormatValues,
    twitterThreadCount,
    onForceUnlock,
    expiryTime: mPublishingEnd,
    onDeleteTemplate,
    onDeleteInstance,
    onCreateFolder,
    onDeleteFolder,
    folders,
    checkVersionRestorability,
    onOpenRundown: handleOpenRundown,
    form,
    storyId: mStoryId,
    mId,
    platform,
    title: mTitle,
    mMetaData: metadata,
    statusId: mState,
    publishingPoint: platformType,
    publishingPointIcon:
      platformType === 'linear' ? 'linear' : platform?.mProperties?.platformIcon || 'general',
    placeholderConfigs: {
      template: placeholderFormat,
      s3Content: editorValueRef.current,
      variables: placeHolderFormatValues,
    },
    relatedMembers: mRelatedMembers,
    instance,
    variant,
    thumbnailUrl,
    onSetDefaultTemplate,
    isContentLoaded: !!editorValueRef.current,
    story: storyData?.getStory,
  };
};

export default useInstance;
