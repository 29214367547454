import { ItemParams } from 'react-contexify';

import { ReactComponent as Preview } from 'assets/icons/systemicons/arrows/disclosurearrow_left.svg';
import { canOpenPreview } from 'features/drawerPreview/utils';
import { useSetPreview } from 'store/preview';

import { ContextItem, MemberMenuProps } from './Base';

type PropsFromTriggerType = {
  propsFromTrigger?: MemberMenuProps;
};

const getMType = ({ propsFromTrigger }: PropsFromTriggerType) => {
  return propsFromTrigger?.member?.mType;
};

export function PreviewMemberItem({ ...contextProps }) {
  const setItemPreview = useSetPreview();

  const canPreview = canOpenPreview(getMType(contextProps));

  const handleClickEvent = (data: ItemParams<MemberMenuProps>) => {
    const { member } = data.props ?? {};
    if (member && canOpenPreview(member.mType)) {
      setItemPreview(member);
    }
  };

  return (
    <ContextItem
      {...contextProps}
      disabled={!canPreview}
      id="preview"
      label="Preview"
      icon={<Preview />}
      onClick={(data: ItemParams<MemberMenuProps>) => handleClickEvent(data)}
    />
  );
}
