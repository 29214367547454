import { CommandGroup } from 'lib/command';

import { Destination, Destinations } from '../command-types';
import { CommandItem } from '../CommandItem';

interface NavigateGroupProps {
  navigateTo: (val: Destination) => void;
}

const NavigationGroup = ({ navigateTo }: NavigateGroupProps) => {
  return (
    <CommandGroup heading="Pages">
      {Destinations.map((d) => (
        <CommandItem key={d} onSelect={() => navigateTo(d)} destination={d} />
      ))}
    </CommandGroup>
  );
};

export default NavigationGroup;
