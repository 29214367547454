/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { debounce, throttle } from 'lodash';

import { useSearch } from 'api/search/useSearch';
import { ReactComponent as SidebarActive } from 'assets/icons/systemicons/side_preview.svg';
import { ClearIcon } from 'components/createNewV3/styled';
import { LoadingButtonIndicator } from 'components/loadingIndicator';
import { StyledTextField } from 'components/mdfEditor/fields/text/styled';
import Text from 'components/text/Text';
import Tooltip from 'components/tooltip';
import useCheckUserRight from 'hooks/useCheckUserRight';
import { HStack, VStack } from 'layouts/box/Box';
import { useSetPreview } from 'store/preview';
import { MemberType, SearchItemTypeEnum } from 'types/graphqlTypes';
import { isoToLocaleShort } from 'utils/datetimeHelpers';

import { StyledPopper } from '../addMember/styled';

import { getIcon, getTitle } from './views/search-results/SearchItem';

interface QuickSearchProps {
  searchString: string;
  setSearchString: (val: string) => void;
  onKeyDown: (ev: React.KeyboardEvent) => void;
  titleErrorTooltip: string | undefined;
}

const StyledVStack = styled(VStack)`
  background: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
`;

const ItemTitle = styled('div')`
  ${({ theme }) => theme.typography.dina.listItemLabel};
  width: 100%;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const StyledHStack = styled(HStack)`
  cursor: pointer;
  height: 32px;
  position: relative;
  em {
    font-style: normal;
    color: ${({ theme }) => theme.palette.dina.statusSearchMatchBG};
  }
  .sidebar {
    display: none;
    position: absolute;
    background: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
    right: 3px;
  }
  &:hover {
    background: ${({ theme }) => theme.palette.dina.hoverOverlay};
    .sidebar {
      display: block;
    }
  }
`;

const ItemDate = styled(Text)`
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 10px;
`;

function SearchHit({ item, doPreview }: Readonly<{ item: MemberType; doPreview: () => void }>) {
  const Icon = getIcon(item);
  return (
    <Tooltip title="Preview" placement="right">
      <StyledHStack width="100%" padding="1px 3px" gap="6px" onMouseDown={() => doPreview()}>
        {Icon && <Icon style={{ width: '20px', height: '20px' }} className="skipOverride" />}
        <VStack width="100%" justifyContent="start" alignItems="start">
          <ItemTitle dangerouslySetInnerHTML={getTitle(item, {})} />
          <ItemDate variant="caption">{`${isoToLocaleShort(item.mCreatedAt, true)}`}</ItemDate>
        </VStack>
        <SidebarActive className="sidebar" />
      </StyledHStack>
    </Tooltip>
  );
}

export default function QuickSearch({
  searchString,
  setSearchString,
  onKeyDown,
  titleErrorTooltip,
}: Readonly<QuickSearchProps>) {
  const [checkUserRight] = useCheckUserRight();
  const canAccessCommand = checkUserRight('feature', 'cmdk');
  const setPreview = useSetPreview();
  const [searchValue, setSearchValue] = useState(searchString);
  const [focused, setFocused] = useState(false);
  const ref = useRef<HTMLInputElement | null>(null);
  const throttled = useRef(
    throttle((newValue: string) => setSearchValue(newValue), 1000, { trailing: true }),
  );
  const debounced = useRef(debounce((newValue: string) => setSearchValue(newValue), 300));
  const { items, loading } = useSearch({
    skip: searchValue.length < 3,
    searchString: searchValue,
    perPagelimit: 10,
    searchableKey: 'mTitle',
    toolbarState: {
      sortBy: 'createdAt',
      order: 'desc',
      mTypes: [SearchItemTypeEnum.pitch, SearchItemTypeEnum.story],
      rangeBy: null,
      statusFilter: [],
      mdfId: null,
      defaultMdfId: null,
      createdByIds: [],
      assignedIds: [],
      isFiltering: false,
    },
  });

  const clearText = () => {
    setSearchString('');
    if (ref.current) ref.current.focus();
  };

  useEffect(() => {
    if (canAccessCommand) {
      debounced.current(searchString);
      throttled.current(searchString);
    }
  }, [searchString, canAccessCommand]);

  return (
    <div>
      <StyledTextField
        ref={ref}
        fullWidth
        autoFocus
        error={Boolean(titleErrorTooltip)}
        variant="filled"
        placeholder="Type title here..."
        value={searchString}
        style={{ marginTop: '2px' }}
        onChange={(ev) => setSearchString(ev.target.value)}
        onFocus={(ev) => {
          ev.currentTarget.select();
          setFocused(true);
        }}
        onBlur={() => setFocused(false)}
        onKeyDown={onKeyDown}
        InputProps={{
          endAdornment: loading ? (
            <LoadingButtonIndicator
              inline
              size={16}
              style={{ position: 'absolute', right: '8px' }}
            />
          ) : (
            <ClearIcon onClick={clearText} />
          ),
          disableUnderline: true,
        }}
      />
      {canAccessCommand && (
        <StyledPopper
          anchorEl={ref.current}
          open={items.length > 0 && focused}
          style={{ width: ref.current?.clientWidth }}
        >
          <StyledVStack gap="4px" alignItems="start" width="100%">
            {items.map((item) => (
              <SearchHit item={item} key={item.mId} doPreview={() => setPreview(item)} />
            ))}
          </StyledVStack>
        </StyledPopper>
      )}
    </div>
  );
}
