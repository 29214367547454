import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import memberTypes from 'graphql/memberTypes';
import DELETE_CONTACT from 'graphql/mutations/deleteContact';
import { getMembersOfTypeQuery } from 'graphql/queryVariables';
import useLogger from 'utils/useLogger';
import { useMembers } from 'store';

const GET_USERS = gql`
  query GetUsers($input: GetMemberOfTypeInput) {
    getMembersOftype(input: $input) {
      mId
      mRefId
    }
  }
`;

const useDeleteContact = () => {
  const logger = useLogger('use delete contact');
  const [deleteContactMutation] = useMutation(DELETE_CONTACT);
  const [allMembers, setAllMembers] = useMembers();
  const deleteContact = async (id) => {
    const deleteMember = {
      mId: id,
    };

    const deleteSingleMember = {
      mId: id,
      mRefId: id,
    };

    try {
      await deleteContactMutation({
        variables: {
          deleteMember,
          deleteSingleMember,
        },
        update: (proxy) => {
          const members = proxy.readQuery({
            query: GET_USERS,
            variables: getMembersOfTypeQuery(memberTypes.CONTACT),
          });

          const updatedMembers = members.getMembersOftype.filter((member) => member.mId !== id);

          setAllMembers({
            ...allMembers,
            contact: allMembers['contact'].filter((member) => member.mId !== id),
          });

          proxy.writeQuery({
            query: GET_USERS,
            variables: getMembersOfTypeQuery(memberTypes.CONTACT),
            data: { getMembersOftype: updatedMembers },
          });
        },
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      logger.log(e);
    }
  };

  return [deleteContact];
};

export default useDeleteContact;
