import { useRightHidden, useRightSelection } from 'store/sidebar';
import openAssetInMimir, { isMimirAssetItem } from 'utils/openAssetInMimir';
import SelectionTypes from 'screens/main/components/rightArea/selectionTypes';

const useOpenAssetInMimir = () => {
  const [, setRightHidden] = useRightHidden();
  const [, setRightSelection] = useRightSelection();
  const openInMimir = (element) => {
    if (!isMimirAssetItem(element)) return;
    setRightHidden(false);
    setRightSelection(SelectionTypes.STORYBOX);
    openAssetInMimir(element);
  };
  return openInMimir;
};

export default useOpenAssetInMimir;
