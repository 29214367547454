import { v4 as uuid } from 'uuid';

import { getDefaultLayoutSettings } from 'components/editMdfDialog/utils';
import { DateRange } from 'components/mdfEditor/fields/date/DatePicker';
import {
  Alternative,
  ConfigType,
  CrudActionEnum,
  FieldTypeEnum,
  Mdf,
  MdfField,
  ViewTypes,
} from 'types/graphqlTypes';
export interface Form {
  mProperties: FormProperties;
  mId: string; // SpaceId
  mRefId: string; // FormId
  mRefTitle: string; // Space title
  mTitle: string; // Form title
  mType: 'order_form';
}

interface FormProperties {
  fields?: FormField[];
  types: FilterType[] | null;
}

type FilterType = ResourceType | 'all';

type FieldType = 'boolean' | 'text' | 'select';

export interface Option {
  id: string;
  title: string;
  value: string;
}
export interface FormField {
  id: string;
  name: string;
  label: string;
  type: FieldType;
  options?: Option[];
}

export interface NewFieldValue {
  fieldId: string;
  value: FieldValue;
}

export type ResourceType = 'story' | 'instance' | 'space' | 'rundown';
export type Metadata = Record<string, FieldValue>;
export type SearchFieldValue = FieldValue | { from: string; to: string };
export type SearchMetadata = Record<string, SearchFieldValue>;
export type FieldValue = string | boolean | number | string[] | string[][] | DateRange;
export type Payload = Record<string, FieldValue>;

type Modify<T, R> = Omit<T, keyof R> & R;

// As used by application where we have parsed the metadata
export type Order = Modify<RawOrder, { metadata: Metadata }>;

// As received by GraphQL
export interface RawOrder extends CreateOrder {
  mId: string;
  mdfId: string;
  metadata: string | null;
  mSpaceId: string;
  mUpdatedAt: string;
  mCreatedAt: string;
  mCreatedById: string;
  mResourceType: ResourceType;
  mStatus: string;
  mActive: boolean;
  crudAction?: CrudActionEnum;
}

/**
 * We prepend mState with these to cheaply separate these two queries
 */
export type OrderState = 'active' | 'inactive';

export interface CreateOrder {
  mResourceId: string; // mId in database
  mFormId: string;
  metadata?: string | null; // stringified into mutation
  mOwner?: string; // mTertId in db
  mAssignee?: string; //mTertRefId in db
  mStatus?: string;
}

export interface UpdateOrder {
  mResourceId: string; // mId in database
  mId: string; // mRefId in database = orderId;
  metadata?: string; // stringified into mutation
  mOwner?: string; // mTertId in db
  mAssignee?: string; //mTertRefId in db
  mStatus?: string;
}

/**
 * Create a new order
 * @param resourceId The resource the order is connected to
 * @param formId The model of the order
 * @param creator Creator & initial owner
 * @param metadata Optionally what metadata to start with
 * @returns Order that can be sent in mutation
 */
export const createOrder = (
  resourceId: string,
  formId: string,
  creator: string,
  metadata?: Metadata,
  assigneeId?: string,
): CreateOrder => {
  return {
    metadata: JSON.stringify(metadata),
    mResourceId: resourceId,
    mFormId: formId,
    mOwner: creator,
    mStatus: 'created',
    ...(assigneeId && { mAssignee: assigneeId }),
  };
};

export const getStatusFromMState = (mState: string, field?: FormField): string => {
  if (field) {
    const option = (field.options ?? []).find((opt) => opt.value == mState);
    return option ? option.title : 'Unknown';
  }
  return mState;
};

export const getDefaultValue = (field: MdfField) => {
  if (!field) return '';
  switch (field.type) {
    case FieldTypeEnum.checkbox:
      return false;
    case FieldTypeEnum.text:
    case FieldTypeEnum.choice:
      return '';
  }
};

export const getLayoutSettings = (mdf: Mdf, field: MdfField, view: ViewTypes) => {
  const specificSettings = mdf.views[view]?.find((l) => l.fieldId === field.fieldId);
  if (!specificSettings) {
    return (
      mdf.views.default.find((l) => l.fieldId === field.fieldId) ?? getDefaultLayoutSettings(field)
    );
  }
  return specificSettings;
};

const getDefaultMStateAlternatives = (): Alternative[] => {
  return [
    {
      id: uuid(),
      label: 'Created',
      value: 'active#created',
    },
    {
      id: uuid(),
      label: 'In progress',
      value: 'active#in_progress',
    },
    {
      id: uuid(),
      label: 'Closed',
      value: 'inactive#closed',
    },
  ];
};

export const generateOrderFormConfigs = (): ConfigType[] => {
  return [
    {
      key: 'statuses',
      alternatives: getDefaultMStateAlternatives(),
    },
    {
      key: 'types',
      values: [],
    },
  ];
};
