const getTypography = (colors) => ({
  h1: {
    fontFamily: 'Inter',
    color: colors.highEmphasis,
    fontSize: '96px',
    letterSpacing: '-1.5',
    lineHeight: '116px',
    fontWeight: 300,
  },
  h2: {
    fontFamily: 'Inter',
    color: colors.highEmphasis,
    fontSize: '60px',
    letterSpacing: '-0.5',
    lineHeight: '72px',
    fontWeight: 300,
  },
  h3: {
    fontFamily: 'Inter',
    color: colors.highEmphasis,
    fontSize: '48px',
    letterSpacing: '0',
    lineHeight: '58px',
    fontWeight: 300,
  },
  h4: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '34px',
    lineHeight: '41px',
    letterSpacing: '0.25px',
    color: colors.highEmphasis,
  },
  h5: {
    fontFamily: 'Inter',
    fontWeight: 300,
    fontSize: '24px',
    lineHeight: '28px',
    letterSpacing: '0.5px',
    color: colors.highEmphasis,
  },
  h6: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color: colors.highEmphasis,
  },
  h7: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '18px',
    letterSpacing: '0px',
    color: colors.highEmphasis,
    textAlign: 'left',
  },
  body1: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '23px',
    color: colors.highEmphasis,
  },
  body1Underline: {
    fontFamily: 'Inter',
    color: colors.highEmphasis,
    fontSize: '16px',
    lineHeight: '23px',
    fontWeight: 400,
    textDecorationLine: 'underline',
  },
  body2: {
    fontFamily: 'Inter',
    fontSize: '15px',
    lineHeight: '23px',
    fontWeight: 'normal',
    color: colors.highEmphasis,
  },
  body2Medium: {
    fontFamily: 'Inter',
    color: colors.highEmphasis,
    fontSize: '24px',
    lineHeight: '36px',
    fontWeight: 'normal',
  },
  body2Large: {
    fontFamily: 'Inter',
    color: colors.highEmphasis,
    fontSize: '32px',
    lineHeight: '46px',
    fontWeight: 'normal',
  },
  body2XLarge: {
    fontFamily: 'Inter',
    color: colors.highEmphasis,
    fontSize: '42px',
    lineHeight: '56px',
    fontWeight: 'normal',
  },
  body2Underline: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: '15px',
    lineHeight: '23px',
    color: colors.mediumEmphasis,
    textDecorationLine: 'underline',
  },
  body2Italic: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontStyle: 'italic',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    color: colors.mediumEmphasis,
  },
  body2ItalicUnderline: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontStyle: 'italic',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    color: colors.mediumEmphasis,
    textDecorationLine: 'underline',
  },
  subtitle1: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '18px',
    color: colors.mediumEmphasis,
  },
  subtitle2: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
    color: colors.mediumEmphasis,
  },
  button: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '16px',
    color: colors.highEmphasis,
  },
  buttonMedium: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
    color: colors.mediumEmphasis,
    '&:hover': {
      color: colors.highEmphasis,
    },
  },
  caption: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '13px',
    letterSpacing: '0.25px',
    color: colors.mediumEmphasis,
  },
  captionRegular: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '13px',
    letterSpacing: '0.25px',
    color: colors.mediumEmphasis,
  },
  captionMedium: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '13px',
    letterSpacing: '0.25px',
    color: colors.mediumEmphasis,
  },
  captionRegularUnderline: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: '0.25px',
    color: colors.mediumEmphasis,
    textDecorationLine: 'underline',
    cursor: 'pointer',
  },
  captionItalic: {
    fontFamily: 'Inter',
    fontStyle: 'italic',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '13px',
    letterSpacing: '0.25px',
    color: colors.mediumEmphasis,
  },
  captionLink: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '13px',
    letterSpacing: '0.25px',
    color: colors.highEmphasis,
    textDecorationLine: 'underline',
  },
  captionSmall: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '12px',
    letterSpacing: '0.5px',
    color: colors.mediumEmphasis,
  },
  captionSmallLink: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '12px',
    letterSpacing: '0.5px',
    color: colors.mediumEmphasis,
    textDecorationLine: 'underline',
  },
  captionSmallBold: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '10px',
    lineHeight: '12px',
    letterSpacing: '0.5px',
    color: colors.mediumEmphasis,
  },
  captionSmallBoldLink: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '10px',
    lineHeight: '12px',
    letterSpacing: '0.5px',
    color: colors.mediumEmphasis,
    textDecorationLine: 'underline',
  },
  overline: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: '0.2px',
    color: colors.highEmphasis,
  },
  listItemLabel: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '16px',
    color: colors.highEmphasis,
  },
  listItemLabelBold: {
    fontFamily: 'Inter',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0px',
    color: colors.highEmphasis,
  },
  listItemLabelLink: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    color: colors.highEmphasis,
    textDecorationLine: 'underline',
  },
  listItemLabelMedium: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
    color: colors.highEmphasis,
  },
  listItemLabelMediumLink: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    color: colors.highEmphasis,
    textDecorationLine: 'underline',
  },
  listItemLabelItalic: {
    fontFamily: 'Inter',
    fontStyle: 'italic',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    color: colors.highEmphasis,
  },
  storyAndInstanceCardsOverline: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: '10px',
    lineHeight: '10px',
    letterSpacing: '0.25px',
    color: colors.mediumEmphasis,
  },
  storyAndInstanceCardsTitle: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '13px',
    letterSpacing: '0.25px',
    color: colors.highEmphasis,
  },
  listItemGroupHeadersLabel: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.5px',
    color: colors.mediumEmphasis,
    textTransform: 'uppercase',
  },
  storyTab: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '14px',
    letterSpacing: '0.25px',
    color: colors.mediumEmphasis,
  },
  tabLabel: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '15px',
    letterSpacing: '0.25px',
    color: colors.mediumEmphasis,
  },
  tab: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: '0.5px',
    color: colors.mediumEmphasis,
    textAlign: 'center',
  },
  label: {
    fontSize: '14px',
    letterSpacing: '0.25px',
    lineHeight: '20px',
    fontWeight: 400,
    textTransform: 'capitalize',
    textAlign: 'center',
  },
  instanceFooterLabel: {
    fontSize: '14px',
    fontFamily: 'Inter',
    lineHeight: '16px',
    letterSpacing: '0.5px',
    color: colors.mediumEmphasis,
    fontStyle: 'normal',
    fontWeight: 'normal',
  },
  instanceHeaderScheduleLabel: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 500,
    fontFamily: 'Inter',
  },
  instanceHeaderTitle: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15',
  },
  formTitle: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '24px',
    lineHeight: 'normal',
    color: colors.highEmphasis,
  },
  rightColumnHeaderText: {
    fontSize: '14px',
    letterSpacing: '0.25',
    lineHeight: '20px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontFamily: 'Inter',
    color: colors.mediumEmphasis,
  },
  teamInfoName: {
    fontSize: '24px',
    fontFamily: 'Inter',
    lineHeight: 'normal',
    color: colors.highEmphasis,
    fontStyle: 'normal',
    fontWeight: '300',
  },
  teamInfoType: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '18px',
    letterSpacing: '0.15px',
    color: colors.highEmphasis,
  },
  chipLabel: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '13px',
    letterSpacing: '0.4',
    color: colors.mediumEmphasis,
  },
  storyCardUsername: {
    fontSize: '8px',
    letterSpacing: '0.4px',
    lineHeight: '13px',
    color: colors.mediumEmphasis,
  },
  storyCardUpdated: {
    fontSize: '10px',
    lineHeight: '13px',
    letterSpacing: '0.4px',
    color: colors.highEmphasis,
  },
  marketplaceHeader: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '24px',
    lineHeight: '28px',
    color: colors.highEmphasis,
  },

  durationLabel: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: '0.5px',
    textAlign: 'center',
    color: colors.highEmphasis,
  },
  priorityLabel: {
    color: colors.highEmphasis,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '8px',
    lineHeight: '13px',
    textTransform: 'uppercase',
  },
  storyCardAvatarText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '9px',
    lineHeight: '11px',
    color: colors.mediumEmphasis,
  },
  iconLabel: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '10px',
    lineHeight: '14px',
    letterSpacing: '0.5px',
    color: colors.blackHighEmphasis,
  },
  instanceCardTitle: {
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '13px',
    letterSpacing: '0.25px',
    color: colors.highEmphasis,
  },
  onAir: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '23px',
    letterSpacing: '0.15px',
    color: colors.highEmphasis,
  },
  planned: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '24px',
    letterSpacing: '1px',
    color: colors.mediumEmphasis,
    textTransform: 'uppercase',
  },
  inputLabel: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '10px',
    fontSize: '16px',
    letterSpacing: '0.15px',
  },
  rundownHeaderTitle: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '20px' /* will be changed in new design */,
    lineHeight: '24px' /* will be changed in new design */,
    color: colors.whiteHighEmphasis,
  },
  storyHeaderTitle: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '18px' /* will be changed in new design */,
    lineHeight: '22px' /* will be changed in new design */,
    color: colors.whiteHighEmphasis,
  },
});

export default getTypography;
