declare global {
  interface Window {
    chrome: unknown;
  }
}

import { isOlderSlateValue, migrateValue } from 'components/editor';
import { EditorValue } from 'types';

declare global {
  interface Window {
    chrome: unknown;
  }
}

/* eslint-disable no-control-regex */
const replaceTab = (value: EditorValue): EditorValue | null => {
  if (!value) return null;
  /* NOTE: This is a temporary fix for chrome. For details please look at 
  https://github.com/ianstormtaylor/slate/issues/5390. Remove the function after 
  the issue is fixed by slate team. */
  if (!window.chrome) return value;
  const content = isOlderSlateValue(value) ? (migrateValue(value, {}) as EditorValue) : value;

  const { document: nodes } = content;

  nodes.forEach((node) => {
    const { children } = node;
    children.forEach((textNode) => {
      if (!('text' in textNode)) return;
      const { text } = textNode;
      const tab = /\u0009/g;
      const regex = new RegExp(tab);
      const match = regex.test(text);

      if (!match) return;
      // eslint-disable-next-line no-param-reassign
      textNode.text = text.replace(tab, ' ');
    });
  });

  return content;
};

// eslint-disable-next-line import/prefer-default-export
export { replaceTab };
