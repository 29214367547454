import { Transforms } from 'slate';
import { actionTypes } from 'components/editor/constants/types';
import selectElement from 'components/editor/utils/selectElement';
import findAndReplace from 'utils/findAndReplace';
import notifyChange from 'components/editor/utils/notifyChange';

/**
 * Adds placeholder media to given element
 *
 * @param {Object} editor SlateJS editor instance
 * @param {Object} element SlateJS element node
 * @param {Function} update Callback to be invoked on update
 * @param {String} title Title text for the placeholder
 * @returns {Object} SlateJS editor instance
 */

const createPlaceholder = async (editor, element, update, title) => {
  const { assets: previousAssets = [], ...rest } = element.data;

  try {
    const result = await update({
      type: actionTypes.CREATE_PLACEHOLDER,
      payload: { document: editor.children, title },
    });

    const updatedData = {
      ...rest,
      assets: findAndReplace(
        previousAssets,
        {
          ...result,
          title,
          itemDuration: 0,
          itemType: 'video',
          mediaType: 'video/placeholder',
          assetType: 'video',
        },
        'assetType',
      ),
    };

    selectElement(editor, element);
    Transforms.setNodes(editor, { data: updatedData });
    notifyChange(editor, update);
  } catch (error) {
    // logger.log(error);
  }

  return editor;
};

export default createPlaceholder;
