import gql from 'graphql-tag';

export default gql`
  mutation CreateContact($input: CreateContactInput) {
    createContact(input: $input) {
      mId
      mRefId
      mTitle
      mDescription
      mType
      mAvatarUrl
      mAvatarKey
      mProperties {
        __typename
        ... on ContactType {
          firstName
          surname
          email
          phone
          dateOfBirth
          jobTitle
          readSpeed
          notListed
          username
        }
      }
      mMetaData {
        key
        value
      }
      mdfId
      metadata
    }
  }
`;
