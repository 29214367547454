import gql from 'graphql-tag';

export default gql`
  mutation updateMemberImage($input: UpdateMemberInput) {
    updateMember(input: $input) {
      mAvatarKey
      mId
      mRefId
      mAvatarUrl
      mThumbnailKey
      mUpdatedAt
      mThumbnailUrl
    }
  }
`;
