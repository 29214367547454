import SplitPane from 'react-split-pane';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import MUIPopover from '@material-ui/core/Popover';
import MUITextField from '@material-ui/core/TextField';

import { ReactComponent as Add } from 'assets/icons/systemicons/add.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_down.svg';
import { ReactComponent as Close } from 'assets/icons/systemicons/close.svg';
import { ReactComponent as DragHandle } from 'assets/icons/systemicons/unionDragHandle.svg';
import IconButtonComponent from 'components/buttons/iconButton';
import Dialog from 'components/dialog';

export const Remove = styled(Close)<{ $disabled?: boolean }>`
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
  opacity: ${({ $disabled }) => ($disabled ? '0.3' : '0.7')};
  &:hover {
    ${({ $disabled }) => ($disabled ? '0.3' : '1')};
  }
`;

export const StyledClose = styled(Close)`
  opacity: 0;
  margin-top: -4px;
  &:hover {
    opacity: 1;
  }
`;

export const StyledDialogTitle = styled(DialogTitle)`
  ${({ theme }) => css`
    ${theme.typography.dina.h7};
    border-bottom: 1px solid ${theme.palette.dina.dividerLight};
  `}
  font-size: 14px !important;
  padding: 8px 8px 8px 12px !important;
`;

export const Input = styled.input`
  ${({ theme }) => theme.typography.dina.h6};
  background: transparent;
  border: none;
  outline: none;
  margin-left: 6px;
  flex: 1 1 auto;
  :focus {
    border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  }
`;

export const StyledDialogActions = styled(DialogActions)`
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledDialogContent = styled(DialogContent)`
  height: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledDialog = styled(Dialog)`
  position: relative;
  .MuiDialogContent-root {
    overflow: hidden;
    ${({ $width, $height }) => css`
      width: ${$width};
      height: ${$height};
    `}
  }
  .MuiPaper-root {
    max-width: initial;
  }
  .MuiDialogContent-root {
    padding: 12px;
  }
  .MuiDialogActions-root {
    border-top: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  }
`;

export const ContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Split = styled(SplitPane)`
  .Resizer {
    box-sizing: border-box;
    background-clip: padding-box;
    z-index: 100;
    transition: all 150ms ease-in-out;
    :hover,
    :active {
      ${({ theme }) => css`
        background-color: ${theme.palette.dina.onFocus} !important;
        box-shadow: 0px 0px 0px 1px ${theme.palette.dina.onFocus};
      `}
    }
  }
`;

export const EditWrapper = styled.div`
  display: flex;
  height: 60vh;
  gap: 40px;
`;

export const StyledAdd = styled(Add)`
  cursor: pointer;
  position: relative;
  top: -6px;
  &:hover * {
    fill-opacity: 1;
  }
`;

export const RightSubWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const RightWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 12px;
`;

export const PayloadWrapper = styled.div`
  padding-left: 12px;
  width: 100%;
`;

export const Overline = styled.div`
  ${({ theme }) => theme.typography.dina.overline};
  margin-bottom: 4px;
`;

export const OptionWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  padding-bottom: 4px;
`;

export const StyledDragHandle = styled(DragHandle)`
  cursor: grab;
  :active {
    cursor: grabbing;
    path {
      fill-opacity: 1;
    }
  }
  :hover {
    path {
      fill-opacity: 1;
    }
  }
  position: relative;
  top: 8px;
`;

export const Wrapper = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ArrowDownIcon = styled(ArrowDown)`
  margin: 0px -8px 0px 0px !important;
`;

export const ButtonText = styled('span')`
  flex-grow: 1;
`;

export const Popover = styled(MUIPopover)`
  .MuiPopover-paper {
    background: ${({ theme }) => theme.palette.dina.surfaceCard};
    margin-top: 4px;
  }
`;

export const StyledDialogContentText = styled(DialogContentText)`
  &.MuiDialogContentText-root {
    ${({ theme }) => css`
      ${theme.typography.dina.overline};
      margin: 0px;
    `}
  }
`;

export const IconButton = styled(IconButtonComponent)`
  position: absolute;
  top: 0;
  right: 0;
`;

export const TextField = styled(MUITextField)`
  &.MuiTextField-root {
    background: rgba(25, 126, 235, 0.1);
  }
`;
