import { CommandGroup } from 'lib/command';
import { SearchItemTypeEnum } from 'types/graphqlTypes';

import { Section } from '../command-types';
import { CommandItem } from '../CommandItem';

interface FeedInstructionProps {
  goToSection: (val: Section | SearchItemTypeEnum) => void;
  inputValue: string;
}

const items: Record<string, { label: string; type: Section | SearchItemTypeEnum }> = {
  stories: {
    label: 'Search in stories',
    type: SearchItemTypeEnum.story,
  },
  instances: {
    label: 'Search in instances',
    type: SearchItemTypeEnum.instance,
  },
  contacts: {
    label: 'Search in contacts',
    type: SearchItemTypeEnum.contact,
  },
  rundowns: {
    label: 'Search in rundowns',
    type: SearchItemTypeEnum.rundown,
  },
  spaces: {
    label: 'Search in spaces',
    type: SearchItemTypeEnum.space,
  },
  tasks: {
    label: 'Search in tasks',
    type: SearchItemTypeEnum.order,
  },
  notes: {
    label: 'Search in notes',
    type: SearchItemTypeEnum.note,
  },
  feeds: {
    label: 'Search in feeds',
    type: 'feeds',
  },
  assets: {
    label: 'Search in assets',
    type: 'assets',
  },
};

const InitiallyHiddenSearchGroup = ({ goToSection, inputValue }: FeedInstructionProps) => {
  return (
    <CommandGroup heading="Search">
      {Object.entries(items).map(([key, value]) => (
        <CommandItem
          forceMount={value.label.toLowerCase().includes(inputValue.toLowerCase())}
          key={`search-${key}`}
          onSelect={() => goToSection(value.type)}
          type="search"
        >
          {value.label}
        </CommandItem>
      ))}
    </CommandGroup>
  );
};

export default InitiallyHiddenSearchGroup;
