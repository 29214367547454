/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useState } from 'react';

import SplitBar from 'components/split';
import { MemberType } from 'types/graphqlTypes';
import isUrl from 'utils/isUrl';

import { useSelectedMenuSystemSettings } from '../../../../../atoms';
import { ChangedWebhookType, useChangedWebhooks } from '../../../../../atomsTs';
import SettingsContent from '../settingsContent/settingsContent-view';

import getPayload from './payload';

import {
  FallbackText,
  FallbackWrapper,
  Formatted,
  HooksWrapper,
  Label,
  PayloadWrapper,
  Viewer,
  Wrapper,
} from './styled';

const getSeverity = (url: string) => (isUrl(url) ? 'regular' : 'error');

const getWebhookSeverity = (hooks: ChangedWebhookType[], key: string) =>
  hooks.find((hook) => hook.key === key)?.severity;

interface Props {
  readonly hooks: MemberType[];
}

function WebhookDetails({ hooks }: Props) {
  const [selectedMenu] = useSelectedMenuSystemSettings();
  const [changedWebhooks, setChangedWebhooks] = useChangedWebhooks();
  const [showPayload, setShowPayload] = useState(false);

  const endpointHooks = hooks.filter((endpoint) =>
    endpoint.mAllowedEntities?.mType?.includes(selectedMenu as string),
  );

  const onChange = (value: string, contentKey: string, originalValue: string) =>
    setChangedWebhooks((prevHooks) => {
      const existingHook = prevHooks.find((ep) => ep.key === contentKey);

      if (existingHook) {
        if (existingHook?.originalValue === value)
          return prevHooks.filter((ep) => ep.key !== contentKey);

        return prevHooks.map((ep) =>
          ep.key === contentKey ? { ...ep, value, severity: getSeverity(value) } : ep,
        );
      } else
        return [
          ...prevHooks,
          { key: contentKey, value, originalValue, severity: getSeverity(value) },
        ];
    });

  return (
    <Wrapper>
      {endpointHooks.length ? (
        <SplitBar
          split={undefined}
          style={{
            height: '100%',
          }}
          primary="first"
          pane1Style={{
            minWidth: '50%',
            maxWidth: '75%',
          }}
          pane2Style={{
            minWidth: '25%',
            maxWidth: '50%',
          }}
        >
          <HooksWrapper>
            {endpointHooks.map((endpointHook) => {
              const severity = getWebhookSeverity(changedWebhooks, endpointHook.mRefId as string);
              const description =
                severity === 'error'
                  ? 'Not a valid URL'
                  : endpointHook.mProvider && `${endpointHook.mProvider} endpoint hook`;

              return (
                <SettingsContent
                  key={endpointHook.mRefId}
                  contentKey={endpointHook.mRefId}
                  label={endpointHook.mTitle}
                  value={endpointHook.mInvokeUrl}
                  description={description}
                  onChange={onChange}
                  changedSettings={changedWebhooks}
                  severity={severity}
                  onFocus={() => setShowPayload(true)}
                />
              );
            })}
          </HooksWrapper>
          <PayloadWrapper>
            <Label>Example Payload</Label>
            <Viewer>
              <Formatted>
                {JSON.stringify(showPayload ? getPayload(selectedMenu as string) : {}, null, 1)}
              </Formatted>
            </Viewer>
          </PayloadWrapper>
        </SplitBar>
      ) : (
        <FallbackWrapper>
          <FallbackText>No {selectedMenu} webhook configured</FallbackText>
        </FallbackWrapper>
      )}
    </Wrapper>
  );
}

export default WebhookDetails;
