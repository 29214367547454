import styled from '@emotion/styled';
import transientOptions from 'theme/helpers';

const BoxWrapper = styled('div', transientOptions)`
  border: 1px solid
    ${({ $isSelected, theme }) =>
      theme.palette.dina[$isSelected ? 'onFocus' : 'buttonBorderOutlined']};
  position: relative;
  border-radius: 4px;
  width: 100%;
  height: 42px;
  display: flex;
  &:hover {
    background-color: ${({ theme }) => theme.palette.dina.hoverOverlay};
    .primaryAutomationMenu {
      opacity: ${({ $readOnly }) => ($readOnly ? 0 : 1)};
      width: ${({ $readOnly }) => ($readOnly ? 0 : '32px')};
    }
  }
`;

const ContentWrapper = styled.div`
  padding-left: 42px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  .primaryAutomationMenu {
    width: 0px;
    opacity: 0;
    transition: all 0.3s ease;
    overflow: hidden;
    display: flex;
    will-change: width, opacity;
  }
`;

const Icon = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
`;

const ScriptIconWrapper = styled('div')`
  display: flex;

  svg {
    path {
      fill: ${({ theme }) => theme.palette.dina.statusWarning};
    }
  }
`;

const PlaceholdersWrapper = styled('div')`
  width: ${({ hasPlaceholder, hasScript }) => (hasPlaceholder && hasScript ? '80px' : '40px')};
  min-width: 40px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const RootWrapper = styled.div`
  pointer-events: ${(props) => (props.readOnly ? 'none' : 'all')};
  padding: 8px;
  width: 100%;
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ThumbnailWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  user-select: none;
  display: flex;
  pointer-events: all;
`;

const TitleWrapper = styled.div`
  width: calc(100% - 144px);
  min-width: calc(100% - 220px);
  transition: width 0.3s ease;
  will-change: width;
`;

export {
  BoxWrapper,
  ContentWrapper,
  Icon,
  PlaceholdersWrapper,
  RootWrapper,
  Thumbnail,
  ThumbnailWrapper,
  TitleWrapper,
  ScriptIconWrapper,
};
