import { useEffect } from 'react';
import { ApolloClient } from '@apollo/client';
import { ScopeProvider } from 'jotai-molecules';
import isEqual from 'lodash/isEqual';

import useGetMember from 'api/useGetMember';
import DebouncedLoadingComponent from 'components/debouncedLoadingIndicator/DebouncedLoadingIndicator';
import NOTIFY_MEMBER_UPDATE_SUBSCRIPTION from 'graphql/subscriptions/notifyMemberUpdate';
import useApolloSubscription from 'hooks/useApolloSubscription';
import { VStack } from 'layouts/box/Box';
import { Instance } from 'types';
import { MemberType } from 'types/graphqlTypes';

import Body from './components/Body';
import DNDProvider from './components/DNDProvider';
import Footer from './components/Footer';
import Header from './components/Header';
import useInstanceAssignees from './hooks/useInstanceAssignees';
import useInstanceCore from './hooks/useInstanceCore';
import useInstancePermissions from './hooks/useInstancePermissions';
import useInstanceViewUtils from './hooks/useInstanceViewUtils';
import { InstanceScope, useInstanceMolecule } from './store/instance';
import { propertiesToMapIntoCache } from './utils';

import { StyledPaper } from './styled';

interface Props {
  instance: Instance;
  hideTemplateOptions?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
}

interface WrapperProps extends Props {
  isSearchItem?: boolean;
}
interface RootProps extends WrapperProps {
  autoUpdate?: boolean;
}

interface SubscriptionEvent {
  data: {
    notifyMemberUpdateSubscription: MemberType;
  };
}

const InstanceComponent = ({ instance, hideTemplateOptions, onOpen, onClose }: Props) => {
  const { useSetInstance, useInstanceValue, useHideTemplateOptions, instanceRef } =
    useInstanceMolecule();
  const { updateRelatedMembersMutation, writeLock, readLock, updatingRelatedMembers, loading } =
    useInstanceCore();
  const { canUpdateInstance } = useInstancePermissions();
  const { assignMemberToInstance } = useInstanceAssignees();
  const { onPaperKeyDown } = useInstanceViewUtils();
  const setInstance = useSetInstance();
  const [, setHideTemplateOptions] = useHideTemplateOptions();
  const instanceValue = useInstanceValue();

  useEffect(() => {
    instanceRef.current = instance;
    if (!isEqual(instanceValue, instance)) {
      setInstance(instance);
    }
  }, [instance, instanceValue, setInstance, instanceRef]);

  useEffect(() => {
    setHideTemplateOptions(!!hideTemplateOptions);
  }, [hideTemplateOptions, setHideTemplateOptions]);

  return (
    <DNDProvider
      updateRelatedMembersMutation={updateRelatedMembersMutation}
      canUpdateInstance={canUpdateInstance}
      instance={instance}
      handleUserDrop={assignMemberToInstance}
      storyId={instance?.mStoryId}
    >
      <VStack flex="1">
        <StyledPaper $writeLock={writeLock} $readLock={readLock} onKeyDown={onPaperKeyDown}>
          {(loading || updatingRelatedMembers) && <DebouncedLoadingComponent />}
          <Header onOpen={onOpen} onClose={onClose} />
          <Body />
          <Footer />
        </StyledPaper>
      </VStack>
    </DNDProvider>
  );
};

const fieldsMapper = (item: MemberType) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mapper: Record<string, any> = {};
  propertiesToMapIntoCache.forEach((property) => {
    mapper[property] = () => item[property] ?? null;
  });
  return mapper;
};

const InstanceWrapper = ({
  instance,
  hideTemplateOptions,
  isSearchItem = false,
  ...rest
}: Readonly<WrapperProps>) => {
  const { useSetInstance, useInstanceValue } = useInstanceMolecule();
  const setInstance = useSetInstance();
  const { mId, mRefId, mStoryId } = instance;
  const { data, loading } = useGetMember({
    mId,
    mRefId,
    fetchPolicy: 'cache-and-network',
    type: 'instance',
  });
  const instanceValue = useInstanceValue();

  useEffect(() => {
    if (!loading && !isEqual(instanceValue, data)) {
      setInstance(data as Instance);
    }
  }, [data, instanceValue, loading, setInstance]);

  const [subscribe, unSubscribe] = useApolloSubscription(NOTIFY_MEMBER_UPDATE_SUBSCRIPTION, {
    variables: {
      mIdSubscribed: mStoryId ?? data?.mStoryId ?? '',
    },

    onSubscriptionData: ({
      subscriptionData,
      client,
    }: {
      subscriptionData: SubscriptionEvent;
      client: ApolloClient<object>;
    }) => {
      if (isSearchItem) {
        client.cache.modify({
          id: client.cache.identify({
            mId,
            mRefId,
            __typename: 'SearchItem',
          }),
          fields: fieldsMapper(subscriptionData.data.notifyMemberUpdateSubscription ?? {}),
        });
      }
    },
  });

  useEffect(() => {
    subscribe();
    return () => {
      unSubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InstanceComponent
      instance={(data as Instance) ?? instance}
      hideTemplateOptions={hideTemplateOptions}
      {...rest}
    />
  );
};

function InstanceRoot({
  instance,
  hideTemplateOptions,
  autoUpdate = false,
  isSearchItem = false,
  ...rest
}: Readonly<RootProps>) {
  if (autoUpdate) {
    return (
      <ScopeProvider scope={InstanceScope} value={instance.mId}>
        <InstanceWrapper
          instance={instance}
          hideTemplateOptions={hideTemplateOptions}
          isSearchItem={isSearchItem}
          {...rest}
        />
      </ScopeProvider>
    );
  }
  return (
    <ScopeProvider scope={InstanceScope} value={instance.mId}>
      <InstanceComponent instance={instance} hideTemplateOptions={hideTemplateOptions} {...rest} />
    </ScopeProvider>
  );
}

export default InstanceRoot;
