/* eslint-disable @typescript-eslint/no-unsafe-return */
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Dictionary } from 'lodash';

import { useGetMdf } from 'api/mdf/useGetMdf';
import { Block } from 'api/mdfBlocks/types';
import { useGetBlock } from 'api/mdfBlocks/useGetMdfBlock';
import { useUpdateBlockTitle } from 'api/mdfBlocks/useUpdateMdfBlock';
import { useGetOrderForms } from 'api/order_forms/useGetOrderForms';
import useUpdateMetadata from 'api/useUpdateMetadata';
import AddMemberDropdown from 'components/addMember';
import MemberLabel from 'components/addMember/MemberLabel';
import Input from 'components/input/Input';
import { MdfEditor } from 'components/mdfEditor/MdfEditor';
import { FieldHeader } from 'components/mdfEditor/styled';
import Text from 'components/text';
import useToast from 'components/toast/useToast';
import LWSelect from 'features/orderForm/components/LWSelect';
import { Box } from 'layouts/box/Box';
import { useGetOrder } from 'screens/space/api/useGetOrdersAndForms';
import useUpdateOrder from 'screens/space/api/useUpdateOrder';
import { useAllMembersKeyed } from 'store';
import { AssignedMember } from 'types';
import { Metadata, NewFieldValue } from 'types/forms/forms';
import { GetOrderEnum, MemberTypeEnum } from 'types/graphqlTypes';
import { OrderFormMemberType } from 'types/memberTypes/order_form';
import capitalize from 'utils/capitalize';
import getRelativeDateTime from 'utils/getRelativeDateTime';
import preventDefaultAndPropagation from 'utils/preventDefaultAndStopPropagation';

import { elementTypes } from './constants';
import { useEditorMolecule } from './store';

const Container = styled('div')`
  height: 100%;
`;

const TabWrapper = styled('div')`
  height: 32px;
  background-color: ${({ theme }) => theme.palette.dina.headerFooterOverlay};
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  display: flex;
  align-items: center;
  padding-left: 8px;
`;

const ContentWrapper = styled('div')`
  padding: 8px 16px 16px;
  height: calc(100% - 32px);
  overflow-y: auto;
`;

const FieldWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  gap: 4px;
`;

const formatLocale = (date: string) =>
  capitalize(getRelativeDateTime(date, undefined, true, 'MMM D YYYY,  hh:mm a', 'hh:mm a'));

export const getStatusOptions = (
  mFormId?: string,
  orderFormMap?: Dictionary<OrderFormMemberType>,
) => {
  if (mFormId && orderFormMap) {
    const formConfigs = orderFormMap[mFormId]?.configs;
    const statusesConfig = formConfigs?.find((config) => config.key === 'statuses');
    return (
      statusesConfig?.alternatives?.map((alt) => ({
        ...alt,
        title: alt.label,
        value: alt.value.split('#')[1],
      })) ?? []
    );
  }
  return [];
};

interface EditOrderProps {
  orderId?: string;
  taskType?: string;
}

interface EditMdfProps {
  resourceId?: string;
  blockId?: string;
  mdfId?: string;
}

export function EditOrderProperties({ orderId, taskType }: Readonly<EditOrderProps>) {
  const [members] = useAllMembersKeyed();
  const { order } = useGetOrder(orderId, GetOrderEnum.Resource);
  const { updateOrder } = useUpdateOrder();
  const { mdf } = useGetMdf(order?.mdfId);
  const { orderForms } = useGetOrderForms();
  const orderMap = useMemo(() => {
    const orderFormMap: Record<string, OrderFormMemberType> = {};
    orderForms.forEach((o) => {
      if (o.mSecId) {
        orderFormMap[o.mRefId] = o;
      }
    });
    return orderFormMap;
  }, [orderForms]);

  const [isFindingAssignee, setIsFindingAssignee] = useState<boolean>(false);
  const [isFindingOwner, setIsFindingOwner] = useState<boolean>(false);

  const assignee = useMemo(
    () => (order?.mAssignee ? members[order.mAssignee] : null),
    [order?.mAssignee],
  );
  const owner = useMemo(() => (order?.mOwner ? members[order.mOwner] : null), [order?.mOwner]);

  const onRemoveClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.stopPropagation();
  };

  const statusOptions = useMemo(() => getStatusOptions(order?.mFormId, orderMap) ?? [], [orderMap]);

  const updateOrderProperty = useCallback(
    (key: string, value: string) => {
      if (!order) return;

      updateOrder({
        mId: order.mId,
        mResourceId: order.mResourceId,
        [key]: value,
      }).then(
        () => {},
        () => {},
      );
    },
    [order],
  );

  const updateMetadata = useCallback(
    ({ fieldId, value }: NewFieldValue) => {
      const changedMetadata = { ...(order?.metadata ?? {}), [fieldId]: value };
      updateOrderProperty('metadata', JSON.stringify(changedMetadata));
    },
    [order?.metadata],
  );

  return (
    mdf && (
      <>
        {taskType && (
          <FieldWrapper>
            <FieldHeader variant="overline">Task type</FieldHeader>
            <Input initialValue={taskType} readOnly />
          </FieldWrapper>
        )}
        <MdfEditor
          fields={mdf.fields}
          permissions={mdf.permissions}
          updateFieldValue={updateMetadata}
          metadata={order?.metadata ?? {}}
          layoutSettings={[]}
          defaultLayoutSettings={mdf.views.default}
        />
        {order?.mStatus && (
          <FieldWrapper>
            <FieldHeader>Status</FieldHeader>
            <LWSelect
              value={order.mStatus}
              options={statusOptions}
              setValue={(value: string) => updateOrderProperty('mStatus', value)}
            />
          </FieldWrapper>
        )}
        <FieldWrapper>
          <FieldHeader>Assignee</FieldHeader>
          {isFindingAssignee ? (
            <AddMemberDropdown
              variant="all"
              autoFocus
              singleChoice
              disableClearable={true}
              selectedMembers={assignee ? [assignee] : []}
              setSelectedMembers={([newMember]: AssignedMember[]) =>
                updateOrderProperty('mAssignee', newMember.mId)
              }
              placeholderText="Set assignee"
              noOptionsText="No matches found"
              injectedMembers={undefined}
              onClose={() => setIsFindingAssignee(false)}
            />
          ) : (
            <MemberLabel
              variant="form"
              member={assignee}
              onClick={() => setIsFindingAssignee(true)}
              onRemoveClick={onRemoveClick}
            />
          )}
        </FieldWrapper>
        <FieldWrapper>
          <FieldHeader>Owner</FieldHeader>
          {isFindingOwner ? (
            <AddMemberDropdown
              variant="all"
              singleChoice
              autoFocus
              disableClearable={true}
              selectedMembers={owner ? [owner] : []}
              setSelectedMembers={([newMember]: AssignedMember[]) =>
                updateOrderProperty('mOwner', newMember.mId)
              }
              placeholderText="Set owner"
              noOptionsText="No matches found"
              injectedMembers={undefined}
              onClose={() => setIsFindingOwner(false)}
            />
          ) : (
            <MemberLabel
              variant="form"
              member={owner}
              onClick={() => setIsFindingOwner(true)}
              onRemoveClick={onRemoveClick}
            />
          )}
        </FieldWrapper>
        {order?.mCreatedAt && (
          <FieldWrapper>
            <FieldHeader>Created</FieldHeader>
            <Input initialValue={formatLocale(order.mCreatedAt)} readOnly />
          </FieldWrapper>
        )}
        {order?.mUpdatedAt && (
          <FieldWrapper>
            <FieldHeader>Updated</FieldHeader>
            <Input initialValue={formatLocale(order.mUpdatedAt)} readOnly />
          </FieldWrapper>
        )}
      </>
    )
  );
}

function Fallback() {
  return (
    <ContentWrapper>
      <Text variant="listItemLabelMedium">Please select valid Task from editor</Text>
    </ContentWrapper>
  );
}

export function EditMdfBlock({ resourceId, blockId, mdfId }: Readonly<EditMdfProps>) {
  const updateMetadata = useUpdateMetadata();
  const updateTitle = useUpdateBlockTitle();
  const [title, setTitle] = useState('');
  const [metadata, setMetadata] = useState<Metadata>({});
  const { errorToast } = useToast();
  const { block, loading } = useGetBlock(resourceId, blockId);
  const { mdf } = useGetMdf(mdfId);

  useEffect(() => {
    setTitle(block?.mTitle ?? '');
    setMetadata(block?.metadata ?? {});
  }, [block?.mRefId]);

  const doPartialUpdate = useCallback(
    (val: NewFieldValue, blockToUpdate: Block) => {
      setMetadata((prevMetadata) => {
        return {
          ...prevMetadata,
          [val.fieldId]: val.value,
        };
      });
      updateMetadata(
        blockToUpdate.mId,
        blockToUpdate.mRefId,
        { [val.fieldId]: val.value },
        blockToUpdate.metadata,
        MemberTypeEnum.Block,
        blockToUpdate.mdfId,
      ).catch(errorToast);
    },
    [updateMetadata, setMetadata],
  );

  const doUpdateTitle = useCallback(
    (val: string, blockToUpdate: Block) => {
      updateTitle(blockToUpdate.mId, blockToUpdate.mRefId, val).catch(errorToast);
    },
    [updateTitle],
  );

  return (
    mdf &&
    block &&
    !loading && (
      <>
        <FieldWrapper>
          <FieldHeader variant="overline">Block title</FieldHeader>
          <Input
            initialValue={title}
            onChange={(ev) => setTitle(ev.currentTarget.value)}
            onBlur={() => doUpdateTitle(title, block)}
          />
        </FieldWrapper>
        <Box margin="5px 0 0 0">
          <MdfEditor
            fields={mdf.fields}
            defaultLayoutSettings={mdf.views.default}
            layoutSettings={mdf.views.story_view}
            metadata={metadata}
            permissions={mdf.permissions}
            updateFieldValue={(val: NewFieldValue) => doPartialUpdate(val, block)}
          />
        </Box>
      </>
    )
  );
}

function SidePanel() {
  const { useSelectedBlock } = useEditorMolecule();
  const [selectedBlock] = useSelectedBlock();

  const MemoizedComponent = useMemo(() => {
    switch (selectedBlock?.type) {
      case elementTypes.ORDER_BLOCK:
        return (
          <EditOrderProperties
            taskType={selectedBlock?.data?.taskType}
            orderId={selectedBlock?.data?.mId}
          />
        );
      case elementTypes.MDF_BLOCK:
        return (
          <EditMdfBlock
            resourceId={selectedBlock.data?.mResourceId}
            blockId={selectedBlock.data?.mId}
            mdfId={selectedBlock.data?.mdfId}
          />
        );
      default:
        return <Fallback />;
    }
  }, [selectedBlock]);

  return (
    <Container onClick={preventDefaultAndPropagation}>
      <TabWrapper>
        <Text variant="tabLabelSmall">Inspector</Text>
      </TabWrapper>
      <ContentWrapper>{MemoizedComponent}</ContentWrapper>
    </Container>
  );
}

export default memo(SidePanel);
