import isHotkey from 'is-hotkey';
import { hotkeys } from 'components/editor/constants';
import { toggleMark, isMarkActive } from 'components/editor/utils';

const onLeafKeyDown = (editor, event) => {
  Object.keys(hotkeys).forEach((hotkey) => {
    if (isHotkey(hotkey, event)) {
      event.preventDefault();

      const mark = hotkeys[hotkey];

      const isActive = isMarkActive(editor, mark);

      toggleMark(editor, mark, isActive);
    }
  });

  return editor;
};

export default onLeafKeyDown;
