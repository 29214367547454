import { useMemo } from 'react';
import { useContextMenu } from 'react-contexify';

import Tooltip from 'components/tooltip';
import { useGetOrders } from 'screens/space/api/useGetOrdersAndForms';
import { useOrderFormFilter, useOrdersDialog } from 'store';
import { ResourceType } from 'types/forms/forms';
import { GetOrderEnum, TaskStatusEnum } from 'types/graphqlTypes';

import {
  StyledButton,
  StyledPlus,
  TaskProgress,
  TaskProgressWrapper,
  TaskText,
  TaskWrapper,
} from '../styled';

export interface OrderButtonProps {
  resourceId: string;
  resourceTitle: string;
  resourceType: ResourceType;
  formTypes: string[];
  dark?: boolean;
  onClose?: () => void;
}

interface TaskDetails {
  completed: number;
  total: number;
  pct: number;
}

function OrderButton({
  resourceId,
  resourceTitle,
  resourceType,
  formTypes,
  dark,
}: Readonly<OrderButtonProps>) {
  const [, setShowOrders] = useOrdersDialog();
  const { show } = useContextMenu({ id: 'orderForms' });
  const { orders, loading } = useGetOrders(resourceId, GetOrderEnum.Resource, TaskStatusEnum.all);
  const [, setFormFilter] = useOrderFormFilter();

  const taskDetails = useMemo<TaskDetails>(() => {
    const completedOrders = orders.filter((order) => order.mActive !== true).length;

    const pct = completedOrders !== 0 ? completedOrders / orders.length : 0;
    return {
      pct: pct * 100,
      completed: completedOrders,
      total: orders.length,
    };
  }, [orders]);

  const handleContextMenu = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => {
    setFormFilter({ types: formTypes ?? null });
    show({
      event,
      props: {
        resourceId: id,
        resourceType,
      },
    });
  };

  const handleShowOrders = (id: string) => {
    if (id) {
      setShowOrders({
        resourceId,
        resourceTitle,
        resourceType,
        formTypes,
        open: true,
        type: 'resource',
      });
    }
  };

  return (
    <StyledButton height={32} variant="outlined" usage="outlined" $dark={dark}>
      <Tooltip title="View tasks">
        <TaskWrapper onClick={() => handleShowOrders(resourceId)}>
          {taskDetails.total === 0 || loading ? (
            <TaskText>{loading ? 'Loading' : 'No tasks'}</TaskText>
          ) : (
            <>
              <TaskText>
                Tasks: {taskDetails.completed} / {taskDetails.total}
              </TaskText>
              <TaskProgressWrapper>
                <TaskProgress $width={taskDetails.pct}></TaskProgress>
              </TaskProgressWrapper>
            </>
          )}
        </TaskWrapper>
      </Tooltip>
      <Tooltip title="Create a task">
        <StyledPlus onClick={(ev) => handleContextMenu(ev, resourceId)}>+</StyledPlus>
      </Tooltip>
    </StyledButton>
  );
}

export default OrderButton;
