import { useContext } from 'react';
import { useQuery } from '@apollo/client';
import groupBy from 'lodash/groupBy';

import UserContext from 'contexts/UserContext';
import GET_CONVERSATIONS_OF_USER from 'graphql/queries/getConversationsOfUser';
import useCreateConversation from 'hooks/useCreateConversation';
import assembleDepartmentData from 'screens/main/components/header/navbar/messageHub/components/newMessage/utils/assembleDepartmentData';
import assemblePeopleData from 'screens/main/components/header/navbar/messageHub/components/newMessage/utils/assemblePeopleData';
import assembleTeamData from 'screens/main/components/header/navbar/messageHub/components/newMessage/utils/assembleTeamData';
import { ConversationType, MemberTypeEnum } from 'types/graphqlTypes';
import { AssignedMember } from 'types/members';
import conversationTypes from 'utils/constants/conversationTypes';

interface FetchConversations {
  getConversationsOfUser: ConversationType[];
}

const useCreateConvo = () => {
  const [createConversation] = useCreateConversation();
  const user = useContext(UserContext);

  const { data } = useQuery<FetchConversations>(GET_CONVERSATIONS_OF_USER, {
    variables: {
      input: {
        mId: user?.mId,
      },
    },
  });

  const groupedData = groupBy(data?.getConversationsOfUser, 'convoType');

  const createConvo = async (member: AssignedMember, onOk: (convo: ConversationType) => void) => {
    let convoData = [];
    if (member.mType === ('department' as MemberTypeEnum)) {
      convoData = assembleDepartmentData([member]);
    } else if (member.mType === ('team' as MemberTypeEnum)) {
      convoData = assembleTeamData([member]);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      convoData = assemblePeopleData([member], user.mId, groupedData[conversationTypes.DIRECT]);
    }

    await createConversation(convoData, onOk);
  };

  return { createConvo };
};

export default useCreateConvo;
