import styled from '@emotion/styled/macro';

import { VStack } from 'layouts/box/Box';

export const Wrapper = styled(VStack)`
  width: 65vw;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.dina.surfaceCard};
`;

export const DateValue = styled('p')`
  ${({ theme }) => theme.typography.dina.caption};
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  margin: 0;
  margin-left: 8px;
`;

export const LeftSideContent = styled('article')`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0 16px 16px;
`;

export const RightSideContent = styled('aside')`
  height: 100%;
  width: 300px;
  min-width: 300px;
  display: flex;
  padding: 16px 0;
  flex-direction: column;
  border-left: 1px solid ${({ theme }) => theme.palette.dina.outlineButtonOnHover};
`;

export const DescriptionInput = styled('textarea')`
  ${({ theme }) => theme.typography.dina.body2};
  background: transparent;
  border: none;
  outline: none;
  border: 1px solid transparent;
  border-radius: 6px;
  padding: 8px;
  resize: none;
  flex: 1;
  :hover {
    filter: brightness(1.1);
    background-color: ${({ theme }) => theme.palette.dina.outlineButtonOnHover};
    border-color: ${({ theme }) => theme.palette.dina.outlineButtonOnHover};
  }
  :focus-within {
    border: 1px solid ${({ theme }) => theme.palette.dina.statusOnFocused};
    background: ${({ theme }) => theme.palette.dina.statusOnFocusedDiscreetFill};
  }
`;

export const SidebarTitle = styled('p')`
  ${({ theme }) => theme.typography.dina.overline};
  margin: 0;
  margin-bottom: 4px;
`;

export const EmptyStateText = styled('p')`
  ${({ theme }) => theme.typography.dina.caption};
  color: ${({ theme }) => theme.palette.dina.disabled};
  margin: 0;
`;

export const Footer = styled('div')`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding: 0 16px;
  align-items: flex-start;
`;

export const AssignMetadata = styled('div')`
  display: flex;
  flex-direction: column;
  padding-left: 12px;
  width: 100%;
  gap: 12px;
  height: 60%;
  padding-right: 4px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.outlineButtonOnHover};
`;

export const ActionButtons = styled('div')`
  padding-top: 8px;
  padding-left: 4px;
  padding-right: 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;
