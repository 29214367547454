import { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { CSS } from '@dnd-kit/utilities';
import Popover from 'components/popover';
import NotificationPopup, { notificationIdentifiers } from 'components/notificationPopup';
import Tooltip from 'components/tooltip/Tooltip';

import { ReactComponent as Delete } from 'assets/icons/systemicons/delete_small.svg';
import { ReactComponent as DragHandle } from 'assets/icons/systemicons/unionDragHandle.svg';
import { ReactComponent as DefaultOff } from 'assets/icons/systemicons/Default_off.svg';
import { ReactComponent as DefaultOn } from 'assets/icons/systemicons/Default_on.svg';
import { ReactComponent as OverwriteIcon } from 'assets/icons/systemicons/refresh_small.svg';

import sortByTitle from 'utils/instance/templates/sortByTitle';
import CreateNew from '../../../linearEllipsisMenu/components/createNew';
import Warning from '../../../warning';
import ConfirmationComponent from '../../../linearEllipsisMenu/components/confirmationComponent';
import SortableList from 'components/sortableList';
import {
  List,
  ListItem,
  ListItemWrapper,
  ListItemText,
  AddIcon,
  Divider,
  DeleteWrapper,
  DragHandleWrapper,
  DefaultWrapper,
  DefaultIconWrapper,
  OverwriteWrapper,
} from './templatesSubMenu-styled';

const anchorOrigin = {
  vertical: 'center',
  horizontal: 'left',
};

const transformOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

const defaultTemplateValue = {
  mId: null,
  mRefId: null,
  mTitle: null,
  mContentKey: null,
};

const getSortedTemplates = (templates, mOrder) => {
  if (!mOrder || !Array.isArray(mOrder)) return sortByTitle(templates);
  const items = [...templates];
  const sortedTemplates = [];

  mOrder.forEach((item) => {
    const index = items.findIndex((template) => template.mRefId === item);
    if (index !== -1) {
      const template = items[index];
      sortedTemplates.push(template);
      items.splice(index, 1);
    }
  });

  if (items.length) sortedTemplates.push(...sortByTitle(items));

  return sortedTemplates;
};

const Template = ({
  template,
  disabled,
  onClick,
  canCreateNewTemplate,
  handleCreateNewTemplate,
  canDeleteTemplate,
  onDelete,
  canReorderTemplates,
  canSetDefaultTemplate,
  isDefaultTemplate,
  onSetDefaultTemplate,
  isContentLoaded,
  attributes,
  listeners,
  setNodeRef,
  setActivatorNodeRef,
  transform,
  transition,
  id,
}) => {
  const { mRefId, mTitle, mContentKey } = template;

  const [anchorEl, setAnchorEl] = useState(null);
  const overwriteRef = useRef(null);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const onOverwriteClick = () => {
    if (!isContentLoaded) return;
    setAnchorEl(overwriteRef.current);
  };

  const onCloseWarning = () => {
    setAnchorEl(null);
  };

  const handleOverwrite = () => {
    handleCreateNewTemplate(mTitle, template);
    onCloseWarning();
  };

  const mergeRefs = (element) => {
    setNodeRef(element);
    overwriteRef.current = element;
  };

  const overwriteIconPosition = useMemo(() => {
    if (canSetDefaultTemplate && canDeleteTemplate) return 2;

    if (
      (canSetDefaultTemplate && !canDeleteTemplate) ||
      (!canSetDefaultTemplate && canDeleteTemplate)
    )
      return 1;

    return 0;
  }, [canSetDefaultTemplate, canDeleteTemplate]);

  const bookmarkIconPosition = useMemo(() => {
    if (canDeleteTemplate) return 1;

    return 0;
  }, [canDeleteTemplate]);

  const handleClick = () => onClick(mContentKey);

  return (
    <ListItemWrapper ref={mergeRefs} style={style} {...attributes}>
      {canReorderTemplates && (
        <DragHandleWrapper>
          <DragHandle ref={setActivatorNodeRef} {...listeners} />
        </DragHandleWrapper>
      )}
      <ListItem button title={mTitle} disabled={disabled} onClick={handleClick} $transparentHover>
        <ListItemText primary={mTitle} />
      </ListItem>
      {canCreateNewTemplate && canDeleteTemplate && (
        <OverwriteWrapper
          title={
            !isContentLoaded ? 'Load content first before you overwrite this template' : 'Overwrite'
          }
          positionFromRight={overwriteIconPosition}
          $disabled={!isContentLoaded}
        >
          <OverwriteIcon onClick={onOverwriteClick} />
        </OverwriteWrapper>
      )}
      {isDefaultTemplate && (
        <DefaultIconWrapper>
          <DefaultOn />
        </DefaultIconWrapper>
      )}
      {canSetDefaultTemplate && (
        <DefaultWrapper
          onClick={() => onSetDefaultTemplate(id ?? mRefId, isDefaultTemplate)}
          positionFromRight={bookmarkIconPosition}
        >
          {isDefaultTemplate ? (
            <DefaultOn title="Remove as default template" />
          ) : (
            <DefaultOff title="Set as default template" />
          )}
        </DefaultWrapper>
      )}
      {canDeleteTemplate && (
        <DeleteWrapper title="Delete Template">
          <Delete onClick={onDelete} ref={overwriteRef} />
        </DeleteWrapper>
      )}
      {!!anchorEl && (
        <Popover anchorEl={anchorEl} onClose={onCloseWarning} position="top-center" noMargin>
          <div>
            <Warning
              title={mTitle}
              onClose={onCloseWarning}
              onConfirm={handleOverwrite}
              variant="templateOverride"
            />
          </div>
        </Popover>
      )}
    </ListItemWrapper>
  );
};

Template.propTypes = {
  template: PropTypes.any,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  canCreateNewTemplate: PropTypes.bool,
  handleCreateNewTemplate: PropTypes.func,
  canDeleteTemplate: PropTypes.bool,
  onDelete: PropTypes.func,
  canReorderTemplates: PropTypes.bool,
  canSetDefaultTemplate: PropTypes.bool,
  isDefaultTemplate: PropTypes.bool,
  onSetDefaultTemplate: PropTypes.func,
};

Template.defaultProps = {
  template: { mRefId: '', mTitle: '', mContentKey: '' },
  onClick: () => {},
  onDelete: () => {},
  handleCreateNewTemplate: () => {},
  onSetDefaultTemplate: () => {},
  disabled: true,
  canCreateNewTemplate: false,
  canDeleteTemplate: false,
  canReorderTemplates: false,
  canSetDefaultTemplate: false,
  isDefaultTemplate: false,
};

function SubMenu(props) {
  const {
    folderId,
    templates,
    anchorEl,
    onTemplateSelect,
    onTemplateSave,
    onDeleteTemplate,
    disabled,
    canCreateNewTemplate,
    canDeleteTemplate,
    mOrder: order,
    updateMOrder,
    canReorderTemplates,
    canSetDefaultTemplate,
    defaultTemplateRefId: dTemplateRefId,
    onSetDefaultTemplate: updateDefaultTemplate,
    isContentLoaded,
  } = props;

  const [anchor, setAnchor] = useState(null);
  const [currentTemplate, setCurrentTemplate] = useState(defaultTemplateValue);
  const [openDialog, setOpenDialog] = useState(false);
  const [mOrder, setMOrder] = useState([]);
  const [defaultTemplateRefId, setDefaultTemplateRefId] = useState(dTemplateRefId);

  useEffect(() => setDefaultTemplateRefId(dTemplateRefId), [dTemplateRefId]);

  const onSetDefaultTemplate = useCallback(
    (newDefaultTemplateRefId, shouldRemove) => {
      if (shouldRemove) {
        updateDefaultTemplate(undefined);
        setDefaultTemplateRefId(undefined);
        return;
      }
      updateDefaultTemplate(newDefaultTemplateRefId);
      setDefaultTemplateRefId(newDefaultTemplateRefId);
    },
    [updateDefaultTemplate],
  );

  const notificationRef = useRef(null);

  useEffect(() => {
    order && setMOrder(order);
  }, [order]);

  const sortedTemplates = useMemo(() => getSortedTemplates(templates, mOrder), [templates, mOrder]);

  const hidePopover = useCallback(() => setAnchor(null), []);

  const handleTemplateSelect = useCallback(
    (data) => {
      onTemplateSelect(data);
      hidePopover();
    },
    [onTemplateSelect],
  );

  const handleCreateNewTemplate = useCallback(
    (title, overwriteData) => {
      onTemplateSave(folderId, title, overwriteData);
      hidePopover();
    },
    [onTemplateSave, folderId],
  );

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
    setCurrentTemplate(defaultTemplateValue);
  };

  const handleConfirm = () => {
    onDeleteTemplate(currentTemplate.mId, currentTemplate.mRefId, currentTemplate.mContentKey);
  };

  const templateOrder = useMemo(() => sortedTemplates.map((f) => f.mRefId), [sortedTemplates]);

  const renderItem = useCallback(
    (renderItemProps) => {
      const template = sortedTemplates.find((t) => t?.mRefId === renderItemProps.id);
      if (!template) return null;
      return (
        <Template
          template={template}
          disabled={disabled}
          onClick={(mContentKey) => handleTemplateSelect({ mContentKey })}
          canCreateNewTemplate={canCreateNewTemplate}
          canDeleteTemplate={canDeleteTemplate}
          onDelete={() => {
            handleOpenDialog();
            setCurrentTemplate(template);
          }}
          handleCreateNewTemplate={handleCreateNewTemplate}
          canReorderTemplates={canReorderTemplates}
          canSetDefaultTemplate={canSetDefaultTemplate}
          isDefaultTemplate={template?.mRefId === defaultTemplateRefId}
          onSetDefaultTemplate={onSetDefaultTemplate}
          isContentLoaded={isContentLoaded}
          {...renderItemProps}
        />
      );
    },
    [
      sortedTemplates,
      disabled,
      handleTemplateSelect,
      canCreateNewTemplate,
      canDeleteTemplate,
      handleCreateNewTemplate,
      canReorderTemplates,
      canSetDefaultTemplate,
      defaultTemplateRefId,
      onSetDefaultTemplate,
      isContentLoaded,
    ],
  );

  return (
    <>
      {canReorderTemplates && !anchor && sortedTemplates.length > 0 && (
        <NotificationPopup
          useHighlight
          position="left"
          anchor={notificationRef}
          id={notificationIdentifiers.ReorderTemplates}
          title="Did you know?"
          text="You can now reorder templates within the folder."
        />
      )}
      <List disablePadding ref={notificationRef}>
        <SortableList order={templateOrder} updateOrder={updateMOrder} renderItem={renderItem} />
      </List>
      {openDialog && (
        <Popover anchorEl={anchorEl} onClose={handleDialogClose} noMargin>
          <ConfirmationComponent
            label={currentTemplate.mTitle}
            onOk={handleConfirm}
            onCancel={handleDialogClose}
          />
        </Popover>
      )}
      {canCreateNewTemplate && (
        <>
          <Divider />
          <Tooltip
            title={
              !isContentLoaded
                ? 'Load content first before you create new template'
                : 'Save as new template'
            }
          >
            <ListItem button onClick={() => setAnchor(anchorEl)} disabled={!isContentLoaded}>
              <AddIcon />
              <ListItemText primary="Save As New Template..." />
            </ListItem>
          </Tooltip>
        </>
      )}
      <Popover
        anchorEl={anchor}
        onClose={hidePopover}
        transformOrigin={transformOrigin}
        anchorOrigin={anchorOrigin}
        noMargin
      >
        <CreateNew
          variant="template"
          onCancel={hidePopover}
          onOk={handleCreateNewTemplate}
          data={templates}
        />
      </Popover>
    </>
  );
}

SubMenu.propTypes = {
  /** List of templates to be shown */
  templates: PropTypes.arrayOf(PropTypes.any),
  /** Node for the popover to attach to */
  anchorEl: PropTypes.instanceOf(Element),
  /** Id of the folder */
  folderId: PropTypes.string,
  /** Callback to be invoked when a template is selected */
  onTemplateSelect: PropTypes.func,
  /** Callback to be invoked when new template is saved */
  onTemplateSave: PropTypes.func,
  /** Callback to be invoked when a template is deleted */
  onDeleteTemplate: PropTypes.func,
  /** boolean to disable the item */
  disabled: PropTypes.bool,
  /** boolean that hides create new template from menu */
  canCreateNewTemplate: PropTypes.bool,
  canDeleteTemplate: PropTypes.bool,
  mOrder: PropTypes.any,
  updateMOrder: PropTypes.func,
  canReorderTemplates: PropTypes.bool,
  canSetDefaultTemplate: PropTypes.bool,
};

SubMenu.defaultProps = {
  templates: [],
  anchorEl: null,
  folderId: null,
  onTemplateSelect: () => {},
  onTemplateSave: () => {},
  onDeleteTemplate: () => {},
  disabled: false,
  canCreateNewTemplate: false,
};

export default SubMenu;
