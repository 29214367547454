import { useState, useContext } from 'react';
import configCtx from 'contexts/configContext';
import { useQuery, useMutation } from '@apollo/client';
import UPDATE_FORM from 'graphql/mutations/updateForm';
import GET_METADATA_FORMS from 'graphql/queries/getMetadataForms';
import { saveMetaFormOnContext, getGridViewForms } from 'graphql/global-functions/utils';
import { useRundownGridViewList } from 'screens/rundown/store';

import getUpdatedRundownGridViewsInForm from '../utils/getUpdatedRundownGridView';

const updateFormInContext = (updatedForm, data, config) => {
  const getMetadataForms = data?.getMetadataForms.map((metadataForm) => {
    if (metadataForm.mRefId === 'form-00') {
      return updatedForm;
    }
    return metadataForm;
  });
  saveMetaFormOnContext({ data: { getMetadataForms } }, config);
};

const useUpdateRundownGridViews = () => {
  const config = useContext(configCtx);
  const [updateForm] = useMutation(UPDATE_FORM);
  const [loading, setLoading] = useState(false);
  const [, setRundownGridViewList] = useRundownGridViewList();
  const { data } = useQuery(GET_METADATA_FORMS, {
    variables: {
      input: {
        mId: 'form',
      },
    },
    fetchPolicy: 'cache-only',
  });

  const updateRundownGridViews = async (form, columnsViews, gridViews) => {
    const { views, defaultViews } = getUpdatedRundownGridViewsInForm(form, columnsViews, gridViews);

    const mProperties = {
      ...form.mProperties,
      views,
      defaultViews,
    };

    setLoading(true);
    const promise = await updateForm({
      variables: {
        input: {
          mId: 'form',
          mRefId: 'form-00',
          mProperties,
        },
      },
    });

    updateFormInContext(promise?.data?.updateForm, data, config);
    setRundownGridViewList(
      getGridViewForms({ data: { getMetadataForms: [promise?.data?.updateForm] } }),
    );
    setLoading(false);

    return promise;
  };

  return [updateRundownGridViews, loading];
};

export default useUpdateRundownGridViews;
