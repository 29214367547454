import React, { useEffect, memo } from 'react';
import { useSlate } from 'slate-react';
import useLogger from 'utils/useLogger';
import useUploadBySignedUrl from 'hooks/useUploadBySignedUrl';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import actionTypes from 'components/editor/constants/types/actionTypes';
import ProgressBar from '../../../progressBar';

const UploadProgress = ({ fileRef, data = {}, updateEditorOnUpload, uploadFinishedCallback }) => {
  const [UploadBySignedUrl, progress, initiateUploading] = useUploadBySignedUrl();
  const logger = useLogger('Upload progress view');
  const { update } = useEditorContext();
  const editor = useSlate();

  useEffect(() => {
    const upload = async () => {
      try {
        initiateUploading(1);
        const file = fileRef?.current || {};
        const isSubtitle = file.name?.includes('.srt');

        const payload = { document: editor.children, file };
        if (!isSubtitle) payload.data = data;

        const { result, signedUrl } = await update({
          type: actionTypes.ASSET_INSERT,
          payload,
        });
        signedUrl && (await UploadBySignedUrl(signedUrl, file, uploadFinishedCallback));
        result && updateEditorOnUpload?.(result, file);
      } catch (e) {
        logger.log(e);
      }
    };

    upload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ProgressBar percentage={progress} />;
};

export default memo(UploadProgress);
