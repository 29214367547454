import { useCallback } from 'react';

import { ReactComponent as CheckIcon } from 'assets/icons/systemicons/check.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/systemicons/close_small.svg';
import useDinaNavigate from 'hooks/useDinaNavigate';
import useTabs from 'hooks/useTabs';
import { ContentTab, useCurrentTabValue } from 'store/tabs';
import capitalize from 'utils/capitalize';

import { icons } from '../../../contentTab/ContentTab';

import {
  StyledDropdownListItem as ListItem,
  StyledDropdownListItemIcons as ListItemIcons,
  StyledDropdownListItemLabel as ListItemLabel,
  StyledIconButton,
  StyledTabIconWrapper,
} from './styled';

interface Props {
  tab: ContentTab;
  visible: boolean;
  handleDropDownClose: () => void;
}

const TabItemsView = ({ tab, visible, handleDropDownClose }: Props) => {
  const { navigateTo } = useDinaNavigate();
  const currentTab = useCurrentTabValue();
  const { closeTab } = useTabs();
  const selected = tab.id === currentTab?.id;
  const Icon = icons[tab.type][selected ? 1 : 0];

  const handleClick = () => {
    if (!selected) {
      navigateTo(tab.type, tab.id);
      handleDropDownClose();
    }
  };

  const handleClose = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      closeTab(tab);
    },
    [closeTab, tab],
  );

  return (
    <ListItem button selected={selected} onClick={handleClick}>
      <StyledTabIconWrapper>
        <Icon className="skipOverride" />
      </StyledTabIconWrapper>
      <ListItemLabel>{tab.title ?? `[Untitled ${capitalize(tab.type)}]`}</ListItemLabel>
      <ListItemIcons>
        {visible && <CheckIcon />}
        <StyledIconButton onClick={handleClose}>
          <CloseIcon />
        </StyledIconButton>
      </ListItemIcons>
    </ListItem>
  );
};

export default TabItemsView;
