import styled from '@emotion/styled';

import { ReactComponent as WarningIconComponent } from 'assets/icons/systemicons/warning.svg';

type WrapperProps = {
  $show?: boolean;
};

export const Wrapper = styled('div')<WrapperProps>`
  max-width: 700px;
  height: 48px;
  border-radius: 8px;
  padding: 0 0 0 4px;
  display: ${({ $show }) => ($show ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  gap: 4px;
  box-shadow: ${({ theme }) => `0px 12px 24px 0px ${theme.palette.dina.boxShadowDark}`};
  background: ${({ theme }) => theme.palette.dina.warningBorder};
`;

export const IconWrapper = styled('div')`
  width: 32px;
  height: 32px;
`;

export const WarningIcon = styled(WarningIconComponent)`
  width: 100%;
  height: 100%;

  path {
    fill: ${({ theme }) => theme.palette.dina.blackHighEmphasis};
    fill-opacity: 1;
  }
`;

export const Description = styled('div')`
  color: ${({ theme }) => theme.palette.dina.blackHighEmphasis};
  line-height: 16px;
  font-size: 14px;
  font-weight: 500;
  max-width: 344px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
`;

export const ButtonGroupWrapper = styled('div')`
  display: flex;
  margin-left: auto;
  gap: 4px;
  padding: 4px 4px 4px 16px;

  > * {
    box-shadow: ${({ theme }) => `0px 12px 24px 0px ${theme.palette.dina.boxShadowDark}`};
    :nth-child(1) {
      width: 75px;
      padding: 0 12px 0 12px;
      border: ${({ theme }) => `1px solid ${theme.palette.dina.blackInactive}`};
      color: ${({ theme }) => theme.palette.dina.blackHighEmphasis};
    }
  }
`;
