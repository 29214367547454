import { useMemo } from 'react';
import useGetFieldsForBlock from 'hooks/useGetFieldsForBlock';

const getMetadata = (metadata) => (field) =>
  metadata?.find((meta) => meta.key === field.id) || {
    key: field.id,
    value: field.value,
  };

/**
 * Type definition for a Field
 *
 * @typedef {Object} Field
 * @property{string} key
 * @property{string} value
 */

/**
 * Hook for getting the force-publish, silent-update metadata value
 * give mMetaData
 *
 * @param mMetaData
 * @returns {[{Field},{Field}]} [force-publish, silent-update]
 */
const usePublishMetadata = (mMetaData) => {
  const [getFieldsForBlock] = useGetFieldsForBlock();

  const getPublishMetadata = getMetadata(mMetaData);

  const forcePublishField = getFieldsForBlock('force-publish', {
    id: 'force-publish',
    value: 'false',
  });

  const silentUpdateField = getFieldsForBlock('update', {
    id: 'silent-update',
    value: 'false',
  });

  return useMemo(
    () => [getPublishMetadata(forcePublishField), getPublishMetadata(silentUpdateField)],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mMetaData],
  );
};

export default usePublishMetadata;
