import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from 'components/tabs/oldTabs';
import Divider from 'components/divider';
import AppBar from '../appBar';
import useStyles from './tabs-styles';

const EmptyWrapper = ({ children }) => <>{children}</>;

const TabsView = ({
  tabItems,
  onTabChange,
  tabIndex,
  disableElevation,
  handleContactTabChange,
  ...rest
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(tabIndex || 0);

  const onChange = (tabItem, newValue) => {
    setValue(newValue);
    onTabChange(tabItem, newValue);
  };

  const Header = disableElevation ? EmptyWrapper : AppBar;

  return (
    <div className={classes.root}>
      <Header position="static" classes={{ root: classes.appBar }}>
        <Tabs tabs={tabItems} onTabChange={onChange} tabIndex={value} {...rest} />
      </Header>

      <div className={classes.divider}>
        <Divider />
      </div>

      <div className={classes.contentWrapper}>{tabItems[value].content}</div>
    </div>
  );
};

TabsView.propTypes = {
  /** Items to be shown on the tabs,
   * each items consists of an object with
   * label and content property
   */
  tabItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
      disabled: PropTypes.bool,
    }),
  ),
  /** Callback to be invoked on tab change,
   * with the selected tabItem passed in
   */
  onTabChange: PropTypes.func,
  /** Defines index value for the selected tab,
   * if not provided component acts as uncontrolled
   */
  tabIndex: PropTypes.number,
  /** When true, disables the tab label header elevation */
  disableElevation: PropTypes.bool,
};

TabsView.defaultProps = {
  tabItems: [
    { label: 'Label A', content: 'Content A' },
    { label: 'Label B', content: 'Content B' },
    { label: 'Label C', content: 'Content C', disabled: true },
  ],
  onTabChange: () => {},
  tabIndex: null,
  disableElevation: false,
};

export default TabsView;
