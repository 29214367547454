import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelected, useFocused } from 'slate-react';
import { elementTypes } from 'components/editor/constants/types';
import { RootWrapper } from './styled';

const Mention = ({ attributes, children, element }) => {
  const selected = useSelected();
  const focused = useFocused();
  const { mTitle } = element.data;

  return (
    <RootWrapper {...attributes} showBorder={selected && focused}>
      @{mTitle}
      {children}
    </RootWrapper>
  );
};

Mention.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

Mention.defaultProps = {
  attributes: {},
  children: null,
  element: {
    type: elementTypes.MENTION,
    data: { mTitle: '' },
    children: [],
  },
};

export default memo(Mention);
