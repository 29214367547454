import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette, typography }) => ({
  root: {
    height: 88,
    width: '100%',
    borderRadius: '8px 8px 0 0',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    overflow: 'hidden',
  },
  backgroundColor: {
    height: '100%',
    width: '100%',
    background: ({ backgroundColor }) => palette.dina[backgroundColor],
    opacity: ({ opacity }) => opacity,
    transition: 'background 0.5s ease-in-out',
  },
  divider: {
    width: '100%',
    background: ({ backgroundColor }) => {
      if (backgroundColor)
        return backgroundColor === 'surfaceCard'
          ? palette.dina.dividerLight
          : palette.dina[backgroundColor];
      return palette.dina.kanbanTodo;
    },
  },
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
  titleWrapper: {
    width: 'calc(100% - 16px)',
    marginLeft: '8px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: 4,
    borderBottom: `1px solid ${palette.dina.dividerLight}`,
  },
  titleBox: {
    flex: 1,
    height: 40,
  },
  scheduleDestination: {
    height: 47,
    display: 'flex',
    alignItems: 'center',
    padding: 8,
    gap: 8,
  },
  avatar: {
    width: 32,
    height: 32,
    position: 'relative',
    cursor: 'pointer',
  },
  thumbnail: {
    width: 26,
    height: 26,
    borderRadius: '2px',
    objectFit: 'cover',
  },
  publishingPoint: {
    width: 22,
    height: 22,
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
  assigneesWrapper: { marginLeft: 9.5, marginRight: 4 },
  fullWidth: { width: '100%' },
  toggleContainer: {
    marginTop: 1,
  },
  toggleEditIcon: {
    '& path': {
      fill: 'white',
      fillOpacity: 1,
    },
  },
  iconButton: {
    '&:hover': {
      cursor: 'pointer',
      '& path': {
        fill: 'white',
        fillOpacity: 1,
      },
    },
  },
}));

export default useStyles;
