/* eslint-disable react/no-unknown-property */
import { Box } from 'layouts/box/Box';

import { CommandIcons, CommandLabel, InstructionIcons, ItemTypeIcons } from './command-constants';
import { Destination, Instruction, ItemType } from './command-types';

import { ItemInner, WrappedItem } from './styled';

interface ItemProps {
  onSelect: (val: string) => void;
  value?: string;
  forceMount?: boolean;
  children?: React.ReactElement | string;
  destination?: Destination;
  instruction?: Instruction;
  type?: ItemType;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
}

const getIcon = ({
  destination,
  instruction,
  type,
}: Pick<ItemProps, 'destination' | 'instruction' | 'type'>) => {
  if (destination) {
    return CommandIcons[destination];
  }
  if (instruction) {
    return InstructionIcons[instruction];
  }
  if (type) {
    return ItemTypeIcons[type];
  }
};

export const CommandItem = ({
  onSelect,
  value,
  children,
  destination,
  instruction,
  forceMount,
  icon,
  type = 'paging',
}: ItemProps) => {
  const Icon = icon ?? getIcon({ destination, instruction, type });
  const label = destination ? CommandLabel[destination] : children;
  switch (type) {
    case 'search-item':
    case 'asset':
      return (
        <WrappedItem onSelect={onSelect} className={type} forceMount value={value}>
          {label}
        </WrappedItem>
      );
    case 'feed':
      return (
        <WrappedItem onSelect={onSelect} className={type} forceMount value={value}>
          <Box container gap="6px" height="84px" width="100%">
            {Icon && (
              <div
                style={{ width: '20px', height: '20px', display: 'flex' }}
                className="search-icon"
              >
                <Icon style={{ margin: 'auto' }} />
              </div>
            )}
            <ItemInner>{label}</ItemInner>
          </Box>
        </WrappedItem>
      );
    default:
      return (
        <WrappedItem onSelect={onSelect} className={type} forceMount={forceMount} value={value}>
          <Box container gap="6px">
            {Icon && (
              <div
                style={{ width: '20px', height: '20px', display: 'flex' }}
                className="search-icon"
              >
                <Icon style={{ margin: 'auto' }} />
              </div>
            )}
            <div>{label}</div>
          </Box>
        </WrappedItem>
      );
  }
};
