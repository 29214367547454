import { useEffect, useState, useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { differenceBy } from 'lodash';
import { useSlate, useReadOnly, useSelected } from 'slate-react';

import useGetAutomationTemplates from 'hooks/useGetAutomationTemplates';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import { onImageLoadingError } from 'utils/image/imageUtils';
import defaultImage from 'assets/images/default/defaultThumbnail.png';
import { allowsGraphics, mediaTypes } from 'utils/rundownItemTypes';
import useGetSignedUrl from 'hooks/useGetSignedUrl';
import useOpenAssetInMimir from 'hooks/useOpenAssetInMimir';
import useOpenAssetInPilotEdge from 'hooks/useOpenAssetInPilotEdge';
import useGetAsset from 'hooks/useGetAsset';
import { getThumbnailKey } from 'utils/mediaKey';
import { isMimirAssetItem, openPreviewInProvider } from 'utils/openAssetInMimir';
import useCheckUserRight from 'hooks/useCheckUserRight';

import { elementTypes } from 'components/editor/constants/types';
import AddSrc from 'assets/icons/systemicons/placeholders_add.svg';
import PlaySrc from 'assets/icons/systemicons/Play_WithCircleBackground_small.svg';
import PhotoSrc from 'assets/icons/systemicons/photo.svg';
import HourglassSrc from 'assets/icons/systemicons/hourglass.svg';
import { ReactComponent as NotesOn } from 'assets/icons/systemicons/notes_on.svg';

import SelectedElement from 'components/editor/components/selectedElement';
import PlaceholderDialog from 'components/editor/components/placeholderDialog';
import removePlaceholder from 'components/editor/components/placeholderDialog/utils/removePlaceholder';
import { refreshSelection } from 'components/editor/utils';
import DragAndDrop from '../dragAndDrop';
import PrimaryDropZone from './components/primaryDropZone';
import MediaDropZone from './components/mediaDropZone';
import AutomationIcon from './components/automationIcon';
import Select from './components/select';
import Menu from '../menu';
import Title from './components/title';
import MediaDialog from './components/mediaDialog';
import DetailsDialog from './components/detailsDialog';

import getInitialData from './utils/getInitialData';
import syncAssetWithSlate from './utils/syncAssetWithSlate';
import { updateElement, updateScript } from './utils/updateElement';

import Script from './components/scriptEditor';
import {
  BoxWrapper,
  ContentWrapper,
  Icon,
  PlaceholdersWrapper,
  RootWrapper,
  Thumbnail,
  ThumbnailWrapper,
  TitleWrapper,
  ScriptIconWrapper,
} from './styled';

const { PACKAGE, ADLIB, VOICE_OVER } = elementTypes;
const videoAutomationTypes = [PACKAGE, ADLIB, VOICE_OVER];

const PrimaryAutomation = ({ attributes, children, element }) => {
  const editor = useSlate();
  const { update, withSignedUrl } = useEditorContext();
  const readOnly = useReadOnly();
  const isSelected = useSelected(element);
  const [checkUserRight] = useCheckUserRight();
  const hasScriptPermission = checkUserRight('feature', 'script') && element.type === 'package';

  const [showScriptView, setShowScriptView] = useState(false);
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const [placeholderDialogOpen, setPlaceholderDialogOpen] = useState(false);
  const [mediaDialogOpen, setMediaDialogOpen] = useState(false);

  const { data, type } = element;
  const initialData = useMemo(() => getInitialData(data), [data]);
  const { assets = [], templateVariant, name, itemId, script } = initialData;

  const isVideoAutomation = videoAutomationTypes.some((automation) => automation === type);

  const { templates } = useGetAutomationTemplates();
  const template = useMemo(
    () =>
      templates && templates.find((item) => item.type === type && item.variant === templateVariant),
    [templateVariant, templates, type],
  );
  const { video = false, graphics = false, image = false } = template?.requires || {};

  const graphicsAsset = assets.find((asset) => asset.mediaType === mediaTypes.GRAPHICS);
  const hasGraphicsAsset = Boolean(graphicsAsset);

  const [clipAsset] = differenceBy(assets, graphicsAsset ? [graphicsAsset] : [], 'mAssetId');
  const hasClipAsset = Boolean(clipAsset);

  const { mId, mRefId, mAssetId } = clipAsset ?? {};
  const hasAssetId = Boolean(mAssetId);
  const [newAssetData] = useGetAsset(mId, mRefId, false);
  const openAssetInMimir = useOpenAssetInMimir();
  const openAssetInPilotEdge = useOpenAssetInPilotEdge();

  useEffect(() => {
    if (newAssetData) syncAssetWithSlate(editor, element, initialData, newAssetData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newAssetData]);

  const placeholder = useMemo(
    () => assets.find(({ mediaType }) => mediaType === 'video/placeholder'),
    [assets],
  );

  const hasPlaceholder = Boolean(placeholder);

  const memoizedKey = useMemo(() => mId && mRefId && getThumbnailKey(mId, mRefId), [mId, mRefId]);
  const { url: thumbSrc } = useGetSignedUrl(memoizedKey, false);

  const showCreatePlaceholderOption = Boolean(video) || isVideoAutomation;
  const showEditGraphicsOption = Boolean(graphics) || allowsGraphics(type);
  const showOpenInMimir = isMimirAssetItem(element);

  // following - 'isMImirProvider' this will be handled in another ticket.
  // ignore, it doesn't have any impact
  const isMimirProvider = true;

  const hasScript = useMemo(() => {
    if (script && script.document) {
      if (script.document.length > 1) return true;
      if (script.document[0].children.length > 1) return true;
      if (script.document[0].children[0].text !== '') return true;
      return false;
    }
    return false;
  }, [script]);

  const menuItems = useMemo(
    () => [
      {
        title: 'Details...',
        action: 'show-details',
        divider: !(hasScriptPermission && script),
      },
      ...(hasScriptPermission && script
        ? [
            {
              title: 'Block Note/Script',
              action: 'show-script',
              divider: true,
            },
          ]
        : []),
      ...(showOpenInMimir || thumbSrc
        ? [
            {
              title: 'Open asset',
              action: 'open-in-mimir',
            },
          ]
        : []),
      ...(hasPlaceholder && isMimirProvider && withSignedUrl
        ? [
            {
              title: 'Upload in Mimir',
              action: 'upload-in-mimir',
            },
          ]
        : []),

      ...(showCreatePlaceholderOption
        ? [
            {
              ...(hasPlaceholder
                ? {
                    title: 'Remove Placeholder',
                    action: 'remove-placeholder',
                  }
                : { title: 'Create Placeholder', action: 'create-placeholder' }),
            },
          ]
        : []),
      ...(showEditGraphicsOption && hasGraphicsAsset
        ? [
            {
              title: 'Edit graphics',
              action: 'edit-graphics',
            },
          ]
        : []),
    ],
    [
      hasPlaceholder,
      showCreatePlaceholderOption,
      showEditGraphicsOption,
      showOpenInMimir,
      thumbSrc,
      isMimirProvider,
      withSignedUrl,
      hasScriptPermission,
      script,
      hasGraphicsAsset,
    ],
  );

  /* Determine when to show add button, functionality to be implemented */
  const showAdd = false;

  const openDetailsDialog = useCallback(() => setDetailsDialogOpen(true), []);

  const closeDetailsDialog = useCallback(() => setDetailsDialogOpen(false), []);

  const openScriptDialog = useCallback(() => setShowScriptView(true), []);

  const closeScriptDialog = useCallback(() => setShowScriptView(false), []);

  const openPlaceholderDialog = useCallback(() => setPlaceholderDialogOpen(true), []);

  const closePlaceholderDialog = useCallback(() => setPlaceholderDialogOpen(false), []);

  const openMediaDialog = useCallback(
    (e) => {
      if (openPreviewInProvider(clipAsset.mProvider)) {
        return handleOpenInMimir(clipAsset);
      }
      e.stopPropagation();
      setMediaDialogOpen(true);
    },
    [clipAsset],
  );

  const closeMediaDialog = useCallback(() => setMediaDialogOpen(false), []);
  const getValidProvider = (provider) => {
    switch (provider) {
      case 'PILOT':
      case 'XPRESSION':
      case 'xpression.mos':
        return true;
      default:
        return false;
    }
  };
  const handleEditGraphics = () => {
    if (!hasGraphicsAsset) return;
    if (!getValidProvider(graphicsAsset.provider)) return;
    if (graphicsAsset?.mosobj) {
      openAssetInPilotEdge(graphicsAsset?.mosobj, element);
    }
  };

  const handleOpenInMimir = (asset) => {
    openAssetInMimir({ data: { assets: [{ ...asset }] } });
  };

  const onBlur = useCallback(() => {
    refreshSelection(editor, element);
  }, [editor, element]);

  const onMenuSelect = useCallback(
    ({ action }) => {
      if (action === 'show-details') openDetailsDialog();

      if (action === 'show-script') openScriptDialog();

      if (action === 'edit-graphics') handleEditGraphics();

      if (action === 'create-placeholder') openPlaceholderDialog();

      if (action === 'remove-placeholder')
        removePlaceholder(editor, initialData, placeholder, update);

      if (action === 'open-in-mimir') {
        if (!hasAssetId) {
          syncAssetWithSlate(editor, element, initialData, newAssetData);
          handleOpenInMimir({
            ...newAssetData,
            mProvider: newAssetData.mMetaData.reduce(
              (accumulator, currentValue) =>
                currentValue.key === 'provider' ? currentValue.value : accumulator,
              '',
            ),
            mediaType: 'video/placeholder',
          });
        } else handleOpenInMimir(clipAsset);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      initialData,
      placeholder,
      openDetailsDialog,
      openScriptDialog,
      openPlaceholderDialog,
      newAssetData,
      editor,
      clipAsset,
    ],
  );

  const onSaveScriptView = (updatedScriptValue) => {
    updateScript(editor, data, updatedScriptValue);
    closeScriptDialog();
  };

  const getAssetIcon = (itemType) => (itemType === mediaTypes.IMAGE ? PhotoSrc : PlaySrc);

  return (
    <div {...attributes} onBlur={onBlur}>
      <DragAndDrop element={element} hideHighlight>
        <SelectedElement element={element}>
          <PrimaryDropZone element={element}>
            <MediaDropZone
              element={element}
              canDropVideo={Boolean(video)}
              canDropImage={Boolean(image)}
              canDropGraphics={Boolean(graphics)}
            >
              {children}
              <RootWrapper contentEditable={false} readOnly={readOnly}>
                <BoxWrapper $isSelected={isSelected} $readOnly={readOnly}>
                  <AutomationIcon type={type} />
                  <ContentWrapper>
                    <Select initialData={initialData} element={element} />
                    <TitleWrapper>
                      <Title {...{ initialData, type, templates, template }} />
                    </TitleWrapper>
                    <PlaceholdersWrapper
                      hasPlaceholder={hasPlaceholder}
                      hasScript={hasScriptPermission && hasScript}
                    >
                      {element.type === 'package' && (
                        <ScriptIconWrapper>
                          {hasScriptPermission && hasScript && <NotesOn />}
                        </ScriptIconWrapper>
                      )}
                      {(showAdd || clipAsset) && (
                        <>
                          {showAdd && <img alt="add" src={AddSrc} />}
                          {hasPlaceholder && (
                            <ThumbnailWrapper
                              onClick={thumbSrc ? openMediaDialog : null}
                              onMouseEnter={(e) => {
                                if (readOnly) e.stopPropagation();
                              }}
                            >
                              {thumbSrc ? (
                                <>
                                  <Thumbnail
                                    alt="placeholder"
                                    src={thumbSrc}
                                    onError={onImageLoadingError}
                                  />
                                  <Icon alt="play" src={getAssetIcon(clipAsset.itemType)} />
                                </>
                              ) : (
                                <Icon alt="placeholder" src={HourglassSrc} />
                              )}
                            </ThumbnailWrapper>
                          )}
                          {!hasPlaceholder && hasClipAsset && (
                            <ThumbnailWrapper onClick={openMediaDialog}>
                              <Thumbnail
                                src={clipAsset.thumbnailUrl || defaultImage}
                                alt="asset"
                                onError={onImageLoadingError}
                              />
                              <Icon alt="play" src={getAssetIcon(clipAsset.itemType)} />
                            </ThumbnailWrapper>
                          )}
                        </>
                      )}
                    </PlaceholdersWrapper>
                    <Menu
                      className="primaryAutomationMenu"
                      items={menuItems}
                      onSelect={onMenuSelect}
                    />
                  </ContentWrapper>
                </BoxWrapper>
              </RootWrapper>
            </MediaDropZone>
          </PrimaryDropZone>
        </SelectedElement>
      </DragAndDrop>

      {detailsDialogOpen && (
        <DetailsDialog
          open={detailsDialogOpen}
          onClose={closeDetailsDialog}
          {...{ initialData, type, element }}
        />
      )}

      {placeholderDialogOpen && (
        <PlaceholderDialog
          open={placeholderDialogOpen}
          onClose={closePlaceholderDialog}
          {...{ element }}
        />
      )}

      {mediaDialogOpen && (
        <MediaDialog asset={clipAsset} open={mediaDialogOpen} onClose={closeMediaDialog} />
      )}
      {showScriptView && (
        <Script
          isOpen={showScriptView}
          onClose={closeScriptDialog}
          onSave={onSaveScriptView}
          scriptBlock={{ id: itemId, name, script }}
        />
      )}
    </div>
  );
};

PrimaryAutomation.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

PrimaryAutomation.defaultProps = {
  attributes: {},
  children: null,
  element: {
    type: elementTypes.CAMERA,
    children: [],
  },
};

export default memo(PrimaryAutomation);
