import { useRightHidden, useRightSelection } from 'store/sidebar';
import SelectionTypes from 'screens/main/components/rightArea/selectionTypes';
import { postMosMessage } from 'utils/postMessage';

/**
 * Hook for opening MOS asset item in PILOT EDGE in right sidebar
 * @returns {function(): void} - returns a function that opens the Asset
 */
const useOpenAssetInPilotEdge = () => {
  const [, setRightHidden] = useRightHidden();
  const [, setRightSelection] = useRightSelection();
  /**
   *
   * @param {Object} mosObj object to open asset
   * @returns
   */
  const openInPilotEdge = (mosObj, element) => {
    if (!mosObj) return;
    postMosMessage(mosObj, 'pilotedge', element);
    setRightHidden(false);
    setRightSelection(SelectionTypes.GRAPHICSBOX);
  };
  return openInPilotEdge;
};

export default useOpenAssetInPilotEdge;
