/* eslint-disable sort-imports */
import { useCallback, useContext } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

import UserContext from 'contexts/UserContext';
import { ORDER_PROPS, writeOrderToCache } from 'screens/space/api/useGetOrdersAndForms';
import type { Metadata, RawOrder } from 'types/forms/forms';
import { createOrder } from 'types/forms/forms';
import { CrudActionEnum, TaskStatusEnum } from 'types/graphqlTypes';

const CREATE_ORDER = gql`
  mutation CreateOrder($input: CreateOrderInput) {
    createOrder(input: $input) {
      ${ORDER_PROPS}
    }
  }
`;

interface CreateRawOrder {
  createOrder: RawOrder;
}

const useCreateOrder = () => {
  const [createNewOrder] = useMutation<CreateRawOrder>(CREATE_ORDER);
  const client = useApolloClient();
  const user = useContext(UserContext);

  const create = useCallback(
    async (
      resourceId: string,
      mFormId: string,
      metadata: Metadata,
      assigneeId?: string,
    ): Promise<RawOrder | null> => {
      const userId = user?.mId ?? 'unknown-user';
      const newOrder = createOrder(resourceId, mFormId, userId, metadata, assigneeId);

      const result = await createNewOrder({
        variables: { input: newOrder },
        update: (_, resultOrder) => {
          if (resultOrder?.data) {
            writeOrderToCache(
              client,
              resourceId,
              resultOrder.data.createOrder,
              TaskStatusEnum.all,
              CrudActionEnum.Create,
            );
          }
        },
      });

      return result?.data?.createOrder ?? null;
    },
    [],
  );

  return [create];
};

export default useCreateOrder;
