/* eslint-disable react/prop-types */
import React, { MouseEventHandler } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { ReactComponent as Close } from 'assets/icons/systemicons/close.svg';
import Avatar, { AvatarVariant } from 'components/avatar/Avatar';
import Tooltip from 'components/tooltip';
import { AssignedMember } from 'types/members';

const MemberWrapper = styled('div')<{ variant: 'grid' | 'form' | 'small' }>`
  display: flex;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  ${({ variant, theme }) => css`
    gap: ${variant === 'small' ? '4px' : '8px'};
    height: ${variant === 'small' ? '16px' : '30px'};
    background: ${variant === 'form' ? theme.palette.dina.buttonBackgroundOutlined : 'transparent'};
    :hover {
      span {
        color: ${theme.palette.dina.highEmphasis} !important;
      }
    }
    .member-details {
      font-size: 13px;
    }
    border: 1px solid ${variant === 'form' ? theme.palette.dina.inputBorderResting : 'transparent'};
    padding-left: ${variant === 'form' ? '8px' : 0};
  `}
`;

const Details = styled.div`
  width: 100%;
  display: flex;
  white-space: pre;
  flex-direction: column;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.dina.highEmphasis};
  div:last-child {
    font-size: 11px;
  }
`;

const CloseIcon = styled(Close)`
  cursor: pointer;
  &:hover path {
    fill-opacity: 1;
  }
  justify-self: end;
  margin-right: 4px;
`;

const Placeholder = styled.div`
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
`;

interface Props {
  member: AssignedMember | null;
  variant: 'grid' | 'form' | 'small';
  style?: React.CSSProperties;
  onClick?: MouseEventHandler;
  onRemoveClick?: MouseEventHandler;
  readOnly?: boolean;
}

const MemberLabel: React.FC<Props> = ({
  member,
  variant,
  onClick,
  onRemoveClick,
  style,
  readOnly,
}) => {
  return (
    <MemberWrapper onClick={onClick} variant={variant} style={style} className="member-wrapper">
      {member ? (
        <>
          <Avatar
            className="avatar"
            variant={member.mType as AvatarVariant}
            src={member.mAvatarUrl}
            size={variant === 'small' ? 16 : 24}
            title={member.mTitle}
          />
          <Details className="member-details">{member?.mTitle}</Details>
          {onRemoveClick && !readOnly && (
            <Tooltip title="Remove assignee">
              <CloseIcon onClick={onRemoveClick} />
            </Tooltip>
          )}
        </>
      ) : (
        <Placeholder>{readOnly ? 'Unknown' : 'Find member'}</Placeholder>
      )}
    </MemberWrapper>
  );
};

export default MemberLabel;
