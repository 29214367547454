import { useEffect } from 'react';
import { Editor } from 'slate';

import notifyChange from 'components/editor/utils/notifyChange';
import useToast from 'components/toast/useToast';
import useSettingsValue from 'hooks/useSettingsValue';
import { useMosItemReplace } from 'store/scratchpad';
import { CustomElement } from 'types';

import addMos from '../components/mos/components/mosDropZone/utils/addMos';
import addGraphics from '../components/primaryAutomation/components/mediaDropZone/utils/addGraphics';
import replaceGraphics from '../components/secondaryAutomation/components/graphicsDropZone/utils/replaceGraphics';
import { elementTypes } from '../constants';
import { Update } from '../EditorContext';

export type MosItem = {
  iconUrl: string;
  item: string;
  itemId: string;
};

const { FULLSCREEN_GRAPHICS, DVE, OVERLAY_GRAPHICS, TELEPHONE, MOS } = elementTypes;

const isPrimaryGraphicsType = (type: string) =>
  type === FULLSCREEN_GRAPHICS || type === DVE || type === TELEPHONE;

const isSecondaryGraphicsType = (type: string) => type === OVERLAY_GRAPHICS;

const isMosType = (type: string) => type === MOS;

const getElementsToUpdate = (mosItemReplace: MosItem, editor: Editor) => {
  const { itemId } = mosItemReplace;
  const elements = (editor.children as CustomElement[]).filter((el) => el?.data?.itemId === itemId);
  return elements ?? [];
};

/**
 * Handles replacing MoS items in the editor.
 *
 * @param editor - The editor instance.
 * @param update - The update object.
 * @param readOnly - Whether the editor is in read-only mode.
 * @param variant - The variant of the editor (e.g., "linear").
 */
const useMosItemReplaceHandler = (
  editor: Editor,
  update: Update,
  readOnly: boolean,
  variant: string,
) => {
  const [mosItemReplace] = useMosItemReplace() as unknown as [MosItem];
  const [getSettingsValue] = useSettingsValue();
  const allowMosItemReplace = getSettingsValue('mos.usemositemreplace') === 'true';
  const { errorToast } = useToast();

  useEffect(() => {
    if (!allowMosItemReplace || !mosItemReplace || readOnly || variant !== 'linear') return;
    const { item, iconUrl } = mosItemReplace;

    if (!item) return;

    const elements = getElementsToUpdate(mosItemReplace, editor);
    if (!elements.length) return;

    elements.forEach((element) => {
      const { type } = element;
      if (isSecondaryGraphicsType(type)) {
        replaceGraphics(editor, element, item, update);
        return;
      }

      if (isPrimaryGraphicsType(type)) {
        addGraphics(editor, element, item, true, update, errorToast).then(
          () => {},
          () => {},
        );
        return;
      }

      if (isMosType(type)) {
        addMos(editor, element, item, iconUrl, update);
      }
    });

    notifyChange(editor, update);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mosItemReplace]);
};

export default useMosItemReplaceHandler;
