import { useQuery } from '@apollo/client';

import GET_USERS from 'graphql/queries/getAllDbUsers';
import { MemberType } from 'types/graphqlTypes';

interface QueryUsersList {
  getUsers: MemberType[];
}

const useGetUsers = () => {
  const { data, error, loading } = useQuery<QueryUsersList>(GET_USERS, {
    fetchPolicy: 'cache-and-network',
  });
  return { data, error, loading };
};

export default useGetUsers;
