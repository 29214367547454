import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import checkIsNotificationConversation from 'utils/messageHub/checkIsNotificationConversation';
import checkIsSystemMessage from 'utils/messageHub/checkIsSystemMessage';
import infoImage from 'assets/icons/systemicons/info_off.svg';
import MessageInput from 'components/messageInput';
import { getInfoFromContent, getInfoFromMessage } from 'utils/messageHub/getInfoFromContent';
import MessageRow from './components/messageRow';
import InfoRow from './components/infoRow';
import { MessageBubbleWrapper, MessageEditContainer } from './messageBubble-styled';

const MessageBubble = ({
  userId,
  message,
  onDelete,
  getUser,
  messageSeen,
  showEditButton,
  showDeleteButton,
  openStory,
  onUpdateMessage,
  suggestedUsers,
}) => {
  const {
    mId,
    mRefId,
    mCreatedById,
    mUpdatedById,
    mCreatedAt,
    mUpdatedAt,
    mContent: content,
    convoType,
    mTitle,
  } = message;

  const assignedByUser = getUser(mUpdatedById);

  const isNotificationMessage = checkIsNotificationConversation(convoType);
  const [isEditingMessage, setIsEditingMessage] = useState(false);

  const { mContent, mStoryId, page, instanceId, publishingAt } = isNotificationMessage
    ? getInfoFromContent(content, convoType, mTitle, assignedByUser?.mTitle)
    : getInfoFromMessage(content, convoType);

  const isSystemMessage = checkIsSystemMessage(message);
  const isSelfMessage = !isNotificationMessage ? userId === (mCreatedById || mUpdatedById) : false;
  const { mTitle: senderName, mAvatarUrl: senderAvatarUrl } =
    isNotificationMessage || isSystemMessage
      ? { mTitle: 'Dina', mAvatarUrl: infoImage }
      : getUser(mCreatedById || mUpdatedById) || {};

  const handleDelete = async () => {
    try {
      await onDelete(mId, mRefId);
    } catch (e) {
      // console.log(e);
    }
  };

  const handleEdit = () => {
    setIsEditingMessage(true);
  };

  const handleUpdateMessage = async (newContent) => {
    try {
      await onUpdateMessage(mRefId, newContent);
      setIsEditingMessage(false);
    } catch (e) {
      // console.log(e);
    }
  };

  const handleCancelEdit = () => {
    setIsEditingMessage(false);
  };

  const handleOpenStory = () => {
    mStoryId && openStory(mTitle, mStoryId, page, instanceId, publishingAt, convoType);
  };

  return isEditingMessage ? (
    <MessageEditContainer>
      <MessageInput
        messageValue={JSON.parse(mContent)}
        handleSaveMessage={handleUpdateMessage}
        handleCancelEdit={handleCancelEdit}
        isEditingMessage={isEditingMessage}
        setIsEditingMessage={setIsEditingMessage}
        showDoneButton={false}
        users={suggestedUsers}
        keepFocus={false}
      />
    </MessageEditContainer>
  ) : (
    <MessageBubbleWrapper isSelfMessage={isSelfMessage} id={mRefId}>
      <MessageRow
        mContent={mContent}
        mAvatarUrl={senderAvatarUrl}
        mTitle={senderName}
        isSelfMessage={isSelfMessage}
        showEditButton={showEditButton}
        isEditingMessage={isEditingMessage}
        handleCancelEdit={handleCancelEdit}
      />
      <InfoRow
        onDelete={handleDelete}
        onEdit={handleEdit}
        mCreatedAt={mCreatedAt}
        mUpdatedAt={mUpdatedAt}
        isSelfMessage={isSelfMessage}
        senderName={senderName}
        messageSeen={messageSeen}
        userId={userId}
        showDeleteButton={showDeleteButton}
        showEditButton={showEditButton}
        canOpenStory={!!mStoryId}
        onOpen={handleOpenStory}
      />
    </MessageBubbleWrapper>
  );
};

MessageBubble.propTypes = {
  /** Object containing message data */
  message: PropTypes.shape({
    mId: PropTypes.string,
    mRefId: PropTypes.string,
    mCreatedAt: PropTypes.string,
    mContent: PropTypes.string,
    mCreatedById: PropTypes.string,
    mUpdatedById: PropTypes.string,
    convoType: PropTypes.string,
    mTitle: PropTypes.string,
    mUpdatedAt: PropTypes.string,
  }),
  /** Id of the user */
  userId: PropTypes.string,
  /** callback function to delete message */
  onDelete: PropTypes.func,
  /** callback to get the user data */
  getUser: PropTypes.func,
  /** boolean to show/hide edit button */
  showEditButton: PropTypes.bool,
  /** boolean to show/hide delete button */
  showDeleteButton: PropTypes.bool,
  /** list of seen info for the message */
  messageSeen: PropTypes.arrayOf(PropTypes.shape({})),
  /** callback to open story */
  openStory: PropTypes.func,
  onUpdateMessage: PropTypes.func,
  suggestedUsers: PropTypes.arrayOf(PropTypes.shape({})),
};

MessageBubble.defaultProps = {
  message: {},
  userId: '',
  onDelete: () => {},
  getUser: () => {},
  showEditButton: true,
  showDeleteButton: true,
  messageSeen: [],
  openStory: () => {},
};

export default memo(MessageBubble);
