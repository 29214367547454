import { Dispatch, SetStateAction, useCallback } from 'react';
import { useMutation } from '@apollo/client';

import memberTypes from 'graphql/memberTypes';
import DELETE_USER from 'graphql/mutations/deleteUser';
import GET_ALL_USERS from 'graphql/queries/getAllDbUsers';
import { useMembers } from 'store';
import { MemberType } from 'types/graphqlTypes';
import useLogger from 'utils/useLogger';

interface QueryUsersList {
  getUsers: MemberType[];
}

interface DeleteUserType {
  deleteUser: MemberType;
}

const useDeleteUser = () => {
  const logger = useLogger('DeleteUser');
  const [deleteUser] = useMutation<DeleteUserType>(DELETE_USER);
  const [members, setMembers] = useMembers();

  const handleDelete = useCallback(
    async (
      mId: string,
      mRefId: string,
      username: string,
      setOpen: Dispatch<SetStateAction<boolean>>,
      setErrorText?: Dispatch<SetStateAction<string>>,
    ) => {
      const variables = {
        input: {
          mId,
          mRefId,
          username,
        },
      };

      await deleteUser({
        variables,
        onError: (error) => {
          logger.log(error);
          if (setErrorText) setErrorText('Could not delete user. Please try again');
        },
        update: (client) => {
          try {
            setMembers({
              ...members,
              [memberTypes.USER]:
                members[memberTypes.USER]?.filter((member) => member.mId !== mId) ?? [],
            });
            const usersList = client.readQuery({
              query: GET_ALL_USERS,
            }) as QueryUsersList;
            if (!Array.isArray(usersList?.getUsers)) return;
            const filteredUsers = usersList.getUsers.filter((user) => {
              return user.mId !== mId;
            });
            client.writeQuery({
              query: GET_ALL_USERS,
              data: {
                getUsers: filteredUsers,
              },
            });
            setOpen(false);
          } catch (err) {
            logger.log(err);
          }
        },
      });
    },
    [deleteUser, logger, members, setMembers],
  );
  return handleDelete;
};

export default useDeleteUser;
