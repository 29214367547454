import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'components/tooltip';
import { FormControlLabel } from '@material-ui/core';
import Checkbox from 'components/checkbox';
import { FieldHeader } from '../../styled';
import BooleanWrapper from './styled';

function BooleanField({ fieldModel, value, setValue, style }) {
  const { label, id } = fieldModel;
  return (
    <Tooltip title={fieldModel.description ?? ''}>
      <BooleanWrapper key={id} style={style}>
        <FieldHeader style={{ position: 'relative', top: '4px' }}>{fieldModel.label}</FieldHeader>
        <FormControlLabel
          control={<Checkbox checked={value} onClick={() => setValue(!value)} />}
          label={label}
        />
      </BooleanWrapper>
    </Tooltip>
  );
}

BooleanField.propTypes = {
  fieldModel: PropTypes.shape({
    type: PropTypes.oneOf(['boolean', 'select', 'text']),
    id: PropTypes.string,
    description: PropTypes.string,
  }),
  value: PropTypes.bool,
  setValue: PropTypes.func,
};

BooleanField.defaultProps = {
  fieldModel: null,
  value: null,
  setValue: () => {},
};

export default BooleanField;
