import React, { useMemo, useState, useRef, useEffect } from 'react';

import useGetPlatforms from 'hooks/useGetPlatforms';
import {
  useLeftSidebarSavedSearches,
  useSidebarDatePickerAtom,
  useSidebarSelectedSubtab,
  subtabs,
  scheduleTypes,
} from 'store/sidebar';
import useCheckUserRight from 'hooks/useCheckUserRight';

import { FormControlLabel } from '@material-ui/core';
import Divider from 'components/divider';
import Searchbar from 'components/searchbar';
import Switch from 'components/switch';
import Tabs from 'components/tabs/oldTabs';
import Scrollbar from 'components/scrollbar';
import DatePickerButton from '../stories/datePicker';
import InstanceListContainer from './list';
import useStyles from './instances-styles';
import Text from 'components/text/Text';

const InstanceContainer = ({
  filterModel,
  updateFilterModel,
  history,
  updateHistory,
  currentSearch,
  updateCurrentSearch,
  searchString,
  assignedMembers,
  platforms,
  platformsFilter,
}) => {
  const classes = useStyles();
  const previewAnchorRef = useRef(null);

  const [checkUserRight] = useCheckUserRight();
  const showArchived = checkUserRight('feature', 'instance-archive');

  const [selectedDates, setSelectedDates] = useSidebarDatePickerAtom();

  const [selectedSubtab, setSelectedSubtab] = useSidebarSelectedSubtab();
  const scheduleType = subtabs[selectedSubtab];
  const [leftSidebarSavedSearches, setLeftSidebarSavedSearches] = useLeftSidebarSavedSearches();

  const [showMyItemsOnly, setShowMyItemsOnly] = useState(false);

  const [platformsData, setPlatformsData] = useState();
  const [data, error, loading] = useGetPlatforms(new Date());

  useEffect(() => {
    if (!loading && !error) setPlatformsData(data);
  }, [data, loading, error]);

  const handleCreateNewFilter = (newFilter) => {
    setLeftSidebarSavedSearches([...leftSidebarSavedSearches, newFilter]);
  };

  const handleToggleSwitch = () => {
    setShowMyItemsOnly(!showMyItemsOnly);
  };
  const handleUpdateFilters = (newSavedFilters) => {
    setLeftSidebarSavedSearches(newSavedFilters);
  };

  const memoizedDateTimePicker = useMemo(
    () => (
      <DatePickerButton
        onSelectedDateChange={setSelectedDates}
        canSelectRange
        showQuickSelect
        selectedDate={selectedDates}
      />
    ),
    [selectedDates],
  );

  const tabIndex = selectedSubtab;

  const onTabChange = (value, index) => {
    setSelectedSubtab(index);
  };

  const visibleTabs = showArchived ? subtabs : subtabs.filter((tab) => tab !== 'archived');
  const tabs = visibleTabs.map((subTab) => ({
    label: subTab,
  }));

  return (
    <div className={classes.container}>
      <div className={classes.sidebarHeader} ref={previewAnchorRef}>
        <Text variant="h7" color="highEmphasis" className={classes.sidebarHeaderText}>
          Instances
        </Text>
        <FormControlLabel
          control={<Switch selected={showMyItemsOnly} onChange={handleToggleSwitch} />}
          classes={{
            root: classes.formControlLabel,
            label: classes.label,
          }}
          label="My items only"
          labelPlacement="start"
        />
      </div>
      <>
        <Tabs variant="default" tabWidth="scrollable" {...{ tabs, tabIndex, onTabChange }} />
        <div className={classes.divider}>
          <Divider />
        </div>
      </>
      {scheduleType !== scheduleTypes.UNSCHEDULED && (
        <div className={classes.datepickerContainer}>{memoizedDateTimePicker}</div>
      )}
      <Scrollbar top={5} bottom={5}>
        <InstanceListContainer
          selectedDates={selectedDates}
          scheduleType={scheduleType}
          showMyItemsOnly={showMyItemsOnly}
          searchString={searchString}
          assignedMembers={assignedMembers}
          platforms={platforms}
          platformsFilter={platformsFilter}
          ref={previewAnchorRef}
          isArchivedTabSelected={subtabs[selectedSubtab] === 'archived'}
        />
      </Scrollbar>
      <Searchbar
        chipTypes={['user', 'platform', 'unit']}
        savedFilters={leftSidebarSavedSearches}
        onCreateFilter={handleCreateNewFilter}
        onUpdateFilters={handleUpdateFilters}
        platforms={platformsData}
        {...{
          filterModel,
          updateFilterModel,
          history,
          updateHistory,
          currentSearch,
          updateCurrentSearch,
        }}
      />
    </div>
  );
};

export default InstanceContainer;
