import InstanceComponent from 'features/instance/Instance';
import { HStack } from 'layouts/box/Box';
import { Instance } from 'types';
import { MemberType } from 'types/graphqlTypes';

interface Props {
  member: MemberType | null;
  onClose: () => void;
}

const WIDTH = '800px';

export default function StoryDrawer({ member, onClose }: Readonly<Props>) {
  return (
    <HStack alignItems="stretch" height="100%" width={WIDTH} maxWidth={WIDTH}>
      <InstanceComponent
        instance={member as Instance}
        autoUpdate
        isSearchItem
        onClose={onClose}
        onOpen={onClose}
      />
    </HStack>
  );
}
