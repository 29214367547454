import React, { useState, useEffect, useCallback, useRef } from 'react';
import Popper from '@material-ui/core/Popper';

import { useLocalNotifications } from 'store/notifications';

import { useIsMessageHubOpen, useSelectedConversationId } from 'store';
import useCheckUserRight from 'hooks/useCheckUserRight';
import useUpdateConvoReadAt from 'hooks/useUpdateConvoReadAt';
import memberTypes from 'graphql/memberTypes';

import { ReactComponent as ChatResting } from 'assets/icons/systemicons/HeaderNavbar/chat_resting.svg';
import { ReactComponent as ChatSelected } from 'assets/icons/systemicons/HeaderNavbar/chat_selected.svg';

import Tooltip from 'components/tooltip';
import NotificationIndicator from 'components/statusIndicators/notification/Notification';
import ResizableDialog from 'components/resizableDialog';
import Alert from '../alert';
import MessageHub from './messageHub-view';

import {
  StyledNotificationIcon,
  StyledChatEnabled,
  NotificationIndicatorWrapper,
  MessagesWrapper,
} from './messageHub-styled';

const MessageHubContainer = ({ messages }) => {
  const [isMessageHubOpen, setIsMessageHubOpen] = useIsMessageHubOpen();
  const [selectedConversationId, setSelectedConversationId] = useSelectedConversationId();
  const [chatNotified, setChatNotified] = useState(0);
  const [newChatNotifications, setNewChatNotifications] = useState([]);
  const messageHubRef = useRef(null);
  const [checkUserRight] = useCheckUserRight();
  const [updateConvoReadAt] = useUpdateConvoReadAt();
  const canUseChat = checkUserRight('chat', 'access');
  const [isToolTipOpen, setIsToolTipOpen] = useState(false);
  const [localNotifications] = useLocalNotifications();

  const removeConversationNotification = useCallback(() => {
    const filteredNotifications = newChatNotifications.filter(
      ({ mType }) => mType !== memberTypes.CONVERSATION,
    );
    setNewChatNotifications([...filteredNotifications]);
  }, [newChatNotifications]);

  const removeMessageNotification = useCallback(
    (conversationId, storyId) => {
      const filteredNotifications = storyId
        ? newChatNotifications.filter(({ mStoryId }) => mStoryId !== storyId)
        : newChatNotifications.filter(({ mId }) => mId !== conversationId);
      setNewChatNotifications([...filteredNotifications]);
    },
    [newChatNotifications],
  );

  const onMessagePopoverClose = () => {
    if (selectedConversationId) updateConvoReadAt(selectedConversationId);
    removeConversationNotification();
    setIsMessageHubOpen(false);
    setSelectedConversationId(null);
  };

  const onMessagePopoverOpen = () => {
    removeConversationNotification();
    setIsMessageHubOpen(true);
    setIsToolTipOpen(false);
  };

  useEffect(() => {
    if (messages.length > chatNotified) {
      const numOfMessagesToBeNotified = messages.length - chatNotified;
      const latestMessages = messages.slice(-numOfMessagesToBeNotified);
      setChatNotified(latestMessages.length + chatNotified);
      setNewChatNotifications([...latestMessages, ...newChatNotifications]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  useEffect(() => {
    window.onbeforeunload = async (e) => {
      e.preventDefault();
      if (selectedConversationId) updateConvoReadAt(selectedConversationId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConversationId]);

  return (
    canUseChat && (
      <>
        <StyledNotificationIcon>
          <Tooltip title="Message Hub" noArrow open={isToolTipOpen}>
            <StyledChatEnabled
              ref={messageHubRef}
              type="button"
              onClick={onMessagePopoverOpen}
              onMouseEnter={() => setIsToolTipOpen(true)}
              onMouseLeave={() => setIsToolTipOpen(false)}
            >
              {isMessageHubOpen ? <ChatSelected /> : <ChatResting />}
            </StyledChatEnabled>
          </Tooltip>
          {newChatNotifications.length > 0 && (
            <NotificationIndicatorWrapper>
              <NotificationIndicator notificationCount={newChatNotifications.length} />
            </NotificationIndicatorWrapper>
          )}
        </StyledNotificationIcon>
        {isMessageHubOpen ? (
          <ResizableDialog
            open
            onClose={onMessagePopoverClose}
            initialWidth={944}
            initialHeight={800}
            minWidth={840}
            minHeight={640}
            initialPosition={{ x: document.body.clientWidth - 960, y: 56 }}
          >
            <MessagesWrapper>
              <MessageHub
                onClose={onMessagePopoverClose}
                removeMessageNotification={removeMessageNotification}
                notifications={[...newChatNotifications, ...localNotifications]}
              />
            </MessagesWrapper>
          </ResizableDialog>
        ) : (
          messageHubRef?.current && (
            <Popper anchorEl={messageHubRef?.current} open disablePortal placement="bottom-end">
              <Alert
                notifications={[...newChatNotifications, ...localNotifications]}
                onOpenMessageHub={onMessagePopoverOpen}
                removeNotificationMessageCount={removeMessageNotification}
              />
            </Popper>
          )
        )}
      </>
    )
  );
};

export default MessageHubContainer;
